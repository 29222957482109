import { IonButton, IonCol, IonIcon, IonRow, IonTextarea, IonToast, IonToolbar } from "@ionic/react";
import { sendSharp } from "ionicons/icons";
import moment from "moment";
import React, { useRef, useState } from "react";
import "../../css/ChatInput.css";
import { getMessagesAndActivities, postChatRoomMessage } from "../../services/ChatService";
import { sendSlackNotification } from "../../services/NotificationService";
import { StorageService } from "../../services/StorageService";
import { getUser } from "../../services/UserService";
import SendPhotoToChatComponent from "../SendPhotoToChatComponent";
const storageService = new StorageService();

const SquadHomeChatInput = ({ feed, setFeed, chatRoomObj, setScroller, setGifSelectorOpen }) => {
	const [text, setText] = useState("");
	const [showToast, setShowToast] = useState(false);

	const submitText = async () => {
		let storedText = text;
		setText("");
		if (storedText !== "" && storedText !== null && storedText !== undefined) {
			// make sure it's legit
			console.log("Text captured: " + storedText);
			let user = JSON.parse(await getUser(null, false));
			let messageObj = {
				avatar: user.avatar,
				author: user.cognitoId,
				chatRoom: chatRoomObj,
				createdAt: moment().format(),
				deleted: false,
				id: null,
				message: storedText,
				reactionCount: 0,
				reactions: [],
				type: "message",
				date: moment().format(),
				timeStamp: moment().format(),
				updatedAt: moment().format(),
				user: {
					avatar: user.avatar,
					level: user.level,
					userName: user.userName,
				},
				userID: user.cognitoId,
				userName: user.userName,
			};

			await storageService.setItem("acceptTimerPull", "false"); // flag just in case
			let currentFeed = await storageService.getObject("messages-" + chatRoomObj.id); // get the current feed
			if (currentFeed === null) {
				currentFeed = [];
			}
			await currentFeed.push(messageObj); // add the message to the feed so it shows up locally
			setFeed(currentFeed); // update the state
			setScroller(true);
			await postChatRoomMessage(chatRoomObj.id, storedText).catch((error) => {
				console.log("Feed update error: ", { error });
				setShowToast(true);
			}); // send it to the server
			sendSlackNotification("*Squad: " + chatRoomObj.name + "*\n[" + user.userName + "] said: " + storedText, "messaging");
			await getMessagesAndActivities(chatRoomObj).catch((error) => {
				console.log("Feed update error: ", { error });
				setShowToast(true);
			});
			let serverFeed = await storageService.getObject("messages-" + chatRoomObj.id);
			console.log("Severfeed after post: ", { serverFeed });
			setFeed(serverFeed);
			setScroller(true);
			await storageService.setItem("acceptTimerPull", "true");
			setText("");
		}
	};

	function handleKeyUp(event) {
		if (event.keyCode === 13) {
			submitText();
		}
	}

	async function inputFocus() {
		console.log("Focus on input. Should scroll");
		let currentFeed = await storageService.getObject("messages-" + chatRoomObj.id);
		setFeed(currentFeed);
		setScroller(true);
	}

	window.addEventListener("keyboardDidShow", async (event) => {
		console.log("KEYBOARD SHOWED");
		setScroller(true);
	});

	window.addEventListener("keyboardDidHide", async (event) => {
		console.log("KEYBOARD HID");
		setScroller(true);
	});

	// if not show a text box with a submit button
	return (
		<IonToolbar className="chat-input-toolbar">
			<IonToast isOpen={showToast} onDidDismiss={() => setShowToast(false)} message="Trouble communicating with the server..." duration={1000} />

			<IonRow>
				<IonCol size="1">
					<IonButton onClick={() => setGifSelectorOpen(true)} className="submit-button gif-button" color="chat-bar-button" style={{ fontSize: "16px" }}>
						GIF
					</IonButton>
				</IonCol>
				<IonCol size="1">
					<SendPhotoToChatComponent chatRoomObj={chatRoomObj} setFeed={setFeed} setScroller={setScroller} setShowToast={setShowToast} />
				</IonCol>
				<IonCol size="8">
					<IonTextarea
						autoGrow="true"
						rows="1"
						className="chat-input"
						type="text"
						value={text}
						onIonChange={(e) => setText(e.detail.value)}
						onKeyUp={handleKeyUp}
						onFocus={inputFocus}
					></IonTextarea>
				</IonCol>
				<IonCol size="2">
					<IonButton onClick={submitText} className="send-button" color="chat-bar-button" style={{ fontSize: "0px" }}>
						<IonIcon size="small" icon={sendSharp}></IonIcon>
					</IonButton>
				</IonCol>
			</IonRow>
		</IonToolbar>
	);
};

export default SquadHomeChatInput;
