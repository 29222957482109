import { IonContent, IonInput, IonItem, IonPage, useIonViewDidEnter } from "@ionic/react";
import React, { useRef, useState } from "react";
import { didSeasonJustEndForUser } from "../../services/SeasonsService";
import EndOfWeekSummaryComponent from "../HomePageCards/EndOfWeekSummaryComponent";
import CollectAddress from "./CollectAddress";

const TestForms = () => {
	const [showModal, setShowModal] = useState(true);

	useIonViewDidEnter(async () => {});

	return (
		<IonPage>
			<IonContent>
				<EndOfWeekSummaryComponent modalState={showModal} setModalState={setShowModal} />
			</IonContent>
		</IonPage>
	);
};

export default TestForms;
