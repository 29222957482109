/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:b35ce171-56b0-471d-981c-29f3741edea1",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_3FbIY8Ryk",
    "aws_user_pools_web_client_id": "3r4o7i4r4laf83ervcsbq9g17a",
    "oauth": {
        "domain": "fitpet.auth.us-west-2.amazoncognito.com"
    }
};


export default awsmobile;
