import React, { useRef } from "react";
import { IonSlides, IonSlide, IonButton } from "@ionic/react";
import Emoji from "react-emoji-render";

const ChallengeDetailsCoachMark = ({ setShowCoachMark }) => {
	return (
		<React.Fragment>
			<div style={{ width: "80%", position: "absolute", top: "10%", textAlign: "center", margin: "10%" }}>
				<br />
				<div style={{ fontSize: "2em" }}>
					🏅🏅🏅
					<br />
					Time to make your activity more rewarding
				</div>
				<div style={{ fontSize: "20px", paddingTop: "10%" }}>Scroll through to see the details of the challenge</div>
				<div style={{ fontSize: "20px", paddingTop: "10%" }}>Tap the icons to show more detail about each item</div>
			</div>
		</React.Fragment>
	);
};

export default ChallengeDetailsCoachMark;
