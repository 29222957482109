import { IonButton, IonHeader, IonIcon, IonTitle, IonToolbar } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React from "react";
import "../../css/ActivityDetails.css";
import "../../css/Home.css";

const RewardPopover = ({ showRewardPopover, setShowRewardPopover }) => {
	return (
		<React.Fragment>
			<IonHeader>
				<IonToolbar className="activity-details-toolbar">
					<IonTitle>Reward Description</IonTitle>
					<IonButton
						color="tertiary"
						slot="end"
						onClick={() =>
							setShowRewardPopover({
								visible: false,
								rewardImage: null,
								rewardDescription: null,
							})
						}
					>
						<IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
					</IonButton>
				</IonToolbar>
			</IonHeader>
			<div style={{ margin: "16px" }}>
				<p>
					{showRewardPopover.rewardImage !== null ? (
						<img src={showRewardPopover.rewardImage} alt="" />
					) : (
						<img src="https://miro.medium.com/max/880/1*PHQUUPETLzyjJ5FE799-UA.jpeg" alt="" />
					)}
				</p>
				<h1>{showRewardPopover.rewardName}</h1>
				<p dangerouslySetInnerHTML={{ __html: `${showRewardPopover.rewardDescription}` }}></p>
			</div>
		</React.Fragment>
	);
};

export default RewardPopover;
