import {
	IonBackButton,
	IonButtons,
	IonCard,
	IonCardContent,
	IonCardTitle,
	IonContent,
	IonHeader,
	IonIcon,
	IonPage,
	IonPopover,
	IonTitle,
	IonToolbar,
	useIonViewWillEnter,
} from "@ionic/react";
import { closeCircleSharp } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../contexts/GlobalState";
import "../../css/Challenges.css";
import "../../css/Home.css";
import { challengeTesterUsernames } from "../../models/constants";
import { logGenericEvent, logPageView } from "../../services/AnalyticsService";
import { getAvailableChallenges } from "../../services/ChallengeService";
import { sendSlackNotification } from "../../services/NotificationService";
import ChallengesComingSoon from "../CoachMarks/ChallengesComingSoon";
import ChallengeHeaderComponent from "./ChallengeHeaderComponent";

const Challenges = () => {
	const { userState, challenges, updateChallenges, challengesEnabled } = useContext(AuthContext);

	const [buttons, setButtons] = useState([]);
	const [availableChallenges, setAvailableChallenges] = useState([]);
	const [showCoachMark, setShowCoachMark] = useState(false);

	useIonViewWillEnter(() => {
		logPageView("Challenges");
		if (userState) {
			sendSlackNotification(userState.user.userName + " viewed challenges page", "challenges", false);
		}
		getAllAvailableChallenges();
	});

	useEffect(() => {}, []);

	const getAllAvailableChallenges = async () => {
		let availableOnly = true;
		if (challengeTesterUsernames.includes(userState.user.userName)) {
			availableOnly = false;
		}
		let challenges = await getAvailableChallenges(availableOnly);
		setAvailableChallenges(challenges);
	};

	const showCM = (event) => {
		logGenericEvent(event);
		setShowCoachMark(true);
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
					<IonTitle>Challenges</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent className="background">
				<IonPopover
					isOpen={showCoachMark}
					cssClass="chat-room-tool-tip-1"
					onDidDismiss={() => {
						setShowCoachMark(false);
					}}
				>
					<div style={{ position: "absolute", top: "0%", left: "90%", fontSize: "30px", zIndex: "200000" }} onClick={() => setShowCoachMark(false)}>
						<IonIcon icon={closeCircleSharp}></IonIcon>
					</div>
					<ChallengesComingSoon setShowCoachMark={setShowCoachMark} />
				</IonPopover>
				{availableChallenges.map((challenge, i) => {
					let primaryColor;
					let textColor;
					// console.log({ challenge });
					if (!challenge.metadata?.primaryColor) {
						primaryColor = "#ffffff";
					} else {
						primaryColor = challenge.metadata.primaryColor;
					}
					if (!challenge.metadata?.textColor) {
						textColor = "#ffffff";
					} else {
						textColor = challenge.metadata.textColor;
					}
					return (
						<React.Fragment key={`${i}`}>
							{userState && challengesEnabled ? (
								<IonCard key={`${i}`} routerLink={`/challenge/${challenge.id}`}>
									{challenge.available === "0" || challenge.available === 0 ? (
										<IonCardTitle style={{ textAlign: "center", backgroundColor: "gray", color: "white", fontSize: "1em" }}>hidden</IonCardTitle>
									) : (
										""
									)}

									<ChallengeHeaderComponent challengeDetails={challenge} />
									<IonCardContent>
										<div className="view-challenge" style={{ width: "90%", float: "left" }}>
											View challenge
										</div>
										<div className="chevron" style={{ float: "left" }}>
											{">"}
										</div>
									</IonCardContent>
								</IonCard>
							) : (
								<IonCard onClick={() => showCM(`Level ${challenge.level} challenge`)}>
									<ChallengeHeaderComponent challengeDetails={challenge} />
									<IonCardContent>
										<div className="view-challenge" style={{ width: "90%", float: "left" }}>
											View challenge
										</div>
										<div className="chevron" style={{ float: "left" }}>
											{">"}
										</div>
									</IonCardContent>
								</IonCard>
							)}
						</React.Fragment>
					);
				})}
			</IonContent>
		</IonPage>
	);
};

export default Challenges;
