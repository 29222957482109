import { IonCard, IonCol, IonItem, IonPopover, IonRow } from "@ionic/react";
import moment from "moment";
import React, { useState } from "react";
import "../../css/ActivityDetails.css";
import "../../css/Home.css";
import "../../css/FAQs.css";
import { ChallengeDetails } from "../../models/challengeModels";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion";
import ChallengeTerms from "./ChallengeTerms";
import { cloudFrontURL } from "../../models/constants";

const ChallengeHeaderComponent = ({ challengeDetails }: { challengeDetails: ChallengeDetails }) => {
	return (
		<React.Fragment>
			{challengeDetails && challengeDetails.metadata ? (
				<React.Fragment>
					<div className="challenge-card-holder" style={{ backgroundColor: `${challengeDetails.metadata.primaryColor}` }}>
						<div className="main-text-div">
							<div className="level-div">Level {challengeDetails.level}</div>
							<div className="name" style={{ color: `${challengeDetails.metadata.textColor}` }}>
								{challengeDetails.name}
							</div>
							<div className="description" style={{ color: `${challengeDetails.metadata.textColor}` }}>
								{challengeDetails.description}
							</div>
						</div>
						<div className="card-image">
							<img className="card-image-img" src={`${cloudFrontURL}/${challengeDetails.image}`} alt="" />
						</div>
					</div>
				</React.Fragment>
			) : (
				""
			)}
		</React.Fragment>
	);
};

export default ChallengeHeaderComponent;
