import { Plugins } from "@capacitor/core";
import { HealthKit } from "@ionic-native/health-kit";
import {
	IonAlert,
	IonBackButton,
	IonButton,
	IonButtons,
	IonContent,
	IonFooter,
	IonHeader,
	IonLoading,
	IonPage,
	IonProgressBar,
	IonToolbar,
	useIonViewDidEnter,
} from "@ionic/react";
import React, { useState } from "react";
import { useHistory } from "react-router";
import "../../css/Home.css";
import "../../css/Onboarding.css";
import { logGenericEvent, logPageView } from "../../services/AnalyticsService";
import { getUser } from "../../services/UserService";
import { StorageService } from "../../services/StorageService";
const storageService = new StorageService();
const { Device } = Plugins;

const ConnectTracker = ({ progress, setProgress }) => {
	const history = useHistory();
	const [user, setUser] = useState();
	const [loading, setLoading] = useState(true);
	const [platform, setPlatform] = useState();
	const [fitbitLink, setFitbitLink] = useState();
	const [fitBitAlert, setFitbitAlert] = useState();
	const [errorAlert, setErrorAlert] = useState({
		visible: false,
		message: null,
	});

	useIonViewDidEnter(() => {
		logPageView("Connect a tracker");
		setProgress(0.8);
		storageService.setItem("lastOnboardingStep", 9);
		Device.getInfo().then((deviceInfo) => {
			setPlatform(deviceInfo.platform);
		});
		fetchUser();
	});

	const fetchUser = async () => {
		let userstored = JSON.parse(await getUser());
		setUser(userstored);
		setFitbitLink("https://www.fitbit.com/oauth2/authorize?response_type=code&client_id=22BLTK&scope=activity&state=" + userstored.cognitoId);
		setLoading(false);
	};

	const connectHealthKit = () => {
		logGenericEvent("attempt healthKit connection");
		if (platform === "web") {
			history.push("/onboarding/healthkitconfirmation");
		} else {
			HealthKit.available().then(
				(data) => {
					if (data) {
						var options = {
							readTypes: [
								"HKQuantityTypeIdentifierStepCount",
								"HKWorkoutTypeIdentifier",
								"HKQuantityTypeIdentifierActiveEnergyBurned",
								"HKQuantityTypeIdentifierAppleExerciseTime",
								"HKQuantityTypeIdentifierBodyMass",
							],
						};

						HealthKit.requestAuthorization(options).then((_) => {
							console.log("Authorization complete");
							history.push("/onboarding/healthkitconfirmation");
						});
					}
					logGenericEvent("succesful healthKit connection");
				},
				(err) => {
					console.log("error in HK available: ", err);
					setErrorAlert({
						visible: true,
						message:
							"Error trying to connect your Healthkit: " +
							err +
							"<br/><br/>Please try again. If it keeps happening, select 'No thanks' and you can connect it later.",
					});
					logGenericEvent("error in healthKit connection: " + err);
				}
			);
		}
	};

	const connectFitbit = () => {
		logGenericEvent("attempt fitbit connection");
		window.open(fitbitLink, "_system");
		setFitbitAlert(true);
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar style={{ "--border-style": "none" }}>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
				</IonToolbar>
				<IonProgressBar value={progress} />
			</IonHeader>
			{/* <IonLoading isOpen={loading} onDidDismiss={() => setLoading(false)} message={"Calculating your credit.."} backdropDismiss /> */}
			<IonAlert
				isOpen={!!errorAlert.visible}
				header="Oops"
				message={errorAlert.message}
				buttons={[
					{
						text: "Ok",
						role: "cancel",
						handler: () => {
							setErrorAlert({ visible: false, message: null });
						},
					},
				]}
				onDidDismiss={() => setErrorAlert({ visible: false, message: null })}
			/>
			<IonAlert
				isOpen={!!fitBitAlert}
				header="Good to go?"
				message="Did you successfully authorize Fitbit?"
				buttons={[
					{
						text: "No",
						role: "cancel",
						handler: () => {
							setFitbitAlert(false);
						},
					},
					{
						text: "Yes",
						role: "ok",
						handler: () => {
							history.push("/onboarding/fitbitconfirmation");
						},
					},
				]}
				onDidDismiss={() => setFitbitAlert(false)}
			/>
			{user && fitbitLink && platform ? (
				<React.Fragment>
					<IonContent>
						<div className="onboarding-main-div">
							<div className="onboarding-title">Connect your tracker</div>
							<div className="onboarding-hero-image-div" style={{ paddingRight: "16px" }}>
								<img src="assets/images/app-icons.jpg" alt="" />
							</div>
							<div className="onboarding-text-area" style={{ textAlign: "center" }}>
								You can always come to the app to log your activity, or if you have one of these you can connect it and your activity will be automatically
								logged for you.
								<div className="onboarding-app-button-div">
									<IonButton className="onboarding-app-button" color="tertiary" onClick={connectFitbit}>
										Fitbit
									</IonButton>
								</div>
								{platform === "ios" || platform === "web" ? (
									<React.Fragment>
										<div className="onboarding-app-button-div">
											<IonButton className="onboarding-app-button" color="tertiary" onClick={connectHealthKit}>
												HealthKit
											</IonButton>
										</div>
										<div className="onboarding-help-text">includes Apple Watch & iPhone</div>
									</React.Fragment>
								) : (
									""
								)}
								{platform ? (
									<React.Fragment>
										<div className="onboarding-app-button-div">
											<IonButton className="onboarding-app-button" color="tertiary" disabled>
												Google Fit
											</IonButton>
										</div>
										<div className="onboarding-help-text">coming soon!</div>
									</React.Fragment>
								) : (
									""
								)}
								<div className="onboarding-app-button-div">
									<IonButton className="onboarding-app-button" color="tertiary" disabled>
										Garmin
									</IonButton>
								</div>
								<div className="onboarding-help-text">coming soon!</div>
							</div>
						</div>
					</IonContent>
					<IonFooter className="onboarding-footer">
						<IonButton className="button-as-link" routerLink="/onboarding/setgoal">
							No thanks / Don't have one
						</IonButton>
					</IonFooter>
				</React.Fragment>
			) : (
				""
			)}
		</IonPage>
	);
};

export default ConnectTracker;
