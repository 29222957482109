import { Plugins } from "@capacitor/core";
import { IonBackButton, IonButtons, IonChip, IonContent, IonHeader, IonLabel, IonPage, IonTitle, IonToolbar, useIonViewDidEnter } from "@ionic/react";
import React, { useState } from "react";
import { useParams } from "react-router";
import SquadInviteComponent from "../components/SquadInviteComponent";
import "../css/Home.css";
import "../css/Onboarding.css";
import "../css/SquadCreate.css";
import "../css/SquadScoreboard.css";
import { getSquad } from "../services/SquadService";

const SquadCreateComplete = ({ onboarding }) => {
	const { PushNotifications, LocalNotifications, Device } = Plugins;
	let squadID = useParams().squadID;
	const [squadObject, setSquadObject] = useState();

	useIonViewDidEnter(async () => {
		let squad = await getSquad(squadID);
		setSquadObject(squad);
	});

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
					<IonTitle>Create a Squad</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<div className="onboarding-main-div" style={{ paddingLeft: "16px", paddingRight: "16px" }}>
					<div className="onboarding-title">Congrats!</div>
					<div style={{ fontSize: "16px", textAlign: "center" }}>
						You are now the <br />
						Squad Leader of this squad:
					</div>
					{squadObject ? (
						<React.Fragment>
							<div className="squad-data-div" style={{ width: "100%", textAlign: "center", paddingTop: "10px" }}>
								<div id="squad-image">
									{squadObject ? <img src={squadObject.image} alt="" style={{ height: "50%", width: "50%", borderRadius: "10px" }} /> : ""}
								</div>
								<div style={{ textAlign: "center" }}>
									<div className="squad-slogan-div" style={{ width: "100%", fontStyle: "italic" }}>
										{squadObject.slogan}
									</div>
									<div className="squad-name-div" style={{ width: "100%", fontSize: "1.5em", fontWeight: "bold" }}>
										{squadObject.name}
									</div>
									<div style={{ width: "100%", fontSize: "1.5em", fontWeight: "bold" }}>
										{squadObject.tags &&
											squadObject.tags.map((tag, i) => {
												return (
													<IonChip key={`${i}`} outline="false" color="tertiary">
														<IonLabel>{tag.tag}</IonLabel>
													</IonChip>
												);
											})}
									</div>
								</div>
							</div>
							<SquadInviteComponent squadObject={squadObject} />
							{onboarding === true ? (
								<React.Fragment>
									<p style={{ fontSize: "16px", textAlign: "center" }}>Not ready to invite? Explore the app by tapping the icons below.</p>
									<p style={{ fontSize: "16px", textAlign: "center" }}>
										Later you can invite people to your squad by tapping:
										<br />
										Squads ➡ Manage Squad
									</p>
									<p className="squad-name-div" style={{ width: "100%", fontSize: "1.5em", fontWeight: "bold", textAlign: "center" }}>
										Welcome to FitSquad!
									</p>
								</React.Fragment>
							) : (
								""
							)}
						</React.Fragment>
					) : (
						""
					)}
				</div>
			</IonContent>
		</IonPage>
	);
};

export default SquadCreateComplete;
