import React, { useRef } from "react";
import { IonSlides, IonSlide, IonButton } from "@ionic/react";
import Emoji from "react-emoji-render";

const ChallengesComingSoon = ({ setShowCoachMark }) => {
	return (
		<React.Fragment>
			<div style={{ width: "80%", position: "absolute", top: "10%", textAlign: "center", margin: "10%" }}>
				<br />
				<span style={{ fontSize: "2em" }}>
					🏅🏅🏅
					<br />
					Challenges are coming soon!
				</span>
				<br />
				<br />
				<span style={{ fontSize: "20px" }}>
					With Challenges, you'll be able to set a multi-week goal.
					<br />
					<br />
					If you're successful, earn medals, t-shirts and more! <br />
					<br />
					Stay tuned!
				</span>
			</div>
		</React.Fragment>
	);
};

export default ChallengesComingSoon;
