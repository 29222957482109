import React from "react";
import moment from "moment";

const TimeAgoComponent = ({ date }) => {
	let response;
	let currentTime = moment();
	let dateMoment = moment(date);
	let minutes = currentTime.diff(dateMoment, "minutes");
	let hours = currentTime.diff(dateMoment, "hours");
	let days = currentTime.diff(dateMoment, "days");
	let weeks = currentTime.diff(dateMoment, "weeks");
	let daysAgo = moment().date() - moment(date).date();
	if (minutes === 0) {
		response = "Less than a minute ago";
	}
	if (minutes === 1) {
		response = minutes + " minute ago";
	}
	if (hours === 0) {
		response = minutes + " minutes ago";
	}
	if (hours === 1) {
		response = hours + " hour ago";
	}
	if (hours > 1 && hours < 24) {
		response = hours + " hours ago";
	}
	if (days === 1) {
		response = "yesterday";
	}
	if (daysAgo === 1) {
		response = "yesterday";
	}
	if (days > 1 && days < 7) {
		response = days + " days ago";
	}
	if (daysAgo > 1 && daysAgo < 7) {
		response = daysAgo + " days ago";
	}
	if (weeks === 1) {
		response = weeks + " week ago";
	}
	if (weeks > 1) {
		response = weeks + " weeks ago";
	}
	return <span>{response}</span>;
};

export default TimeAgoComponent;
