import moment from "moment";
import { useEffect } from "react";
import "../../css/ActivityDetails.css";
import "../../css/Home.css";
import { getUserActivities } from "../../services/ActivitiesService";

const ActivitySummary = () => {
	useEffect(() => {
		pullActivities();
	}, []);

	const pullActivities = async () => {
		let tempActivities = [];
		let activities = JSON.parse(
			await getUserActivities(null, null, moment("2021-05-24 07:00:00"), moment("2021-05-31 06:59:59"), null, "ff506450-90e1-42b8-ba0a-de3204dece06")
		);
		for await (let activity of activities) {
			if (activity.points && activity.points > 0 && activity.squadId === null) {
				tempActivities.push(activity);
			}
		}
		console.log({ tempActivities });
	};

	return "";
};

export default ActivitySummary;
