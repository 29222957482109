import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonLoading,
	IonPage,
	IonPopover,
	IonRefresher,
	IonRefresherContent,
	IonTitle,
	IonToolbar,
	useIonViewDidEnter,
} from "@ionic/react";
import { syncOutline } from "ionicons/icons";
import React, { useState } from "react";
import { useParams } from "react-router";
import ExerciseDetailsComponent from "../ActivityFeedComponents/ExerciseDetailsComponent";
import SystemActivityDetailsComponent from "../ActivityFeedComponents/SystemActivityDetailsComponent";
import ProfileMainAvatarComponent from "./ProfileMainAvatarComponent";
import ProfileWeeklyProgressComponent from "./ProfileWeeklyProgressComponent";
import ProfileWeeklyStreaksComponent from "./ProfileWeeklyStreaksComponent";
import SquadUserAdmin from "./SquadUserAdminComponent";
import "../../css/Home.css";
import "../../css/Profile.css";
import { logPageView } from "../../services/AnalyticsService";
import { getGoal, getPrediction } from "../../services/PointsAndGoalsService";
import { StorageService } from "../../services/StorageService";
import { getUser, sameSquadAndAdmin, updateUser } from "../../services/UserService";
import UserActivityFeed from "./UserActivityFeed";
import { getSquad } from "../../services/SquadService";

const UserProfileOther = () => {
	// Loading states
	const [loading, setLoading] = useState();
	const [squads, setSquads] = useState([]);
	const [userIDPassed, setUserID] = useState();
	const [userObject, setUserObject] = useState();
	const [goalObject, setGoalObject] = useState();
	const [activityDetails, showActivityDetails] = useState({
		visible: false,
		activityID: null,
		component: null,
	});
	const [systemActivityDetails, showSystemActivityDetails] = useState({
		visible: false,
		activityID: null,
		component: null,
	});
	let userID = useParams().passedUserID;

	const storageService = new StorageService();

	useIonViewDidEnter(async () => {
		logPageView("profile-other");
		console.log("Triggering doRefresh");
		doRefresh(null, false, userID);
	}, [squads]);

	const getUserObject = async (userID, refresh = false) => {
		let userObj = JSON.parse(await getUser(userID, refresh));
		console.log("UserID: ", { userObj });
		setUserObject(userObj);
		return userObj;
	};

	const getGoalObject = async (userID, refresh = false) => {
		let goalObject = await getGoal(userID, refresh).catch((error) => {
			console.error("Error in getting goal: " + error);
			return;
		});
		let gp;
		if (goalObject !== null) {
			gp = Math.round((goalObject.goalProgress / goalObject.goalPoints) * 100);
		} else {
			gp = 0;
		}
		goalObject.goalPercent = gp;
		if (!goalObject.prediction) {
			goalObject.prediction = await getPrediction(userID);
		}
		let squadPredictionPoints = goalObject.prediction;
		let squadPredictionPercent = Math.round((squadPredictionPoints / goalObject.goalPoints) * 100);
		if (goalObject.goalProgress >= goalObject.goalPoints) {
			goalObject.iconColor = "green";
		} else if (squadPredictionPercent >= 100) {
			goalObject.iconColor = "green";
		} else if (squadPredictionPercent >= 90) {
			goalObject.iconColor = "yellow";
		} else if (squadPredictionPercent < 90) {
			goalObject.iconColor = "red";
		}
		setGoalObject(goalObject);
	};

	const doRefresh = async (event, refresh = true, userID) => {
		setUserID(userID);
		setUserObject(null);
		setGoalObject(null);
		let userObj = await getUserObject(userID, refresh);
		console.log({ userObj });
		let potentialSquadIDs = await sameSquadAndAdmin(userID); // returns an array
		let tempSquads = [];
		for await (let squadID of potentialSquadIDs) {
			let squad = await getSquad(squadID, null, null, false);
			tempSquads.push(squad);
		}
		setSquads(tempSquads);
		setUserObject(userObj);
		getGoalObject(userID, true);
		if (event)
			setTimeout(() => {
				console.log("Async operation has ended");
				event.detail.complete();
			}, 2000);
	};

	const clearStorage = async () => {
		setLoading(true);
		storageService.removeItem("userGoal-" + userObject.cognitoId);
		storageService.removeItem("userPointHistory-" + userObject.cognitoId);
		await doRefresh(null, true, userIDPassed);
		setLoading(false);
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
					<IonButton color="secondary" slot="end" onClick={() => clearStorage()}>
						<IonIcon slot="icon-only" icon={syncOutline}></IonIcon>
					</IonButton>
					<IonTitle>Profile</IonTitle>
				</IonToolbar>
				{squads.length > 0 && userObject ? <SquadUserAdmin userObject={userObject} squads={squads} setSquads={setSquads} /> : ""}
			</IonHeader>
			<IonContent>
				<IonPopover
					//event={showGoalConfirmation.event}
					isOpen={activityDetails.visible}
					cssClass="activity-full-screen-popover"
					onDidDismiss={(e) => {
						showActivityDetails({
							visible: false,
							activityID: null,
							component: null,
						});
					}}
				>
					<ExerciseDetailsComponent activityID={activityDetails.activityID} showActivityDetails={showActivityDetails} />
				</IonPopover>
				<IonPopover
					//event={showGoalConfirmation.event}
					isOpen={systemActivityDetails.visible}
					cssClass="activity-full-screen-popover"
					onDidDismiss={(e) => {
						showSystemActivityDetails({
							visible: false,
							activityID: null,
							component: null,
						});
					}}
				>
					<SystemActivityDetailsComponent activityID={systemActivityDetails.activityID} showSystemActivityDetails={showSystemActivityDetails} />
				</IonPopover>
				<IonRefresher slot="fixed" onIonRefresh={doRefresh}>
					<IonRefresherContent refreshingText="Updating Profile"></IonRefresherContent>
				</IonRefresher>
				<IonLoading isOpen={loading} onDidDismiss={() => setLoading(false)} message={"Loading"} />
				<ProfileMainAvatarComponent userObj={userObject} goalObj={goalObject} />

				{userObject && userObject.cognitoId ? <ProfileWeeklyStreaksComponent userObject={userObject} /> : ""}
				{userObject && userObject.cognitoId ? <ProfileWeeklyProgressComponent userObj={userObject} /> : ""}
				<div className="section-title ">Activities</div>
				{userIDPassed ? (
					<UserActivityFeed
						refresher={null}
						passedUserID={userIDPassed}
						showActivityDetails={showActivityDetails}
						showSystemActivityDetails={showSystemActivityDetails}
					/>
				) : (
					""
				)}
			</IonContent>
		</IonPage>
	);
};

export default UserProfileOther;
