import { Plugins } from "@capacitor/core";
import moment from "moment";
import { API_URL, emptyAvatar, PHP_URL_NAME } from "../models/constants";
import { StorageService } from "../services/StorageService";
import { setUserProfile } from "./AnalyticsService";
import { getIDToken } from "./AuthService";
import { isUserOnAChallenge } from "./ChallengeService";
import { convertStreakToEmoji, validateResponse } from "./HelperServices";
import { sendSlackErrorNotification, sendSlackNotification } from "./NotificationService";
import { shouldRefresh } from "./RefreshService";
import { areUsersInSameSquad, isUserASquadAdmin, getSquadNameFromSquadID } from "./SquadService";
import { postPHPJSONResponse, getPHP } from "./APIService";


const storageService = new StorageService();
const { Device } = Plugins;


/**
 *
 * Gets info about a user. If a userID is passed will pull info about that user instead
 * of the user who called this. If null is passed for userID and refresh is false
 * will return local user object
 *	
 * @param {string} userID (optional)
 *
 * return JSON object for the user
 */
export const getUser = async (userID = null, refresh = false) => {
	
	let userObject;
	// Check if userObject is stored
	// Check last updated
	// If userObject is not stored, get it
	// If userObject is stored, but TTL has passed, update it
	// If userObject is available, use it
	userObject = await storageService.getObject("user-"+userID);
	if (userObject && userObject !== "" && userObject.lastUpdated) {
		let update = await shouldRefresh(userObject,1440)		
		if (update === false && refresh === false){
			//console.log(`Loading user ${userID} from local storage`);
			if (userID === null && !userObject.deviceInfo){ // if it's the current user get & store their device info
				let deviceInfo = await Device.getInfo()
				userObject.deviceInfo = deviceInfo
				storageService.setObject("user-"+userID, userObject);
			}
			userObject = convertStreakToEmoji(userObject)
			return JSON.stringify(userObject);
		} else {
			//console.log(`Hitting user API because userID=${userID}, refresh=${refresh} & update=${update}`);
		}
	} else {
		//console.log(`No user object for user ${userID}`);		
	}

	const url = API_URL;
	//const url = "https://v8w1cpi7fd.execute-api.us-west-2.amazonaws.com/prod/";
	let idToken = await getIDToken().catch((error) => {
		//console.log("[getUser] Error in getIDtoken: " + error);
		throw Error("[getUser] Error in getIDtoken: " + error);
	});
	let endPoint;
	if (userID == null) {
		endPoint = "user";
	} else {
		endPoint = "user/" + userID;
	}
	const response = await Promise.resolve(
		fetch(url + endPoint, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[JS][getUser] Error in API call: " + error);
		throw Error(error);
	});

	return response
		.json()
		.then(async (data) => {		
			//console.log("[JS] getUser severResponse:", data);
			data.lastUpdated = moment()
			if (userID === null) { 
				// if it's the current user get & store their device info
				// console.log("getting device info");
				let deviceInfo = await Device.getInfo()
				// console.log(deviceInfo);
				data.deviceInfo = deviceInfo
				// also if they dont have a platform (longitude) set update that
				if (data.titleSuffix === null){
					let body = {
						titleSuffix: deviceInfo.platform
					}
					await updateUser(body)
					console.log("user platform updated");
				}
			}
			// if (await isUserOnAChallenge(userID) === true ){
			// 	console.log(data.userName+" on challenge");
			// } else {
			// 	console.log(data.userName+" NOT on challenge");
			// }
			/*
			if(data.seasonData) {
				let index = 0
				for await (let season of data.seasonData){
					data.seasonData[index].seasonID = season["season-tier"].seasonId
					//Add title
					let currentSeasons = await getCurrentSeasons()
					//console.log("Checking for title");
					//console.log(`currentSeasons[0].id = ${currentSeasons[0].id} season["season-tier"].seasonId = ${season["season-tier"].seasonId} isSeasonOpenForRegistration(season["season-tier"].seasonId = ${isSeasonOpenForRegistration(season["season-tier"].seasonId)} hasSeasonStarted(season["season-tier"].seasonId = ${hasSeasonStarted(season["season-tier"].seasonId)}`);
					if (currentSeasons.length > 0 && currentSeasons[0].id === season["season-tier"].seasonId && (isSeasonOpenForRegistration(currentSeasons[0]) === true || await hasSeasonStarted(currentSeasons[0]) === true)){
						data.userName = addSeasonEmojiToUsername(data.userName)
					}
					index++
				}

			}
			*/
			storageService.setObject("user-"+userID, data);
			data = convertStreakToEmoji(data)
			userObject = JSON.stringify(data);
			if (userID === null){setUserProfile(data)}
			return userObject;
		})
		.catch((error) => {
			console.log("[JS][getUser] Error in JSON conversion: " + error);
			throw Error(error);
		});
};

/**
 *
 * Updates the current user with attributes in the body
 *
 * @param {JSON} body
 */
export const updateUser = async (body, avatarURL = null) => {
	const url = API_URL;
	var idToken;

	// let userID = await getUserID()


	idToken = await getIDToken().catch((error) => {
		throw Error("[updateUser] Error in getIDtoken: " + error);
	});
	console.log("Update body: ",{body});
	const response = await Promise.resolve(
		fetch(url + "user", {
			method: "PATCH",
			body: JSON.stringify(body),
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[JS][updateUser] Error in API call: " + error);
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {
			console.log("[JS] [updateUser] updateUser Success:", data);
			if(data.message){
				console.error("Error detected in updateUser: "+JSON.stringify(data));
				// sendSlackErrorNotification(`Error detected in updateUser: `+JSON.stringify(data),"updateUser","userID: "+userID)
				return null
			} else {
				if (avatarURL !== null) { // basically only used when registering fakes
					data.avatar = avatarURL
				}
				storageService.setObject("user-null", data);
				return data;
			}			
		})
		.catch((error) => {
			console.log("[JS] [updateUser] Error in JSON conversion: " + error);
			throw Error(error);
		});
};

export const removeBadgesFromUserObject = async () => {
	const url = API_URL;
	var idToken;
	idToken = await getIDToken().catch((error) => {
		throw Error("[removeBadges] Error in getIDtoken: " + error);
	});
	let body = {
		badgeCount: 0
	}
	await Promise.resolve(
		fetch(url + "user", {
			method: "PATCH",
			body: JSON.stringify(body),
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[JS][removeBadges] Error in API call: " + error);
		//throw Error(error);
	});
/*
	return response
		.json()
		.then((data) => {
			console.log("[JS] [removeBadges] removeBadges Success:", data);					
		})
		.catch((error) => {
			console.log("[JS] [removeBadges] Error in JSON conversion: " + error);
			throw Error(error);
		});
		*/
};

/**
 * 
 * Simple helper function that just updates the local user object asynchronously
 * 
 */

export const updateLocalUserObject = async () => {
	getUser(null, true)
}

/**
 * 
 * Takes in optional param of userID and returns the url for the users' avatar.
 * If userID is null & refresh is false, pulls image from local storage, else fetches it from server
 * 
 * @param {string} userID
 * @param {boolean} refresh 
 */

export const getUserProfileImage = async (userID = null, refresh = false) => {
	let userObj = JSON.parse(await getUser(userID, refresh));
	return userObj.avatar
};

export const getUserNameFromUserID = async (userID) => {
	let user = await JSON.parse(await getUser(userID, false));
	return user.userName;
};

export const getUserNameOfLoggedInUser = async () => {
	let user = await JSON.parse(await getUser(null,false).catch((error) => {
		return "unknown user"	
	}));
	if (user === null || user === undefined || user === ""){
		return "unknown user"		
	}
	return user.userName;
};

export const getListOfUsers = async () => {
	//TODO: Replace with API that filters for users already in the squad
	const url = API_URL;
	let idToken = await getIDToken().catch((error) => {
		throw Error("[getUser] Error in getIDtoken: " + error);
	});
	const response = await Promise.resolve(
		fetch(url + "user?all", {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[JS][getListOfUsers] Error in API call: " + error);
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {
			//console.log("[JS] getListOfUsers severResponse:", JSON.stringify(data));
			return data;
		})
		.catch((error) => {
			console.log("[JS][getListOfUsers] Error in JSON conversion: " + error);
			throw Error(error);
		});
};

/**
 *
 * Triggers an activity to add a user to a squad
 *
 * @param {uuid} userID
 * @param {int} squadID
 */

export const inviteUserToSquad = async (userID, squadID) => {
	console.log("[inviteUserToSquad] userID: "+userID);
	const url = API_URL;
	var idToken;
	idToken = await getIDToken().catch((error) => {
		throw Error("[inviteUserToSquad] Error in getIDtoken: " + error);
	});
	let body = {
		"addMember": {
			"memberId": userID,
			"role": "normal",
		},
	};
	let fullURL = url + "squads/" + squadID 
	console.log("[inviteUserToSquad] URL: " + fullURL);
	console.log("[inviteUserToSquad] Body: " + JSON.stringify(body));
	const response = await Promise.resolve(
		fetch(fullURL, {
			method: "PATCH",
			body: JSON.stringify(body),
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[JS][inviteUserToSquad] Error in API call: " + error);
		throw Error(error);
	});

	if (response.status !== 200) {
		console.log({response});
		sendSlackErrorNotification(response.status,fullURL,"```"+JSON.stringify(body)+"```")
		throw Error("Error code: "+response.status+" Response: "+JSON.stringify(response))
	}

	return response
		.json()
		.then(async (data) => {
			let addResponse = JSON.stringify(data);
			let userName = await getUserNameOfLoggedInUser()
			let squadName = await getSquadNameFromSquadID(squadID)
			sendSlackNotification(`*User Joined Squad*\nUsername: ${userName}\nSquad ID: ${squadID}\nSquad Name: ${squadName}`,'keyEvent', true)
			//console.log("[JS] [inviteUserToSquad] updateUser response:", addResponse);
			if (addResponse.message !== undefined){
				throw Error(addResponse.message);
			} else {
				return data;
			}
		})
		.catch((error) => {
			console.log("[JS] [inviteUserToSquad] Error in JSON conversion: " + error);
			throw Error(error);
		});
};

/**
 *
 * Triggers an activity to add a user to a squad
 *
 * @param {uuid} userID
 * @param {int} squadID
 */

 export const autoAddUserToSquad = async (userID, squadID, shortCode) => {
	const url = API_URL;
	var idToken;
	idToken = await getIDToken().catch((error) => {
		throw Error("[autoAddUserToSquad] Error in getIDtoken: " + error);
	});
	let body = {
		"addMember": {
			"memberId": userID,
			"role": "normal",
		},
		"shortCode": shortCode
	};
	let fullURL = url + "squads/" + squadID 
	console.log("[autoAddUserToSquad] URL: " + fullURL);
	console.log("[autoAddUserToSquad] Body: " + JSON.stringify(body));
	const response = await Promise.resolve(
		fetch(fullURL, {
			method: "PATCH",
			body: JSON.stringify(body),
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[JS][autoAddUserToSquad] Error in API call: " + error);
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {
			let addResponse = JSON.stringify(data);
			//console.log("[JS] [inviteUserToSquad] updateUser response:", addResponse);
			if (addResponse.message !== undefined){
				throw Error(addResponse.message);
			} else {
				return data;
			}
		})
		.catch((error) => {
			console.log("[JS] [inviteUserToSquad] Error in JSON conversion: " + error);
			throw Error(error);
		});
};

/**
 *
 * Triggers an activity to accept a user to a squad
 *
 * @param {uuid} userID
 * @param {int} squadID
 */

export const acceptUserToSquad = async (userID, squadID) => {
	const url = API_URL;
	var idToken;
	idToken = await getIDToken().catch((error) => {
		throw Error("[acceptUserToSquad] Error in getIDtoken: " + error);
	});
	let body = {
		acceptMember: userID,
	};
	let fullURL = url + "squads/" + squadID 
	console.log("[acceptUserToSquad] URL: " + fullURL);
	console.log("[acceptUserToSquad] Body: " + JSON.stringify(body));
	const response = await Promise.resolve(
		fetch(fullURL, {
			method: "PATCH",
			body: JSON.stringify(body),
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[acceptUserToSquad] Error in API call: " + error);
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {
			let addResponse = JSON.stringify(data);
			//console.log("[JS] [acceptUserToSquad] updateUser response:", addResponse);
			if (addResponse.message !== undefined){
				throw Error(addResponse.message);
			} else {
				return data;
			}
		})
		.catch((error) => {
			console.log("[acceptUserToSquad] Error in JSON conversion: " + error);
			throw Error(error);
		});
};

/**
 *
 * Removes a user from a squad - both current users and invited users
 *
 * @param {uuid} userID
 * @param {int} squadID
 */

export const deleteUserFromSquad = async (userID, squadID) => {
	const url = API_URL;
	var idToken;
	idToken = await getIDToken().catch((error) => {
		throw Error("[deleteUserFromSquad] Error in getIDtoken: " + error);
	});
	const body = {
		removeMember: userID,
	};
	let fullURL = url + "squads/" + squadID 
	console.log("[deleteUserFromSquad] URL: " + fullURL);
	console.log("[deleteUserFromSquad] Body: " + JSON.stringify(body));
	const response = await Promise.resolve(
		fetch(fullURL, {
			method: "PATCH",
			body: JSON.stringify(body),
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[deleteUserFromSquad] Error in API call: " + error);
		throw Error(error);
	});

	await validateResponse(response, fullURL,body).catch((error) => {
		throw Error("Error code: "+response.status+" Response: "+JSON.stringify(response))
	})

	return response
		.json()
		.then((data) => {
			let addResponse = JSON.stringify(data);
			//console.log("[JS] [deleteUserFromSquad] response:", addResponse);
			if (addResponse.message !== undefined){
				throw Error(addResponse.message);
			} else {
				return data;
			}
		})
		.catch((error) => {
			console.log("[deleteUserFromSquad] Error in JSON conversion: " + error);
			throw Error(error);
		});
};


export const getUserID = async (refresh = false) => {
	let user, userID = null
	try {
		user = JSON.parse(await getUser(null, refresh));
		userID = user["cognitoId"];
	} catch (error) {
		console.log("Error: "+error);
		
	}	
	return userID;
};

export const updateUserSetting = async (settingName, settingValue, userID = null, refresh = false) => {
	let user = JSON.parse(await getUser(userID,true))
	let settings = user.settings	
	//console.log("Previous User settings: ",{settings});
	if (settings === undefined){
		settings = {
			[settingName]: settingValue
		}
	} else {
		settings[settingName] = settingValue
	}
	//console.log("Updated User settings: ",{settings});
	let body = {
		settings: settings
	}
	await updateUser(body).then((response) => {
		//console.log("Update User Setting Response: ",{response});
	})
}

export const getUserSetting = async (settingName,userID = null, refresh = false) => {
	let user = JSON.parse(await getUser(userID,refresh))
	let settings = user.settings	
	if (settings !== undefined){
		if (settings.hasOwnProperty(settingName)) {
			//console.log("Found setting for "+settingName+" with value = "+settings[settingName]);
			return settings[settingName]
		} else {
			//console.log("No setting found for ",settingName);
			return null
		}
	} else {
		//console.log("No settings found");
		return null
	}
}

export const getAllUserIDs = async (refresh = false) => {
	const url = API_URL;
	let idToken = await getIDToken().catch((error) => {
		throw Error("[getUser] Error in getIDtoken: " + error);
	});
	//let allUserIDs = storageService.getObject("user-ids")
	let allUserIDs = []
	if (allUserIDs && allUserIDs !== "" && allUserIDs.lastUpdated) { // all userIDs object exists
		let update = await shouldRefresh(allUserIDs)		
		if (update === false && refresh === false){	
			console.log(`Loading allUserIDs from local storage`);		
			return allUserIDs
		} else {
			//console.log(`Hitting users API because refresh=${refresh} & update=${update}`);
		}
	} else {
		//console.log(`No allUserIDs object`);
	}
	let endPoint = "user?all=true&activeHumansOnly=true"
	const response = await Promise.resolve(
		fetch(url + endPoint, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[JS][getUser] Error in API call: " + error);
		throw Error(error);
	});

	if (response.status !== 200) {
		throw Error("Error code: "+response.status+" Response: "+JSON.stringify(response))
	}


	return response
		.json()
		.then((data) => {
				console.log("Data from getAllUserIDs: ",{data});
				let allUserIDs = []
				data.forEach(async (user)=>{	
					allUserIDs.push(user.cognitoId)			
				})
				allUserIDs.lastUpdated = moment()	
				storageService.setObject("user-ids",allUserIDs)			
			return allUserIDs;
		})
		.catch((error) => {
			console.log("[JS][getUser] Error in JSON conversion: " + error);
			throw Error(error);
		});
}

export const getUserStatistics = async (userID, refresh = false) => {
	const url = API_URL;
	let idToken = await getIDToken().catch((error) => {
		throw Error("[getUserStatistics] Error in getIDtoken: " + error);
	});
	let userStatistics = await storageService.getObject("user-statistics")
	//console.log("userStatistics: ",{userStatistics});
	if (userStatistics !== null) { // the object exists
		if(userStatistics[userID]) {
			return userStatistics[userID]
		}
	} else {
		userStatistics = {}
	}
	/*
	if (allUserIDs && allUserIDs !== "" && allUserIDs.lastUpdated) { // all userIDs object exists
		let update = await shouldRefresh(allUserIDs)		
		if (update === false && refresh === false){	
			console.log(`Loading allUserIDs from local storage`);		
			return allUserIDs
		} else {
			console.log(`Hitting users API because refresh=${refresh} & update=${update}`);
		}
	} else {
		console.log(`No allUserIDs object`);
	}
	*/
	let endPoint = "stats?type=userGeneral&userId="+userID
	const response = await Promise.resolve(
		fetch(url + endPoint, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[getUserStatistics] Error in API call: " + error);
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {				
				//console.log("Data from getUserStatistics: ",{data});				
				data.lastUpdated = moment()
				userStatistics[userID] = data
				storageService.setObject("user-statistics",userStatistics)			
			return data;
		})
		.catch((error) => {
			//console.log("[getUserStatistics] Error in JSON conversion: " + error);
			throw Error(error);
		});
}

export const getAvatarFromUserID = async (userID) => {
	let user = JSON.parse(await getUser(userID))
	let avatar = ""
	if (user.avatar === undefined || user.avatar === "" || user.avatar === null){
		avatar = emptyAvatar
	} else {
		avatar = user.avatar
	}
	return avatar
}

/**
 * 
 * Returns SquadID of the squad the userID and the logged in user share
 * 
 * @param {*} userID 
 * @returns 
 */

export const sameSquadAndAdmin = async (userID) => {
	let squadIDsSameAndAdmin = []
	let inSameSquadIDs = await areUsersInSameSquad(userID, null); // returns an array of squad IDs
	if (inSameSquadIDs !== []) {
		for (let squadID of inSameSquadIDs) {
			let userIsAdmin = await isUserASquadAdmin(squadID);
			if (userIsAdmin === true){
				squadIDsSameAndAdmin.push(squadID)
			}
		}
	}
	return squadIDsSameAndAdmin;
};

export const isUserFacilitator = async (userID = null) => {
	let user = JSON.parse(await getUser(userID))
	if (user.userType === "facilitator"){
		return true
	} else {
		return false
	}
}

export const getUserEmail = async () => {
	let user = JSON.parse(await getUser())
	return user.email
}

export const forceAddUserToSquad = async (userID, squadID) =>{
	try {
		let userName = await getUserNameFromUserID(userID)
		let squadName = await getSquadNameFromSquadID(squadID)
		let text = `*New Squad Joined On Registration*\nUsername: ${userName}\nSquad Name: ${squadName}`
		sendSlackNotification(text,"keyEvent",false)
	} catch(e){
		sendSlackErrorNotification("*Error joining new squad on Registration*","forceAddUserToSquad",`UserID: ${userID}\nSquadID: ${squadID}`)
	}
	
	let url = PHP_URL_NAME+"fitsquad/addUserToSquad.php?userID="+userID+"&squadID="+squadID
	await getPHP(url)
}

export const getCognitoUsernameFromUsername = async (username) => {
	let getCognitoUserNameAPI = PHP_URL_NAME + "fitsquad/getCognitoUserName.php?email=" + username;
	let cognitoUserNameResult = await getPHP(getCognitoUserNameAPI);
	console.log({ cognitoUserNameResult });
	if (cognitoUserNameResult.result === 400) {
		return null
	}
	let cognitoUsername = cognitoUserNameResult.cognitoUserName;
	return cognitoUsername
}