/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useEffect } from "react";
import { IonContent, IonGrid, IonRow, IonCol, useIonViewDidEnter, IonAvatar, useIonViewWillEnter } from "@ionic/react";

import "../../css/BotRenderer.css";
import { ChatMessage, ChatMessageType } from "../../models/chat-message";
import Emoji from "react-emoji-render";

const BotMessageRenderer = ({ messages, botResponseMessages, userObject, setButtons }) => {
	const messagesEndRef = useRef(null);
	var waitTime = 1000; // just adding 1 incase it's nil

	useIonViewWillEnter(() => {
		if (messagesEndRef !== null && messages !== undefined && messages.length > 0) {
			scrollToBottom();
		}
	});

	useEffect(() => {
		if (messagesEndRef.current !== null && messages !== undefined && messages.length > 0) {
			setTimeout(() => {
				messagesEndRef.current.scrollIntoView({ behavior: "auto" });
			}, waitTime);
			//console.log("Scrolling to bottom (useEffect)");
		}
		//console.log("Current Messages Array (useEffect): "+JSON.stringify(messages));
	});

	const scrollToBottom = () => {
		if (messagesEndRef.current !== null && messages !== undefined && messages.length > 0) {
			setTimeout(() => {
				messagesEndRef.current.scrollIntoView({ behavior: "auto" });
			}, waitTime);
			//console.log("Scrolling to bottom (function)");
		}
	};

	if (messages !== null && messages !== undefined && messages.length > 0) {
		return (
			<IonContent className="chat-scroll">
				<div>
					<div>
						{messages.map((msg, index) => {
							let msgwidth = msg.msg.length * 13;
							const maxMessageWidth = 300;
							if (msgwidth > maxMessageWidth) {
								msgwidth = maxMessageWidth;
							}
							let randimg = Math.floor(Math.random() * 6 + 1);
							if (msg.msg.indexOf(".gif") > 0 || msg.msg.indexOf(".jpg") > 0 || msg.msg.indexOf(".png") > 0) {
								console.log("gif found in message");
								msg.imageUrl = msg.msg;
								msg.msg = "";
							}
							/*
							if(msg.buttons && index === (messages.length-1)){
								setButtons(msg.buttons)
							}
							*/
							return (
								<IonGrid className="message-container" key={`${index}`}>
									<IonRow>
										{msg.sender === ChatMessageType.User ? (
											<React.Fragment>
												{/** User message */}
												<IonCol size="1">{/** initial column left blank for user message */}</IonCol>
												<IonCol size="11">
													{msg.imageUrl !== undefined && msg.imageUrl !== null && msg.imageUrl !== "" ? (
														<div className="image-container">
															<div dangerouslySetInnerHTML={{ __html: msg.msg }}></div>
															<img src={msg.imageUrl}></img>
														</div>
													) : (
														<div className="user-message">
															{msg.msg.indexOf("<") >= 0 ? <div dangerouslySetInnerHTML={{ __html: msg.msg }}></div> : <Emoji text={msg.msg} />}
														</div>
													)}
												</IonCol>
											</React.Fragment>
										) : (
											<React.Fragment>
												{/** Bot message */}
												<IonCol size="2">
													<IonAvatar>
														<img src={`assets/images/cheerleader-0.jpg`} alt="" style={{ height: "50px", width: "50px" }} />
													</IonAvatar>
												</IonCol>
												<IonCol size="9">
													{/** Typing indicator */}
													{msg.msg === "is typing" ? (
														<div className="image-container">
															<img src="https://media.giphy.com/media/VeerK4hE9sjoB8e6OQ/giphy.gif" style={{ marginTop: "-15px", width: 75, height: 50 }}></img>
														</div>
													) : (
														<React.Fragment>
															{/** Actual message */}
															{/** Image */}
															{msg.imageUrl !== undefined && msg.imageUrl !== null && msg.imageUrl !== "" ? (
																<React.Fragment>
																	{msg.msg && msg.msg !== "" ? (
																		<div className="bot-message">
																			{msg.msg.indexOf("<") >= 0 ? <div dangerouslySetInnerHTML={{ __html: msg.msg }}></div> : <Emoji text={msg.msg} />}
																		</div>
																	) : (
																		""
																	)}

																	<div className="image-container">
																		<img style={{ width: "100%" }} src={msg.imageUrl}></img>
																	</div>
																</React.Fragment>
															) : (
																<div className="bot-message">
																	{/** Text */}
																	{msg.msg.indexOf("<") > 0 ? <div dangerouslySetInnerHTML={{ __html: msg.msg }}></div> : <Emoji text={msg.msg} />}
																</div>
															)}
														</React.Fragment>
													)}
												</IonCol>
												<IonCol size="1">{/** End column left blank for bot messages */}</IonCol>
											</React.Fragment>
										)}
									</IonRow>
								</IonGrid>
							);
						})}
						<div ref={messagesEndRef} />
					</div>
				</div>
				{scrollToBottom()}
			</IonContent>
		);
	} else {
		console.log("Messages is undefined");
		return (
			<IonContent className="chat-scroll">
				<div>
					<div ref={messagesEndRef} />
				</div>
				{scrollToBottom()}
			</IonContent>
		);
	}
};

export default BotMessageRenderer;
