import React, { useEffect, useState } from "react";
import classes from "./BagExplode.module.css";
//import bagImg from "assets/images/bag/bag.png";
//import bagOpenImg from "assets/images/bag/bag_open.png";
import { useRef } from "react";
import gsap from "gsap";

function getRandomInt(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

const BagExplode = ({ data, winner, setShowCompletion }) => {
	useEffect(() => {
		startAction();
	}, []);
	// Bag before explode
	const bagClosed = useRef();

	// Bag after explode
	const bagOpened = useRef();

	// Helper text at the top of screen
	const helperText = useRef();

	// Equipment images array
	const equipment = useRef([]);

	const startAction = () => {
		// Start Animation on Bag Click

		console.log("ACTION START");

		// Calculate the explode animation area
		let explodeLeftOffset = (window.innerHeight * (window.innerHeight > window.innerWidth ? 1 : 1.3) - window.innerWidth) / 2;
		let explodeRightOffset = explodeLeftOffset + window.innerWidth;

		// expd is the timeline object of the animation created from GSAP
		// It will call handleActionComplate function on animation complete
		var expd = gsap.timeline({ onComplete: handleActionComplete });
		expd.to(helperText.current, { duration: 0.5, opacity: 0 });
		expd.set(helperText.current, { display: "none" });
		expd.to(bagClosed.current, { duration: 0.5, ease: "back.out", scale: 1.1 }, 0.8);
		expd.set(bagOpened.current, { scale: 1.1 }, 0.6);
		expd.to(bagClosed.current, {
			duration: 0.03,
			x: "+=8",
			yoyo: true,
			repeat: 6,
		});
		expd.to(bagClosed.current, {
			duration: 0.03,
			x: "-=8",
			yoyo: true,
			repeat: 6,
		});
		expd.to(bagClosed.current, {
			duration: 0.03,
			x: "+=14",
			yoyo: true,
			repeat: 6,
		});
		expd.to(bagClosed.current, {
			duration: 0.03,
			x: "-=14",
			yoyo: true,
			repeat: 6,
		});
		expd.to(bagClosed.current, {
			duration: 0.03,
			x: "+=20",
			yoyo: true,
			repeat: 6,
		});
		expd.to(bagClosed.current, {
			duration: 0.03,
			x: "-=20",
			yoyo: true,
			repeat: 6,
		});
		expd.set(bagClosed.current, { display: "none" });
		expd.set(bagOpened.current, { display: "block" });
		expd.set(equipment.current, {
			bottom: "25%",
			left: "50%",
			xPercent: -50,
			yPercent: 50,
		});
		expd.to(bagOpened.current, {
			duration: 0.00001,
			opacity: 1,
		});

		// This is the erupt animation for the images. useRef array is used.
		// You can control the speed using 'duration' key
		// You can control easing function using 'ease' key
		equipment.current.forEach((element, index) => {
			expd.to(
				element,
				{
					duration: 3,
					ease: "steps (12)",
					bottom: `${getRandomInt(100, 130)}%`,
					left: `${getRandomInt(
						((explodeRightOffset + explodeLeftOffset) / (equipment.current.length - 1)) * (index - 0.1) - explodeLeftOffset,
						((explodeRightOffset + explodeLeftOffset) / (equipment.current.length - 1)) * (index + 0.1) - explodeLeftOffset
					)}px`,
					yPercent: -50,
					rotateZ: getRandomInt(-450, 450),
				},
				"<"
			);
		});
		expd.to(
			bagOpened.current,
			{
				duration: 1.2,
				rotateZ: 18,
				ease: "elastic.out(1, 0.3)",
				transformOrigin: "15px 66px",
			},
			"<"
		);
		expd.to(
			bagOpened.current,
			{
				duration: 0.2,
				ease: "none",
				top: "100%",
				yPercent: 100,
			},
			"-=2"
		);
		expd.set(equipment.current[winner], {
			bottom: "100%",
			left: "50%",
			xPercent: -50,
			yPercent: -50,
		});
		expd.to(equipment.current[winner], { duration: 1, bottom: "70%", yPercent: 50, ease: "bounce.out" });
		expd.to(
			equipment.current[winner],
			{
				duration: 0.3,
				ease: "none",
				rotateZ: 0,
			},
			"+=0.5"
		);
		expd.to(
			equipment.current[winner],
			{
				duration: 0.6,
				scale: 1.5,
				ease: "back.out(3)",
			},
			"-=0.1"
		);
	};

	const handleActionComplete = () => {
		setTimeout(() => {
			setShowCompletion(true);
		}, 500);
	};

	return (
		<React.Fragment>
			<div className={`${classes.explodeWrapper}`}>
				<div className={`${classes.explodeBox}`}>
					<img ref={bagClosed} src="assets/images/bag/bag.png" className={`${classes.bagClosed}`} alt="bag" onClick={startAction} />
					<img ref={bagOpened} src="assets/images/bag/bag_open.png" className={`${classes.bagOpened}`} alt="bag" />
					{data.map((item, index) => (
						<img
							src={`${item.image}`}
							alt="img"
							className={`${classes.equipImg}`}
							key={index}
							ref={(element) => {
								equipment.current.push(element);
							}}
						/>
					))}
				</div>
			</div>
		</React.Fragment>
	);
};

export default BagExplode;
