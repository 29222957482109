import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonContent,
	IonFooter,
	IonHeader,
	IonItem,
	IonLabel,
	IonLoading,
	IonPage,
	IonPopover,
	IonProgressBar,
	IonRadio,
	IonRadioGroup,
	IonToolbar,
	useIonViewWillEnter,
} from "@ionic/react";
import moment from "moment";
import React, { useState } from "react";
import { useHistory } from "react-router";
import "../../css/Home.css";
import "../../css/Onboarding.css";
import { logGenericEvent, logPageView } from "../../services/AnalyticsService";
import { createGoal, getGoal } from "../../services/PointsAndGoalsService";
import { autoAddUserToSquad, getUser } from "../../services/UserService";
import FirstGoalDescriptionComponent from "../FirstGoalDescriptionComponent";
import { StorageService } from "../../services/StorageService";
const storageService = new StorageService();

const SetGoal = ({ progress, setProgress, healthKitData, setGoalObject, squadToJoin }) => {
	const casualPercent = 0.5;
	const normalPercent = 1;
	const seriousPercent = 1.5;
	const beastPercent = 2.5;
	const defaultNormal = 225;
	const history = useHistory();
	const [goalSelected, setGoalSelected] = useState();
	const [showWhyModal, setShowWhyModal] = useState(false);
	const [settingGoal, setSettingGoal] = useState(false);
	const [goalValues, setGoalValues] = useState({
		casual: null,
		normal: null,
		serious: null,
		projected: null,
		beast: null,
	});
	useIonViewWillEnter(() => {
		logPageView("set goal");
		setProgress(0.8);
		storageService.setItem("lastOnboardingStep", 12);
		calcGoal();
	});

	const calcGoal = () => {
		let casual = null,
			normal = null,
			serious = null,
			beast = null,
			projected = null;
		let currentDate = moment();
		let startOfWeek = moment().startOf("isoWeek");
		let daysRemaining = 7 - (currentDate.diff(startOfWeek, "days") + 1);
		console.log("Days Remaining: " + daysRemaining);
		if (healthKitData.avgWeeklyPoints > 0) {
			setGoalSelected("projected");
			projected = healthKitData.avgWeeklyPoints;
			casual = Math.round((healthKitData.avgWeeklyPoints * casualPercent) / 10) * 10;
			serious = Math.round((healthKitData.avgWeeklyPoints * seriousPercent) / 10) * 10;
			beast = Math.round((healthKitData.avgWeeklyPoints * beastPercent) / 10) * 10;
		} else {
			normal = defaultNormal;
			casual = Math.round((normal * casualPercent) / 10) * 10;
			serious = Math.round((normal * seriousPercent) / 10) * 10;
			beast = Math.round((normal * beastPercent) / 10) * 10;
		}
		setGoalValues({
			casual: casual,
			normal: normal,
			serious: serious,
			projected: projected,
			beast: beast,
		});
	};

	const setInitialGoal = async () => {
		setSettingGoal(true);
		let user = JSON.parse(await getUser());
		let goal = goalValues[goalSelected];
		console.log("Goal: ", { user });
		let goalObject = await createGoal(goal, user.timezone);
		if (goalObject.message) {
			// only if a goal already exists
			goalObject = await getGoal();
		}
		logGenericEvent("goal selected");
		if (squadToJoin) {
			console.log({ squadToJoin });

			console.log(`Adding user ${user.cognitoId} to squad ${squadToJoin.id}`);
			await autoAddUserToSquad(user.cognitoId, squadToJoin.id, squadToJoin.shortCode)
				.catch((error) => {
					logGenericEvent("error autoadding user to squad");
					console.log("Error: ", { error });
					return;
				})
				.then((response) => {
					console.log("Response from adding user: ", { response });
					logGenericEvent("Joined squad " + squadToJoin.id);
				});
		}
		// console.log({ goalObject });
		setGoalObject(goalObject);
		setSettingGoal(false);
		history.push("/onboarding/initialbonus");
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar style={{ "--border-style": "none" }}>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
				</IonToolbar>
				<IonProgressBar value={progress} />
			</IonHeader>
			<IonContent>
				<IonPopover
					isOpen={showWhyModal}
					cssClass="exercise-details-full-screen-popover"
					onDidDismiss={(e) => {
						setShowWhyModal(false);
					}}
				>
					<FirstGoalDescriptionComponent setShowWhyModal={setShowWhyModal} />
				</IonPopover>
				<IonLoading isOpen={settingGoal} onDidDismiss={() => setSettingGoal(false)} message={"Setting up your goal"} backdropDismiss="true" />
				<div className="onboarding-main-div">
					<div className="onboarding-title">Now you just need your first weekly point goal</div>
					<div className="onboarding-hero-image-div" style={{ padding: "0px" }}>
						<img className="onboarding-hero-image" src="assets/images/set-goal-image.jpg" alt="" />
					</div>
					<div className="onboarding-text-area" style={{ textAlign: "center" }}>
						<p>
							Weeks start on Monday
							<br />
							and end Sunday at midnight.
						</p>
						<p>
							Choose from one of these <br />
							as your goal for your first week:
						</p>
						<span className="onboarding-help-text" onClick={() => setShowWhyModal(true)}>
							What's a good goal?
						</span>
						<IonRadioGroup value={goalSelected} onIonChange={(e) => setGoalSelected(e.detail.value)} mode="md">
							{goalValues.casual !== null ? (
								<IonItem className="onboarding-radio-item">
									<IonRadio className="onboarding-radio" value="casual" />
									<IonLabel className="onboarding-radio-label">Casual</IonLabel>
									<div style={{ marginTop: "-4px" }}>{goalValues.casual} points per week</div>
								</IonItem>
							) : (
								""
							)}
							{goalValues.normal !== null ? (
								<IonItem className="onboarding-radio-item">
									<IonRadio className="onboarding-radio" value="normal" />
									<IonLabel className="onboarding-radio-label">Normal</IonLabel>
									<div style={{ marginTop: "-4px" }}>{goalValues.normal} points per week</div>
								</IonItem>
							) : (
								""
							)}
							{goalValues.projected !== null ? (
								<React.Fragment>
									<IonItem className="onboarding-radio-item">
										<IonRadio className="onboarding-radio" value="projected" />
										<IonLabel className="onboarding-radio-label">Projected</IonLabel>
										<div style={{ marginTop: "-4px" }}>{goalValues.projected} points per week</div>
									</IonItem>
								</React.Fragment>
							) : (
								""
							)}
							{goalValues.serious !== null ? (
								<IonItem className="onboarding-radio-item">
									<IonRadio className="onboarding-radio" value="serious" />
									<IonLabel className="onboarding-radio-label">Serious</IonLabel>
									<div style={{ marginTop: "-4px" }}>{goalValues.serious} points per week</div>
								</IonItem>
							) : (
								""
							)}
							{goalValues.beast !== null ? (
								<IonItem className="onboarding-radio-item">
									<IonRadio className="onboarding-radio" value="beast" />
									<IonLabel className="onboarding-radio-label">Beast</IonLabel>
									<div style={{ marginTop: "-4px" }}>{goalValues.beast} points per week</div>
								</IonItem>
							) : (
								""
							)}
						</IonRadioGroup>
					</div>
				</div>
			</IonContent>
			<IonFooter className="onboarding-footer">
				{goalSelected ? (
					<div className="onboarding-footer">
						<IonButton onClick={() => setInitialGoal()} className="onboarding-advance-button">
							This will work
						</IonButton>
					</div>
				) : (
					""
				)}
			</IonFooter>
		</IonPage>
	);
};

export default SetGoal;
