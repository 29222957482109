import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonRow, IonLoading, IonCol, IonAvatar } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Emoji from "react-emoji-render";
import "../css/Home.css";
import "../css/SquadCompetitionScoreboard.css";
import { getSquad, getSquadGoal, getUsersInSquad } from "../services/SquadService";
import { getSquadPrediction } from "../services/PointsAndGoalsService";
import { getCompetitionByID } from "../services/CompetitionService";
//import moment from "moment";

const SquadCompetitionScoreboard = () => {
	const competitionID = useParams().competitionID;
	const [loading, setLoading] = useState(false);
	const [squadAGoalObject, setSquadAGoalObject] = useState();
	const [squadBGoalObject, setSquadBGoalObject] = useState();
	const [squadAObject, setSquadAObject] = useState();
	const [squadBObject, setSquadBObject] = useState();
	const [squadAMembers, setSquadAMembers] = useState();
	const [squadBMembers, setSquadBMembers] = useState();
	const [squadAHandicap, setsquadAHandicap] = useState();
	const [squadBHandicap, setsquadBHandicap] = useState();
	const [squadATitles, setSquadATitles] = useState({
		mvpID: null,
		fireID: null,
		fastID: null,
	});
	const [squadBTitles, setSquadBTitles] = useState({
		mvpID: null,
		fireID: null,
		fastID: null,
	});

	//const hoursInAWeek = 168;
	//let remainingSquadPoints;

	useEffect(() => {
		loadSquadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const loadSquadData = async () => {
		let competitionObject = await getCompetitionByID(competitionID);
		console.log("competitionObject: ", { competitionObject });
		// Setup Squad A
		setSquadAObject(await getSquad(competitionObject.squadIdA));
		let squadAGoal = await getSquadGoal(competitionObject.squadIdA);

		// Setup Squad B
		setSquadBObject(await getSquad(competitionObject.squadIdB));
		let squadBGoal = await getSquadGoal(competitionObject.squadIdB);

		setLoading(true);

		//let timeRemaining = moment.duration(moment(squadAGoal.endDate).diff(moment(Date.now())));
		//let hoursElapsed = hoursInAWeek - timeRemaining.asHours();
		//Setup Squad A Members
		let squadAMembers = await getUsersInSquad(competitionObject.squadIdA, true, true);
		setSquadAMembers(squadAMembers);
		console.log("squad A Members: ", { squadAMembers });
		squadAMembers.sort((a, b) => (a.goalPoints > b.goalPoints ? -1 : 1));
		let mvpA = squadAMembers[0].id;
		squadAMembers.sort((a, b) => (a.user.goalStreak > b.user.goalStreak ? -1 : 1));
		let fireA = squadAMembers[0].id;
		squadAMembers.sort((a, b) => (a.goalPrediction > b.goalPrediction ? -1 : 1));
		let fastA = squadAMembers[0].id;
		setSquadATitles({
			mvpID: mvpA,
			fireID: fireA,
			fastID: fastA,
		});

		//Setup Squad A Members
		let squadBMembers = await getUsersInSquad(competitionObject.squadIdB, true, true);
		setSquadBMembers(squadBMembers);
		console.log("squad B Members: ", { squadBMembers });
		squadBMembers.sort((a, b) => (a.goalPoints > b.goalPoints ? -1 : 1));
		let mvpB = squadBMembers[0].id;
		squadBMembers.sort((a, b) => (a.user.goalStreak > b.user.goalStreak ? -1 : 1));
		let fireB = squadBMembers[0].id;
		squadBMembers.sort((a, b) => (a.goalPrediction > b.goalPrediction ? -1 : 1));
		let fastB = squadBMembers[0].id;
		setSquadBTitles({
			mvpID: mvpB,
			fireID: fireB,
			fastID: fastB,
		});
		if (!squadAGoal.prediction) {
			squadAGoal.prediction = await getSquadPrediction(competitionObject.squadIdA);
		}
		if (!squadBGoal.prediction) {
			squadBGoal.prediction = await getSquadPrediction(competitionObject.squadIdB);
		}
		// Setup Handicap
		squadAGoal.goalProgress = Math.round(squadAGoal.goalProgress * competitionObject.squadAHandicap);
		squadAGoal.squadAPredictionPoints = Math.round(squadAGoal.prediction * competitionObject.squadAHandicap);
		squadBGoal.goalProgress = Math.round(squadBGoal.goalProgress * competitionObject.squadBHandicap);
		squadBGoal.squadBPredictionPoints = Math.round(squadBGoal.prediction * competitionObject.squadBHandicap);
		setSquadAGoalObject(squadAGoal);
		setSquadBGoalObject(squadBGoal);
		setsquadAHandicap(competitionObject.squadAHandicap);
		setsquadBHandicap(competitionObject.squadBHandicap);
		setLoading(false);
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton defaultHref="/home" text="" color="light" />
					</IonButtons>
					<IonTitle>The Matchup</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonLoading isOpen={loading} onDidDismiss={() => setLoading(false)} message={"Loading some amazing people"} duration={10000} />

			<div id="top-part" className="competition-scoreboard-header">
				<div className="competition-squad-data-holder">
					<IonRow style={{ marginLeft: "20px" }}>
						{squadAObject ? (
							<IonCol className="squad-image-holder" size="4">
								{squadAGoalObject && squadBGoalObject && squadAGoalObject.goalProgress > squadBGoalObject.goalProgress ? (
									<span className="leading-team">In the lead</span>
								) : (
									<br />
								)}
								{squadAGoalObject && squadBGoalObject && squadAGoalObject.squadAPredictionPoints > squadBGoalObject.squadBPredictionPoints ? (
									<span className="leading-team">Proj. winner</span>
								) : (
									<br />
								)}
								<IonAvatar className="squad-image">
									<img src={squadAObject.image} alt="" />
								</IonAvatar>
								<span className="competition-squad-name">{squadAObject.name}</span>
								<div className="competition-squad-slogan">"{squadAObject.slogan}"</div>
							</IonCol>
						) : (
							""
						)}
						<IonCol className="vs-image-holder" size="3">
							<img src="assets/images/vs.png" alt="" />
						</IonCol>
						{squadBObject ? (
							<IonCol className="squad-image-holder" size="4">
								{squadAGoalObject && squadBGoalObject && squadBGoalObject.goalProgress > squadAGoalObject.goalProgress ? (
									<span className="leading-team">In the lead</span>
								) : (
									<br />
								)}
								{squadAGoalObject && squadBGoalObject && squadBGoalObject.squadBPredictionPoints > squadAGoalObject.squadAPredictionPoints ? (
									<span className="leading-team">Proj. winner</span>
								) : (
									<br />
								)}
								<IonAvatar className="squad-image">
									<img src={squadBObject.image} alt="" />
								</IonAvatar>
								<span className="competition-squad-name">{squadBObject.name}</span>
								<div className="competition-squad-slogan">"{squadBObject.slogan}"</div>
							</IonCol>
						) : (
							""
						)}
					</IonRow>
				</div>
			</div>
			<IonContent>
				<div>
					<div id="numbers-part" className="competition-goal-holder">
						<IonRow style={{ marginLeft: "20px", textAlign: "center" }}>
							<span className="competition-goals">Current Score</span>
						</IonRow>
						<IonRow>
							{squadAGoalObject ? (
								<IonCol className="points-border" size="6">
									{squadAGoalObject.goalProgress.toLocaleString()}
									{squadAHandicap && squadAHandicap > 1 ? <span className="handicap"> (x{squadAHandicap})</span> : ""}
								</IonCol>
							) : (
								""
							)}
							{squadBGoalObject ? (
								<IonCol className="points-border" size="6">
									{squadBGoalObject.goalProgress.toLocaleString()}
									{squadBHandicap && squadBHandicap > 1 ? <span className="handicap"> (x{squadBHandicap})</span> : ""}
								</IonCol>
							) : (
								""
							)}
						</IonRow>
					</div>
					<div id="numbers-part" className="competition-goal-holder">
						<IonRow style={{ marginLeft: "20px", textAlign: "center" }}>
							<span className="competition-goals">Predicted Totals</span>
						</IonRow>
						<IonRow>
							{squadAGoalObject ? (
								<IonCol className="points-border" size="6">
									{squadAGoalObject.squadAPredictionPoints.toLocaleString()}
								</IonCol>
							) : (
								""
							)}
							{squadBGoalObject ? (
								<IonCol className="points-border" size="6">
									{squadBGoalObject.squadBPredictionPoints.toLocaleString()}
								</IonCol>
							) : (
								""
							)}
						</IonRow>
					</div>
					<div className="competition-squad-team-holder">
						<div className="left-column-teammates">
							{squadAMembers &&
								squadATitles &&
								squadAMembers.map((member, i) => {
									return (
										<IonCol className="squad-a-image-holder" size="4" key={`${i}`}>
											{squadATitles.mvpID && squadATitles.mvpID === member.id ? (
												<div className="projected-mvp">
													<Emoji text=":trophy: Proj. MVP" />
												</div>
											) : (
												<div></div>
											)}
											{squadATitles.fireID && squadATitles.fireID === member.id ? (
												<div className="most-on-fire">
													<Emoji text=":fire: Most On Fire" />
												</div>
											) : (
												<div></div>
											)}
											{squadATitles.fastID && squadATitles.fastID === member.id ? (
												<div className="fastest-start">
													<Emoji text=":dash: Fastest Start" />
												</div>
											) : (
												<div></div>
											)}
											<IonAvatar className="member-image">
												<img className="user-avatar" src={member.user.avatar} alt="" />
											</IonAvatar>
											<span className="competition-squad-name">{member.user.userName}</span>
											<br />
											<span className="competition-squad-name">
												{member.goalProgress}/{member.goalPoints}
												<br /> {Math.round((member.goalProgress / member.goalPoints) * 100)}%{" "}
											</span>
											<br />
											{member.goalPrediction > member.goalPoints ? <span className="progress-tag">on track</span> : ""}
											<br />
										</IonCol>
									);
								})}
						</div>
						<div className="right-column-teammates">
							{squadBMembers &&
								squadBTitles &&
								squadBMembers.map((member, i) => {
									return (
										<IonCol className="squad-b-image-holder" size="4" key={`${i}`}>
											{squadBTitles.mvpID && squadBTitles.mvpID === member.id ? (
												<div className="projected-mvp">
													<Emoji text=":trophy: Proj. MVP" />
												</div>
											) : (
												<div></div>
											)}
											{squadBTitles.fireID && squadBTitles.fireID === member.id ? (
												<div className="most-on-fire">
													<Emoji text=":fire: Most On Fire" />
												</div>
											) : (
												<div></div>
											)}
											{squadBTitles.fastID && squadBTitles.fastID === member.id ? (
												<div className="fastest-start">
													<Emoji text=":dash: Fastest Start" />
												</div>
											) : (
												<div></div>
											)}
											<IonAvatar className="member-image">
												<img className="user-avatar" src={member.user.avatar} alt="" />
											</IonAvatar>
											<span className="competition-squad-name">{member.user.userName}</span>
											<br />
											<span className="competition-squad-name">
												{member.goalProgress}/{member.goalPoints}
												<br /> {Math.round((member.goalProgress / member.goalPoints) * 100)}%{" "}
											</span>
											<br />
											{member.goalPrediction > member.goalPoints ? <span className="progress-tag">on track</span> : ""}
											<br />
										</IonCol>
									);
								})}
						</div>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default SquadCompetitionScoreboard;
