import {
	IonAvatar,
	IonBackButton,
	IonButton,
	IonButtons,
	IonCol,
	IonContent,
	IonFooter,
	IonGrid,
	IonHeader,
	IonIcon,
	IonLoading,
	IonPage,
	IonRow,
	IonSkeletonText,
	IonTitle,
	IonToolbar,
	useIonViewDidEnter,
	useIonViewDidLeave,
	useIonViewWillEnter,
} from "@ionic/react";
import { syncOutline } from "ionicons/icons";
import moment from "moment";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import ChatInput from "./ChatInput";
import GifSelector from "./ChatInput-gifSelector";
import CoachMarkController from "../CoachMarks/CoachMarkController";
import FeedRenderer from "./FeedRenderer";
import SquadProgressComponentMini from "./SquadProgressComponent-mini";
import "../../css/ChatContainer.css";
import { logPageView } from "../../services/AnalyticsService";
import { getActivities, getChatRooms, getLatestMessages, getMessagesAndActivities } from "../../services/ChatService";
import { getCompetitionSquadMembers, getSquadMembers } from "../../services/SquadService";
import { StorageService } from "../../services/StorageService";

const ChatRoom = () => {
	const { roomID } = useParams();
	const [messageFeed, setMessageFeed] = useState([]);
	const [activityFeed, setActivityFeed] = useState([]);
	const storageService = new StorageService();
	const [dayOfTheWeek] = useState(moment().format("ddd"));
	const [feed, setFeed] = useState([]);
	const [scroller, setScroller] = useState(true); // used to simply trigger a rerender without more info
	const [squadMembers, setSquadMembers] = useState([]);
	const [chatRoomObj, setChatRoomObject] = useState();
	const [refresher, setRefresher] = useState(0);
	const [skeletonText, setSkeletonText] = useState(false);
	const [gifSelectorOpen, setGifSelectorOpen] = useState(false);
	const [componentRefresh, setTriggerRefresh] = useState();
	const [refreshValue, setRefreshValue] = useState(false);
	const [textValue, setTextValue] = useState("");
	//const [showLoading, setShowLoading] = useState(true);

	let interval;
	const timerInterval = 10000;
	const history = useHistory();
	let chatRoomObject;

	useIonViewDidEnter(async () => {
		logPageView("squad-chatroom");

		//console.log(`RoomID ${roomID}`);
		chatRoomObject = await storageService.getObject(roomID);
		if (chatRoomObject === null) {
			if (roomID) {
				// if a roomID was passed, go ahead and fetch all chat rooms
				console.log("No chat room stored locally, trying to fetch");
				let chatRooms = await getChatRooms(true);
				console.log("chatRooms found: ", chatRooms);
				for (let chatRoom of chatRooms) {
					chatRoom.roomPage = "/chatroom/" + chatRoom.id;
					storageService.setObject(chatRoom.id, chatRoom);
					if (chatRoom.id === roomID) {
						chatRoomObject = chatRoom;
					}
				}
				console.log("chatRoomObject found: ", chatRoomObject);
				storageService.setObject(chatRoomObject.id, chatRoomObject);
			} else {
				console.log("No Room ID and nothing stored.. shouldnt be here");
				history.push("/chatinterface");
			}
		}
		if (chatRoomObject) {
			//console.log({ chatRoomObject });
			setChatRoomObject(chatRoomObject);
			getChatRoomMembers(chatRoomObject);
			let localFeed = await storageService.getObject("feed-" + chatRoomObject.id); // this will return back an empty array OR null/undefined
			if (localFeed && localFeed.length > 0) {
				console.log("Local feed found");
				setFeed(localFeed);
			} else {
				localFeed = null;
				console.log("No local feed found, pulling");
			}
			setSkeletonText(true);
			/*
			// First render what's stored locally because it's faster...
			let localFeed = await storageService.getObject("feed-" + chatRoomObject.id);
			let localActivityFeed = await storageService.getObject("feed-" + chatRoomObject.id);
			let localMessageFeed = await storageService.getObject("messages-" + chatRoomObject.id);
			if (localFeed) {
				setMessageFeed(localMessageFeed);
				setActivityFeed(localActivityFeed);
			} else {
				console.log("No local feed found, pulling");
			}
			setSkeletonText(true);

			// Then get the new stuff
			getLatestMessages(chatRoomObject).then(async (tempMessagesFeed) => {
				if (tempMessagesFeed !== null) {
					setMessageFeed(await storageService.getObject("messages-" + chatRoomObject.id));
					setRefresher(Date.now());
					setSkeletonText(false);
				} else {
					console.log("Temp messages feed was empty, no need to refresh");
				}
			});

			getActivities(chatRoomObject).then((tempFeed) => {
				console.log("TempFeed: ", { tempFeed });
				if (tempFeed !== null) {
					setActivityFeed(tempFeed);
					setRefresher(Date.now());
					setSkeletonText(false);
				} else {
					console.log("Temp feed was empty, no need to refresh");
				}
				//setShowLoading(false);
			});

			interval = setInterval(() => {
				console.log("Timer pull triggered");
				getLatestMessages(chatRoomObject).then(async (timerPull) => {
					if (timerPull !== null) {
						if ((await storageService.getItem("acceptTimerPull-messages")) !== "false") {
							setMessageFeed(await storageService.getObject("messages-" + chatRoomObject.id));
							setRefresher(Date.now());
							setSkeletonText(false);
						} else {
							console.log("acceptTimerPull-messages = false. Throwing away result from timer pull");
						}
					} else {
						console.log("Temp feed was empty, no need to refresh");
					}
					console.log("Timer pull end");
				});
				getActivities(chatRoomObject).then(async (timerPull) => {
					if (timerPull !== null) {
						//console.log("timerPull: " + JSON.stringify(timerPull));
						if ((await storageService.getItem("acceptTimerPull-activities")) !== "false") {
							setActivityFeed(timerPull);
							setRefresher(Date.now());
							setSkeletonText(false);
						} else {
							console.log("acceptTimerPull-activities = false. Throwing away result from timer pull");
						}
					} else {
						console.log("Temp feed was empty, no need to refresh");
					}
					console.log("Timer pull end");
				});
			}, timerInterval);
*/

			getMessagesAndActivities(chatRoomObject).then((tempFeed) => {
				//tempFeed = null;
				console.log("TempFeed: ", { tempFeed });
				if (tempFeed !== null && tempFeed.length > 0) {
					setFeed(tempFeed);
					setRefresher(Date.now());
				} else if ((tempFeed === null || tempFeed.length === 0) && !localFeed) {
					console.log("No temp feed AND no local feed = new chatroom");
					setFeed(null);
				} else {
					console.log("Local feed exists but temp feed was empty, no need to refresh");
				}
				setSkeletonText(false);
			});
			if (chatRoomObject.type === "competition") {
				console.log("Competition Chat Room");
			} else {
			}

			interval = setInterval(() => {
				console.log("Timer pull triggered");
				getMessagesAndActivities(chatRoomObject).then((timerPull) => {
					if (timerPull !== null) {
						//console.log("timerPull: " + JSON.stringify(timerPull));
						if (storageService.getItem("acceptTimerPull") === "true") {
							setFeed(timerPull);
							setRefresher(Date.now());
							setSkeletonText(false);
						} else {
							console.log("acceptTimerPull = false. Throwing away result from timer pull");
						}
					} else {
						console.log("Temp feed was empty, no need to refresh");
					}
					console.log("Timer pull end");
				});
			}, timerInterval);
		}
		let newFeedFormat = await storageService.getItem("newFeedFormat");
		if (newFeedFormat === "") {
			console.log("No new feed format. Refreshing");
			await refresh(chatRoomObject);
		}
	});

	useIonViewDidLeave(() => {
		console.log("ionViewDidLeave event fired");
		//setShowLoading(false);
		clearInterval(interval);
	});

	const refresh = async (chatRoomO = null) => {
		//setShowLoading(true);
		await storageService.setItem("newFeedFormat", true);
		console.log({ chatRoomO });

		if (chatRoomO === null) {
			chatRoomO = chatRoomObj;
		}
		console.log({ chatRoomO });
		setFeed([]);
		console.log("ChatRoomIbject: " + JSON.stringify(chatRoomO));
		await storageService.removeItem("feed-" + roomID);
		await storageService.removeItem("lastSquadActivityPull-" + chatRoomO.squadIdA);
		await storageService.removeItem("squad-activities-" + chatRoomO.squadIdA);
		await storageService.removeItem("squadGoal-" + chatRoomO.squadIdA);
		setRefreshValue(true);
		setTriggerRefresh(Date.now());
		/*
		if (chatRoomObj.type === "competition") {
			await storageService.removeItem("lastSquadActivityPull-" + chatRoomO.squadIdB);
			await storageService.removeItem("squad-activities-" + chatRoomO.squadIdB);
		}
		*/
		await storageService.removeItem("lastMessagePull-" + roomID);
		await storageService.removeItem("messages-" + roomID);
		setSkeletonText(true);
		let tempFeed = await getMessagesAndActivities(chatRoomO);
		console.log("TempFeed: ", { tempFeed });
		if (tempFeed !== null && tempFeed.length > 0) {
			setFeed(tempFeed);
		} else {
			setFeed(null);
			console.log("Temp feed was empty, no need to refresh");
		}
		setSkeletonText(false);
		//setShowLoading(false);
	};

	const getChatRoomMembers = async (chatRoomObject) => {
		//TODO: get members of the squad for avatar
		let squadArray = [];
		switch (chatRoomObject.type) {
			case "competition":
				squadArray = await getCompetitionSquadMembers(chatRoomObject);
				break;
			case "squad":
				squadArray = await getSquadMembers(chatRoomObject);
				break;
			case "friend":
				break;
			default:
				squadArray = await getSquadMembers(chatRoomObject);
				break;
		}
		console.log("SquadArray: ", { squadArray });
		let members = [];
		squadArray.forEach((element) => {
			members.push(element);
		});
		console.log("members: ", { members });
		setSquadMembers(members);
	};

	const navToSquadDetails = async (squadID) => {
		history.push("/squad/" + squadID);
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton text="" />
					</IonButtons>
					{chatRoomObj ? <IonTitle onClick={() => navToSquadDetails(chatRoomObj.squadIdA)}>{chatRoomObj.name}</IonTitle> : <IonTitle>Chat Room</IonTitle>}
					<IonButton slot="end" color="secondary" onClick={() => refresh()}>
						<IonIcon slot="icon-only" icon={syncOutline}></IonIcon>
					</IonButton>
				</IonToolbar>
				{dayOfTheWeek && chatRoomObj && (chatRoomObj.type === "squad" || chatRoomObj.type === undefined) ? (
					<SquadProgressComponentMini
						squadId={chatRoomObj.squadIdA}
						dow={dayOfTheWeek}
						showFlavorText={true}
						triggerRefresh={componentRefresh}
						refresh={refreshValue}
					/>
				) : (
					<React.Fragment>{console.log({ chatRoomObj })}</React.Fragment>
				)}
				<IonLoading //isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={"Loading..."} duration={5000}
				/>
			</IonHeader>
			<IonContent>
				{feed === null && skeletonText === false ? (
					<div className="no-message-yet">
						<p>No one in the squad has sent a message yet.</p>
						<p>Get things started by introducing yourself! 👋</p>
					</div>
				) : (
					""
				)}
				{chatRoomObj && feed && feed.length > 0 ? (
					<FeedRenderer
						feed={feed}
						setFeed={setFeed}
						chatRoomObj={chatRoomObj}
						setScroller={setScroller}
						scroller={scroller}
						refresher={refresher}
						roomType={chatRoomObj.type}
					/>
				) : (
					""
				)}
				{skeletonText === true ? (
					<IonGrid className="message-container">
						<IonRow>
							<IonCol size="2">
								<IonAvatar>
									<IonSkeletonText animated />
								</IonAvatar>
							</IonCol>
							<IonCol size="10">
								<IonGrid>
									<IonRow>
										<IonCol size="12">
											<IonSkeletonText animated />
											<span className="date">
												<IonSkeletonText animated />
											</span>
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol size="12">
											<IonSkeletonText animated />
										</IonCol>
									</IonRow>
								</IonGrid>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size="2">
								<IonAvatar>
									<IonSkeletonText animated />
								</IonAvatar>
							</IonCol>
							<IonCol size="10">
								<IonGrid>
									<IonRow>
										<IonCol size="12">
											<IonSkeletonText animated />
											<span className="date">
												<IonSkeletonText animated />
											</span>
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol size="12">
											<IonSkeletonText animated />
										</IonCol>
									</IonRow>
								</IonGrid>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size="2">
								<IonAvatar>
									<IonSkeletonText animated />
								</IonAvatar>
							</IonCol>
							<IonCol size="10">
								<IonGrid>
									<IonRow>
										<IonCol size="12">
											<IonSkeletonText animated />
											<span className="date">
												<IonSkeletonText animated />
											</span>
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol size="12">
											<IonSkeletonText animated />
										</IonCol>
									</IonRow>
								</IonGrid>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size="2">
								<IonAvatar>
									<IonSkeletonText animated />
								</IonAvatar>
							</IonCol>
							<IonCol size="10">
								<IonGrid>
									<IonRow>
										<IonCol size="12">
											<IonSkeletonText animated />
											<span className="date">
												<IonSkeletonText animated />
											</span>
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol size="12">
											<IonSkeletonText animated />
										</IonCol>
									</IonRow>
								</IonGrid>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size="2">
								<IonAvatar>
									<IonSkeletonText animated />
								</IonAvatar>
							</IonCol>
							<IonCol size="10">
								<IonGrid>
									<IonRow>
										<IonCol size="12">
											<IonSkeletonText animated />
											<span className="date">
												<IonSkeletonText animated />
											</span>
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol size="12">
											<IonSkeletonText animated />
										</IonCol>
									</IonRow>
								</IonGrid>
							</IonCol>
						</IonRow>
					</IonGrid>
				) : (
					""
				)}
			</IonContent>
			<IonFooter>
				{gifSelectorOpen === true ? (
					<GifSelector setGifSelectorOpen={setGifSelectorOpen} setFeed={setFeed} feed={feed} chatRoomObj={chatRoomObj} setScroller={setScroller} />
				) : (
					<ChatInput
						setGifSelectorOpen={setGifSelectorOpen}
						setFeed={setFeed}
						feed={feed}
						chatRoomObj={chatRoomObj}
						setScroller={setScroller}
						textValue={textValue}
						setTextValue={setTextValue}
					/>
				)}
			</IonFooter>
		</IonPage>
	);
};

export default ChatRoom;
