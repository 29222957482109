import { API_URL, excludedActivities, PHP_URL_NAME, systemActivities } from "../models/constants";
import { getIDToken } from "./AuthService";
import { StorageService } from "../services/StorageService";
//import moment from "moment";
import { getActivityImage, getLatestSquadActivities, postActivityImage } from "./ActivitiesService";
import { convertExerciseActivityToText, convertSystemActivityToTextSquad, getSystemActivitiesLink } from "./HelperServices";
import { getAvatarFromUserID, getUser, getUserNameFromUserID,getUserNameOfLoggedInUser } from "./UserService";
import { getAllUserSquadIDs, getSquad } from "./SquadService";
import { shouldRefresh } from "./RefreshService";
import { emptyAvatar } from "../models/constants"
import AuthContext from "../contexts/GlobalState";
import { useContext } from "react";
import { sendSlackNotification } from "./NotificationService";
import { logReactionGiven } from "./FeedService";
import { getRandomGifFromSearch } from "./GifService";
const storageService = new StorageService();
var moment = require("moment-timezone");


const buffer = 10 // buffer in seconds for pulling prior data

export const getMessages = async (chatRoomID, limit = 25, offset = 0, updatedAtMoment = null) => {
	let url;
	let idToken = await getIDToken().catch((error) => {
		throw Error("[getMessages] Error in getIDtoken: " + error);
	});
	if (updatedAtMoment !== null) {
		// if a date is passed in, use that instead of limit/offset
		//console.log("Fetching messages since: " + updatedAtMoment.format());
		url = API_URL + "chat-rooms/" + chatRoomID + "?updatedAt=" + updatedAtMoment.format();
	} else {		
		url = API_URL + "chat-rooms/" + chatRoomID + "?limit=" + limit + "&offset=" + offset;
		//console.log("Fetching messages with limit: " + limit + ", offset: " + offset+". URL: "+url);
	}
	const response = await Promise.resolve(
		fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[JS][getMessages] Error in API call: " + error);
		throw error(error)
	});

	return response
		.json()
		.then((data) => {
			//console.log("[JS] getMessages response:", data);
			return data;
		})
		.catch((error) => {
			console.log("[JS][getMessages] Error in JSON conversion: " + error);
			return ""
		});
};

export const postChatRoomMessage = async (chatRoomID, message) => {
	const url = API_URL;
	const data = {
		message: message,
	};
	let idToken = await getIDToken().catch((error) => {
		throw Error("[postMessage] Error in getIDtoken: " + error);
	});
	const response = await Promise.resolve(
		fetch(url + "chat-rooms/" + chatRoomID, {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[JS][postMessage] Error in API call: " + error);
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {
			console.log("[JS] postMessage response:", data);
			return data;
		})
		.catch((error) => {
			console.log("[JS][postMessage] Error in JSON conversion: " + error);
			throw Error(error);
		});
};

export const postChatRoomImage = async (chatRoomID, dataURL) => {
	const url = API_URL;
	const data = {
		message: "sent an image",
		type: "image",
		payload: {
			imageData: dataURL
		}
	};
	let idToken = await getIDToken().catch((error) => {
		throw Error("[postMessage] Error in getIDtoken: " + error);
	});
	const response = await Promise.resolve(
		fetch(url + "chat-rooms/" + chatRoomID, {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[JS][postMessage] Error in API call: " + error);
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {
			console.log("[JS] postMessage response:", data);
			return data;
		})
		.catch((error) => {
			console.log("[JS][postMessage] Error in JSON conversion: " + error);
			throw Error(error);
		});
};

/**
 *
 * Returns all chat rooms that the user is authorized to participate in
 *
 */

export const getChatRooms = async (refresh = false, user = null) => {
	if (user === null){
		user = JSON.parse(await getUser(null,false))
	}
	let chatRoomsURL = "chat-rooms"
	if (user.userType === "facilitator") {
		chatRoomsURL = chatRoomsURL + "/?all=true"
	}
	const url = API_URL;
	let chatRoomsObject;
	let chatRoomsLastUpated;
	let ttl = 4320
	chatRoomsObject = await storageService.getObject("chatRooms");
	chatRoomsLastUpated = await storageService.getObject("chatRoomsLastUpdated"); // do this because chatRooms is multi-element array
	if (chatRoomsObject && chatRoomsObject !== "" && chatRoomsLastUpated) {
		chatRoomsObject.lastUpdated = chatRoomsLastUpated
		//console.log('Stored chatRoomsObject: '+JSON.stringify(chatRoomsObject))
		let update = await shouldRefresh(chatRoomsObject,ttl)		
		if (update === false && refresh === false){
			console.log(`Loading chatRooms from local storage`);			
			return chatRoomsObject
		} else {
			console.log(`Hitting chatRooms API because refresh=${refresh} & update=${update}`);
		}
	} else {
		console.log(`Hitting chatRooms API because chatRoomsObject = ${chatRoomsObject} & chatRoomsLastUpated = ${chatRoomsLastUpated}`);		
	}
	let idToken = await getIDToken().catch((error) => {
		throw Error("[getChatRooms] Error in getIDtoken: " + error);
	});
	const response = await Promise.resolve(
		fetch(url + chatRoomsURL, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[JS][getChatRooms] Error in API call: " + error);
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {
			storageService.setObject("chatRooms", data);	
			storageService.setObject("chatRoomsLastUpdated", moment());	
			return data;
		})
		.catch((error) => {
			console.log("[JS][getChatRooms] Error in JSON conversion: " + error);
			throw Error(error);
		});
};

/**
 *
 * Returns all chat rooms that the user is authorized to participate in
 *
 */

 export const getAllChatRooms = async (refresh = false, onlyAccepted = true) => {
	let data = {
		squads: await getAllUserSquadIDs(null,onlyAccepted)	
	}		
	// console.log(JSON.stringify(data));
	const url = PHP_URL_NAME;
	let chatRoomsURL = "fitsquad/getChatRooms.php"
	const response = await Promise.resolve(
		fetch(url + chatRoomsURL, {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
			},
		})
	).catch((error) => {
		console.log("[JS][getChatRooms] Error in API call: " + error);
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {
			storageService.setObject("chatRooms", data);	
			storageService.setObject("chatRoomsLastUpdated", moment());	
			return data;
		})
		.catch((error) => {
			console.log("[JS][getChatRooms] Error in JSON conversion: " + error);
			throw Error(error);
		});
};

/**
 *
 * Returns latest messages for a chat room. If there are none, returns null
 *
 * Logic:
 * Looks for the lastMessagePullTS field.
 * If it exists AND the messages object exists for the chat room, get messages since lastMessagePullTS from server
 * 		If any of the messages returned already exist in local storage
 * 			skips those to remove dupes
 * 		Replaces local storage
 * If it the above is not true, gets the last numberOfMessagesToPull messages
 * 		Replaces local storage
 * Updates lastMessagePullTS
 *
 * @param {string} chatRoomID
 */

export const getLatestMessages = async (chatRoomObj) => {
	let numberOfMessagesToPull = 25;
	let messagesInLatestPull;
	const storageService = new StorageService();

	// Wild idea: instead of logging everytime it's pulled, just pull since the last message that's stored locally
	// If nothing is stored locally, get everything

	let lastMessagePullTS = await storageService.getItem("lastMessagePull-" + chatRoomObj.id); // get the last time something was pulled
	let tsPlusBuffer = moment(lastMessagePullTS).subtract(buffer, "seconds") // add a little buffer just in case
	let messageArray = await storageService.getObject("messages-" + chatRoomObj.id); // get what's already stored
	console.log("Last message pulled: " + lastMessagePullTS);
	if (lastMessagePullTS !== "" && lastMessagePullTS !== undefined && lastMessagePullTS !== null && messageArray) { // if there is a last time stamp and existing messages
		//console.log("Pulling messages since " + lastMessagePullTS);
		messagesInLatestPull = await getMessages(chatRoomObj.id, null, null, moment(tsPlusBuffer)).catch((error) => { // get any messages since last check
			messagesInLatestPull = ""
			console.error("Error in getting messages: ",{error})
		})
		// console.log({messagesInLatestPull});
		if (messagesInLatestPull === "" || messagesInLatestPull === "[]" || messagesInLatestPull.length === 0 || messagesInLatestPull.message === "User is not authorized to access this chat room") {
			//console.log("No messages returned");
			messagesInLatestPull = null;
		} else {
			//console.log("Latest Messages: " + JSON.stringify(messagesInLatestPull));
			messagesInLatestPull.sort((a, b) => (a.timeStamp > b.timeStamp ? 1 : -1));
			messagesInLatestPull.forEach((element) => {
				let filterResult = messageArray.filter((x) => x.id === element.id); // look for duplicate messages in storage
				//console.log("ID of latest message: " + element.id);
				//console.log("filterResult: " + JSON.stringify(filterResult));
				if (Object.keys(filterResult).length === 0) {
					//console.log("No message with ID = " + element.id + " found. Pushing");
					messageArray.push(element); // if no dupe found, add it to the array
				} else {
					console.log("Found dupe message in local storage: " + JSON.stringify(filterResult));
				}
			});
			storageService.setObject("messages-" + chatRoomObj.id, messageArray); // store the updated array
		}
	} else {
		console.log("No last message pull found. Pulling last " + numberOfMessagesToPull + " message");
		switch(chatRoomObj.type) {
			case "competition":
				messagesInLatestPull = await getMessages(chatRoomObj.id, numberOfMessagesToPull,null,moment(chatRoomObj.startDate)).catch((error) => {
					messagesInLatestPull = ""
					console.error("Error in getting messages: ",{error})
				})
				break;
			case "squad":
				messagesInLatestPull = await getMessages(chatRoomObj.id, numberOfMessagesToPull).catch((error) => {
					messagesInLatestPull = ""
					console.error("Error in getting messages: ",{error})
				})
				break;
			case "friend":
				break;
			default:
				messagesInLatestPull = await getMessages(chatRoomObj.id, numberOfMessagesToPull).catch((error) => {
					messagesInLatestPull = ""
					console.error("Error in getting messages: ",{error})
				})
				break;
		}
		if (messagesInLatestPull !== "" && messagesInLatestPull !== null && messagesInLatestPull !== undefined){
			try {
				messagesInLatestPull.sort((a, b) => (a.timeStamp > b.timeStamp ? 1 : -1));				
			} catch (error){
				console.log("Error sorting messages for chatroom "+ chatRoomObj.id);
				messagesInLatestPull = [];
			}
			storageService.setObject("messages-" + chatRoomObj.id, messagesInLatestPull);
		}		
	}
	await storageService.setItem("lastMessagePull-" + chatRoomObj.id, moment().format());
	//console.log("lastMessagePull set to: " + (await storageService.getItem("lastMessagePull-" + chatRoomObj.id)));
	return messagesInLatestPull;
};

export const getLatestMessagesV2 = async (chatRoomObj) => {
	let numberOfMessagesToPull = 25;
	let messagesInLatestPull;
	const storageService = new StorageService();

	// Wild idea: instead of logging everytime it's pulled, just pull since the last message that's stored locally
	// If nothing is stored locally, get everything
	// Why is chatRoomObj null sometimes??
	let messageArray = await storageService.getObject("messages-" + chatRoomObj.id); // get what's already stored
	if (messageArray !== null && messageArray.length > 0){
		let lastMessage = messageArray[messageArray.length - 1];
		messagesInLatestPull = await getMessages(chatRoomObj.id, null, null, moment(lastMessage.timeStamp).add(1, "second")).catch((error) => { // get any messages since last check
			messagesInLatestPull = ""
			console.error("Error in getting messages: ",{error})
		})
		console.log("Latest Messages since "+moment(lastMessage.timeStamp).format()+": " + JSON.stringify(messagesInLatestPull));
	} else {
		messagesInLatestPull = await getMessages(chatRoomObj.id, 1000, null, null).catch((error) => { // get any messages since last check
			messagesInLatestPull = ""
			console.error("Error in getting messages: ",{error})
		})
		console.log("Latest 1000 Messages: " + JSON.stringify(messagesInLatestPull));
	}
	if (messagesInLatestPull === null || messagesInLatestPull === "" || messagesInLatestPull === "[]" || messagesInLatestPull.length === 0 || messagesInLatestPull.message === "User is not authorized to access this chat room") {
		console.log("No messages returned");
		messagesInLatestPull = null;
	} else {		
		messagesInLatestPull.sort((a, b) => (a.timeStamp > b.timeStamp ? 1 : -1));
		messagesInLatestPull.forEach(async (element) => {
			let filterResult = null
			if(messageArray !== null){ // no messages stored
				filterResult = messageArray.filter((x) => x.id === element.id); // look for duplicate messages in storage
			}
			//console.log("ID of latest message: " + element.id);
			//console.log("filterResult: " + JSON.stringify(filterResult));
			if (filterResult === null || Object.keys(filterResult).length === 0) {
				//console.log("No message with ID = " + element.id + " found. Pushing");
				messageArray.push(element); // if no dupe found, add it to the array
			} else {
				console.log("Found dupe message in local storage: " + JSON.stringify(filterResult));
			}
		});
		await storageService.setObject("messages-" + chatRoomObj.id, messageArray); // store the updated array
	}
	



	// if (lastMessagePullTS !== "" && lastMessagePullTS !== undefined && lastMessagePullTS !== null && messageArray) { // if there is a last time stamp and existing messages
	// 	//console.log("Pulling messages since " + lastMessagePullTS);
		
	// 	console.log({messagesInLatestPull});
	// 	if (messagesInLatestPull === "" || messagesInLatestPull === "[]" || messagesInLatestPull.length === 0 || messagesInLatestPull.message === "User is not authorized to access this chat room") {
	// 		//console.log("No messages returned");
	// 		messagesInLatestPull = null;
	// 	} else {
	// 		//console.log("Latest Messages: " + JSON.stringify(messagesInLatestPull));
	// 		messagesInLatestPull.sort((a, b) => (a.timeStamp > b.timeStamp ? 1 : -1));
	// 		messagesInLatestPull.forEach((element) => {
	// 			let filterResult = messageArray.filter((x) => x.id === element.id); // look for duplicate messages in storage
	// 			//console.log("ID of latest message: " + element.id);
	// 			//console.log("filterResult: " + JSON.stringify(filterResult));
	// 			if (Object.keys(filterResult).length === 0) {
	// 				//console.log("No message with ID = " + element.id + " found. Pushing");
	// 				messageArray.push(element); // if no dupe found, add it to the array
	// 			} else {
	// 				console.log("Found dupe message in local storage: " + JSON.stringify(filterResult));
	// 			}
	// 		});
	// 		storageService.setObject("messages-" + chatRoomObj.id, messageArray); // store the updated array
	// 	}
	// } else {
	// 	console.log("No last message pull found. Pulling last " + numberOfMessagesToPull + " message");
	// 	switch(chatRoomObj.type) {
	// 		case "competition":
	// 			messagesInLatestPull = await getMessages(chatRoomObj.id, numberOfMessagesToPull,null,moment(chatRoomObj.startDate)).catch((error) => {
	// 				messagesInLatestPull = ""
	// 				console.error("Error in getting messages: ",{error})
	// 			})
	// 			break;
	// 		case "squad":
	// 			messagesInLatestPull = await getMessages(chatRoomObj.id, numberOfMessagesToPull).catch((error) => {
	// 				messagesInLatestPull = ""
	// 				console.error("Error in getting messages: ",{error})
	// 			})
	// 			break;
	// 		case "friend":
	// 			break;
	// 		default:
	// 			messagesInLatestPull = await getMessages(chatRoomObj.id, numberOfMessagesToPull).catch((error) => {
	// 				messagesInLatestPull = ""
	// 				console.error("Error in getting messages: ",{error})
	// 			})
	// 			break;
	// 	}
	// 	if (messagesInLatestPull !== "" && messagesInLatestPull !== null && messagesInLatestPull !== undefined){
	// 		try {
	// 			messagesInLatestPull.sort((a, b) => (a.timeStamp > b.timeStamp ? 1 : -1));				
	// 		} catch (error){
	// 			console.log("Error sorting messages for chatroom "+ chatRoomObj.id);
	// 			messagesInLatestPull = [];
	// 		}
	// 		storageService.setObject("messages-" + chatRoomObj.id, messagesInLatestPull);
	// 	}		
	// }
	// await storageService.setItem("lastMessagePull-" + chatRoomObj.id, moment().format());
	// //console.log("lastMessagePull set to: " + (await storageService.getItem("lastMessagePull-" + chatRoomObj.id)));
	// return messagesInLatestPull;
};

const convertMessagesToFeed = async (latestMessages, feedArray) => {
	for (let element of latestMessages) {
		let reactions = []

		if (element.reactionCount > 0){ // if something from server has reactions
			let originalReactionArray = element.reactions
			for(let reaction of originalReactionArray) { // parse ther servers' reaction object
				// look for reaction in reactions
				// if it's there increment the count. If not, add it
				let filterResult = reactions.filter((x) => x.emoji === reaction.type);
				if (Object.keys(filterResult).length === 0) {
					//console.log("No reaction of type "+reaction.type+" found. Adding");
					let reactionModel = {
						count: 1,
						emoji: reaction.type
					}
					reactions.push(reactionModel);
				} else {
					//console.log("Reaction "+reaction.type+" found. Incrementing Count" );
					for (var x = 0; x < reactions.length; x++) {
						if (reactions[x].emoji === reaction.type) {
							reactions[x].count++;
							//console.log("Count incremented");
						}
					}
					//console.log("Filter Result: "+JSON.stringify(filterResult));					
				}
			}
		}
		if (element.type === "image"){
			element.message = element.payload.imageUrl
		}
		let tempArray = {
			id: element.id,
			type: element.type,
			message: element.message,
			date: moment(element.timeStamp).format(),
			unixdate: moment(element.timeStamp).valueOf(),
			points: null,
			userName: element.user.userName,
			avatar: element.user.avatar,
			userID: element.author,
			duration: null,
			durationUnit: null,
			distance: null,
			distanceUnit: null,
			activityID: null,
			reactions: reactions,
			reactionCount: element["reactionCount"],
			link: null,
		};
		feedArray.push(tempArray);
	}
	//console.log("feedArray after messages: " + JSON.stringify(feedArray));
	return feedArray;
};

export const convertActivitiesToFeed = async (activities, feedArray, user = null) => {
	if (user === null){ // just a safe guard, should never happen
		user = JSON.parse(await getUser(null, false));
	}
	let avatar = emptyAvatar;
	let userName = "";
	
	//const storageService = new StorageService();
	if(activities !== null) {
		for (let element of activities) {
			//console.log("Elements in ConvertAct2F: ",{element});
			if (excludedActivities.includes(element.activityType)) {
				continue
			}
			let cssClass = "feed-activity"
			if (element.activityType === null){
				continue
			}
			let link = null;
			let displayText = "";
			let pointsText = ""
			let date = "";
			let bonusPointsText = ""
			if (element.userId === user.cognitoId) {
				//console.log("It's the current user");
				avatar = user.avatar;
				userName = user.userName;
			} else if(element.userId == null)  {// if userId = null then it's probably a squad event 
				let squadInfo = await getSquad(element.squadId)
				avatar = squadInfo.image
				userName = squadInfo.name
			} else {
				let otherUser = JSON.parse(await getUser(element.userId, false))
				//console.log("Other user: ",{otherUser});
				
				avatar = otherUser.avatar;
				userName = otherUser.userName; // this should eventually be cached
			}
			if (systemActivities.includes(element.activityType)) { // converts a system activity to readable text
				displayText = await convertSystemActivityToTextSquad(userName, element);
				if(element.activityType === "squadJoinAccepted"){
					avatar = await getAvatarFromUserID(element.activityData.userId)
				}
				switch(element.activityType) {
					case "squadGoalEvaluated":
						cssClass = "squadGoalEvaluated-css"
						break;
					case "competitionEvaluated":
						cssClass = "competitionEvaluated-css"
						break;
					case "newSquadCompetition":
						cssClass = "newSquadCompetition-css"
						break;
					case "newSquadGoal":
						cssClass = "newSquadGoal-css" 
						break;
					default:
						cssClass = "feed-activity"
						break;
				}
				link = "/"+getSystemActivitiesLink(element)
				date = moment(element.timeStamp).format();
			} else { // converts an exercise activity to readable text
				displayText = convertExerciseActivityToText(userName, element.activityType, element.activityData.value, element.activityData.valueUnit, element.points, element.activityData.dataType);			
				if (element && element.points) {
					pointsText = "+ "+element.points
				}
				if (element && element.bonusPoints && element.bonusPoints >0) {
					bonusPointsText = "+ "+element.bonusPoints
				}					
				link = "/exercisedetails/" + element.id;
				// Do this because added activities don't have same format :/
				if (element.activityData.endDate && element.activityData.timeZone) {
					date = moment(element.activityData.endDate).tz(element.activityData.timeZone).format();
				} else {
					date = moment(element.activityData.endDate).format();
				}
				switch(element.activityType) {
					case "dailySteps":
						date = element.activityData.startDate
						let tempDate = date.split('T')
						date = moment(tempDate[0]).format("YYYY-MM-DD");
						// console.log({date});
						//console.log("Daily Steps Object: "+JSON.stringify(element));						
						//date = moment(element.startDate).startOf('day').format(); // updated 12/24/21 to use startDate
						cssClass = "dailySteps-css" // use this to render it without time
						break;
					default:
						cssClass = "feed-activity"
						break;
				}
			}		
			let reactions = []
			// Reaction handler
			if (element.reactionCount > 0){ // if something from server has reactions
				let originalReactionArray = element.reactions
				for(let reaction of originalReactionArray) { // parse ther servers' reaction object
					// look for reaction in reactions
					// if it's there increment the count. If not, add it
					let filterResult = reactions.filter((x) => x.emoji === reaction.type);
					if (Object.keys(filterResult).length === 0) {
						let avatar = await getAvatarFromUserID(reaction.user)
						//console.log("No reaction of type "+reaction.type+" found. Adding");
						let reactionModel = {
							count: 1,
							emoji: reaction.type,
							user: reaction.user,
							avatar: avatar
						}
						reactions.push(reactionModel);
					} else {
						//console.log("Reaction "+reaction.type+" found. Incrementing Count" );
						for (var x = 0; x < reactions.length; x++) {
							if (reactions[x].emoji === reaction.type) {
								reactions[x].count++;
								console.log("Count incremented");
							}
						}
						//console.log("Filter Result: "+JSON.stringify(filterResult));					
					}
				}
			}
			// GIF handler
			// This now should not actually run because it's done in Activities Services
			// Leaving it hear juuuuuust in case
			let imageURL = null
			if(element["activityData"]["imageURL"]){ // if it' already there on the element, set the variable
				imageURL = element["activityData"]["imageURL"]
			} else if(element["activityData"]["reportId"] && element["activityData"]["imageURL"] !== null){ // if it's not there but reportId is, fetch it
				let response = await getActivityImage(element["activityData"]["reportId"])
				if (response.imageURL !== false){
					imageURL = response.imageURL
				}
			}
			let tempArray = {
				id: element["id"],
				type: element["actvityType"],
				message: displayText,
				pointsText: pointsText,
				bonusPointsText: bonusPointsText,
				cssClass: cssClass,
				date: date,
				unixdate: moment(date).valueOf(),
				points: element["points"],
				userName: userName,
				avatar: avatar,
				userID: element["userId"],
				duration: element["activityData"]["value"],
				durationUnit: element["activityData"]["valueUnit"],
				distance: element["activityData"]["totalDistance"],
				distanceUnit: element["activityData"]["totalDistanceUnit"],
				activityID: element["sourceActivityId"],
				reactions: reactions,
				reactionCount: element["reactionCount"],
				link: link,
				reportID: element["activityData"]["reportId"],
				imageURL: imageURL
			};
			feedArray.push(tempArray);
		}
	} else {
		console.log("No activities");
	}
	//console.log("feedArray after activities: " + JSON.stringify(feedArray));
	return feedArray;
};

export const getMessagesAndActivitiesAllChatRooms = async () => {
	let chatRooms = await getChatRooms(true)
	for(let room of chatRooms) {
		//console.log("Getting messages for room "+room.id);
		await getMessagesAndActivities(room)
	}
}

export const getActivities = async (chatRoomObject, limit = 25, offset = 0, startDateMoment = null, endDateMoment = null) => {
	let user = JSON.parse(await getUser(null, false));
	let latestActivitiesSquadA = null;
	let latestActivitiesSquadB = null;
	let activityArray = [];
	if (chatRoomObject.squadIdA !== null) {
		// This doesn't need to be assigned anything since it's storing it locally
		if (chatRoomObject.type === "competition"){
			latestActivitiesSquadA = await getLatestSquadActivities(chatRoomObject.squadIdA, limit, 0, moment(chatRoomObject.startDate),moment(chatRoomObject.endDate));
		} else {
			latestActivitiesSquadA = await getLatestSquadActivities(chatRoomObject.squadIdA, limit, offset, startDateMoment, endDateMoment);
		}	
	}

	if (chatRoomObject.squadIdB !== null) {
		if (chatRoomObject.type === "competition"){
			latestActivitiesSquadB = await getLatestSquadActivities(chatRoomObject.squadIdB, limit, 0, moment(chatRoomObject.startDate),moment(chatRoomObject.endDate));
		} else {
			latestActivitiesSquadB = await getLatestSquadActivities(chatRoomObject.squadIdB, limit, 0);
		}		
	}

	if (latestActivitiesSquadA === null && latestActivitiesSquadB === null) {
		//console.log("Nothing to update");
		activityArray = null;
	} else {
		let squadAActivities, squadBActivities
		switch(chatRoomObject.type) {
			case "competition":
				squadAActivities = await storageService.getObject("squad-activities-" + chatRoomObject.squadIdA);
				activityArray = await convertActivitiesToFeed(squadAActivities, activityArray, user);
				squadBActivities = await storageService.getObject("squad-activities-" + chatRoomObject.squadIdB);
				activityArray = await convertActivitiesToFeed(squadBActivities, activityArray, user);
				break;
			case "squad":
				squadAActivities = await storageService.getObject("squad-activities-" + chatRoomObject.squadIdA);
				activityArray = await convertActivitiesToFeed(squadAActivities, activityArray, user);
				break;
			case "friend":
				break;
			default:
				squadAActivities = await storageService.getObject("squad-activities-" + chatRoomObject.squadIdA);
				activityArray = await convertActivitiesToFeed(squadAActivities, activityArray, user);
				break;
		}
		activityArray.sort((a, b) => {
			if (a.unixdate){
				//console.log("Sorting by unixdate");
				return a.unixdate > b.unixdate ? 1 : -1
			} else {
				//console.log("Sorting by date");
				return a.date > b.date ? 1 : -1
			}
		});
		await storageService.setObject("feed-"+chatRoomObject.id, activityArray);
	}

	return activityArray;
}

export const getMessagesAndActivities = async (chatRoomObject) => { 
	let user = JSON.parse(await getUser(null, false));
	// Check for the last time messages were pulled
	// Pull messages since last pull
	let latestActivitiesSquadA = null;
	let latestActivitiesSquadB = null;
	let feedArray = [];
	
	let latestMessages = await getLatestMessages(chatRoomObject);
	//let latestMessages = await getLatestMessages(chatRoomObject.id);

	//console.log("latestMessages: " + JSON.stringify(latestMessages));
	if (chatRoomObject.squadIdA !== null) {
		//console.log("Adding activities from squadID " + chatRoomObject.squadIdA);
		// This doesn't need to be assigned anything since it's storing it locally
		if (chatRoomObject.type === "competition"){
			latestActivitiesSquadA = await getLatestSquadActivities(chatRoomObject.squadIdA, 25, 0, moment(chatRoomObject.startDate),moment(chatRoomObject.endDate));
		} else {
			latestActivitiesSquadA = await getLatestSquadActivities(chatRoomObject.squadIdA, 100, 0);
		}	
	}

	if (chatRoomObject.squadIdB !== null) {
		//console.log("Adding activities from squadID " + chatRoomObject.squadIdB);
		if (chatRoomObject.type === "competition"){
			latestActivitiesSquadB = await getLatestSquadActivities(chatRoomObject.squadIdB, 25, 0, moment(chatRoomObject.startDate),moment(chatRoomObject.endDate));
		} else {
			latestActivitiesSquadB = await getLatestSquadActivities(chatRoomObject.squadIdB, 100, 0);
		}		
	}

	//console.log("Activity Feed: " + JSON.stringify(activityFeed));
	// Doing it this way for now in case I want to use a flag later...
	//console.log("UPDATE CHECKER");
	//console.log("latestMessages: " + JSON.stringify(latestMessages));
	//console.log("latestActivitiesSquadA: " + JSON.stringify(latestActivitiesSquadA));
	//console.log("latestActivitiesSquadB: ",{latestActivitiesSquadB});	

	let feedObject = await storageService.getObject("feed-"+chatRoomObject.id) // just in case the feed is empty for some reason

	if (latestActivitiesSquadA === null && latestActivitiesSquadB === null && latestMessages === null && feedObject !== null) {
		//console.log("Nothing to update");
		feedArray = null;
	} else {
		// TODO: Dont get ALL the messages from storage
		let messagesInStorage = await storageService.getObject("messages-" + chatRoomObject.id);
		//console.log("Messages In Storage: " + JSON.stringify(messagesInStorage));

		let squadAActivities, squadBActivities
		feedArray = await convertMessagesToFeed(messagesInStorage, feedArray);
		switch(chatRoomObject.type) {
			case "competition":
				squadAActivities = await storageService.getObject("squad-activities-" + chatRoomObject.squadIdA);
				feedArray = await convertActivitiesToFeed(squadAActivities, feedArray, user);
				squadBActivities = await storageService.getObject("squad-activities-" + chatRoomObject.squadIdB);
				feedArray = await convertActivitiesToFeed(squadBActivities, feedArray, user);
				break;
			case "squad":
				squadAActivities = await storageService.getObject("squad-activities-" + chatRoomObject.squadIdA);
				feedArray = await convertActivitiesToFeed(squadAActivities, feedArray, user);
				break;
			case "friend":
				break;
			default:
				squadAActivities = await storageService.getObject("squad-activities-" + chatRoomObject.squadIdA);
				feedArray = await convertActivitiesToFeed(squadAActivities, feedArray, user);
				break;
		}
		//feedArray.sort((a, b) => (a.date > b.date ? 1 : -1));

		feedArray.sort((a, b) => {
			if (a.unixdate){
				//console.log("Sorting by unixdate");
				return a.unixdate > b.unixdate ? 1 : -1
			} else {
				//console.log("Sorting by date");
				return a.date > b.date ? 1 : -1
			}
		});
		//console.log("Sorted Activity Feed: " + JSON.stringify(feedArray));
		// Combine them all into an array sorted by date
		// Return the array
		storageService.setObject("feed-"+chatRoomObject.id, feedArray);
	}

	return feedArray;
};

export const getLastMessageInFeed = async (chatRoomID) =>{
	const storageService = new StorageService();
	let messagesInStorage = await storageService.getObject("messages-" + chatRoomID);
	//console.log("messagesInStorage pre-sort: ",{messagesInStorage});
	if (messagesInStorage === null){
		return null
	} else {
		await messagesInStorage.sort((a, b) => (a.timeStamp > b.timeStamp ? -1 : 1));
		//console.log("messagesInStorage post-sort: ",{messagesInStorage});
		return messagesInStorage[0]
	}	
}

export const addEmoji = async (activityID,reaction, type = "activity", squadID = null) => {
	let url 
	if (type === "activity"){
		url = API_URL + "activities/"+activityID
	} else if(type === "message") {
		url = API_URL + "chat-message/"+activityID
	}
	let idToken = await getIDToken().catch((error) => {
		throw Error("[addEmoji] Error in getIDtoken: " + error);
	});
	let data = {
		"addReaction": reaction
	}
	logReactionGiven(reaction, squadID)
	const response = await Promise.resolve(
		fetch(url, {
			method: "PATCH",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[JS][addEmoji] Error in API call: " + error);
		throw Error(error);
	});

	return response
		.json()
		.then(async (data) => {
			if(data.activityType) {
				let toUserName = await getUserNameFromUserID(data.userId)
				let fromUserName = await getUserNameOfLoggedInUser()
				sendSlackNotification(`👏*New Reaction Added*👏\nActivity: ${data.activityType}\nSquad ID: ${data.squadId}\nFrom User: ${fromUserName}\nTo User: ${toUserName}\nReaction: ${reaction}`, "love")
			} else if (data.message) {
				let toUserName = await getUserNameFromUserID(data.author)
				let fromUserName = await getUserNameOfLoggedInUser()
				sendSlackNotification(`👏*New Reaction Added*👏\nMessage: ${data.message}\nSquad ID: ${data.squadId}\nFrom User: ${fromUserName}\nTo User: ${toUserName}\nReaction: ${reaction}}`, "love")
			}		
			console.log("[JS] addEmoji response:", data);
			let serverResponse = JSON.stringify(data);
			return serverResponse;
		})
		.catch((error) => {
			console.log("[JS][addEmoji] Error in JSON conversion: " + error);
			throw Error(error);
		});
}

export const clearLocalStorage = async (squadIDA, chatRoomID) => {
	const storageService = new StorageService();
	await storageService.removeItem("feed-" + chatRoomID);
	await storageService.removeItem("lastSquadActivityPull-" + squadIDA);
	await storageService.removeItem("squad-activities-" + squadIDA);
	await storageService.removeItem("lastMessagePull-" + chatRoomID);
	await storageService.removeItem("messages-" + chatRoomID);
	console.log("Storage cleared for squadID "+squadIDA+" & chatRoomID "+chatRoomID);
};

export const sendMessage = async (text, chatRoomObj, setFeed, setScroller, setShowToast, type = "text", imageURL = null) => {
	// make sure it's legit
	let user = JSON.parse(await getUser(null, false));
	console.log("User object in sendMessage: " + JSON.stringify(user));
	let messageObj = {
		avatar: user.avatar,
		author: user.cognitoId,
		chatRoom: chatRoomObj,
		createdAt: moment().format(),
		deleted: false,
		id: null,
		message: text,
		reactionCount: 0,
		reactions: [],
		type: type,
		date: moment().format(),
		timeStamp: moment().format(),
		updatedAt: moment().format(),
		user: {
			avatar: user.avatar,
			level: user.level,
			userName: user.userName,
		},
		userID: user.cognitoId,
		userName: user.userName,
	};
	await storageService.setItem("acceptTimerPull-messages", "false"); // flag just in case
	console.log("acceptTimerPull-messages set to false");
	let currentFeed = await storageService.getObject("messages-" + chatRoomObj.id); // get the current feed
	if (currentFeed === null) {
		currentFeed = [];
	}
	console.log({messageObj});
	await currentFeed.push(messageObj); // add the message to the feed so it shows up locally
	console.log("Message pushed to current feed");
	setFeed(currentFeed); // update the state
	setScroller(true);
	console.log("Feed state updated with currentFeed");
	if (type === "image") {
		let response = await postChatRoomImage(chatRoomObj.id, text).catch((error) => {
			console.log("Feed update error: ", { error });
			setShowToast(true);
		}); // send it to the server
		sendSlackNotification("*Squad: "+chatRoomObj.name+"*\n["+user.userName+"] sent this image:\n"+response.payload.imageUrl, "messaging")
	} else {
		await postChatRoomMessage(chatRoomObj.id, text).catch((error) => {
			console.log("Feed update error: ", { error });
			setShowToast(true);
		}); // send it to the server
		sendSlackNotification("*Squad: " + chatRoomObj.name + "*\n[" + user.userName + "] said: " + text, "messaging");
	}
	await getMessagesAndActivities(chatRoomObj).catch((error) => {
		console.log("Feed update error: ", { error });
		setShowToast(true);
	});
	let serverFeed = await storageService.getObject("messages-" + chatRoomObj.id);
	setFeed(serverFeed);
	setScroller(true);
	console.log("Feed state updated with serverFeed");
	await storageService.setItem("acceptTimerPull-messages", "true");
	console.log("acceptTimerPull-messages set to true");
}

export const sendMessageSquadHome = async (text, chatRoomObj, setFeed, setScroller, setShowToast, type = "text", imageURL = null) => {
	// make sure it's legit
	let user = JSON.parse(await getUser(null, false));
	console.log("User object in sendMessage: " + JSON.stringify(user));
	let messageObj = {
		author: user.cognitoId,
		chatRoom: chatRoomObj,
		createdAt: moment().format(),
		deleted: false,
		id: null,
		message: text,
		reactionCount: 0,
		reactions: [],
		type: type,
		timeStamp: moment().format(),
		updatedAt: moment().format(),
		user: {
			avatar: user.avatar,
			level: user.level,
			userName: user.userName,
		},
	};
	await storageService.setItem("acceptTimerPull-messages", "false"); // flag just in case
	console.log("acceptTimerPull-messages set to false");
	let currentFeed = await storageService.getObject("messages-" + chatRoomObj.id); // get the current feed
	if (currentFeed === null) {
		currentFeed = [];
	}
	console.log({messageObj});
	await currentFeed.push(messageObj); // add the message to the feed so it shows up locally
	console.log("Message pushed to current feed");
	setFeed(currentFeed); // update the state
	setScroller(true);
	console.log("Feed state updated with currentFeed");
	if (type === "image") {
		let response = await postChatRoomImage(chatRoomObj.id, text).catch((error) => {
			console.log("Feed update error: ", { error });
			setShowToast(true);
		}); // send it to the server
		sendSlackNotification("*Squad: "+chatRoomObj.name+"*\n["+user.userName+"] sent this image:\n"+response.payload.imageUrl, "messaging")
	} else {
		await postChatRoomMessage(chatRoomObj.id, text).catch((error) => {
			console.log("Feed update error: ", { error });
			setShowToast(true);
		}); // send it to the server
		sendSlackNotification("*Squad: " + chatRoomObj.name + "*\n[" + user.userName + "] said: " + text, "messaging");
	}
	console.log("posted to server");
	//let serverFeed = await getMessagesAndActivities(chatRoomObj).catch((error) => {
	// Then get the new stuff
	let serverFeed = await getMessages(chatRoomObj.id).catch((error) => {
		console.log("Feed update error: ", { error });
		setShowToast(true);
	});
	console.log("all activity retrieved from server");
	setFeed(serverFeed.reverse());
	setScroller(true);
	console.log("Feed state updated with serverFeed");
	await storageService.setItem("acceptTimerPull-messages", "true");
	console.log("acceptTimerPull-messages set to true");
}

const setupSquadChatRoom = async (chatRoom, refresh) => {
		let squadInfo = await getSquad(chatRoom.squadIdA, null, null, refresh);
		// console.log({ chatRoom });
		// updating chatroom to get most recent message
		chatRoom.type = "squad";
		chatRoom.name = squadInfo.name;
		chatRoom.lastTime = "";
		chatRoom.lastSender = "";
		chatRoom.lastMessage = "";
		chatRoom.image = squadInfo.image;
		chatRoom.roomPage = "/chatroom/" + chatRoom.id;
		await storageService.setObject(chatRoom.id, chatRoom);	
};
