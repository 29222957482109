import { IonButton, IonHeader, IonIcon, IonTitle, IonToolbar } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React from "react";
import "../css/ActivityDetails.css";
import "../css/Home.css";

const FirstGoalDescriptionComponent = ({ setShowWhyModal }) => {
	return (
		<React.Fragment>
			<IonHeader>
				<IonToolbar className="activity-details-toolbar">
					<IonTitle>Goal Setting</IonTitle>
					<IonButton color="tertiary" slot="end" onClick={() => setShowWhyModal(false)}>
						<IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
					</IonButton>
				</IonToolbar>
			</IonHeader>
			<div style={{ margin: "16px" }}>
				<p>
					<a
						href="https://www.mayoclinic.org/healthy-lifestyle/fitness/expert-answers/exercise/faq-20057916#:~:text=As%20a%20general%20goal%2C%20aim,may%20need%20to%20exercise%20more."
						target="_new"
					>
						According to the Mayo Clinic
					</a>
					, healthy adults should get <span className="bold">150 minutes of moderate intensity</span> activity a week.
				</p>
				<p>
					In FitSquad terms, because you get 1.5 points for each minute of moderate intensity activity that equates to a{" "}
					<span className="bold">goal of 225 points.</span>
				</p>
				<p>
					However,{" "}
					<a href="https://www.insider.com/300-minutes-exercise-week-weight-loss2020-12" target="_new">
						recent research
					</a>{" "}
					shows that <span className="bold">for weight loss</span>, you should target 300 minutes of moderate intensity or a{" "}
					<span className="bold">goal of 450 points</span>.
				</p>
			</div>
		</React.Fragment>
	);
};

export default FirstGoalDescriptionComponent;
