import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonViewWillEnter } from "@ionic/react";
import "emoji-mart/css/emoji-mart.css";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import NotificationsListComponent from "../components/NotificationListComponent";
import AuthContext from "../contexts/GlobalState";
import { logGiveLoveEvent } from "../services/AnalyticsService";
import { addReaction } from "../services/FeedService";
import { getRecentUpdatesFromAPI, processUpdates, updateReply } from "../services/NotificationService";
import { getSquadIDFromChatRoomID } from "../services/SquadService";
import { StorageService } from "../services/StorageService";
import { getUserID, getUserNameOfLoggedInUser } from "../services/UserService";
const storageService = new StorageService();

const NotificationsList = ({ setNotificationsModal }) => {
	const history = useHistory();
	const { userState, pullingUpdates, setPullingUpdates } = useContext(AuthContext);
	const [latestUpdates, setLatestUpdates] = useState([]);
	const [updating, setUpdating] = useState(false);
	const [showReactionSelector, setShowReactionSelector] = useState({
		open: false,
		event: undefined,
		activityID: undefined,
		type: undefined,
		index: undefined,
	});
	const [tyMessage, setTyMessage] = useState({
		open: false,
		message: null,
		emoji: null,
	});

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton text="" />
					</IonButtons>
					<IonTitle>Notifications</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<NotificationsListComponent maxUpdates={99} showAllUpdates={true} />
			</IonContent>
		</IonPage>
	);
};

export default NotificationsList;
