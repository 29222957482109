/* eslint-disable jsx-a11y/accessible-emoji */
import { IonHeader, IonTitle, IonToolbar, IonButton, IonIcon, IonTextarea, IonContent, IonInput } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import Rating from "react-rating";
import "../../css/Home.css";
import "../../css/ActivityDetails.css";
import { sendSlackNotification } from "../../services/NotificationService";
import { getUserID, getUserNameFromUserID } from "../../services/UserService";
import { sendGenericFeedback } from "../../services/FeedbackService";

const GenericFeedbackComponent = ({ setGenericFeedbackModal }) => {
	const feedbackBody = useRef(null);
	const emailRef = useRef(null);
	const [feedbackSent, setFeedbackSent] = useState(false);

	const sendFeedback = async () => {
		let userName = await getUserNameFromUserID();
		let userID = await getUserID();
		let text = "*Username:* " + userName + "\n*UserID:* " + userID + "\n*Email:* " + emailRef.current.value + "\n*Feedback:* " + feedbackBody.current.value;
		sendSlackNotification(text, "feedback");
		sendGenericFeedback(emailRef.current.value, feedbackBody.current.value);
		setFeedbackSent(true);
	};

	return (
		<React.Fragment>
			<IonContent style={{ "--background": "var(--ion-color-tertiary)" }}>
				<IonHeader>
					<IonToolbar className="activity-details-toolbar" style={{ "--border-width": "0px" }}>
						<IonButton color="tertiary" slot="end" onClick={() => setGenericFeedbackModal(false)}>
							<IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
						</IonButton>
					</IonToolbar>
				</IonHeader>
				<div style={{ margin: "8%" }}>
					<React.Fragment>
						<div style={{ textAlign: "center", color: "var(--ion-color-secondary)", marginBottom: "10%", fontSize: "1.2em" }}>
							What are we're doing well?
							<br />
							What could we do better?
						</div>
						<IonInput
							className="collect-info-input"
							ref={emailRef}
							name="email"
							type={"email"}
							placeholder="Your email address"
							enterkeyhint="next"
							style={{ background: "white", marginBottom: "10%", borderRadius: "10px" }}
						></IonInput>
						<IonTextarea
							autoGrow={true}
							autoCapitalize={true}
							autoFocus={true}
							enterKeyHint="send"
							spellCheck={true}
							style={{
								minHeight: "200px",
								border: "1px darkgray solid",
								borderRadius: "5px",
								paddingLeft: "10px",
								paddingRight: "10px",
								backgroundColor: "white",
							}}
							ref={feedbackBody}
							placeholder={"Your thoughts..."}
							type={"text"}
						></IonTextarea>
						<IonButton
							onClick={() => sendFeedback()}
							size="large"
							color="success"
							style={{ marginLeft: "15%", width: "70%", marginTop: "5%", marginBottom: "5%", fontSize: "1em", textTransform: "none" }}
						>
							Send feedback
						</IonButton>
					</React.Fragment>
					{feedbackSent === true ? (
						<div style={{ marginTop: "20%", textAlign: "center", color: "var(--ion-color-secondary)" }}>Feedback received. Thank you!</div>
					) : (
						""
					)}
				</div>
			</IonContent>
		</React.Fragment>
	);
};

export default GenericFeedbackComponent;
