import {
	IonCol,
	IonContent,
	IonGrid,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonItem,
	IonList,
	IonPopover,
	IonRow,
	useIonViewDidEnter,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import "../../css/Home.css";
import "../../css/Seasons.css";

import RewardPopover from "./RewardPopover";
import SeasonsWeeklyResultComponent from "./SeasonsWeeklyResultComponent";

const SeasonsWeeklyResults = ({ seasonObject }) => {
	const userSuccessText = "You hit you goal this week!";
	const squadSuccessText = "Your squad hit their goal this week!";
	const bothFailedText = "Neither you nor your squad hit their goals this week.";
	const [userLedger, setUserLedger] = useState([]);
	useEffect(() => {
		createLedger();
	}, [userLedger]);

	const createLedger = async () => {
		if (seasonObject.weeklyResults.length > 0) {
			let tempLedger = [];

			for await (let weekResult of seasonObject.weeklyResults) {
				tempLedger.unshift(weekResult); // just reorders in reverse
			}
			setUserLedger(tempLedger);
		}
	};

	return (
		<React.Fragment>
			<div style={{ marginLeft: "16px" }}>
				<div className="section-title" style={{ paddingLeft: "0px" }}>
					Weekly Results
				</div>
			</div>
			<IonContent style={{ maxHeight: "80%" }}>
				{userLedger.length === 0 ? <div className="no-activities">No results yet</div> : ""}
				{userLedger &&
					userLedger.map((result, i) => {
						return (
							<IonList key={`${i}`}>
								<div>
									<div className="date-separator" style={{ display: "block" }}>
										<div className="date-separator-text" style={{ marginBottom: "0px" }}>
											Week {result.week}
										</div>
									</div>
								</div>
								{result.userWeeklyGoalHit === 1 ? (
									<IonItem className="feed-item" lines="none">
										<SeasonsWeeklyResultComponent success={true} displayText={userSuccessText} icon={"💥"} />
									</IonItem>
								) : (
									""
								)}
								{result.squadWeeklyGoalHit === 1 ? (
									<IonItem className="feed-item" lines="none">
										<SeasonsWeeklyResultComponent success={true} displayText={squadSuccessText} icon={"🎉"} />
									</IonItem>
								) : (
									""
								)}
								{result.squadWeeklyGoalHit === 0 && result.userWeeklyGoalHit === 0 ? (
									<SeasonsWeeklyResultComponent success={false} displayText={bothFailedText} icon={"😞"} />
								) : (
									""
								)}
							</IonList>
						);
					})}
			</IonContent>
		</React.Fragment>
	);
};

export default SeasonsWeeklyResults;
