import {
	IonBackButton,
	IonButtons,
	IonCard,
	IonContent,
	IonHeader,
	IonIcon,
	IonLoading,
	IonPage,
	IonPopover,
	IonRefresher,
	IonRefresherContent,
	IonTitle,
	IonToolbar,
	useIonViewDidEnter,
} from "@ionic/react";
import { menuOutline } from "ionicons/icons";
import { menuController } from "@ionic/core";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import AuthContext from "../../contexts/GlobalState";
import "../../css/Home.css";
import "../../css/Profile.css";
import { logGenericEvent, logPageView } from "../../services/AnalyticsService";
import { StorageService } from "../../services/StorageService";
import ExerciseDetailsComponent from "../ActivityFeedComponents/ExerciseDetailsComponent";
import SystemActivityDetailsComponent from "../ActivityFeedComponents/SystemActivityDetailsComponent";
import InventoryComponent from "./InventoryComponent";
import ProfileMainAvatarComponent from "./ProfileMainAvatarComponent";
import ProfileWeeklyProgressComponent from "./ProfileWeeklyProgressComponent";
import ProfileWeeklyStreaksComponent from "./ProfileWeeklyStreaksComponent";
import UserActivityFeed from "./UserActivityFeed";
import UserDataComponent from "../UserDataComponent";

const UserProfile = () => {
	const history = useHistory();
	// Loading states
	const [loading, setLoading] = useState();
	const { userState, updateGoalState, updateUserState, updateWeeklyProgress } = useContext(AuthContext);
	const [parentFeedOffset, setParentFeedOffset] = useState(false);
	const [userObject, setUserObject] = useState();
	const [activityDetails, showActivityDetails] = useState({
		visible: false,
		activityID: null,
		component: null,
	});
	const [systemActivityDetails, showSystemActivityDetails] = useState({
		visible: false,
		activityID: null,
		component: null,
	});

	const storageService = new StorageService();

	useIonViewDidEnter(async () => {
		logPageView("profile");
		setUserObject(userState.user);
	});

	const doRefresh = async (event, refresh = true) => {
		setLoading(true);
		storageService.removeItem("userGoal-null");
		storageService.removeItem("userGoal-" + userObject.cognitoId);
		storageService.removeItem("userPointHistory-" + userObject.cognitoId);
		storageService.removeItem("allInventory");
		setParentFeedOffset(true);
		await updateGoalState().then(() => console.log("User Goal State Updated"));
		await updateUserState().then(() => console.log("User State Updated"));
		await updateWeeklyProgress(userObject, true);
		if (event)
			setTimeout(() => {
				console.log("Async operation has ended");
				event.detail.complete();
				setParentFeedOffset(false);
			}, 2000);
		setLoading(false);
	};
	const onClickHandler = () => {
		logGenericEvent("Menu");
		menuController.open();
	};
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonIcon slot="start" icon={menuOutline} onClick={() => onClickHandler()} size="large" />
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
					<IonTitle>Profile</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonPopover
					//event={showGoalConfirmation.event}
					isOpen={activityDetails.visible}
					cssClass="activity-full-screen-popover"
					onDidDismiss={(e) => {
						showActivityDetails({
							visible: false,
							activityID: null,
							component: null,
						});
					}}
				>
					<ExerciseDetailsComponent activityID={activityDetails.activityID} showActivityDetails={showActivityDetails} />
				</IonPopover>
				<IonPopover
					//event={showGoalConfirmation.event}
					isOpen={systemActivityDetails.visible}
					cssClass="activity-full-screen-popover"
					onDidDismiss={(e) => {
						showSystemActivityDetails({
							visible: false,
							activityID: null,
							component: null,
						});
					}}
				>
					<SystemActivityDetailsComponent activityID={systemActivityDetails.activityID} showSystemActivityDetails={showSystemActivityDetails} />
				</IonPopover>
				<IonRefresher slot="fixed" onIonRefresh={doRefresh}>
					<IonRefresherContent refreshingText="Updating Profile"></IonRefresherContent>
				</IonRefresher>
				<IonLoading isOpen={loading} onDidDismiss={() => setLoading(false)} message={"Loading"} />
				<UserDataComponent />
				{userObject && userObject.cognitoId ? <InventoryComponent /> : ""}
				{userObject && userObject.cognitoId ? <ProfileWeeklyStreaksComponent userObject={userObject} /> : ""}
				{userObject && userObject.cognitoId ? <ProfileWeeklyProgressComponent userObject={userObject} /> : ""}
				<div className="section-title ">Activities</div>
				<UserActivityFeed
					passedUserID={null}
					showActivityDetails={showActivityDetails}
					showSystemActivityDetails={showSystemActivityDetails}
					parentFeedOffset={parentFeedOffset}
				/>
			</IonContent>
		</IonPage>
	);
};

export default UserProfile;
