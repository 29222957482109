import { IonCard, IonCol, IonPopover, IonRow } from "@ionic/react";
import React, { useState } from "react";
import "../../css/ActivityDetails.css";
import "../../css/Home.css";
import { cloudFrontURL } from "../../models/constants";

const ChallengeRewardsComponent = ({ challengeDetails, challengeObject }) => {
	const [detailsWindow, setDetailsWindow] = useState({
		visible: false,
		item: null,
	});

	return (
		<React.Fragment>
			<IonPopover
				isOpen={detailsWindow.visible}
				cssClass="exercise-details-full-screen-popover"
				onDidDismiss={(e) => {
					setDetailsWindow({ visible: false });
				}}
			>
				<DetailsPopover item={detailsWindow.item} />
			</IonPopover>
			<IonCard className="no-shadow light-gray-border">
				{challengeDetails.metadata.rewards.map((details, i) => {
					let size = challengeDetails.metadata.details.length - 1;
					return (
						<React.Fragment key={`${i}`}>
							<IonRow onClick={() => setDetailsWindow({ visible: true, item: details })}>
								<IonCol size="3">
									<img
										style={{ marginTop: i === 0 ? "2%" : "auto", marginBottom: i === size ? "5%" : "auto" }}
										className="image-icon"
										src={`${cloudFrontURL}/${details.icon}`}
										alt=""
									/>
								</IonCol>
								<IonCol size="8">
									<div style={{ marginTop: i === 0 ? "2%" : "auto", marginBottom: i === size ? "5%" : "auto" }} className="details-description-div">
										<div dangerouslySetInnerHTML={{ __html: details.description }}></div>
									</div>
								</IonCol>
							</IonRow>
							{size === i ? (
								""
							) : (
								<div>
									<hr className="details-separator" />
								</div>
							)}
						</React.Fragment>
					);
				})}
			</IonCard>
		</React.Fragment>
	);
};

export default ChallengeRewardsComponent;

const DetailsPopover = ({ item }) => {
	// console.log({ item });
	return (
		<React.Fragment>
			<div style={{ textAlign: "center", marginTop: "10%" }}>
				{item.extendedDescriptionImage && item.extendedDescriptionImage !== "" ? (
					<img className="challenge-details-image" src={`${cloudFrontURL}/${item.extendedDescriptionImage}`} alt="" />
				) : (
					<img className="challenge-details-image" src={`${cloudFrontURL}/${item.icon}`} alt="" /> // more that just file name because they are generic and it's easier to read
				)}
			</div>
			<div style={{ margin: "10%" }}>
				{item.extendedDescription && item.extendedDescription !== "" ? (
					<div dangerouslySetInnerHTML={{ __html: item.extendedDescription }}></div>
				) : (
					<div dangerouslySetInnerHTML={{ __html: item.description }}></div>
				)}
			</div>
		</React.Fragment>
	);
};
