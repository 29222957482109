import { getIDToken } from "./AuthService";
import { API_URL } from "../models/constants";
import moment from "moment";
import { StorageService } from "../services/StorageService";
import { getWeight, loadSumCalorieData, loadSumStepsData } from "./HealthKitService";
import {convertStepsToCals,convertCalsToCredits} from "./HelperServices"
import { getUserID } from "./UserService";
import { shouldRefresh } from "./RefreshService";
import { getSquad, getSquadGoal } from "./SquadService";
const storageService = new StorageService();

/**
 * 
 * Returns back a goal object for a given user. If userID is null, assumes the logged in users. By default will always refresh the user's goal
 * For a squad, use getSquad goal
 * Store goal is used for getting goals from previous weeks
 * 
 * @param {*} userID 
 * @param {*} squadID 
 * @param {*} refresh 
 * @param {*} startDateMoment 
 * @param {*} endDateMoment 
 */

export const getGoal = async (userID = null, refresh = true, startDateMoment = null, endDateMoment = null, storeGoal = true, includePrediction = true) => {
	let mostRecentGoalObject = [];
	let url
	let idToken = await getIDToken().catch((error) => {
		throw Error("Error in getIDtoken: " + error);
	});
	if(userID === null){ // if this is null it means use the logged in user
		userID = await getUserID() 
	}
	let goalObject = await storageService.getObject("userGoal-"+userID);
	if (goalObject && goalObject !== "" && goalObject.lastUpdated) {
		let update = await shouldRefresh(goalObject, 60)		
		if (update === false && refresh === false){
			//console.log(`Loading user goal ${userID} from local storage`);	
			if (goalObject.bonusPoints && goalObject.bonusPoints > 0) {
				goalObject.prediction = Math.ceil(goalObject.prediction * 1.10) // add activity bonus to prediction if the user has bonus points
				goalObject.goalProgress = goalObject.goalProgress + goalObject.bonusPoints
			}		
			return goalObject;
		} else {
			//console.log(`Hitting goal API because userID=${userID}, refresh=${refresh} & update=${update}`);
		}
	} else {
		//console.log(`No goal object for user ${userID}`);		
	}
	url = API_URL + "goals?userId="+userID;
	if (startDateMoment !== null){
		url = url + "&startDate="+startDateMoment.format()+"&endDate="+endDateMoment.format()
	}

	//console.log("Getting goal object: "+url);
	const response = await Promise.resolve(
		fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		}).catch((error) => {
			throw Error("Error in getting goal: " + error);
		})
	).catch((error) => {
		throw Error("Error in getting goal: " + error);
	});
	const responseLength = (await response.clone().text()).length;
	//console.log("Response Length:" + responseLength);
	if (responseLength > 2) {
		let serverResponse = await response.json().catch((error) => {
			throw Error("Error in parsing API response from getGoal: " + error);
		});
		//console.log("[JS] getGoal Success:", serverResponse);
		serverResponse[0].lastUpdated = moment()
		if (storeGoal === true){
			if ((startDateMoment === null || endDateMoment === null) && includePrediction === true){ // only get a prediction if it's for this week and it wasnt changed when calling
				let prediction = await getPrediction(userID)
				//console.log("Predict response: "+prediction);
				if (prediction || prediction === 0){
					serverResponse[0].prediction = prediction
				}
			}			
			storageService.setObject("userGoal-"+userID, serverResponse[0]);
		}			
		if (serverResponse[0].bonusPoints && serverResponse[0].bonusPoints > 0) {
			serverResponse[0].prediction = Math.ceil(serverResponse[0].prediction * 1.10) // add activity bonus to prediction if the user has bonus points
			serverResponse[0].goalProgress = serverResponse[0].goalProgress + serverResponse[0].bonusPoints
		}
		return serverResponse[0];
	} else {
		console.log("No goal object returned from server for URL: ",url)
		mostRecentGoalObject = null;
	}
	return mostRecentGoalObject;
};

/** 
 * 
 * Takes an id from the goals table and a number and updates the goal to that number
 * 
 * @param {integer} goalID 
 * @param {integer} goal 
 */

export const updateGoal = async (goalID, goal) => {
	const url = API_URL;
	let idToken = await getIDToken().catch((error) => {throw Error("[updateGoal] Error in getIDtoken: " + error);});
	let body = {
		goalPoints: goal
	}
	const response = await Promise.resolve(
		fetch(url + "goals/"+goalID, {
			method: "PATCH",
			body: JSON.stringify(body),
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[updateGoal] Error in API call: " + error);
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {
			console.log("[updateGoal] Response:", data);
			return data;
		})
		.catch((error) => {
			console.log("[updateGoal] Error in JSON conversion: " + error);
			throw Error(error);
		});
};

export const getUserPoints = async () => {
	let goalObject = await getGoal();
	let goalProgress = goalObject.goalProgress * 1.75;
	return goalProgress;
};

export const getUserGoal = async () => {
	let goalObject = await getGoal();
	let goalPoints = goalObject.goalPoints;
	return goalPoints;
};

export const deleteUserEntries = async (startDateMoment, endDateMoment, idToken = "") => {
	console.log("idToken: " + idToken);
	if (idToken === null || idToken === "" || idToken === undefined) {
		idToken = await getIDToken().catch((error) => {
			throw Error("Error in getIDtoken: " + error);
		});
	}
	console.log("[JS] token is good to go");
	const url = API_URL;
	//console.log('[JS] id token passed to API:', idToken)
	console.log("Deleting Data between " + startDateMoment.format() + " and " + endDateMoment.format());
	let deleteResponse = await Promise.resolve(
		fetch(url + "healthkit?startDate=" + startDateMoment.format() + "&endDate=" + endDateMoment.format(), {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	);
	deleteResponse = await deleteResponse.json().catch((error) => {
		throw Error("Error in parsing healthKit response: " + error);
	});
	console.log("[JS] [syncHistory] DELETE Server Response:" + JSON.stringify(deleteResponse));
};

export const postUserEntries = async (startDateMoment, endDateMoment, calories, idToken = "") => {
	console.log("idToken: " + idToken);
	if (idToken === null || idToken === "" || idToken === undefined) {
		idToken = await getIDToken().catch((error) => {
			throw Error("Error in getIDtoken: " + error);
		});
	}
	console.log("[JS] token is good to go");
	const url = API_URL;
	const data = {
		dataType: "calories",
		startDate: startDateMoment.format(),
		endDate: endDateMoment.format(),
		creationDate: moment().toDate(),
		sourceName: "healthKit",
		valueUnit: "calories",
		value: calories,
		totalEnergyBurned: calories,
	};
	console.log("Sending Data: " + JSON.stringify(data));
	//console.log('[JS] id token passed to API:', idToken)
	console.log("Posting sum Data between " + startDateMoment.format() + " and " + endDateMoment.format());
	let postResponse = await Promise.resolve(
		fetch(url + "healthkit", {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	);
	postResponse = await postResponse.json().catch((error) => {
		throw Error("Error in parsing healthKit response: " + error);
	});
	console.log("[JS] [syncHistory] POST Server Response:" + JSON.stringify(postResponse));
};

/**
 * 
 * Creates the goal object on the server
 * 
 * returns the projected goal for the user or a strign if there was an error
 * 
 */

export const createGoal = async (numberOfMinutes = null, timezone = "America/Los_Angeles") => {
	// TODO: Populate the partial week
	console.log("Goal Creation Process started");    
    //let calculatedGoal = await calculateGoal(numberOfMinutes);
    let calculatedGoal = numberOfMinutes;
    let idToken = await getIDToken().catch((error) => {
        throw Error("Error in getIDtoken: " + error);
    });
    //console.log("[JS] token is good to go");
    const url = API_URL;	
    const mondayOfCurrentWeek = moment(moment().startOf("isoWeek")).tz(timezone).format()
    const sundayOfCurrentWeek = moment(moment().endOf("isoWeek")).tz(timezone).format()
    const data = {
        startDate: mondayOfCurrentWeek,
        endDate: sundayOfCurrentWeek,
        goalPoints: calculatedGoal,
	};
	console.log("Data for goal creation: "+JSON.stringify(data));
    //console.log('[JS] id token passed to API:', idToken)
    const response = await Promise.resolve(
        fetch(url + "goals", {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: idToken,
            },
        })
    );
    let serverResponse = await response.json().catch((error) => {
        throw Error("Error in parsing createGoal response: " + error);
    });
	return serverResponse

}

/**
 * 
 * Pulls 4 weeks of calorie & step data from HealthKit, 
 * converts it to points, divide by 4, round & return.
 * Should account for testing via mobileweb
 * 
 */

const calculateGoal = async (numberOfMinutes) => {
	let userWeightInKG = getWeight()
	let remainingDays = 7
	let daysInAMonth = 30
	let calPerMinute = 6
	let hoursInAWeek = 168
	let monthOfCredits
	if (numberOfMinutes == null) {
		let monthOfCals = Math.floor(await loadSumCalorieData(moment().subtract(4, "weeks"), moment(), userWeightInKG));
		const monthOfSteps = Math.floor(await loadSumStepsData(moment().subtract(4, "weeks").toDate()));
		const calsFromSteps = convertStepsToCals(monthOfSteps, userWeightInKG);
		console.log("Month of Cals: " + monthOfCals);
		console.log("Month of Steps: " + monthOfSteps);
		console.log("Cals from Month of Steps: " + calsFromSteps);
		if (calsFromSteps > monthOfCals) {
			// if the cals from the steps is higher use it
			monthOfCals = calsFromSteps;
			console.log("Using clas from steps instead of active energy");
		}
		monthOfCredits = convertCalsToCredits(monthOfCals);
		console.log("Month of Credits: " + monthOfCredits);
	} else {
		let monthOfMins = numberOfMinutes/remainingDays*daysInAMonth
		let monthOfCals = monthOfMins * calPerMinute
		monthOfCredits = convertCalsToCredits(monthOfCals);
		console.log("Mins calculation:\n monthOfMins: "+monthOfMins+"\n monthOfCals: "+monthOfCals+"\n monthOfCredits: "+monthOfCredits);		
	}
    const goal = Math.round(Math.floor(monthOfCredits / 4) / 100) * 100; // should round to nearest 1000
	console.log("Weekly goal: " + goal);
	var a = moment().endOf("isoWeek").local()
	var b = moment()	
	let hoursRemaining = a.diff(b, 'hours')
	let remainingGoal = goal/hoursInAWeek*hoursRemaining
	console.log("Hours Remaining: " + hoursRemaining);
	console.log("Points Remaining: " + remainingGoal);
    return remainingGoal;
}


export const goalPrediction = async (goalObject) => {
	let prediction
	if (goalObject.goalPoints === 0){
		prediction = 0
		return prediction
	}
	let hoursInAWeek = 168
	let currentTime = moment()
	let endDate = moment(goalObject.endDate)
	let hoursRemaining = endDate.diff(currentTime,'hours')
	let hoursElapsed = hoursInAWeek-hoursRemaining
	console.log(`Hours Remaining: ${hoursRemaining}, GoalProgress: ${goalObject.goalProgress}`);
	prediction = Math.round(goalObject.goalProgress/hoursElapsed*hoursInAWeek)
	return prediction
}

export const isUserOptedOut = async (userID) => {
	let goal = await getGoal(userID)
	if (goal === null){
		return true
	}
	if (goal.optOut !== undefined || goal.optOut == null ){
		goal.optOut = false // if it's not there default to false
	}
	return goal.optOut
}

export const getCompetitionObject = async (squadID) => {
	const url = API_URL;
	let idToken = await getIDToken().catch((error) => {throw Error("[getCompetitionObject] Error in getIDtoken: " + error);});
	const response = await Promise.resolve(
		fetch(url + "competitions?squadId="+squadID, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[getCompetitionObject] Error in API call: " + error);
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {
			console.log("[getCompetitionObject] Response:", data);
			return data;
		})
		.catch((error) => {
			console.log("[getCompetitionObject] Error in JSON conversion: " + error);
			throw Error(error);
		});
}

/**
 * 
 * Gets points from the time frame and returns them
 * Consider writing to object in the future
 * 
 * @param {*} userID 
 * @param {*} squadID 
 * @param {*} startDateMoment 
 * @param {*} endDateMoment 
 */
export const getPointsByDate = async (userID = null, squadID = null, startDateMoment, endDateMoment, daySeparated = true) => {
	let url = API_URL+ "points"
	let idToken = await getIDToken().catch((error) => {throw Error("[getPointsByDate] Error in getIDtoken: " + error);});
	
	if(userID !== null){
		url = url+"?userId="+userID+"&startDate="+startDateMoment.format()+"&endDate="+endDateMoment.format()
	} else if (squadID !== null) {
		url = url+"?squadId="+squadID+"&startDate="+startDateMoment.format()+"&endDate="+endDateMoment.format()
	} else {
		return "Error: userID or squadID required"
	}

	console.log("GPBD Url: ",url);
	const response = await Promise.resolve(
		fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[getPointsByDate] Error in API call: " + error);
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {
			console.log("[getPointsByDate] Response:", data);
			return Math.round(data.points);
		})
		.catch((error) => {
			console.log("[getPointsByDate] Error in JSON conversion: " + error);
			throw Error(error);
		});
}

/**
 * 
 * Takes in a chatRoomObj, checks it for point totals, if it has those, returns them, if not, fetches them, populates the object and returns them
 * 
 * @param {*} chatRoomObj 
 * @param {*} startDateMoment 
 * @param {*} endDateMoment 
 */

export const getPointsByDateForCompetition = async (chatRoomObj, startDateMoment) => {
	// add squadADailyTotal, squadBDailyTotal
	// Both with dates and total elements
	// get squadA daily totals
	let ttl = 5
	let startDate = moment(startDateMoment).startOf("day").format()
	console.log("startDate: ",startDate);
	let daysToCheck = 7
	let daysChecked;	
	console.log("DAYS TO CHECK: ", daysToCheck);

	// Get for squad A

	let squadAID = chatRoomObj.squadIdA
	if (!chatRoomObj.squadAPoints) {chatRoomObj.squadAPoints = {}}
	if (!chatRoomObj.squadAPoints.dailyTotal) {chatRoomObj.squadAPoints.dailyTotal = {}}	
	chatRoomObj.squadAPoints.total = 0
	if (!chatRoomObj.squadAPoints.lastUpdated) {chatRoomObj.squadAPoints.lastUpdated = moment().format()}
	daysChecked = 0;	
	while (daysChecked < daysToCheck) {
		// All that's needed here is sum from activities
		let sd = moment(startDate).startOf("day").add(daysChecked, "days");
		let ed = moment(startDate).endOf("day").add(daysChecked, "days");
		let update = await shouldRefresh(chatRoomObj.squadAPoints,ttl)
		if (!chatRoomObj.squadAPoints.dailyTotal[moment(sd).format("MM/DD/YY")] || update === true) {
			console.log(`Pulling new data for squad ${squadAID} on ${moment(sd).format("MM/DD/YY")} update = ${update}`);
			let dailyTotal = await getPointsByDate(null, squadAID,sd,ed);
			chatRoomObj.squadAPoints.dailyTotal[moment(sd).format("MM/DD/YY")]=dailyTotal
		} else {
			console.log(`Using current data:\n date: ${moment(sd).format("MM/DD/YY")}  amount: ${chatRoomObj.squadAPoints.dailyTotal[moment(sd).format("MM/DD/YY")]} update: ${update}`);
		}		
		chatRoomObj.squadAPoints.total = chatRoomObj.squadAPoints.total + chatRoomObj.squadAPoints.dailyTotal[moment(sd).format("MM/DD/YY")]
		daysChecked++;
	}

	// Get for squad B
	let squadBID = chatRoomObj.squadIdB
	if (!chatRoomObj.squadBPoints) {chatRoomObj.squadBPoints = {}}
	if (!chatRoomObj.squadBPoints.dailyTotal) {chatRoomObj.squadBPoints.dailyTotal = {}}	
	chatRoomObj.squadBPoints.total = 0
	if (!chatRoomObj.squadBPoints.lastUpdated) {chatRoomObj.squadBPoints.lastUpdated = moment().format()}
	daysChecked = 0;
	while (daysChecked < daysToCheck) {
		// All that's needed here is sum from activities
		let sd = moment(startDate).startOf("day").add(daysChecked, "days");
		let ed = moment(startDate).endOf("day").add(daysChecked, "days");
		let update = await shouldRefresh(chatRoomObj.squadBPoints,ttl)
		if (!chatRoomObj.squadBPoints.dailyTotal[moment(sd).format("MM/DD/YY")] || update === true) {
			console.log(`Pulling new data for squad ${squadBID} on ${moment(sd).format("MM/DD/YY")} update = ${update}`);
			let dailyTotal = await getPointsByDate(null, squadBID,sd,ed);
			chatRoomObj.squadBPoints.dailyTotal[moment(sd).format("MM/DD/YY")]=dailyTotal
		} else {
			console.log(`Using current data:\n date: ${moment(sd).format("MM/DD/YY")}  amount: ${chatRoomObj.squadBPoints.dailyTotal[moment(sd).format("MM/DD/YY")]} update: ${update}`);
		}		
		chatRoomObj.squadBPoints.total = chatRoomObj.squadBPoints.total + chatRoomObj.squadBPoints.dailyTotal[moment(sd).format("MM/DD/YY")]
		daysChecked++;
	}

	if (!chatRoomObj.dailyTotals) {chatRoomObj.dailyTotals = {}}
	daysChecked = 0;
	while (daysChecked < daysToCheck) {
		let sd = moment(startDate).startOf("day").add(daysChecked, "days");
		chatRoomObj.dailyTotals[moment(sd).format("MM/DD/YY")] = {
			squadA: chatRoomObj.squadAPoints.dailyTotal[moment(sd).format("MM/DD/YY")],
			squadB: chatRoomObj.squadBPoints.dailyTotal[moment(sd).format("MM/DD/YY")]
		}
		daysChecked++;
	}

	console.log("chatRoomObj: ",{chatRoomObj});
	await storageService.setObject(chatRoomObj.id, chatRoomObj);
}

export const getLinerPrediction = async (userID = null, refresh) => {
	let goal = await getGoal(userID,false,null,null,true,false)
	const hoursInAWeek = 168;
	let timeRemaining = moment.duration(moment(goal.endDate).diff(moment(Date.now())));
	let hoursElapsed = hoursInAWeek - timeRemaining.asHours();
	let prediction = Math.round((goal.goalProgress / hoursElapsed) * hoursInAWeek);
	// console.log("prediction");
	return prediction
}


export const getPrediction = async (userID = null, refresh) => {

	let goal = await getGoal(userID,true,null,null,true,false)
	let dow = moment().format('ddd')
	if (dow === "Mon" && goal.goalProgress === 0){		
		return goal.goalPoints
	}
	let prediction = await getLinerPrediction(userID, refresh)
	if (prediction !== 0 && prediction !== null && prediction !== undefined){
		return prediction
	} else {

	let url
	let idToken = await getIDToken().catch((error) => {
		throw Error("Error in getIDtoken: " + error);
	});
	if(userID === null){ // if this is null it means use the logged in user
		userID = await getUserID() 
	}
	url = API_URL + "goals/?forecast=true&userId="+userID;
	//console.log("Getting goal object: "+url);
	const response = await Promise.resolve(
		fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		}).catch((error) => {
			throw Error("Error in getting goal: " + error);
		})
	).catch((error) => {
		throw Error("Error in getting goal: " + error);
	});
	return response
		.json()
		.then((data) => {
			//console.log("[getPrediction] Response:", data);
			return Math.round(data.forecast);
		})
		.catch((error) => {
			console.log("[getPrediction] Error in JSON conversion: " + error);
			throw Error(error);
		});
}
}

export const getSquadPredictionFromSumOfMembersPredictions = async (squadID) => {
	let squad = await getSquad(squadID,null,null,true)
	//console.log("Getting predictions for squadID: "+squadID);
	let memberPredictions = 0
	for await (let member of squad.members){
		//console.log({member});
		if (member.accepted === true && member.role !== "roboCoach"){
			let memberGoal = await getGoal(member.user.cognitoId,true,null,null,true,true)
			//console.log({memberGoal});
			if(memberGoal && memberGoal.prediction){				
				//console.log(`Adding prediction of ${memberGoal.prediction} from ${member.user.userName}`);
				memberPredictions = memberPredictions + memberGoal.prediction
			}			
		}
	}
	return memberPredictions
}


export const getSquadPrediction = async (squadID) => {
	return(await getSquadPredictionFromSumOfMembersPredictions(squadID))
/*
	let squadGoal = await getSquadGoal(squadID,null,null,false, true,false)
	const hoursInAWeek = 168;
	let timeRemaining = moment.duration(moment(squadGoal.endDate).diff(moment(Date.now())));
	let hoursElapsed = hoursInAWeek - timeRemaining.asHours();
	let prediction = Math.round((squadGoal.goalProgress / hoursElapsed) * hoursInAWeek);
	return prediction


	// May need to implement caching at some point
	let url
	let idToken = await getIDToken().catch((error) => {
		throw Error("Error in getIDtoken: " + error);
	});
	url = API_URL + "goals/?forecast=true&squadId="+squadID;
	//console.log("Getting goal object: "+url);
	const response = await Promise.resolve(
		fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		}).catch((error) => {
			throw Error("Error in getting forecast: " + error);
		})
	).catch((error) => {
		throw Error("Error in getting forecast: " + error);
	});
	return response
		.json()
		.then((data) => {
			console.log("[getSquadPrediction] Response:", data);
			return Math.round(data.forecast);
		})
		.catch((error) => {
			console.log("[getSquadPrediction] Error in JSON conversion: " + error);
			throw Error(error);
		});
	//*/
}

export const isGoalCompleted = (goal) =>{
	if(goal.evaluated){
		if (goal.success){
			return true
		} else {
			return false
		}
	} else {
		if (goal.goalProgress >= goal.goalPoints) {
			return true
		} else {
			return false
		}
	}
}