import React from "react";
import Countdown from "react-countdown";
import "../../css/SeasonsCountdownTimer.css";

const SeasonsCountdownTimer = ({ countdownDate }) => {
	const renderer = ({ days, hours, minutes, seconds, completed }) => {
		if (completed) {
			// Render a completed state
			return "Done!";
		} else {
			// Render a countdown
			return (
				<div className="countdown">
					<div className="card">
						<div className="countdown-value">{days}</div>
						<div className="countdown-unit">Days</div>
					</div>
					<div className="card">
						<div className="countdown-value">{hours}</div>
						<div className="countdown-unit">Hours</div>
					</div>
					<div className="card">
						<div className="countdown-value">{minutes}</div>
						<div className="countdown-unit">Mins</div>
					</div>
					<div className="card">
						<div className="countdown-value">{seconds}</div>
						<div className="countdown-unit">Secs</div>
					</div>
				</div>
			);
		}
	};
	return <Countdown date={countdownDate} renderer={renderer} />;
};

export default SeasonsCountdownTimer;
