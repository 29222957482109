import {
	IonAlert,
	IonBackButton,
	IonButton,
	IonButtons,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonInput,
	IonItem,
	IonLabel,
	IonPage,
	IonPopover,
	IonProgressBar,
	IonRange,
	IonRow,
	IonSegment,
	IonSegmentButton,
	IonToolbar,
	useIonViewDidEnter,
} from "@ionic/react";
import moment from "moment";
import React, { useRef, useState } from "react";
import Emoji from "react-emoji-render";
import { useHistory } from "react-router";
import "../../css/ActivitiesFeed.css";
import "../../css/Home.css";
import "../../css/Onboarding.css";
import { logAddActivityEvent, logPageView } from "../../services/AnalyticsService";
import { getUser } from "../../services/UserService";
import DidNothingComponent from "../DidNothingComponent";
import IntensityDescriptionComponent from "../IntensityDescriptionComponent";
import { StorageService } from "../../services/StorageService";
const storageService = new StorageService();

const LogFirstWorkout = ({ progress, setProgress, setActivity }) => {
	const history = useHistory();
	const [user, setUser] = useState();
	const [showIntensityModal, setShowIntensityModal] = useState(false);
	const [showNothingModal, setShowNothingModal] = useState(false);
	const [confirmationAlert, setConfirmationAlert] = useState({
		visible: false,
		message: null,
	});
	const [errorAlert, setErrorAlert] = useState({
		visible: false,
		message: null,
	});
	const [placeholderText] = useState("e.g. walking, yardwork, golf, lifted weights");
	const [minutes, setMinutes] = useState(0);
	const [hours, setHours] = useState(0);
	const [totalTime, setTotalTime] = useState(0);
	const [intensity, setIntensity] = useState("normal");
	const [timeUnit, setTimeUnit] = useState("minutes");
	const activity = useRef(null);

	useIonViewDidEnter(async () => {
		logPageView("Log First Workout");
		setProgress(0.6);
		storageService.setItem("lastOnboardingStep", 7);
		let userstored = JSON.parse(await getUser());
		setUser(userstored);
	});

	const logActivity = async () => {
		setConfirmationAlert({ visible: false, message: null });
		let calories, points;
		const normalIntensityMultiplier = 4;
		const moderateIntensityMultiplier = 7;
		const highIntensityMultiplier = 11;
		switch (intensity) {
			case "normal":
				calories = totalTime * normalIntensityMultiplier;
				points = totalTime * 1;
				break;
			case "high":
				calories = totalTime * moderateIntensityMultiplier;
				points = totalTime * 1.5;
				break;
			case "very high":
				calories = totalTime * highIntensityMultiplier;
				points = totalTime * 2;
				break;
			default:
				break;
		}
		let tempActivity = {
			activity: activity.current.value,
			duration: totalTime,
			unit: "minutes",
			calories: calories,
			date: moment().format(),
			points: Math.round(points),
		};
		setActivity(tempActivity);
		logAddActivityEvent(tempActivity);
		history.push("/onboarding/pointsconfirmation");
	};

	const validateActivity = async () => {
		let errorText = "";
		let time = parseInt(hours) * 60 + parseInt(minutes);
		console.log({ time });

		if (activity.current.value === "" || activity.current.value === null) {
			errorText = "Please enter an activity, even if it's something small or was a couple days ago<br/><br/>";
		}
		if (time === 0) {
			errorText = errorText + "Please enter share how long your activity was\n\n";
		}

		if (errorText !== "") {
			setErrorAlert({ visible: true, message: errorText });
		} else {
			setTotalTime(time);
			let text =
				"Your activity was <b>" +
				activity.current.value +
				"</b> you did it for <b>" +
				time +
				" minutes </b> and you did it at <b>" +
				intensity +
				"</b> intensity?";
			setConfirmationAlert({ visible: true, message: text });
		}
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar style={{ "--border-style": "none" }}>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
				</IonToolbar>
				<IonProgressBar value={progress} />
			</IonHeader>
			{user ? (
				<React.Fragment>
					<IonPopover
						isOpen={showNothingModal}
						cssClass="exercise-details-full-screen-popover"
						onDidDismiss={(e) => {
							setShowNothingModal(false);
						}}
					>
						<DidNothingComponent setShowNothingModal={setShowNothingModal} />
					</IonPopover>
					<IonPopover
						isOpen={showIntensityModal}
						cssClass="exercise-details-full-screen-popover"
						onDidDismiss={(e) => {
							setShowIntensityModal(false);
						}}
					>
						<IntensityDescriptionComponent setShowIntensityModal={setShowIntensityModal} />
					</IonPopover>
					<IonAlert
						isOpen={!!confirmationAlert.visible}
						header="Confirm Activity"
						message={confirmationAlert.message}
						buttons={[
							{
								text: "No",
								role: "cancel",
								handler: () => {
									setConfirmationAlert({ visible: false, message: null });
								},
							},
							{
								text: "Confirm",
								role: "ok",
								handler: () => {
									logActivity();
								},
							},
						]}
						onDidDismiss={() => setConfirmationAlert({ visible: false, message: null })}
					/>
					<IonAlert
						isOpen={!!errorAlert.visible}
						header="Oops"
						message={errorAlert.message}
						buttons={[
							{
								text: "Ok",
								role: "ok",
								handler: () => setErrorAlert({ visible: false, message: null }),
							},
						]}
						onDidDismiss={() => setErrorAlert({ visible: false, message: null })}
					/>
					<IonContent>
						<div className="onboarding-main-div">
							<div className="onboarding-title">Let's get you started with some points!</div>

							<div className="onboarding-text-area" style={{ textAlign: "center" }}>
								<p>Share ONE activity you recently did. Remember it can be anything reasonable that you deserve credit for.</p>
							</div>
							<IonGrid>
								<IonItem className="activity-item white-border" mode="ios">
									<div className="field-label" position="stacked">
										What was your activity?
									</div>
								</IonItem>
								<IonItem className="activity-item" mode="ios">
									<IonInput enterkeyhint="done" classname="activity-field" placeholder={placeholderText} type="text" ref={activity} required></IonInput>
								</IonItem>
								<br />
								<div className="onboarding-help-text" style={{ textAlign: "center" }} onClick={() => setShowNothingModal(true)}>
									What if I didn't do anything recently?
								</div>
								<IonItem className="activity-item white-border" style={{ marginTop: "10%" }} mode="ios">
									<div className="field-label" position="stacked">
										How long was your activity?
									</div>
								</IonItem>
								<IonRow style={{ alignItems: "center", textAlign: "center" }}>
									<IonCol size="1"></IonCol>
									<IonCol size="3" style={{ marginLeft: "25px" }}>
										<IonInput className="input-box" max="240" inputMode="numeric" clearOnEdit onIonChange={(e) => setMinutes(e.detail.value)} />
									</IonCol>
									<IonCol size="2"></IonCol>
									<IonCol size="3">
										<IonInput className="input-box" max="12" inputMode="numeric" clearOnEdit onIonChange={(e) => setHours(e.detail.value)} />
									</IonCol>
								</IonRow>
								<IonRow style={{ alignItems: "center", textAlign: "center" }}>
									<IonCol size="1"></IonCol>
									<IonCol size="3" style={{ marginLeft: "25px" }}>
										Minutes
									</IonCol>
									<IonCol size="2"></IonCol>
									<IonCol size="3">Hours</IonCol>
								</IonRow>

								<IonItem className="activity-item white-border" style={{ marginTop: "10%" }} mode="ios">
									<div className="field-label" position="stacked">
										How intense was your activity?
									</div>
								</IonItem>
								<IonRow>
									<div style={{ width: "100%" }}>
										<IonSegment value={intensity} className="intensity-segment-controller" mode="ios" onIonChange={(e) => setIntensity(e.detail.value)}>
											<IonSegmentButton value="normal" className="intensity-segment-button" mode="ios">
												<IonLabel style={{ marginTop: "0px", marginBottom: "0px" }}>Normal</IonLabel>
												<IonLabel className="intensity-emoji">
													<Emoji text=":slightly_smiling_face:" />
												</IonLabel>
											</IonSegmentButton>
											<IonSegmentButton value="high" className="intensity-segment-button" mode="ios">
												<IonLabel style={{ marginTop: "0px", marginBottom: "0px" }}>High</IonLabel>
												<IonLabel className="intensity-emoji">
													<Emoji text=":sweat_smile:" />
												</IonLabel>
											</IonSegmentButton>
											<IonSegmentButton value="very high" className="intensity-segment-button" mode="ios">
												<IonLabel style={{ marginTop: "0px", marginBottom: "0px" }}>Very High</IonLabel>
												<IonLabel className="intensity-emoji">
													<Emoji text=":sign_of_the_horns:" />
												</IonLabel>
											</IonSegmentButton>
										</IonSegment>
									</div>
								</IonRow>

								<br />
								<div className="onboarding-help-text" style={{ textAlign: "center" }} onClick={() => setShowIntensityModal(true)}>
									What do you mean by intensity?
								</div>
							</IonGrid>
						</div>
						<div className="onboarding-footer">
							{activity !== null && (hours !== 0 || minutes !== 0) ? (
								<IonButton onClick={() => validateActivity()} className="onboarding-advance-button">
									Give me points!
								</IonButton>
							) : (
								""
							)}
						</div>
					</IonContent>
				</React.Fragment>
			) : (
				""
			)}
		</IonPage>
	);
};

export default LogFirstWorkout;
