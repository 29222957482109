import { IonAvatar, IonCol, IonContent, IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import "../../css/Home.css";
import "../../css/SquadScoreboard.css";
import { getGoal, getPrediction, isGoalCompleted } from "../../services/PointsAndGoalsService";
import { hasSeasonStarted, isUserinSeason } from "../../services/SeasonsService";
import { getSquadMembers } from "../../services/SquadService";
import { getUser } from "../../services/UserService";
import LoadingComponent from "../LoadingComponent";

const SquadScoreboardComponent = ({ squadID, setSquadMemberDetailsModal }) => {
	const history = useHistory();
	const [squadMembers, setSquadMembers] = useState();
	const [loading, setLoading] = useState();

	//let remainingSquadPoints;

	useEffect(() => {
		loadSquadMembers(squadID);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const loadSquadMembers = async (squadID) => {
		setLoading(true);
		let squadArray = await getSquadMembers(null, squadID);
		setSquadMembers(squadArray);
		await loadMemberData(squadArray);
		setLoading(false);
		return squadArray;
	};

	const loadMemberData = async (squadArray) => {
		let members = [];
		let user = JSON.parse(await getUser(null, false));
		//let seasonObject = await getCurrentSeasons();
		//seasonObject = seasonObject[0];
		let seasonStarted = await hasSeasonStarted();
		let userInSeason = await isUserinSeason(user);
		//seasonStarted = true;
		//userInSeason = false;
		for (let element of squadArray) {
			element.goal = await getGoal(element.userID, false);
			//console.log({ element });
			if (element.goal !== null) {
				if (seasonStarted === true && userInSeason === false) {
					element.predictionText = "";
					element.iconColor = "";
					element.goal.prediction = "?";
				} else {
					if (element.goal.prediction === undefined || element.goal.prediction === null) {
						element.goal.prediction = await getPrediction(element.userID, false);
						if (seasonStarted === true && userInSeason === true) {
							element.goal.prediction = Math.round(element.goal.prediction * 1.1);
						}
					}
					let predictionPercent = Math.round((element.goal.prediction / element.goal.goalPoints) * 100);
					element.predictionPercent = predictionPercent;
					if (isGoalCompleted(element.goal) === true) {
						element.predictionText = "Goal completed";
						element.iconColor = "green";
					} else if (predictionPercent >= 120) {
						element.predictionText = "Going to crush it!";
						element.iconColor = "green";
					} else if (predictionPercent >= 100) {
						element.predictionText = "On track";
						element.iconColor = "green";
					} else if (predictionPercent >= 90) {
						element.predictionText = "Cutting it close";
						element.iconColor = "#D69B04";
					} else if (predictionPercent < 90) {
						element.predictionText = "Off track";
						element.iconColor = "red";
					}
				}
				element.goal.goalPercent = Math.round((element.goal.goalProgress / element.goal.goalPoints) * 100);
				members.push(element);
			} else {
				console.error(`User ${element.userID} doesn't have a goal`);
			}
		}
		members.sort((a, b) => (a.predictionPercent > b.predictionPercent ? -1 : 1));
		setSquadMembers(members);
	};

	const navToProfile = (userID) => {
		setSquadMemberDetailsModal(false);
		history.push("/otherprofile/" + userID);
	};

	return (
		<React.Fragment>
			<LoadingComponent stateController={loading} setStateController={setLoading} />
			<IonContent className="member-list">
				{squadMembers &&
					squadMembers.length > 0 &&
					squadMembers.map((member, i) => {
						console.log({ member });
						let rowBackgroundColor = "#fff";
						return (
							<IonRow className="member-holder" style={{ backgroundColor: `${rowBackgroundColor}` }} key={`${i}`}>
								<IonCol className="member-avatar" size="3" onClick={() => navToProfile(member.userID)}>
									<IonAvatar style={{ width: "75px", height: "75px" }}>
										{member.avatar === undefined || member.avatar === "" || member.avatar === null ? (
											<img src="https://fitsquad-ui-assets.s3-us-west-2.amazonaws.com/empty-avatar.png" alt="" className="fit-fill" />
										) : (
											<img src={member.avatar} alt="" className="fit-fill" />
										)}
									</IonAvatar>
								</IonCol>
								<IonCol className="member-data" size="4">
									<div className="member-name member-data-point-row">
										{member.userName}
										{member.predictionText && member.predictionText === "Goal completed" ? (
											<img src="assets/images/trophy_transparent.gif" alt="" style={{ height: "20px", position: "relative", top: "2px" }} />
										) : (
											""
										)}
									</div>
									<IonRow>
										{member.goal ? (
											<React.Fragment>
												<span className="member-current-points member-data-point-row">{member.goal.goalProgress.toLocaleString()}</span>
												{member.goal.bonusPoints > 0 ? <span className="squad-info-bonus">(+{member.goal.bonusPoints.toLocaleString()})</span> : ""}
												{" / "}
												{member.goal.goalPoints.toLocaleString()}
											</React.Fragment>
										) : (
											""
										)}
									</IonRow>
									<IonRow className="member-percent member-data-point-row">
										{member.goal ? (
											<React.Fragment>
												<span style={{ color: `${member.iconColor}` }}>{member.goal.goalPercent}%</span>
											</React.Fragment>
										) : (
											""
										)}
									</IonRow>
								</IonCol>
								<IonCol className="member-data-prediction-column" size="4">
									{member.predictionText !== undefined ? (
										<React.Fragment>
											<span className="member-data-prediction-text">{member.predictionText}</span>
											<br />
											<span className="member-data-prediction-numbers">Prediction: {member.goal.prediction.toLocaleString()}</span>
										</React.Fragment>
									) : (
										""
									)}
								</IonCol>
							</IonRow>
						);
					})}
			</IonContent>
		</React.Fragment>
	);
};

export default SquadScoreboardComponent;
