/* eslint-disable react-hooks/exhaustive-deps */
import { IonButton, IonCheckbox, IonContent, IonFooter, IonHeader, IonIcon, IonTitle, IonToolbar } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { getSquadTags } from "../../services/TagService";

const JoinSquadFilters = ({ setFilters, filters, setShowFilters }) => {
	const [allTags, setAllTags] = useState([]);
	const [localTags, setLocalTags] = useState([]);

	useEffect(() => {
		setupTags();
	}, []);
	const setupTags = async () => {
		console.log(`Setting up tags: Filters ${JSON.stringify(filters)}`);
		let tagList = await getSquadTags(true);
		let tagListSelected = [];
		tagList.forEach((tag) => {
			let inFilters = filters.includes(tag.id);
			if (inFilters === true) {
				tag.selected = true;
				setLocalTags((localTags) => [...localTags, tag.id]);
			} else {
				tag.selected = false;
			}
			tagListSelected.push(tag);
		});
		setAllTags(tagListSelected);
	};

	const addTag = (id) => {
		let tempAllTags = [];
		allTags.forEach((tag) => {
			if (tag.id === id) {
				tag.selected = true;
				setLocalTags((localTags) => [...localTags, tag.id]);
			}
			tempAllTags.push(tag);
		});
		setAllTags(tempAllTags);
	};

	const removeTag = async (id) => {
		let tempAllTags = [];
		let tempSquadTags = localTags;
		for await (let tag of allTags) {
			// used for await just in case
			if (tag.id === id) {
				let index = tempSquadTags.indexOf(tag.id); // issue here was index was not being found
				if (index !== -1) {
					tempSquadTags.splice(index, 1);
					setLocalTags(tempSquadTags);
				}
				tag.selected = false;
			}
			tempAllTags.push(tag);
		}
		setAllTags(tempAllTags);
	};

	const seeResults = async () => {
		setFilters(localTags); // used local variable becuase messing with props (filters) was just an issue
		setShowFilters(false);
	};

	return (
		<React.Fragment>
			<IonHeader>
				<IonToolbar className="activity-details-toolbar">
					<IonTitle>Squad Tags</IonTitle>
					<IonButton color="tertiary" slot="end" onClick={() => setShowFilters(false)}>
						<IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
					</IonButton>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<div style={{ margin: "16px" }}>
					{allTags.length > 0
						? allTags.map((tagInfo, i) => {
								if (tagInfo.selected === false) {
									return (
										<div key={`${i}`}>
											<IonCheckbox value={tagInfo.id} onClick={() => addTag(tagInfo.id)} />
											<span style={{ verticalAlign: "super", marginLeft: "5px" }}>{tagInfo.tag}</span>
										</div>
									);
								} else {
									return (
										<div key={`${i}`}>
											<IonCheckbox checked value={tagInfo.id} onClick={() => removeTag(tagInfo.id)} />
											<span style={{ verticalAlign: "super", marginLeft: "5px" }}>{tagInfo.tag}</span>
										</div>
									);
								}
						  })
						: ""}
				</div>
			</IonContent>
			<IonFooter className="squad-join-footer">
				<IonButton onClick={() => seeResults()} className="onboarding-advance-button">
					See Results
				</IonButton>
			</IonFooter>
		</React.Fragment>
	);
};

export default JoinSquadFilters;
