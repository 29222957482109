import {
	IonAlert,
	IonBackButton,
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonLoading,
	IonPage,
	IonProgressBar,
	IonToolbar,
	useIonViewDidEnter,
} from "@ionic/react";
import React, { useState } from "react";
import Emoji from "react-emoji-render";
import { useHistory } from "react-router";
import "../../css/Home.css";
import "../../css/Onboarding.css";
import { logGenericEvent, logPageView } from "../../services/AnalyticsService";
import { getFitbitHistory } from "../../services/FitbitService";
import { getUser } from "../../services/UserService";
import { StorageService } from "../../services/StorageService";
const storageService = new StorageService();

const FitbitConfirmation = ({ progress, setProgress, healthKitData, setHealthKitData }) => {
	const history = useHistory();
	const [user, setUser] = useState();
	const [loading, setLoading] = useState(false);
	const [fitbitError, setFitbitError] = useState(false);
	useIonViewDidEnter(async () => {
		logPageView("Successful Fitbit Connection");
		setProgress(0.8);
		storageService.setItem("lastOnboardingStep", 10);
		await createGoalFromFitbit();
		await fetchUser();
		
	});

	const fetchUser = async () => {
		let userstored = JSON.parse(await getUser());
		setUser(userstored);
	};

	const createGoalFromFitbit = async () => {
		setLoading(true);
		let totalExerciseTime;
		let totalCalories;
		let totalSteps;
		let fitbitHistory = await getFitbitHistory().catch((error) => {
			setFitbitError(true);
			logGenericEvent("error connecting with Fitbit: " + error);
			return;
		});
		let percentOfCaloriesFromExercise = 0.15;
		console.log({ fitbitHistory });

		let anyData = true,
			avgWeeklyTime = null,
			avgWeeklyCalories = null,
			avgCaloriesPerMin = null,
			avgWeeklyPoints = null,
			avgWeeklySteps = null,
			weightedExerciseTime = null;
		if (!fitbitHistory) {
			anyData = false;
		} else {
			totalExerciseTime = fitbitHistory["activities-minutesFairlyActive"] + fitbitHistory["activities-minutesVeryActive"];
			weightedExerciseTime = fitbitHistory["activities-minutesFairlyActive"] * 1.5 + fitbitHistory["activities-minutesVeryActive"] * 2;
			totalCalories = fitbitHistory["activities-calories"];
			totalSteps = fitbitHistory["activities-steps"];
			console.log("Total time: " + totalExerciseTime);
			console.log("totalCalories: " + totalCalories);
			avgWeeklyTime = Math.round((totalExerciseTime / 30) * 7);
			avgWeeklyCalories = Math.round((totalCalories / 30) * 7 * percentOfCaloriesFromExercise);
			avgWeeklySteps = Math.round((totalSteps / 30) * 7);
			if (avgWeeklyTime === 0) {
				// just in case
				avgWeeklyTime = Math.round((avgWeeklySteps / 2000 / 3) * 60); // steps/2000 = # of miles / 3mph = hours stepping * 60 mins
			}
			avgCaloriesPerMin = Math.round(avgWeeklyCalories / avgWeeklyTime);
			if (isNaN(avgCaloriesPerMin)) {
				avgCaloriesPerMin = 0;
			}
			//avgCaloriesPerMin = 0;
			if (weightedExerciseTime !== null && weightedExerciseTime > 0) {
				avgWeeklyPoints =
					Math.round(((fitbitHistory["activities-minutesFairlyActive"] / 30) * 7 * 1.5) / 10) * 10 +
					Math.round(((fitbitHistory["activities-minutesVeryActive"] / 30) * 7 * 2) / 10) * 10;
			} else if (avgCaloriesPerMin > 11) {
				avgWeeklyPoints = Math.round((avgWeeklyTime * 2) / 10) * 10;
			} else if (avgCaloriesPerMin > 7) {
				avgWeeklyPoints = Math.round((avgWeeklyTime * 1.5) / 10) * 10;
			} else {
				avgWeeklyPoints = Math.round(avgWeeklyTime / 10) * 10;
			}
			if (avgWeeklyTime === 0 && avgWeeklyCalories === 0 && avgWeeklySteps === 0 && avgCaloriesPerMin === 0) {
				anyData = false;
			}
			logGenericEvent("goal created with Fitbit");
		}

		setHealthKitData({
			avgWeeklyTime: avgWeeklyTime,
			avgWeeklyCalories: avgWeeklyCalories,
			avgWeeklySteps: avgWeeklySteps,
			avgWeeklyPoints: avgWeeklyPoints,
			anyData: anyData,
		});
		setLoading(false);
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar style={{ "--border-style": "none" }}>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
				</IonToolbar>
				<IonProgressBar value={progress} />
			</IonHeader>
			<IonLoading isOpen={loading} onDidDismiss={() => setLoading(false)} message={"Pulling Fitbit Data..."} backdropDismiss />
			<IonAlert
				isOpen={fitbitError}
				header="Uh oh"
				message="We're having trouble pulling your Fitbit data. Try again or continue without it?"
				buttons={[
					{
						text: "Try Again",
						handler: () => {
							createGoalFromFitbit();
						},
					},
					{
						text: "Continue Without",
						handler: () => {
							history.push("/onboarding/setgoal");
						},
					},
				]}
				onDidDismiss={() => setFitbitError(false)}
			/>
			{user ? (
				<React.Fragment>
					<IonContent>
						<div className="onboarding-main-div">
							<div className="onboarding-title">Your Fitbit is connected!</div>
							<div className="onboarding-hero-image-div">
								<img src="assets/images/fitbit-connected.jpg" alt="" style={{ width: "40%" }} />
							</div>
							<div className="onboarding-text-area" style={{ textAlign: "left" }}>
								Whenever your Fitbit picks up steps, calories burned or workouts, you'll get your credit!
								{healthKitData.anyData !== false ? (
									<div>
										<br />
										<span>According to Fitbit over the past month, you averaged the following per week:</span>
										<br />
										<br />
										{healthKitData.avgWeeklySteps > 0 ? (
											<div className="onboarding-hk-datapoint">
												<Emoji text=":walking:" /> {healthKitData.avgWeeklySteps.toLocaleString()} steps
											</div>
										) : (
											""
										)}
										{healthKitData.avgWeeklyTime > 0 ? (
											<div className="onboarding-hk-datapoint">
												<Emoji text=":timer_clock:" /> {healthKitData.avgWeeklyTime.toLocaleString()} minutes of exercise
											</div>
										) : (
											""
										)}
										{healthKitData.avgWeeklyCalories > 0 ? (
											<div className="onboarding-hk-datapoint">
												<Emoji text=":fire:" /> {healthKitData.avgWeeklyCalories.toLocaleString()} calories burned from exercise
											</div>
										) : (
											""
										)}
										<br />
										That's pretty good!
										<br />
										<br />
										{healthKitData.avgWeeklyPoints > 0 ? (
											<React.Fragment>
												<div>Based on this your weely projected points are: </div>
												<div style={{ fontSize: "20px", paddingTop: "10px", fontWeight: "bold" }}>
													{healthKitData.avgWeeklyPoints.toLocaleString()} points per week
												</div>
											</React.Fragment>
										) : (
											""
										)}
									</div>
								) : (
									""
								)}
								<br />
								<br />
								<div>Ready to set your first weekly goal?</div>
							</div>
						</div>
						<div className="onboarding-footer">
							<IonButton routerLink="/onboarding/setgoal" className="onboarding-advance-button">
								Let's do it!
							</IonButton>
						</div>
					</IonContent>
				</React.Fragment>
			) : (
				""
			)}
		</IonPage>
	);
};

export default FitbitConfirmation;
