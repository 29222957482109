import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonPage,
	IonProgressBar,
	IonToolbar,
	useIonViewDidEnter,
	getPlatforms,
	IonLoading,
	IonFooter,
} from "@ionic/react";
import React, { useState } from "react";
import "../../css/Home.css";
import "../../css/Onboarding.css";
import { StorageService } from "../../services/StorageService";
import { Plugins } from "@capacitor/core";
import { updateUser } from "../../services/UserService";
import { useHistory } from "react-router";
import { getChatRoomIDFromSquadID } from "../../services/SquadService";
import { postChatRoomMessage, addEmoji, getChatRooms } from "../../services/ChatService";
import { logGenericEvent, logPageView } from "../../services/AnalyticsService";
import { enableNotifications } from "../../services/NotificationService";
import Lottie from "react-lottie";
import animationData from "../../lotties/love-emoji.json";

const TriggerNotification = ({ progress, setProgress, activity }) => {
	const storageService = new StorageService();
	const [loading, setLoading] = useState(false);
	useIonViewDidEnter(() => {
		logPageView("notifications trigger");
		console.log(JSON.stringify(activity));
		setProgress(0.6);
		sendImmediateNotification();
		// storageService.setItem("lastOnboardingStep", 8);
	});

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};

	const { PushNotifications, LocalNotifications, Device } = Plugins;
	const history = useHistory();

	const sendImmediateNotification = async () => {
		LocalNotifications.schedule({
			notifications: [
				{
					title: "😍😍😍😍😍😍😍😍😍😍😍",
					body: "Riggz gave you some love for your " + activity.activity,
					id: 100,
					schedule: {
						on: new Date(Date.now()),
					},
				},
			],
		});
	};

	const notificationReceived = async (received) => {
		logGenericEvent("Received love " + received);
		history.push("/onboarding/connecttracker");
	};
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar style={{ "--border-style": "none" }}>
					<IonButtons slot="start">
						<IonBackButton defaultHref="/entryscreen" text="" color="primary" />
					</IonButtons>
				</IonToolbar>
				<IonProgressBar value={progress} />
			</IonHeader>
			<IonContent>
				<div className="onboarding-main-div">
					<div className="onboarding-title">Did you feel the love?</div>
					<Lottie options={defaultOptions} height={100} width={100} />

					<div className="onboarding-text-area">
						You should have just received a little love for your earlier workout you logged.
						<br />
						<br />
						Did you get the notification?
					</div>
				</div>
				<div className="onboarding-footer"></div>
			</IonContent>
			<IonFooter className="onboarding-footer">
				<IonButton onClick={() => notificationReceived(true)} className="onboarding-advance-button">
					Yes! 😃
				</IonButton>
				<IonButton onClick={() => notificationReceived(true)} className="onboarding-advance-button">
					No 🙁
				</IonButton>
			</IonFooter>
		</IonPage>
	);
};

export default TriggerNotification;
