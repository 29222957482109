import React from "react";
import { useHistory } from "react-router";
import "../../css/SquadScoreboard.css";
import { logGenericClickEvent, logNavigationEvent } from "../../services/AnalyticsService";
import UserDataComponent from "../UserDataComponent";

const UserProgressComponent = ({ onProfile = false }) => {
	const history = useHistory();

	const navTo = (destination, analyticsName) => {
		logNavigationEvent("user-progress-component", analyticsName);
		history.push(destination);
	};

	return (
		<div>
			<div
				onClick={() => {
					// only Nav to profile if not on profile
					if (onProfile === false) {
						navTo("/profile", "profile");
					}
				}}
			>
				<UserDataComponent />
			</div>
		</div>
	);
};

export default UserProgressComponent;
