import { IonAlert, IonButton, IonCol, IonLoading, IonRow, IonToast } from "@ionic/react";
import React, { useState } from "react";
import { sendSlackNotification } from "../../services/NotificationService";
import { getSquad, getSquadGoal, getSquadNameFromSquadID } from "../../services/SquadService";
import { deleteUserFromSquad, getUserNameOfLoggedInUser } from "../../services/UserService";

const SquadUserAdmin = ({ userObject, squads, setSquads }) => {
	const [deleteUserAlert, setDeleteUserAlert] = useState({
		visible: false,
		squadID: null,
		squadName: null,
	});
	const [deleting, setDeleting] = useState(false);

	const deleteUserTrigger = async (squadID, squadName) => {
		setDeleting(true);
		let userName = await getUserNameOfLoggedInUser();
		//console.log({ squadID });
		//console.log("Kicking user w/ID = " + userObject.cognitoId);
		await deleteUserFromSquad(userObject.cognitoId, squadID)
			.then(async () => {
				sendSlackNotification(
					`*User Removed From Squad*\nRemover: ${userName}\nSquad Name: ${squadName}\nSquad ID: ${squadID}\nUser Removed: ${JSON.stringify(userObject)}`,
					"keyEvent"
				);
				//console.log({ response });
				await getSquad(squadID, null, null, true).then(() => {
					console.log("Squad info updated");
				});
				await getSquadGoal(squadID, null, null, true, true, false).then(() => {
					console.log("Squad goal");
				});
				squads = squads.filter((squads) => squads.id !== squadID);
				console.log("Updates squads: ", { squads });
				setSquads(squads);
			})
			.catch((error) => {
				alert("Trouble deleting user: " + error);
				sendSlackNotification(
					`*Error Removing User From Squad*\nError: ${error}\nRemover: ${userName}\nSquad Name: ${squadName}\nSquad ID: ${squadID}\nUser Removed: ${JSON.stringify(
						userObject
					)}`,
					"error"
				);
				return;
			});
		setDeleting(false);
	};

	return (
		<React.Fragment>
			<IonLoading isOpen={deleting} onDidDismiss={() => setDeleting(false)} message={"Removing squaddie from your squad"} backdropDismiss />
			<IonAlert
				isOpen={deleteUserAlert.visible}
				header="You sure?"
				message={`Are you sure you want to remove ${userObject.userName} from squad ${deleteUserAlert.squadName}?`}
				buttons={[
					{
						text: "Cancel",
						role: "cancel",
						handler: () => {
							setDeleteUserAlert({
								visible: false,
								squadID: null,
								squadName: null,
							});
						},
					},
					{
						text: "Yes",
						role: "ok",
						handler: () => {
							deleteUserTrigger(deleteUserAlert.squadID, deleteUserAlert.squadName);
						},
					},
				]}
				onDidDismiss={() =>
					setDeleteUserAlert({
						visible: false,
						squadID: null,
					})
				}
			/>
			<IonRow className="page-info-container" style={window.innerWidth <= 320 ? { fontSize: "0.75em", paddingTop: "0px" } : { paddingTop: "0px" }}>
				{squads.map((squad, i) => {
					return (
						<IonCol key={i} size="12" className="squad-management-column">
							<IonButton
								color="primary"
								size="small"
								className="squad-management-button"
								onClick={() => setDeleteUserAlert({ visible: true, squadID: squad.id, squadName: squad.name })}
							>
								Remove squaddie from {squad.name}
							</IonButton>
						</IonCol>
					);
				})}
			</IonRow>
		</React.Fragment>
	);
};

export default SquadUserAdmin;
