import { IonHeader, IonTitle, IonToolbar, IonButton, IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React from "react";
import "../css/Home.css";
import "../css/ActivityDetails.css";

const SelectWeeklySquadComponent = ({ modal, setModal, squadID }) => {
	return (
		<React.Fragment>
			<IonHeader>
				<IonToolbar className="activity-details-toolbar">
					<IonTitle>Recent Activity?</IonTitle>
					<IonButton color="tertiary" slot="end" onClick={() => setModal(false)}>
						<IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
					</IonButton>
				</IonToolbar>
			</IonHeader>
			<div style={{ margin: "16px" }}>
				<p>
					Don't worry, your activity didn't have to be today{" "}
					<span role="img" aria-label="smiley">
						🙂
					</span>
					.
				</p>
				<p>
					Did you go for a walk recently?
					<br /> Play with the kids? <br />
					Do some gardening? <br />
					<br />
					All those count!
				</p>
				<p>
					If nothing really comes to mind, just to see how it works, enter <span className="bold">walking</span> for <span className="bold">5 minutes</span>{" "}
					just to keep progressing{" "}
					<span role="img" aria-label="thumbs-up">
						👍
					</span>
				</p>
			</div>
		</React.Fragment>
	);
};

export default SelectWeeklySquadComponent;
