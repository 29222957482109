import { get, patch } from "./APIService"
import { getUser } from "./UserService"
import { StorageService } from "../services/StorageService";
import { shouldRefresh, shouldRefreshBaseValue } from "./RefreshService";
import moment from "moment";
import { premiumItemIDs } from "../models/constants";

/**
 * 
 * Gets all the inventory items possible
 * If it's been more than 60 minutes from the last pull, refreshes it from the server
 * Otherwise returns whats local
 * Stores them locally along with the last updated date
 * Returns all the inventory
 * 
 * @returns array of all inventory
 */

export const getAllInventoryItems = async () => {
    // check local inventory before pulling
    const storageService = new StorageService();
    let refresh = true
    let response
    let allInventory = await storageService.getObject("allInventory");
    let allInventoryLastUpdated = await storageService.getObject("allInventory-last-updated"); // returns a moment
    refresh = await shouldRefreshBaseValue(allInventoryLastUpdated, 60)
    //console.log("Refresh: ",refresh);
    // if refresh is true or allInventory doesnt exist
    // hit the API and store both the results and the current time
    if(refresh === true || allInventory === null) {
        response = await get("inventory")
        await storageService.setObject("allInventory-last-updated", moment())
        await storageService.setObject("allInventory", response)
    } else {
        response = allInventory
    }
    return response    
}

export const getMysteryBagItems = async () => {
    // check local inventory before pulling
    const storageService = new StorageService();
    let refresh = true
    let response
    let allInventory = await storageService.getObject("mysteryBagItems");
    let allInventoryLastUpdated = await storageService.getObject("mysteryBagItems-last-updated"); // returns a moment
    refresh = await shouldRefreshBaseValue(allInventoryLastUpdated, 60)
    //console.log("Refresh: ",refresh);
    // if refresh is true or allInventory doesnt exist
    // hit the API and store both the results and the current time
    if(refresh === true || allInventory === null) {
        response = await get("mysterybag")
        await storageService.setObject("mysteryBagItems-last-updated", moment())
        await storageService.setObject("mysteryBagItems", response)
    } else {
        response = allInventory
    }
    return response    
}

export const getRandomItems = async (number = 5) => {
    let allItems = await getAllInventoryItems()
    let itemIds = []
    let tempIds = []
    let items = []
    for(let i=1;i<number;i++) {
        let num = Math.floor(Math.random() * allItems.length - number);
        let item = allItems.splice(num,1);
        items.push(item[0]);
    }
   return items
}

export const getRandomMysteryBagItems = async (excludedID = null, number = 5) => {
    let allItems = await getMysteryBagItems()
    let items = []
    for(let i=1;i<number;i++) {
        let num
        do {
            num = Math.floor(Math.random() * allItems.length);
            //console.log("Num: "+num);
            //console.log("Item: ",JSON.stringify(allItems[num]));
        } while (allItems[num].inventoryItemId === excludedID)
        let item = allItems.splice(num,1);
        items.push(item[0]);
    }
   return items
}

export const getSpecificMysteryBagItem = async (itemID) => {
    let allItems = await getMysteryBagItems()
    let item = allItems.find((bagItem) => bagItem.inventoryItemId === itemID)
    return item
}

export const getPremiumItems = async (winningID) => {
    let allItems = await getAllInventoryItems()
    let premiumList = []
    for await (let itemID of premiumItemIDs) {
        if (itemID === 0){
            let item = allItems.find((item) => item.id === winningID)
            premiumList.push(item)
        } else {
            let item = allItems.find((item) => item.id === itemID)
            premiumList.push(item)
        }
    }
    return premiumList
}

/**
 * 
 * Simple helper function that refreshes the user object
 * and returns the inventory object within it
 * 
 * @returns 
 * 
 */

export const getUserInventory = async (refresh = false) => {
    let user = JSON.parse(await getUser(null,refresh)) // refresh here to ensure inventory is always up to date
    return user.inventory  
}

/**
 * 
 * Takes an item object from the user inventory 
 * and returns the details for that item based on the system inventory
 * 
 * @param {object} item 
 * @returns 
 */

export const getItemDetailsByItem = async (item) => {
    let allInventoryItems = await getAllInventoryItems()
    let filteredItem = allInventoryItems.find((x) => x.id === item.inventoryItemId)
    //console.log({allInventoryItems});
    return filteredItem
    // Check each inventory item id vs param
    // If item id is found return item
}

/**
 * 
 * Takes and itemID as a string and returns back
 * an object with that item's details
 * 
 * @param {string} itemID 
 * @returns 
 */

export const getItemDetailsByItemID = async (itemID) => {
    let allInventoryItems = await getAllInventoryItems()
    let filteredItem = allInventoryItems.find((x) => x.id === parseInt(itemID))
    return filteredItem
}

/**
 * 
 * Takes an itemID as a string, gets the user's inventory
 * and returns back the quantity of that itemID that the user has
 * If the user doesn't have the item, returns 0
 * 
 * @param {string} itemID 
 * @returns 
 */

export const getQuantityOfItemByItemID = async (itemID, refresh = false) => {
    let userInventory = await getUserInventory(refresh)
    let foundItem = userInventory.find((x) => x.inventoryItemId === parseInt(itemID))
    if (foundItem){
        return foundItem.quantity
    } else {
        return 0
    }
}

/**
 * 
 * Takes a userItemID as a string, gets the user's inventory
 * and returns back the quantity of that userItemID that the user has
 * If the user doesn't have the item, returns 0
 * 
 * @param {string} itemID 
 * @returns 
 */

 export const getQuantityOfItemByUserItemID = async (userItemID, refresh = false) => {
    let userInventory = await getUserInventory(refresh)
    let foundItem = userInventory.find((x) => x.id === parseInt(userItemID))
    if (foundItem){
        return foundItem.quantity
    } else {
        return 0
    }
}

/**
 * 
 * Takes an itemID as a string, gets the user's inventory
 * and returns back the quantity of that itemID that the user has
 * If the user doesn't have the item, returns 0
 * 
 * @param {string} itemID 
 * @returns 
 */

 export const getUserItemIDByInventoryID = async (itemID) => {
    let userInventory = await getUserInventory()
    let foundItem = userInventory.find((x) => x.inventoryItemId === parseInt(itemID))
    if (foundItem){
        return foundItem.id
    } else {
        return null
    }
}

/**
 * 
 * Takes an itemID as a string, gets the user's inventory
 * and returns back the item details in the user's inventory
 * If the user doesn't have the item, returns null
 * 
 * @param {string} itemID 
 * @returns 
 */

 export const getUserItemByInventoryID = async (itemID) => {
    let userInventory = await getUserInventory()
    let foundItem = userInventory.find((x) => x.inventoryItemId === parseInt(itemID))
    if (foundItem){
        return foundItem
    } else {
        return null
    }
}

export const redeemItem = async (itemID) => {
    let response
    await get("inventory/"+itemID).then((result)=>{
        response = result
    }).catch((error)=>{
        throw Error(error)
    })
    return response
}

export const updateInventoryItem = async (itemID,body) => {
    let response
    await patch("inventory/"+itemID,body).then((result)=>{
        response = result
    }).catch((error)=>{
        throw Error(error)
    })
    return response
}