import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButton,
	IonCard,
	IonGrid,
	IonCol,
	IonRow,
	IonAlert,
	IonButtons,
	IonBackButton,
	IonItem,
	IonList,
	IonLabel,
	IonLoading,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import "../css/Home.css";
import "../css/ActivityDetails.css";
import { getActivityDetails, convertDurationToMinutes } from "../services/ActivitiesService";
import moment from "moment";
import { useParams } from "react-router";
import { getUserID, getUserNameFromUserID } from "../services/UserService";
import { deleteActivity } from "../services/FeedService";
import { getMessagesAndActivitiesAllChatRooms } from "../services/ChatService";
import { logPageView } from "../services/AnalyticsService";

const ExerciseDetails = () => {
	const [activityDetails, setActivityDetails] = useState([]);
	const [confirmDeleteActivity, setConfirmDeleteActivity] = useState(false);
	const [deleting, setDeleting] = useState(false);
	const [loading, setLoading] = useState(true);
	const activityID = useParams().activityID;

	useEffect(() => {
		logPageView("exercise-details");
		fetchActivityDetails(activityID);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function fetchActivityDetails(activityID) {
		let activity = JSON.parse(await getActivityDetails(activityID));
		activity["userName"] = await getUserNameFromUserID(activity.userId);
		let currentUserID = await getUserID();
		//let images = await gf.search(activity["activityType"], { limit: 1, sort: "relevant" });
		//console.log("Images " + JSON.stringify(images));
		activity["unit"] = activity["activityData"]["valueUnit"];
		activity["duration"] = activity["activityData"]["value"];
		console.log("Activity: " + JSON.stringify(activity));
		activity["minutes"] = Math.round(await convertDurationToMinutes(activity));
		if (activity.userId === currentUserID) {
			console.log("User can delete");
			activity["canDelete"] = true;
		} else {
			console.log("User cannot delete");
			activity["canDelete"] = false;
		}
		setActivityDetails([activity]);
		setLoading(false);
	}

	const deleteActivityTrigger = async (event, actvityID, confirmation = false) => {
		if (confirmation === false) {
			setConfirmDeleteActivity({
				message: "Are you sure you want to delete this activity?",
				id: actvityID,
			});
		} else {
			setDeleting(true);
			console.log("Deleting activity w/ID = " + actvityID);
			let deleteResult = await deleteActivity(activityID);
			console.log("Delete Result: " + deleteResult);
			await getMessagesAndActivitiesAllChatRooms();
			setDeleting(false);
			window.history.back();
		}
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton text="" />
					</IonButtons>
					<IonTitle>Activity Details</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent className="background">
				<IonAlert
					isOpen={!!confirmDeleteActivity.message}
					header="You sure?"
					message={confirmDeleteActivity.message}
					buttons={[
						{
							text: "Yes",
							role: "ok",
							handler: () => {
								deleteActivityTrigger(null, confirmDeleteActivity.id, true);
							},
						},
						{
							text: "Cancel",
							role: "cancel",
							handler: () => {
								setConfirmDeleteActivity("");
								window.history.back();
							},
						},
					]}
					onDidDismiss={() => setConfirmDeleteActivity("")}
				/>
				<IonLoading isOpen={deleting} onDidDismiss={() => setDeleting(false)} message={"Deleting Activity..."} />
				<IonLoading isOpen={loading} onDidDismiss={() => setLoading(false)} message={"Loading details"} />
				<IonCard>
					{activityDetails.map((activity, i) => {
						return (
							<IonList key={`${i}`}>
								<IonItem button="true" routerLink={`/squadmember/${activity.userId}`}>
									<IonLabel>
										<h3 className="activity-title">Username</h3>
										<h2>{activity.userName}</h2>
									</IonLabel>
								</IonItem>
								<IonItem>
									<IonLabel>
										<h3 className="activity-title">Activity Name</h3>
										<h2>{activity.activityType}</h2>
									</IonLabel>
								</IonItem>
								<IonItem>
									<IonLabel>
										<h3 className="activity-title">Date</h3>
										<h2>{moment(activity.startDate).format("ddd MM/DD/YY")}</h2>
									</IonLabel>
								</IonItem>
								<IonItem>
									<IonLabel>
										<h3 className="activity-title">Time</h3>
										<h2>
											{moment(activity.activityData.startDate).format("h:mm a")} - {moment(activity.activityData.endDate).format("h:mm a")}
										</h2>
									</IonLabel>
								</IonItem>
								<IonItem>
									<IonLabel>
										<h3 className="activity-title">Total mins</h3>
										<h2>{activity.minutes}</h2>
									</IonLabel>
								</IonItem>
								<IonItem>
									<IonLabel>
										<h3 className="activity-title">Points</h3>
										<h2>{Math.round(activity.points).toLocaleString()}</h2>
									</IonLabel>
								</IonItem>
							</IonList>
						);
					})}
					{activityDetails.map((activity, i) => {
						if (activity.activityData.weatherTemperature !== undefined) {
							return (
								<IonList key={`${i}`}>
									<IonItem>
										<IonLabel>
											<h3 className="activity-title">Temperature</h3>
											<h2>{activity.activityData.weatherTemperature}</h2>
										</IonLabel>
									</IonItem>
								</IonList>
							);
						} else {
							return <div key={`${i}`}></div>;
						}
					})}
					{activityDetails.map((activity, i) => {
						if (activity.activityData.weatherTemperature !== undefined) {
							return (
								<IonList key={`${i}`}>
									<IonItem>
										<IonLabel>
											<h3 className="activity-title">Humidity</h3>
											<h2>{activity.activityData.humidity}</h2>
										</IonLabel>
									</IonItem>
								</IonList>
							);
						} else {
							return <div key={`${i}`}></div>;
						}
					})}
					{activityDetails.map((activity, i) => {
						if (activity.activityData.weatherTemperature !== undefined) {
							return (
								<IonList key={`${i}`}>
									<IonItem>
										<IonLabel>
											<h3 className="activity-title">Total Distance</h3>
											<h2>
												{Math.round(activity.activityData.totalDistance).toLocaleString()} {activity.activityData.totalDistanceUnit}
											</h2>
										</IonLabel>
									</IonItem>
								</IonList>
							);
						} else {
							return <div key={`${i}`}></div>;
						}
					})}
					{activityDetails.map((activity, i) => {
						if (activity.activityData.weatherTemperature !== undefined) {
							return (
								<IonList key={`${i}`}>
									<IonItem>
										<IonLabel>
											<h3 className="activity-title">Active Calories Burned</h3>
											<h2>{Math.round(activity.activityData.totalEnergyBurned)}</h2>
										</IonLabel>
									</IonItem>
								</IonList>
							);
						} else {
							return <div key={`${i}`}></div>;
						}
					})}
					{activityDetails.map((activity, i) => {
						if (activity.activityData.sourceName === "Manual Log" && activity.canDelete === true) {
							return (
								<IonGrid key={`${i}`}>
									<IonRow class="ion-text-center">
										<IonCol>
											<IonButton color="danger" fill="solid" onClick={() => deleteActivityTrigger(React.MouseEvent, activity.id)}>
												Delete Activity
											</IonButton>
										</IonCol>
									</IonRow>
								</IonGrid>
							);
						} else {
							return <div key={`${i}`}></div>;
						}
					})}
				</IonCard>
			</IonContent>
		</IonPage>
	);
};

export default ExerciseDetails;
