import React, { useEffect, useRef } from "react";
import "../../css/Home.css";
import "../../css/Seasons.css";

const SeasonsProgressGraphicComponent = ({ userSeasonObject, card }) => {
	const canvasRef = useRef();
	const seasonGoal = 15;
	const weeksInSeason = 10;
	let ctx = null;
	let screenWidth = window.innerWidth;
	let screedWidthWithCard = window.innerWidth - 50;

	// initialize the canvas context
	useEffect(() => {
		drawGraphic();
	}, []);

	const drawGraphic = async () => {
		let currentPoints = 0; // do this so it can be shown without the userObject
		if (userSeasonObject !== undefined) {
			currentPoints = userSeasonObject.totalPoints;
		}
		let rightSideOffset = 50;
		let trophyImageOffset = rightSideOffset - 25;
		let lineLength;
		if (card === true) {
			lineLength = screedWidthWithCard - rightSideOffset - 2;
		} else {
			lineLength = screenWidth - rightSideOffset - 2;
		}
		const canvas = canvasRef.current;
		const context = canvas.getContext("2d");
		let placementPerPoint = Math.floor(lineLength / seasonGoal);
		let placementLocation = placementPerPoint * currentPoints;
		if (placementLocation === 0) {
			placementLocation = 21;
		}

		// Draw the main line

		context.fillStyle = "#92949c";
		context.fillRect(0, 60, lineLength, 5);

		// Draw the goal points circle

		context.beginPath();
		context.arc(lineLength, 60, 20, 0, 2 * Math.PI, false);
		context.strokeStyle = "#525252";
		context.lineWidth = 10;
		context.stroke();
		context.fillStyle = "#ffffff";
		context.fill();

		// Add trophy to goal points circle

		let trophy = new Image();
		trophy.src = "assets/images/trophy-for-circle.png";
		trophy.onload = function () {
			context.drawImage(trophy, lineLength - trophyImageOffset, 35, 50, 50);
		};

		// Draw the lines for the current points circle

		context.fillStyle = "#525252";
		context.fillRect(placementLocation, 62, 2, 30);
		context.fillStyle = "#525252";
		context.fillRect(placementLocation, 62, 2, -30);

		// Draw the current points circle

		context.beginPath();
		context.arc(placementLocation, 62, 20, 0, 2 * Math.PI, false);
		context.strokeStyle = "#1571b3";
		context.lineWidth = 3;
		context.stroke();
		context.fillStyle = "#ffffff";
		context.fill();

		//Add the text in the circle

		context.fillStyle = "#000000";
		context.textAlign = "center";
		context.font = "30px Arial";
		context.fillText(currentPoints, placementLocation - 1, 72);

		//Add the text below goal

		context.fillStyle = "#000000";
		context.textAlign = "center";
		context.font = "20px Arial";
		context.fillText("15", lineLength - 2, 110);
		context.fillText("Goal", lineLength - 2, 130);
	};
	return (
		<React.Fragment>
			{card === true ? (
				<div className="seasons-graphic-holder" style={{ height: "145px" }}>
					<div className="seasons-pts-text">PTS</div>
					<div className="seasons-canvas-holder">
						{screenWidth ? <canvas width={screedWidthWithCard} height="150" style={{ position: "relative", top: "0px" }} ref={canvasRef}></canvas> : ""}
					</div>
				</div>
			) : (
				<div className="seasons-graphic-holder">
					<div className="seasons-pts-text">PTS</div>
					<div className="seasons-canvas-holder">
						{screenWidth ? <canvas width={screenWidth} height="150" style={{ position: "relative", top: "0px" }} ref={canvasRef}></canvas> : ""}
					</div>
				</div>
			)}
		</React.Fragment>
	);
};

export default SeasonsProgressGraphicComponent;
