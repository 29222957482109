import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonItem,
	IonList,
	IonRow,
	IonSpinner,
	useIonViewWillEnter,
} from "@ionic/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ChallengeDetailsComponent from "../../components/Challenges/ChallengeDetailsComponent";
import ChallengeLedgerComponent from "../../components/Challenges/ChallengeLedgerComponent";
import ChallengeProgressComponent from "../../components/Challenges/ChallengeProgressComponent";
import "../../css/ActivityDetails.css";
import "../../css/Home.css";
import { getChallengeDetailsByChallengeID } from "../../services/ChallengeService";

const ChallengeSummary = ({ challengeObject }) => {
	// console.log({ challengeObject });

	return (
		<React.Fragment>
			{challengeObject && challengeObject.challengeDetails ? (
				<React.Fragment>
					<IonCard>
						<IonCardHeader>
							<IonCardTitle>Challenge Progress</IonCardTitle>
						</IonCardHeader>
						{challengeObject.recentStatus ? (
							<React.Fragment>
								<div className="weekly-update-message">{challengeObject.recentStatus}</div>
							</React.Fragment>
						) : (
							""
						)}
						<ChallengeProgressComponent challengeObject={challengeObject} source={"EOW"} />
						<div className="details-card-holder">
							<div className="details-section-title">Challenge progress</div>
							<ChallengeLedgerComponent challengeLedger={challengeObject.ledger} />
						</div>
						<div className="details-card-holder">
							<ChallengeDetailsComponent challengeDetails={challengeObject.challengeDetails} />
						</div>
					</IonCard>
				</React.Fragment>
			) : (
				<IonSpinner />
			)}
		</React.Fragment>
	);
};

export default ChallengeSummary;
