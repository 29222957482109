import { API_URL } from "../models/constants";
import { getIDToken } from "./AuthService";
import { StorageService } from "../services/StorageService";
import { shouldRefresh } from "./RefreshService";
import { sendSlackErrorNotification } from "./NotificationService";
import { getUser, getUserID, getUserNameFromUserID } from "./UserService";

export const post = async (api, body) => {
	//console.log("Attempting to add activity to Activities");
	const url = API_URL + api
	let idToken = await getIDToken().catch((error) => {
		throw Error(`[Post to ${api}] Error in getIDtoken: ${error}`);
	});

	let response
	let statusCode

	await fetch(url, {
		method: "POST",
		body: JSON.stringify(body),
		headers: {
			"Content-Type": "application/json",
			"Accept": "application/json",
			Authorization: idToken,
		},
	}).catch(async (error)=>{
		let userID = await getUserID(false)
		let userName = await getUserNameFromUserID()
		sendSlackErrorNotification(error.status,"POST: "+url,"\nUser ID: "+userID+"\nUserName: "+userName+"\nPOST BODY: ```"+JSON.stringify(body)+"```")
		throw new Error(error.status + " Failed Fetch ");
	}).then(async(result) => {
		// status 404 or 500 will set ok to false
		if (result.ok) {
			// Success: convert data received & run callback
			response = result
			statusCode = result.status

		}
		else {
			let userID = await getUserID(false)
			let userName = await getUserNameFromUserID()
			sendSlackErrorNotification(result.status,"POST: "+url,"\nUser ID: "+userID+"\nUserName: "+userName+"\nPOST BODY: ```"+JSON.stringify(body)+"```")
			throw new Error(result.status + " Failed Fetch ");
		}
	})

	return response
		.json()
		.then((data) => {
			//console.log(`[Post to ${api}] success: ${JSON.stringify(data)}`);
			data.statusCode = statusCode

			return data;
		})
		.catch((error) => {
			console.log(`[Post to ${api}] Error in JSON conversion: ${error}`);
			throw Error(error);
		});
};

export const get = async (api, checkLastUpdated = false, objectToCheck = null, minutesBetweenRefresh = 2880) => {
	/*
	Need to address refreshing here at some point....
	//console.log("Attempting to add activity to Activities");
	const storageService = new StorageService();
	let lastUpdated
	// if checkLastUpdated was set to true and an object was passed, check it for refresh
	// object to check should look like seasons-last-updated.lastUpdated
	if(checkLastUpdated === true && objectToCheck != null) { 
		lastUpdated = await storageService.getObject(objectToCheck)
		let refresh = await shouldRefresh(lastUpdated, minutesBetweenRefresh)
		if (refresh === false){
			return false
		}
	}
    */

	const url = API_URL + api
	let idToken = await getIDToken().catch((error) => {
		throw Error(`[Get ${api}] Error in getIDtoken: ${error}`);
	});
	let response
	let statusCode
	await fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			"Accept": "application/json",
			Authorization: idToken,
		},
	}).catch(async (error)=>{
			let userID = await getUserID(false)
			let userName = await getUserNameFromUserID()
			console.log({error});
			sendSlackErrorNotification(error.status,"\nGET: "+url,"User ID: "+userID+"\nUserName: "+userName+"\nResult: "+error)
			throw new Error(error.status + " Failed Fetch ");
	}).then(async(result) => {
		// status 404 or 500 will set ok to false
		if (result.ok) {
			// Success: convert data received & run callback
			response = result
			statusCode = result.status
		}
		else {
			let userID = await getUserID(false)
			let userName = await getUserNameFromUserID()
			console.log({result});
			sendSlackErrorNotification(result.status,"\nGET: "+url,"User ID: "+userID+"\nUserName: "+userName+"\nResult: "+JSON.stringify(result))
			throw new Error(result.status + " Failed Fetch ");
		}
	})

	return response
		.json()
		.then((data) => {
			//console.log(`[GET ${api}] success: ${JSON.stringify(data)}`);
			if (objectToCheck != null){ // an object that tracks last updated was passed in

			}
			data.statusCode = statusCode
			return data;
		})
		.catch((error) => {
			console.log(`[GET ${api}] Error in JSON conversion: ${error}`);
			throw Error(error);
		});
};

export const patch = async (api, body) => {
	//console.log("Attempting to add activity to Activities");
	const url = API_URL + api
	let idToken = await getIDToken().catch((error) => {
		throw Error(`[Patch to ${api}] Error in getIDtoken: ${error}`);
	});

	let response
	let statusCode
	await fetch(url, {
		method: "PATCH",
		body: JSON.stringify(body),
		headers: {
			"Content-Type": "application/json",
			"Accept": "application/json",
			Authorization: idToken,
		},
	}).catch(async (error)=>{
		let userID = await getUserID(false)
		let userName = await getUserNameFromUserID()
		sendSlackErrorNotification(error.status,"PATCH: "+url,"\nUser ID: "+userID+"\nUserName: "+userName+"\nPATCH BODY: ```"+JSON.stringify(body)+"```")
		throw new Error(error.status + " Failed PATCH ");
	}).then(async(result) => {
		// status 404 or 500 will set ok to false
		if (result.ok) {
			// Success: convert data received & run callback
			statusCode = result.status
			response = result
		}
		else {
			let userID = await getUserID(false)
			let userName = await getUserNameFromUserID()
			sendSlackErrorNotification(result.status,"PATCH: "+url,"\nUser ID: "+userID+"\nUserName: "+userName+"\nPATCH BODY: ```"+JSON.stringify(body)+"```")
			throw new Error(result.status + " Failed PATCH ");
		}
	})

	return response
		.json()
		.then((data) => {
			//console.log(`[Post to ${api}] success: ${JSON.stringify(data)}`);
			data.statusCode = statusCode
			return data;
		})
		.catch((error) => {
			console.log(`[PATCH to ${api}] Error in JSON conversion: ${error}`);
			throw Error(error);
		});
};

export const getPHP = async (url) => {
	let response
	let statusCode
	await fetch(url, {
		method: "GET",
	}).catch(async (error)=>{
			let userID = await getUserID(false)
			let userName = await getUserNameFromUserID()
			console.log({error});
			sendSlackErrorNotification(error.status,"\nGET: "+url,"User ID: "+userID+"\nUserName: "+userName+"\nResult: "+error)
			throw new Error(error.status + " Failed Fetch ");
	}).then(async(result) => {
		// status 404 or 500 will set ok to false
		if (result.ok) {
			// Success: convert data received & run callback
			response = result
			statusCode = result.status
		} else {
			let userID = await getUserID(false)
			let userName = await getUserNameFromUserID()
			console.log({result});
			sendSlackErrorNotification(result.status,"\nGET: "+url,"User ID: "+userID+"\nUserName: "+userName+"\nResult: "+JSON.stringify(result))
			throw new Error(result.status + " Failed Fetch ");
		}
	})
	return response
		.json()
		.then((data) => {		
			data.statusCode = statusCode
			return data;
		})
		.catch((error) => {
			console.log(`[GET ${url}] Error in JSON conversion: ${error}`);
			// throw Error(error);
		});
};

export const postPHP = async (url, data) => {
	let response
	let statusCode
	await fetch(url, {
		method: "POST",
		body: JSON.stringify(data),
		headers: {
			"Content-Type": "application/json",
			"Accept": "application/json",
		},
	}).catch(async (error)=>{
			let userID = await getUserID(false)
			let userName = await getUserNameFromUserID()
			console.log({error});
			sendSlackErrorNotification(error.status,"\nPOST: "+url,"User ID: "+userID+"\nUserName: "+userName+"\nResult: "+error)
			throw new Error(error.status + " Failed Fetch ");
	}).then(async(result) => {
		// status 404 or 500 will set ok to false
		if (result.ok) {
			// Success: convert data received & run callback
			response = result
			statusCode = result.status
			return response
		}
		else {
			let userID = await getUserID(false)
			let userName = await getUserNameFromUserID()
			console.log({result});
			sendSlackErrorNotification(result.status,"\nPOST: "+url,"User ID: "+userID+"\nUserName: "+userName+"\nResult: "+JSON.stringify(result))
			throw new Error(result.status + " Failed Fetch ");
		}
	})
	return response
		.json()
		.then((data) => {		
			data.statusCode = statusCode
			return data;
		})
		.catch((error) => {
			console.log(`[POST ${url}] Error in JSON conversion: ${error}`);
			throw Error(error);
		});
};

export const patchPHP = async (url, data) => {
	console.log("DATA PASSED TO PATCH: ");
	console.log(JSON.stringify(data));
	let response
	let statusCode
	await fetch(url, {
		method: "PATCH",
		body: JSON.stringify(data),
		headers: {
			"Content-Type": "application/json",
			"Accept": "application/json",
		},
	}).catch(async (error)=>{
			let userID = await getUserID(false)
			let userName = await getUserNameFromUserID()
			console.log({error});
			sendSlackErrorNotification(error.status,"\nPATCH PHP: "+url,"User ID: "+userID+"\nUserName: "+userName+"\nResult: "+error)
			throw new Error(error.status + " Failed Fetch ");
	}).then(async(result) => {
		// status 404 or 500 will set ok to false
		if (result.ok) {
			// Success: convert data received & run callback
			response = result
			statusCode = result.status
			return response
		}
		else {
			let userID = await getUserID(false)
			let userName = await getUserNameFromUserID()
			console.log({result});
			sendSlackErrorNotification(result.status,"\nPATCH PHP: "+url,"User ID: "+userID+"\nUserName: "+userName+"\nResult: "+JSON.stringify(result))
			throw new Error(result.status + " Failed Fetch ");
		}
	})
	return response
		.json()
		.then((data) => {		
			data.statusCode = statusCode
			return data;
		})
		.catch((error) => {
			console.log(`[POST ${url}] Error in JSON conversion: ${error}`);
			throw Error(error);
		});
};

export const postPHPJSONResponse = async (url, data) => {
	let response
	let statusCode
	await fetch(url, {
		method: "POST",
		body: JSON.stringify(data),
		headers: {
			"Content-Type": "application/json",
			"Accept": "application/json",
		},
	}).catch(async (error)=>{
			let userID = await getUserID(false)
			let userName = await getUserNameFromUserID()
			console.log({error});
			sendSlackErrorNotification(error.status,"\nGET: "+url,"User ID: "+userID+"\nUserName: "+userName+"\nResult: "+error)
			throw new Error(error.status + " Failed Fetch ");
	}).then(async(result) => {
		// status 404 or 500 will set ok to false
		if (result.ok) {
			// Success: convert data received & run callback
			response = result
			statusCode = result.status
		}
		else {
			let userID = await getUserID(false)
			let userName = await getUserNameFromUserID()
			console.log({result});
			sendSlackErrorNotification(result.status,"\nGET: "+url,"User ID: "+userID+"\nUserName: "+userName+"\nResult: "+JSON.stringify(result))
			throw new Error(result.status + " Failed Fetch ");
		}
	})
	return response
		.json()
		.then((data) => {		
			return data;
		})
		.catch((error) => {
			console.log(`[GET ${url}] Error in JSON conversion: ${error}`);
			throw Error(error);
		});
};