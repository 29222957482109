/* eslint-disable array-callback-return */
import { CameraResultType, CameraSource, Plugins } from "@capacitor/core";
import {
	IonAlert,
	IonBackButton,
	IonButton,
	IonButtons,
	IonChip,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonLoading,
	IonPage,
	IonPopover,
	IonRow,
	IonSkeletonText,
	IonTextarea,
	IonTitle,
	IonToast,
	IonToggle,
	IonToolbar,
	useIonViewDidEnter,
	useIonViewWillEnter,
} from "@ionic/react";
import { closeCircleSharp } from "ionicons/icons";
import React, { useContext, useRef, useState } from "react";
import { useHistory } from "react-router";
import CoachMarkController from "../components/CoachMarks/CoachMarkController";
import CreateSquadCoachMark from "../components/CoachMarks/CreateSquadCoachMark";
import InfoButton from "../components/InfoButton";
import AuthContext from "../contexts/GlobalState";
import "../css/Home.css";
import "../css/SquadCreate.css";
import { fakeSquadNames, fakeSquadSlogans } from "../models/constants";
import { logGenericEvent, logPageView } from "../services/AnalyticsService";
import { getRandomGifFromSearch } from "../services/GifService";
import { coachMarkCheck, getRandomValueFromArray } from "../services/HelperServices";
import { sendSlackNotification } from "../services/NotificationService";
import { addTagsToSquad, createSquad } from "../services/SquadService";
import { StorageService } from "../services/StorageService";
import { getSquadTags } from "../services/TagService";
import { getUser } from "../services/UserService";

const { Device } = Plugins;
const { Camera } = Plugins;

const SquadCreate = ({ onboarding }) => {
	let squadNameRef = useRef(null);
	let squadSloganRef = useRef(null);
	const { login, setOnboardingCompleted, updateUserState, userState } = useContext(AuthContext);
	// const newTagRef = useRef(null);
	const history = useHistory();
	const storageService = new StorageService();
	const [takenPhoto, setTakenPhoto] = useState({
		path: null,
		preview: null,
		url: null,
	});
	const [errorMessage, setErrorMessage] = useState("");
	const [toastMessage, setToastMessage] = useState("");
	const [allTags, setAllTags] = useState([]);
	const [squadTags, setSquadTags] = useState([]);
	const [loading, setLoading] = useState(false);
	const [showCoachMark, setShowCoachMark] = useState(false);

	// const [tagCreator, setTagCreator] = useState();
	// const [newTag, setNewTag] = useState();
	const [isPrivate, setIsPrivate] = useState(false); // requires approval to be joined
	const [isSearchable, setIsSearchable] = useState(true);

	const [cancelAlert, setCancelAlert] = useState(false);
	const [showConfirmation, setShowConfirmation] = useState({
		visible: false,
		message: null,
	});
	const [tooManyTagsAlert, setTooManyTagsAlert] = useState();
	const [squadNamePlaceholder] = useState(getRandomValueFromArray(fakeSquadNames));
	const [squadSloganPlaceholder] = useState(getRandomValueFromArray(fakeSquadSlogans));
	const [showHelpText, setShowHelpText] = useState({
		open: false,
		event: undefined,
		message: null,
	});

	const [platform, setPlatform] = useState();

	useIonViewWillEnter(async () => {
		await coachMarkCheck(setShowCoachMark, "createSquad");
	});

	useIonViewDidEnter(async () => {
		Device.getInfo().then((deviceInfo) => {
			setPlatform(deviceInfo.platform);
		});
		logPageView("squad creation");
		getTags();
	});

	const takePhotoHandler = async () => {
		const photo = await Camera.getPhoto({
			allowEditing: false,
			correctOrientation: true,
			resultType: CameraResultType.DataUrl,
			source: CameraSource.Prompt,
			quality: 80,
			width: 500,
		});

		setTakenPhoto({
			path: photo.dataUrl,
			preview: photo.dataUrl,
			url: false,
		});
	};

	const randomImage = async () => {
		console.log({ squadTags });
		let searchTerm = "fitness";
		if (squadTags.length > 0) {
			let squadTagArray = [];
			for await (let tag of squadTags) {
				squadTagArray.push(tag.tag);
			}
			searchTerm = squadTagArray.toString().replace(",", " ");
		}
		console.log({ searchTerm });
		setTakenPhoto({
			preview: await getRandomGifFromSearch(searchTerm, 50, "pg-13", false),
			url: true,
		});
	};

	const setupFakeSqaud = async () => {
		setTakenPhoto({
			preview: await getRandomGifFromSearch("squad"),
			url: true,
		});
		squadNameRef.current.value = getRandomValueFromArray(fakeSquadNames);
		squadSloganRef.current.value = getRandomValueFromArray(fakeSquadSlogans);
	};

	const getTags = async () => {
		let tagList = await getSquadTags(true);
		let tagListSelected = [];
		tagList.forEach((tag) => {
			tag.selected = false;
			tagListSelected.push(tag);
		});
		setAllTags(tagListSelected);
	};

	const setupSquad = async (confirmation = false) => {
		let squadName = squadNameRef.current.value;
		let squadSlogan = squadSloganRef.current.value;
		console.log({ takenPhoto });
		let squadImage = takenPhoto.preview;
		console.log("squadImage: " + squadImage);
		if (!squadName) {
			setErrorMessage("Missing squad name");
			return;
		}
		if (!squadSlogan) {
			setErrorMessage("Missing squad slogan");
			return;
		}
		if (squadImage === null) {
			setErrorMessage("Missing squad image");
			return;
		}
		if (confirmation === false) {
			console.log({ isPrivate });
			console.log({ isSearchable });
			let message =
				"Confirm you'd like to create this squad:<br/><strong>Name</strong><br/>" +
				squadName +
				"<br/><strong>Slogan</strong><br/>" +
				squadSlogan +
				"<br/><strong>Tags</strong><br/>";
			let numTags = squadTags.length;
			let tagNumber = 1;
			squadTags.forEach((tag) => {
				if (tagNumber === numTags) {
					message = message + tag.tag;
				} else {
					message = message + tag.tag + ", ";
				}
				tagNumber++;
			});
			message = message + "</span>";
			setShowConfirmation({ visible: true, message: message });
		} else {
			setLoading(true);
			let body = {
				name: squadName,
				slogan: squadSlogan,
				showInSearch: isSearchable,
				isPrivate: isPrivate,
			};
			if (takenPhoto.url === true) {
				body.imageUrl = squadImage;
			} else {
				body.image = squadImage;
			}
			let squadCreateResult = await createSquad(body, takenPhoto.url).catch((error) => {
				setLoading(false);
				setErrorMessage(error);
			});
			if (squadCreateResult.message) {
				setLoading(false);
				setErrorMessage(squadCreateResult.message);
			}
			if (squadCreateResult.id) {
				let userObject = userState.user;
				console.log({ userObject });
				if (squadTags.length > 0) {
					let tagIDs = [];
					squadTags.forEach((tag) => {
						tagIDs.push(tag.id);
					});
					let addTagResult = await addTagsToSquad(squadCreateResult.id, tagIDs);
					console.log({ addTagResult });
				}
				logGenericEvent("successfully created squad");
				sendSlackNotification("*New squad created!*\nImage: " + squadCreateResult.image + "\nSquad Name: " + squadName + "\n", "creation");
				setLoading(false);
				if (onboarding === true) {
					await storageService.setObject("onboarding", { completed: true });
					//let deviceInfo = Device.getInfo();
					//userObject.deviceInfo = deviceInfo;
					//await login(userObject);
					setOnboardingCompleted(true);
					history.push("/squadcreatecompleteonboarding/" + squadCreateResult.id);
				} else {
					await updateUserState();
					history.push("/squadcreatecomplete/" + squadCreateResult.id);
				}
			}
			console.log("Squad create result: " + JSON.stringify(squadCreateResult));
		}
	};

	const addSquadTag = async ({ id, tag }) => {
		console.log("Tag ID: " + id + " Tag: " + tag);
		let tempAllTags = [];
		if (squadTags.length < 5) {
			allTags.forEach((tag) => {
				if (tag.id === id) {
					tag.selected = true;
					setSquadTags((squadTags) => [...squadTags, tag]);
				}
				tempAllTags.push(tag);
			});
			setAllTags(tempAllTags);
		} else {
			setTooManyTagsAlert(true);
		}
	};

	const removeSquadTag = async ({ id, tag }) => {
		console.log("Tag ID: " + id + " Tag: " + tag);
		let tempAllTags = [];
		let tempSquadTags = squadTags;
		allTags.forEach((tag) => {
			if (tag.id === id) {
				let index = tempSquadTags.indexOf(tag);
				if (index !== -1) {
					tempSquadTags.splice(index, 1);
					setSquadTags(tempSquadTags);
				}
				tag.selected = false;
			}
			tempAllTags.push(tag);
		});
		setAllTags(tempAllTags);
	};

	const cancelCreate = async (confirmation = false) => {
		if (confirmation === false) {
			setCancelAlert(true);
		} else {
			setCancelAlert(false);
			await storageService.setObject("onboarding", { completed: true });
			let userObject = userState.user;
			if (!userObject) {
				userObject = JSON.parse(await getUser());
			}
			//let deviceInfo = Device.getInfo();
			//userObject.deviceInfo = deviceInfo;
			await login(userObject);
			setOnboardingCompleted(true);
			history.push("/home");
		}
	};
	/*
	const createTag = async () => {
		//let newTag = newTagRef.current.value;
		if (!newTag) {
			setTagCreator(true);
		} else {
			let tag = {
				id: null,
				tag: newTag,
				createdAt: moment().format(),
				updatedAt: moment().format(),
			}
			setSquadTags((squadTags) => [...squadTags, tag]);
			setTagCreator(false);
		}
	};
*/
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
					<IonTitle>Create a Squad</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonPopover
					isOpen={showCoachMark}
					cssClass="chat-room-tool-tip-1"
					onDidDismiss={() => {
						setShowCoachMark(false);
					}}
				>
					<div style={{ position: "absolute", top: "0%", left: "90%", fontSize: "30px", zIndex: "200000" }} onClick={() => setShowCoachMark(false)}>
						<IonIcon icon={closeCircleSharp}></IonIcon>
					</div>
					<CreateSquadCoachMark setShowCoachMark={setShowCoachMark} />
				</IonPopover>
				<IonPopover
					event={showHelpText.event}
					isOpen={showHelpText.open}
					cssClass="info-popover"
					onDidDismiss={(e) => {
						setShowHelpText({ open: false, event: undefined });
						//setScroller(false);
					}}
				>
					<div>{showHelpText.message}</div>
				</IonPopover>
				<IonAlert
					isOpen={!!showConfirmation.visible}
					header="Confirm New Squad"
					message={showConfirmation.message}
					buttons={[
						{
							text: "Cancel",
							role: "cancel",
							handler: () => {
								setShowConfirmation({
									visible: false,
									message: null,
								});
							},
						},
						{
							text: "Confirm",
							role: "ok",
							handler: () => setupSquad(true),
						},
					]}
					onDidDismiss={() =>
						setShowConfirmation({
							visible: false,
							message: null,
						})
					}
				/>
				<IonAlert
					isOpen={cancelAlert}
					header="Cancel Squad Creation?"
					message="Are you sure you'd like to skip creating a squad?"
					buttons={[
						{
							text: "No",
							role: "cancel",
							handler: () => {
								setCancelAlert(false);
							},
						},
						{
							text: "Yes",
							role: "ok",
							handler: () => cancelCreate(true),
						},
					]}
					onDidDismiss={() => setCancelAlert(false)}
				/>
				<IonAlert
					isOpen={!!errorMessage}
					header="Oops"
					message={errorMessage}
					buttons={[
						{
							text: "Got it",
							role: "ok",
							handler: () => {
								setErrorMessage("");
							},
						},
					]}
					onDidDismiss={() => setErrorMessage("")}
				/>
				<IonAlert
					isOpen={tooManyTagsAlert}
					header="Oops"
					message="Your squad can have a max of 5 tags. Please remove one before adding another"
					buttons={[
						{
							text: "Ok",
							role: "ok",
							handler: () => {
								setTooManyTagsAlert(false);
							},
						},
					]}
					onDidDismiss={() => setTooManyTagsAlert(false)}
				/>
				<IonToast isOpen={!!toastMessage} onDidDismiss={() => setToastMessage(false)} message={toastMessage} duration={1000} />
				<IonLoading isOpen={loading} onDidDismiss={() => setLoading(false)} message={"Creating Squad..."} />
				<IonGrid>
					<IonRow className="ion-text-center">
						<IonCol>
							<div className="image-preview">
								{!takenPhoto.preview && (
									<img src="assets/images/add-photo-button-square.jpg" className="onboarding-add-photo-button" alt="" onClick={takePhotoHandler} />
								)}
								{takenPhoto.preview && <img src={takenPhoto.preview} alt="Preview" onClick={takePhotoHandler} />}
							</div>
							<span className="onboarding-help-text" onClick={() => randomImage()}>
								use random image
							</span>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol>
							<IonItem>
								<IonLabel style={{ height: "20px" }} position="floating">
									Squad Name
								</IonLabel>
								<IonInput type="text" ref={squadNameRef} placeholder={squadNamePlaceholder} required></IonInput>
							</IonItem>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol>
							<IonItem>
								<IonLabel style={{ height: "20px" }} position="floating">
									Squad Slogan
								</IonLabel>
								<IonTextarea type="text" ref={squadSloganRef} placeholder={squadSloganPlaceholder} spellCheck required></IonTextarea>
							</IonItem>
						</IonCol>
					</IonRow>
					<br />
					<IonRow>
						<IonCol>
							<div style={{ paddingLeft: "20px" }}>
								Squad Tags <span style={{ fontStyle: "italic", fontSize: "14px" }}>(max. 5)</span> <br />
								<div className="tag-holder">
									{squadTags &&
										squadTags.map((tagInfo, i) => {
											return (
												<IonChip onClick={() => removeSquadTag({ id: tagInfo.id, tag: tagInfo.tag })} key={`${i}`} outline="false" color="tertiary">
													<IonLabel>{tagInfo.tag}</IonLabel>
												</IonChip>
											);
										})}
								</div>
							</div>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol>
							<div style={{ paddingLeft: "20px" }}>
								{allTags.length > 0 ? (
									allTags.map((tagInfo, i) => {
										if (tagInfo.selected === false) {
											return (
												<IonChip onClick={() => addSquadTag({ id: tagInfo.id, tag: tagInfo.tag })} key={`${i}`} outline="false" color="tertiary">
													<IonLabel>{tagInfo.tag}</IonLabel>
												</IonChip>
											);
										}
									})
								) : (
									<div>
										<h3>
											<IonSkeletonText animated style={{ width: "80%" }} />
										</h3>
										<p>
											<IonSkeletonText animated style={{ width: "60%" }} />
										</p>
										<p>
											<IonSkeletonText animated style={{ width: "40%" }} />
										</p>
									</div>
								)}
							</div>
						</IonCol>
					</IonRow>
					<br />
					<IonRow style={{ textAlign: "center" }}>
						<IonCol size="6">
							Private Squad
							<InfoButton message="Requires your approval before a Squaddie can join" />
							<br />
							<IonToggle
								checked={isPrivate}
								onClick={() => {
									isPrivate ? setIsPrivate(false) : setIsPrivate(true);
								}}
								color="tertiary"
							/>
						</IonCol>
						<IonCol size="6">
							Visible in Search <InfoButton message="This squad will show up in search results within the app" />
							<br />
							<IonToggle
								checked={isSearchable}
								onClick={() => {
									isSearchable ? setIsSearchable(false) : setIsSearchable(true);
								}}
								color="tertiary"
							/>
						</IonCol>
					</IonRow>
					<br />
					<IonRow className="ion-margin-top">
						<IonCol className="ion-text-center">
							<IonButton onClick={() => setupSquad(false)}>Create Squad</IonButton>
						</IonCol>
						{onboarding === true ? (
							<IonCol className="ion-text-center">
								<IonButton className="button-as-link" style={{ fontSize: "14px" }} onClick={() => cancelCreate()}>
									I don't want to <br />
									create a squad right now
								</IonButton>
							</IonCol>
						) : (
							""
						)}
					</IonRow>
					{platform && platform === "web" ? (
						<IonRow className="ion-margin-top">
							<IonCol className="ion-text-center">
								<IonButton fill="outline" onClick={setupFakeSqaud}>
									Create Fake Squad
								</IonButton>
							</IonCol>
						</IonRow>
					) : (
						""
					)}
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default SquadCreate;
