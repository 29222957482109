import React from "react";

const JoinSquadCoachMark = ({ setShowCoachMark }) => {
	return (
		<React.Fragment>
			<div style={{ width: "30%", position: "absolute", top: "3%" }}>
				<img src="assets/images/arrow-white-up-right-2.png" alt="" style={{ height: "70px" }} />
				<br />
				Search for squads by name or Squad Code
			</div>
			<div style={{ width: "30%", position: "absolute", top: "26%", left: "27%" }}>
				Tap the squad image to see more details
				<br />
				<img src="assets/images/arrow-white-down-left.png" alt="" style={{ height: "70px" }} />
			</div>
			<div style={{ width: "60%", position: "absolute", left: "52%", top: "53%" }}>
				<div style={{ width: "40%", float: "left" }}>Request to join and the Squad Leader will be notified</div>
			</div>
		</React.Fragment>
	);
};

export default JoinSquadCoachMark;
