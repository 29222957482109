import {
	IonAlert,
	IonAvatar,
	IonBackButton,
	IonButton,
	IonButtons,
	IonChip,
	IonCol,
	IonContent,
	IonHeader,
	IonItem,
	IonLabel,
	IonLoading,
	IonPage,
	IonRefresher,
	IonRefresherContent,
	IonRow,
	IonSpinner,
	IonTitle,
	IonToast,
	IonToolbar,
	useIonViewDidEnter,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { loadingMessages } from "../../models/constants";
import { logGenericEvent } from "../../services/AnalyticsService";
import { getRandomValueFromArray } from "../../services/HelperServices";
import { sendSlackNotification } from "../../services/NotificationService";
import { getAllSquads, getSquad } from "../../services/SquadService";
import { acceptUserToSquad, deleteUserFromSquad, getUser, getUserID } from "../../services/UserService";
import AuthContext from "../../contexts/GlobalState";
import SquadsManaged from "./SquadsManaged";
import SquadsMember from "./SquadsMember";

const ManageSquads = () => {
	const { userState, updateUserState } = useContext(AuthContext);
	const [userObj, setUserObj] = useState();
	const [loading, setLoading] = useState();
	const [squadsManaged, setSquadsManaged] = useState();
	const [squadsMember, setSquadsMember] = useState();
	const [pendingInvitations, setPendingInvitations] = useState([]);
	const [pendingRequests, setPendingRequests] = useState([]);
	const [pendingRequestsToJoin, setPendingRequestsToJoin] = useState([]);

	useIonViewDidEnter(() => {
		getKeyObjects();
	});

	const getSquadRequests = async (squadID) => {
		setPendingRequests([]);
		//console.log(`SquadID: ${squadID}`);
		let squadInfo = await getSquad(squadID, null, null, true);
		//console.log({ squadInfo });
		for (let members of squadInfo.members) {
			if (members.requestDate !== null && members.accepted === false && members.invited === false) {
				// user must have requested
				members.squadName = squadInfo.name;
				members.squadID = squadID;
				//console.log("members:", { members });
				members.fontSize = "1em";
				if (members.user.userName.length > 11) {
					members.fontSize = "0.75em";
				}
				if (members.user.userName.length > 17) {
					members.fontSize = "0.75em";
				}
				setPendingRequests((pendingRequests) => [...pendingRequests, members]);
			}
		}
	};

	const getKeyObjects = async (event) => {
		setLoading(true);
		let user = JSON.parse(await getUser(null, true));
		setSquadsManaged([]);
		setSquadsMember([]);
		setUserObj(user);
		if (user.userType === "facilitator") {
			let allSquads = await getAllSquads();
			console.log({ allSquads });
			for (let squad of allSquads) {
				if (squad.isPrivate === false) {
					// need to mangle this so it matches the format from the other logical path
					squad.squadId = squad.id;
					squad.squad = squad;
					setSquadsManaged((squadsManaged) => [...squadsManaged, squad]);
					getSquadRequests(squad.squadId); // Get pending requests
				}
			}
		}
		if (user.squads.length > 0) {
			// make sure the user is in a squad
			for (let squad of user.squads) {
				// add to the respective temparrays
				if (squad.accepted === true) {
					if (squad.role === "owner") {
						setSquadsManaged((squadsManaged) => [...squadsManaged, squad]);
						getSquadRequests(squad.squadId); // Get pending requests
					} else if (squad.role === "normal") {
						setSquadsMember((squadsMember) => [...squadsMember, squad]);
					}
				} else if (squad.invited === true) {
					setPendingInvitations((pendingInvitations) => [...pendingInvitations, squad]);
				}
			}
		}
		if (event) event.detail.complete();
		setLoading(false);
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton text="" />
					</IonButtons>
					<IonTitle>Manage Squads</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonRefresher slot="fixed" onIonRefresh={getKeyObjects}>
					<IonRefresherContent refreshingText="Updating Squad List"></IonRefresherContent>
				</IonRefresher>
				<IonLoading isOpen={loading} onDidDismiss={() => setLoading(false)} backdropDismiss message={getRandomValueFromArray(loadingMessages)} />
				{/*
				pendingInvitations && pendingInvitations.length > 0 ? (
					<React.Fragment>
						<div className="section-title ">Pending Invitations</div>
						{pendingInvitations.map((squad, i) => {
							return <div key={i}>{JSON.stringify(squad)}</div>;
						})}
					</React.Fragment>
				) : (
					""
				)
				*/}

				<div className="section-title ">Squads You Lead</div>
				{!squadsMember ? (
					<IonSpinner />
				) : (
					<React.Fragment>
						{squadsManaged.length > 0 ? (
							<SquadsManaged
								squadsManaged={squadsManaged}
								pendingRequests={pendingRequests}
								setPendingRequests={setPendingRequests}
								getKeyObjects={getKeyObjects}
							/>
						) : (
							<div style={{ paddingLeft: "20px" }}>You're not currently a Squad Leader</div>
						)}
					</React.Fragment>
				)}
				<div className="section-title ">Squads Where You're a Member </div>
				{!squadsMember ? (
					<IonSpinner />
				) : (
					<React.Fragment>
						{squadsMember.length > 0 ? (
							<SquadsMember squadsMember={squadsMember} setSquadsMember={setSquadsMember} getKeyObjects={getKeyObjects} />
						) : (
							<div style={{ paddingLeft: "20px" }}>You're not currently a member of a squad</div>
						)}
					</React.Fragment>
				)}
			</IonContent>
		</IonPage>
	);
};

export default ManageSquads;
