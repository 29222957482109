import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonCardContent, IonButtons, IonBackButton, useIonViewWillEnter } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import "../css/Home.css";

const TestGif = () => {
	const [buttons, setButtons] = useState([]);
	const [gifComplete, setGifComplete] = useState(false);
	const canvasRef = useRef();

	useEffect(() => {}, []);

	useIonViewWillEnter(async () => {
		await drawGraphic();
	});

	// initialize the canvas contextddd
	useEffect(() => {}, []);

	const drawGraphic = async () => {
		const canvas = canvasRef.current;
		const context = canvas.getContext("2d");

		// Add trophy to goal points circle

		let trophy = new Image();
		trophy.src = "https://media.giphy.com/media/Wr8PrRVqnGOLE48FWk/giphy.gif";
		trophy.onload = function () {
			context.drawImage(trophy, 0, 0);
			// context.font = "40px Courier";
			// context.fillText("TEST", 210, 75); // THIS IS THE PLACE TEXT IS EMBEDDED INTO THE PICTURE
		};
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
					<IonTitle>Test Gif</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent className="background">
				<canvas height={800} style={{ position: "relative", top: "0px" }} ref={canvasRef}></canvas>
			</IonContent>
		</IonPage>
	);
};

export default TestGif;
