import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonBackButton,
	IonCol,
	IonAvatar,
	IonRow,
	IonLoading,
	useIonViewDidEnter,
} from "@ionic/react";
import React, { useState } from "react";
import "../../css/Home.css";
import "../../css/ActivityDetails.css";

import { useParams } from "react-router";

import moment from "moment";

import { getSquad, getSquadGoal, getUsersInSquad } from "../../services/SquadService";

import "../../css/CompetitionCompleted.css";
import { getCompetitionByID } from "../../services/CompetitionService";
import Emoji from "react-emoji-render";

const CompetitionCompletedLastWeek = () => {
	const competitionID = useParams().competitionID;
	const [loading, setLoading] = useState(false);
	const [squadAGoalObject, setSquadAGoalObject] = useState();
	const [squadBGoalObject, setSquadBGoalObject] = useState();
	const [squadAObject, setSquadAObject] = useState();
	const [squadBObject, setSquadBObject] = useState();
	const [squadAMembers, setSquadAMembers] = useState();
	const [squadBMembers, setSquadBMembers] = useState();
	const [squadAHandicap, setsquadAHandicap] = useState();
	const [squadBHandicap, setsquadBHandicap] = useState();
	const [squadATitles, setSquadATitles] = useState({
		mvpID: null,
		overAchieverID: null,
		fastID: null,
	});
	const [squadBTitles, setSquadBTitles] = useState({
		mvpID: null,
		overAchieverID: null,
		fastID: null,
	});

	//let remainingSquadPoints;

	useIonViewDidEnter(async () => {
		await fetchActivityDetails();
	}, []);

	async function fetchActivityDetails() {
		//TODO: Put content into DB for randomizing
		//TODO: Show table of activities, broken by day to help user understand what happened that week
		//TODO: Show week over week performance
		//TODO: Show achievements, highlight key performances etc.
		//let user = await getUser();
		loadSquadData();
	}

	const loadSquadData = async () => {
		let competitionObject = await getCompetitionByID(competitionID);
		let startDate = moment(competitionObject.startDate);
		let endDate = moment(competitionObject.endDate);
		// Setup Squad A
		setSquadAObject(await getSquad(competitionObject.squadIdA));
		let squadAGoal = await getSquadGoal(competitionObject.squadIdA, startDate, endDate, true, false);

		// Setup Squad B
		setSquadBObject(await getSquad(competitionObject.squadIdB));
		let squadBGoal = await getSquadGoal(competitionObject.squadIdB, startDate, endDate, true, false);

		setLoading(true);

		//Setup Squad A Members
		let squadAMembers = await getUsersInSquad(competitionObject.squadIdA, true, false, startDate, endDate);
		squadAMembers.sort((a, b) => (a.goalProgress > b.goalProgress ? -1 : 1));
		let mvpA = squadAMembers[0].id;
		squadAMembers.sort((a, b) => (a.goalProgress / a.goalPoints > b.goalProgress / b.goalPoints ? -1 : 1));
		let i = 0;
		while (squadAMembers[i].id === mvpA && i < squadAMembers.length) {
			i++;
		}
		let overAchieverA = squadAMembers[i].id;
		squadAMembers.sort((a, b) => (a.goalPoints > b.goalPoints ? -1 : 1));
		i = 0;
		while ((squadAMembers[i].id === mvpA || squadAMembers[i].id === overAchieverA) && i < squadAMembers.length) {
			i++;
		}
		let mostAmbitiousA = squadAMembers[i].id;
		squadAMembers.sort((a, b) => (a.goalProgress > b.goalProgress ? -1 : 1));
		setSquadAMembers(squadAMembers);
		setSquadATitles({
			mvpID: mvpA,
			overAchieverID: overAchieverA,
			mostAmbitiousID: mostAmbitiousA,
		});
		console.log("squad A Members: ", { squadAMembers });

		//Setup Squad A Members
		let squadBMembers = await getUsersInSquad(competitionObject.squadIdB, true, false, startDate, endDate);
		squadBMembers.sort((a, b) => (a.goalProgress > b.goalProgress ? -1 : 1));
		let mvpB = squadBMembers[0].id;
		squadBMembers.sort((a, b) => (a.goalProgress / a.goalPoints > b.goalProgress / b.goalPoints ? -1 : 1));
		i = 0;
		while (squadBMembers[i].id === mvpB && i < squadBMembers.length) {
			i++;
		}
		let overAchieverB = squadBMembers[i].id;
		squadBMembers.sort((a, b) => (a.goalPoints > b.goalPoints ? -1 : 1));
		i = 0;
		while ((squadBMembers[i].id === mvpB || squadBMembers[i].id === overAchieverB) && i < squadBMembers.length) {
			i++;
		}
		let mostAmbitiousB = squadBMembers[i].id;
		squadBMembers.sort((a, b) => (a.goalProgress > b.goalProgress ? -1 : 1));
		setSquadBMembers(squadBMembers);
		setSquadBTitles({
			mvpID: mvpB,
			overAchieverID: overAchieverB,
			mostAmbitiousID: mostAmbitiousB,
		});
		console.log("squad B Members: ", { squadBMembers });

		// Setup Handicap
		let aHandicap, bHandicap;
		if (squadAGoal.goalPoints > squadBGoal.goalPoints) {
			aHandicap = "n/a";
			bHandicap = Math.round((squadAGoal.goalPoints / squadBGoal.goalPoints) * 100) / 100;
			squadBGoal.goalPoints = squadAGoal.goalPoints;
			squadBGoal.goalProgress = Math.round(squadBGoal.goalProgress * bHandicap);
		} else {
			bHandicap = "n/a";
			aHandicap = Math.round((squadAGoal.goalPoints / squadBGoal.goalPoints) * 100) / 100;
			squadAGoal.goalPoints = squadBGoal.goalPoints;
			squadAGoal.goalProgress = Math.round(squadAGoal.goalProgress * aHandicap);
		}
		setSquadAGoalObject(squadAGoal);
		setSquadBGoalObject(squadBGoal);
		setsquadAHandicap(aHandicap);
		setsquadBHandicap(bHandicap);
		setLoading(false);
	};

	return (
		<IonPage>
			<IonContent>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/home" text="" color="light" />
						</IonButtons>
						<IonTitle>The Results</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonLoading isOpen={loading} onDidDismiss={() => setLoading(false)} message={"Loading some amazing people"} duration={10000} />
				<div id="top-part" className="competition-scoreboard-header">
					<div className="competition-squad-data-holder">
						<IonRow>
							<IonCol style={{ marginLeft: "20px", textAlign: "center" }} size="4">
								{squadAGoalObject && squadBGoalObject && squadAGoalObject.goalProgress > squadBGoalObject.goalProgress ? (
									<IonCol style={{ textAlign: "center", backgroundColor: "yellow", color: "black" }} size="4">
										WINNING TEAM!
									</IonCol>
								) : (
									<IonCol style={{ textAlign: "center" }} size="4"></IonCol>
								)}
							</IonCol>
							<IonCol style={{ textAlign: "center" }} size="3"></IonCol>

							{squadAGoalObject && squadBGoalObject && squadBGoalObject.goalProgress > squadAGoalObject.goalProgress ? (
								<IonCol style={{ textAlign: "center", backgroundColor: "yellow", color: "black" }} size="4">
									WINNING TEAM!
								</IonCol>
							) : (
								<IonCol style={{ textAlign: "center" }} size="4"></IonCol>
							)}
						</IonRow>
						<IonRow style={{ marginLeft: "20px" }}>
							{squadAObject ? (
								<IonCol className="squad-a-image-holder" size="4">
									<IonAvatar className="squad-image">
										<img src={squadAObject.image} alt="" />
									</IonAvatar>
									<span className="competition-squad-name">{squadAObject.name}</span>
									<div className="competition-squad-slogan">"{squadAObject.slogan}"</div>
								</IonCol>
							) : (
								""
							)}
							<IonCol className="vs-image-holder" size="3">
								<img src="assets/images/vs.png" alt="" />
							</IonCol>
							{squadBObject ? (
								<IonCol className="squad-b-image-holder" size="4">
									<IonAvatar className="squad-image">
										<img src={squadBObject.image} alt="" />
									</IonAvatar>
									<span className="competition-squad-name">{squadBObject.name}</span>
									<div className="competition-squad-slogan">"{squadBObject.slogan}"</div>
								</IonCol>
							) : (
								""
							)}
						</IonRow>
					</div>
				</div>
				<div>
					<div id="numbers-part" className="competition-goal-holder">
						<IonRow style={{ marginLeft: "20px" }}>
							{squadAGoalObject ? (
								<IonCol className="" size="4">
									<span className="competition-goals">
										Goal <br />
									</span>
									{squadAGoalObject.goalPoints}
									<br />
									<br />
									<span className="competition-goals" style={{ backgroundColor: "yellow", color: "black" }}>
										Total <br />
										{squadAGoalObject.goalProgress}
									</span>
									<br />
									<br />
									<span className="competition-goals">Handicap</span> <br />
									{squadAHandicap}
								</IonCol>
							) : (
								""
							)}
							<IonCol className="points-border" size="3"></IonCol>
							{squadBGoalObject ? (
								<IonCol size="4">
									<span className="competition-goals">
										Goal <br />
									</span>
									{squadBGoalObject.goalPoints}
									<br />
									<br />
									<span className="competition-goals" style={{ backgroundColor: "yellow", color: "black" }}>
										Total <br />
										{squadBGoalObject.goalProgress}
									</span>
									<br />
									<br />
									<span className="competition-goals">Handicap</span> <br />
									{squadBHandicap}
								</IonCol>
							) : (
								""
							)}
						</IonRow>
					</div>
					<div className="competition-squad-team-holder">
						<div className="left-column-teammates">
							{squadAMembers &&
								squadAMembers.map((member, i) => {
									let bkColor;
									if (Math.round((member.goalProgress / member.goalPoints) * 100) >= 100) {
										bkColor = "green";
									} else if (Math.round((member.goalProgress / member.goalPoints) * 100) >= 90) {
										bkColor = "yellow";
									} else if (Math.round((member.goalProgress / member.goalPoints) * 100) < 90) {
										bkColor = "red";
									}
									return (
										<IonCol className="squad-a-image-holder" size="4" style={{ backgroundColor: `${bkColor}`, color: "white" }}>
											{squadATitles.mvpID && squadATitles.mvpID === member.id ? (
												<div className="projected-mvp">
													<Emoji text=":trophy: MVP :trophy:" />
												</div>
											) : (
												<div></div>
											)}
											{squadATitles.overAchieverID && squadATitles.overAchieverID === member.id ? (
												<div className="most-on-fire">
													<Emoji text=":fire: Over Achiever :fire:" />
												</div>
											) : (
												<div></div>
											)}
											{squadATitles.mostAmbitiousID && squadATitles.mostAmbitiousID === member.id ? (
												<div className="fastest-start">
													<Emoji text=":dash: Most Ambitious :dash:" />
												</div>
											) : (
												<div></div>
											)}
											<IonAvatar className="member-image">
												<img className="user-avatar" src={member.user.avatar} alt="" />
											</IonAvatar>
											<span className="competition-squad-name">{member.user.userName}</span>
											<br />
											<span className="competition-squad-name">
												{member.goalProgress}/{member.goalPoints}
												<br /> {Math.round((member.goalProgress / member.goalPoints) * 100)}%{" "}
											</span>
											<br />
										</IonCol>
									);
								})}
						</div>
						<div className="right-column-teammates">
							{squadBMembers &&
								squadBMembers.map((member, i) => {
									let bkColor;
									if (Math.round((member.goalProgress / member.goalPoints) * 100) >= 100) {
										bkColor = "green";
									} else if (Math.round((member.goalProgress / member.goalPoints) * 100) >= 90) {
										bkColor = "yellow";
									} else if (Math.round((member.goalProgress / member.goalPoints) * 100) < 90) {
										bkColor = "red";
									}
									return (
										<IonCol className="squad-b-image-holder" size="4" style={{ backgroundColor: `${bkColor}`, color: "white" }}>
											{squadBTitles.mvpID && squadBTitles.mvpID === member.id ? (
												<div className="projected-mvp">
													<Emoji text=":trophy: MVP :trophy:" />
												</div>
											) : (
												<div></div>
											)}
											{squadBTitles.overAchieverID && squadBTitles.overAchieverID === member.id ? (
												<div className="most-on-fire">
													<Emoji text=":fire: Over Achiever :fire:" />
												</div>
											) : (
												<div></div>
											)}
											{squadBTitles.mostAmbitiousID && squadBTitles.mostAmbitiousID === member.id ? (
												<div className="fastest-start">
													<Emoji text=":dash: Most Ambitious :dash:" />
												</div>
											) : (
												<div></div>
											)}
											<IonAvatar className="member-image">
												<img className="user-avatar" src={member.user.avatar} alt="" />
											</IonAvatar>
											<span className="competition-squad-name">{member.user.userName}</span>
											<br />
											<span className="competition-squad-name">
												{member.goalProgress}/{member.goalPoints}
												<br /> {Math.round((member.goalProgress / member.goalPoints) * 100)}%{" "}
											</span>
											<br />
										</IonCol>
									);
								})}
						</div>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default CompetitionCompletedLastWeek;
