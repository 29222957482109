import { StorageService } from "../services/StorageService";
import moment from "moment";

const storageService = new StorageService();
//const localDataTTL = 60; // the TTL for local data in minutes

export const setLastRefresh = () => {
	storageService.setItem("lastRefresh", moment().format());
};

export const getLastRefresh = async () => {
	return await storageService.getItem("lastRefresh");
};

/**
 * 
 * Checks on whether profile data should be refreshed. 
 * Pulls lastRefresh key from local storage. If it doesn't exist, return true
 * If it does exist, check if it's been an hour since last refresh. If yes, then return true
 * Else false
 * 
 */

export const shouldRefresh = async (object = null, minutes = 2880, lastUpdatedDate = null) => { // 2880 minutes is 48 hours
	let lastRefreshDate
	if (object === null){
		lastRefreshDate = await getLastRefresh();
	} 
	if (lastUpdatedDate === null) {
		lastRefreshDate = object.lastUpdated
	} else {
		lastRefreshDate = lastUpdatedDate
	}
	if (lastRefreshDate == null || lastRefreshDate === "") {
		return true;
	}
	let lastRefresh = moment(lastRefreshDate);
	let targetRefresh = lastRefresh.add(minutes, "minutes");
	if (moment() > targetRefresh) {
		return true;
	} else {
		return false;
	}
};

/**
 * 
 * Checks a value to see if requires an update based on the minutes passed or the default value
 * Returns true if it should be updated, false if not
 * 
 */

 export const shouldRefreshBaseValue = async (value, minutes = 2880) => { // 2880 minutes is 48 hours
	if (value == null) {
		return true;
	}
	let lastRefresh = moment(value);
	let targetRefresh = lastRefresh.add(minutes, "minutes");
	if (moment() > targetRefresh) {
		return true;
	} else {
		return false;
	}
};

