/* eslint-disable jsx-a11y/accessible-emoji */
import {
	IonAvatar,
	IonBackButton,
	IonButton,
	IonButtons,
	IonChip,
	IonCol,
	IonContent,
	IonHeader,
	IonItem,
	IonLabel,
	IonLoading,
	IonPage,
	IonPopover,
	IonProgressBar,
	IonRow,
	IonToast,
	IonToolbar,
	useIonViewWillEnter,
} from "@ionic/react";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import JSConfetti from "js-confetti";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import AuthContext from "../../contexts/GlobalState";
import "../../css/Home.css";
import "../../css/Onboarding.css";
import { PHP_URL_NAME } from "../../models/constants";
import { sampleUpdate } from "../../models/sampleUpdate";
import { logGenericEvent, logGiveLoveEvent, logPageView } from "../../services/AnalyticsService";
import { getPHP } from "../../services/APIService";
import { getAvailableChallenges } from "../../services/ChallengeService";
import { sendSlackNotification } from "../../services/NotificationService";
import { getRandomSquadID, getSquad } from "../../services/SquadService";
import { StorageService } from "../../services/StorageService";
import { forceAddUserToSquad, getUser, getUserID, inviteUserToSquad } from "../../services/UserService";
import ChallengeHeaderComponent from "../Challenges/ChallengeHeaderComponent";

const SquadSelection = ({ progress, setProgress, squadToJoin, setSquadToJoin }) => {
	const history = useHistory();
	const storageService = new StorageService();
	const { login, setOnboardingCompleted } = useContext(AuthContext);
	const [squadJoined, setSquadJoined] = useState();
	const [challengeJoined, setChallengeJoined] = useState();
	const [availableChallenges, setAvailableChallenges] = useState();
	const [loading, setLoading] = useState(false);

	useIonViewWillEnter(async () => {
		await checkForInitialSquad();
		await checkForInitialChallenge();
		setAvailableChallenges(await getAvailableChallenges());
		logPageView("squad selection");
		setProgress(1.0);
		storageService.setItem("lastOnboardingStep", 18);
	});

	const checkForInitialSquad = async () => {
		setLoading(true);
		let initialSquad = await storageService.getObject("initialSquadToJoin");
		if (initialSquad !== null) {
			setSquadJoined(initialSquad);
			let user = JSON.parse(await getUser());
			await forceAddUserToSquad(user.cognitoId, initialSquad.id).catch((error) => {
				alert("Error: " + error);
				return;
			});
			// let response = await inviteUserToSquad(user.cognitoId, initialSquad.id);
			// let acceptURL = PHP_URL_NAME + "fitsquad/acceptUserToSquad.php?rowID=" + response.id;
			// getPHP(acceptURL);
			try {
				logGenericEvent("Finalized adding to initial squad " + initialSquad.name);
				sendSlackNotification(
					`*NEW USER CONFIMRED JOINING INITIAL SQUAD*\nUsername: ${user.userName}\nSquad: ${initialSquad.name}\nSquad ID: ${initialSquad.id}`,
					"creation"
				);
			} catch (e) {
				console.log("Error in logging to slack: ", e);
				console.log({ user });
			}
		} else {
			setSquadJoined(false);
		}
		setLoading(false);
	};
	const checkForInitialChallenge = async () => {
		setLoading(true);
		let initialChallenge = await storageService.getObject("initialChallengeToJoin");
		if (initialChallenge !== null) {
			setChallengeJoined(initialChallenge);
		} else {
			setChallengeJoined(false);
		}
		setLoading(false);
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar style={{ "--border-style": "none" }}>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
				</IonToolbar>
				<IonProgressBar value={progress} />
			</IonHeader>
			<IonContent>
				<IonLoading isOpen={loading} onDidDismiss={() => setLoading(false)} message={"Joining your Squad..."} backdropDismiss />
				<div className="onboarding-main-div" style={{ textAlign: "center" }}>
					{challengeJoined ? <ChallengeToJoin challengeJoined={challengeJoined} /> : ""}
					{squadJoined ? <SquadToJoin squadJoined={squadJoined} setOnboardingCompleted={setOnboardingCompleted} /> : ""}
				</div>
				{!challengeJoined && !squadJoined ? (
					<NoSquadNoChallenge
						availableChallenges={availableChallenges}
						setOnboardingCompleted={setOnboardingCompleted}
						squadJoined={squadJoined}
						setSquadToJoin={setSquadToJoin}
					/>
				) : (
					""
				)}
			</IonContent>
		</IonPage>
	);
};

export default SquadSelection;

const ChallengeToJoin = ({ challengeJoined }) => {
	const history = useHistory();
	const storageService = new StorageService();
	const { login, setOnboardingCompleted } = useContext(AuthContext);

	const goToChallengePage = async () => {
		await storageService.setObject("onboarding", { completed: true });
		history.replace(`/challenge/${challengeJoined.id}`);
		setOnboardingCompleted(true);
		await login();
	};
	return (
		<React.Fragment>
			<div className="onboarding-title">
				<div style={{ fontWeight: "bold" }}>
					That's it! <br />
				</div>
				<div>
					Complete the sign up for your challenge to get started
					<br />
				</div>
			</div>
			<div className="squad-data-div" style={{ width: "100%", textAlign: "center", paddingTop: "10px" }}>
				<ChallengeHeaderComponent challengeDetails={challengeJoined} />
			</div>
			<div className="onboarding-footer">
				<IonButton onClick={() => goToChallengePage()} className="onboarding-advance-button">
					Let's do it!
				</IonButton>
			</div>
		</React.Fragment>
	);
};

const SquadToJoin = ({ squadJoined, setOnboardingCompleted }) => {
	const history = useHistory();
	const storageService = new StorageService();

	const endOnboardingSayHello = async () => {
		console.log("Squad found");
		await storageService.setObject("onboarding", { completed: true });
		// note this only works with a brand new user because of global state
		console.log(`squadID: ${squadJoined.id}`);
		history.push(`/squadhome/${squadJoined.id}`);
		setOnboardingCompleted(true);
	};
	return (
		<React.Fragment>
			<div className="onboarding-title">
				<div style={{ fontWeight: "bold" }}>
					That's it! <br />
				</div>
				<div>
					Here's your squad
					<br />
				</div>
			</div>
			<div className="squad-data-div" style={{ width: "100%", textAlign: "center", paddingTop: "10px" }}>
				<div id="squad-image">
					<img src={squadJoined.image} alt="" style={{ height: "50%", width: "50%", borderRadius: "10px" }} />
				</div>
				<div style={{ textAlign: "center" }}>
					<div className="squad-slogan-div" style={{ width: "100%", fontStyle: "italic" }}>
						{squadJoined.slogan}
					</div>
					<div className="squad-name-div" style={{ width: "100%", fontSize: "1.5em", fontWeight: "bold" }}>
						{squadJoined.name}
					</div>
					<div style={{ width: "100%", fontSize: "1.5em", fontWeight: "bold" }}>
						{squadJoined.tags &&
							squadJoined.tags.map((tag, i) => {
								return (
									<IonChip key={`${i}`} outline="false" color="tertiary">
										<IonLabel>{tag.tag}</IonLabel>
									</IonChip>
								);
							})}
					</div>
				</div>
			</div>
			<div className="onboarding-text-area" style={{ textAlign: "center", paddingTop: "20%", fontSize: "20px" }}>
				Let's go to the chat room and say hello!
			</div>
			<div className="onboarding-footer">
				<IonButton onClick={() => endOnboardingSayHello()} className="onboarding-advance-button">
					Let's do it!
				</IonButton>
			</div>
		</React.Fragment>
	);
};

const NoSquadNoChallenge = ({ setOnboardingCompleted, availableChallenges, setSquadToJoin, squadJoined = null }) => {
	const history = useHistory();
	const storageService = new StorageService();
	const update = sampleUpdate;
	console.log("squadJoined: " + squadJoined);
	useEffect(() => {
		if (squadJoined === false) {
			getRandomSquadID().then((squadID) => {
				console.log({ squadID });
				// squadID = 5;
				setSquadToJoin(squadID);
				getSquad(squadID).then(async (result) => {
					setRandomSquad(result);
					let userID = await getUserID();
					sendSlackNotification("User " + userID + " forced to join random squad ID " + result.id, "creation");
					// add them to the squad
					await forceAddUserToSquad(userID, squadID).catch((error) => {
						alert("Error: " + error);
						return;
					});
					let user = await JSON.parse(await getUser(null, true));
				});
			});
		}
	}, [squadJoined]);

	const [tyMessage, setTyMessage] = useState({
		open: false,
		message: null,
		emoji: null,
	});
	const [showReactionSelector, setShowReactionSelector] = useState({
		open: false,
		event: undefined,
		activityID: undefined,
		type: undefined,
		index: undefined,
		targetUserID: undefined,
	});

	const slideOpts = {
		initialSlide: 0,
		speed: 400,
		slidesPerView: 1.2,
		autoHeight: true,
		centeredSlides: true,
	};
	const mySwiper = useRef(document.createElement("ion-slides"));
	const [randomSquad, setRandomSquad] = useState(squadJoined);
	const [loveGiven, setLoveGiven] = useState();
	const [joining, setJoining] = useState(false);
	const [showButton, setShowButton] = useState(true);
	const [showJoinSquadModal, setShowJoinSquadModal] = useState({
		visible: false,
		squad: randomSquad,
	});
	const [errorToast, setErrorToast] = useState({
		visible: false,
		message: null,
	});
	const jsConfetti = new JSConfetti();

	const showEmojiPicked = async (event, emojiObject, activityID, index, targetUserID) => {
		let userID = await getUserID();
		logGiveLoveEvent("notifications", "activity", emojiObject.colons, targetUserID);
		setShowReactionSelector({ open: false, event: undefined, activityID: undefined, type: undefined, index: undefined });
		setTyMessage({
			open: true,
			message: "Love sent!\nGreat job supporting your squad",
			emoji: emojiObject.colons,
		});
		jsConfetti.addConfetti({
			emojis: [emojiObject.native],
		});
		setLoveGiven(true);
		setShowButton(false);
	};
	return (
		<React.Fragment>
			<IonToast isOpen={errorToast.visible} onDidDismiss={() => setErrorToast({ visible: false })} message={errorToast.message} duration={2000} />
			<IonLoading isOpen={joining} onDidDismiss={() => setJoining(false)} message={"Joining your squad"} backdropDismiss />
			<IonPopover
				isOpen={tyMessage.open}
				className="love-thanks"
				onDidDismiss={(e) => {
					setTyMessage({
						open: false,
						message: null,
						imageURL: null,
					});
					history.push(`/onboarding/endonboarding`);
				}}
			>
				<div style={{ fontSize: "1.25em", paddingTop: "33px", textAlign: "center" }}>
					<br />
					<span dangerouslySetInnerHTML={{ __html: tyMessage.message }}></span>
				</div>
			</IonPopover>
			<IonPopover
				event={showReactionSelector.event}
				isOpen={showReactionSelector.open}
				cssClass="reaction-selector"
				onDidDismiss={(e) => {
					setShowReactionSelector({ open: false, event: undefined, activityID: null, type: null, index: null, targetUserID: null });
				}}
			>
				<Picker
					onSelect={(emoji) => showEmojiPicked(emoji, emoji, showReactionSelector.activityID, showReactionSelector.index, showReactionSelector.targetUserID)}
				/>
			</IonPopover>
			<div className="onboarding-title">
				<div style={{ fontWeight: "bold" }}>
					Almost there! <br />
				</div>
				<div>
					Here's your intro squad:
					<br />
				</div>
			</div>
			{randomSquad ? (
				<React.Fragment>
					<div className="squad-data-div" style={{ margin: "10%", width: "80%", textAlign: "center", paddingTop: "10px" }}>
						<div id="squad-image">
							<img src={randomSquad.image} alt="" style={{ height: "50%", width: "50%", borderRadius: "10px" }} />
						</div>
						<div style={{ textAlign: "center" }}>
							<div className="squad-slogan-div" style={{ width: "100%", fontStyle: "italic" }}>
								{randomSquad.slogan}
							</div>
							<div className="squad-name-div" style={{ width: "100%", fontSize: "1.5em", fontWeight: "bold" }}>
								{randomSquad.name}
							</div>
							<div style={{ width: "100%", fontSize: "1.5em", fontWeight: "bold" }}>
								{randomSquad.tags &&
									randomSquad.tags.map((tag, i) => {
										return (
											<IonChip key={`${i}`} outline="false" color="tertiary">
												<IonLabel>{tag.tag}</IonLabel>
											</IonChip>
										);
									})}
							</div>
						</div>
					</div>
					<div>
						<div className="onboarding-text-area" style={{ textAlign: "center", fontSize: "20px", marginBottom: "10%" }}>
							Start off showing a squad mate some love for one of their recent activities:
						</div>
						<IonItem style={{ fontSize: "0.9em", paddingBottom: "5px" }} className={"update-read"}>
							<IonRow style={{ paddingBottom: "5px" }}>
								<IonCol size="2" style={{ marginTop: "auto", marginBottom: "auto", marginRight: "4%" }}>
									<IonAvatar>
										<img style={{ objectFit: "cover" }} src={update.avatar} alt="" />
									</IonAvatar>
								</IonCol>
								<IonCol size="6">
									<div dangerouslySetInnerHTML={{ __html: update.body }}></div>
									<div style={{ fontSize: "0.7em", color: "#a4a4a4", marginTop: "2%" }}>3 minutes ago</div>
								</IonCol>
								<IonCol size="3" style={{ marginTop: "auto", marginBottom: "auto" }}>
									{showButton === true ? (
										<IonButton
											style={{ fontSize: "1em" }}
											onClick={(e) =>
												setShowReactionSelector({
													targetUserID: update.payload.userId,
													open: true,
													event: e.nativeEvent,
													activityID: update.payload.id,
													type: "activity",
												})
											}
										>
											+ Love
										</IonButton>
									) : (
										""
									)}
								</IonCol>
							</IonRow>
						</IonItem>
					</div>
				</React.Fragment>
			) : (
				""
			)}
		</React.Fragment>
	);
};
