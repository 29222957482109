import { Plugins } from "@capacitor/core";
import { getPlatforms } from "@ionic/react";
import { API_URL, challengeTesterUsernames, PHP_URL_NAME } from "../models/constants";
import { sampleNotifications } from "../models/sampleNotifications";
import { logGenericClickEvent, logGenericEvent } from "./AnalyticsService";
import { getIDToken } from "./AuthService";
import { addEmoji, getChatRooms, postChatRoomMessage } from "./ChatService";
import { getGoal } from "./PointsAndGoalsService";
import { getChatRoomIDFromSquadID, getSquad, getSquadIDFromChatRoomID } from "./SquadService";
import { StorageService } from "./StorageService";
import { getAvatarFromUserID, getUser, getUserID, getUserNameOfLoggedInUser, getUserSetting, updateUser } from "./UserService";
import moment from 'moment'
import { post, postPHPJSONResponse } from "./APIService";
import { logReactionGiven } from "./FeedService";
const storageService = new StorageService();
const { PushNotifications, LocalNotifications, Device } = Plugins;


const androidPayloadNotificationActionPerformed = {
    "actionId":"tap",
    "notification":{
        "id":"0:1610323071768781%2155dd7c2155dd7c",
        "data":{
            "google.delivered_priority":"high",
            "google.sent_time":"1610323071754",
            "google.ttl":"2419200",
            "google.original_priority":"high",
            // is escaped
            "payload":{
                "timeStamp":"2021-01-10T23:57:51.345Z",
                "createdAt":"2021-01-10T23:57:51.345Z",
                "deleted":false,
                "payload":"",
                "author":"b2b3ca3d-e22e-458d-bb2e-f99631646657",
                "reactions":[],
                "id":"827a2e74-aa18-4b3c-a371-a9d26a1b8564",
                "message":"qqqqqqq\\n",
                "type":"text",
                "reactionCount":0,
                "chatRoom":"5631b270-2d29-431e-9e9f-f77d9b73fc5b",
                "updatedAt":"2021-01-10T23:57:51.345Z"
            },
            "from":"400305806374",
            "collapse_key":"fitsquad.app"
        }
    }
}

const convertNotification = async (notificationList) => {
    let converetedNotificationList = []
    for (let notification of notificationList){
        let tempNotification = {
            "id":0,
            "title":"Riggz // Test Chat",
            "body":"rrrr\n",
            "groupSummary":false,
            "data":{
                "android.title":"Riggz // Test Chat",
                "android.reduced.images":true,
                "android.template":"android.app.Notification$BigTextStyle",
                "android.showChronometer":false,
                "android.text":"rrrr\n","android.progress":0,
                "android.progressMax":0,
                "android.appInfo":"ApplicationInfo{b45318 fitsquad.app}",
                "android.showWhen":true,
                "android.bigText":"rrrr\n",
                "android.progressIndeterminate":false
            }
        }
    }
}

export const storeDeliveredNotifications = async () => {
    console.log("Processing notifications");
    let notificationList = await PushNotifications.getDeliveredNotifications()
    //let converetedNotificationList = await convertNotification(notificationList)
    let existingNotifications = await storageService.getObject("deliveredNotifications")
    let updatedNotificationList = {}
    if (existingNotifications === null ){
        updatedNotificationList = notificationList
    } else {
        updatedNotificationList = {
            ...existingNotifications,
            ...notificationList
        }
    }
    console.log("Notification list as object: ",{updatedNotificationList});
    console.log("Notification list as stringfy: "+ JSON.stringify(updatedNotificationList));
    //console.log("Notification list as parse + stringfy: "+ JSON.stringify(JSON.parse(updatedNotificationList)));
    await storageService.setObject("deliveredNotifications", updatedNotificationList)
    let temp = await storageService.getObject("deliveredNotifications")
    console.log("temp list as object: ",{temp});
    console.log("temp list as stringfy: "+ JSON.stringify(temp));
    //console.log("temp list as parse + stringfy: "+ JSON.stringify(JSON.parse(temp)));
}

const androidNotificationReceived = {
    "id":"0:1610323731505430%2155dd7c2155dd7c",
    "data":{
        // Needs to be parsed... comes in escaped
        "payload":{
            "timeStamp":"2021-01-11T00:08:50.970Z",
            "createdAt":"2021-01-11T00:08:50.970Z",
            "deleted":false,
            "payload":"",
            "author":"b2b3ca3d-e22e-458d-bb2e-f99631646657",
            "reactions":[],
            "id":"33499f2f-8b63-4003-8cb6-830f4009ac95",
            "message":"app open\\n",
            "type":"text",
            "reactionCount":0,
            "chatRoom":"5631b270-2d29-431e-9e9f-f77d9b73fc5b",
            "updatedAt":"2021-01-11T00:08:50.970Z"
        }
    },
    "title":"Riggz // Test Chat",
    "body":"app open\n"
}

const androidNotificationStored = {
    "notifications":[{
        "id":0,
        "title":"Riggz // Test Chat",
        "body":"rrrr\n",
        "groupSummary":false,
        "data":{
            "android.title":"Riggz // Test Chat",
            "android.reduced.images":true,
            "android.template":"android.app.Notification$BigTextStyle",
            "android.showChronometer":false,
            "android.text":"rrrr\n","android.progress":0,
            "android.progressMax":0,
            "android.appInfo":"ApplicationInfo{b45318 fitsquad.app}",
            "android.showWhen":true,
            "android.bigText":"rrrr\n",
            "android.progressIndeterminate":false
        }
    }]
}



const notificationObject = {
    "notifications":[{
        "id":0,
        "title":"Riggz // Test Chat",
        "body":"rrrr\n",
        "data":{
            "timeStamp": "",
            "author": "",
            "chatRoom": "",
            "deleted": "",
            "type": "",
            "reactions": "",
            "reactionCount": "",            
        }
    }]

}


export const storeNotification = async (notificationObject) => {
    console.log("Storing notification");

    let notificationList = await PushNotifications.getDeliveredNotifications()
    let existingNotifications = await storageService.getObject("deliveredNotifications")
    let updatedNotificationList = {}
    if (existingNotifications === null ){
        updatedNotificationList = notificationList
    } else {
        updatedNotificationList = {
            ...existingNotifications,
            ...notificationList
        }
    }
    console.log("Notification list as object: ",{updatedNotificationList});
    console.log("Notification list as stringfy: "+ JSON.stringify(updatedNotificationList));
    //console.log("Notification list as parse + stringfy: "+ JSON.stringify(JSON.parse(updatedNotificationList)));
    await storageService.setObject("deliveredNotifications", updatedNotificationList)
    let temp = await storageService.getObject("deliveredNotifications")
    console.log("temp list as object: ",{temp});
    console.log("temp list as stringfy: "+ JSON.stringify(temp));
    //console.log("temp list as parse + stringfy: "+ JSON.stringify(JSON.parse(temp)));
}

const registerActionTypes = async () =>{
    LocalNotifications.registerActionTypes({
        // I think these are randomly set by the server
        types: [
            {
                id: "workout-5",
                actions: [
                    {
                        id: ":+1:",
                        title: "👍",
                        destructive: true,
                    },
                    {
                        id: ":muscle:",
                        title: "💪",
                        destructive: true,
                    },
                    {
                        id: ":poop:",
                        title: "💩",
                        destructive: true,
                    },
                ],
            },{
                id: "workout-1",
                actions: [
                    {
                        id: ":grinning:",
                        title: "😀",
                        destructive: true,
                    },
                    {
                        id: ":punch:",
                        title: "👊",
                        destructive: true,
                    },
                    {
                        id: ":eggplant:",
                        title: "🍆",
                        destructive: true,
                    },
                ],
            },
            {
                id: "workout-2",
                actions: [
                    {
                        id: ":raised_hands:",
                        title: "🙌",
                        destructive: true,
                    },
                    {
                        id: ":sign_of_the_horns:",
                        title: "🤘",
                        destructive: true,
                    },
                    {
                        id: ":thinking_face:",
                        title: "🤔",
                        destructive: true,
                    },
                ],
            },
            {
                id: "workout-3",
                actions: [
                    {
                        id: ":ok_hand:",
                        title: "👌",
                        destructive: true,
                    },
                    {
                        id: ":boom:",
                        title: "💥",
                        destructive: true,
                    },
                    {
                        id: ":beer:",
                        title: "🍺",
                        destructive: true,
                    },
                ],
            },
            {
                id: "workout-4",
                actions: [
                    {
                        id: ":clap:",
                        title: "👏",
                        destructive: true,
                    },
                    {
                        id: ":100:",
                        title: "💯",
                        destructive: true,
                    },
                    {
                        id: ":face_vomiting:",
                        title: "🤮",
                        destructive: true,
                    },
                ],
            },
        ],
    });
}

export const setupNotificationListener = async (history) => {
        LocalNotifications.removeAllListeners();
        PushNotifications.removeAllListeners();
        await registerActionTypes();
        

        /**
         * This is triggered when the app is open on Android and the user taps a notification
         */
        LocalNotifications.addListener("localNotificationActionPerformed", async (action) => {
            let deviceInfo = await Device.getInfo();
            if (deviceInfo.platform === "android") {
                // console.log("Action performed: " + JSON.stringify(action));
                if (action.actionId === "tap") {
                    if (action.notification.extra) {
                        // console.log("Data payload: " + JSON.stringify(action.notification.extra));
                        if(action.notification.extra.activityData){ // it has activityData property
                            if(action.notification.extra.activityData.payload?.activityData?.dataType === "seasonRegistration"){
                                window.open("http://fitsquadapp.com/products", "_new");
                            }
                        } else if (action.notification.extra.payload.chatRoom) {
                            // console.log("ChatRoom: " + action.notification.extra.payload.chatRoom);   
                            // console.log("History: " + JSON.stringify(history));   
                            let squadID = await getSquadIDFromChatRoomID(action.notification.extra.payload.chatRoom)
                            if (squadID !== null){
                                window.open("/squadhome/" + squadID);
                            }                            
                        }
                    }
                } else if (action.actionId === "free_text") {
                    // console.log("squadID: " + action.notification.extra.activityData.squadId);
    
                    let chatRoomID = await getChatRoomIDFromSquadID(action.notification.extra.activityData.squadId);
                    // console.log("ChatRoomID: " + chatRoomID);
                    postChatRoomMessage(chatRoomID, action.inputValue);
                } else {
                    let response = await addEmoji(action.notification.id, action.actionId, "activity", action.notification.extra.activityData.squadId);
                    // console.log("Response from addEmoji: " + response);
                }
            }
        });


        /**
         * 
         * Converts Pushes to Locals for Android
         * pushNotificationReceived doesn't fire for iOS
         * 
         */
        PushNotifications.addListener("pushNotificationReceived", async (notification) => {
            let deviceInfo = await Device.getInfo();
            console.log("DeviceInfo: ", JSON.stringify(deviceInfo));
            console.log("Notification: " + JSON.stringify(notification));
            if (deviceInfo.platform === "android") {
                let dataPayload;
                if (notification?.data?.payload) {
                    dataPayload = await JSON.parse(notification.data.payload);
                    console.log("Notification  - dataPayload: " + JSON.stringify(dataPayload));
                    if (dataPayload.payload?.activityData) { // for a workout
                        console.log("Datatype: " + dataPayload["payload"]["activityData"]["dataType"]);
                        console.log("Activity ID: " + dataPayload["payload"]["sourceActivityId"]);
                        let randResponse = Math.floor(Math.random() * Math.floor(4)) + 1;
                        let actTypeID = dataPayload["payload"]["activityData"]["dataType"] + "-" + randResponse;
                        console.log("randResponse: " + actTypeID);
                        await LocalNotifications.schedule({
                            notifications: [
                                {
                                    title: dataPayload["title"],
                                    body: dataPayload["body"],
                                    id: dataPayload["payload"]["id"],
                                    schedule: {
                                        on: new Date(Date.now() + 5000),
                                    },
                                    channelId: "primary",
                                    extra: {
                                        activityData: dataPayload,
                                    },
                                    actionTypeId: actTypeID,
                                },
                            ],
                        });
                    } else {// it's a chat message
                        console.log("ID: " + dataPayload["payload"]["id"]);                        
                        await LocalNotifications.schedule({
                            notifications: [
                                {
                                    title: dataPayload["title"],
                                    body: dataPayload["body"],
                                    id: Date.now(),
                                    schedule: {
                                        on: new Date(Date.now() + 5000),
                                    },
                                    channelId: "primary",
                                    extra: {
                                        payload: dataPayload["payload"],
                                    },
                                    actionTypeId: null,
                                },
                            ],
                        });
                    }
                } else {
                    // this shouldn't happen
                    console.log("No data payload");
                }
            }
            console.log("\n\nPUSH - pushNotificationReceived *NEW!*: " + JSON.stringify(notification));
        });

        /**
         * 
         * Android is converted to LocalNotifications when the app is open, but this works when it's closed
         * 
         */
         PushNotifications.addListener("pushNotificationActionPerformed", async (action) => {
            let deviceInfo = await Device.getInfo();
            console.log("DeviceInfo: "+ JSON.stringify(deviceInfo));
            if (deviceInfo.platform === "ios") {
                console.log("\n\nPUSH - pushNotificationActionPerformed iOS: " + JSON.stringify(action));

                let destination
                if (action.notification.data.aps.alert.payload.chatRoom) { // if it has the chatRoom property, push to there                    
                    let squadID = await getSquadIDFromChatRoomID(action.notification.data.aps.alert.payload.chatRoom)
                    if (squadID !== null){
                        window.open("/squadhome/" + squadID);
                    }            
                } else if (action.notification.data.aps.alert.payload.activityData?.dataType === "workout") {
                    if (action.actionId === "free_text") {
                        console.log("squadID: " + action.notification.data.aps.alert.payload.squadId);
                        let chatRoomID = await getChatRoomIDFromSquadID(action.notification.data.aps.alert.payload.squadId);                
                        postChatRoomMessage(chatRoomID, action.inputValue);
                    } else if (action.actionId === "tap") {
                        let squadID = action.notification.data.aps.alert.payload.squadId  
                        if (squadID !== null){
                            window.open("/squadhome/" + squadID);
                        }
                    } else {
                        await addEmoji(action.notification.data.aps.alert.payload.id, action.actionId, "activity", action.notification.data.aps.alert.payload.squadId);
                    }
                } else if (action.notification.data.aps.alert.payload.activityData?.dataType === "seasonRegistration") {
                    //destination = "/season-sales-page"
                    window.open("http://fitsquadapp.com/products", "_new");
                } else {                
                    if (action.notification.data.aps.alert.payload.squadId) { // this is for parsing whether it's a chatRoom or competitionChat
                        let squadID = action.notification.data.aps.alert.payload.squadId;
                        window.open("/squadhome/" + squadID);
                    }
                    /*
                    if (action.data.aps.alert.payload.squadId) {
                        let squadID = action.data.aps.alert.payload.squadId;
                        console.log("\nLooking for chat room for squadID " + squadID);
                        console.log("\nGetting chat room");
                        let chatRooms = await getChatRooms(false);
                        let chatRoomID = null;
                        for (let roomData of chatRooms) {
                            console.log("\n\nchecking roomData: " + JSON.stringify(roomData));
                            if (roomData.squadIdA === squadID && roomData.squadIdB !== null && chatRoomID === null) {
                                console.log("\nFound the competition one in A: " + roomData.id);
                                chatRoomID = roomData.id;
                            } else if (roomData.squadIdA === squadID && chatRoomID === null) {
                                console.log("\nFound the solo one in A: " + roomData.id);
                                chatRoomID = roomData.id;
                            } else if (roomData.squadIdB === squadID && roomData.squadIdA !== null && chatRoomID === null) {
                                console.log("\nFound the competition one in B: " + roomData.id);
                                chatRoomID = roomData.id;
                            } else if (roomData.squadIdB === squadID && chatRoomID === null) {
                                console.log("\nFound the solo one in B: " + roomData.id);
                                chatRoomID = roomData.id;
                            }
                        }
                    } else {
                        console.log("\n\n NO SQUAD ID");
                    }
                    */
                    console.log("User is on iOS. Using that payload");
                }
            }
            if (deviceInfo.platform === "android") {
                console.log("\n\nPUSH - pushNotificationActionPerformed Android: " + JSON.stringify(action));
                if (action.notification.data.payload) { // if it has the payload property
                    let payload = JSON.parse(action.notification.data.payload); // parse it since
                    console.log("Data payload: " + JSON.stringify(payload.payload));
                    console.log("ChatRoom: " + payload.payload.chatRoom);
                    if (payload.payload.chatRoom) {
                        let squadID = await getSquadIDFromChatRoomID(payload.payload.chatRoom)
                        if (squadID !== null){
                            window.open("/squadhome/" + squadID);
                        }
                    }
                }
            }
        });
}

/**
 * 
 * Sends a notification to Slack. Possible channels:
 * default, creation, keyEvent, activity, love, messaging, error, feedback, purchase
 * 
 * @param {*} text 
 * @param {*} channel 
 */

export const sendSlackNotification = async (text, channel="default", includeTesters = true) => {
    if (includeTesters === false){
        let userName = await getUserNameOfLoggedInUser()
        if (challengeTesterUsernames.includes(userName) === true){
            console.log("No notification sent because user is in testers group");
            return
        }
    }
    let url;
    switch (channel) {
        case "default":
            url = "https://hooks.slack.com/services/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"
            break;
        case "creation":
            url = "https://hooks.slack.com/services/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"
            break;
        case "keyEvent":
            url = "https://hooks.slack.com/services/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"
            break;
        case "activity":
            url = "https://hooks.slack.com/services/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"
            break;
        case "love":
            url = "https://hooks.slack.com/services/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"
            break;
        case "messaging":
            url = "https://hooks.slack.com/services/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"
            break;
        case "error":
            url = "https://hooks.slack.com/services/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"
            break;
        case "feedback":
            url = "https://hooks.slack.com/services/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"
            break;
        case "purchase":
            url = "https://hooks.slack.com/services/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"
            break;
        case "challenges":
            url = "https://hooks.slack.com/services/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"
            break;
            
        default:
            url = "https://hooks.slack.com/services/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"
            break;
    }
    let data = {
        "text": text
    }
    //console.log("Sending Slack Notification with text: "+text);
    Promise.resolve(
		fetch(url, {
			method: "POST",
			body: JSON.stringify(data),
		})
	).catch((error) => {
		console.log("[sendSlackNotification] Error in API call: " + error);
		throw Error(error);
	});
}

/**
 * 
 * @param {string} error - text of error to display under "NEW ERROR" title
 * @param {*} source - text of the source of the error
 * @param {*} additionalData - any additional data to display. If it's to be printed in code use ```
 */

export const sendSlackErrorNotification = async (error,source, additionalData = "") => {
    let url;
    url = "https://hooks.slack.com/services/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"
    let data = {
        "text": `*NEW ERROR*\n*Error:* ${error}\n*Source:* ${source}\n*Additional Data:* ${additionalData}`
    }
    //console.log("Sending Slack Notification with text: "+text);
    Promise.resolve(
		fetch(url, {
			method: "POST",
			body: JSON.stringify(data),
		})
	).catch((error) => {
		console.log("[sendSlackNotification] Error in API call: " + error);
		throw Error(error);
	});
}

/**
 * 
 * @param {*} error 
 * @param {*} source 
 * @param {*} additionalData 
 */

 export const sendSlackBotNotification = async (sender, message,response,df_object = null) => {
    let url;
    url = "https://hooks.slack.com/services/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"
    let text = "*NEW BOT MESSAGE*\n*From:* "+sender+"\n*Message:* "+message
    if (response) {text = text +"\n*Bot Response:* "+response}
    if(df_object){text=text+"\n*DF Object:* \n```"+JSON.stringify(df_object)+"```"}
    let data = {
        "text": text
    }
    //console.log("Sending Slack Notification with text: "+text);
    Promise.resolve(
		fetch(url, {
			method: "POST",
			body: JSON.stringify(data),
		})
	).catch((error) => {
		console.log("[sendSlackNotification] Error in API call: " + error);
		throw Error(error);
	});
}

/**
 * 
 * @param {*} error 
 * @param {*} source 
 * @param {*} additionalData 
 */

 export const sendSlackBotNotificationFromBot = async (receiver, messageObject) => {
    let url;
    url = "https://hooks.slack.com/services/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"
    let text = "*NEW BOT MESSAGE*\n*From*: Cheerleadr\n*To:* "+receiver+"\n*Message:* "+messageObject.messageText
    if (messageObject.imageUrl){
        text = text+"\nImage: "+messageObject.imageUrl
    }
    let data = {
        "text": text
    }
    //console.log("Sending Slack Notification with text: "+text);
    Promise.resolve(
		fetch(url, {
			method: "POST",
			body: JSON.stringify(data),
		})
	).catch((error) => {
		console.log("[sendSlackNotification] Error in API call: " + error);
		throw Error(error);
	});
}


export const formatUpdate = async (update) => {
    let chatRoom
    try {
        if (update.payload.deleted !== true){
            switch(update.payload.type) {
                case "reactionReceived":
                    update.payload.userId = update.payload.from.cognitoId
                    update.avatar = update.payload.from.avatar
                    update.body = update.title+"<br/><b>"+update.payload.from.userName+ "</b> gave you some love for your <b>"+update.payload.activity.activityType+"</b>"
                    update.button = true
                    chatRoom = await getChatRoomIDFromSquadID(update.payload.activity.squadId)
                    update.link = "/chatroom/"+chatRoom                    
                    break;
                case "newSquadActivity":
                    let timeCase = "minutes"
                    let pointCase = "points"
                    if (update.payload.activityData.valueUnit === "sec") {
                        update.payload.activityData.value = Math.round(update.payload.activityData.value/60)
                    }
                    if(update.payload.activityData.value === 1){
                        timeCase = "minute"
                    }
                    if (update.payload.points === 1){
                        pointCase = "point"
                    }
                    update.body = "<b>"+update.payload.userName+ "</b> did <b>"+update.payload.activityType+" for " + update.payload.activityData.value + " "+timeCase+"</b> earning "+update.payload.squadName+" "+update.payload.points+" "+pointCase
                    update.avatar = await getAvatarFromUserID(update.payload.userId)
                    update.button = true
                    chatRoom = await getChatRoomIDFromSquadID(update.payload.squadId)
                    update.link = "/chatroom/"+chatRoom
                    break;
                case "newChatMessage":
                    update.body = update.payload.authorName+ " sent your squad <b>this message:</b><br/> <i>"+update.body+"</i>"
                    update.payload.userId = update.payload.author
                    update.avatar = await getAvatarFromUserID(update.payload.userId)
                    update.link = "/chatroom/"+update.payload.chatRoom
                    update.button = false
                    break;
                default:
                    update.button = false
                    break;
            }
        } else {            
            return null
        }
        if (update.responseSent === true) { update.button = false}
        update.read = false
        return update
    } catch (error) {
        console.log({error});
        return null
    }

}

export const filterUpdate = async (userId, update, recentUpdates) => {
    let exclude = false
    let formattedUpdate = null
    let percentThreshold = 0.05
    // Exclusions

    if (update.payload === null){
        return null
    }
    let filterResult = recentUpdates.filter((x) => x.id === update.id); // look for duplicate messages in storage
    if (Object.keys(filterResult).length !== 0){        
        console.log("Found duplicate update");
        return null
    }
    if (update.payload !== null && (update.payload.userId === userId || update.payload.type === "dailyMessage")){
        console.log("Paylod is either dailymessage or update for logged in user");
        return null
    }
    if (update.payload.type === 'newSquadActivity'){
        // If they created it today, but it was for another day, exclude
        // Prevents when people log a bunch before EOW
        let dateOfActivity = moment(update.payload.endDate).date()
        let dateOfLog = moment(update.payload.createdAt).date()
        let todaysDate = moment().date()
        console.log(`Date of activity ${dateOfActivity} - Todays Date ${todaysDate}`);
        if (dateOfLog === todaysDate && dateOfActivity !== todaysDate) {
            console.log("Date of log is today but date of activity is not today");
            return null
        }
        // Exclude "small" workouts
        let userGoal = await getGoal(update.payload.userId,false,null,null,false)
        console.log("Getting goal for "+update.payload.userName+": "+userGoal.goalPoints);
        console.log(`Points: ${update.payload.points} Threshold: ${Math.round(userGoal.goalPoints*percentThreshold)}`);
        if (update.payload.points < (userGoal.goalPoints * percentThreshold)){ // If the activity is not more than 5% of their goal, don't show it
            console.log("Update doesnt cross threshold");
            return null
        }
        
    }
    console.log("Adding update ID "+update.id+" to recent updates");
    formattedUpdate = await formatUpdate(update)             
    return formattedUpdate
}

export const getRecentUpdatesFromAPI = async () => {
    console.log("Pulling recent updates");
	const url = API_URL;
	var idToken;
	idToken = await getIDToken().catch((error) => {
		throw Error("[getLatestUpdates] Error in getIDtoken: " + error);
    });

    const response = await Promise.resolve(
		fetch(url + "updates", {
			method: "get",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[getLatestUpdates] Error in API call: " + error);
		throw Error(error);
	});

    return response
        .json()
        .then(async (data) => {
            console.log(data.length+" updates found");            
            return data
        })
        .catch((error) => {
            console.log("[getLatestUpdates] Error in JSON conversion: " + error);
            throw Error(error);
        });
}

export const processUpdates = async (recentUpdatesFromAPI, userObject) =>{
    if (userObject === null || userObject === undefined) {
        userObject = JSON.parse(await getUser(null));
    }
    let recentUpdates = await storageService.getObject("recentUpdates");
    let recentUpdateCount = parseInt(await storageService.getItem("recentUpdateCount"));
    let resetLocalStorage = await storageService.getItem("resetRecentLocalStorage"); // used to forced a cleaning of the localstorage
    let count = 0;
    if (recentUpdates === null || resetLocalStorage === null) {
        recentUpdates = [];
    } // If there's nothing locally, or we want to reset, do that here
    //console.log("recentUpdates: "+recentUpdates);

    if (isNaN(recentUpdateCount) === true) {
        console.log("recentUpdates count is NaN");
        recentUpdateCount = 0;
    }
    let userId = userObject.cognitoId;
    for (let update of recentUpdatesFromAPI) {
        // console.log({ update });
        let filteredUpdate = await filterUpdate(userId, update, recentUpdates);
        if (filteredUpdate !== null) {
            recentUpdates.push(filteredUpdate);
            count++;
            console.log("adding to updates, count = "+count);
        }
    }
    recentUpdateCount = recentUpdateCount + count;
    recentUpdates.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
    await storageService.setObject("recentUpdates", recentUpdates);
    await storageService.setItem("recentUpdateCount", recentUpdateCount.toString());
    console.log("recentUpdateCount set to "+recentUpdateCount);
    await storageService.setItem("resetRecentLocalStorage", "complete");
    return recentUpdates
}

export const enableNotifications = async () => {
    logGenericEvent("attempting to enable notifications");
    let body;
    let pushToken = await storageService.getItem("pushToken");
    if (pushToken !== "") {
        console.log("Your pushToken: " + pushToken);
    } else {
        console.log("No push token stored");
    }
    let deviceInfo = await Device.getInfo();
    if (deviceInfo.platform === "web") {
        return
    }
    //LocalNotifications.requestPermission();
    PushNotifications.addListener("registration", async (token) => {
        storageService.setItem("pushToken", token.value);
        console.log("Push Notification set to: "+token.value);
        const platformsArray = getPlatforms();
        console.log("Platforms: ", { platformsArray });
        platformsArray.forEach((platform) => {
            if (platform === "ios") {
                body = {
                    deviceToken: token.value.toLowerCase(),
                };
                console.log("User is on iOS. Using that payload");
            }
            if (platform === "android") {
                body = {
                    registrationId: token.value,
                };
                console.log("User is on Android. Using that payload");
            }
        });
        await updateUser(body);
        await setupNotificationListener()
        return true
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener("registrationError", (error) => {
        console.log("PUSH - Error on registration: " + JSON.stringify(error));
        return false
    });

    let permResult = await PushNotifications.requestPermission();
    if (permResult.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        const result = await PushNotifications.register();
        console.log("register result: " + JSON.stringify(result));
        logGenericEvent("successfully to enabled notifications");
    } else {
        console.log("push notifications wont work");
        console.log("permResult: " + JSON.stringify(permResult));
        //alert("Oops something happened. Try tapping the button again");
        return false
    }
};

export const updateReply = async (updateID, bodyObject) =>{
    logReactionGiven(":blue_heart:")
    let response = await post("updates/"+updateID,bodyObject)
    return response
}

export const areNotificationsEnabled = async () => {
    let user = JSON.parse(await getUser(null,false))
    let pushToken = await storageService.getItem("pushToken");
    if (user.pushIdentifiers.length > 0 && pushToken !== ""){
        return true
    } else {
        return false
    }
}

export const areNotificationsAuthorized = async () => {
    let user = JSON.parse(await getUser(null,false))
    let pushToken = await storageService.getItem("pushToken");
    if (user.pushIdentifiers.length > 0 || pushToken !== ""){ //if it's stored server side OR client-side then yes, authorized
        return true
    } else {
        return false
    }
}

export const scheduleGroupNotification = async (body, title) => {
    let notificationAPI = PHP_URL_NAME + "fitsquad/sendPushNotifications.php";
    let messageBody = {
        title: title,
        body: body
    }
    //console.log({body});

    let response = await postPHPJSONResponse(notificationAPI, messageBody)
    console.log("PHP Response: "+response);
}

export const scheduleSingleNotification = async (body, title, recipientID) => {
    let notificationAPI = PHP_URL_NAME + "fitsquad/sendSinglePushNotification.php";
    let messageBody = {
        title: title,
        body: body,
        recipientID: recipientID
    }
    //console.log({body});

    let response = await postPHPJSONResponse(notificationAPI, messageBody)
    console.log("PHP Response: "+response);
}