import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import { sendSlackNotification } from "./NotificationService";
import { getUserID, updateUser } from "./UserService";

export const initAnalytics = async (userID = null) => {
    console.log("INIT MIXPANEL");
    try {
        mixpanel.init("7cb64637457b8be24547a401e601b13e", {debug: true});
        if (userID === null){
            userID = await getUserID()
        }
        try {
            await mixpanel.identify(userID)  
        } catch(error) {
            console.log("Error - mixpanel init/identifty: "+error);
            sendSlackNotification(`Error - mixpanel init/identify: ${error}`,error)
        }          
    } catch(error) {
        console.log("Error - mixpanel init/identifty: "+error);
        sendSlackNotification(`Error - mixpanel init/identify: ${error}`,error)
    }
  
};

export const mixpanelIdentifyUser = async (userID = null) => {
    console.log("MIXPANEL ID'ING USER");
    if (userID === null){
        userID = await getUserID()
    }
    try {
        await mixpanel.identify(userID)  
    } catch(error) {
        console.log("Error - mixpanel init/identifty: "+error);
        sendSlackNotification(`Error - mixpanel init/identify: ${error}`,error)
    }          
};


export const initAnalyticsFreshInstall = async () => {
    console.log("INIT MIXPANEL - FRESH INSTALL");
    try {
        mixpanel.init("7cb64637457b8be24547a401e601b13e", {debug: true});   
        mixpanel.track("Fresh install"); 
   } catch(error) {
        console.log("Error - mixpanel init/identifty: "+error);
        sendSlackNotification(`Error - mixpanel init/identify: ${error}`,error)
   }
  
};


export const logNavigationEvent = (source, destination) => {
   mixpanel.track("Clicked to "+destination, {
       "source": source, 
       "destination": destination 
    });
};

export const logGenericEvent = (eventName) => {
    try{
        mixpanel.track(eventName);
    } catch(error) {
        console.log("Error - mixpanel logGenericEvent: "+error);
        sendSlackNotification(`Error - mixpanel logGenericEvent: ${error}`,error)
    }
 };

export const logGenericClickEvent = (source, value) => {
   mixpanel.track("Clicked - "+value, {
       "source": source, 
       "value": value
    });
};

export const logGiveLoveEvent = async (sourcePage, sourceActivityName, emoji, targetUserID) => {
   mixpanel.track("Gave love", {
       "sourcePage": sourcePage,
       "sourceActivityName": sourceActivityName,
       "emoji": emoji,
       "targetUserID": targetUserID
    });
    //let userName = await getUserNameOfLoggedInUser()
    //let targetUser = await getUserNameFromUserID(targetUserID)
    //let notificationText = userName +" on added emoji "+emoji+" to "+sourceActivityName+" by "+targetUser
    //sendSlackNotification(notificationText)
};

export const logPageView = (pageName) => {
    try{
        mixpanel.track("PageView - "+pageName)
    } catch (error) {
        console.log("Error in logpageView: "+error);
    }
};

export const setProperty = (propertyName, propertyValue) => {
    try{
        mixpanel.people.set(propertyName,propertyValue)
    } catch (error) {
        console.log("Error in setProperty: "+error);
    }
};

export const logAddActivityEvent = (activityObject) => {
    try{
        mixpanel.track("Logged Activity", activityObject);
    } catch (error) {
        console.log("Error in logAddActivityEvent: "+error);
    }
}

export const setUserProfile = async (userObj) => {          
    let mpObj = {}
    let mpKey
	for (const key in userObj) {	
        switch (key) { // for handling Mixpanel specific keys
            case "cognitoId":
                mpKey = "$distinct_id"
                break;
            case "avatar":
                mpKey = "$avatar"
                break;
            case "email":
                mpKey = "$email"
                break;
            case "pushIdentifiers":
            case "fitbitAccessToken":
            case "fitbitRefreshToken":
                mpKey = null
                break;
            default:
                mpKey = key
                break;
        }
        if (mpKey !== null){
            mpObj[mpKey] = userObj[key]
        }
      }
    if (userObj.cognitoId){ // help ensure the incoming object is properly formatted
        if(!mixpanel.people){
            await initAnalytics()
        }
        try {
            mixpanel.people.set(mpObj); 
       } catch(error) {
            console.log("Error - mixpanel.people.set: "+error);
            sendSlackNotification(`Error - mixpanel: ${error}`,error)
       }
        
    }  
    
}

export const logLogin = () => {
    /*
    const body = {
        "logLevel": "info",
        "message" :"logged in"
    }
    post("log",body)
    */
    updateUser({
        "titlePrefix": moment().format()
    })
}