import { PHP_URL_NAME } from "../models/constants";
import { postPHP } from "./APIService";
import { getUserID, getUserNameFromUserID } from "./UserService";

export const sendGenericFeedback = async (emailAddress : string, feedbackText: string) => {
    let userID = await getUserID()
    let userName = await getUserNameFromUserID()
    let genericFeedbackAPI = PHP_URL_NAME + "fitsquad/feedback/genericFeedback.php";
    let body = {
        userName:userName,
        userID: userID,
        emailAddress: emailAddress,
        feedbackText: feedbackText
    }
    var response : any = await postPHP(genericFeedbackAPI,body)
    console.log({response});
}