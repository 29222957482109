import { IonButton, IonHeader, IonIcon, IonInput, IonLoading, IonTitle, IonToolbar } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React, { useRef, useState } from "react";
import "../css/ActivityDetails.css";
import "../css/Home.css";
import { logGenericEvent } from "../services/AnalyticsService";
import { sendSlackNotification } from "../services/NotificationService";
import { getSquad, updateSquad } from "../services/SquadService";

const ChangeName = ({ setChangeName, squadData, setSquadData }) => {
	const [changeError, setChangeError] = useState({ visible: false, message: null });
	const [loading, setLoading] = useState(false);
	const nameRef = useRef(null);

	const changeSquadName = async () => {
		setLoading(true);
		let name = nameRef.current.value;
		let body = {
			name: name,
		};
		await updateSquad(squadData.id, body)
			.catch((error) => {
				console.log("Error updating name toggle: " + { error });
				setChangeError({ visible: false, message: error });
			})
			.then(async () => {
				let squad = await getSquad(squadData.id, null, null, true);
				sendSlackNotification(`*SQUAD NAME CHANGE*\nSquad ID: ${squadData.id}\nPrevious Name: ${squadData.name}\nNew Name: ${squad.name}`, "creation");
				logGenericEvent("changed squad name");
				setSquadData(squad);
				setLoading(false);
				setChangeName(false);
			});
	};

	return (
		<React.Fragment>
			<IonHeader>
				<IonToolbar className="activity-details-toolbar">
					<IonTitle>Change Squad Name</IonTitle>
					<IonButton color="tertiary" slot="end" onClick={() => setChangeName(false)}>
						<IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
					</IonButton>
				</IonToolbar>
			</IonHeader>
			<IonLoading isOpen={loading} onDidDismiss={() => setLoading(false)} message={"Loading"} />
			<div style={{ margin: "16px", textAlign: "center" }}>
				<IonInput
					ref={nameRef}
					name="code"
					type={"text"}
					placeholder="e.g. Team Amazing"
					enterkeyhint="done"
					onFocus={() => setChangeError({ visible: false, message: null })}
					required
				></IonInput>
				<IonButton expand="block" onClick={() => changeSquadName()}>
					Change Name
				</IonButton>
				{changeError.visible === true ? <p className="onboarding-error-message">{changeError.message}</p> : ""}
			</div>
		</React.Fragment>
	);
};

export default ChangeName;
