import { IonHeader, IonTitle, IonToolbar, IonButton, IonGrid, IonCol, IonRow, IonAlert, IonItem, IonList, IonLabel, IonLoading, IonIcon } from "@ionic/react";
import React, { useEffect, useState } from "react";
import "../../css/Home.css";
import "../../css/ActivityDetails.css";
import { getActivityDetails, convertDurationToMinutes } from "../../services/ActivitiesService";
import moment from "moment";
import { getUserID, getUserNameFromUserID } from "../../services/UserService";
import { deleteActivity } from "../../services/FeedService";
import { getMessagesAndActivitiesAllChatRooms } from "../../services/ChatService";
import { logPageView } from "../../services/AnalyticsService";
import { closeOutline } from "ionicons/icons";

const ExerciseDetailsComponent = ({ activityObject, showExerciseDetails }) => {
	const [activityDetails, setActivityDetails] = useState([]);
	const [confirmDeleteActivity, setConfirmDeleteActivity] = useState({
		visible: false,
		message: null,
		id: null,
	});
	const [deleting, setDeleting] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		logPageView("exercise-details");
		fetchActivityDetails(activityObject.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function fetchActivityDetails(activityID) {
		console.log("Fetching activity details");
		let activity = JSON.parse(await getActivityDetails(activityID));
		activity.userName = await getUserNameFromUserID(activity.userId);
		let currentUserID = await getUserID();
		//let images = await gf.search(activity["activityType"], { limit: 1, sort: "relevant" });
		//console.log("Images " + JSON.stringify(images));
		activity.unit = activity.activityData.valueUnit;
		activity.duration = activity.activityData.value;
		console.log("Activity: " + JSON.stringify(activity));
		activity.minutes = Math.round(await convertDurationToMinutes(activity));
		if (activity.userId === currentUserID) {
			console.log("User can delete");
			activity.canDelete = true;
		} else {
			console.log("User cannot delete");
			activity.canDelete = false;
		}
		setActivityDetails([activity]);
		setLoading(false);
	}

	const deleteActivityTrigger = async (event, activityID, confirmation = false) => {
		if (confirmation === false) {
			setConfirmDeleteActivity({
				visible: true,
				message: "Are you sure you want to delete this activity?",
				id: activityID,
			});
		} else {
			setDeleting(true);
			console.log("Deleting activity w/ID = " + activityID);
			let deleteResult = await deleteActivity(activityID);
			console.log("Delete Result: " + deleteResult);
			await getMessagesAndActivitiesAllChatRooms();
			setDeleting(false);
			showExerciseDetails({ visible: false, details: null });
		}
	};

	return (
		<React.Fragment>
			<IonHeader>
				<IonToolbar className="activity-details-toolbar">
					<IonTitle>Activity Details</IonTitle>
					<IonButton color="tertiary" slot="end" onClick={() => showExerciseDetails({ visible: false, details: null })}>
						<IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
					</IonButton>
				</IonToolbar>
			</IonHeader>
			<IonAlert
				isOpen={confirmDeleteActivity.visible === true}
				header="You sure?"
				message={confirmDeleteActivity.message}
				buttons={[
					{
						text: "Yes",
						role: "ok",
						handler: () => {
							deleteActivityTrigger(null, confirmDeleteActivity.id, true);
						},
					},
					{
						text: "Cancel",
						role: "cancel",
						handler: () => {
							setConfirmDeleteActivity({
								visible: false,
								message: null,
								id: null,
							});
						},
					},
				]}
				onDidDismiss={() =>
					setConfirmDeleteActivity({
						visible: false,
						message: null,
						id: null,
					})
				}
			/>
			<IonLoading isOpen={deleting} onDidDismiss={() => setDeleting(false)} message={"Deleting Activity..."} />
			<IonLoading isOpen={loading} onDidDismiss={() => setLoading(false)} message={"Loading details"} />
			{activityDetails.map((activity, i) => {
				return (
					<IonList key={`${i}`}>
						<IonItem className="activity-details-item">
							<IonLabel>
								<h3 className="activity-title">Username</h3>
								<h2 className="activity-value">{activity.userName}</h2>
							</IonLabel>
						</IonItem>
						<IonItem className="activity-details-item">
							<IonLabel>
								<h3 className="activity-title">Activity Name</h3>
								<h2 className="activity-value">{activity.activityType}</h2>
							</IonLabel>
						</IonItem>
						<IonItem className="activity-details-item">
							<IonLabel>
								<h3 className="activity-title">Date</h3>
								<h2 className="activity-value">{moment(activity.startDate).format("ddd MM/DD/YY")}</h2>
							</IonLabel>
						</IonItem>
						<IonItem className="activity-details-item">
							<IonLabel>
								<h3 className="activity-title">Time</h3>
								<h2 className="activity-value">
									{moment(activity.activityData.startDate).format("h:mm a")} - {moment(activity.activityData.endDate).format("h:mm a")}
								</h2>
							</IonLabel>
						</IonItem>
						<IonItem className="activity-details-item">
							<IonLabel>
								<h3 className="activity-title">Total mins</h3>
								<h2 className="activity-value">{activity.minutes}</h2>
							</IonLabel>
						</IonItem>
						<IonItem className="activity-details-item">
							<IonLabel>
								<h3 className="activity-title">Points</h3>
								<h2 className="activity-value">{Math.round(activity.points).toLocaleString()}</h2>
							</IonLabel>
						</IonItem>
					</IonList>
				);
			})}
			{activityDetails.map((activity, i) => {
				if (activity.canDelete === true) {
					return (
						<IonGrid key={`${i}`}>
							<IonRow class="ion-text-center">
								<IonCol>
									<IonButton color="danger" fill="solid" onClick={() => deleteActivityTrigger(React.MouseEvent, activity.id)}>
										Delete Activity
									</IonButton>
								</IonCol>
							</IonRow>
						</IonGrid>
					);
				} else {
					return <div key={`${i}`}></div>;
				}
			})}
		</React.Fragment>
	);
};

export default ExerciseDetailsComponent;
