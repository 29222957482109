import React, { useState } from "react";
import { StorageService } from "../services/StorageService";
import { Plugins } from "@capacitor/core";
import { getUser } from "../services/UserService";
import { getGoal, getPrediction, getSquadPrediction, isGoalCompleted } from "../services/PointsAndGoalsService";
import { hasSeasonStarted, isUserinSeason } from "../services/SeasonsService";
import { getAllChatRooms, getChatRooms, getMessagesAndActivities } from "../services/ChatService";
import moment from "moment-timezone";
import { shouldRefresh } from "../services/RefreshService";
import { getSumPointsFromActivities } from "../services/ActivitiesService";
import { getAllUserSquadIDs, getSquad, getSquadGoal, getSquadMembers } from "../services/SquadService";
import { getGoalStatusSystemMessage } from "../services/MessageServices";
import { titleCase } from "../services/HelperServices";
import { getAllUserChallenges, getAvailableChallenges, getChallengeDetailsByChallengeID } from "../services/ChallengeService";
import { challengeTesterUsernames, excludedNotifications } from "../models/constants";
import { getRecentUpdatesFromAPI, processUpdates } from "../services/NotificationService";
import { Purchases } from "@awesome-cordova-plugins/purchases";
export const Context = React.createContext(undefined);

export const AuthProvider = ({ children }) => {
	moment.tz.setDefault("America/Los_Angeles");

	const [challengesEnabled, setChallengesEnabled] = useState(true);

	const storageService = new StorageService();
	const { LocalNotifications, Device } = Plugins;
	// States in memory
	const [userState, setUserstate] = useState({
		authenticated: null,
		user: null,
		deviceInfo: null,
		seasonStarted: null,
		userInSeason: null,
	});
	const [globalGoal, setUserGoal] = useState();
	const [numberOfUpdates, setNumberOfUpdates] = useState(0);
	const [chatRoomsGlobal, setChatRoomsGlobal] = useState();
	const [weeklyProgress, setWeeklyProgress] = useState(null);
	const [squadMemberProgress, setSquadMemberProgress] = useState([]);
	const [userSquads, setUserSquads] = useState([]);
	const [squadInfo, setSquadInfo] = useState([]);
	const [squadGoals, setSquadGoals] = useState([]);
	const [availableChallenges, setAvailableChallenges] = useState([]);
	const [squadMatesLastUpdated, setSquadMatesLastUpdates] = useState(null);
	const [notificationsList, setNotificationsList] = useState([]);

	// Progress indicators
	const [pullingUpdates, setPullingUpdates] = useState(false);
	const [pullingNotifications, setPullingNotifications] = useState(false);
	const [refreshInProgress, setRefreshInProgress] = useState(false);
	const [refreshingChat, setRefreshingChat] = useState(false);
	const [onboardingCompleted, setOnboardingCompleted] = useState();
	const [calculatingSquadMemberProgress, setCalculatingSquadMemberProgress] = useState(false);
	const [gettingSquadInfo, setGettingSquadInfo] = useState(false);
	const [challenges, setChallenges] = useState([]);
	const [subscriptionOfferings, setSubscriptionOfferings] = useState ();
	const [subscriptionInfo, setSubscriptionInfo] = useState ();

	const login = async (userObject) => {
		console.log("User State Established");
		let device = await Device.getInfo();
		if (userObject === null || userObject === undefined) {
			userObject = JSON.parse(await getUser(null));
		}
		moment.tz.setDefault(userObject.timezone);
		if (challengeTesterUsernames.includes(userObject.userName)) {
			setChallengesEnabled(true);
		}
		updateGoalState();
		updateChatRooms();
		updateWeeklyProgress(userObject);
		console.log("Calling updateSquadMates");
		updateSquadMates(userObject);
		setGettingSquadInfo(true);
		updateSquadData(userObject).then(() => {
			setGettingSquadInfo(false);
		});
		updateAvailableChallenges();
		updateSquadGoalData(userObject);
		updateChallenges(userObject);
		await updateNotifications(userObject);
		setupSubscriptions(userObject)
		getSubscriptionStatus(userObject)
		userObject.deviceInfo = device;
		return new Promise((resolve) => {
			setUserstate({
				authenticated: true,
				user: userObject,
				deviceInfo: device,
				seasonStarted: false,
				userInSeason: false,
			});
			resolve(true);
		});
	};

	const getSubscriptionStatus = async (userObject) => {
		let customerInfo = await Purchases.getPurchaserInfo()
        console.log("[Purchases] Customer Info:");
		console.log(JSON.stringify(customerInfo));
		console.log(customerInfo.entitlements.active);
		
		setSubscriptionInfo(customerInfo)
	}

	// Get the subscription options and store them in the global state
	const setupSubscriptions = async (userObject) => {
		console.log("Preparing purchases");
		Purchases.setDebugLogsEnabled(true);
		Purchases.setup("appl_PzeDmQKWSxqAyPhJcTWXUjNaQoQ", userObject.cognitoId);
		await Purchases.getOfferings(
			(offerings) => {
				console.log("Purchases offerings: ", { offerings });
				if (offerings.current !== null && offerings.current.availablePackages.length !== 0) {
					setSubscriptionOfferings(offerings.current);
				}
			},
			(error) => {
				console.log("Purchases Error: ", { error });
			}
		);
	};

	const updateNotifications = async (userObject = null) => {
		let recentUpdates = await storageService.getObject("recentUpdates");
		// Filters out updates that shouldn't be shown
		// Have to have two sets of logic because of payload format
		if (recentUpdates !== null) {
			recentUpdates = await recentUpdates.filter((update) => {
				if (update.payload.payload && excludedNotifications.includes(update.payload.payload.activityType) === false) {
					// console.log("update.payload.payload.activityType:" + update.payload.payload.activityType);
					return update;
				}
				if (update.payload.activityType && excludedNotifications.includes(update.payload.activityType) === false) {
					// console.log("update.payload.activityType:" + update.payload.activityType);
					return update;
				}
			});
		}
		console.log({ recentUpdates });
		setNotificationsList(recentUpdates);
		getRecentUpdatesFromAPI().then(async (rus) => {
			if (rus.length > 0) {
				console.log(rus.length + " recent updates found, processing");
				// Filters out updates that shouldn't be shown
				// Have to have two sets of logic because of payload format
				rus = await rus.filter((update) => {
					if (update.payload.payload && excludedNotifications.includes(update.payload.payload.activityType) === false) {
						// console.log("update.payload.payload.activityType:" + update.payload.payload.activityType);
						return update;
					}
					if (update.payload.activityType && excludedNotifications.includes(update.payload.activityType) === false) {
						// console.log("update.payload.activityType:" + update.payload.activityType);
						return update;
					}
				});
				rus = await processUpdates(rus, userState.user);
				console.log({ rus });
				setNotificationsList(rus);
			}
		});

		storageService.setItem("recentUpdateCount", "0");
	};

	const updateChallenges = async (userObject = null) => {
		const updateChallengeStatus = async (result) => {
			// States of a challenge: yet to start, in progress, recently completed, completed
			let today = moment();
			for (let [i, challenge] of result.challengeObject.entries()) {
				result.challengeObject[i].status = -1; // default state
				if (today < moment(challenge.startDate)) {
					result.challengeObject[i].status = 0; //  not yet started, based on ChallengeStatus in model
				}
				if (today >= moment(challenge.startDate) && parseInt(challenge.success) === 0 && parseInt(challenge.active) === 1) {
					result.challengeObject[i].status = 1; //  in progress, based on ChallengeStatus in model
				}
				if (today >= moment(challenge.endDate)) {
					result.challengeObject[i].status = 4; // completed, based on ChallengeStatus in model
				}
				if (today >= moment(challenge.endDate) && today < moment(challenge.endDate).add(1, "week")) {
					result.challengeObject[i].status = 3; // recently completed, based on ChallengeStatus in model
				}
			}
			// Figure out how to indicate if the user was successful in given week
			// Track last updated? Only look at Mondays? Break each challenge into weeks?
			return result;
		};

		if (userObject === null) {
			userObject = JSON.parse(await getUser(null));
		}

		let tempChallengeArray = await storageService.getObject("challenges");
		getAllUserChallenges(userObject.cognitoId).then(async (result) => {
			let updatedResult = await updateChallengeStatus(result);
			let tempChallengeObject = [];
			for (let challenge of updatedResult.challengeObject) {
				let challengeDetail = await getChallengeDetailsByChallengeID(challenge.challengeID);
				challenge.challengeDetails = challengeDetail;
				tempChallengeObject.push(challenge);
			}
			storageService.setObject("challenges", tempChallengeObject);
			setChallenges(tempChallengeObject);
		});
		setChallenges(tempChallengeArray);
	};

	const updateAvailableChallenges = async () => {
		let tempChallengeArray = await storageService.getObject("availableChallenges");
		getAvailableChallenges().then(async (result) => {
			storageService.setObject("availableChallenges", result);
			setAvailableChallenges(result);
		});
		setAvailableChallenges(tempChallengeArray);
	};

	const updateSquadData = async (userObject) => {
		let squadIDs = await getAllUserSquadIDs(null, true);
		let tempArray = [];
		for (let squadID of squadIDs) {
			let squad = await storageService.getObject("squad-info-" + squadID);
			getSquad(squadID, null, null, true).then((result) => {
				let squads = userSquads;
				squads[squadID] = result;
				setUserSquads(squads);
				console.log("Squad data updated");
			});
			tempArray[squadID] = squad;
		}
		setUserSquads(tempArray);
		console.log(`User squads updated`);
	};

	const createSquadPredictionObject = async (squadGoalObject) => {
		if (!squadGoalObject) {
			console.log(`No squad goal points`);
			return;
		}
		let squadPredictionPoints;
		let squadPredictionPercent;
		let squadPredictionText, squadPredictionIcon, iconColor;
		let squadFlavorText;
		if (squadGoalObject && squadGoalObject.prediction) {
			squadPredictionPoints = squadGoalObject.prediction;
		}
		squadPredictionPercent = Math.round((squadPredictionPoints / squadGoalObject.goalPoints) * 100);
		//squadPredictionPercent = 80;

		if (isGoalCompleted(squadGoalObject) === true) {
			squadPredictionText = "GOAL COMPLETED!";
			squadFlavorText = ":boom: Your squad hit their goal this week! Time to celebrate";
			squadPredictionIcon = "completed";
			iconColor = "green";
		} else if (squadPredictionPercent >= 100) {
			squadPredictionText = "ON TRACK";
			squadFlavorText = ":+1: Looks like you guys are on track to be successful this week. Keep it up and motivate your squad to close it out.";
			squadPredictionIcon = "checkmarkCircleSharp";
			iconColor = "green";
		} else if (squadPredictionPercent >= 90) {
			squadPredictionText = "CUTTING IT CLOSE";
			squadFlavorText =
				":grimace: Looks like you gusy might get there but it's going to be close. Help your squad pick up the pace with some words of encouragement";
			squadPredictionIcon = "warningSharp";
			iconColor = "yellow";
		} else if (squadPredictionPercent < 90) {
			squadPredictionText = "OFF TRACK";
			squadFlavorText =
				":disappointed: Something is off. Your squad is not looking like they'll hit their goal this week. What can you do to help everyone pick up the pace?";
			squadPredictionIcon = "closeCircleSharp";
			iconColor = "red";
		}

		let squadPredictionObject = {
			points: squadPredictionPoints,
			text: squadPredictionText,
			icon: squadPredictionIcon,
			iconColor: iconColor,
			flavorText: squadFlavorText,
			style: {
				backgroundColor: iconColor,
				left: "1px",
			},
		};

		return squadPredictionObject;
	};

	const updateSquadGoalData = async (userObject) => {
		// Get the locally stored squad data
		// Make that quickly available
		// Then pull updated data
		// And make that available
		// tricky part is fitting the model
		let dow = moment().format("ddd");
		let squadIDs = await getAllUserSquadIDs(null, true);
		let tempArray = squadGoals;
		for (let squadID of squadIDs) {
			let squadGoalObject = await storageService.getObject("squadGoal-" + squadID);
			getSquadGoal(squadID, null, null, true, true, true).then(async () => {
				let squadGoalObject = await storageService.getObject("squadGoal-" + squadID);
				// console.log("Squad object for ID: " + squadID);
				// console.log({ squadGoalObject });
				if (squadGoalObject === null) {
					console.log("No goal for squadID " + squadID + ". Exiting");
					return;
				}
				if (!squadGoalObject.prediction) {
					squadGoalObject.prediction = await getSquadPrediction(squadID);
				}
				squadGoalObject.predictionObject = await createSquadPredictionObject(squadGoalObject);
				console.log({ squadGoalObject });
				tempArray[squadID] = squadGoalObject;
				setSquadGoals(tempArray);
			});
			if (squadGoalObject === null) {
				console.log("No goal for squadID " + squadID + ". Exiting");
				return;
			}
			if (squadGoalObject && !squadGoalObject.prediction) {
				squadGoalObject.prediction = await getSquadPrediction(squadID);
			}
			squadGoalObject.predictionObject = await createSquadPredictionObject(squadGoalObject);
			tempArray[squadID] = squadGoalObject;
			setSquadGoals(tempArray);
		}
	};

	const updateSquadObjects = async (userObject) => {
		let dow = moment().format("ddd");
		let squadIDs = await getAllUserSquadIDs(null, true);
		let tempArray = [];
		for (let squadID of squadIDs) {
			//console.log("loading squad data");
			let squad = await storageService.getObject("squad-info-" + squadID);
			let squadGoal = await getSquadGoal(squadID, null, null, true, true, true);
			// If there's no data stored, fetch it with await. Otherwise can be done async
			if (squad === null) {
				squad = await getSquad(squadID, null, null, true);
			} else {
				getSquad(squadID, null, null, true).then((result) => {
					console.log("Squad data updated");
					squad = result;
				});
			}
			squad.lastWeekURL = "/squadlastweekcompleted/" + squadID;
			if (dow && dow !== "Mon") {
				if (squadGoal === null) {
					//console.log("squad goal is null");
					//setNullGoalNotice(true);
					return;
				}
				let percent = Math.round((squadGoal.goalProgress / squadGoal.goalPoints) * 100);
				if (percent > 100) {
					percent = 100;
				}
				let startOfIsoWeek = moment().startOf("isoWeek").format();
				let squadCreatedStartOfIsoWeek = moment(squad.createdAt).startOf("isoWeek").format();
				let squadPredictionPoints, squadPredictionPercent;
				let squadPredictionText, squadPredictionIcon, iconColor, chartColor, textColor;
				let squadFlavorText;
				let squadText = {};

				// Have something different if the squad was formed this week
				if (startOfIsoWeek === squadCreatedStartOfIsoWeek) {
					squad.startedThisWeek = true;
					console.log("Started this week");
					squadPredictionPercent = 0;
					if (squadGoal.goalProgress === 0) {
						squadText.title = "Ready to get started?";
						squadText.body = "Log your first activity by tapping the plus button. It can be for earlier in the week if you like.";
					} else {
						squadText.title = "Great Start 👍";
						squadText.body = "The more activities your squad logs, the better we'll be able to predict your weekly score.";
					}
					squadPredictionIcon = "trophySharp";
					iconColor = "var(--ion-color-tertiary)";
					squadPredictionText = squadText.title;
					squadFlavorText = squadText.body;
					textColor = "white";
					squadPredictionPoints = "TBD";
					chartColor = "green";
				}
				// Just broke this out so it's clearer
				if (startOfIsoWeek !== squadCreatedStartOfIsoWeek) {
					let seasonStarted = userState.seasonStarted;
					let userInSeason = userState.userInSeason;
					let showUpgradeIndicator = seasonStarted === true && userInSeason === false ? true : false;
					//seasonStarted = true;
					//userInSeason = true;
					if (showUpgradeIndicator === true) {
						squadGoal.prediction = "upgrade";
						squadPredictionPoints = "?";
						squadPredictionPercent = "upgrade";
						squadPredictionText = "Upgrade your season to see predictions";
						squadPredictionIcon = "upgrade";
						iconColor = "#1571b3";
						textColor = "white";
						chartColor = "blue";
						squadFlavorText = "";
					}
					if (showUpgradeIndicator === false) {
						if (!squadGoal.prediction) {
							//console.log("Fetching prediction??");
							squadGoal.prediction = await getSquadPrediction(squadID);
						}
						squadPredictionPoints = squadGoal.prediction;
						squadPredictionPercent = Math.round((squadPredictionPoints / squadGoal.goalPoints) * 100);
						//squadPredictionPercent = 80;
						if (squadGoal.goalProgress >= squadGoal.goalPoints) {
							squadText = await getGoalStatusSystemMessage("squadStatus", "goalcompleted");
							squadPredictionIcon = "completed";
							iconColor = "purple";
							textColor = "white";
							chartColor = "green";
						} else if (squadPredictionPercent >= 120) {
							squadText = await getGoalStatusSystemMessage("squadStatus", "crushit");
							squadPredictionIcon = "hammerSharp";
							iconColor = "orange";
							textColor = "white";
							chartColor = "green";
						} else if (squadPredictionPercent >= 100) {
							squadText = await getGoalStatusSystemMessage("squadStatus", "ontrack");
							squadPredictionIcon = "checkmarkCircleSharp";
							iconColor = "green";
							textColor = "white";
							chartColor = "green";
						} else if (squadPredictionPercent >= 90) {
							squadText = await getGoalStatusSystemMessage("squadStatus", "cuttingitclose");
							squadPredictionIcon = "warningSharp";
							iconColor = "yellow";
							textColor = "black";
							chartColor = "#f0ed00";
						} else if (squadPredictionPercent < 90) {
							squadText = await getGoalStatusSystemMessage("squadStatus", "offtrack");
							squadPredictionIcon = "closeCircleSharp";
							iconColor = "#525252";
							textColor = "white";
							chartColor = iconColor;
						}
						squadPredictionText = await titleCase(squadText.title);
						squadFlavorText = squadText.body;
					}
				}
				squadGoal.predictionObject = {};
				squadGoal.predictionObject.points = squadPredictionPoints;
				squadGoal.predictionObject.percent = squadPredictionPercent;
				squadGoal.predictionObject.text = squadPredictionText;
				squadGoal.predictionObject.textColor = textColor;
				squadGoal.predictionObject.icon = squadPredictionIcon;
				squadGoal.predictionObject.iconColor = iconColor;
				squadGoal.predictionObject.flavorText = squadFlavorText;
			}
			squad.goal = squadGoal;
			tempArray[squadID] = squad;
			console.log({ tempArray });
		}
		setSquadInfo(tempArray);
		console.log({ tempArray });

		// return new Promise((resolve) => {
		// 	console.log("finished updating squad info");
		// 	resolve(true);
		// });
	};

	const setGlobalGoal = async (goalObject) => {
		console.log("User Goal State Established");
		if (goalObject !== null) {
			goalObject.goalPercent = Math.round((goalObject.goalProgress / goalObject.goalPoints) * 100);
		} else {
			goalObject.goalPercent = 0;
		}
		return new Promise((resolve) => {
			setUserGoal(goalObject);
			resolve(true);
		});
	};

	const updateUserState = async (userObject = null) => {
		if (userObject === null) {
			userObject = JSON.parse(await getUser(null), true);
		}

		return new Promise((resolve) => {
			setUserstate({
				authenticated: true,
				user: userObject,
				deviceInfo: userState.deviceInfo,
				seasonStarted: userState.seasonStarted,
				userInSeason: userState.userInSeason,
			});
			resolve(true);
		});
	};

	const updateGoalState = async (goalObject = null) => {
		console.log("In UGS");
		if (goalObject === null) {
			goalObject = await getGoal(null, true, null, null, true, true);
		}
		if (goalObject !== null) {
			goalObject.goalPercent = Math.round((goalObject.goalProgress / goalObject.goalPoints) * 100);
		} else {
			goalObject = {};
			goalObject.goalPercent = 0;
		}
		// console.log({ goalObject });
		return new Promise((resolve) => {
			setUserGoal(goalObject);
			resolve(true);
		});
	};

	const updateChatRooms = async () => {
		console.log("updating chat rooms");
		// let tempChatRooms = await storageService.getObject("chatRooms");
		// getAllChatRooms(true, true).then(async (result) => {
		// 	storageService.setObject("chatRooms", result);
		// 	for await (let room of tempChatRooms) {
		// 		room.roomPage = "/chatroom/" + room.id;
		// 		await storageService.setObject(room.id, room);
		// 		await getMessagesAndActivities(room);
		// 		console.log(`Room ID ${room.id} created`);
		// 	}
		// 	setChatRoomsGlobal(result);
		// });
		// for await (let room of tempChatRooms) {
		// 	room.roomPage = "/chatroom/" + room.id;
		// 	await storageService.setObject(room.id, room);
		// 	await getMessagesAndActivities(room);
		// }
		// setChatRoomsGlobal(tempChatRooms);

		// setRefreshingChat(true);
		let chatRooms = await getAllChatRooms(true, true);
		// console.log({ chatRooms });
		for await (let room of chatRooms) {
			room.roomPage = "/chatroom/" + room.id;
			await storageService.setObject(room.id, room);
			await getMessagesAndActivities(room);
		}
		return new Promise((resolve) => {
			setChatRoomsGlobal(chatRooms);
			console.log("finished updating chat rooms");
			setRefreshingChat(false);
			resolve(true);
		});
	};

	const updateSquadMates = async (userObject, refresh = false) => {
		let currentTime = moment();
		if (squadMatesLastUpdated === null || moment(currentTime).diff(squadMatesLastUpdated, "minutes") > 5) {
			setCalculatingSquadMemberProgress(true);
			//Get active squads
			console.log("updateSquadMates");
			let tempArray = {};
			await Promise.all(
				userObject.squads.map(async (squad, i) => {
					//console.log({ squad });
					if (squad.accepted === true && squad.squad.disbanded !== true) {
						let squadArray = await getSquadMembers(null, squad.squad.id);
						if (squadArray.length === 0) {
							console.log(`No squad members. Returning`);
							return;
						}
						let members = [];
						let seasonStarted = false; // just keeping these in for now...
						let userInSeason = false;
						for (let element of squadArray) {
							element.goal = await getGoal(element.userID, refresh);
							//console.log({ element });
							if (element.goal !== null) {
								if (seasonStarted === true && userInSeason === false) {
									element.predictionText = "";
									element.iconColor = "";
									element.goal.prediction = "?";
								} else {
									if (element.goal.prediction === undefined || element.goal.prediction === null) {
										element.goal.prediction = await getPrediction(element.userID, refresh);
										if (seasonStarted === true && userInSeason === true) {
											element.goal.prediction = Math.round(element.goal.prediction * 1.1);
										}
									}
									let predictionPercent = Math.round((element.goal.prediction / element.goal.goalPoints) * 100);
									element.predictionPercent = predictionPercent;
									if (isGoalCompleted(element.goal) === true) {
										element.predictionText = "Goal completed";
										element.iconColor = "green";
									} else if (predictionPercent >= 120) {
										element.predictionText = "Going to crush it!";
										element.iconColor = "green";
									} else if (predictionPercent >= 100) {
										element.predictionText = "On track";
										element.iconColor = "green";
									} else if (predictionPercent >= 90) {
										element.predictionText = "Cutting it close";
										element.iconColor = "#D69B04";
									} else if (predictionPercent < 90) {
										element.predictionText = "Off track";
										element.iconColor = "red";
									}
								}
								element.goal.goalPercent = Math.round((element.goal.goalProgress / element.goal.goalPoints) * 100);
								members.push(element);
							} else {
								console.error(`User ${element.userID} doesn't have a goal`);
							}
						}
						members.sort((a, b) => (a.predictionPercent > b.predictionPercent ? -1 : 1));
						tempArray[squad.squad.id] = members;
						// console.log("In Map:");
						// console.log(tempArray[squad.squad.id]);
					}
				})
			);

			return new Promise((resolve) => {
				// console.log("Squad Members in Global State:");
				// console.log(JSON.stringify(tempArray[1]));
				// console.log({ tempArray });
				setSquadMemberProgress(tempArray);
				// console.log("finished updating squad member progress");
				setCalculatingSquadMemberProgress(false);
				setSquadMatesLastUpdates(moment());
			});
		} else {
			console.log("Updated less than 5 mins ago");
			return true;
		}
	};

	const logout = async () => {
		const storageService = new StorageService();
		LocalNotifications.removeAllListeners();
		await storageService.clear();
		localStorage.clear();
		setUserstate({
			authenticated: false,
			user: null,
		});
		return Promise.resolve(true);
	};

	const updateWeeklyLoveGiven = async () => {};

	const updateWeeklyProgress = async (userObject, refresh = true) => {
		let currentTimeZone = moment.tz.guess(true);
		console.log(`current timeZone: ${currentTimeZone}`);
		const storageService = new StorageService();
		//console.log("UserID: " + userObject.cognitoId);
		let pointHistory = await storageService.getObject("userPointHistory-" + userObject.cognitoId);
		if (pointHistory === null) {
			pointHistory = {};
		}
		if (!pointHistory.dailyTotals) {
			pointHistory.dailyTotals = {};
		}
		if (!pointHistory.dailyTotals.lastUpdated) {
			pointHistory.dailyTotals.lastUpdated = moment().format();
		}
		let lwDataSet = [];
		let twDataSet = [];
		let daysChecked = 0;
		let ttl = 30;
		let daysToCheck = moment().diff(moment().startOf("isoWeek").subtract(1, "weeks"), "days") + 1;
		let updated = false;
		// console.log("DAYS TO CHECK: ", daysToCheck);
		// console.log(moment().tz("UTC").format());

		while (daysChecked < daysToCheck) {
			// In this section the API is hit with the UTC time zone
			// But the results are categorized into the current time zone
			console.log(`daysChecked: ${daysChecked}`);
			let dailyTotal = 0;
			//setting TZ here is important
			let startDate = moment().tz("UTC").subtract(daysChecked, "days").startOf("day");
			let endDate = moment().tz("UTC").subtract(daysChecked, "days").endOf("day");
			let startDateLocal = moment().tz(currentTimeZone).subtract(daysChecked, "days").startOf("day");
			let endDateLocal = moment().tz(currentTimeZone).subtract(daysChecked, "days").endOf("day");
			let rightNow = moment();
			console.log("Checking " + startDate.format() + "to " + endDate.format());
			if (moment(startDateLocal).isSame(rightNow, "isoWeek") === true) {
				console.log("This week");
				let update;
				if (refresh === false) {
					update = await shouldRefresh(pointHistory.dailyTotals, ttl);
				} else {
					update = true;
				}
				if (!pointHistory.dailyTotals[moment(startDate).tz(currentTimeZone).format("MM/DD/YY")] || update === true) {
					console.log(`Pulling new data for user on ${moment(startDate).tz(currentTimeZone).format("MM/DD/YY")} update = ${update}`);
					//dailyTotal = await getPointsByDate(userObject.cognitoId, null, startDate, endDate);
					dailyTotal = await getSumPointsFromActivities(startDate, endDate, userObject.cognitoId);
					console.log(`Total points for ${moment(startDate.tz(currentTimeZone)).format("MM/DD/YY")} = ${dailyTotal}`);
					pointHistory.dailyTotals[moment(startDate).tz(currentTimeZone).format("MM/DD/YY")] = dailyTotal;
					updated = true;
				} else {
					console.log(
						`Using current data:\n date: ${moment(startDate).tz(currentTimeZone).format("MM/DD/YY")}  amount: ${
							pointHistory.dailyTotals[moment(startDate).tz(currentTimeZone).format("MM/DD/YY")]
						} update: ${update}`
					);
					dailyTotal = pointHistory.dailyTotals[moment(startDate).tz(currentTimeZone).format("MM/DD/YY")];
				}
				twDataSet.push(dailyTotal);
			} else {
				console.log("Last week");
				// last week, dont check for refresh
				if (!pointHistory.dailyTotals[moment(startDate).tz(currentTimeZone).format("MM/DD/YY")]) {
					console.log(`Pulling new data for user on ${moment(startDate).tz(currentTimeZone).format("MM/DD/YY")}`);
					//dailyTotal = await getPointsByDate(userObject.cognitoId, null, startDate, endDate);
					dailyTotal = await getSumPointsFromActivities(startDate, endDate, userObject.cognitoId);
					console.log(`Total points for ${moment(startDate).tz(currentTimeZone).format("MM/DD/YY")} = ${dailyTotal}`);
					pointHistory.dailyTotals[moment(startDate).tz(currentTimeZone).format("MM/DD/YY")] = dailyTotal;
				} else {
					console.log("using local info");
					dailyTotal = pointHistory.dailyTotals[moment(startDate).tz(currentTimeZone).format("MM/DD/YY")];
					console.log(`Total points for ${moment(startDate).tz(currentTimeZone).format("MM/DD/YY")} = ${dailyTotal}`);
				}
				lwDataSet.push(dailyTotal);
			}
			daysChecked++;
		}
		if (updated === true) {
			pointHistory.dailyTotals.lastUpdated = moment().format();
		}
		//console.log("setWeeklyProgress - pointHistory prior to setting: ", { pointHistory });
		await storageService.setObject("userPointHistory-" + userObject.cognitoId, pointHistory);
		// console.log("twdataset: ", { twDataSet });
		// console.log("lwDataSet: ", { lwDataSet });
		lwDataSet = lwDataSet.reverse();
		twDataSet = twDataSet.reverse();
		// This was added because sometimes the lwDataSet would have 1 too many results.
		// This cuts off the first (oldest) result
		if (lwDataSet.length > 7) {
			lwDataSet = lwDataSet.slice(1);
		}
		// console.log("lwDataSet fixed: ", { lwDataSet });

		console.log("Pulled a total of " + (daysChecked + 0) + " days");
		console.log("[JS] [pull weekly progress] Complete");
		setWeeklyProgress({
			lwDataSet: lwDataSet,
			twDataSet: twDataSet,
		});
		return new Promise((resolve) => {
			setWeeklyProgress({
				lwDataSet: lwDataSet,
				twDataSet: twDataSet,
			});
			console.log("finished updating weekly stats");
			resolve(true);
		});
	};

	let state = {
		userState,
		updateUserState,
		updateGoalState,
		updateWeeklyProgress,
		login,
		logout,
		pullingUpdates,
		setPullingUpdates,
		numberOfUpdates,
		setNumberOfUpdates,
		refreshingChat,
		setRefreshingChat,
		onboardingCompleted,
		setOnboardingCompleted,
		refreshInProgress,
		setRefreshInProgress,
		setGlobalGoal,
		globalGoal,
		setChatRoomsGlobal,
		chatRoomsGlobal,
		setWeeklyProgress,
		weeklyProgress,
		squadMemberProgress,
		calculatingSquadMemberProgress,
		updateSquadMates,
		squadInfo,
		updateSquadObjects,
		gettingSquadInfo,
		userSquads,
		squadGoals,
		updateSquadGoalData,
		challenges,
		setChallenges,
		updateChallenges,
		availableChallenges,
		setAvailableChallenges,
		challengesEnabled,
		notificationsList,
		setNotificationsList,
		subscriptionOfferings,
		setSubscriptionOfferings,
		subscriptionInfo,
		setSubscriptionInfo
	};
	return <Context.Provider value={state}>{children}</Context.Provider>;
};

export default Context;
