import { API_URL, PHP_URL, PHP_URL_NAME } from "../models/constants";
import { getIDToken } from "./AuthService";
import { StorageService } from "../services/StorageService";
import moment from "moment";
import { getWeight } from "./HealthKitService";
import { v4 as uuidv4 } from "uuid";
import { getUserID, getUserNameFromUserID, getUserNameOfLoggedInUser } from "./UserService";
import { sendSlackErrorNotification, sendSlackNotification } from "./NotificationService";
import { logAddActivityEvent } from "./AnalyticsService";
const buffer = 120

/**
 *
 * Gets the activities associated with a squad.
 *
 *
 * @param {string} squadID
 * @param {integer} activityLimit
 * @param {integer} activityOffset
 * @param {moment} startDateMoment
 *
 * Returns JSON stringified data. Returns null if there are no activities
 *
 */

export const getSquadActivities = async (squadID, limit = 100, offset = 0, updatedAtMoment = null, startDateMoment = null, endDateMoment = null, storeResult = true) => {
	//console.log("Getting squad activities");
	const storageService = new StorageService();
	let url = API_URL + "activities?squadId=" + squadID
	if (limit !== null) {
		url = url + "&limit=" + limit + "&offset=" + offset
	}
	// Should the be put below for efficiency sake?
	let localActivitiesArray = await storageService.getObject("squad-activities-" + squadID);
	if (localActivitiesArray) {
		//console.log("Local activities array found");
	} else {
		//console.log("No local activities array found");
		localActivitiesArray = [];
	}
	if (updatedAtMoment !== null) {
		// if an updatedAt date is passed in, use that instead of limit/offset
		//console.log("Fetching activities since: " + updatedAtMoment.format());
		url = url + "&updatedAt=" + updatedAtMoment.format();
	} 
	if (startDateMoment !== null) {
		// if a specific date range is passed in, use that
		//console.log("Fetching activities on: " + startDateMoment.format());
		url = url +  "&startDate=" + startDateMoment.format() +"&endDate=" + endDateMoment.format()
	}
	let idToken = await getIDToken().catch((error) => {
		throw Error("[getSquadActivities] Error in getIDtoken: " + error);
	});

	//console.log(`GetSquadActivities URL: ${url}`);

	const response = await Promise.resolve(
		fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[JS][getSquadActivities] Error in API call: " + error);
		throw Error(error);
	});
	return response
		.json()
		.then(async (data) => {
			//console.log("[JS] getSquadActivities response:", data);
			let serverResponse = JSON.stringify(data);
			if (serverResponse === "" || serverResponse === "[]" || serverResponse.length === 0) {
				// if nothing is returned by the API, nothing to do
				// console.log("No new activities returned");
				serverResponse = null;
			} else {
				// if it does return something
				//console.log("getSquadActivities: " + serverResponse);
				// This filters the activities that are not included in the local array
				data.forEach((element) => {
					//console.log("Activity Element: ",{element});
					let filterResult = localActivitiesArray.filter((x) => x.id === element.id); // need to keep an eye on this and make sure it actually runs
					//console.log("ID of latest activity: " + element.id);
					//console.log("filterResult: " + JSON.stringify(filterResult));
					if (Object.keys(filterResult).length === 0) {
						//console.log("No activity with ID = " + element.id + " found. Pushing");
						localActivitiesArray.push(element);
					} else {
						//console.log("Found dupe activity in local storage: " + JSON.stringify(filterResult));						
						for (var x = 0; x < localActivitiesArray.length; x++) {
							//console.log("Checking id "+localActivitiesArray[x].id+" to see if it equals "+element.id);
							if (localActivitiesArray[x].id === element.id) {
								localActivitiesArray[x] = element
								//console.log("Replaced entry in local storage with server version");
							}
						}
					}

				});
				if (storeResult === true){
					console.log("Squad activity elements for squadID "+squadID);
					let x = 0;
					for (let element of localActivitiesArray){
						// this ensures activities have an image
						if (element && element["activityData"]){
							// GIF handler
							let imageURL = null
							if(element["activityData"]["imageURL"]){ // if it' already there on the element, set the variable
								imageURL = element["activityData"]["imageURL"]
							} else if(element["activityData"]["reportId"] && element["activityData"]["imageURL"] !== null){ // if it's not there but reportId is, fetch it
								let response = await getActivityImage(element["activityData"]["reportId"])
								if (response.imageURL !== false){
									imageURL = response.imageURL
									// Patch the activity 
									element["activityData"]["imageURL"] = imageURL
									updateActivity(element)									
								} else {
									element["activityData"]["imageURL"] = null
									updateActivity(element)
								}
							}
							element["activityData"]["imageURL"] = imageURL
						}
						localActivitiesArray[x] = element
						x++
					}
					await storageService.setObject("squad-activities-" + squadID, localActivitiesArray);
				}
			}
			return serverResponse;
		})
		.catch((error) => {
			console.log("[JS][getSquadActivities] Error in JSON conversion: " + error);
			throw Error(error);
		});
};

export const getUserActivities = async (limit = 10, offset = 0, startDateMoment = null, endDateMoment = null, sortField = null, userID = null, queryEndDate = true) => {
	//Note: dates dont currently function
	const url = API_URL;
	let fullURL;
	//console.log("Getting users activities for userID: "+userID);
	let idToken = await getIDToken().catch((error) => {
		throw Error("[getUserActivities] Error in getIDtoken: " + error);
	});
	if (startDateMoment == null) {
		fullURL = url + "activities?limit=" + limit + "&offset=" + offset;
	} else {
		fullURL = url + "activities?limit=100&startDate=" + startDateMoment.format() + "&endDate=" + endDateMoment.format();
	}
	if (sortField !== null) {
		fullURL = fullURL + "&sortField="+sortField
	}
	if (userID !== null){
		fullURL = fullURL + "&userId="+userID
	}
	fullURL = fullURL + "&queryEndDate="+queryEndDate
	console.log("Fetching user activties fullURL: " + fullURL);
	const response = await Promise.resolve(
		fetch(fullURL, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[JS][getUserActivities] Error in API call: " + error);
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {
			//console.log("[JS] getUserActivities Success:", data);
			let severResponse = JSON.stringify(data);
			return severResponse;
		})
		.catch((error) => {
			console.log("[JS][getUserActivities] Error in JSON conversion: " + error);
			throw Error(error);
		});
};

export const getActivityDetails = async (activityID) => {
	//Note: dates dont currently function
	const url = API_URL;
	//console.log("Getting activity details for activity " + activityID);
	let idToken = await getIDToken().catch((error) => {
		throw Error("[getUserActivities] Error in getIDtoken: " + error);
	});
	const response = await Promise.resolve(
		fetch(url + "activities/" + activityID, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[JS][getActivityDetails] Error in API call: " + error);
		throw Error(error);
	});
	console.log({response});
	return response
		.json()
		.then((data) => {
			console.log("[JS] getActivityDetails Success:", data);
			let severResponse = JSON.stringify(data);
			return severResponse;
		})
		.catch((error) => {
			console.log("[JS][getActivityDetails] Error in JSON conversion: " + error);
			throw Error(error);
		});
};

/**
 *
 * Gets the sum of a users' points for a given day based on the activities table
 *
 * Returns an integer for number of points
 *
 * @param {*} startDateMoment
 * @param {*} endDateMoment
 */

export const getSumPointsFromActivities = async (startDateMoment = moment().startOf("day"), endDateMoment = moment(), userID = null) => {
	//console.log("[getSumPointsFromActivities] Getting data for "+startDateMoment.format("YYYY-MM-DD"));
	let userActivitiesArray = await JSON.parse(await getUserActivities(null, null, startDateMoment, endDateMoment, null, userID));
	console.log({userActivitiesArray});
	let totalPoints = 0;
	for (let activity of userActivitiesArray) {		
		if (activity.points !== null && activity.points !== undefined && activity.points !== 0 && activity.squadId === null && activity.deleted === false) {
			totalPoints += activity.points + activity.bonusPoints
			//console.log(`Adding points for ${endDateMoment.format()}: `,{activity});
		} else {
			//console.log("activity ID " + activity["id"] + " does not have points associated with it. It is activityType " + activity["activityType"]);
		}
	}
	return totalPoints;
};

/**
 * 
 * Gets the sum of a squads' points for a given day based on the activities table
 *
 * Returns an integer for number of points
 * 
 * @param {*} squadID 
 * @param {*} startDateMoment 
 * @param {*} endDateMoment 
 */
export const getSquadSumPointsFromActivities = async (squadID, startDateMoment = moment().startOf("day"), endDateMoment = moment()) => {
		let squadActivitiesArray = await JSON.parse(await getSquadActivities (squadID, null, null, null, startDateMoment, endDateMoment))
		console.log({squadActivitiesArray});
		let totalPoints = 0;
		if (squadActivitiesArray === null){
			return totalPoints
		}
		for (let activity of squadActivitiesArray) {
			if (activity.points !== null && activity.points !== undefined && activity.points !== 0) {
				totalPoints += activity.points;
			} else {
			}
		}
	return totalPoints;
};

export const convertDurationToMinutes = async (logWorkoutArray) => {
	let minutes;
	switch (logWorkoutArray.unit) {
		case "min":
		case "mins":
		case "m":
			minutes = logWorkoutArray.duration;
			break;
		case "h":
		case "hour":
		case "hours":
			minutes = logWorkoutArray.duration * 60;
			break;
		case "s":
		case "sec":
		case "secs":
		case "second":
		case "seconds":
			minutes = logWorkoutArray.duration / 60;
			break;
		default:
			minutes = logWorkoutArray.duration;
			break;
	}
	return minutes;
};

const getMetsFromActivity = async (activityName, intensity = "moderate") => {
	const url = PHP_URL;
	//console.log("Getting mets for activity " + activityName + " with intensity = " + intensity);
	const response = await Promise.resolve(
		fetch(url + "log.workout.simple.php?activity=" + activityName + "&intensity=" + intensity, {
			method: "GET",
		})
	).catch((error) => {
		console.log("[JS][getMetsFromActivity] Error in API call: " + error);
		throw Error(error);
	});
	let responseArray = JSON.parse(JSON.stringify(await response.json()));
	console.log("Response from getMetsFromActivity: " + responseArray["mets"]);
	return responseArray["mets"];
};

export const convertActivityToPoints = async (activityArray) => {
	let fitbitFactor = 1.75;
	let minutes = await convertDurationToMinutes(activityArray);
	let userWeight = await getWeight(false);
	//console.log("Converting activity to points");
	//console.log("Minutes = " + minutes);
	//console.log("Weight = " + userWeight);
	// Convert activity to mets
	let mets = await getMetsFromActivity(activityArray["activity"]);
	//console.log("Mets = " + mets);
	// Convert using mets, duration & weight to calc calories
	let calorieBurned = Math.round(minutes * 0.0175 * (mets + 1) * userWeight, -1);
	if (calorieBurned < 1) {
		calorieBurned = 1;
	} // Just in case
	// Convert calories to points
	//console.log("Calories burned = " + calorieBurned);
	let pointsEarned = Math.round(100 * (calorieBurned / userWeight) * fitbitFactor);
	//console.log("Points earned = " + pointsEarned);
	return pointsEarned;
};

export const convertActivityToCalories = async (activityArray) => {
	let minutes = await convertDurationToMinutes(activityArray);
	let userWeight = await getWeight(false);
	//console.log("Converting activity to points");
	//console.log("Minutes = " + minutes);
	//console.log("Weight = " + userWeight);
	// Convert activity to mets
	let mets = await getMetsFromActivity(activityArray["activity"]);
	//console.log("Mets = " + mets);
	// Convert using mets, duration & weight to calc calories
	let caloriesBurned = Math.round(minutes * 0.0175 * (mets + 1) * userWeight, -1);
	if (caloriesBurned < 1) {
		caloriesBurned = 1;
	} // Just in case
	//console.log("Calories burned = " + caloriesBurned);
	return caloriesBurned;
};

export const addActivity = async (logWorkoutArray) => {
	//console.log("Attempting to add activity to Activities");
	const url = API_URL;
	let userName = await getUserNameOfLoggedInUser()
	let idToken = await getIDToken().catch((error) => {
		throw Error("[addActivity] Error in getIDtoken: " + error);
	});
	let endDate = moment(logWorkoutArray.date).format();
	let startDate = endDate;
	let dataType
	console.log("Logworkout array: " + JSON.stringify(logWorkoutArray));
	if (logWorkoutArray.duration > 0) {
		startDate = moment(endDate).subtract(logWorkoutArray.duration, "minutes").format();
		//console.log("Setting endDate to " + endDate + " and startDate to " + startDate);
	}
	if (!logWorkoutArray.dataType) {
		dataType = 'workout'
	} else {
		dataType = logWorkoutArray.dataType
	}
	let reportID = uuidv4()
	let userID = await getUserID()
	let data = {
		dataType: dataType,
		activityType: logWorkoutArray.activity,
		value: logWorkoutArray.duration,
		valueUnit: logWorkoutArray.unit,
		totalDistance: logWorkoutArray.distance,
		totalDistanceUnit: logWorkoutArray.distanceUnit,
		totalEnergyBurned: logWorkoutArray.calories,
		sourceName: "Manual Log",
		creationDate: moment().format(),
		startDate: startDate,
		endDate: endDate,
		reportId: reportID,
		imageUrl: logWorkoutArray.imageURL
	};
	let data2 = {
		dataType: dataType,
		userName: userName,
		userID: userID,
		activityType: logWorkoutArray.activity,
		duration: logWorkoutArray.duration,
		durationUnit: logWorkoutArray.unit,
		distance: logWorkoutArray.distance,
		distanceUnit: logWorkoutArray.distanceUnit,
		caloriesBurned: logWorkoutArray.calories,
		source: "Manual Log",
		startDate: startDate,
		endDate: endDate,
		reportID: reportID,
	};
	let activityImageJSON = {
		reportID: reportID,
		imageURL: logWorkoutArray.imageURL
	}
	fetch("https://fitpetapp.com/fitbotphp/fitsquad/activityImages.php", {
		method: "POST",
		body: JSON.stringify(activityImageJSON),
		headers: {
			"Content-Type": "application/json",
		},
	})
	//console.log("Data object: " + JSON.stringify(data));
	sendSlackNotification("*New Activity Logged*💪\nUserName: " + userName + "\nActivity: " + data.activityType + "\nDuration: " + data.value + " minutes\nGIF: "+data.imageUrl+"\nShuffles: "+logWorkoutArray.shuffleCounter, "activity");
	fetch("https://x8ki-letl-twmt.n7.xano.io/api:-91w5asB/activities", {
		method: "POST",
		body: JSON.stringify(data2),
		headers: {
			"Content-Type": "application/json",
		},
	})

	console.log("data posting to activities API: ");
	console.log({data});

	const response = await Promise.resolve(
		fetch(url + "activities", {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[JS][addActivity] Error in API call: " + error);
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {
			console.log("[JS] addActivity Success:", data);
			logAddActivityEvent(data)
			return data;
		})
		.catch((error) => {
			console.log("[JS][addActivity] Error in JSON conversion: " + error);
			throw Error(error);
		});
};

export const addHKActivity = async (workoutObject) => {
	//console.log("Attempting to add activity to Activities");
	const url = API_URL;
	let idToken = await getIDToken().catch((error) => {
		throw Error("[addHKActivity] Error in getIDtoken: " + error);
	});
	//console.log("Logworkout array: " + JSON.stringify(workoutObject));
	//console.log("Data object: " + JSON.stringify(workoutObject));
	const response = await Promise.resolve(
		fetch(url + "healthkit", {
			method: "POST",
			body: JSON.stringify(workoutObject),
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[addHKActivity Error in API call: " + error);
		//throw Error(error);
	});

	return response
		.json()
		.then((data) => {
			console.log("[addHKActivity] Success:", data);
			let severResponse = JSON.stringify(data);
			return severResponse;
		})
		.catch((error) => {
			console.log("[addHKActivity] Error in JSON conversion: " + error);
			throw Error(error);
		});
};

export const sendStepsToServer = async (stepsArray) => {
	//console.log("Attempting to add activity to Activities");
	const url = API_URL;
	let idToken = await getIDToken().catch((error) => {
		throw Error("[addHKActivity] Error in getIDtoken: " + error);
	});
	//console.log("Logworkout array: " + JSON.stringify(workoutObject));
	//console.log("Data object: " + JSON.stringify(workoutObject));
	const response = await Promise.resolve(
		fetch(url + "healthkit", {
			method: "POST",
			body: JSON.stringify(stepsArray),
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[sendStepsToServer Error in API call: " + error);
		//throw Error(error);
	});

	return response
		.json()
		.then((data) => {
			console.log("[sendStepsToServer] Success:", data);
			return data;
		})
		.catch((error) => {
			console.log("[sendStepsToServer] Error in JSON conversion: " + error);
			throw Error(error);
		});
};

export const getLatestSquadActivities = async (squadID, limit = 100, offset = 0, startDateMoment = null, endDateMoment = null) => {
	const storageService = new StorageService();
	let latestSquadActivities = [];
	let lastSquadActivityPullTS = await storageService.getItem("lastSquadActivityPull-" + squadID);
	let tsPlusBuffer = moment(lastSquadActivityPullTS).subtract(buffer, "seconds")
	let squadActivitiesArray = await storageService.getObject("squad-activities-" + squadID);
	//console.log("Last squad activity pulled: " + moment(tsPlusBuffer).format());
	if ((lastSquadActivityPullTS !== "" || lastSquadActivityPullTS !== undefined || lastSquadActivityPullTS !== null) && squadActivitiesArray) {
		//console.log("Pulling activities since " + moment(tsPlusBuffer).format());
		latestSquadActivities = await getSquadActivities(squadID, null, null, moment(tsPlusBuffer));
	} else {
		//console.log("No last actvity pull found or no activity array. Pulling last 25 activities");
		latestSquadActivities = await getSquadActivities(squadID, limit, offset, null, startDateMoment, endDateMoment);
	}
	await storageService.setItem("lastSquadActivityPull-" + squadID, moment().format());
	//console.log("lastMessagelastSquadActivityPullPull set to: " + (await storageService.getItem("lastSquadActivityPull-" + squadID)));
	return latestSquadActivities;
};

export const getMostCommonActivities = async (userID = null) => {
	if (userID === null){
		userID = await getUserID()
	}
	const url = "https://x8ki-letl-twmt.n7.xano.io/api:-91w5asB/mostfrequentactivities/"+userID
	let response
	let statusCode
	await fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			"Accept": "application/json",
		},
	}).catch(async (error)=>{
			let userName = await getUserNameFromUserID(userID)
			console.log({error});
			sendSlackErrorNotification(error.status,"\nGET: "+url,"User ID: "+userID+"\nUserName: "+userName+"\nResult: "+error)
			throw new Error(error.status + " Failed Fetch ");
	}).then(async(result) => {
		// status 404 or 500 will set ok to false
		if (result.ok) {
			// Success: convert data received & run callback
			response = result
			statusCode = result.status
		}
		else {
			let userName = await getUserNameFromUserID(userID)
			console.log({result});
			sendSlackErrorNotification(result.status,"\nGET: "+url,"User ID: "+userID+"\nUserName: "+userName+"\nResult: "+JSON.stringify(result))
			throw new Error(result.status + " Failed Fetch ");
		}
	})

	return response
		.json()
		.then((data) => {
			data.statusCode = statusCode
			console.log(`[GET ${url}] success: ${JSON.stringify(data)}`);
			return data;
		})
		.catch((error) => {
			console.log(`[GET ${url}] Error in JSON conversion: ${error}`);
			throw Error(error);
		});
}

export const getActivityImage = async (reportID) => {
	const url = PHP_URL_NAME+"fitsquad/activityImages.php?reportID="+reportID
	let response
	let statusCode
	await fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			"Accept": "application/json",
		},
	}).catch(async (error)=>{
		let userID = await getUserID()
			let userName = await getUserNameFromUserID(userID)
			
			console.log({error});
			sendSlackErrorNotification(error.status,"\nGET: "+url,"User ID: "+userID+"\nUserName: "+userName+"\nResult: "+error)
			throw new Error(error.status + " Failed Fetch ");
	}).then(async(result) => {
		// status 404 or 500 will set ok to false
		if (result.ok) {
			// Success: convert data received & run callback
			response = result
			statusCode = result.status
		}
		else {
			let userID = await getUserID()
			let userName = await getUserNameFromUserID(userID)
			console.log({result});
			sendSlackErrorNotification(result.status,"\nGET: "+url,"User ID: "+userID+"\nUserName: "+userName+"\nResult: "+JSON.stringify(result))
			throw new Error(result.status + " Failed Fetch ");
		}
	})

	return response
		.json()
		.then((data) => {
			data.statusCode = statusCode
			console.log(`[GET ${url}] success: ${JSON.stringify(data)}`);
			return data;
		})
		.catch((error) => {
			console.log(`[GET ${url}] Error in JSON conversion: ${error}`);
			throw Error(error);
		});
}

export const postActivityImage = async (reportID, imageURL) => {
	const url = PHP_URL_NAME+"fitsquad/activityImages.php"
	if (!reportID || !imageURL) {
		return null
	}
	let response
	let statusCode
	let data = {
		reportID: reportID,
		imageURL: imageURL
	}
	await fetch(url, {
		method: "POST",
		body: JSON.stringify(data),
		headers: {
			"Content-Type": "application/json",
			"Accept": "application/json",
		},
	}).catch(async (error)=>{
		let userID = await getUserID()
			let userName = await getUserNameFromUserID(userID)
			
			console.log({error});
			sendSlackErrorNotification(error.status,"\nGET: "+url,"User ID: "+userID+"\nUserName: "+userName+"\nResult: "+error)
			throw new Error(error.status + " Failed Fetch ");
	}).then(async(result) => {
		// status 404 or 500 will set ok to false
		if (result.ok) {
			// Success: convert data received & run callback
			response = result
			statusCode = result.status
		}
		else {
			let userID = await getUserID()
			let userName = await getUserNameFromUserID(userID)
			console.log({result});
			sendSlackErrorNotification(result.status,"\nGET: "+url,"User ID: "+userID+"\nUserName: "+userName+"\nResult: "+JSON.stringify(result))
			throw new Error(result.status + " Failed Fetch ");
		}
	})

	return response
		.json()
		.then((data) => {
			data.statusCode = statusCode
			console.log(`[GET ${url}] success: ${JSON.stringify(data)}`);
			return data;
		})
		.catch((error) => {
			console.log(`[GET ${url}] Error in JSON conversion: ${error}`);
			throw Error(error);
		});
}

export const updateActivity = async (data) => {
	// console.log(`data passed to updating activity: ${JSON.stringify(data)}`);
	let url = API_URL + "activities/"+data.id
	let idToken = await getIDToken().catch((error) => {
		throw Error("[updateActivity] Error in getIDtoken: " + error);
	});
	let body = {
		activityData: data.activityData
	}
	// console.log(`data passed to API: ${JSON.stringify(body)}`);
	const response = await Promise.resolve(
		fetch(url, {
			method: "PATCH",
			body: JSON.stringify(body),
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[updateActivity] Error in API call: " + error);
		throw Error(error);
	});
	// console.log(`Response from updating activity: ${JSON.stringify(response)}`);
}

