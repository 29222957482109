import React from "react";
import { IonButton, IonIcon } from "@ionic/react";
import "../css/ChatInput.css";
import { cameraOutline } from "ionicons/icons";
import { sendMessage } from "../services/ChatService";

import { Plugins, CameraResultType, CameraSource } from "@capacitor/core";
const { Camera } = Plugins;

const SendPhotoToChatComponent = ({ chatRoomObj, setFeed, setScroller, setShowToast }) => {
	const takePhotoHandler = async () => {
		const photo = await Camera.getPhoto({
			allowEditing: false,
			correctOrientation: true,
			resultType: CameraResultType.DataUrl,
			source: CameraSource.Prompt,
			quality: 80,
		});
		sendMessage(photo.dataUrl, chatRoomObj, setFeed, setScroller, setShowToast, "image");
		//let updateUserResponse = await updateUser(body);
		//console.log("Update User Response: ", { updateUserResponse });
	};

	// if not show a text box with a submit button
	return (
		<IonButton onClick={() => takePhotoHandler()} className="submit-button" color="chat-bar-button" style={{ fontSize: "0px" }}>
			<IonIcon size="small" icon={cameraOutline}></IonIcon>
		</IonButton>
	);
};

export default SendPhotoToChatComponent;
