import {
	IonButton,
	IonCol,
	IonContent,
	IonDatetime,
	IonGrid,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonLoading,
	IonPopover,
	IonRow,
	IonSegment,
	IonSegmentButton,
	IonTitle,
	IonToolbar,
	IonModal,
	IonSelect,
	IonSelectOption,
	useIonViewWillEnter,
	IonCheckbox,
	IonSpinner,
	IonToast,
	IonCard,
} from "@ionic/react";
import { CameraResultType, CameraSource, Plugins } from "@capacitor/core";
import { closeOutline } from "ionicons/icons";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import Emoji from "react-emoji-render";
import "../css/AddActivityComponent.css";
import "../css/Home.css";
import { logActivityPlaceholderText } from "../models/constants";
import { addActivity, getMostCommonActivities } from "../services/ActivitiesService";
import { getMultipleRandomGifsFromSearch, getRandomGifFromSearch } from "../services/GifService";
import { getRandomValueFromArray } from "../services/HelperServices";
import { getActivitySystemMessage } from "../services/MessageServices";
import AuthContext from "../contexts/GlobalState";
import { getGoal } from "../services/PointsAndGoalsService";
import { StorageService } from "../services/StorageService";
import { getUser, getUserNameOfLoggedInUser } from "../services/UserService";
import { sendSlackNotification } from "../services/NotificationService";
import { getUserNameFromUserID } from "../services/UserService";
import CoachMarkController from "./CoachMarks/CoachMarkController";
import { ImageService } from "m3o/image";
import RandomChallengeComponent from "./Challenges/RandomChallengeComponent";
import ChallengeHeaderComponent from "./Challenges/ChallengeHeaderComponent";
import { logGenericEvent, logPageView } from "../services/AnalyticsService";
import { useHistory } from "react-router";

const AddActivityComponentCore = ({ showModal, setFeedbackPopover }) => {
	const storageService = new StorageService();
	const imageService = new ImageService("OTIyNjFmZjItNjdlOC00ZThmLTg0MzktMmVhZGUyZDZmNTVi");
	const history = useHistory();

	const { Camera } = Plugins;
	const { updateGoalState, userState, challenges, availableChallenges } = useContext(AuthContext);
	//const [modalState, setModalState] = useState(true);
	const [adding, setAdding] = useState(false);
	const [gifing, setGiffing] = useState(false);
	const [showFrequent, setShowFrequent] = useState(false);
	const [gifMessage, setGifMessage] = useState({
		visible: false,
		message: null,
		imageURL: null,
	});
	const [activityText, setActivityText] = useState("");
	const [stepsText, setStepsText] = useState("");
	const [activityTime, setActivityTime] = useState("");
	const [activityDate, setActivityDate] = useState("");
	const [stepsDate, setStepsDate] = useState("");
	const [activityIntensity, setActivityIntensity] = useState("");
	const [placeholderText] = useState(getRandomValueFromArray(logActivityPlaceholderText));
	const [minutes, setMinutes] = useState(0);
	const [hours, setHours] = useState(0);
	const [steps, setSteps] = useState(0);
	const [totalTime, setTotalTime] = useState(0);
	const [intensity, setIntensity] = useState("normal");
	const [activityType, setActivityType] = useState("activity");
	const [timeUnit, setTimeUnit] = useState("minutes");
	const [done, setDone] = useState(false);
	const [selectedDate, setSelectedDate] = useState(moment().format("MM/DD/YYYY"));
	const [activityText2, setActivityText2] = useState(getRandomValueFromArray(logActivityPlaceholderText));
	const activity = useRef(null);
	const [frequentActivities, setFrequentActivities] = useState(null);
	const [activityData, setActivityData] = useState({
		activity: null,
		duration: null,
		unit: null,
		calories: null,
		date: null,
		dataType: null,
		imageURL: null,
		shuffleCounter: 0,
	});
	const [randomGifArray, setRandomGifArray] = useState([]);
	const [showGIFs, setShowGIFs] = useState(true);
	const [takenPhoto, setTakenPhoto] = useState(null);
	const [loadingImage, setLoadingImage] = useState(false);
	const [errorToast, setErrorToast] = useState({
		visible: false,
		message: null,
	});
	const [randomChallengeID, setRandomChallengeID] = useState();

	//const maxDate = "2020-11-20";

	//var muscleSize = "30px";
	//var backgroundColor = "background-blue";
	//const minutes = useRef(30);
	//const hours = useRef(0);

	const normalIntensityMultiplier = 4;
	const moderateIntensityMultiplier = 7;
	const highIntensityMultiplier = 11;

	useEffect(() => {
		//console.log({ mostFrequentActivities });
		if (done === false && activityText !== "") {
			updateActivityText();
		}
	});

	useEffect(() => {
		logPageView("Add Activity");
		getFrequentActivities();
	}, []);

	useEffect(() => {
		if (challenges && challenges.length === 0 && availableChallenges && availableChallenges.length > 0) {
			setRandomChallengeID(Math.floor(Math.random() * availableChallenges.length));
		}
	}, [challenges, availableChallenges]);

	useEffect(() => {}, [gifMessage, showGIFs]);

	const takePhotoHandler = async () => {
		setLoadingImage(true);
		const photo = await Camera.getPhoto({
			allowEditing: false,
			correctOrientation: true,
			resultType: CameraResultType.DataUrl,
			source: CameraSource.Prompt,
			quality: 80,
			width: 500,
		});
		let name = Date.now() + ".png";
		//console.log("Photo String: " + JSON.stringify(photo));
		const rsp = await imageService
			.convert({
				name: name,
				outputURL: true,
				base64: photo.dataUrl,
			})
			.catch((error) => {
				// console.log({ error });
				setErrorToast({
					visible: true,
					message: "Error processing photo. Please try again (" + error.Status + ")",
				});
				setLoadingImage(false);
				return;
			});

		// console.log(`Photo URL ${rsp.url}`);
		setTakenPhoto({
			path: photo.dataUrl,
			preview: photo.dataUrl,
			url: rsp.url,
		});
		setLoadingImage(false);
	};

	const getFrequentActivities = async () => {
		let tempFrequent = await getMostCommonActivities();
		let result = await storageService.getItem("addGIFs");
		if (result === "false" || result === false) {
			setShowGIFs(false);
		}
		setFrequentActivities(tempFrequent);
	};

	const updateActivityText = (e = null) => {
		let at2 = activityText2;
		if (at2) {
			// do this because when tapping the toggle it trip ionChange 🤷🏾‍♂️
			let text;
			//console.log("Activity: ", { activity });
			if (moment(selectedDate).format("MM/DD/YYYY") !== moment().format("MM/DD/YYYY")) {
				setActivityDate("On <b>" + moment(selectedDate).format("MM/DD/YYYY") + "</b>");
			} else {
				setActivityDate("");
			}
			text = "Your activity was <b>" + at2 + "</b>";
			let localMinutes = minutes;
			let localHours = hours;

			if (localMinutes === null || localMinutes === undefined || localMinutes === "") {
				localMinutes = 0;
			}
			if (localHours === null || localHours === undefined || localHours === "") {
				localHours = 0;
			}
			let time = parseInt(localHours) * 60 + parseInt(localMinutes);
			setTotalTime(time);
			let timeText = "you did it for <b>" + time + " minutes </b>";
			let intensityText = "and you did it at <b>" + intensity + "</b> intensity?";

			setActivityText(text);
			setActivityTime(timeText);
			setActivityIntensity(intensityText);
		} else {
			setActivityText(null);
		}
		//}
	};

	const updateStepsText = (stepsUpdated = null, date = null) => {
		if (date !== null) {
			setSelectedDate(date);
		} else {
			date = selectedDate;
		}
		if (stepsUpdated !== null) {
			setSteps(stepsUpdated);
		} else {
			stepsUpdated = steps;
		}
		if (stepsUpdated) {
			if (moment(date).format("MM/DD/YYYY") !== moment().format("MM/DD/YYYY")) {
				setStepsText("On <b>" + moment(date).format("MM/DD/YYYY") + "</b> you took <b>" + stepsUpdated.toLocaleString() + " steps?</b>");
			} else {
				setStepsText("<b>Today</b>, you took <b>" + stepsUpdated.toLocaleString() + " steps?</b>");
			}
		} else {
			setStepsText(null);
		}
	};

	const updateIntensity = (value) => {
		setIntensity(value);
	};

	const updateActivityType = (value) => {
		/* Not sure why this is needed
		if (value === "steps") {
			activity.current.value = "steps";
		}
		*/
		setActivityType(value);
	};

	const getRandomGifFromArray = (gifArray = null) => {
		let gifNumber;
		if (gifArray !== null) {
			gifNumber = Math.floor(Math.random() * gifArray.length);
			// console.log({ gifNumber });
		}
		return gifArray[gifNumber].images.fixed_width.url.split("?")[0];
	};

	const addActivityConfirm = async () => {
		setGiffing(true);

		let calories, points;

		switch (intensity) {
			case "normal":
				calories = totalTime * normalIntensityMultiplier;
				points = totalTime * 1;
				break;
			case "high":
				calories = totalTime * moderateIntensityMultiplier;
				points = totalTime * 1.5;
				break;
			case "very high":
				calories = totalTime * highIntensityMultiplier;
				points = totalTime * 2;
				break;
			default:
				break;
		}
		let currentDate = moment().format("MM/DD/YYYY");
		let selectDateAndTime;
		if (selectedDate === currentDate) {
			selectDateAndTime = moment(selectedDate).add(moment().format("H"), "hours").add(moment().format("m"), "minutes").format();
		} else {
			selectDateAndTime = moment(selectedDate).set("hour", 12).set("minute", 0).format();
		}
		let imageURL = null;
		if (takenPhoto && takenPhoto.url) {
			imageURL = takenPhoto.url;
		} else if (showGIFs === true) {
			let gifArray = await getMultipleRandomGifsFromSearch(activityText2, 50, "pg-13").catch((error) => {
				// console.log("Error getting GIF: " + error);
				imageURL =
					"https://www.temptationgifts.com/media/catalog/product/cache/c09c62f4d7c7812f632d8af59eec3417/p/i/pigment-happy-jackson-yay-you-totally-did-it-greeting-card-gf831b_1.jpg";
			});
			if (imageURL === null) {
				// the giphy call was not empty
				imageURL = getRandomGifFromArray(gifArray);
				setRandomGifArray(gifArray);
			}
		}
		let ad = {
			activity: activityText2,
			duration: totalTime,
			unit: "minutes",
			calories: calories,
			date: selectDateAndTime,
			dataType: "workout",
			imageURL: imageURL,
			shuffleCounter: 0,
		};
		// console.log({ ad });

		setActivityData(ad);

		let congratsMessage = { title: "Awesome job", body: "That " + activityText2 + " earned you " + points + " points!" };
		/*
		await getActivitySystemMessage(intensity, activityText2, points)
			.catch((error) => {
				console.log("Error: " + error);
			})
			.then((result) => {
				congratsMessage = result;
			});
		*/
		// updateGoalState();
		setGiffing(false);

		setDone(true);
		resetActivity();
		setGifMessage({
			visible: true,
			messageTitle: congratsMessage.title,
			messageBody: congratsMessage.body,
			imageURL: imageURL,
		});
	};

	const addStepsConfirm = async () => {
		setGiffing(true);
		let calories, points;
		let currentDate = moment().format("MM/DD/YYYY");
		let selectDateAndTime;
		if (selectedDate === currentDate) {
			selectDateAndTime = moment(selectedDate).add(moment().format("H"), "hours").add(moment().format("m"), "minutes").format();
		} else {
			selectDateAndTime = moment(selectedDate).set("hour", 12).set("minute", 0).format();
		}
		let stepsPerMinute = (2000 * 3) / 60; // steps per mile x 3mph / 60 mins
		let stepsTime = Math.ceil(steps / stepsPerMinute);
		points = stepsTime;
		calories = stepsTime * normalIntensityMultiplier;
		let imageURL = null;
		if (takenPhoto && takenPhoto.url) {
			imageURL = takenPhoto.url;
		} else if (showGIFs === true) {
			let gifArray = await getMultipleRandomGifsFromSearch("walking", 50, "pg-13").catch((error) => {
				// console.log("Error getting GIF: " + error);
				imageURL =
					"https://www.temptationgifts.com/media/catalog/product/cache/c09c62f4d7c7812f632d8af59eec3417/p/i/pigment-happy-jackson-yay-you-totally-did-it-greeting-card-gf831b_1.jpg";
			});
			if (imageURL === null) {
				// the giphy call was not empty
				imageURL = getRandomGifFromArray(gifArray);
				setRandomGifArray(gifArray);
			}
		}

		setActivityData({
			activity: "steps",
			duration: steps,
			unit: "steps",
			calories: calories,
			date: selectDateAndTime,
			dataType: "steps",
			imageURL: imageURL,
			shuffleCounter: 0,
		});
		// console.log({ activityData });
		let congratsMessage;
		congratsMessage = await getActivitySystemMessage(intensity, "those steps", points).catch((error) => {
			// console.log("Error: " + error);
			congratsMessage = { title: "Awesome job", body: "Those " + steps.toLocaleString() + " steps earned you " + points + " points!" };
		});
		// updateGoalState();
		setDone(true);
		resetActivity();
		setGiffing(false);
		setGifMessage({
			visible: true,
			messageTitle: congratsMessage.title,
			messageBody: congratsMessage.body,
			imageURL: imageURL,
		});
	};

	const resetActivity = () => {
		setActivityText("");
		setHours(0);
		setMinutes(30);
		setActivityTime("");
		setIntensity("normal");
		setActivityIntensity("");
		setActivityDate("");
		setStepsText("");
		setStepsDate("");
		setDone(false);
	};

	const closeModal = async () => {
		if (activityData.activity !== null) {
			let user = JSON.parse(await getUser(null, false));
			setAdding(true);
			let response = await addActivity(activityData).catch((error) => {
				alert("Error: ", { error });
				return;
			});
			await updateGoalState();
			resetActivity();
			let lastFeedback = await storageService.getItem("lastFeedback");
			if ((lastFeedback === "" || moment().diff(moment(lastFeedback), "days") > 21) && moment().diff(moment(user.createdAt), "days") > 4) {
				let userName = await getUserNameFromUserID();
				sendSlackNotification("Feedback popover shown to " + userName, "feedback");
				setFeedbackPopover(true);
				await storageService.setItem("lastFeedback", moment().format("YYYY-MM-DD"));
			}
			setAdding(false);
		}
		showModal(false);
	};

	const shuffleGIF = async () => {
		let shuffleCounter = activityData.shuffleCounter + 1;
		// console.log(`${shuffleCounter}`);

		let randomGif = getRandomGifFromArray(randomGifArray);
		setActivityData({
			activity: activityData.activity,
			duration: activityData.duration,
			unit: activityData.unit,
			calories: activityData.calories,
			date: activityData.date,
			dataType: activityData.dataType,
			imageURL: randomGif,
			shuffleCounter: shuffleCounter,
		});
		setGifMessage({
			visible: true,
			messageTitle: gifMessage.messageTitle,
			messageBody: gifMessage.messageBody,
			imageURL: randomGif,
		});
	};

	const updateGIFPreference = async () => {
		let value;
		if (showGIFs === true) {
			value = false;
		} else {
			value = true;
		}
		setShowGIFs(value);
		let userName = await getUserNameOfLoggedInUser();
		sendSlackNotification(`*New GIF Preference set*\n*UserName:* ${userName}\n*Setting:* ${value}`, "keyEvent");
		await storageService.setItem("addGIFs", value);
	};

	return (
		<React.Fragment>
			<IonContent>
				<IonHeader>
					<IonToolbar>
						<IonTitle>Add Activity</IonTitle>
						<IonButton color="secondary" slot="end" onClick={closeModal}>
							<IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
						</IonButton>
					</IonToolbar>
				</IonHeader>
				<IonToast isOpen={errorToast.visible} onDidDismiss={() => setErrorToast({ visible: false })} message={errorToast.message} duration={2000} />
				<IonPopover
					//event={showGoalConfirmation.event}
					isOpen={gifMessage.visible}
					cssClass="gif-response"
					onDidDismiss={(e) => {
						setGifMessage({
							visible: false,
							message: null,
							imageURL: null,
						});
						closeModal();
					}}
				>
					<div style={{ fontSize: "1.25em", padding: "10%", textAlign: "center" }}>
						<b>{gifMessage.messageTitle}</b>
						<br />
						{gifMessage.messageBody}
						<br />
						<br />
						{showGIFs === true && !takenPhoto ? ( // only show this if GIFs on AND no photo
							<React.Fragment>
								<img src={gifMessage.imageURL} alt="" />
								<img src="assets/images/giphy.png" style={{ width: "100px" }} alt="" />
								<br />
								<br />
								<IonButton style={{ marginBottom: "5%" }} expand="block" color="tertiary" onClick={() => shuffleGIF()}>
									Shuffle GIF
								</IonButton>
								<IonButton
									expand="block"
									color="success"
									onClick={() => {
										setGifMessage({
											visible: false,
											message: null,
											imageURL: null,
										});
									}}
								>
									Confirm Activity
								</IonButton>
							</React.Fragment>
						) : (
							""
						)}
						{takenPhoto && takenPhoto.url ? ( // only show this if theres a photo
							<React.Fragment>
								<img src={takenPhoto.preview} className="add-activity-photo" alt="" />
								<IonButton
									expand="block"
									color="success"
									onClick={() => {
										setGifMessage({
											visible: false,
											message: null,
											imageURL: null,
										});
									}}
								>
									Sweet!
								</IonButton>
							</React.Fragment>
						) : (
							""
						)}
						{!takenPhoto && showGIFs === false ? ( // if there are no gifs AND no photo, just show the button
							<React.Fragment>
								{challenges && challenges.length === 0 && availableChallenges && availableChallenges.length > 0 ? (
									<React.Fragment>
										<div>Consider trying out a challenge to earn rewards and do good</div>
										<IonCard
											onClick={() => {
												setGifMessage({
													visible: false,
													message: null,
													imageURL: null,
												});
												logGenericEvent("View Challenge " + availableChallenges[randomChallengeID].id);
												history.push("/challenge/" + availableChallenges[randomChallengeID].id);
											}}
										>
											<ChallengeHeaderComponent challengeDetails={availableChallenges[randomChallengeID]} />
										</IonCard>
										<IonButton
											expand="block"
											color="success"
											onClick={() => {
												setGifMessage({
													visible: false,
													message: null,
													imageURL: null,
												});
											}}
										>
											Sweet!
										</IonButton>
									</React.Fragment>
								) : (
									<IonButton
										expand="block"
										color="success"
										onClick={() => {
											setGifMessage({
												visible: false,
												message: null,
												imageURL: null,
											});
										}}
									>
										Sweet!
									</IonButton>
								)}
							</React.Fragment>
						) : (
							""
						)}
					</div>
				</IonPopover>
				<IonLoading isOpen={adding} onDidDismiss={() => setAdding(false)} message={"Confirming your activity..."} duration={10000} />
				<IonLoading isOpen={gifing} onDidDismiss={() => setGiffing(false)} message={"Calculating points..."} duration={10000} />

				<IonGrid>
					<IonRow>
						<IonCol size="3"></IonCol>
						<IonCol size="6">
							<IonSegment value={activityType} onIonChange={(e) => updateActivityType(e.detail.value)} className="activity-segment-controller" mode="ios">
								<IonSegmentButton value="activity" className="activity-segment-button" mode="ios">
									<IonLabel style={{ marginTop: "0px", marginBottom: "0px" }}>Activity</IonLabel>
								</IonSegmentButton>
								<IonSegmentButton value="steps" className="activity-segment-button" mode="ios">
									<IonLabel style={{ marginTop: "0px", marginBottom: "0px" }}>Steps</IonLabel>
								</IonSegmentButton>
							</IonSegment>
						</IonCol>
						<IonCol size="3"></IonCol>
					</IonRow>
					{activityType === "activity" ? (
						<React.Fragment>
							<IonItem className="activity-item" mode="ios">
								<IonLabel className="field-label" position="stacked">
									What was your activity?
								</IonLabel>
								<IonInput
									classname="activity-field"
									type="text"
									value={activityText2}
									onIonChange={(e) => {
										setActivityText2(e.detail.value);
										updateActivityText();
									}}
									onFocus={(e) => {
										setShowFrequent(true);
										setActivityText2("");
									}}
									/*
								onBlur={() => setShowFrequent(false)}
								*/
									autocomplete="true"
									clearOnEdit="true"
									list="activities"
									required
								></IonInput>
								{showFrequent ? (
									<React.Fragment>
										{frequentActivities &&
											frequentActivities.length > 0 &&
											frequentActivities.map((activityRecord, i) => {
												//console.log({ activityRecord });
												return (
													<div
														className="frequent-activities"
														value={activityRecord.activities_activityType}
														onClick={() => {
															setActivityText2(activityRecord.activities_activityType);
															setShowFrequent(false);
														}}
													>
														{activityRecord.activities_activityType}
													</div>
												);
											})}
									</React.Fragment>
								) : (
									""
								)}
							</IonItem>
							<IonItem className="activity-item white-border" mode="ios">
								<IonLabel className="field-label" position="stacked" style={{ maxHeight: "44px" }}>
									How long was your activity?
								</IonLabel>
							</IonItem>
							<IonRow style={{ alignItems: "center" }}>
								<IonCol size="4">
									<IonInput
										className="input-box"
										max="240"
										inputMode="numeric"
										clearOnEdit
										onIonChange={(e) => {
											setMinutes(e.detail.value);
											updateActivityText();
										}}
									/>
								</IonCol>
								<IonCol style={{ paddingLeft: "10px" }} size="6">
									Minutes
								</IonCol>
							</IonRow>
							<IonRow style={{ alignItems: "center" }}>
								<IonCol size="4">
									<IonInput className="input-box" max="12" inputMode="numeric" clearOnEdit onIonChange={(e) => setHours(e.detail.value)} />
								</IonCol>
								<IonCol style={{ paddingLeft: "10px" }} size="6">
									Hours
								</IonCol>
							</IonRow>
							<IonRow className="activity-item" style={{ "--border-color": "white" }}>
								<IonCol size="6">
									<IonLabel className="field-label" position="stacked">
										Intensity
									</IonLabel>
								</IonCol>
								<IonCol size="6">
									<IonSegment value={intensity} onIonChange={(e) => updateIntensity(e.detail.value)} className="intensity-segment-controller" mode="ios">
										<IonSegmentButton value="normal" className="intensity-segment-button" mode="ios">
											<IonLabel style={{ marginTop: "0px", marginBottom: "0px" }}>Normal</IonLabel>
											<IonLabel className="intensity-emoji">
												<Emoji text=":slightly_smiling_face:" />
											</IonLabel>
										</IonSegmentButton>
										<IonSegmentButton value="high" className="intensity-segment-button" mode="ios">
											<IonLabel style={{ marginTop: "0px", marginBottom: "0px" }}>High</IonLabel>
											<IonLabel className="intensity-emoji">
												<Emoji text=":sweat_smile:" />
											</IonLabel>
										</IonSegmentButton>
										<IonSegmentButton value="very high" className="intensity-segment-button" mode="ios">
											<IonLabel style={{ marginTop: "0px", marginBottom: "0px" }}>Very High</IonLabel>
											<IonLabel className="intensity-emoji">
												<Emoji text=":sign_of_the_horns:" />
											</IonLabel>
										</IonSegmentButton>
									</IonSegment>
								</IonCol>
							</IonRow>
							<IonRow className="activity-item" style={{ "--border-color": "white" }}>
								<IonCol size="6">
									<IonLabel className="field-label" position="stacked">
										Date of activity
									</IonLabel>
								</IonCol>
								<IonCol size="6" className="activity-date-controller">
									<IonDatetime
										className="activity-date-datetime"
										color="secondary"
										displayFormat="MM.DD.YYYY"
										value={selectedDate}
										mode="ios"
										onIonChange={(e) => setSelectedDate(e.detail.value)}
									></IonDatetime>
									<span className="activity-date-down-chevron">{"\u2304"}</span>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonButton
									onClick={() =>
										takePhotoHandler().catch((error) => {
											setLoadingImage(false);
										})
									}
									className="add-activity-add-photo-button"
								>
									Add photo
								</IonButton>
							</IonRow>
							{takenPhoto && (takenPhoto.path || takenPhoto.preview) ? (
								<div>
									<img className="add-activity-photo" src={takenPhoto.preview} alt="" />
								</div>
							) : (
								""
							)}
							{loadingImage === true ? <IonSpinner /> : ""}
							<IonRow>
								{activityText ? (
									<React.Fragment>
										<div>
											<p dangerouslySetInnerHTML={{ __html: activityDate }}></p>
											<p dangerouslySetInnerHTML={{ __html: activityText }}></p>
											<p dangerouslySetInnerHTML={{ __html: activityTime }}></p>
											<p dangerouslySetInnerHTML={{ __html: activityIntensity }}></p>
											<IonGrid>
												<IonRow style={{ alignItems: "center" }}>
													<IonButton
														color="success"
														onClick={() => {
															addActivityConfirm();
														}}
													>
														Yep!
													</IonButton>
													<IonButton
														color="danger"
														onClick={() => {
															resetActivity();
														}}
													>
														Reset
													</IonButton>
													{takenPhoto ? (
														""
													) : (
														<React.Fragment>
															<IonCheckbox
																color="success"
																style={{ "--size": "1.5em", marginLeft: "30px" }}
																checked={showGIFs}
																onClick={(e) => updateGIFPreference()}
															/>
															<IonLabel style={{ marginLeft: "10px" }}>Add a fun GIF</IonLabel>
														</React.Fragment>
													)}
												</IonRow>
											</IonGrid>
										</div>
									</React.Fragment>
								) : (
									""
								)}
							</IonRow>
						</React.Fragment>
					) : (
						""
					)}
					{activityType === "steps" ? (
						<React.Fragment>
							<IonItem className="activity-item white-border" mode="ios">
								<IonLabel className="field-label" position="stacked">
									How many steps did you take?
								</IonLabel>
							</IonItem>
							<IonRow style={{ alignItems: "center" }}>
								<IonCol size="4">
									<IonInput className="input-box" max="240" inputMode="numeric" clearOnEdit onIonChange={(e) => updateStepsText(e.detail.value, null)} />
								</IonCol>
								<IonCol style={{ paddingLeft: "10px" }} size="6">
									Steps
								</IonCol>
							</IonRow>
							<IonRow className="activity-item" style={{ "--border-color": "white" }}>
								<IonCol size="8">
									<IonLabel className="field-label" position="stacked">
										Date of steps
									</IonLabel>
								</IonCol>
								<IonCol size="4" className="activity-date-controller">
									<IonDatetime
										className="activity-date-datetime"
										color="secondary"
										displayFormat="MM.DD.YYYY"
										value={selectedDate}
										mode="ios"
										onIonChange={(e) => updateStepsText(null, e.detail.value)}
									></IonDatetime>
									<span className="activity-date-down-chevron">{"\u2304"}</span>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonButton onClick={takePhotoHandler} className="add-activity-add-photo-button">
									Add photo
								</IonButton>
							</IonRow>
							{takenPhoto && (takenPhoto.path || takenPhoto.preview) ? (
								<div>
									<img className="add-activity-photo" src={takenPhoto.preview} alt="" />
								</div>
							) : (
								""
							)}
							{loadingImage === true ? <IonSpinner /> : ""}
							<IonRow>
								{stepsText ? (
									<div>
										<p dangerouslySetInnerHTML={{ __html: stepsText }}></p>
										<IonGrid>
											<IonRow style={{ alignItems: "center" }}>
												<IonButton
													color="success"
													onClick={() => {
														addStepsConfirm();
													}}
												>
													Yep!
												</IonButton>
												<IonButton
													color="danger"
													onClick={() => {
														resetActivity();
													}}
												>
													Reset
												</IonButton>
												{takenPhoto ? (
													""
												) : (
													<React.Fragment>
														<IonCheckbox
															color="success"
															style={{ "--size": "1.5em", marginLeft: "30px" }}
															checked={showGIFs}
															onClick={(e) => updateGIFPreference()}
														/>
														<IonLabel style={{ marginLeft: "10px" }}>Add a fun GIF</IonLabel>
													</React.Fragment>
												)}
											</IonRow>
										</IonGrid>
									</div>
								) : (
									""
								)}
							</IonRow>
						</React.Fragment>
					) : (
						""
					)}
				</IonGrid>
			</IonContent>
		</React.Fragment>
	);
};

export default AddActivityComponentCore;
