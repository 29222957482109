/**
 * The page that greets a user when there is no user object, i.e they're not logged in
 */
import { Device } from "@capacitor/core";
import { IonButton, IonCol, IonContent, IonGrid, IonPage, IonRow, useIonViewDidEnter } from "@ionic/react";
import { logOut } from "ionicons/icons";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import AuthContext from "../contexts/GlobalState";
import "../css/EntryScreen.css";
import { getMessagesAndActivitiesAllChatRooms } from "../services/ChatService";
//import '../css/Home.css';
import { StorageService } from "../services/StorageService";
import { getUser } from "../services/UserService";

const EntryScreen = () => {
	const storageService = new StorageService();
	const { login, logout, onboardingCompleted } = useContext(AuthContext);
	const history = useHistory();

	const loginUser = () => {
		history.push("/login");
	};

	useIonViewDidEnter(() => {
		console.log("Triggering login from page load");
		doLogin();
	});

	const doLogin = async () => {
		let user = null;
		try {
			user = JSON.parse(await getUser(null, false));
		} catch (error) {
			console.log("Error: " + error);
		}

		//console.log("Entry Screen User: " + JSON.stringify(user));
		if (user) {
			await login(user);
			if (onboardingCompleted === false || onboardingCompleted === undefined) {
				let lastOnboardingStep = storageService.getItem("lastOnboardingStep");
				if (lastOnboardingStep) {
					history.push("/onboarding");
				}
			} else {
				console.log("User in app doLogin: ", { user });
				console.log("Pulling most recent data for all chat rooms");
				getMessagesAndActivitiesAllChatRooms();
				console.log("Pulling most recent data for all chat rooms - DONE");
				history.push("/home");
			}
		} else {
			console.log("No user object found entry screen");
			//await logout();
		}
	};

	const onboardingNavigation = () => {
		console.log({ onboardingCompleted });

		if (onboardingCompleted === undefined) {
			// this state exists if there is no user object
			history.push("/onboarding/intro");
		} else {
			history.push("/onboarding"); // this should leave the navigation up to the container
		}
	};

	return (
		<IonPage>
			<IonContent>
				<div style={{ height: "100%", backgroundColor: "#1571b3" }}>
					{/* <video className="video-background" preload="auto" autoPlay playsInline muted loop>
						<source src="assets/videos/intro-video.mp4" />
					</video> */}
					<div style={{ height: "100%", width: "100%", position: "absolute" }}>
						<img src="assets/images/fitsquad-background.jpg" alt="" style={{ height: "100%", width: "100%", objectFit: "cover" }} />
					</div>
					<IonRow style={{ position: "relative", top: "10%" }}>
						<IonCol style={{ textAlign: "center", paddingBottom: "50px" }}>
							<img src="assets/images/white-transparent-tagline.png" style={{ width: "80%" }} alt="" />
						</IonCol>
					</IonRow>
					<IonGrid className="footer">
						<IonRow>
							<IonCol style={{ textAlign: "center" }}>
								<IonButton expand="block" color="tertiary" onClick={onboardingNavigation}>
									Get Started
								</IonButton>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol style={{ textAlign: "center" }}>
								<IonButton expand="block" color="secondary" onClick={loginUser}>
									Sign In
								</IonButton>
							</IonCol>
						</IonRow>
					</IonGrid>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default EntryScreen;
