import { Plugins } from "@capacitor/core";
import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonLoading, IonPage, IonProgressBar, IonToolbar, useIonViewDidEnter } from "@ionic/react";
import moment from "moment";
import React, { useState, useEffect } from "react";
import Emoji from "react-emoji-render";

import "../../css/Home.css";
import "../../css/Onboarding.css";
import { logGenericEvent, logPageView } from "../../services/AnalyticsService";
import { loadAppleExerciseTime, loadSumCalorieData, loadSumStepsData, pullStepsByDay } from "../../services/HealthKitService";
import { sendSlackNotification } from "../../services/NotificationService";
import { getUser } from "../../services/UserService";
import { StorageService } from "../../services/StorageService";
const storageService = new StorageService();
const { Device } = Plugins;

const HealthKitConfirmation = ({ progress, setProgress, healthKitData, setHealthKitData }) => {
	const [user, setUser] = useState();
	const [loading, setLoading] = useState(true);
	const [loadingMessage] = useState("Pulling data from healthKit ⏱🔥🦶")
	useIonViewDidEnter(async () => {
		logPageView("healthkit confirmation");
		setProgress(0.8);
		storageService.setItem("lastOnboardingStep", 11);
		Device.getInfo().then((deviceInfo) => {
			createGoalFromHealthKit(deviceInfo.platform);
		});
	});

	const createGoalFromHealthKit = async (platform) => {
		let userstored = JSON.parse(await getUser());
		setUser(userstored);
		let totalExerciseTime;
		let thirtyDaysAgo;
		let totalCalories;
		let totalSteps;
		if (platform !== "web") {
			// done just for testing purposes
			totalExerciseTime = await loadAppleExerciseTime();
			thirtyDaysAgo = moment().subtract("30", "days");
			totalCalories = await loadSumCalorieData(thirtyDaysAgo);
			totalSteps = await pullStepsByDay(thirtyDaysAgo, moment());
		} else {
			totalExerciseTime = 0;
			thirtyDaysAgo = moment().subtract("30", "days");
			totalCalories = 0;
			totalSteps = 10000;
		}
		let anyData = true,
			avgWeeklyTime = null,
			avgWeeklyTimeFromWorkouts = null,
			avgWeeklyTimeFromSteps = null,
			avgWeeklyCalories = null,
			avgCaloriesPerMin = null,
			avgWeeklyPoints = null,
			avgWeeklySteps = null;
		avgWeeklyTimeFromWorkouts = Math.round((totalExerciseTime / 30) * 7);
		avgWeeklyCalories = Math.round((totalCalories / 30) * 7);
		avgWeeklySteps = Math.round((totalSteps / 30) * 7);
		avgWeeklyTimeFromSteps = Math.round((avgWeeklySteps / 2000 / 3) * 60); // steps/2000 = # of miles / 3mph = hours stepping * 60 mins
		if (avgWeeklyTimeFromWorkouts > avgWeeklyTimeFromSteps) {
			// just in case
			avgWeeklyTime = avgWeeklyTimeFromWorkouts;
		} else {
			avgWeeklyTime = avgWeeklyTimeFromSteps;
		}
		console.log("avgWeeklyTimeFromWorkouts: " + avgWeeklyTimeFromWorkouts);
		console.log("avgWeeklyTimeFromSteps: " + avgWeeklyTimeFromSteps);
		console.log("avgWeeklyTime: " + avgWeeklyTime);
		avgCaloriesPerMin = Math.round(avgWeeklyCalories / avgWeeklyTime);
		if (isNaN(avgCaloriesPerMin)) {
			avgCaloriesPerMin = 0;
		}
		if (avgCaloriesPerMin > 11) {
			avgWeeklyPoints = Math.round((avgWeeklyTime * 2) / 10) * 10;
		} else if (avgCaloriesPerMin > 7) {
			avgWeeklyPoints = Math.round((avgWeeklyTime * 1.5) / 10) * 10;
		} else {
			avgWeeklyPoints = Math.round(avgWeeklyTime / 10) * 10;
		}
		if (avgWeeklyTime === 0 && avgWeeklyCalories === 0 && avgWeeklySteps === 0 && avgCaloriesPerMin === 0) {
			anyData = false;
		}
		let hkData = {
			avgWeeklyTime: avgWeeklyTime,
			avgWeeklyCalories: avgWeeklyCalories,
			avgWeeklySteps: avgWeeklySteps,
			avgWeeklyPoints: avgWeeklyPoints,
			anyData: anyData,
		};
		setHealthKitData(hkData);
		sendSlackNotification(
			`*NEW HEALTHKIT ONBOARDING DATA*\n*Username:* ${userstored.userName}\n*UserID:* ${userstored.cognitoId}\n*HK Data:* ${JSON.stringify(hkData)}`,
			"creation"
		);
		logGenericEvent("goal created with healthkit");
		setLoading(false);
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar style={{ "--border-style": "none" }}>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
				</IonToolbar>
				<IonProgressBar value={progress} />
			</IonHeader>
			<IonLoading isOpen={loading} onDidDismiss={() => setLoading(false)} message={loadingMessage} backdropDismiss />
			{user ? (
				<React.Fragment>
					<IonContent>
						<div className="onboarding-main-div">
							<div className="onboarding-title">Your HealthKit is connected</div>
							<div className="onboarding-hero-image-div">
								<img src="assets/images/hk-connected.jpg" alt="" style={{ width: "40%" }} />
							</div>
							<div className="onboarding-text-area" style={{ textAlign: "left" }}>
								Whenever your iPhone, Apple Watch or other apps connected to HealthKit log steps, calories burned or workouts, you'll earn points!
								{healthKitData.anyData !== false ? (
									<div>
										<br />
										<span>According to HealthKit you average:</span>
										<br />
										<br />
										{healthKitData.avgWeeklySteps > 0 ? (
											<div className="onboarding-hk-datapoint">
												<Emoji text=":walking:" /> {healthKitData.avgWeeklySteps.toLocaleString()} steps/week
											</div>
										) : (
											""
										)}
										{healthKitData.avgWeeklyTime > 0 ? (
											<div className="onboarding-hk-datapoint">
												<Emoji text=":timer_clock:" /> {healthKitData.avgWeeklyTime.toLocaleString()} minutes of exercise/week
											</div>
										) : (
											""
										)}
										{healthKitData.avgWeeklyCalories > 0 ? (
											<div className="onboarding-hk-datapoint">
												<Emoji text=":fire:" /> {healthKitData.avgWeeklyCalories.toLocaleString()} calories burned/week
											</div>
										) : (
											""
										)}
										<br />
										That's pretty good!
										<br />
										<br />
										{healthKitData.avgWeeklyPoints > 0 ? (
											<React.Fragment>
												<div>Based on this your weely projected points are: </div>
												<div style={{ fontSize: "20px", paddingTop: "10px", fontWeight: "bold" }}>
													{healthKitData.avgWeeklyPoints.toLocaleString()} points/week
												</div>
											</React.Fragment>
										) : (
											""
										)}
									</div>
								) : (
									""
								)}
								<br />
								<br />
								<div>Ready to set your first weekly goal?</div>
							</div>
						</div>
						<div className="onboarding-footer">
							<IonButton routerLink="/onboarding/setgoal" className="onboarding-advance-button">
								Let's do it!
							</IonButton>
						</div>
					</IonContent>
				</React.Fragment>
			) : (
				""
			)}
		</IonPage>
	);
};

export default HealthKitConfirmation;
