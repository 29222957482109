import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonCard,
	IonContent,
	IonFooter,
	IonHeader,
	IonPage,
	IonProgressBar,
	IonToolbar,
	useIonViewWillEnter,
} from "@ionic/react";
import React, { useState } from "react";
import "../../css/Home.css";
import "../../css/Onboarding.css";
import { logPageView } from "../../services/AnalyticsService";
import { StorageService } from "../../services/StorageService";
import { getUser } from "../../services/UserService";
import UserProgressComponentSample from "./UserProgressComponentSample";
const storageService = new StorageService();

const GoalsExplanationSolo = ({ progress, setProgress, squadToJoin }) => {
	const [userObject, setUserObject] = useState();
	let sampleData = {};

	useIonViewWillEnter(async () => {
		logPageView("Goal Explanation");
		setUserObject(JSON.parse(await getUser()));
		setProgress(0.5);
		storageService.setItem("lastOnboardingStep", 6);
	});

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar style={{ "--border-style": "none" }}>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
				</IonToolbar>
				<IonProgressBar value={progress} />
			</IonHeader>
			<IonContent>
				<div className="onboarding-main-div">
					<div className="onboarding-title">Every week you will have a weekly point goal</div>
					<div className="onboarding-hero-image-div"></div>

					<div className="onboarding-text-area" style={{ textAlign: "center" }}>
						<p>Here's an example of what this looks like:</p>
						{userObject ? (
							<IonCard id="userInfo">
								<div className="squad-progress-component">
									<UserProgressComponentSample userObj={userObject} />
								</div>
							</IonCard>
						) : (
							""
						)}

						<p>In this example your goal for the week is 900 points, you have 600 points and you're projected to hit your goal based on your history!</p>
					</div>
				</div>
			</IonContent>
			<IonFooter className="onboarding-footer">
				<IonButton routerLink="/onboarding/randomsquadexplanation" className="onboarding-advance-button">
					Got it
				</IonButton>
			</IonFooter>
		</IonPage>
	);
};

export default GoalsExplanationSolo;
