import { Plugins } from "@capacitor/core";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { addEmoji, postChatRoomMessage } from "../services/ChatService";
import { getChatRoomIDFromSquadID, getSquadIDFromChatRoomID } from "../services/SquadService";
import { StorageService } from "../services/StorageService";
const storageService = new StorageService();
const { PushNotifications, LocalNotifications, Device } = Plugins;

const NotificationComponent = () => {
	const history = useHistory();

	useEffect(() => {
		setupListeners();
	}, []);

	const navTo = (destination) => {
		history.replace(destination);
	};

	const registerActionTypes = async () => {
		LocalNotifications.registerActionTypes({
			// I think these are randomly set by the server
			types: [
				{
					id: "workout-5",
					actions: [
						{
							id: ":+1:",
							title: "👍",
							destructive: true,
						},
						{
							id: ":muscle:",
							title: "💪",
							destructive: true,
						},
						{
							id: ":poop:",
							title: "💩",
							destructive: true,
						},
					],
				},
				{
					id: "workout-1",
					actions: [
						{
							id: ":grinning:",
							title: "😀",
							destructive: true,
						},
						{
							id: ":punch:",
							title: "👊",
							destructive: true,
						},
						{
							id: ":eggplant:",
							title: "🍆",
							destructive: true,
						},
					],
				},
				{
					id: "workout-2",
					actions: [
						{
							id: ":raised_hands:",
							title: "🙌",
							destructive: true,
						},
						{
							id: ":sign_of_the_horns:",
							title: "🤘",
							destructive: true,
						},
						{
							id: ":thinking_face:",
							title: "🤔",
							destructive: true,
						},
					],
				},
				{
					id: "workout-3",
					actions: [
						{
							id: ":ok_hand:",
							title: "👌",
							destructive: true,
						},
						{
							id: ":boom:",
							title: "💥",
							destructive: true,
						},
						{
							id: ":beer:",
							title: "🍺",
							destructive: true,
						},
					],
				},
				{
					id: "workout-4",
					actions: [
						{
							id: ":clap:",
							title: "👏",
							destructive: true,
						},
						{
							id: ":100:",
							title: "💯",
							destructive: true,
						},
						{
							id: ":face_vomiting:",
							title: "🤮",
							destructive: true,
						},
					],
				},
			],
		});
	};

	const setupListeners = async () => {
		console.log("History in setupListner: " + JSON.stringify(history));
		let deviceInfo = await Device.getInfo();
		if (deviceInfo.platform === "android" || deviceInfo.platform === "ios") {
			LocalNotifications.removeAllListeners();
			PushNotifications.removeAllListeners();
			await registerActionTypes();

			/**
			 * This is triggered when the app is open on Android and the user taps a notification
			 */
			LocalNotifications.addListener("localNotificationActionPerformed", async (action) => {
				let deviceInfo = await Device.getInfo();
				if (deviceInfo.platform === "android") {
					console.log("Action performed: " + JSON.stringify(action));
					if (action.actionId === "tap") {
						if (action.notification.extra) {
							console.log("Data payload: " + JSON.stringify(action.notification.extra));
							if (action.notification.extra.activityData) {
								// it has activityData property
								if (action.notification.extra.activityData.payload?.activityData?.dataType === "seasonRegistration") {
									window.open("http://fitsquadapp.com/products", "_new");
								}
							} else if (action.notification.extra.payload.chatRoom) {
								console.log("ChatRoom: " + action.notification.extra.payload.chatRoom);
								console.log("History: " + JSON.stringify(history));
								let squadID = await getSquadIDFromChatRoomID(action.notification.extra.payload.chatRoom);
								if (squadID !== null) {
									window.open("/squadhome/" + squadID);
								}
							}
						}
					} else if (action.actionId === "free_text") {
						// console.log("squadID: " + action.notification.extra.activityData.squadId);

						let chatRoomID = await getChatRoomIDFromSquadID(action.notification.extra.activityData.squadId);
						// console.log("ChatRoomID: " + chatRoomID);
						postChatRoomMessage(chatRoomID, action.inputValue);
					} else {
						let response = await addEmoji(action.notification.id, action.actionId, "activity", action.notification.extra.activityData.squadId);
						// console.log("Response from addEmoji: " + response);
					}
				}
			});

			/**
			 *
			 * Converts Pushes to Locals for Android
			 * pushNotificationReceived doesn't fire for iOS
			 *
			 */
			PushNotifications.addListener("pushNotificationReceived", async (notification) => {
				let deviceInfo = await Device.getInfo();
				console.log("DeviceInfo: ", JSON.stringify(deviceInfo));
				console.log("Notification: " + JSON.stringify(notification));
				if (deviceInfo.platform === "android") {
					let dataPayload;
					if (notification?.data?.payload) {
						dataPayload = await JSON.parse(notification.data.payload);
						console.log("Notification  - dataPayload: " + JSON.stringify(dataPayload));
						if (dataPayload.payload?.activityData) {
							// for a workout
							console.log("Datatype: " + dataPayload["payload"]["activityData"]["dataType"]);
							console.log("Activity ID: " + dataPayload["payload"]["sourceActivityId"]);
							let randResponse = Math.floor(Math.random() * Math.floor(4)) + 1;
							let actTypeID = dataPayload["payload"]["activityData"]["dataType"] + "-" + randResponse;
							console.log("randResponse: " + actTypeID);
							await LocalNotifications.schedule({
								notifications: [
									{
										title: dataPayload["title"],
										body: dataPayload["body"],
										id: dataPayload["payload"]["id"],
										schedule: {
											on: new Date(Date.now() + 5000),
										},
										channelId: "primary",
										extra: {
											activityData: dataPayload,
										},
										actionTypeId: actTypeID,
									},
								],
							});
						} else {
							// it's a chat message
							console.log("ID: " + dataPayload["payload"]["id"]);
							await LocalNotifications.schedule({
								notifications: [
									{
										title: dataPayload["title"],
										body: dataPayload["body"],
										id: Date.now(),
										schedule: {
											on: new Date(Date.now() + 5000),
										},
										channelId: "primary",
										extra: {
											payload: dataPayload["payload"],
										},
										actionTypeId: null,
									},
								],
							});
						}
					} else {
						// this shouldn't happen
						console.log("No data payload");
					}
				}
				console.log("\n\nPUSH - pushNotificationReceived *NEW!*: " + JSON.stringify(notification));
			});

			/**
			 *
			 * Android is converted to LocalNotifications when the app is open, but this works when it's closed
			 *
			 */
			PushNotifications.addListener("pushNotificationActionPerformed", async (action) => {
				let deviceInfo = await Device.getInfo();
				console.log("DeviceInfo: " + JSON.stringify(deviceInfo));
				if (deviceInfo.platform === "ios") {
					console.log("\n\nPUSH - pushNotificationActionPerformed iOS: " + JSON.stringify(action));

					if (action.notification.data.aps.alert.payload.chatRoom) {
						// if it has the chatRoom property, push to there
						console.log("Has chatroom, sending to link");
						let squadID = await getSquadIDFromChatRoomID(action.notification.data.aps.alert.payload.chatRoom);
						if (squadID !== null) {
							let destinaton = "/squadhome/" + squadID;
							console.log("Navigating to " + destinaton);
							navTo(destinaton);
						}
					} else if (action.notification.data.aps.alert.payload.activityData?.dataType === "workout") {
						if (action.actionId === "free_text") {
							console.log("squadID: " + action.notification.data.aps.alert.payload.squadId);
							let chatRoomID = await getChatRoomIDFromSquadID(action.notification.data.aps.alert.payload.squadId);
							postChatRoomMessage(chatRoomID, action.inputValue);
						} else if (action.actionId === "tap") {
							console.log("ActionId is tap, sending to link");

							let squadID = action.notification.data.aps.alert.payload.squadId;
							if (squadID !== null) {
								let destinaton = "/squadhome/" + squadID;
								console.log("Navigating to " + destinaton);
								navTo(destinaton);
							}
						} else {
							await addEmoji(action.notification.data.aps.alert.payload.id, action.actionId, "activity", action.notification.data.aps.alert.payload.squadId);
						}
					} else if (action.notification.data.aps.alert.payload.activityData?.dataType === "seasonRegistration") {
						//destination = "/season-sales-page"
						window.open("http://fitsquadapp.com/products", "_new");
					} else {
						console.log("Some default case, sending to link");
						if (action.notification.data.aps.alert.payload.squadId) {
							// this is for parsing whether it's a chatRoom or competitionChat
							let squadID = action.notification.data.aps.alert.payload.squadId;
							let destinaton = "/squadhome/" + squadID;
							console.log("Navigating to " + destinaton);
							navTo(destinaton);
						}
						/*
                        if (action.data.aps.alert.payload.squadId) {
                            let squadID = action.data.aps.alert.payload.squadId;
                            console.log("\nLooking for chat room for squadID " + squadID);
                            console.log("\nGetting chat room");
                            let chatRooms = await getChatRooms(false);
                            let chatRoomID = null;
                            for (let roomData of chatRooms) {
                                console.log("\n\nchecking roomData: " + JSON.stringify(roomData));
                                if (roomData.squadIdA === squadID && roomData.squadIdB !== null && chatRoomID === null) {
                                    console.log("\nFound the competition one in A: " + roomData.id);
                                    chatRoomID = roomData.id;
                                } else if (roomData.squadIdA === squadID && chatRoomID === null) {
                                    console.log("\nFound the solo one in A: " + roomData.id);
                                    chatRoomID = roomData.id;
                                } else if (roomData.squadIdB === squadID && roomData.squadIdA !== null && chatRoomID === null) {
                                    console.log("\nFound the competition one in B: " + roomData.id);
                                    chatRoomID = roomData.id;
                                } else if (roomData.squadIdB === squadID && chatRoomID === null) {
                                    console.log("\nFound the solo one in B: " + roomData.id);
                                    chatRoomID = roomData.id;
                                }
                            }
                        } else {
                            console.log("\n\n NO SQUAD ID");
                        }
                        */
						console.log("User is on iOS. Using that payload");
					}
				}
				if (deviceInfo.platform === "android") {
					console.log("\n\nPUSH - pushNotificationActionPerformed Android: " + JSON.stringify(action));
					if (action.notification.data.payload) {
						// if it has the payload property
						let payload = JSON.parse(action.notification.data.payload); // parse it since
						console.log("Data payload: " + JSON.stringify(payload.payload));
						console.log("ChatRoom: " + payload.payload.chatRoom);
						if (payload.payload.chatRoom) {
							let squadID = await getSquadIDFromChatRoomID(payload.payload.chatRoom);
							if (squadID !== null) {
								let destinaton = "/squadhome/" + squadID;
								console.log("Navigating to " + destinaton);
								navTo(destinaton);
							}
						}
					}
				}
			});
		}
	};
	return null;
};

export default NotificationComponent;
