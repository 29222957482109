import { IonButton, IonContent, IonHeader, IonInput, IonItem, IonItemGroup, IonLabel, IonPage, IonRow, IonTitle, IonToolbar, IonTextarea } from "@ionic/react";
import Amplify from "aws-amplify";
import AWS from "aws-sdk";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import aws_exports from "../aws-exports";
import ActivitySummary from "../components/HomePageCards/ActivitySummary";
import "../css/Register.css";
import { getSquadActivities, sendStepsToServer } from "../services/ActivitiesService";
import { getActivities } from "../services/ChatService";
import { loadStepsData, loadSumStepsData, pullStepsByDayLast8days } from "../services/HealthKitService";
import { getAllSquadIDs, isUserInSquad } from "../services/SquadService";
import { getUserNameFromUserID } from "../services/UserService";
import { postPHPJSONResponse } from "../services/APIService";
import { PHP_URL_NAME } from "../models/constants";

const Admin = () => {
	Amplify.configure(aws_exports);
	const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
		apiVersion: "2016-04-18",
		region: "us-west-2",
		accessKeyId: "AKIAZYATSPDT2UHNR77Y",
		secretAccessKey: "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",
	});
	const userPoolID = "us-west-2_3FbIY8Ryk";
	const emailAddressRef = useRef(null);
	const passwordRef = useRef(null);
	const pushTitle = useRef(null);
	const pushBody = useRef(null);

	const resetPassword = async () => {
		cognitoidentityserviceprovider.adminSetUserPassword(
			{
				Password: passwordRef.current.value,
				UserPoolId: userPoolID,
				Username: emailAddressRef.current.value,
				Permanent: true,
			},
			(err, data) => {
				console.log({ err });
				console.log(JSON.stringify(err, null, 2));

				console.log({ data });
				console.log(JSON.stringify(data, null, 2));
			}
		);
	};

	const countReactionsLastWeek = async () => {
		let reactionObject = {};
		let allSquadIDs = await getAllSquadIDs();
		//console.log({ allSquadIDs });
		for await (let squadId of allSquadIDs) {
			let startDate = moment().startOf("isoWeek").subtract(10, "weeks");
			let endDate = moment().endOf("isoWeek");
			let allActivities = JSON.parse(await getSquadActivities(squadId, 5000, 0, null, moment(startDate), moment(endDate), false));
			if (allActivities === null || allActivities.length === 0) {
				continue;
			}
			let allReactions = [];
			for await (let activity of allActivities) {
				if (activity.reactionCount > 0) {
					allReactions.push(activity);
				}
			}
			//console.log({ allReactions });
			for await (let reaction of allReactions) {
				for await (let reactionData of reaction.reactions) {
					if ((await isUserInSquad(squadId, reactionData.user)) === true) {
						let userName = await getUserNameFromUserID(reactionData.user);
						if (reactionObject[userName] === undefined) {
							reactionObject[userName] = 1;
						} else {
							reactionObject[userName]++;
						}
					}
				}
			}
		}
		console.log({ reactionObject });
	};

	const pullStepsSamplesLast30days = async () => {
		let daysChecked = 0;
		let daysToCheck = 30;
		let stepsArray = {};
		while (daysChecked < daysToCheck) {
			let dailyTotal = 0;
			let startDate = moment().startOf("day").subtract(daysChecked, "days");
			let endDate = moment().endOf("day").subtract(daysChecked, "days");
			console.log("Checking " + startDate.format());
			let tempStepsArray = [];
			tempStepsArray = await loadStepsData(startDate, endDate); // should just output to console
			console.log({ tempStepsArray });
			if (tempStepsArray && tempStepsArray.length > 0) {
				for await (let sample of tempStepsArray) {
					dailyTotal = dailyTotal + sample.quantity;
				}
			}
			stepsArray[startDate.format("YYYY-MM-DD")] = dailyTotal;
			daysChecked++;
		}
		console.log({ stepsArray });
	};

	const pullStepsByDay = async () => {
		let stepsArray = await pullStepsByDayLast8days();
		await sendStepsToServer(stepsArray);
	};

	const scheduleNotification = async () => {
		let notificationAPI = PHP_URL_NAME + "fitsquad/sendPushNotifications.php";
		let body = {
			title: pushTitle.current.value,
			body: pushBody.current.value,
		};
		//console.log({body});

		let response = await postPHPJSONResponse(notificationAPI, body);
		console.log("PHP Response: " + response);
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Admin</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonItemGroup>
					<IonItem>
						<IonLabel>Email Address</IonLabel>
						<IonInput ref={emailAddressRef} name="userID" type={"text"} placeholder={"email"} required></IonInput>
					</IonItem>
					<IonItem>
						<IonLabel>Password</IonLabel>
						<IonInput ref={passwordRef} name="password" type={"text"} placeholder={"pw"} required></IonInput>
					</IonItem>
					<IonItem>
						<IonButton onClick={() => resetPassword()}>Reset Password</IonButton>
					</IonItem>
				</IonItemGroup>
				<div style={{ marginLeft: "5%" }}>
					<h1>Push Notification Scheduler</h1>

					<h3>Title</h3>
					<IonInput
						style={{ border: "solid 1px", "--padding-start": "10px", width: "90%" }}
						ref={pushTitle}
						name="userID"
						type={"text"}
						placeholder={"title"}
						required
					></IonInput>
					<h3>Body</h3>
					<IonTextarea
						style={{ border: "solid 1px", "--padding-start": "10px", width: "90%" }}
						ref={pushBody}
						name="userID"
						type={"text"}
						placeholder={"body"}
						required
					></IonTextarea>

					<IonButton onClick={() => scheduleNotification()}>Schedule Notification</IonButton>
				</div>
				<IonItemGroup>
					<IonItem>
						<IonButton onClick={() => countReactionsLastWeek()}>Get Last Week Reactions</IonButton>
					</IonItem>
				</IonItemGroup>
				<IonItemGroup>
					<IonItem>
						<IonButton onClick={() => pullStepsSamplesLast30days()}>Pull 30 days steps</IonButton>
					</IonItem>
				</IonItemGroup>
				<IonItemGroup>
					<IonItem>
						<IonButton onClick={() => pullStepsByDay()}>Pull 8 days steps by day</IonButton>
					</IonItem>
				</IonItemGroup>
				<ActivitySummary />
			</IonContent>
		</IonPage>
	);
};

export default Admin;
