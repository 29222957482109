import {
	IonContent,
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonCardSubtitle,
	IonModal,
	IonPicker,
	IonPopover,
	IonLoading,
	IonRow,
	IonCol,
	IonRadioGroup,
	IonItem,
	IonRadio,
	IonLabel,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import "../css/Home.css";
import "../css/ActivityDetails.css";
import "../css/NewUserGoal.css";
import "../css/SquadScoreboard.css";
import { getUserNameFromUserID, getUser } from "../services/UserService";
import moment from "moment";
import { getGoal, updateGoal } from "../services/PointsAndGoalsService";
import { StorageService } from "../services/StorageService";
import { getRandomValueFromArray } from "../services/HelperServices";
import { goalSettingImages } from "../models/constants";
import AuthContext from "../contexts/GlobalState";

const NewUserGoalComponent = ({ swiper }) => {
	const { updateGoalState } = useContext(AuthContext);
	const casualPercent = 0.85;
	const normalPercent = 1;
	const seriousPercent = 1.15;
	const beastPercent = 1.25;
	const [goalSelected, setGoalSelected] = useState("projected");
	const [loading, setLoading] = useState(true);
	const [activityDetails, setActivityDetails] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [showShowSorryModal, setShowSorryModal] = useState(false);
	const [pickerOpen, setPickerOpen] = useState(false);
	const [goalOptions, setGoalOptions] = useState();
	const [goal, setGoal] = useState(0);
	const [showGoalInfo, setShowGoalInfo] = useState();
	const [dayOfTheWeek] = useState(moment().format("ddd"));
	//const [dayOfTheWeek] = useState("Mon");
	const [goalID, setGoalID] = useState();
	const [activityArray, setActivityArray] = useState([]);
	const [showGoalConfirmation, setShowGoalConfirmation] = useState({
		open: false,
		event: undefined,
		goal: undefined,
	});
	const [goalValues, setGoalValues] = useState({
		takeItEasy: null,
		projected: null,
		stepItUp: null,
		allOut: null,
		manual: null,
	});
	const storageService = new StorageService();
	useEffect(() => {
		fetchActivityDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function fetchActivityDetails() {
		//tempDOW = "Mon";
		let user = await getUser();
		let goal = await getGoal(null, true);
		console.log("New User Goal: ", { goal });
		let activity = {};
		activity.userName = await getUserNameFromUserID();
		//console.log("Activity: " + JSON.stringify(activity));
		activity.image = "";
		activity.goal = goal.goalPoints;
		activity.systemGoalPoints = goal.systemGoalPoints;
		activity.streakEnabled = true;
		activity.streak = user.goalStreak;
		if (!activity.streak) {
			activity.streak = 0;
		}
		let avgWeeklyGoalsObject = await getPastGoals();
		activity.avgWeeklyPoints = avgWeeklyGoalsObject.weeklyPoints;
		activity.weeks = avgWeeklyGoalsObject.weeks;
		let projected = goal.goalPoints;
		let takeItEasy = Math.round((projected * casualPercent) / 10) * 10;
		let stepItUp = Math.round((projected * seriousPercent) / 10) * 10;
		let allOut = Math.round((projected * beastPercent) / 10) * 10;
		setGoalValues({
			takeItEasy: takeItEasy,
			projected: projected,
			stepItUp: stepItUp,
			allOut: allOut,
			manual: null,
		});
		setGoalID(goal.id);
		setGoal(projected);
		activity.goalImage = getRandomValueFromArray(goalSettingImages);
		setActivityDetails([activity]);
		setLoading(false);
	}

	const getPastGoals = async () => {
		let userObject = await getUser();
		let pointHistory = await storageService.getObject("userPointHistory-" + userObject.cognitoId);
		if (pointHistory === null) {
			pointHistory = {};
		}
		if (!pointHistory.weeklyTotals) {
			pointHistory.weeklyTotals = {};
		}
		//TODO: Store results locally once pulled and check that storage before pulling
		//Because it's historic data, we don't need to hit the server everytime to get it
		setActivityArray([]);
		let weeksChecked = 1;
		let totalPriorWeeks = 4;
		let sumPoints = 0;
		while (weeksChecked <= totalPriorWeeks) {
			let goalObject;
			let startDate = moment().startOf("isoWeek").subtract(weeksChecked, "weeks");
			let endDate = moment().endOf("isoWeek").subtract(weeksChecked, "weeks").add(1, "day");
			// Do this because of tz BS
			startDate = moment(startDate).subtract(1, "day");
			endDate = moment(endDate).add(1, "day");
			//console.log("StartDate set: " + startDate.format());
			//console.log("EndDate set: " + endDate.format());
			if (!pointHistory.weeklyTotals[moment(startDate).format("MM/DD/YY")]) {
				//console.log(`Pulling new data for user on ${moment(startDate).format("MM/DD/YY")}`);
				goalObject = await getGoal(null, true, startDate, endDate);
				if (goalObject !== null) {
					pointHistory.weeklyTotals[moment(startDate).format("MM/DD/YY")] = goalObject;
				}
			} else {
				console.log(
					`Using current data:\n date: ${moment(startDate).format("MM/DD/YY")}  amount: ${pointHistory.dailyTotals[moment(startDate).format("MM/DD/YY")]} `
				);
				goalObject = pointHistory.weeklyTotals[moment(startDate).format("MM/DD/YY")];
			}
			//console.log({ goalObject });
			if (goalObject !== null) {
				sumPoints += goalObject.goalProgress;
				//console.log("sumPoints: " + sumPoints);
				if (goalObject.goalProgress >= goalObject.goalPoints) {
					goalObject.success = 1;
				} else {
					goalObject.success = 0;
				}
				setActivityArray((activityArray) => [...activityArray, goalObject]);
			}
			weeksChecked++;
		}
		let avgWeeklyPoints = sumPoints / (weeksChecked - 1);
		return { weeklyPoints: avgWeeklyPoints, weeks: weeksChecked };
	};

	const onCancel = () => {
		//console.log("Cancelled");
		setPickerOpen(false);
	};

	const onSave = (valueObject) => {
		console.log("Saved with value = " + JSON.stringify(valueObject.goalValue.value));
		setGoalValues({
			takeItEasy: goalValues.takeItEasy,
			projected: goalValues.projected,
			stepItUp: goalValues.stepItUp,
			allOut: goalValues.allOut,
			manual: valueObject.goalValue.value,
		});
		setGoal(valueObject.goalValue.value);
		setGoalSelected("manual");
		//acceptGoal(null, valueObject.goalValue.value);
		setPickerOpen(false);
	};

	const createGoalPicker = (startingGoal) => {
		if (dayOfTheWeek !== "Mon") {
			setShowSorryModal(true);
		} else {
			//let startingGoal_test = 1
			//TODO: Figure out how to set a default value for the picker
			let pickerStart = 0;
			let pickerIncrement = 10;
			let pickerEnd = Math.round(startingGoal * 3, -2);
			let goalPickerOptions = [];
			for (let i = pickerStart; i <= pickerEnd; i = i + pickerIncrement) {
				goalPickerOptions.push({
					text: "" + i + "",
					value: i,
				});
			}
			let goalOptionsTemp = {
				name: "goalValue",
				options: goalPickerOptions,
			};
			//TODO: Figure out how to set default value
			setGoalOptions(goalOptionsTemp);
			//console.log("Goal picker options: "+JSON.stringify(goalOptions));
			//console.log("SessionTimeColumn: "+JSON.stringify(SessionTimeColumn));
			setPickerOpen(true);
		}
	};

	const acceptGoal = async (event, goalPassed = null) => {
		// set the goal with the api
		if (goalPassed === null) {
			goalPassed = goal;
		}
		let response = await updateGoal(goalID, goalPassed);
		updateGoalState();
		storageService.setObject("userGoal-null", response);
		// show congrats modal
		setShowGoalConfirmation({
			open: true,
			event: event,
			goal: goal,
		});
		//updateLocalUserObject();
		// on close of modal go back
	};

	const changeGoal = async (value) => {
		console.log({ value });
		switch (value) {
			case "takeItEasy":
				setGoalSelected("takeItEasy");
				setGoal(goalValues.takeItEasy);
				break;
			case "projected":
				setGoalSelected("projected");
				setGoal(goalValues.projected);
				break;
			case "stepItUp":
				setGoalSelected("stepItUp");
				setGoal(goalValues.stepItUp);
				break;
			case "allOut":
				setGoalSelected("allOut");
				setGoal(goalValues.allOut);
				break;
			case "manual":
				setGoalSelected("manual");
				setGoal(goalValues.manual);
				break;
			default:
				setGoalSelected("projected");
				setGoal(goalValues.projected);
				break;
		}
	};

	const nextSlide = async () => {
		setShowGoalConfirmation({ open: false, event: undefined });
		const currentSwiper = await swiper.current.getSwiper();
		currentSwiper.slideNext();
	};

	return (
		<React.Fragment>
			<div>
				<IonPicker
					isOpen={pickerOpen}
					columns={[goalOptions]}
					buttons={[
						{
							text: "Cancel",
							role: "cancel",
							cssClass: "picker",
							handler: (value) => {
								onCancel();
							},
						},
						{
							text: "Confirm",
							cssClass: "picker",
							handler: (value) => {
								onSave(value);
							},
						},
					]}
				></IonPicker>
				<IonPopover
					//event={showGoalConfirmation.event}
					isOpen={showGoalConfirmation.open}
					cssClass="new-goal-popover"
					onDidDismiss={(e) => {
						setShowGoalConfirmation({ open: false, event: undefined });
					}}
				>
					<IonCard style={{ boxShadow: "none" }}>
						<IonCardHeader>
							<IonCardSubtitle>Your new goal</IonCardSubtitle>
							<IonCardTitle>{goal.toLocaleString()} points</IonCardTitle>
						</IonCardHeader>
						<IonCardContent className="new-user-card-content">
							Great! You're all set for a goal of {goal.toLocaleString()} points this week. <br />
							<br />
							<span className="new-goal-past-weeks-bold" style={{ fontSize: "20px", color: "black" }}>
								Go get em!
							</span>
						</IonCardContent>
						{
							//<IonButton expand="block" onClick={(e) => setShowGoalConfirmation({ open: false, event: undefined })}>
						}
						<IonButton expand="block" onClick={() => nextSlide()}>
							Let's Go!
						</IonButton>
					</IonCard>
				</IonPopover>
				<IonPopover
					//event={showGoalConfirmation.event}
					isOpen={showGoalInfo}
					cssClass="tool-tip"
					onDidDismiss={(e) => setShowGoalInfo(false)}
				>
					Something about goals
					<IonButton onClick={(e) => setShowGoalInfo(false)}>Got it</IonButton>
				</IonPopover>
				<IonPopover
					//event={showGoalConfirmation.event}
					isOpen={showShowSorryModal}
					cssClass="new-goal-popover"
					onDidDismiss={(e) => setShowSorryModal(false)}
				>
					<IonCard style={{ boxShadow: "none" }}>
						<IonCardContent className="new-user-card-content">Sorry, but to keep things fair, goals can only be changed on Mondays</IonCardContent>
						<IonButton expand="block" onClick={(e) => setShowSorryModal(false)}>
							Understood
						</IonButton>
					</IonCard>
				</IonPopover>
				<IonModal isOpen={showModal} cssClass="my-custom-class">
					<p>What would you like your goal set to?</p>
					<IonButton onClick={() => setShowModal(false)}>Close Modal</IonButton>
				</IonModal>
				<IonCard className="new-user-goal-card">
					{activityDetails.map((activity, i) => {
						if (dayOfTheWeek === "Mon") {
							return (
								<div key={`${i}`}>
									<div className="activity-card-image-div">
										<img src={activity.goalImage} alt="" />
									</div>

									<IonCardHeader>
										<IonCardSubtitle>Selected goal</IonCardSubtitle>
										<IonCardTitle>{goal.toLocaleString()} points</IonCardTitle>
									</IonCardHeader>
									<IonCardContent className="new-user-card-content">
										{activity.weeks > 1 ? (
											<div>
												Over the past {activity.weeks} weeks you have averaged {Math.round(activity.avgWeeklyPoints).toLocaleString()} points.
											</div>
										) : (
											<div>Last week you had {Math.round(activity.avgWeeklyPoints).toLocaleString()} points.</div>
										)}

										<br />
										{activity.streak > 0 ? (
											<div>You're currently on a {activity.streak} week streak of hitting your goals</div>
										) : (
											<div>Hitting this goal this week will get you on a streak!</div>
										)}
										<br />
										<div>Your most recent performance has been:</div>
										<br />
										{activityArray.map((goal, i) => {
											return (
												<IonRow key={`${i}`} className="new-goal-past-weeks-row">
													<IonCol size="3">
														Goal: <span className="new-goal-past-weeks-bold">{goal.goalPoints.toLocaleString()}</span>
													</IonCol>
													<IonCol size="6">
														Points: <span className="new-goal-past-weeks-bold">{goal.goalProgress.toLocaleString()}</span>
														{goal.success === 1 ? (
															<span className="dot" style={{ backgroundColor: "green", left: "4px" }}>
																{" "}
															</span>
														) : (
															<span className="dot" style={{ backgroundColor: "red", left: "4px" }}>
																{" "}
															</span>
														)}
													</IonCol>
													<IonCol>{moment(goal.startDate).format("MMM DD,YYYY")}</IonCol>
												</IonRow>
											);
										})}
										<br />
										<div className="new-goal-past-weeks-bold">Accept this goal?</div>
										<br />
										<IonRadioGroup value={goalSelected} onIonChange={(e) => changeGoal(e.detail.value)} mode="md">
											{console.log("Goal: " + goal)}
											{goalValues.takeItEasy !== null ? (
												<IonItem className="onboarding-radio-item">
													<IonRadio className="onboarding-radio" value="takeItEasy" />
													<IonLabel className="onboarding-radio-label">Take it Easy: </IonLabel>
													<div>{goalValues.takeItEasy.toLocaleString()} points</div>
												</IonItem>
											) : (
												""
											)}
											{goalValues.projected !== null ? (
												<React.Fragment>
													<IonItem className="onboarding-radio-item">
														<IonRadio className="onboarding-radio" value="projected" />
														<IonLabel className="onboarding-radio-label">Projected: </IonLabel>
														<div>{goalValues.projected.toLocaleString()} points</div>
													</IonItem>
												</React.Fragment>
											) : (
												""
											)}
											{goalValues.stepItUp !== null ? (
												<IonItem className="onboarding-radio-item">
													<IonRadio className="onboarding-radio" value="stepItUp" />
													<IonLabel className="onboarding-radio-label">Step it Up</IonLabel>
													<div>{goalValues.stepItUp.toLocaleString()} points</div>
												</IonItem>
											) : (
												""
											)}
											{goalValues.allOut !== null ? (
												<IonItem className="onboarding-radio-item">
													<IonRadio className="onboarding-radio" value="allOut" />
													<IonLabel className="onboarding-radio-label">All Out</IonLabel>
													<div>{goalValues.allOut.toLocaleString()} points</div>
												</IonItem>
											) : (
												""
											)}
											{goalValues.manual !== null ? (
												<IonItem className="onboarding-radio-item">
													<IonRadio className="onboarding-radio" value="manual" />
													<IonLabel className="onboarding-radio-label">Manual</IonLabel>
													<div>{goalValues.manual.toLocaleString()} points</div>
												</IonItem>
											) : (
												""
											)}
										</IonRadioGroup>
										<div>
											<IonButton color="tertiary" size="medium" onClick={(e) => acceptGoal(e.nativeEvent)}>
												Accept
											</IonButton>
											<IonButton color="secondary" size="medium" onClick={() => createGoalPicker(Math.round(activity.avgWeeklyPoints))}>
												Set a different goal
											</IonButton>
										</div>
									</IonCardContent>
								</div>
							);
						} else {
							return (
								<div key={`${i}`}>
									<div className="activity-card-image-div">
										<img src={activity.goalImage} alt="" />
									</div>
									<IonCardHeader>
										<IonCardSubtitle>Goal this week</IonCardSubtitle>
										<IonCardTitle>{goal.toLocaleString()} points</IonCardTitle>
									</IonCardHeader>
									<IonCardContent className="new-user-card-content">
										<div>This is your goal for the week.</div>
										<br />
										{activity.streakEnabled === true ? <div>Hitting this goal will increase your Goal Streak to {activity.streak + 1}!</div> : ""}
										<br />
										<div>
											<IonButton color="tertiary" size="medium" onClick={(e) => acceptGoal(e.nativeEvent)}>
												Sounds good
											</IonButton>
											<IonButton color="secondary" size="medium" onClick={() => createGoalPicker(Math.round(activity.avgWeeklyPoints))}>
												Set a different goal
											</IonButton>
										</div>
									</IonCardContent>
								</div>
							);
						}
					})}
				</IonCard>
			</div>
		</React.Fragment>
	);
};

export default NewUserGoalComponent;
