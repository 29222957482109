import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonChip,
	IonContent,
	IonFooter,
	IonHeader,
	IonLabel,
	IonLoading,
	IonPage,
	IonPopover,
	IonProgressBar,
	IonToolbar,
	useIonViewWillEnter,
} from "@ionic/react";
import React, { useRef, useState } from "react";
import "../../css/Home.css";
import "../../css/Onboarding.css";
import { cloudFrontURL, loadingMessages } from "../../models/constants";
import { logGenericEvent, logPageView } from "../../services/AnalyticsService";
import { getRandomValueFromArray } from "../../services/HelperServices";
import { getUser } from "../../services/UserService";
import { inviteUserToSquad, acceptUserToSquad } from "../../services/UserService";
import { StorageService } from "../../services/StorageService";
import { validateCode } from "../../services/SquadService";
import ChallengeDetailsComponent from "../Challenges/ChallengeDetailsComponent";
import ChallengeHeaderComponent from "../Challenges/ChallengeHeaderComponent";
import { sendSlackNotification } from "../../services/NotificationService";
const storageService = new StorageService();

const Greeting = ({ progress, setProgress }) => {
	const [user, setUser] = useState();
	const [loading, setLoading] = useState();
	const [squadToJoin, setSquadToJoin] = useState();
	const [challengeToJoin, setChallengeToJoin] = useState();

	useIonViewWillEnter(async () => {
		logPageView("Greeting");
		console.log("In greeting");
		let tempuser = await fetchUser();
		setUser(tempuser);
		await checkForInitialSquad(tempuser);
		await checkForInitialChallenge();
		setProgress(0.3);
		storageService.setItem("lastOnboardingStep", 3);
	});

	const checkForInitialSquad = async (tempuser) => {
		let initialSquad = await storageService.getObject("initialSquadToJoin");
		if (initialSquad !== null) {
			setSquadToJoin(initialSquad);
			try {
				logGenericEvent("Joined initial squad " + initialSquad.name);
				sendSlackNotification(
					`*NEW USER SET TO JOIN INITIAL SQUAD*\nUsername: ${tempuser.userName}\nSquad: ${initialSquad.name}\nSquad ID: ${initialSquad.id}`,
					"creation"
				);
			} catch (e) {
				console.log("Error in logging to slack: ", e);
				console.log({ tempuser });
			}
		}
	};

	const checkForInitialChallenge = async () => {
		let initialChallenge = await storageService.getObject("initialChallengeToJoin");
		if (initialChallenge !== null) {
			setChallengeToJoin(initialChallenge);
		}
	};

	const fetchUser = async () => {
		let userstored = JSON.parse(await getUser(null, false));
		// setUser(userstored);
		return userstored;
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar style={{ "--border-style": "none" }}>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
				</IonToolbar>
				<IonProgressBar value={progress} />
			</IonHeader>
			{user ? (
				<React.Fragment>
					<IonContent>
						<IonLoading isOpen={loading} onDidDismiss={() => setLoading(false)} message={getRandomValueFromArray(loadingMessages)} duration={1000} />
						<div className="onboarding-main-div">
							{squadToJoin ? <SquadToJoin user={user} squadToJoin={squadToJoin} /> : ""}
							{challengeToJoin ? <ChallengeToJoin user={user} challengeToJoin={challengeToJoin} /> : ""}
							{!challengeToJoin && !squadToJoin ? (
								<React.Fragment>
									<div className="onboarding-title">Great to meet you {user.userName}!</div>
									<img className="onboarding-hero-image" src="assets/images/fistbump.gif" alt="" />
								</React.Fragment>
							) : (
								""
							)}
						</div>
					</IonContent>
					<IonFooter className="onboarding-footer">
						<IonButton routerLink="/onboarding/question" className="onboarding-advance-button">
							{squadToJoin ? "Great!" : ""}
							{challengeToJoin ? "Looks right!" : ""}
							{!challengeToJoin && !squadToJoin ? "Great to meet you too!" : ""}
						</IonButton>
					</IonFooter>
				</React.Fragment>
			) : (
				""
			)}
		</IonPage>
	);
};

export default Greeting;

const SquadToJoin = ({ user, squadToJoin }) => {
	return (
		<React.Fragment>
			<div className="onboarding-title">
				Welcome {user.userName}!<br />
				<br />
				<div style={{ fontSize: "17px", fontWeight: "bold", textDecoration: "underline" }}>Here is the squad you'll be joining</div>
			</div>
			<div className="squad-data-div" style={{ width: "100%", textAlign: "center", paddingTop: "10px" }}>
				<div id="squad-image">
					<img src={squadToJoin.image} alt="" style={{ height: "50%", width: "50%", borderRadius: "10px" }} />
				</div>
				<div style={{ textAlign: "center" }}>
					<div className="squad-slogan-div" style={{ width: "100%", fontStyle: "italic" }}>
						{squadToJoin.slogan}
					</div>
					<div className="squad-name-div" style={{ width: "100%", fontSize: "1.5em", fontWeight: "bold" }}>
						{squadToJoin.name}
					</div>
					<div style={{ width: "100%", fontSize: "1.5em", fontWeight: "bold" }}>
						{squadToJoin.tags &&
							squadToJoin.tags.map((tag, i) => {
								return (
									<IonChip key={`${i}`} outline="false" color="tertiary">
										<IonLabel>{tag.tag}</IonLabel>
									</IonChip>
								);
							})}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

const ChallengeToJoin = ({ user, challengeToJoin }) => {
	const [videoWindowOpen, setVideoWindowOpen] = useState(false);

	const playIntroVideo = () => {
		setVideoWindowOpen(true);
		// vidRef.current.play();
	};
	const VideoPopover = ({ challengeID, videoURL }) => {
		const vidRef = useRef(null);
		return (
			<React.Fragment>
				<div>
					<video ref={vidRef} id="success" preload="auto" controls autoPlay={true} style={{ width: "100%" }}>
						<source src={`${cloudFrontURL}/challenges/${challengeID}/${videoURL}`} />
					</video>
				</div>
			</React.Fragment>
		);
	};

	return (
		<React.Fragment>
			<IonPopover
				isOpen={videoWindowOpen}
				cssClass="exercise-details-full-screen-popover"
				onDidDismiss={(e) => {
					setVideoWindowOpen(false);
				}}
			>
				<VideoPopover setVideoWindowOpen={setVideoWindowOpen} challengeID={challengeToJoin.id} videoURL={challengeToJoin.metadata.videoURL} />
			</IonPopover>
			<div className="onboarding-title">
				Welcome {user.userName}!<br />
				<br />
				<div style={{ fontSize: "24px", fontWeight: "bold" }}>Here is the challenge you'll be joining</div>
				<p style={{ fontStyle: "italic", fontSize: "16px" }}>Don't worry, theres no commitment right now 😉</p>
			</div>
			<div className="squad-data-div" style={{ width: "100%", paddingTop: "10px" }}>
				<ChallengeHeaderComponent challengeDetails={challengeToJoin} />
			</div>
			{challengeToJoin && challengeToJoin.metadata && challengeToJoin.metadata.videoURL ? ( // Challenge is not active
				<div style={{ textAlign: "center" }}>
					<IonButton color="success" style={{ width: "50%", marginBottom: "10%" }} onClick={() => playIntroVideo()}>
						Play Intro Video
					</IonButton>
				</div>
			) : (
				""
			)}
		</React.Fragment>
	);
};
