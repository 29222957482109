import React, { useRef } from "react";
import { IonSlides, IonSlide, IonButton } from "@ionic/react";
import Emoji from "react-emoji-render";

const CreateSquadCoachMark = ({ setShowCoachMark }) => {
	return (
		<React.Fragment>
			<div style={{ width: "30%", position: "absolute", top: "3%" }}>
				<img src="assets/images/arrow-white-up-right-2.png" alt="" style={{ height: "70px" }} />
				<br />
				Give your squad a fun photo!
			</div>
			<div style={{ width: "60%", position: "absolute", top: "28%" }}>
				<div style={{ width: "40%", float: "left" }}>
					... or you can use a random one if you want <Emoji text=":smile:" />
				</div>
				<img src="assets/images/arrow-white-up-right.png" alt="" style={{ height: "70px" }} />
			</div>
			<div style={{ width: "30%", position: "absolute", top: "26%", left: "67%" }}>
				A slogan helps customize your squad
				<br />
				<img src="assets/images/arrow-white-down-left.png" alt="" style={{ height: "70px" }} />
			</div>
			<div style={{ width: "65%", position: "absolute", top: "75%", left: "29%" }}>
				<img src="assets/images/arrow-white-up-left.png" alt="" style={{ height: "70px", float: "left" }} />
				<br />
				<div>Tap to add tags describing your squad</div>
			</div>
		</React.Fragment>
	);
};

export default CreateSquadCoachMark;
