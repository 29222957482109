import { IonButton, IonCol, IonGrid, IonHeader, IonIcon, IonModal, IonRow, IonSpinner, IonTitle, IonToolbar } from "@ionic/react";
import { closeOutline, expandOutline } from "ionicons/icons";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import "../../css/SquadScoreboard.css";
import { getSquadPrediction, isGoalCompleted } from "../../services/PointsAndGoalsService";
import { hasSeasonStarted, isUserinSeason } from "../../services/SeasonsService";
import { getSquad, getSquadGoal } from "../../services/SquadService";
import { getUser } from "../../services/UserService";
import SquadScoreboardComponent from "./SquadScoreboardComponent";
import AuthContext from "../../contexts/GlobalState";

/**
 *
 * The little component at the top of the chat room that shows at a glance the squad's goal, total & prediction for the week
 *
 */

const SquadProgressComponentMini = ({ dow, squadId, showFlavorText, refresher, refresh = false }) => {
	const history = useHistory();
	const { squadGoals } = useContext(AuthContext);
	const [squadGoalObject, setSquadGoalObject] = useState();
	const [lastWeekSquadGoal, setLastWeekSquadGoal] = useState(null);
	const [squadPrediction, setSquadPrediction] = useState({
		points: null,
		percent: null,
		text: null,
		icon: null,
		iconColor: null,
		flavorText: null,
		style: null,
	});

	useEffect(() => {
		setSquadGoalObject();
		loadSquadDataFromGlobalContext();
		//loadSquadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refresher, squadGoals]);

	/**
	 *
	 * Get all the squad's data
	 *
	 */

	const loadSquadDataFromGlobalContext = async () => {
		// Get squad total
		let squadGoal = squadGoals[squadId];
		if (squadGoal === undefined) {
			return;
		}
		if (squadGoal === null) {
			console.log("squad goal is null");
			//setNullGoalNotice(true);
			return;
		}
		setSquadGoalObject(squadGoal);
		setSquadPrediction(squadGoal.predictionObject);
	};

	const navTo = (link) => {
		console.log("history pushing");
		history.push(link);
	};
	return (
		<React.Fragment>
			{dow && dow !== "Mon" ? (
				<div className="squad-info-container" style={window.innerWidth <= 320 ? { fontSize: "0.75em" } : {}}>
					{squadGoalObject && squadPrediction.points ? (
						<React.Fragment>
							<div style={{ paddingRight: "10px" }}>
								Goal: <span className="squad-info-bold">{squadGoalObject.goalPoints.toLocaleString()}</span>
							</div>
							<div style={{ paddingRight: "10px" }}>
								Current: <span className="squad-info-bold">{squadGoalObject.goalProgress.toLocaleString()}</span>
							</div>
							{squadGoalObject.goalProgress > squadGoalObject.goalPoints ? (
								<React.Fragment>
									<div>
										<img src="assets/images/trophy_transparent.gif" alt="" style={{ height: "30px" }} />
									</div>
									<div>
										Prediction: <span className="squad-info-bold">{squadPrediction.points.toLocaleString()}</span>
									</div>
								</React.Fragment>
							) : (
								<React.Fragment>
									<div>
										Prediction: <span className="squad-info-bold">{squadPrediction.points.toLocaleString()}</span>
									</div>
									<div className="dot" style={squadPrediction.style}></div>
								</React.Fragment>
							)}
						</React.Fragment>
					) : (
						<React.Fragment>
							<div style={{ paddingRight: "10px", paddingLeft: "10px" }}>
								Goal: <IonSpinner />
							</div>
							<div style={{ paddingRight: "10px" }}>
								Current: <IonSpinner />
							</div>
							<div style={{ paddingRight: "10px" }}>
								Prediction: <IonSpinner />
							</div>
							<div className="dot" style={squadPrediction.style}></div>
						</React.Fragment>
					)}
				</div>
			) : (
				""
			)}
			{lastWeekSquadGoal !== null && dow === "Mon" ? (
				<div>
					<div className="time-holder">
						<IonGrid>
							<IonRow>
								<IonCol className="time-remaining-text" size="12">
									New Squad Goal Starts Tomorrow
									{squadId ? (
										<IonButton color="secondary" onClick={() => navTo("/squadlastweekcompleted/" + squadId)}>
											Last Week's Results
										</IonButton>
									) : (
										""
									)}
								</IonCol>
							</IonRow>
						</IonGrid>
					</div>
				</div>
			) : (
				""
			)}
		</React.Fragment>
	);
};

export default SquadProgressComponentMini;
