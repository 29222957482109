/* eslint-disable no-loop-func */
import moment from "moment";
import { API_URL, PHP_URL_NAME } from "../models/constants";
import { StorageService } from "../services/StorageService";
import { getSquadActivities } from "./ActivitiesService";
import { getPHP } from "./APIService";
import { getIDToken } from "./AuthService";
import { getChatRooms } from "./ChatService";
import { sendSlackErrorNotification } from "./NotificationService";
import { getGoal, getPrediction, getSquadPrediction, isUserOptedOut } from "./PointsAndGoalsService";
import { shouldRefresh } from "./RefreshService";
import { getUser, getUserID, isUserFacilitator } from "./UserService";
const storageService = new StorageService();

/**
 *
 * Creates a squad with the passed variables
 *
 * @param {string} squadName
 * @param {string} squadSlogan
 * @param {string} squadImage
 *
 * returns JSON body of squad info
 *
 */
export const createSquad = async (data, urlLink = false) => {
	//if (squadName == null || squadSlogan == null || squadImage == null) {return '{"error":"missing "}'}
	const url = API_URL;
	let idToken = await getIDToken().catch((error) => {
		throw Error("[createSquad] Error in getIDtoken: " + error);
	});

	console.log({urlLink});
	console.log({data});
	const response = await Promise.resolve(
		fetch(url + "squads", {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		//console.log("[JS][createSquad] Error in API call: " + error);
		sendSlackErrorNotification(error,"createSquad",url)
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {
			//console.log("[JS] createSquad Success:", data);
			return data
		})
		.catch((error) => {
			//console.log("[JS][createSquad] Error in JSON conversion: " + error);
			throw Error(error);
		});
};

/**
 *
 * Takes in a squad ID, writes the squad info to storage and returns back info about that squad
 * If ID is empty, returns back info about all squads
 *
 * @param {integer} squadID (optional)
 */
export const getSquad = async (squadID = null, limit = 10, offset = 0, refresh = false) => {
	//console.log("Refresh: "+refresh);
	const storageService = new StorageService();
	let ttl = 60*60 // refresh hourly	
	let squadIDs = await storageService.getObject("squad-ids") // not sure why this was commented out	
	//let squadIDs = []
	if (squadID === null && squadIDs !== null && squadIDs.length > 0){
		let squads = []
		let tempSquadIDS = []		
		for(let sID of squadIDs){
			let squadInfo = await getSquad(sID, null, null, refresh)			
			if(squadInfo.disbanded === false){
				squads.push(squadInfo)
				tempSquadIDS.push(squadInfo.id)
			} else if (squadInfo.disbanded === true){
				//console.log(`Squad ${sID} has been disbanded. Removing from local storage`);
				await storageService.removeItem("squad-info-"+sID)
			}			
		}	
		await storageService.setObject("squad-ids", tempSquadIDS)	
		return squads
	} else {
		let squadInfo = await storageService.getObject("squad-info-"+squadID);
		if (squadInfo && squadInfo !== "" && squadInfo.lastUpdated) {
			let update = await shouldRefresh(squadInfo,ttl)		
			if (update === false && refresh === false){
				console.log(`Loading squad ${squadID} from local storage`);			
				return squadInfo
			} else {
				// console.log(`Hitting squad API because squad ${squadID}, refresh=${refresh} & update=${update}`);
			}
		} else {
			console.log(`No squad object for squad ${squadID}`);
		}
		
	const url = API_URL;
	let idToken = await getIDToken().catch((error) => {
		throw Error("[getSquad] Error in getIDtoken: " + error);
	});
	let endPoint;
	if (squadID == null) {
		endPoint = "squads?limit=" + limit + "&offset=" + offset;
	} else {
		endPoint = "squads/" + squadID;
	}
	//console.log(`URL: ${url}${endPoint} `);
	const response = await Promise.resolve(
		fetch(url + endPoint, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		sendSlackErrorNotification(error,"getSquad",url)
		//console.log("[JS][getSquad] Error in API call: " + error);
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {
			//console.log("[JS] getSquad response:", data);
			//let severResponse = JSON.stringify(data);
			
			if (squadID === null ) { // assumes if all squads are fetched, they'll all be stored
				let allSquadIDs = []
				data.forEach(async (squad)=>{	
					allSquadIDs.push(squad.id)
					squad.lastUpdated = moment()				
					await storageService.setObject("squad-info-"+squad.id,squad)					
				})
				storageService.setObject("squad-ids",allSquadIDs)
			} else {
				data.lastUpdated = moment()
				storageService.setObject("squad-info-"+squadID,data)
			}
			return data;
		})
		.catch((error) => {
			//console.log("[JS][getSquad] Error in JSON conversion: " + error);
			throw Error(error);
		});
	}
};

/**
 *
 * Updates the metadata for a squad. User needs to be the admin for the squad or it will throw an error
 * Body can contain the following values:
 * Name, Image (URL), lifetimePoints, level, goalStreak, createdAt, updatedAt
 *
 * @param {integer} squadID
 * @param {JSON} body
 *
 * returns JSON of current squad metaData or an error
 *
 */
export const updateSquad = async (squadID, body) => {
	//if (squadName == null || squadSlogan == null || squadImage == null) {return '{"error":"missing "}'}
	const url = API_URL;
	let idToken = await getIDToken().catch((error) => {
		throw Error("[createSquad] Error in getIDtoken: " + error);
	});
	const response = await Promise.resolve(
		fetch(url + "squads/" + squadID, {
			method: "PATCH",
			body: JSON.stringify(body),
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		//console.log("[JS][updateSquad] Error in API call: " + error);
		sendSlackErrorNotification(error,"updateSquad",url)
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {
			//console.log("[JS] updateSquad Success:", data);			
			return data;
		})
		.catch((error) => {
			//console.log("[JS][updateSquad] Error in JSON conversion: " + error);
			throw Error(error);
		});
};

/**
 *
 * @param {*} squadID
 *
 */

export const deleteSquad = async (squadID) => {
	const url = API_URL;
	let idToken = await getIDToken().catch((error) => {
		throw Error("[deleteSquad] Error in getIDtoken: " + error);
	});
	const response = await Promise.resolve(
		fetch(url + "squads/" + squadID, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		//console.log("[JS][deleteSquad] Error in API call: " + error);
		sendSlackErrorNotification(error,"deleteSquad",url)
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {
			//console.log("[JS] deleteSquad Success:", data);
			let severResponse = JSON.parse(JSON.stringify(data));
			return severResponse;
		})
		.catch((error) => {
			//console.log("[JS][deleteSquad] Error in JSON conversion: " + error);
			throw Error(error);
		});
};

export const getRandomSquadImage = async () => {
	const response = await Promise.resolve(
		fetch("https://picsum.photos/500", {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		})
	).catch((error) => {
		//console.log("[JS][deleteSquad] Error in API call: " + error);
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {
			//console.log("[JS] deleteSquad Success:", data);
			let severResponse = JSON.parse(JSON.stringify(data));
			return severResponse;
		})
		.catch((error) => {
			//console.log("[JS][deleteSquad] Error in JSON conversion: " + error);
			throw Error(error);
		});
};

export const getRandomAvatar = async () => {
	let style="big-ears"
	let seed = Math.random()
	let randomNumber = Math.floor(Math.random()*10)
	switch(randomNumber){
		case 0:
			style = "adventurer"
			break;
			case 1:
				style = "adventurer-neutral"
				break;
				case 2:
					style = "avataaars"
				break;
				case 3:
					style = "micah"
				break;
				case 4:
					style = "big-smile"
				break;
				case 5:
					style = "bottts"
					
				break;
				case 6:
					style = "croodles"

				break;
				case 7:
					style = "human"

				break;
				case 8:
					style = "miniavs"

				break;
				case 9:
					style = "open-peeps"

				break;
				case 10:
					style = "personas"

				break;
				case 11:
					style = "pixel-art"

				break;
		default:
			style = "avataaars"
			break;
	}
	return "https://avatars.dicebear.com/api/"+style+"/:"+seed+".svg"
};

/**
 *
 * Adds memberId to squad id with the role = role
 * Possible role values: (normal, manager)
 *
 * @param {integer} squadID
 * @param {string} memberID
 * @param {string} role
 *
 * returns JSON object with data related to memebers status
 *
 */

export const addMemberToSquad = async (squadID, memberID, role) => {
	let body = {
		addMember: {
			memberId: memberID,
			role: role,
		},
	};
	let updateResponse = await updateSquad(squadID, body);
	return updateResponse;
};

/**
 *
 * Removes memberId from squad id
 *
 * @param {integer} squadID
 * @param {string} memberID
 *
 * returns JSON object with "message" key and a value
 */

export const removeMemberFromSquad = async (squadID, memberID) => {
	let body = {
		removeMember: memberID,
	};
	let updateResponse = await updateSquad(squadID, body);
	return updateResponse;
};

/**
 *
 * Updates the role of memberID in squadID
 *
 * @param {integer} squadID
 * @param {string} memberID
 * @param {string} role
 *
 * returns JSON object related to members' status
 *
 */

export const updateMemberRole = async (squadID, memberID, role) => {
	let body = {
		setMemberRole: {
			memberId: memberID,
			role: role,
		},
	};
	let updateResponse = await updateSquad(squadID, body);
	return updateResponse;
};

/**
 * 
 * Returns an array of all the squad IDs the user in local storage belongs to
 * 
 */

export const getUserSquadIDs = async (userObject = null) => {
	const storageService = new StorageService();
	let userSquadIDs = [];
	// if no userObject is passed get it from local storage
	if (userObject === null){
		let userObjectJson = await storageService.getItem("user-null");
		if (userObjectJson === "") {
			throw Error ("No user logged in")
		}
		userObject = JSON.parse(userObjectJson)
	}
	if (userObject.squads.length > 0) {
		let i;
		for (i = 0; i < userObject.squads.length; i++) {
			// Make sure the user is accepted into the squad and the squad is not disbanded
			if (userObject["squads"][i].accepted === true && userObject["squads"][i]["squad"]["disbanded"] === false){
				userSquadIDs.push(userObject["squads"][i]["squad"]["id"]);
			}
		}
	}
	//console.log("SquadIDs returned: "+JSON.stringify(userSquadIDs));
	return userSquadIDs;
};

/**
 * 
 * Returns an array of all the squad IDs the user in local storage belongs to
 * 
 */

 export const getAllUserSquadIDs = async (userObject = null, onlyAccepted = false) => {
	const storageService = new StorageService();
	let userSquadIDs = [];
	// if no userObject is passed get it from local storage
	if (userObject === null){
		let userObjectJson = await storageService.getItem("user-null");
		if (userObjectJson === "") {
			throw Error ("No user logged in")
		}
		userObject = JSON.parse(userObjectJson)
	}
	if (userObject.squads.length > 0) {
		let i;
		for (i = 0; i < userObject.squads.length; i++) {
			// Make sure the user is accepted into the squad and the squad is not disbanded
			if (onlyAccepted === true){
				if (userObject["squads"][i]["squad"]["disbanded"] === false && userObject["squads"][i]["accepted"] === true){
					userSquadIDs.push(userObject["squads"][i]["squad"]["id"]);
				}
			} else {
				if (userObject["squads"][i]["squad"]["disbanded"] === false){
					userSquadIDs.push(userObject["squads"][i]["squad"]["id"]);
				}
			}
			
		}
	}
	//console.log("SquadIDs returned: "+JSON.stringify(userSquadIDs));
	return userSquadIDs;
};

/**
 * 
 * Get list of users in squad. Can return different types depending on parameters
 * 
 */

export const getUsersInSquad = async (squadID, acceptedOnly = false, includeCurrentGoal = false, goalStartDateMoment = null, goalEndDateMoment = null, includePrediction = true) => {
	let squadInfo = await getSquad(squadID)
	//console.log("Squad Info for squad "+squadID+": "+JSON.stringify(squadInfo));
	let squadMemberInfo = []
	//console.log("# of squad members: "+squadInfo.members.length);
	if (squadInfo.members.length > 0) { // squad has members
		for (let element of squadInfo.members) {
			element.squadID = squadInfo.id
			element.squadName = squadInfo.name
			//console.log("Checking "+element.user.userName);
			//console.log({element});
			if (acceptedOnly === true){ 
				if (element.accepted === true && 
					element.role && 
					element.role !== 'roboCoach' && 
					element.role !== 'squadRoboCoach' && 
					element.role !== 'retiredSquadRoboCoach' && 
					element.user.cognitoId !== "9370480d-e510-4d88-bd7a-f644b5a51e3b" && // exclude TheCheerleader
					element.user.active === true) {
					//console.log("Adding user because accepted = true");
					squadMemberInfo.push(element)
				} else {
					//console.log("Excluding user because accepted = false");
				}
			} else {
				//console.log("Adding user");
				squadMemberInfo.push(element)
			}
		}			
	}
	if (includeCurrentGoal === true || goalStartDateMoment !== null) {
		//console.log("Adding user goals to object");
		for (let i = 0; i<squadMemberInfo.length; i++){ // iterate over each element of squadMemberInfo and set goal properties
			let goal = await getGoal(squadMemberInfo[i].userId,true, goalStartDateMoment, goalEndDateMoment, false, false)
			if (goal !== null){
				squadMemberInfo[i].goalPoints = goal.goalPoints
				squadMemberInfo[i].goalProgress = goal.goalProgress
				if (!goal.prediction && (goalStartDateMoment !== null && goalEndDateMoment !== null) && includePrediction === true){ // don't get a prediction if youre getting a historical goal
					goal.prediction = await getPrediction(squadMemberInfo[i].userId)
				}
				squadMemberInfo[i].goalPrediction = goal.prediction
			} else {
				squadMemberInfo[i].goalPoints = 0
				squadMemberInfo[i].goalProgress = 0
				squadMemberInfo[i].goalPrediction = 0
			}			
		}
	}
	if (squadMemberInfo === []) {squadMemberInfo = null}
	return squadMemberInfo
}

export const getSquadGoal = async (squadID, startDateMoment = null, endDateMoment = null, refresh = false, storeGoal = true, includePrediction = true) => {
	let squadGoalObject
	let defaultRefreshMinutes = 15
	squadGoalObject = await storageService.getObject("squadGoal-"+squadID);
	//console.log({squadGoalObject});
	if (squadGoalObject && squadGoalObject !== "" && squadGoalObject.lastUpdated && startDateMoment === null) {
		let update = await shouldRefresh(squadGoalObject,defaultRefreshMinutes)		
		if (update === false && refresh === false){
			//console.log(`Loading squadGoal ${squadID} from local storage`);	
			console.log(`Not refreshing squad goal for squad ID ${squadID} `);
			if (squadGoalObject.bonusPoints && squadGoalObject.bonusPoints > 0) {
				squadGoalObject.goalProgress = squadGoalObject.goalProgress + squadGoalObject.bonusPoints
			}		
			return squadGoalObject;
		} else {
			//console.log(`Hitting squad goal API because squad=${squadID}, refresh=${refresh} & update=${update}`);
		}
	} else {
		//console.log(`No squad goal object for squad ${squadID}`);		
	}
	let idToken = await getIDToken().catch((error) => {
        throw Error("Error in getIDtoken: " + error);
    });
	let url = API_URL + "goals?squadId="+squadID;
	if (startDateMoment !== null){
		url = url + "&startDate=" + startDateMoment.format() + "&endDate=" + endDateMoment.format()
	}
	//console.log("Squad Goal URL: "+url);
	const response = await Promise.resolve(
		fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		}).catch((error) => {
			sendSlackErrorNotification(error,"getSquadGoal",url)
			throw Error("Error getSquadGoal: " + error);
		})
	).catch((error) => {
		sendSlackErrorNotification(error,"getSquadGoal",url)
		throw Error("Error getSquadGoal: " + error);
	});
	const responseLength = (await response.clone().text()).length;
	//console.log("Response Length:" + responseLength);
	if (responseLength > 2) {
		let serverResponse = await response.json().catch((error) => {
			throw Error("Error in parsing API response from getSquadGoal: " + error);
		});
		// console.log("[JS] getSquadGoal Success:", serverResponse);
		serverResponse[0].lastUpdated = moment()		
		if (storeGoal === true) {
			if (startDateMoment === null && endDateMoment === null && includePrediction === true){ // only get a prediction if it's for this week
				let prediction = await getSquadPrediction(squadID)
				//console.log("Predict response: "+prediction);
				if (prediction){
					serverResponse[0].prediction = prediction
				}
			}
			storageService.setObject("squadGoal-"+squadID, serverResponse[0])
		}
		if (serverResponse[0].bonusPoints && serverResponse[0].bonusPoints > 0) {
			serverResponse[0].goalProgress = serverResponse[0].goalProgress + serverResponse[0].bonusPoints
		}	
		return serverResponse[0];
	} else {
		//console.log("No goal object returned from server");
		return null;
	}
};

/**
 * 
 * getSquadPoints
 * 
 * Parses the activities table and returns the number of points
 * for a squad for a given time frame
 * 
 * @param {int} squadID 
 * @param {moment} startDateMoment 
 * @param {moment} endDateMoment 
 */


export const getSquadPoints = async (squadID, startDateMoment, endDateMoment) => {
	// Placeholder for now
	let activitiesJSON = await getSquadActivities(squadID, null, null, null, startDateMoment, endDateMoment);
	//console.log("ActivitiesJSON: "+activitiesJSON);
	let totalPoints = 0;
	if (activitiesJSON !== null) {
		let activitiesArray = JSON.parse(activitiesJSON);		
		activitiesArray.forEach((loggedActivity) => {
			//console.log("Logged Activity: "+JSON.stringify(loggedActivity));
			totalPoints += Math.round(loggedActivity.points);
			//console.log("totalPoints: " + totalPoints);
		});
	}
	return totalPoints;
};

export const getPendingInvites = (userID) => {
	getUser(userID, true).then((userObject)=>{
		//console.log("[getPendingInvites] User Object: "+JSON.stringify(userObject));
	}).catch((error) => {
		throw error ("Error: "+error)
	})
}

export const getCompetitionSquadMembers = async (chatRoomObject) => {
	// Get users from both squad in chatRoomObject
	// Exclude all users who opt'd out
	// Return back as one array
	let squadA = await getSquad(chatRoomObject.squadIdA)
	let squadB = await getSquad(chatRoomObject.squadIdB)
	let membersArrayA = squadA.members;
	let membersArrayB = squadB.members;
	//console.log("membersArrayA: ",{membersArrayA});
	//console.log("membersArrayB: ",{membersArrayB});
	let members = [];
	for (let element of membersArrayA) {
		//console.log("Element: ",{element});
		let optedOut = await isUserOptedOut(element.userId)
		if (element.acceptDate !== null && optedOut === false) {
			let tempUserIDArray = [];
			let user = JSON.parse(JSON.stringify(element.user));
			tempUserIDArray.userID = element.userId;
			tempUserIDArray.userName = user.userName;
			if (user.avatar === undefined) {
				tempUserIDArray.avatar = "https://fitsquad-ui-assets.s3-us-west-2.amazonaws.com/empty-avatar.png";
			} else {
				tempUserIDArray.avatar = user.avatar;
			}
			members.push(tempUserIDArray);
		} else {
			//console.log(`User ${element.userName} not included. acceptDate: ${element.acceptDate}. optedOut: ${optedOut}`);
		}
	};
	for (let element of membersArrayB) {
		let optedOut = await isUserOptedOut(element.userId)
		if (element.acceptDate !== null && optedOut === false) {
			let tempUserIDArray = [];
			let user = JSON.parse(JSON.stringify(element.user));
			tempUserIDArray.userID = element.userId;
			tempUserIDArray.userName = user.userName;
			if (user.avatar === undefined) {
				tempUserIDArray.avatar = "https://fitsquad-ui-assets.s3-us-west-2.amazonaws.com/empty-avatar.png";
			} else {
				tempUserIDArray.avatar = user.avatar;
			}
			members.push(tempUserIDArray);
		} else {
			//console.log(`User ${element.userName} not included. acceptDate: ${element.acceptDate}. optedOut: ${optedOut}`);
		}
	};
	//console.log("Members: ",{members});
	return members
}

export const getSquadMembers = async (chatRoomObject = null, squadID = null) => {
	// Get users from both squad in chatRoomObject
	// Exclude all users who opt'd out
	// Return back as one array
	let squad
	if (chatRoomObject !== null){
		squad = await getSquad(chatRoomObject.squadIdA)
	} else if (squadID !== null) {
		squad = await getSquad(squadID,null,null,true)
	}
	
	let membersArray = squad.members;
	let members = [];
	for (let element of membersArray) {
		//console.log("Element: ",{element});
		if (element.acceptDate !== null && element.role !== "roboCoach" && element.user.userType !== "facilitator" && element.accepted === true) { // exclude robocoached for now
			let tempUserIDArray = [];
			let user = JSON.parse(JSON.stringify(element.user));
			tempUserIDArray.userID = element.userId;
			tempUserIDArray.userName = user.userName;
			if (user.avatar === undefined) {
				tempUserIDArray.avatar = "https://fitsquad-ui-assets.s3-us-west-2.amazonaws.com/empty-avatar.png";
			} else {
				tempUserIDArray.avatar = user.avatar;
			}
			members.push(tempUserIDArray);
		} else {
			//console.log(`User ${element.user.userName} not included. acceptDate: ${element.acceptDate}`);
		}
	};
	//console.log("Members: ",{members});
	return members
}

/**
 * 
 * Takes in a user ID and returns an array of all the squadIDs that the current user and needle user share in common 
 * 
 * @param {*} needleUserID  
 * @returns 
 */

export const areUsersInSameSquad = async (needleUserID, hayStackUser) => {
	let squadIDs = await getUserSquadIDs() // getting userIDs of logged in user
	let foundInSquadID = []
	if (await isUserFacilitator() === true){
		let user = JSON.parse(await getUser(needleUserID))
		for (let squad of user.squads) {
			foundInSquadID.push(squad.squadId)
		}
		return foundInSquadID
	}
	//console.log("squadIDs: ",{squadIDs});
	for await (let squadID of squadIDs){ // get all the squads for haystackUser
		let tempSquadMembers = await getSquadMembers(null, squadID)
		tempSquadMembers.forEach((member)=>{ // check every member of the squad to see if it matches needleUser
			//console.log("MemberID: "+member.userID);
			if (member.userID === needleUserID){
				foundInSquadID.push(squadID)
				//console.log("FOUND IT");
			}
		})
	}
	return foundInSquadID
}

export const isUserInSquad = async (squadID, userID = null, refresh = false) => {
	let inSquad = false
	if (userID === null) {
		userID = await getUserID()
	}

	let squadInfo = await getSquad(squadID, null, null, refresh)
	console.log({squadInfo});
	for(let user of squadInfo.members){
		if (userID === user.userId && user.accepted === true){
			inSquad = true
		}
	}
	return inSquad
}

export const isSquadIDinUserObject = async (user = null, squadID) => {
	let inSquad = false
	if (user === null) {
		user = JSON.parse(await getUser(null,true)) 
	}
	for await (let squad of user.squads){
		if (squad.squadId === squadID){
			inSquad = true
		}
	}
	return inSquad
}

export const isUserASquadAdmin = async (squadID, user = null) =>{
	if (user === null){
		user = JSON.parse(await getUser())		
	}
	let squad = await getSquad(squadID,null,null,false)
	let isAdmin = false
	//console.log("userId: "+userID);
	if (user.userType === 'facilitator' && squad.isPrivate === false){
		isAdmin = true
		return isAdmin
	}
	squad.members.forEach((member)=>{
		//console.log("member.userId: "+member.userId);
		if(member.userId === user.cognitoId){
			// Found them
			//console.log("member.role: "+member.role);
			if (member.role === "owner"){
				isAdmin = true
			}
		}
	})
	return isAdmin
}

/**
 * 
 * Will look up squad with the passed squadID. If the squad exists locally, will pull that, otherwise will hit the API
 * If the locally stored squad, will check if it has a chatRoomID stored. If not will pull one and store it 
 * 
 * 
 * @param {*} squadID 
 */

export const getChatRoomIDFromSquadID = async (squadID, refresh = false) => {
	let squad = await storageService.getObject("squad-info-"+squadID)
	if (squad === null){
		squad = await getSquad(squadID)
	}
	if (!squad.chatRoomID){
		let chatRooms = await getChatRooms(refresh);
		let chatRoomID = null;
		for (let room of chatRooms) {
			if (parseInt(room.squadIdA) === parseInt(squadID) && room.squadIdB === null) {
				chatRoomID = room.id;
			}
		}
		squad.chatRoomID = chatRoomID
		storageService.setObject("squad-info-"+squadID,squad)
	}
	return squad.chatRoomID;
};

/**
 *
 * Takes in a squad ID
 * Checks local squad standings info and refresh
 * If needed, pulls standings, saves to squad standings object and returns
 *
 * @param {integer} squadID (optional)
 */
export const getSquadStandings = async (squadID, refresh = false) => {
	const storageService = new StorageService();
	let ttl = (60*60*24) // basically a day
	let squadInfo = await storageService.getObject("squad-standings-"+squadID);
	if (squadInfo && squadInfo !== "" && squadInfo.lastUpdated) {
		let update = await shouldRefresh(squadInfo,ttl)		
		if (update === false && refresh === false){
			//console.log(`Loading squad standings for ${squadID} from local storage`);			
			return squadInfo
		} else {
			//console.log(`Hitting squad standings API because squad ${squadID}, refresh=${refresh} & update=${update}`);			
		}
	} else {
		//console.log(`No squad object for squad ${squadID}`);		
	}

	const url = API_URL;
	let idToken = await getIDToken().catch((error) => {
		throw Error("[getSquadStandings] Error in getIDtoken: " + error);
	});
	let endPoint = "stats?type=squadGeneral&squadId=" + squadID;
	//console.log(`URL: ${url}${endPoint} `);
	const response = await Promise.resolve(
		fetch(url + endPoint, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		//console.log("[getSquadStandings] Error in API call: " + error);
		sendSlackErrorNotification(error,"getSquadStandings",url)
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {
			//console.log("[getSquadStandings] response:", data);
			//let severResponse = JSON.stringify(data);
			data.lastUpdated = moment()
			storageService.setObject("squad-standings-"+squadID,data)
			return data;
		})
		.catch((error) => {
			//console.log("[getSquadStandings] Error in JSON conversion: " + error);
			throw Error(error);
		});
};

/**
 *
 * Takes in a squad ID
 * Checks local squad standings info and refresh
 * If needed, pulls standings, saves to squad standings object and returns
 *
 * @param {integer} squadID (optional)
 */
export const getSquadCompetitionStandings = async (squadID, refresh = false) => {
	const storageService = new StorageService();
	let ttl = (60*60*24) // basically a day
	let squadInfo = await storageService.getObject("squad-competition-standings-"+squadID);
	if (squadInfo && squadInfo !== "" && squadInfo.lastUpdated) {
		let update = await shouldRefresh(squadInfo,ttl)		
		if (update === false && refresh === false){
			//console.log(`Loading squad standings for ${squadID} from local storage`);			
			return squadInfo
		} else {
			//console.log(`Hitting squad standings API because squad ${squadID}, refresh=${refresh} & update=${update}`);			
		}
	} else {
		//console.log(`No squad object for squad ${squadID}`);		
	}

	const url = API_URL;
	let idToken = await getIDToken().catch((error) => {
		throw Error("[getSquadCompetitionStandings] Error in getIDtoken: " + error);
	});
	let endPoint = "stats?type=squadCompetition&squadId=" + squadID;
	//console.log(`URL: ${url}${endPoint} `);
	const response = await Promise.resolve(
		fetch(url + endPoint, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		//console.log("[getSquadCompetitionStandings] Error in API call: " + error);
		sendSlackErrorNotification(error,"getSquadCompetitionStandings",url)
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {
			//console.log("[getSquadCompetitionStandings] response:", data);
			//let severResponse = JSON.stringify(data);
			data.lastUpdated = moment()
			storageService.setObject("squad-competition-standings-"+squadID,data)
			return data;
		})
		.catch((error) => {
			//console.log("[getSquadCompetitionStandings] Error in JSON conversion: " + error);
			throw Error(error);
		});
};

export const getAllSquadIDs = async () => {
	const url = API_URL;
	let idToken = await getIDToken().catch((error) => {
		throw Error("[getAllSquadIDS] Error in getIDtoken: " + error);
	});
	let endPoint = "squads?idOnly=true&limit=1000"
	const response = await Promise.resolve(
		fetch(url + endPoint, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		//console.log("[getAllSquadIDS] Error in API call: " + error);
		sendSlackErrorNotification(error,"getAllSquadIDs",url)
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {
			//console.log("All squad IDs: ",{data});								
			return data;
		})
		.catch((error) => {
			//console.log("[getAllSquadIDS] Error in JSON conversion: " + error);
			throw Error(error);
		});
}

/**
 * 
 * Gets all squads. If refreshIDs is false, then will get allIDs from API and pull squads based on that.
 * If it's true, will just get all squads, not looking at IDs.
 * 
 * @param {*} refreshIDs 
 * @param {*} refreshSquads 
 * @returns 
 */

export const getAllSquads = async (refreshIDs = false, refreshSquads = false) => {
	// if refresh is true, ignore squad-ids, otherwise pull from there
	let squadIDs = await getAllSquadIDs()
	let allSquads = []
	if (refreshIDs === false && squadIDs && squadIDs.length > 0){	
		//console.log("squadIDs.length: "+squadIDs.length);
		//console.log("hitting getSquad 1 by 1, hopefully pulling from internal")	
		for(let squadID of squadIDs){
			let squadInfo = await getSquad(squadID,null,null,refreshSquads).catch((error) => {
				throw Error(error);
			});
			allSquads.push(squadInfo)
		}
	} else {
		//console.log("squadIDs: "+squadIDs);
		//console.log("hitting getSquad with null");
		allSquads = await getSquad(null, 100).catch((error) => {
			sendSlackErrorNotification(error,"getAllSquads")
			throw Error(error);
		});
	}
	return allSquads;
};


export const addTagsToSquad = async (squadID, tagArray) => {
	const url = API_URL;
	let idToken = await getIDToken().catch((error) => {
		throw Error("[addTagsToSquad] Error in getIDtoken: " + error);
	});
	let endPoint = "squads/"+squadID
	let data = {
		addTag: tagArray
	}
	//console.log("Tag patch URL: "+ url + endPoint);
	//console.log({data});
	const response = await Promise.resolve(
		fetch(url + endPoint, {
			method: "PATCH",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		//console.log("[addTagsToSquad] Error in API call: " + error);
		sendSlackErrorNotification(error,"addTagsToSquad",url)
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {								
			return data;
		})
		.catch((error) => {
			//console.log("[addTagsToSquad] Error in JSON conversion: " + error);
			throw Error(error);
		});
}

export const validateCode = async (inviteCode) => {
	let url = API_URL +"squads/validate?shortCode="+inviteCode
	const response = await Promise.resolve(
		fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		}).catch((error) => {
			sendSlackErrorNotification(error,"validateCode",url)
			throw Error("Error validateCode: " + error);
		})
	).catch((error) => {
		sendSlackErrorNotification(error,"validateCode",url)
		throw Error("Error validateCode: " + error);
	});
	return response
		.json()
		.then((data) => {								
			return data;
		})
		.catch((error) => {
			//console.log("[validateCode] Error in JSON conversion: " + error);
			throw Error(error);
		});
}

export const clearAllSquadGoals = async (userObj = null) =>{
	if (userObj === null) {
		userObj = JSON.parse(await getUser(null, true));
	}
	
	//console.log("refreshAllSquadGoals");
	for await (let squad of userObj.squads) {
		if(squad.accepted === true){
			console.log("Removing goal for "+ squad.id);
			storageService.removeItem("squadGoal-" + squad.squadId);			
		}
	}
	return
}

export const clearAllSquadInfo = async (userObj = null) =>{
	if (userObj === null) {
		userObj = JSON.parse(await getUser(null, true));
	}
	//console.log("refreshAllSquadInfo");
	for await (let squad of userObj.squads) {
		if(squad.accepted === true){
			storageService.removeItem("squad-info-" + squad.id);
		}
	}
	return
}

export const getSquadNameFromSquadID = async (squadID) => {
	let squad = await getSquad(squadID,null,null,false)
	return squad.name
}

export const getCurrentWeeklySquad = async (refresh = false) => {
	let startOfWeek = moment().startOf("isoWeek").format("YYYY-MM-DD");
	let weeklySquad = await storageService.getObject("weeklySquad");
	//console.log("Start of week: "+startOfWeek);
	if (weeklySquad !== null && refresh === false) {
		if (Object.prototype.hasOwnProperty.call(weeklySquad, startOfWeek) === true) {
			console.log("Start of Week: " + weeklySquad[startOfWeek]);
			return (weeklySquad[startOfWeek]);
		} else {
			return null
		}
	} else {
		let userID = await getUserID()
		let weeklySquadAPI = PHP_URL_NAME + "fitsquad/userWeeklySquad.php?userID="+userID+"&date="+startOfWeek;
		weeklySquad = await getPHP(weeklySquadAPI)
		console.log("Weekly squad: "+JSON.stringify(weeklySquad));
		console.log({weeklySquad});
		if (weeklySquad.squadID === false){
			console.log("No weekly squad");
			return null
		} else {
			let weeklySquadIDArray = weeklySquad.squadID.split(",")
			//if(isNaN(parseInt(weeklySquad.squadID))){weeklySquadID = weeklySquad.squadID} else { weeklySquadID = parseInt(weeklySquad.squadID)}
			setCurrentlyWeeklySquad(weeklySquadIDArray) // will write the values as strings
			return weeklySquadIDArray
		}
	}
}

export const getLastWeeklySquad = async () => {
	let startOfLastWeek = moment().startOf("isoWeek").subtract(1, "weeks").format("YYYY-MM-DD");
	let weeklySquad = await storageService.getObject("weeklySquad");
	//console.log("Start of week: "+startOfWeek);
	if (weeklySquad !== null) {
		if (Object.prototype.hasOwnProperty.call(weeklySquad, startOfLastWeek) === true) {
			console.log("Start of Week: " + weeklySquad[startOfLastWeek]);
			return (weeklySquad[startOfLastWeek]);
		} else {
			return null
		}
	} else {
		return null
	}
}

export const setCurrentlyWeeklySquad = async (squadID) => {
	let startOfWeek = moment().startOf("isoWeek").format("YYYY-MM-DD");
	let weeklySquad = await storageService.getObject("weeklySquad");
	if(weeklySquad === null){
		weeklySquad = {}
	}
	weeklySquad[startOfWeek] = squadID
	await storageService.setObject("weeklySquad", weeklySquad);
}

export const getAcceptedNonWeeklySquads = async () => {
	let userID = await getUserID()
	let getAcceptedSquadsAPI = PHP_URL_NAME + "fitsquad/getAcceptedSquads.php?userID="+userID
	let acceptedSquads = await getPHP(getAcceptedSquadsAPI)
	return acceptedSquads
}
/**
 * 
 * Takes a chatRoomID and returns back the squadIdA of that chatRoom.
 * If a chatroom is not found, returns back null
 * 
 * @param {*} chatRoomID 
 * @returns 
 */
export const getSquadIDFromChatRoomID = async (chatRoomID) => {
	let chatRooms = await storageService.getObject("chatRooms")
	let squadID = null
	for (let chatRoom of chatRooms){
		if(chatRoom.id === chatRoomID){
			squadID = chatRoom.squadIdA
		}
	}
	return squadID
}

export const getRandomSquadID = async () => {
	let getRandomSquadIDAPI = PHP_URL_NAME + "fitsquad/getRandomSquad.php"
	let randomSquadID = await getPHP(getRandomSquadIDAPI)
	console.log({randomSquadID});
	if (parseInt(randomSquadID.result) === 200){
		return parseInt(randomSquadID.squadID)
	} else {
		return null
	}
}