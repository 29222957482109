/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useEffect, useState } from "react";
import { IonSkeletonText } from "@ionic/react";
import "../../css/Profile.css";
import "../../css/Home.css";
import Emoji from "react-emoji-render";
import moment from "moment-timezone";
import { StorageService } from "../../services/StorageService";
import { getGoal, isGoalCompleted } from "../../services/PointsAndGoalsService";

const ProfileWeeklyStreaksComponent = ({ userObject }) => {
	var [activityArray, setActivityArray] = useState([]);
	const [loadingStreaks, setLoadingStreaks] = useState(true);
	const storageService = new StorageService();

	useEffect(() => {
		setGoalStreaks(userObject);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const setGoalStreaks = async (userObject) => {
		moment.tz.setDefault(userObject.timezone);
		let pointHistory = await storageService.getObject("userPointHistory-" + userObject.cognitoId);
		if (pointHistory === null) {
			pointHistory = {};
		}
		if (!pointHistory.weeklyTotals) {
			pointHistory.weeklyTotals = {};
		}
		setActivityArray([]);
		let weeksChecked = 1;
		let finished = false; // we use this to stop the checking early so we don't keep pulling empty goals
		let numberOfWeeksToCheck = 20;
		if (pointHistory.firstWeek) {
			let currentWeek = moment().endOf("isoWeek");
			let firstWeek = moment(pointHistory.firstWeek);
			let numberOfWeeksToCheck = currentWeek.diff(firstWeek, "weeks");
			console.log("numberOfWeeksToCheck: " + numberOfWeeksToCheck);
		}
		while (weeksChecked <= numberOfWeeksToCheck && finished === false) {
			let goalObject;
			let startDate = moment().startOf("isoWeek").subtract(weeksChecked, "weeks").tz(userObject.timezone)
			let endDate = moment().endOf("isoWeek").subtract(weeksChecked, "weeks").add(1, "day").tz(userObject.timezone);
			// Do this because of tz BS
			startDate = moment(startDate).subtract(1, "day");
			endDate = moment(endDate).add(1, "day");
			//console.log("StartDate set: " + startDate.format());
			//console.log("EndDate set: " + endDate.format());
			if (!pointHistory.weeklyTotals[moment(startDate).format("MM/DD/YY")]) {
				//console.log(`Pulling new data for user on ${moment(startDate).format("MM/DD/YY")}`);
				goalObject = await getGoal(userObject.cognitoId, true, startDate, endDate, false);
				if (goalObject !== null) {
					pointHistory.weeklyTotals[moment(startDate).format("MM/DD/YY")] = goalObject;
				} else {
					pointHistory.firstWeek = moment(startDate).format("MM/DD/YY");
					console.log("Nothing returned from server. End of the road");
					finished = true;
				}
			} else {
				goalObject = pointHistory.weeklyTotals[moment(startDate).format("MM/DD/YY")];
			}
			//console.log({ goalObject });
			if (goalObject !== null) {
				if (isGoalCompleted(goalObject) === true) {
					goalObject.success = 1;
				} else {
					goalObject.success = 0;
				}
				setActivityArray((activityArray) => [...activityArray, goalObject]);
			}
			weeksChecked++;
		}
		//console.log("setGoalStreaks - pointHistory prior to setting: ", { pointHistory });
		storageService.setObject("userPointHistory-" + userObject.cognitoId, pointHistory);
		//console.log("Pulled a total of " + (weeksChecked + 1) + " weeks");
		//console.log("[JS] [pullLastThreeWeeks] Complete");
		setLoadingStreaks(false);
	};

	return (
		<React.Fragment>
			<div className="section-title">Weekly Goal Streaks</div>
			{loadingStreaks === true ? (
				<IonSkeletonText style={{ width: "50px", height: "50px" }} animated />
			) : (
				<div className="horizontal-scroll" style={{ paddingLeft: "12px" }}>
					{activityArray.length > 0 ? (
						activityArray.map((activity, index) => {
							return (
								<div key={`${index}`} className="streak">
									<div className="goal-streak-container">
										<div className="goal-streak-indicator">
											{activity.success === 1 ? <Emoji text=":trophy:" /> : <Emoji text=":disappointed:" />}
											<br />
										</div>
										<div className="goal-streak-data-points">
											<div className="goal-streak-data-points-each">
												<span style={{ opacity: "0.5" }}>Points: </span>
												{activity.success === 1 ? (
													<React.Fragment>
														<span className="goal-streak-data-points-bold success-bold">{activity.goalProgress.toLocaleString()}</span>
														{activity.bonusPoints > 0 ? <span className="profile-bonus"> (+{activity.bonusPoints.toLocaleString()})</span> : ""}
													</React.Fragment>
												) : (
													<span className="goal-streak-data-points-bold fail-bold">{activity.goalProgress.toLocaleString()}</span>
												)}
											</div>
											<div className="goal-streak-data-points-each">
												<span style={{ opacity: "0.5" }}>Goal: </span>
												<span className="goal-streak-data-points-bold">{activity.goalPoints.toLocaleString()}</span>
											</div>
										</div>
									</div>
									<div className="goal-week">{moment(activity.startDate).format("MMM DD, YYYY")}</div>
									{activity.forgiven ? <div className="forgiveness-token">Forgiveness token used 🙏</div> : ""}
								</div>
							);
						})
					) : (
						<div className="instructional-text">after your first week, you'll see your streaks here</div>
					)}
				</div>
			)}
		</React.Fragment>
	);
};

export default ProfileWeeklyStreaksComponent;
