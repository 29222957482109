/* eslint-disable jsx-a11y/accessible-emoji */
import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonChip,
	IonContent,
	IonHeader,
	IonLabel,
	IonPage,
	IonProgressBar,
	IonToolbar,
	useIonViewWillEnter,
} from "@ionic/react";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import AuthContext from "../../contexts/GlobalState";
import "../../css/Home.css";
import "../../css/Onboarding.css";
import { logPageView } from "../../services/AnalyticsService";
import { getSquad, getUserSquadIDs } from "../../services/SquadService";
import { StorageService } from "../../services/StorageService";
import { getUser } from "../../services/UserService";

const EndOnboarding = ({ progress, setProgress }) => {
	const history = useHistory();
	const storageService = new StorageService();
	const [squad, setSquad] = useState();
	const { login, setOnboardingCompleted } = useContext(AuthContext);
	useIonViewWillEnter(async () => {
		let user = JSON.parse(await getUser());
		let squadIDs = await getUserSquadIDs();
		let tempSquad = await getSquad(squadIDs[0]);
		setSquad(tempSquad);
		logPageView("end onboarding");
		setProgress(1.0);
		storageService.setItem("lastOnboardingStep", 18);
	});

	const endOnboardingSayHello = async () => {
		console.log("Squad found");
		await storageService.setObject("onboarding", { completed: true });
		// note this only works with a brand new user because of global state
		console.log(`squadID: ${squad.id}`);
		history.push(`/squadhome/${squad.id}`);
		setOnboardingCompleted(true);
	};

	const navToSquadCreate = async () => {
		await storageService.setObject("onboarding", { completed: true });
		history.push("/squadcreate");
		setOnboardingCompleted(true);
	};

	const endOnboarding = async () => {
		await storageService.setObject("onboarding", { completed: true });
		history.push("/");
		setOnboardingCompleted(true);
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar style={{ "--border-style": "none" }}>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
				</IonToolbar>
				<IonProgressBar value={progress} />
			</IonHeader>
			<IonContent>
				<div className="onboarding-main-div" style={{ textAlign: "center" }}>
					<div className="onboarding-title">
						<div style={{ fontWeight: "bold" }}>
							That's it! <br />
						</div>
						<div>
							Let's go to your squad chatroom and say hi
							<br />
						</div>
					</div>
					{squad ? (
						<React.Fragment>
							<div className="squad-data-div" style={{ margin: "10%", width: "80%", textAlign: "center", paddingTop: "10px" }}>
								<div id="squad-image">
									<img src={squad.image} alt="" style={{ height: "50%", width: "50%", borderRadius: "10px" }} />
								</div>
								<div style={{ textAlign: "center" }}>
									<div className="squad-slogan-div" style={{ width: "100%", fontStyle: "italic" }}>
										{squad.slogan}
									</div>
									<div className="squad-name-div" style={{ width: "100%", fontSize: "1.5em", fontWeight: "bold" }}>
										{squad.name}
									</div>
									<div style={{ width: "100%", fontSize: "1.5em", fontWeight: "bold" }}>
										{squad.tags &&
											squad.tags.map((tag, i) => {
												return (
													<IonChip key={`${i}`} outline="false" color="tertiary">
														<IonLabel>{tag.tag}</IonLabel>
													</IonChip>
												);
											})}
									</div>
								</div>
							</div>
						</React.Fragment>
					) : (
						""
					)}
				</div>
				<div className="onboarding-footer">
					<IonButton onClick={() => endOnboardingSayHello()} className="onboarding-advance-button">
						Let's do it! 👋
					</IonButton>
					<div style={{ marginBottom: "10%" }}>
						<div className="onboarding-text-area" style={{ textAlign: "center", paddingBottom: "15px" }}>
							Or explore other options:
						</div>
						<IonButton onClick={() => navToSquadCreate()} className="onboarding-advance-button" style={{ fontSize: "100%" }}>
							Create a squad 📢
						</IonButton>
						<br />
						<br />
						<IonButton onClick={() => endOnboarding()} className="onboarding-advance-button" style={{ fontSize: "100%" }}>
							Just explore 🔎
						</IonButton>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default EndOnboarding;
