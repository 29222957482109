import React from "react";
import ReactDOM from "react-dom";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./App";
import { AuthProvider } from "./contexts/GlobalState";
import { ErrorBoundary } from "react-error-boundary";
//import * as serviceWorker from './serviceWorker';
/*
Sentry.init({
	dsn: "https://e82ae3838d1e44ccbcd709c77a0df0a3@o484193.ingest.sentry.io/5537079",
	integrations: [new Integrations.BrowserTracing()],
	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: 1.0,
});
*/
function ErrorFallback({ error, resetErrorBoundary }) {
	return (
		<div role="alert">
			<p>Something went wrong:</p>
			<pre>{error.message}</pre>
			<button onClick={resetErrorBoundary}>Try again</button>
		</div>
	);
}

const myErrorHandler = (error: Error, info: { componentStack: string }) => {
	console.log("I FOUND AN ERROR!");
};

ReactDOM.render(
	<AuthProvider>
		<App />
	</AuthProvider>,
	document.getElementById("root")
);

defineCustomElements(window);
