import { API_URL, sports } from "../models/constants";
import { getIDToken } from "./AuthService";
import { sendSlackErrorNotification } from "./NotificationService";
import { getUserNameFromUserID, getUser } from "./UserService";
import { StorageService } from "../services/StorageService";

export function timeConverter(UNIX_timestamp) {
	var a = new Date(UNIX_timestamp * 1000);
	var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
	var year = a.getFullYear();
	var month = months[a.getMonth()];
	var date = a.getDate();
	var hour = a.getHours();
	var min = a.getMinutes();
	var sec = a.getSeconds();
	var time = date + " " + month + " " + year + " " + hour + ":" + min + ":" + sec;
	return time;
}

export const getBubbleSize = (messageText) => {
	if (!messageText){
		throw Error ("Message text was undefined")
	}
	var messageBubbleSize;
	const messageLength = messageText.length;
	switch (true) {
		case messageText.indexOf(":") === 0: // if it starts with a colon it's an emoji
			messageBubbleSize = 1;
			break;
		case messageLength === 0: // covers messages set to blank to show images
			messageBubbleSize = 9;
			break;
		case messageLength <= 2:
			messageBubbleSize = 2;
			break;
		case messageLength > 2 && messageLength <= 10:
			messageBubbleSize = 3;
			break;
		case messageLength > 10 && messageLength <= 15:
			messageBubbleSize = 4;
			break;
		case messageLength > 15 && messageLength <= 20:
			messageBubbleSize = 5;
			break;
		case messageLength > 20 && messageLength <= 25:
			messageBubbleSize = 6;
			break;
		case messageLength > 25 && messageLength <= 30:
			messageBubbleSize = 7;
			break;
		case messageLength > 30:
			messageBubbleSize = 8;
			break;
		default:
			messageBubbleSize = 8;
			break;
	}
	return messageBubbleSize;
};

export const convertCalsToCredits = (cals, userWeightInKG = 87) => {
	const iOSfactor = 1.75;
	console.log("Cals passed: " + cals);
	console.log("weight in KG passed: " + userWeightInKG);
	const credits = ((cals * iOSfactor) / userWeightInKG) * 100;
	console.log("Calculated credits: " + credits);
	return credits;
};

/**
 *
 * Returns a random value from the array that was passed
 * Array should be a simple one of just a set of values
 *
 * @param {*} array
 */
export const getRandomValueFromArray = (array) => {
	return array[Math.floor(Math.random() * array.length)];
};

export const convertStepsToCals = (steps, userWeightInKG) => {
	if (steps === undefined) {
		// for debug
		steps = 10000;
	}
	// Assumptions
	const stepsPerMile = 2000; //https://www.verywellfit.com/pedometer-steps-to-calories-converter-3882595
	const milesPerHour = 3;
	const walkingMETs = 3.3;
	// Calculations
	const timeWalking = steps / stepsPerMile / milesPerHour;
	const hoursWalking = Math.floor(steps / stepsPerMile / milesPerHour); // Should give just hours
	const minsWalking = Math.ceil((timeWalking - hoursWalking) * 60); // Should give fraction of mins
	const totalTimeWalking = hoursWalking * 60 + minsWalking;
	const cals = ((walkingMETs * 3.5 * userWeightInKG) / 200) * totalTimeWalking;
	console.log(
		"timewalking: " +
			timeWalking +
			"\n hours Walking: " +
			hoursWalking +
			"\n mins walking: " +
			minsWalking +
			"\n totalTimeWalking: " +
			totalTimeWalking +
			"\n cals: " +
			cals
	);
	return cals;
};

export const convertExerciseActivityToText = (userName, activityType, duration, durationUnit, points, dataType = "workout") => {
	switch (durationUnit) {
		case "sec":
		case "secs":
		case "s":
			duration = duration / 60;
			break;
		case "hour":
		case "hours":
		case "hr":
		case "h":
			duration = duration * 60;
			break;
		default:
			break;
	}
	durationUnit = "minutes";
	let workoutDescription;
	if (duration === 1) {
		durationUnit = "minute";
	}
	switch (activityType.toLowerCase()) {
		case "walking":
			workoutDescription = "walked for";
			break;
		case "running":
			workoutDescription = "ran for";
			break;
		case "flexibility":
			workoutDescription = "stretched for";
			break;
		case "cycling":
			workoutDescription = "cycled for";
			break;
		case "high intensity interval training":
			workoutDescription = "did a HIIT workout for";
			break;
		case "yoga":
			workoutDescription = "did yoga for";
			break;
		case "hiking":
			workoutDescription = "hiked for";
			break;
		case "swimming":
			workoutDescription = "swam for";
			break;
		default:
			workoutDescription = "did " + activityType +" for ";
			break;
	}
	if (sports.includes(activityType.toLowerCase())){
		workoutDescription = "played "+activityType.toLowerCase()+" for"
	}
	let displayText = userName + " " + workoutDescription + " " + Math.round(duration) + " " + durationUnit;
	if (activityType === "dailySteps"){
		displayText = userName + " took " + Math.round(duration).toLocaleString() +" steps today";
	}
	if (dataType === "steps"){
		displayText = userName + " took " + Math.round(duration).toLocaleString() +" steps";
	}
	return displayText;
};

export const convertExerciseActivityToFlavorText = (userName, points) => {
	let flavorText = userName + " earned " + Math.round(points).toLocaleString() + " points!";
	return flavorText;
};

export const convertSystemActivityToTextSquad = async (userName, activityArray) => {
	// If it's a squadActivity, userName will be squadName
	//console.log("System activity array: ",{activityArray});	
	let displayText 
	switch (activityArray.activityType) {
		case "squadJoinAccepted":
			let squadieName = await getUserNameFromUserID(activityArray.activityData.userId)
			displayText = squadieName + " was accepted into the squad! Show them a big \""+userName+"\" welcome by sending a message 🙂";
			break;
		case "squadMembershipRemoved":
			let squadName = await getUserNameFromUserID(activityArray.activityData.userId)
			displayText = squadName + " was removed from the squad";
			break;
		case "userLevelUp":
			displayText = userName + " leveled up to level " + activityArray.activityData.newLevel + "! :raised_hands: ";
			break;
		case "newUserGoal":
			displayText = userName + " was given a new goal for the week :dart: ";
			break;
		case "userGoalEvaluated":
			displayText = userName + " had their goal evaluated :1234:";
			break;
		case "competitionEvaluated":
			displayText = ":checkered_flag: Last week's competition is complete! Tap here to see the results";
			break;
		case "newSquadCompetition":
			displayText = ":triumph: :facepunch: Your squad competition for this week is here! Tap to see the matchup";
			break;
		case "squadGoalEvaluated":
			displayText = ":1234: Your scores from last week have been tallied! Tap here to see how your squad did last week";
			break;
		case "newSquadGoal":
			displayText = ":dart: Your squad goal has been calculated. Good luck this week!";
			break;
		case "dailySteps":
			displayText = userName + " took " + Math.round(activityArray.activityData.value).toLocaleString() + " steps today earning "+activityArray.activityData.points+" points"
			break;
		case "seasonRegistration":
			displayText = userName + " committed to the "+activityArray.activityData.seasonName+"! Show some love to them for making the commitment 🙌"
			break;
		default:
			displayText = userName + " did " + activityArray.activityType;
			break;
	}
	return displayText;
};

export const convertSystemActivityToTextUser = (activityArray) => {
	let feedBody = [{
		"displayText": "",
		"flavorText": ""
	}];
	switch (activityArray.activityType) {
		case "dailySteps":
		case "steps":
			feedBody.displayText = "You took " + Math.round(activityArray.activityData.value).toLocaleString() + " steps";
			feedBody.flavorText = convertExerciseActivityToFlavorText("You", activityArray.activityData.points);
			feedBody.icon = getIconFromExerciseActivity(activityArray.activityType);
			feedBody.component = "dailysteps"
			break;
		case "userLevelUp":
			feedBody.displayText = "You leveled up to level " + activityArray.activityData.newLevel + "! :raised_hands: ";
			feedBody.icon = ":up:"
			feedBody.component = "systemactivitydetails"
			break;
		case "newUserGoal":			
			feedBody.displayText = "You have a new goal for the week. :dart:";
			feedBody.flavorText = "Tap to accept or change it";
			feedBody.icon = ":dart:"
			feedBody.component = "newusergoal"
			break;
		case "userGoalEvaluated":
			feedBody.displayText = "Your week is complete! :1234:";
			feedBody.flavorText = "Tap to see how you did";
			feedBody.icon = ":checkered_flag:"
			feedBody.component = "endofweeksummary"
			break;
		case "goalOver80Percent":
			feedBody.displayText = "You're over 80% of the way to your goal. Almost there!";
			feedBody.icon = ":dash:"
			feedBody.component = "systemactivitydetails"
			break;
		case "squadJoinAccepted":
			feedBody.displayText = "Congrats! You've been accepted into the squad '"+activityArray.activityData.squadName+"'!";
			feedBody.flavorText = "You should greet your new squadmates";
			feedBody.icon = ":raised_hands:"
			feedBody.component = "systemactivitydetails"
			break;
		case "goalCompleted":
			feedBody.displayText = "YES! You completed your goal this week! Way to go!!";
			feedBody.icon = ":boom:"
			feedBody.component = "systemactivitydetails"
			break;
		case "squadMembershipQuit":
			feedBody.displayText = "You left the squad '"+activityArray.activityData.squadName+"'";
			feedBody.icon = ":x:"
			feedBody.component = "systemactivitydetails"
			break;
		default:
			feedBody.displayText = "You did " + activityArray.activityType;
			feedBody.component = "systemactivitydetails"
			break;
	}
	return feedBody;
};

export const getSystemActivityComponents = (activityArray) => {
	let displayComponents = [];
	switch (activityArray.activityType) {
		case "userLevelUp":
			displayComponents = {
				title: "Congrats on leveling up!",
				body: "You reached level " + activityArray.activityData.newLevel + "! You're really progressing nicely :thumbs_up:",
				image:
					"https://us.123rf.com/450wm/soifer/soifer1809/soifer180900217/110862178-stock-vector-level-up-neon-text-vector-level-up-neon-sign-design-template-modern-trend-design-night-neon-signboar.jpg?ver=6",
				buttons: [
					{
						name: "Got it",
						action: "activityfeed",
					},
					{
						name: "Cool!",
						action: "activityfeed",
					},
				],
			};			
			break;
		default:
			displayComponents["title"] = "Congrats!";
			displayComponents["body"] = "You did a thing";
			displayComponents["image"] =
				"https://www.temptationgifts.com/media/catalog/product/cache/c09c62f4d7c7812f632d8af59eec3417/p/i/pigment-happy-jackson-yay-you-totally-did-it-greeting-card-gf831b_1.jpg";
			break;
	}
	console.log("Display components: " + JSON.stringify(displayComponents));
	return displayComponents;
};

/**
 * 
 * Returns the page an activity type should link to
 * 
 * @param {string} activityType 
 */

export const getSystemActivitiesLink = (activityArray) => {
	//console.log("[getSystemActivitiesLink] Activity array received: "+JSON.stringify(activityArray));
	switch (activityArray.activityType) {
		case "newUserGoal":
			return "newusergoal/" + activityArray.id
		case "userGoalEvaluated":
			return "endofweeksummary/" + activityArray.id
		case "competitionEvaluated":
			return "competitioncompleted/" + activityArray.id
		case "newSquadCompetition":
			return "newsquadcompetition/" + activityArray.id
		case "squadGoalEvaluated":
			return "squadweekcompleted/" + activityArray.id
		default:
			return "systemactivitydetails/" + activityArray.id
	}
};

export const getBase64Image = async (img) => {
	var canvas = document.createElement("canvas");
	canvas.width = img.width;
	canvas.height = img.height;
	var ctx = canvas.getContext("2d");
	ctx.drawImage(img, 0, 0);
	var dataURL = canvas.toDataURL("image/png");
	return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

export const getIconFromExerciseActivity = (activity) =>{
	let activityType = activity.toLowerCase()
	// console.log("ActivityType: "+activityType);
	if (activityType.indexOf("walk") >=0 || activityType.indexOf("hik") >=0 || activityType.indexOf("dailysteps") >=0){
		return ":walking:"
	}else if(activityType.indexOf("jog") >=0 || activityType.indexOf("run") >=0){
		return ":runner:"
	}else if(activityType.indexOf("weight") >=0 || activityType.indexOf("trx") >=0 || activityType.indexOf("strength") >=0 || activityType.indexOf("pushup") >=0 || activityType.indexOf("push-up") >=0  || activityType.indexOf("pullup") >=0 || activityType.indexOf("pull-up") >=0 || activityType.indexOf("squat") >=0 || activityType.indexOf("wad") >=0 || activityType.indexOf("wod") >=0){
		return ":weight_lifter:"
	}else if(activityType.indexOf("cycling") >=0 || activityType.indexOf("bike") >=0){
		return ":bicyclist:"
	}else if(activityType.indexOf("soccer") >=0){
		return ":soccer:"
	}else if(activityType.indexOf("cardio") >=0 || activityType.indexOf("circuit") >=0 || activityType.indexOf("high intensity interval training") >=0 || activityType.indexOf("hiit") >=0 || activityType.indexOf("jump") >=0){
		return ":person_doing_cartwheel:"
	}else if(activityType.indexOf("yoga") >=0){
		return ":person_in_lotus_position:"
	}else if(activityType.indexOf("baseball") >=0 || activityType.indexOf("softball") >=0){
		return ":baseball:"
	}else if(activityType.indexOf("basketball") >=0){
		return ":basketball:"
	}else if(activityType.indexOf("tennis") >=0){
		return ":tennis:"
	}else if(activityType.indexOf("house") >=0 || activityType.indexOf("yard") >=0){
		return ":broom:"
	}else if(activityType.indexOf("swi") >=0){
		return ":swimmer:"
	}else if(activityType.indexOf("mma") >=0){
		return ":wrestlers:"
	}else if(activityType.indexOf("golf") >=0){
		return ":golfer:"
	}else if(activityType.indexOf("surf") >=0){
		return ":surfer:"
	}else if(activityType.indexOf("volley") >=0){
		return ":volleyball:"
	}else {
		return ":muscle:"
	}
	
}

/**
 * 
 * Object must have a "streak" property. Will add "emojiStreak" to object and return
 * 
 * @param {*} object 
 */

export const convertStreakToEmoji = (object) => {
	if (object.goalStreak && object.goalStreak > 0) {
		let emojiStreak = "";
		for (let i = 1; i <= object.goalStreak; i++) {
			emojiStreak = emojiStreak + "🔥";
		}
		object.emojiStreak = emojiStreak
	}
	if (object.streak && object.goalStstreakreak > 0) {
		let emojiStreak = "";
		for (let i = 1; i <= object.streak; i++) {
			emojiStreak = emojiStreak + "🔥";
		}
		object.emojiStreak = emojiStreak
	}
	return object
}

export const fetcher = async (endPoint) => {
	console.log("Fetching with endpoint "+ endPoint);
	let url = API_URL
	let idToken = await getIDToken().catch((error) => {
		throw Error("Error in getIDtoken: " + error);
	});
	await fetch(url + endPoint, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: idToken,
		},
	}).then(async (res) => {
		console.log(`${res.json()}`);
		return await res.json()})
}

export const validateResponse = async (response, url, body) =>{
	if (response.status !== 200) {
		sendSlackErrorNotification(response.status,url,"```"+JSON.stringify(body)+"```")
		throw Error("Error code: "+response.status+" Response: "+response)
	}
}

export const convertStringToMessageObjectArray = async (string) => {
	let messageArray = string.split("\n")
	let messageObjectArray = []
	for await (let message of messageArray) {
		// these properties are defined in BotService/sendBotMessage
		//let message = await replaceVariables(message)
		let messageObject = {
			messageText: message,
			imageUrl: null,
			contextID: null,
			command: null,
			buttons: null
		}
		messageObjectArray.push(messageObject)
	}
	return messageObjectArray
}

export const packageMessageObjectArray = async (fullMessageObject) => {
	let messageArray = fullMessageObject.messageText.split("\\n")
	let messageObjectArray = []
	for await (let message of messageArray) {
		// these properties are defined in BotService/sendBotMessage
		let messageObject = {
			messageText: message,
			imageUrl: null,
			buttons: null
		}
		messageObjectArray.push(messageObject)
	}
	// If there's an image, treat it as a new message
	if (fullMessageObject.imageURL !== null && fullMessageObject.imageURL !== "") {
		let messageObject = {
			messageText: "",
			imageUrl: fullMessageObject.imageURL,
			buttons:null
		}
		messageObjectArray.push(messageObject)
	}
	// If there are buttons, get the last message and add them to it
	if (fullMessageObject.buttons !== []){
		let lastMessage = messageObjectArray.pop()
		lastMessage.buttons = fullMessageObject.buttons
		messageObjectArray.push(lastMessage)
	}
	if (fullMessageObject.contextID !== null){
		let lastMessage = messageObjectArray.pop()
		lastMessage.contextID = fullMessageObject.contextID
		messageObjectArray.push(lastMessage)
	}
	if (fullMessageObject.command !== null){
		let lastMessage = messageObjectArray.pop()
		lastMessage.command = fullMessageObject.command
		messageObjectArray.push(lastMessage)
	}
	console.log({messageObjectArray});
	return messageObjectArray
}

export const replaceVariables = async (string) => {

}

export const getRandomBackgroundImage = async () =>{
	let numImages = 24
	let randomImage = Math.floor(Math.random() * numImages)
	return "https://fitsquad-ui-assets.s3.us-west-2.amazonaws.com/squaddie-images/"+randomImage+".jpg"
}

export const titleCase = (str) => {
	str = str.toLowerCase().split(" ");
	for (var i = 0; i < str.length; i++) {
		str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
	}
	return str.join(" ");
}


export const coachMarkCheck = async (setShowCoachMark, source) => {
	const storageService = new StorageService();
	let coachMarkShown = await storageService.getObject("coach-marks-shown");
	if (coachMarkShown === null || !coachMarkShown[source] || coachMarkShown[source] !== "true") {
		setShowCoachMark(true);
		if (coachMarkShown === null) {
			coachMarkShown = {};
		}
		coachMarkShown[source] = "true";
		await storageService.setObject("coach-marks-shown", coachMarkShown);
		return true
	} else {
		setShowCoachMark(false);
		return false
	}
};

export const getUserTimeZone = async (userObject = null) => {
	if (userObject === null) {
		userObject = JSON.parse(await getUser(null));
	}
	return userObject.timezone
}