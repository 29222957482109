import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonRow, IonLoading, IonCol, IonAvatar } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Emoji from "react-emoji-render";
import moment from "moment";
import "../../css/Home.css";
import "../../css/NewSquadCompetition.css";
import { getActivityDetails } from "../../services/ActivitiesService";
import { getSquad, getSquadGoal, getUsersInSquad } from "../../services/SquadService";

const NewSquadCompetition = ({ chatRoomObj, squadList }) => {
	const activityID = useParams().activityID;
	const [loading, setLoading] = useState(false);
	const [squadAGoalObject, setSquadAGoalObject] = useState();
	const [squadBGoalObject, setSquadBGoalObject] = useState();
	const [squadAObject, setSquadAObject] = useState();
	const [squadBObject, setSquadBObject] = useState();
	const [squadAMembers, setSquadAMembers] = useState();
	const [squadBMembers, setSquadBMembers] = useState();
	const [squadAHandicap, setsquadAHandicap] = useState();
	const [squadATitles, setSquadATitles] = useState({
		mvpID: null,
		fireID: null,
		fastID: null,
	});
	const [squadBTitles, setSquadBTitles] = useState({
		mvpID: null,
		fireID: null,
		fastID: null,
	});
	const [squadBHandicap, setsquadBHandicap] = useState();

	//let remainingSquadPoints;

	useEffect(() => {
		loadSquadData(activityID);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const loadSquadData = async (activityID) => {
		let competitionDetails = JSON.parse(await getActivityDetails(activityID));
		console.log({ competitionDetails });
		// Setup Squad A
		setSquadAObject(await getSquad(competitionDetails.activityData.squadIdA));
		let squadAGoal = await getSquadGoal(
			competitionDetails.activityData.squadIdA,
			moment(competitionDetails.activityData.startDate),
			moment(competitionDetails.activityData.endDate),
			true,
			false
		);

		// Setup Squad B
		setSquadBObject(await getSquad(competitionDetails.activityData.squadIdB));
		let squadBGoal = await getSquadGoal(
			competitionDetails.activityData.squadIdB,
			moment(competitionDetails.activityData.startDate),
			moment(competitionDetails.activityData.endDate),
			true,
			false
		);
		setLoading(true);

		//Setup Squad A Members
		let squadAMembers = await getUsersInSquad(competitionDetails.activityData.squadIdA, true, true);
		setSquadAMembers(squadAMembers);
		squadAMembers.sort((a, b) => (a.goalPoints > b.goalPoints ? -1 : 1));
		let mvpA = squadAMembers[0].id;
		squadAMembers.sort((a, b) => (a.user.goalStreak > b.user.goalStreak ? -1 : 1));
		let fireA = squadAMembers[0].id;
		squadAMembers.sort((a, b) => (a.goalPrediction > b.goalPrediction ? -1 : 1));
		let fastA = squadAMembers[0].id;
		setSquadATitles({
			mvpID: mvpA,
			fireID: fireA,
			fastID: fastA,
		});

		//Setup Squad A Members
		let squadBMembers = await getUsersInSquad(competitionDetails.activityData.squadIdB, true, true);
		setSquadBMembers(squadBMembers);
		console.log("squad B Members: ", { squadAMembers });
		squadBMembers.sort((a, b) => (a.goalPoints > b.goalPoints ? -1 : 1));
		let mvpB = squadBMembers[0].id;
		squadBMembers.sort((a, b) => (a.user.goalStreak > b.user.goalStreak ? -1 : 1));
		let fireB = squadBMembers[0].id;
		squadBMembers.sort((a, b) => (a.goalPrediction > b.goalPrediction ? -1 : 1));
		let fastB = squadBMembers[0].id;
		setSquadBTitles({
			mvpID: mvpB,
			fireID: fireB,
			fastID: fastB,
		});

		// Setup Handicap
		let aHandicap, bHandicap;
		if (squadAGoal.goalPoints > squadBGoal.goalPoints) {
			aHandicap = "n/a";
			bHandicap = Math.round((squadAGoal.goalPoints / squadBGoal.goalPoints) * 100) / 100;
			squadBGoal.goalPoints = squadAGoal.goalPoints;
			squadBGoal.goalProgress = Math.round(squadBGoal.goalProgress * bHandicap);
		} else {
			bHandicap = "n/a";
			aHandicap = Math.round((squadAGoal.goalPoints / squadBGoal.goalPoints) * 100) / 100;
			squadAGoal.goalPoints = squadBGoal.goalPoints;
			squadAGoal.goalProgress = Math.round(squadAGoal.goalProgress * aHandicap);
		}
		setSquadAGoalObject(squadAGoal);
		setSquadBGoalObject(squadBGoal);
		setsquadAHandicap(aHandicap);
		setsquadBHandicap(bHandicap);
		setLoading(false);
	};

	return (
		<IonPage>
			<IonContent>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton text="" color="light" />
						</IonButtons>
						<IonTitle>The Matchup</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonLoading isOpen={loading} onDidDismiss={() => setLoading(false)} message={"Loading some amazing people"} duration={10000} />
				<div id="top-part" className="competition-scoreboard-header">
					<div className="competition-squad-data-holder">
						<IonRow style={{ marginLeft: "20px" }}>
							{squadAObject ? (
								<IonCol className="squad-a-image-holder" size="4">
									<IonAvatar className="squad-image">
										<img src={squadAObject.image} alt="" />
									</IonAvatar>
									<span className="competition-squad-name">{squadAObject.name}</span>
									<div className="competition-squad-slogan">"{squadAObject.slogan}"</div>
								</IonCol>
							) : (
								""
							)}
							<IonCol className="vs-image-holder" size="3">
								<img src="assets/images/vs.png" alt="" />
							</IonCol>
							{squadBObject ? (
								<IonCol className="squad-b-image-holder" size="4">
									<IonAvatar className="squad-image">
										<img src={squadBObject.image} alt="" />
									</IonAvatar>
									<span className="competition-squad-name">{squadBObject.name}</span>
									<div className="competition-squad-slogan">"{squadBObject.slogan}"</div>
								</IonCol>
							) : (
								""
							)}
						</IonRow>
					</div>
				</div>
				<div>
					<div id="numbers-part" className="competition-goal-holder">
						<IonRow style={{ marginLeft: "20px" }}>
							{squadAGoalObject ? (
								<IonCol className="" size="4">
									<span className="competition-goals">
										Goal <br />
									</span>
									{squadAGoalObject.goalPoints}
									<br />
									<br />
									<span className="competition-goals">
										Current <br />
									</span>
									{squadAGoalObject.goalProgress}
									<br />
									<br />
									<span className="competition-goals">Handicap</span> <br />
									{squadAHandicap}
								</IonCol>
							) : (
								""
							)}
							<IonCol className="points-border" size="3"></IonCol>
							{squadBGoalObject ? (
								<IonCol size="4">
									<span className="competition-goals">
										Goal <br />
									</span>
									{squadBGoalObject.goalPoints}
									<br />
									<br />
									<span className="competition-goals">
										Current <br />
									</span>
									{squadBGoalObject.goalProgress}
									<br />
									<br />
									<span className="competition-goals">Handicap</span> <br />
									{squadBHandicap}
								</IonCol>
							) : (
								""
							)}
						</IonRow>
					</div>
					<div id="projectedMVPs"></div>
					<div className="competition-squad-team-holder">
						<div className="left-column-teammates">
							{squadAMembers &&
								squadATitles &&
								squadAMembers.map((member, i) => {
									return (
										<IonCol className="squad-a-image-holder" size="4" key={`${i}`}>
											{squadATitles.mvpID && squadATitles.mvpID === member.id ? (
												<div className="projected-mvp">
													<Emoji text=":trophy: Proj. MVP" />
												</div>
											) : (
												<div></div>
											)}
											{squadATitles.fireID && squadATitles.fireID === member.id ? (
												<div className="most-on-fire">
													<Emoji text=":fire: Most On Fire" />
												</div>
											) : (
												<div></div>
											)}
											{squadATitles.fastID && squadATitles.fastID === member.id ? (
												<div className="fastest-start">
													<Emoji text=":dash: Fastest Start" />
												</div>
											) : (
												<div></div>
											)}
											<IonAvatar className="member-image">
												<img className="user-avatar" src={member.user.avatar} alt="" />
											</IonAvatar>
											<span className="competition-squad-name">{member.user.userName}</span>
											<br />
											<span className="competition-squad-name">
												{member.goalProgress}/{member.goalPoints}
												<br /> {Math.round((member.goalProgress / member.goalPoints) * 100)}%{" "}
											</span>
											<br />
										</IonCol>
									);
								})}
						</div>
						<div className="right-column-teammates">
							{squadBMembers &&
								squadBTitles &&
								squadBMembers.map((member, i) => {
									return (
										<IonCol className="squad-b-image-holder" size="4" key={`${i}`}>
											{squadBTitles.mvpID && squadBTitles.mvpID === member.id ? (
												<div className="projected-mvp">
													<Emoji text=":trophy: Proj. MVP" />
												</div>
											) : (
												<div></div>
											)}
											{squadBTitles.fireID && squadBTitles.fireID === member.id ? (
												<div className="most-on-fire">
													<Emoji text=":fire: Most On Fire" />
												</div>
											) : (
												<div></div>
											)}
											{squadBTitles.fastID && squadBTitles.fastID === member.id ? (
												<div className="fastest-start">
													<Emoji text=":dash: Fastest Start" />
												</div>
											) : (
												<div></div>
											)}
											<IonAvatar className="member-image">
												<img className="user-avatar" src={member.user.avatar} alt="" />
											</IonAvatar>
											<span className="competition-squad-name">{member.user.userName}</span>
											<br />
											<span className="competition-squad-name">
												{member.goalProgress}/{member.goalPoints}
												<br /> {Math.round((member.goalProgress / member.goalPoints) * 100)}%{" "}
											</span>
											<br />
										</IonCol>
									);
								})}
						</div>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default NewSquadCompetition;
