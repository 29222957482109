import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButton,
	IonCardContent,
	IonButtons,
	IonBackButton,
	IonCard,
	IonSpinner,
	useIonViewDidEnter,
	IonRow,
	IonCol,
	IonRefresher,
	IonRefresherContent,
} from "@ionic/react";
import React, { useEffect, useState, useContext } from "react";
import "../../css/Home.css";
import AuthContext from "../../contexts/GlobalState";
import { Purchases } from "@awesome-cordova-plugins/purchases";
import moment from "moment";
import { sendSlackErrorNotification } from "../../services/NotificationService";
import JSConfetti from "js-confetti";

const PurchaseTesting = () => {
	const [buttons, setButtons] = useState([]);
	const { userState, subscriptionInfo, setSubscriptionInfo, updateUserState, subscriptionOfferings } = useContext(AuthContext);

	useEffect(() => {
		// console.log("[Purchases] Customer Info useEffect:");
		// console.log(JSON.stringify(subscriptionInfo));
	}, [subscriptionInfo]);

	const purchasePackage = async (packageToPurchase) => {
		// console.log("[Purchases] Package to purchase:");
		// console.log(JSON.stringify(packageToPurchase));

		await Purchases.purchasePackage(packageToPurchase)
			.then(async () => {
				let customerInfo = await Purchases.getPurchaserInfo();
				// console.log("[Purchases] Customer Info Object:");
				// console.log(JSON.stringify(customerInfo));
				setSubscriptionInfo(customerInfo);
			})
			.catch((error, userCancelled) => {
				// console.log("[Purchases] Error: ");
				// console.log(JSON.stringify(error));

				// console.log("[Purchases] userCancelled: ");
				// console.log(JSON.stringify(userCancelled));
				alert("Error processing payment: " + JSON.stringify(error));
				let errorData = "Error: " + JSON.stringify(error) + "\n UserCancelled: " + JSON.stringify(userCancelled);
				sendSlackErrorNotification("Error purchasing subscription", "Purchase Component", errorData);
			});
	};

	const refresh = async (event) => {
		let customerInfo = await Purchases.getPurchaserInfo();
		console.log("[Purchases] Customer Info Refresh:");
		console.log(JSON.stringify(customerInfo));
		setSubscriptionInfo(customerInfo);
		if (event) {
			event.detail.complete();
		}
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
					<IonTitle>Purchase Testing</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent className="background">
				<div className="onboarding-main-div">
					<div className="onboarding-title">Choose your reward options</div>
					<IonRefresher slot="fixed" onIonRefresh={refresh}>
						<IonRefresherContent refreshingText="Updating Squad Data"></IonRefresherContent>
					</IonRefresher>
					{subscriptionInfo !== undefined && Object.keys(subscriptionInfo.entitlements.active).length > 0 ? (
						<IonCard className={`${"selected-pricing-option"}`}>
							<div className="pricing-tier-flag" style={{ backgroundColor: "gold" }}>
								Current Subscription
							</div>
							<IonRow>
								<IonCol size="5">Purchase Date:</IonCol>
								<IonCol size="7">{moment(subscriptionInfo.entitlements.active.premium.latestPurchaseDate).format("MMMM Do YYYY, h:mm:ss a")}</IonCol>
							</IonRow>
							<IonRow>
								<IonCol size="5">Expiration Date:</IonCol>
								<IonCol size="7">{moment(subscriptionInfo.entitlements.active.premium.expirationDate).format("MMMM Do YYYY, h:mm:ss a")}</IonCol>
							</IonRow>
							<IonRow>
								<IonCol size="5">Will Auto-Renew?</IonCol>
								<IonCol size="7">{JSON.stringify(subscriptionInfo.entitlements.active.premium.willRenew)}</IonCol>
							</IonRow>
						</IonCard>
					) : (
						""
					)}

					{subscriptionInfo !== undefined && Object.keys(subscriptionInfo.entitlements.all).length > 0 ? (
						<IonCard className={`${"selected-pricing-option"}`}>
							<div className="pricing-tier-flag" style={{ backgroundColor: "gold" }}>
								Previous Subscriptions
							</div>
							<IonRow>
								<IonCol size="5">Original Purchase Date:</IonCol>
								<IonCol size="7">{moment(subscriptionInfo.entitlements.all.premium.originalPurchaseDate).format("MMMM Do YYYY, h:mm:ss a")}</IonCol>
							</IonRow>
							<IonRow>
								<IonCol size="5">Latest Purchase Date:</IonCol>
								<IonCol size="7">{moment(subscriptionInfo.entitlements.all.premium.latestPurchaseDate).format("MMMM Do YYYY, h:mm:ss a")}</IonCol>
							</IonRow>
							<IonRow>
								<IonCol size="5">Unsubscribe Date:</IonCol>
								<IonCol size="7">{moment(subscriptionInfo.entitlements.all.premium.unsubscribeDetectedAt).format("MMMM Do YYYY, h:mm:ss a")}</IonCol>
							</IonRow>
							<IonRow>
								<IonCol size="5">Expiration Date:</IonCol>
								<IonCol size="7">{moment(subscriptionInfo.entitlements.all.premium.expirationDate).format("MMMM Do YYYY, h:mm:ss a")}</IonCol>
							</IonRow>
							<IonRow>
								<IonCol size="5">Will Auto-Renew?</IonCol>
								<IonCol size="7">{JSON.stringify(subscriptionInfo.entitlements.all.premium.willRenew)}</IonCol>
							</IonRow>
						</IonCard>
					) : (
						""
					)}

					<IonCard
						onClick={() => {
							purchasePackage(subscriptionOfferings.availablePackages[0]);
						}}
						className={`${"selected-pricing-option"}`}
					>
						<div className="pricing-tier-flag" style={{ backgroundColor: "gold" }}>
							GOLD TIER
						</div>
						<div className="most-popular-flag ">MOST POPULAR</div>
						{subscriptionOfferings && subscriptionOfferings.availablePackages[0] ? (
							<React.Fragment>
								{subscriptionInfo && subscriptionInfo.activeSubscriptions.length > 0 ? (
									<div className="pricing-text">Currently subscribed</div>
								) : (
									<div className="pricing-text">
										{subscriptionOfferings.availablePackages[0].product.price_string} {subscriptionOfferings.availablePackages[0].product.currency_code}/
										{subscriptionOfferings.availablePackages[0].packageType === "MONTHLY" ? "mo" : ""}
									</div>
								)}
							</React.Fragment>
						) : (
							<React.Fragment>
								<div className="pricing-text">
									<IonSpinner />
								</div>
							</React.Fragment>
						)}
					</IonCard>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default PurchaseTesting;
