/* eslint-disable react-hooks/exhaustive-deps */
import { IonCol, IonRow } from "@ionic/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Emoji from "react-emoji-render";
import "../../css/Home.css";
import "../../css/Seasons.css";
import { sampleUserSeason } from "../../models/sampleSeason";

const SeasonsProgressComponent = ({ seasonObject, userSeasonObject }) => {
	const seasonGoal = 15;
	const weeksInSeason = 10;
	const [weeksRemaining, setWeeksRemaining] = useState("-");
	const [projectedPoints, setProjectedPoints] = useState("TBD");
	useEffect(() => {
		let wksRemaining;
		let weeksCompleted;
		if (userSeasonObject.weeklyResults && Object.keys(userSeasonObject.weeklyResults).length > 0) {
			weeksCompleted = Object.keys(userSeasonObject.weeklyResults).length;
		} else {
			weeksCompleted = 0;
		}
		if (weeksCompleted !== undefined) {
			wksRemaining = weeksInSeason - weeksCompleted;
		} else {
			wksRemaining = weeksInSeason;
		}
		setWeeksRemaining(wksRemaining);
		if (wksRemaining <= 7) {
			let avgPtsPerWeek = userSeasonObject.totalPoints / weeksCompleted;
			let projPoints = Math.floor(avgPtsPerWeek * wksRemaining) + userSeasonObject.totalPoints;
			setProjectedPoints(projPoints);
		}
	}, []);
	return (
		<React.Fragment>
			<div style={{ marginLeft: "16px", textAlign: "left" }}>
				<div className="section-title" style={{ paddingLeft: "0px" }}>
					Season Progress
				</div>
				<div className="season-date">Ends {moment(seasonObject.endDate).format("dddd, MMMM Do YYYY")}</div>
				<IonRow style={{ paddingTop: "10px" }}>
					{[...Array(10)].map((week, i) => {
						let weeklySeasonPoints, backgroundColor, textColor;
						//console.log({userSeasonObject});
						if (userSeasonObject.weeklyResults && userSeasonObject.weeklyResults[i] !== undefined) {
							weeklySeasonPoints = userSeasonObject.weeklyResults[i].totalPoints;
						} else {
							weeklySeasonPoints = "-";
						}
						switch (weeklySeasonPoints) {
							case 0:
								backgroundColor = "black";
								textColor = "white";
								break;
							case 1:
								backgroundColor = "lightGreen";
								textColor = "black";
								break;
							case 2:
								backgroundColor = "green";
								textColor = "white";
								break;
							default:
								backgroundColor = "default";
								textColor = "default";
								break;
						}
						return (
							<React.Fragment key={i}>
								<IonCol className="seasons-weekly-points-col" style={{ backgroundColor: `${backgroundColor}`, color: `${textColor}` }} size="1">
									<span className="seasons-weekly-points">{weeklySeasonPoints}</span>
								</IonCol>
							</React.Fragment>
						);
					})}
				</IonRow>
				<div>
					<IonRow>
						<IonCol className="seasons-data-point" size="6">
							Season Points:
						</IonCol>
						<IonCol style={{ textAlign: "center" }} size="6">
							{userSeasonObject.totalPoints >= seasonGoal ? (
								<span>
									<img src="assets/images/trophy_transparent.gif" alt="" style={{ height: "16px", position: "relative" }} /> VICTORY{" "}
									<img src="assets/images/trophy_transparent.gif" alt="" style={{ height: "16px", position: "relative" }} />
								</span>
							) : (
								<span>{userSeasonObject.totalPoints}</span>
							)}
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol className="seasons-data-point" size="6">
							Weeks Remaining:
						</IonCol>
						<IonCol style={{ textAlign: "center" }} size="6">
							{weeksRemaining}
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol className="seasons-data-point" size="6">
							Projected:
						</IonCol>
						<IonCol style={{ textAlign: "center" }} size="6">
							{projectedPoints}
						</IonCol>
					</IonRow>
				</div>
			</div>
		</React.Fragment>
	);
};

export default SeasonsProgressComponent;
