import moment from "moment";
import { addActivity, convertDurationToMinutes, getSquadActivities } from "./ActivitiesService";
import { getMessageFromDatabase } from "./BotService";
import { getRandomGifFromSearch } from "./GifService";
import { getSquadGoal, getUserSquadIDs, getSquad } from "./SquadService";
import { getGoal } from "./PointsAndGoalsService";
import { StorageService } from "../services/StorageService";
import { getUserID, getUserNameFromUserID } from "./UserService";
import { addReaction } from "./FeedService";
const storageService = new StorageService();

export default class botFunctions {
	// The things that go in here are functions for the bot to execute,
	// Like buttons or actions from DF
	async addReaction(button) {
		let msgResponse = {};
		if (button.text.toLowerCase() !== "no thanks") {
			console.log("Add reaction button obj: ", { button });
			console.log("Adding " + button.text + " to id " + button.param.id);
			addReaction(button.param.id, button.text);
			msgResponse = {
				messageText: "Love sent. Thanks for being a good squad member! :+1:",
			};
		} else {
			msgResponse = {
				messageText: "Ok I won't send them any love.",
			};
		}
		return msgResponse;
	}

	/*
	async competitionProgressUpdate () {
		let dow = moment().format('ddd')
		let responseText = ""
		if (dow !== "Mon"){
			let squadIDs = await getUserSquadIDs()
			for (let squadID of squadIDs){
				let squad = await getSquad(squadID, null, null, false)
				let squadGoal = await getSquadGoal(squadID, null, null, true, true)					
				let squadPercent = Math.round((squadGoal.goalProgress / squadGoal.goalPoints)*100)					
				responseText += "\\nYour squad '"+squad.name+"' is now "+squadPercent+"% of the way to hitting their goal"
			}				
		}
		return responseText
	}
	*/
	async logActivity(logWorkoutObject) {
		let points;
		let msgResponse = {};
		switch (logWorkoutObject.intensity) {
			case "low":
				points = logWorkoutObject.duration;
				logWorkoutObject.calories = logWorkoutObject.duration;
				break;
			case "moderate":
				points = Math.round(logWorkoutObject.duration * 1.5);
				logWorkoutObject.calories = logWorkoutObject.duration * 7;
				break;
			case "high":
				points = Math.round(logWorkoutObject.duration * 2);
				logWorkoutObject.calories = logWorkoutObject.duration * 11;
				break;
			case "cancel":
				points = null;
				break;
			default:
				points = logWorkoutObject.duration;
				break;
		}
		if (points === null) {
			let message_query = "_dialog.cancel";
			let message_context_id = 900;
			msgResponse.messageText = await getMessageFromDatabase(null, message_query, message_context_id);
		} else if (points > 0) {
			// TODO: change addActivity to not require JSON.parse
			let addActivityResponse = JSON.parse(await addActivity(logWorkoutObject));
			msgResponse.messageText = "Awesome you earned " + points + " points and this activity has been added to your feed. Hopefully it looked like this";
			msgResponse.type = "LOG_WORKOUT";
			//msgResponse.messageText += await this.userProgressUpdate();
			//msgResponse.messageText += await this.squadProgressUpdate();
			//msgResponse += await this.giveLoveToSquaddie();
			msgResponse.imageUrl = await getRandomGifFromSearch(logWorkoutObject.activity);
		} else {
			msgResponse.messageText = "Hmm. Something went wrong. Here is your activity array: " + JSON.stringify(logWorkoutObject);
		}
		return msgResponse;
	}
	async setIntensity(button) {
		console.log({ button });
		button.param.intensity = button.text.toLowerCase();
		let msgResponse = await this.logActivity(button.param);
		if (!msgResponse.buttons) {
			msgResponse.buttons = null;
		} else {
			console.log("Msgresponse: ", { msgResponse });
		}
		return msgResponse;
	}
	async logworkoutmets(msgResponse) {
		// Parse JSON into variables
		// TODO: Handle reps by conv
		// TODO: Handle different numbers of activities and durations and intensities
		let logWorkoutObject = {};
		let activityParameters = msgResponse.dialogFlowResponse.queryResult.parameters;
		console.log("Activity Parameters: " + JSON.stringify(activityParameters));
		logWorkoutObject.distance = null;
		logWorkoutObject.weight = null;
		logWorkoutObject.distanceUnit = null;
		logWorkoutObject.weightUnit = null;
		logWorkoutObject.repCount =
			activityParameters["number"].length > 0 && activityParameters["number"][0]["amount"] === undefined ? null : activityParameters["number"][0];
		logWorkoutObject.duration =
			activityParameters["duration"].length > 0 && activityParameters["duration"][0]["amount"] === undefined
				? null
				: activityParameters["duration"][0]["amount"];
		logWorkoutObject.unit =
			activityParameters["duration"].length > 0 && activityParameters["duration"][0]["unit"] === undefined ? null : activityParameters["duration"][0]["unit"];
		logWorkoutObject.intensity =
			activityParameters["intensity"].length > 0 && activityParameters["intensity"][0] === undefined ? "low" : activityParameters["intensity"][0];
		logWorkoutObject.activity = activityParameters.exercise_activities === undefined ? null : activityParameters.exercise_activities.join(" ");
		if (activityParameters["unit-length"] && activityParameters["unit-length"].length > 0) {
			logWorkoutObject.distance = activityParameters["unit-length"][0]["amount"];
			logWorkoutObject.distanceUnit = activityParameters["unit-length"][0]["unit"];
		}
		if (activityParameters["unit-weight"] && activityParameters["unit-weight"].length > 0) {
			logWorkoutObject.weight = activityParameters["unit-weight"][0]["amount"];
			logWorkoutObject.weightUnit = activityParameters["unit-weight"][0]["unit"];
		}
		if (activityParameters.date !== "") {
			console.log("Theres a date: " + activityParameters.date);

			// We do the below because DF defaults dates to the future
			if (moment(activityParameters.date) > moment()) {
				console.log("Its in the future");
				console.log('moment(activityParameters.date).format("YYYY-MM-DD") ' + moment(activityParameters.date).format("YYYY-MM-DD"));
				console.log('moment().add(1,"year").format("YYYY-MM-DD") ' + moment().add(1, "year").format("YYYY-MM-DD"));
				if (moment(activityParameters.date).format("W") === moment().format("W")) {
					logWorkoutObject.date = moment(activityParameters.date).subtract(1, "week").format();
				} else if (moment(activityParameters.date).format("YYYY") === moment().add(1, "year").format("YYYY")) {
					// it logged it for next year :/, but NOT later this week
					console.log("A year in the future");
					logWorkoutObject.date = moment(activityParameters.date).subtract(1, "year").format();
				} else {
					console.log("Its in the future, but not sure how...");
					logWorkoutObject.date = activityParameters.date;
				}
			} else {
				// The date is good
				console.log("Its all good");
				logWorkoutObject.date = activityParameters.date;
			}
		} else {
			console.log("No date");
			logWorkoutObject.date = moment().format();
		}
		let points;
		logWorkoutObject.duration = await convertDurationToMinutes(logWorkoutObject);
		logWorkoutObject.unit = "m";
		console.log("Log workout object: ", logWorkoutObject);
		console.log("Log workout object date: " + logWorkoutObject.date);

		let askForIntensity = requireIntensity(logWorkoutObject);
		if (askForIntensity === true) {
			msgResponse.messageText = "What was the intensity of your " + logWorkoutObject.activity + "?";
			msgResponse.buttons = [
				{
					id: 1,
					text: "Low",
					context: "logWorkoutIntensity",
					function: "setIntensity",
					param: logWorkoutObject,
				},
				{
					id: 2,
					text: "Moderate",
					context: "logWorkoutIntensity",
					function: "setIntensity",
					param: logWorkoutObject,
				},
				{
					id: 3,
					text: "High",
					context: "logWorkoutIntensity",
					function: "setIntensity",
					param: logWorkoutObject,
				},
				{
					id: 4,
					text: "Cancel",
					context: "logWorkoutIntensity",
					function: "setIntensity",
					param: logWorkoutObject,
				},
			];
			return msgResponse;
		} else {
			switch (logWorkoutObject.intensity) {
				case "low":
					points = logWorkoutObject.duration;
					logWorkoutObject.calories = logWorkoutObject.duration;
					break;
				case "moderate":
					points = Math.round(logWorkoutObject.duration * 1.5);
					logWorkoutObject.calories = logWorkoutObject.duration * 7;
					break;
				case "high":
					points = Math.round(logWorkoutObject.duration * 2);
					logWorkoutObject.calories = logWorkoutObject.duration * 11;
					break;
				default:
					points = logWorkoutObject.duration;
					break;
			}
			if (points > 0) {
				let addActivityResponse = JSON.parse(await addActivity(logWorkoutObject));
				msgResponse.messageText = "Awesome. You earned " + points + " points and this activity has been added to your feed. Hopefully it looked like this: ";
				msgResponse.type = "LOG_WORKOUT";
				msgResponse.imageUrl = await getRandomGifFromSearch(logWorkoutObject.activity);
				//msgResponse.messageText += await this.userProgressUpdate();
				//msgResponse.messageText += await this.squadProgressUpdate();
				//msgResponse += await this.giveLoveToSquaddie();
			} else {
				msgResponse.messageText = "Hmm. Something went wrong. Here is your activity array: " + JSON.stringify(logWorkoutObject);
			}
			console.log("msgResponse: ", { msgResponse });
			return msgResponse;
		}
	}
	async smalltalkagent(msgResponse) {
		let m_q = msgResponse.dialogFlowResponse.queryResult.parameters.type;
		let message_query = "_" + m_q;
		let message_context_id = 900;
		msgResponse.messageText = await getMessageFromDatabase(null, message_query, message_context_id);
		return msgResponse;
	}
	async processfreetextresponse(msgResponse) {
		let message_context_id = null;
		let message_query = null;
		let message_id = null;
		if (msgResponse.dialogFlowResponse.queryResult.parameters.message_context_id) {
			message_context_id = msgResponse.dialogFlowResponse.queryResult.parameters.message_context_id;
		}
		if (msgResponse.dialogFlowResponse.queryResult.parameters.message_context) {
			message_context_id = msgResponse.dialogFlowResponse.queryResult.parameters.message_context;
		}
		if (msgResponse.dialogFlowResponse.queryResult.parameters.message_query) {
			message_query = msgResponse.dialogFlowResponse.queryResult.parameters.message_query;
		}
		if (msgResponse.dialogFlowResponse.queryResult.parameters.message_id) {
			message_id = msgResponse.dialogFlowResponse.queryResult.parameters.message_id;
		}
		console.log({ message_context_id });
		console.log({ message_query });
		console.log({ message_id });
		msgResponse.messageText = await getMessageFromDatabase(message_id, message_query, message_context_id);
		return msgResponse;
	}
}

export const requireIntensity = (logWorkoutObject) => {
	if (logWorkoutObject.intensity && logWorkoutObject.intensity.length > 0) {
		return false;
	} else {
		return true;
	}
};

const giveLoveToSquaddie = async () => {
	// get recent activities
	// find one in last 24 hours that doesn't have a reaction
	// present it
	let messagingRecords = await storageService.getObject("messaging-records");
	if (messagingRecords !== null && messagingRecords.squaddieLove) {
		if (moment() < moment(messagingRecords.squaddieLove).add(24, "hours")) console.log("Love asked in last 24 hours. Skipping");
		return null;
	}
	let squadIDs = await getUserSquadIDs();
	let time24HoursAgo = moment().subtract(48, "hours");
	let activityFound = {};
	let msgResponse = {};
	let userID = await getUserID();
	for (let squadID of squadIDs) {
		await getSquadActivities(squadID, 20, 0, null, time24HoursAgo, moment());
		let tempSquadActivitiesArray = await storageService.getObject("squad-activities-" + squadID);
		// Find an activity that is of type workout, has 0 reactions and occurred in the last 24 hours
		activityFound = tempSquadActivitiesArray.filter(
			(activity) =>
				//TODO: Return activities that haven't been reacted to by THIS user
				activity.activityData.dataType === "workout" &&
				activity.reactionCount === 0 &&
				moment(activity.createdAt) > time24HoursAgo &&
				activity.userId !== userID
		);
	}
	//console.log("Activity: ", { activityFound });
	if (activityFound && activityFound[0]) {
		//console.log("activityFound: ", activityFound[0]);
		let userName = await getUserNameFromUserID(activityFound[0].userId);
		//TODO: Pull message from DB
		msgResponse.messageText =
			"Would you mind showing " + userName + " some love for their recent " + activityFound[0].activityType + " activity? I'm sure they'd appreciate it!";
		msgResponse.buttons = [
			{
				id: 1,
				text: ":+1:",
				context: "giveLoveToSquaddie",
				function: "addReaction",
				param: activityFound[0],
			},
			{
				id: 2,
				text: ":raised_hands:",
				context: "giveLoveToSquaddie",
				function: "addReaction",
				param: activityFound[0],
			},
			{
				id: 3,
				text: ":muscle:",
				context: "giveLoveToSquaddie",
				function: "showLove",
				param: activityFound[0],
			},
			{
				id: 4,
				text: ":taco:",
				context: "giveLoveToSquaddie",
				function: "addReaction",
				param: activityFound[0],
			},
			{
				id: 5,
				text: ":poop:",
				context: "giveLoveToSquaddie",
				function: "addReaction",
				param: activityFound[0],
			},
			{
				id: 6,
				text: "No thanks",
				context: "giveLoveToSquaddie",
				function: "addReaction",
				param: activityFound[0],
			},
		];
		console.log("msgResponse activityFound: ", { msgResponse });
		if (messagingRecords === null) messagingRecords = {};
		messagingRecords.squaddieLove = moment().format();
		storageService.setObject("messaging-records", messagingRecords);
		return msgResponse;
	} else {
		// no activity fits the criteria...
		return null;
	}
};

const userProgressUpdate = async () => {
	let goal = await getGoal(null, true);
	let userPercent = Math.round((goal.goalProgress / goal.goalPoints) * 100) + "%";
	let msgResponse = {};
	if (userPercent > 100) {
		msgResponse.messageText =
			"You've surpassed your goal of " + goal.goalPoints + " points this week! You're now at " + userPercent + " of your tally. Way to go!";
	} else {
		msgResponse.messageText = "You are now " + userPercent + " of the way to your goal of " + goal.goalPoints + " points. Great progress!";
	}
	return msgResponse;
};
const squadProgressUpdate = async () => {
	let dow = moment().format("ddd");
	let msgResponse = {};
	msgResponse.messageText = "";
	if (dow !== "Mon") {
		let squadIDs = await getUserSquadIDs();
		for (let squadID of squadIDs) {
			let squad = await getSquad(squadID, null, null, false);
			let squadGoal = await getSquadGoal(squadID, null, null, true, true);
			let squadPercent = Math.round((squadGoal.goalProgress / squadGoal.goalPoints) * 100);
			msgResponse.messageText += "Your squad '" + squad.name + "' is now " + squadPercent + "% of the way to hitting their goal";
		}
	}
	return msgResponse;
};

export const conversationEngine = async (messageObject) => {
	//TODO: Move this to DB
	let message;
	switch (messageObject.type) {
		case "LOG_WORKOUT":
			let coinFlip = Math.random();
			console.log("First Coin Flip: " + coinFlip);
			if (coinFlip >= 0.5) {
				console.log("Triggering love for squaddie");
				message = await giveLoveToSquaddie();
				message.btw = true;
			} else if (coinFlip >= 0.25) {
				console.log("Triggering user progress update");
				message = await userProgressUpdate();
				message.btw = false;
			} else {
				console.log("Triggering squad progress update");
				message = await squadProgressUpdate();
				message.btw = false;
			}
			if (message === null) {
				console.log("no squaddie to give love to");
				let coinFlip = Math.random();
				console.log("Second Coin Flip: " + coinFlip);
				if (coinFlip >= 0.5) {
					console.log("Triggering user progress update");
					message = await userProgressUpdate();
					message.btw = false;
				} else {
					console.log("Triggering squad progress update");
					message = await squadProgressUpdate();
					message.btw = false;
				}
			}
			break;
		default:
			break;
	}
	console.log("from engine: ", { message });
	return message;
};
