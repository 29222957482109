import { IonCard, IonCardContent, IonCardHeader, IonSkeletonText } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import "../../css/Home.css";
import "../../css/Inventory.css";
import { getItemDetailsByItem, getQuantityOfItemByItemID } from "../../services/InventoryService";
import AuthContext from "../../contexts/GlobalState";

const InventoryComponent = ({ userObject }) => {
	const { userState } = useContext(AuthContext);
	const [inventory, setInventory] = useState([]);
	const [showLoading, setShowLoading] = useState(false);
	const history = useHistory();

	useEffect(() => {
		getInventory(userState.user).then(() => console.log("Inventory Updated"));
	}, [userState.user]);
	const getInventory = async (userObject) => {
		setShowLoading(true);
		if (userObject.inventory.length > 0) {
			let tempInventory = await userObject.inventory;
			let tempArray = [];
			for await (let item of tempInventory) {
				item.details = await getItemDetailsByItem(item);
				item.quantity = await getQuantityOfItemByItemID(item.inventoryItemId);
				if (item.quantity > 0) {
					tempArray.push(item);
				}
			}
			setInventory(tempInventory);
		} else {
			setInventory(null);
		}
		setShowLoading(false);
	};

	return (
		<React.Fragment>
			<div className="section-title">Your Inventory</div>
			{showLoading === true ? (
				<IonSkeletonText style={{ width: "50px", height: "50px" }} animated />
			) : (
				<div className="horizontal-scroll" style={{ paddingLeft: "12px" }}>
					{inventory !== null ? (
						inventory.map((item, i) => {
							return (
								<div key={`${i}`} className="streak" onClick={() => history.push("/userinventory/" + item.inventoryItemId)}>
									<div className="goal-streak-container">
										<div style={{ fontSize: "0.75em", textAlign: "center" }}>{item.details.name}</div>
										<div className="goal-streak-data-points">
											<div className="goal-streak-data-points-each">
												<img style={{ maxHeight: "45px" }} src={item.details.image} alt="" />
											</div>
										</div>
									</div>
									<div className="goal-week">Quantity: {item.quantity}</div>
								</div>
							);
						})
					) : (
						<div className="instructional-text">Earn items by participating in Challenges</div>
					)}
				</div>
			)}
		</React.Fragment>
	);
};

export default InventoryComponent;
