import { IonSpinner } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import AuthContext from "../../contexts/GlobalState";
import "../../css/Home.css";
import "../../css/Profile.css";
import { StorageService } from "../../services/StorageService";

const ProfileWeeklyProgressComponent = ({ userObj }) => {
	const { userState, globalGoal, weeklyProgress } = useContext(AuthContext);
	const [weeklyProgressStats, setWeeklyProgressStats] = useState(weeklyProgress);
	const [loadingWeeklyProgress, setLoadingWeeklyProgress] = useState(true);
	const [barChartData, setBarChartData] = useState([]);

	useEffect(() => {
		console.log({ weeklyProgress });
		setWeeklyProgressStats(weeklyProgress);
	}, [weeklyProgress]);

	useEffect(() => {
		getWeeklyProgress(userObj, false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [globalGoal, weeklyProgress]);

	//useEffect(() => {}, [globalGoal]);

	/**
	 *
	 * Pull data from prior week and current week up to current date
	 *
	 */
	const getWeeklyProgress = async (userObject, refresh = false) => {
		setLoadingWeeklyProgress(true);
		if (!weeklyProgressStats || weeklyProgressStats === {}) {
			console.log("weeklyProgressStats is null");
			//setNullGoalNotice(true);
			return;
		}
		setBarChartData({
			labels: ["M", "T", "W", "Th", "F", "Sa", "Su"],
			datasets: [
				{
					data: weeklyProgressStats.twDataSet,
					label: "This Week",
					backgroundColor: "#1571b3",
				},
				{
					data: weeklyProgressStats.lwDataSet,
					label: "Last Week",
					backgroundColor: "#525252",
				},
			],
			options: {
				scales: {
					y: {
						beginAtZero: true,
					},
				},
			},
		});
		setLoadingWeeklyProgress(false);
	};

	return (
		<React.Fragment>
			<div className="section-title">Weekly Progress</div>
			{loadingWeeklyProgress === true ? <IonSpinner /> : <Bar data={barChartData} />}
		</React.Fragment>
	);
};

export default ProfileWeeklyProgressComponent;
