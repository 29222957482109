import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonCol,
	IonContent,
	IonFooter,
	IonHeader,
	IonIcon,
	IonLoading,
	IonPage,
	IonPopover,
	IonRow,
	IonTitle,
	IonToolbar,
	useIonViewDidEnter,
} from "@ionic/react";
import { addCircle } from "ionicons/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "../../css/ChatContainer.css";
import { getMostCommonActivities } from "../../services/ActivitiesService";
import { botSendMessage, getDateLastMessageBotSent, getWelcomeBackMessage, sendActivityMessage } from "../../services/BotService";
import { convertStringToMessageObjectArray } from "../../services/HelperServices";
import { StorageService } from "../../services/StorageService";
import { getUser, getUserNameOfLoggedInUser } from "../../services/UserService";
import BotAddActivityComponent from "./BotAddActivityComponent";
import BotMessageInput from "./BotMessageInput";
import BotMessageRenderer from "./BotMessageRenderer";

const BotAddActivity = () => {
	const fromBot = true;
	const [messages, setMessages] = useState();
	const [botResponseMessages, setBotResponseMessages] = useState(); // this does nothing but refresh ::shrug::
	// eslint-disable-next-line no-unused-vars
	const [typingMessage, setTypingMessage] = useState(); // this does nothing but refresh ::shrug::
	const [showLoading, setShowLoading] = useState(true);
	const [buttons, setButtons] = useState();
	const [activityModal, showAddActivityModal] = useState(false);
	const [frequentActivities, setFrequentActivities] = useState(null);
	const [contextID, setContextID] = useState(null); // This is the id number of a message to send from the DB after a free text message is sent
	const [command, setCommand] = useState(null); // A text string sent by the server, parsed by the the client, telling it something to do
	const [activityData, setActivityData] = useState({
		promise: null,
		activity: null,
		duration: null,
		unit: null,
		calories: null,
		date: null,
		imageURL: null,
		points: null,
		intensity: null,
	});
	const [userObject, setUserObject] = useState();

	const storageService = new StorageService();

	useIonViewDidEnter(async () => {
		setUserObject(await getUser(null, false));
		console.log("Getting messages");
		getFrequentActivities();
		await getStoredMessages();
		// Get existing messages in storage
		// Send message from bot
		setShowLoading(false);
		sendGreetingMessage();
	});

	useEffect(() => {
		if (activityData.activity !== null) {
			sendActivityMessage(activityData, setBotResponseMessages, setButtons, setMessages, setContextID, command, setCommand);
		}
	}, [activityData]);

	const getFrequentActivities = async () => {
		let tempFrequent = await getMostCommonActivities();
		console.log({ tempFrequent });
		setFrequentActivities(tempFrequent);
	};

	const sendGreetingMessage = async () => {
		let messageStore = await storageService.getBotMessages();
		let userName = await getUserNameOfLoggedInUser();
		if (messageStore !== null) {
			let dateLastMessageBotSent = await getDateLastMessageBotSent();
			//dateLastMessageBotSent = moment().subtract(5, "hours");
			let now = moment();
			let hoursSinceLastMessage = now.diff(dateLastMessageBotSent, "hours");
			//console.log({ hoursSinceLastMessage });
			if (hoursSinceLastMessage < 2) {
				return;
			} else {
				let welcomeBackMessage = await convertStringToMessageObjectArray(await getWelcomeBackMessage());
				botSendMessage(welcomeBackMessage, setBotResponseMessages, setButtons, setMessages, true, setContextID, command, setCommand);
			}
		} else {
			let fullMessage =
				"Hey " +
				userName +
				", I'm your new cheerleadr! I'm here to cheer you on as build your exercise habit!\nTo log your activity hit the + button in the bottom left.\nAnd if you're ever bored and want a joke or story, just ask me for one! In the meantime.. GOOOOOO " +
				userName +
				"!";
			let messageObjectArray = await convertStringToMessageObjectArray(fullMessage);
			botSendMessage(messageObjectArray, setBotResponseMessages, setButtons, setMessages, true, setContextID, command, setCommand);
		}
	};

	const getStoredMessages = async () => {
		let messagesTemp = await storageService.getBotMessages();
		if (messagesTemp && messagesTemp.length > 20) {
			console.log();
			messagesTemp.slice(Math.max(messagesTemp.length - 20, 0));
		}
		setMessages(messagesTemp);
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton text=" " color="light" />
					</IonButtons>
					<IonTitle>Cheerleadr</IonTitle>
					{/*
						<AddActivityComponent source={"bot"} />
						*/}
				</IonToolbar>
			</IonHeader>
			<IonPopover
				//event={showGoalConfirmation.event}
				isOpen={activityModal}
				cssClass="exercise-details-full-screen-popover"
				style={{ top: "5%", height: "90%" }}
				onDidDismiss={() => showAddActivityModal(false)}
			>
				<BotAddActivityComponent
					mostFrequentActivities={frequentActivities}
					modalState={activityModal}
					setModalState={showAddActivityModal}
					setActivityData={setActivityData}
					fromBot={fromBot}
				/>
			</IonPopover>
			<IonContent>
				<IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={"Loading Messages..."} />
				{messages && userObject ? (
					<BotMessageRenderer setButtons={setButtons} messages={messages} botResponseMessages={botResponseMessages} userObject={userObject} />
				) : (
					""
				)}
			</IonContent>
			{showLoading === false ? (
				<IonFooter>
					<IonToolbar className="chat-input-toolbar" style={{ height: "60px" }}>
						<IonRow style={{ height: "50px" }}>
							<IonCol size="2">
								<IonButton onClick={() => showAddActivityModal(true)} className="submit-button" color="chat-bar-button">
									<IonIcon size="small" icon={addCircle}></IonIcon>
								</IonButton>
								<div style={{ fontSize: "8px", marginTop: "-8px", marginLeft: "4px" }}>Add Activity</div>
							</IonCol>

							<BotMessageInput
								messages={messages}
								setMessages={setMessages}
								setButtons={setButtons}
								buttons={buttons}
								setBotResponseMessages={setBotResponseMessages}
								setTypingMessage={setTypingMessage}
								showAddActivityModal={showAddActivityModal}
								activityData={activityData}
								setContextID={setContextID}
								command={command}
								setCommand={setCommand}
							/>
						</IonRow>
					</IonToolbar>
				</IonFooter>
			) : (
				""
			)}
		</IonPage>
	);
};

export default BotAddActivity;
