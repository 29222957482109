/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useRef, useState } from "react";
import { IonButton, IonButtons, IonHeader, IonIcon, IonInput, IonTitle, IonToolbar } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import "../../css/Home.css";
import "../../css/ActivityDetails.css";
import { validateCode } from "../../services/SquadService";
import { useHistory } from "react-router";

const JoinSquadPopover = ({ setShowJoinSquadConfirmed, showJoinSquadConfirmed }) => {
	const history = useHistory();
	const [codeError, setCodeError] = useState(false);
	const [validatingCode, setValidatingCode] = useState(false);
	const codeRef = useRef(null);

	console.log({showJoinSquadConfirmed});
	

	return (
		<React.Fragment>
			<IonHeader>
				<IonToolbar className="activity-details-toolbar">
					<IonTitle>You're In!</IonTitle>
				</IonToolbar>
			</IonHeader>
			<div style={{ margin: "16px", textAlign: "center" }}>
				<div>
					You joined <br />
					<h1>{showJoinSquadConfirmed.squadObject.name}</h1>
				</div>
				<div>
					<img className="confirm-squad-image" style={{ height: "50%" }} src={showJoinSquadConfirmed.squadObject.image} alt="" />
				</div>
				<div className="confirm-squad-slogan">{showJoinSquadConfirmed.squadObject.slogan}</div>
				<div style={{ paddingTop: "5%", paddingBottom: "5%" }}>Join the chat room and introduce yourself to your squaddies</div>
				<IonButton expand="block" routerLink={`/squadhome/${showJoinSquadConfirmed.squadObject.id}`} onClick={() => setShowJoinSquadConfirmed({ visible: false })}>
					Introduce yourself 👋
				</IonButton>
			</div>
		</React.Fragment>
	);
};

export default JoinSquadPopover;
