/* eslint-disable jsx-a11y/alt-text */
import {
	IonAvatar,
	IonChip,
	IonCol,
	IonContent,
	IonGrid,
	IonImg,
	IonItem,
	IonLoading,
	IonPopover,
	IonRow,
	IonSpinner,
	IonToast,
	useIonViewDidEnter,
	useIonViewDidLeave,
	useIonViewWillEnter,
} from "@ionic/react";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import Emoji from "react-emoji-render";
import { Picker } from "emoji-mart";
import { useHistory } from "react-router";
import "../../css/FeedRenderer.css";
import "../../css/Home.css";
import { getActivityDetails } from "../../services/ActivitiesService";
import { logGiveLoveEvent } from "../../services/AnalyticsService";
import { addEmoji } from "../../services/ChatService";
import { StorageService } from "../../services/StorageService";
import { getUserID } from "../../services/UserService";
import { getSquadIDFromChatRoomID } from "../../services/SquadService";
import AuthContext from "../../contexts/GlobalState";

/**
 *
 * Important: This component is only rerendered when the feed object is updated
 * The scroller
 *
 */

const SquadHomeMessageRenderer = ({ feed, setFeed, chatRoomObj, setScroller, scroller, refresher }) => {
	const { refreshingChat } = useContext(AuthContext);
	const [renderingMessages, setRenderingMessages] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const [showReactionSelector, setShowReactionSelector] = useState({
		open: false,
		event: undefined,
		activityID: undefined,
		type: undefined,
	});
	console.log("Rendering FeedRenderer");
	let counterDate = "";
	let activityDate;
	let showSeperator = true;
	let setToScroll;
	const feedEndRef = useRef(null);
	const history = useHistory();

	var waitTime = 500; // just adding 1 incase it's nil

	useIonViewWillEnter(() => {
		if (feedEndRef.current !== null) {
			setTimeout(() => {
				feedEndRef.current.scrollIntoView({ behavior: "auto" });
			}, waitTime);
			setRenderingMessages(false);
		} else {
			console.log("Condition not met to scroll");
		}
	});

	useIonViewDidLeave(() => {
		console.log("ionViewDidLeave event fired from FeedRendere");
		clearTimeout(setToScroll);
	});

	useEffect(() => {
		//setRenderingMessages(true);
		if (scroller === true) {
			if (feedEndRef.current !== null) {
				setTimeout(() => {
					try {
						feedEndRef.current.scrollIntoView({ behavior: "auto" });
					} catch (error) {
						console.log("ERROR: " + error);
					}
				}, waitTime);
				setRenderingMessages(false);
				feedEndRef.current.scrollIntoView({ behavior: "auto" });
				console.log("Scrolling to bottom (useEffect)");
			} else {
				console.log("Condition not met to scroll");
			}
		}
	}, [feed, refresher]);

	const showEmojiPicked = (event, emojiObject) => {
		addEmojiToActivity(showReactionSelector.activityID, emojiObject.colons, showReactionSelector.type);
		setShowReactionSelector({ open: false, event: undefined, activityID: undefined, type: undefined });
	};

	const addExistingEmoji = (emoji, activityID, type) => {
		addEmojiToActivity(activityID, emoji, type);
	};

	const showPhotoZoomed = async (imageURL) => {
		history.push("/image/" + encodeURIComponent(imageURL));
	};

	const scrollToBottom = () => {
		//console.log("Scroller value: " + scroller);
		if (scroller === true) {
			if (feedEndRef.current !== null && showReactionSelector.open !== true) {
				setToScroll = setTimeout(() => {
					if (feedEndRef.current !== null) {
						// just to make sure...
						feedEndRef.current.scrollIntoView({ behavior: "auto" });
						setRenderingMessages(false);
					}
				}, waitTime);
				console.log("Scrolling to bottom (function)");
				//setScrolled(true)
			}
		}
	};

	/**
	 *
	 * Adds an emoji to Activity
	 *
	 * Immediately closes popover
	 * Gets localFeed
	 * Looks for the activityID, checks it for # of reactions
	 * If there aren't any, sets count to 1, adds the reactions object
	 * If there are some, checks to see if what's being added already exists
	 *
	 * @param {*} activityID
	 * @param {*} reaction
	 */
	const addEmojiToActivity = async (activityID, reaction, type) => {
		setScroller(false);
		const storageService = new StorageService();
		//console.log("Adding emoji "+reaction+" to ID "+activityID+" ["+moment().format()+"]");
		setShowReactionSelector({ open: false, event: undefined, activityID: undefined, type: undefined });
		let currentFeed = await storageService.getObject("messages-" + chatRoomObj.id); // get the current feed
		for (var x = 0; x < currentFeed.length; x++) {
			// iterate over local feed
			if (currentFeed[x].id === activityID) {
				// once you find the activity ID
				//console.log("Found activity ID "+activityID+" in currentFeed ["+moment().format()+"]");
				// check the reactions object
				if (currentFeed[x].reactionCount > 0) {
					// if something from local storage has reactions we need to see if we're incrementing
					//console.log("activity ID "+activityID+" has "+currentFeed[x].reactionCount+" reactions");
					//console.log("activity ID "+activityID+" has "+currentFeed[x].reactions.length+" length reactions");
					let reactionUpdated = false;
					for (var y = 0; y < currentFeed[x].reactions.length; y++) {
						// look for reaction in reactions
						console.log("Reaction " + y + " has " + currentFeed[x].reactions[y].emoji + " emoji");
						if (currentFeed[x].reactions[y].emoji === reaction) {
							// what is being added already exists
							// TODO: Come back to this and implement removing of previous reactions
							/* 
							let userID = await getUserID()
							let filterResult = currentFeed[x].reactionIDs.filter((obj) => obj.user === userID && obj.type === reaction); // look if THIS user has left THIS reaction before
							if (Object.keys(filterResult).length === 0) {
								currentFeed[x].reactions[y].count++ // if not, add it
							} else {
								console.log("User has left this reaction before. Removing it");
							}
							*/
							//console.log("Reactions before: "+JSON.stringify(currentFeed[x].reactions[y])+" ["+moment().format()+"]");
							currentFeed[x].reactions[y].count++;
							//console.log("Reactions after: "+JSON.stringify(currentFeed[x].reactions[y])+" ["+moment().format()+"]");
							reactionUpdated = true;
						}
					}
					if (reactionUpdated === false) {
						//console.log("Checked existing reactions. Doesn't exist so adding reaction "+reaction+" to activity ID "+activityID+" ["+moment().format()+"]");
						currentFeed[x].reactionCount++;
						currentFeed[x].reactions.push({
							count: 1,
							emoji: reaction,
						});
					}
				} else {
					// whats in local storage does not have a reaction yet
					//console.log("Adding reaction "+reaction+" to activity ID "+activityID+" ["+moment().format()+"]");
					currentFeed[x].reactionCount = 1;
					currentFeed[x].reactions.push({
						count: 1,
						emoji: reaction,
					});
				}
			}
		}
		await storageService.setItem("acceptTimerPull", "false"); // Ensure we don't pull before this is added
		setFeed(currentFeed); // update the state
		await storageService.setObject("messages-" + chatRoomObj.id, currentFeed); // store updated feed locally
		try {
			let activityDetails = await getActivityDetails(activityID);
			logGiveLoveEvent("chat-room", activityDetails.activityType, reaction, activityDetails.userId);
			let squadID = await getSquadIDFromChatRoomID(chatRoomObj.id);
			addEmoji(activityID, reaction, type, squadID); // send it to the server
		} catch (e) {
			console.log("Error in adding emoji: " + e);
		}
		await storageService.setItem("acceptTimerPull", "true");
		console.log("acceptTimerPull set to true");
		console.log("Emoji Added " + activityID + " [" + moment().format() + "]");
	};

	const goToMemberPage = (id) => {
		history.push("/otherprofile/" + id);
	};

	const confirmAddReaction = async (isopen, e, activityID, type, userID) => {
		let currentUserID = await getUserID();
		if (userID !== currentUserID) {
			console.log("showing reaction selector");
			setShowReactionSelector(isopen, e, activityID, type, userID);
		} else {
			setShowToast(true);
		}
	};

	if (feed !== null && feed !== undefined && feed.length > 0) {
		console.log("Feed: ", { feed });
		return (
			<React.Fragment>
				<IonLoading isOpen={renderingMessages} onDidDismiss={() => setRenderingMessages(false)} message="Loading" duration={1000} />
				<IonContent className="chat-scroll-squad-home">
					<IonPopover
						event={showReactionSelector.event}
						isOpen={showReactionSelector.open}
						cssClass="reaction-selector"
						onDidDismiss={(e) => {
							setShowReactionSelector({ open: false, event: undefined });
							//setScroller(false);
						}}
					>
						<Picker sheetSize={32} onSelect={(emoji) => showEmojiPicked(emoji, emoji)} />
					</IonPopover>
					<IonToast
						isOpen={showToast}
						onDidDismiss={() => setShowToast(false)}
						message="You can't add reactions to your own activities :)"
						duration={3000}
						color="tertiary"
					/>
					<div>
						<div>
							{feed.map((feedItem, index) => {
								if (feedItem.cssClass === "squadGoalEvaluated-css") {
									return "";
								}
								activityDate = moment(feedItem.timeStamp).format("ddd, MMMM DD");
								if (counterDate !== activityDate) {
									showSeperator = true;
									counterDate = activityDate;
								} else {
									showSeperator = false;
								}
								if (feedItem.type === "text" || feedItem.type === "message") {
									return (
										<React.Fragment key={`${index}`}>
											{showSeperator === true ? (
												<div className="date-separator">
													<div className="date-separator-text">{activityDate}</div>
												</div>
											) : (
												""
											)}
											<IonRow
												className="message-container"
												key={`${index}`}
												onClick={(e) =>
													confirmAddReaction({ open: true, event: e.nativeEvent, activityID: feedItem.id, type: "message", userID: feedItem.author })
												}
											>
												<IonCol size="1" style={{ marginRight: "12px" }}>
													<IonAvatar onClick={() => goToMemberPage(feedItem.author)}>
														{feedItem.user.avatar && feedItem.user.avatar !== "" && feedItem.user.avatar !== null ? (
															<React.Fragment>
																<img className="user-avatar" src={feedItem.user.avatar} style={{ height: "32px", width: "32px" }} />
															</React.Fragment>
														) : (
															<img
																className="user-avatar"
																src="https://fitsquad-ui-assets.s3-us-west-2.amazonaws.com/empty-avatar.png"
																style={{ height: "32px", width: "32px" }}
															/>
														)}
													</IonAvatar>
												</IonCol>
												<IonCol size="9">
													<IonRow>
														<strong>{feedItem.user.userName}</strong>
														<span className="date">{moment(feedItem.timeStamp).format("h:mm a")}</span>
													</IonRow>
													<IonRow>
														{feedItem.message.includes(".jpg") ||
														feedItem.message.includes(".gif") ||
														feedItem.message.includes(".png") ||
														feedItem.message.includes("gph.is") ||
														feedItem.message.includes("giphy.com") ? (
															<img src={feedItem.message} alt="" style={{ objectFit: "cover" }} />
														) : feedItem.message.includes("http") ? (
															<div style={{ fontSize: "0.9em" }}>
																<a href={feedItem.message} target="_new">
																	{feedItem.message}
																</a>
															</div>
														) : (
															<div style={{ fontSize: "0.9em" }}>{feedItem.message}</div>
														)}
													</IonRow>
												</IonCol>
											</IonRow>
											{feedItem.reactionCount > 0 ? (
												<React.Fragment>
													<div className="reaction-container" style={{ top: "-30px" }}>
														{feedItem.reactions.map((reactions, index) => {
															//TODO: Only show reactions from people in the current squad...
															if (reactions.type) {
																reactions.emoji = reactions.type;
															}
															if (!reactions.count) {
																reactions.count = 1;
															}
															return (
																<IonChip
																	key={`${index}`}
																	className="emoji-chip"
																	onClick={(e) => {
																		addEmojiToActivity(showReactionSelector.activityID, ":smile:", showReactionSelector.type);
																	}}
																>
																	<Emoji text={reactions.emoji} className="reaction-emoji-container" /> {reactions.count}
																</IonChip>
															);
														})}
													</div>
												</React.Fragment>
											) : (
												""
											)}
										</React.Fragment>
									);
								} else if (feedItem.type === "image") {
									return (
										<React.Fragment key={`${index}`}>
											{showSeperator === true ? (
												<div className="date-separator">
													<div className="date-separator-text">{activityDate}</div>
												</div>
											) : (
												""
											)}
											<IonRow className="message-container" key={`${index}`}>
												<IonCol size="1" style={{ marginRight: "12px" }}>
													<IonAvatar onClick={() => goToMemberPage(feedItem.author)}>
														{feedItem.user.avatar && feedItem.user.avatar !== "" && feedItem.user.avatar !== null ? (
															<img className="user-avatar-chat" src={feedItem.user.avatar} style={{ height: "32px", width: "32px" }} />
														) : (
															<img
																className="user-avatar-chat"
																src="https://fitsquad-ui-assets.s3-us-west-2.amazonaws.com/empty-avatar.png"
																style={{ height: "25px", width: "25px" }}
															/>
														)}
													</IonAvatar>
												</IonCol>
												<IonCol size="9" style={{ marginRight: "12px" }}>
													<IonRow>
														<strong>{feedItem.user.userName}</strong>
														<span className="date">{moment(feedItem.timeStamp).format("h:mm a")}</span>
													</IonRow>
													{feedItem.payload && feedItem.payload.imageUrl ? (
														<IonRow>
															<img
																src={feedItem.payload.imageUrl}
																alt=""
																onClick={() => showPhotoZoomed(feedItem.payload.imageUrl)}
																style={{ objectFit: "cover" }}
															/>
														</IonRow>
													) : (
														<IonRow>
															<img src={feedItem.message} alt="" onClick={() => showPhotoZoomed(feedItem.message)} style={{ objectFit: "cover" }} />
														</IonRow>
													)}
												</IonCol>
											</IonRow>
											{feedItem.reactionCount > 0 ? (
												<React.Fragment>
													<div className="reaction-container" style={{ top: "-5px" }}>
														{feedItem.reactions.map((reactions, index) => {
															//TODO: Only show reactions from people in the current squad...
															return (
																<IonAvatar
																	className="reaction-avatar"
																	key={`${index}`}
																	onClick={(e) => addExistingEmoji(reactions.emoji, feedItem.id, "message")}
																>
																	<img className="user-avatar" src={reactions.avatar} style={{ height: "24px", width: "24px", objectFit: "cover" }} />
																	<Emoji text={reactions.emoji} className="reaction-emoji-container" />
																</IonAvatar>
															);
														})}
													</div>
												</React.Fragment>
											) : (
												""
											)}
										</React.Fragment>
									);
								}
							})}
							{refreshingChat === true ? (
								<div className="loading-recent-messages">
									<IonSpinner name="dots" />
								</div>
							) : (
								""
							)}
							<div></div>
							<div ref={feedEndRef} />
						</div>
					</div>
					{scrollToBottom()}
				</IonContent>
			</React.Fragment>
		);
	} else {
		console.log("Feed is undefined");
		return (
			<IonContent className="chat-scroll-squad-home">
				<div>
					No messages yet. Be the first!
					<div ref={feedEndRef} />
				</div>
				{scrollToBottom()}
			</IonContent>
		);
	}
};

export default SquadHomeMessageRenderer;
