import {
	IonAlert,
	IonAvatar,
	IonBackButton,
	IonButton,
	IonButtons,
	IonCol,
	IonContent,
	IonFooter,
	IonHeader,
	IonInput,
	IonLoading,
	IonPage,
	IonProgressBar,
	IonRow,
	IonToolbar,
	useIonViewWillEnter,
} from "@ionic/react";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router";
import "../../css/Home.css";
import "../../css/Onboarding.css";
import { logPageView } from "../../services/AnalyticsService";
import { getUser, updateUser } from "../../services/UserService";
import { StorageService } from "../../services/StorageService";
import { getRandomUser } from "../../services/AuthService";
import { Device } from "@capacitor/core";
import { sendSlackNotification } from "../../services/NotificationService";
const storageService = new StorageService();

const SaveProgress = ({ progress, setProgress, squadToJoin }) => {
	const [userObject, setUserObject] = useState();
	const [email, setEmail] = useState(null);
	const [password, setPassword] = useState(null);
	const [platform, setPlatform] = useState();
	const [nextPage, setNextPage] = useState("/onboarding/squadselection");
	const [isFakeUser, setIsFakeUser] = useState(false);
	const passwordRef = useRef(null);
	const emailRef = useRef(null);

	const [emailError, setEmailError] = useState({
		visible: false,
		message: null,
	});
	const [emailConfirmation, setEmailConfirmation] = useState({
		visible: false,
		message: null,
	});
	const [passwordError, setPasswordError] = useState({
		visible: false,
		message: null,
	});

	const [registering, setRegistering] = useState({
		visible: false,
		message: "",
	});

	const history = useHistory();

	useIonViewWillEnter(async () => {
		logPageView("Save Your Progress");
		//setUserObject(JSON.parse(await getUser()));
		await Device.getInfo().then((deviceInfo) => {
			setPlatform(deviceInfo.platform);
		});
		setProgress(0.95);
		// let initialChallenge = await storageService.getObject("initialChallengeToJoin");
		// let initialSquad = await storageService.getObject("initialSquadToJoin");
		// if (initialChallenge === null && initialSquad === null) {
		// 	// if both initialSquad and initialChallenge are not set show the Challenge offer
		// 	setNextPage("/onboarding/challengesignup");
		// }
		storageService.setItem("lastOnboardingStep", 16);
	});

	const validateEmail = (email) => {
		var mailformat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		console.log("mailformat.test(email) :" + mailformat.test(email));
		if (!mailformat.test(email)) {
			setEmailError({
				visible: true,
				message: "Please enter a valid email",
			});
			return false;
		} else {
			setEmailError({
				visible: false,
				message: null,
			});
			return true;
		}
	};

	const validatePassword = (password) => {
		console.log("Password: " + password);
		if (!password || password.length < 8) {
			setPasswordError({
				visible: true,
				message: "Password should be at least 8 characters",
			});
			return false;
		} else {
			setPasswordError({
				visible: false,
				message: null,
			});
			return true;
		}
	};

	const validateData = async () => {
		let validData = true;
		if (validData === true) {
			validData = validateEmail(emailRef.current.value);
			console.log("Checking email");
		}
		if (validData === true) {
			validData = validatePassword(passwordRef.current.value);
			console.log("Checking password");
		}
		console.log("Valid Data: " + validData);
		if (validData === false) {
			return false;
		} else {
			setEmailConfirmation({
				visible: true,
				message: "You entered your email as: " + emailRef.current.value,
			});
		}
	};

	const validateDataFake = async () => {
		setIsFakeUser(true);
		let rando = await getRandomUser();
		setEmail(rando.results[0].email);
		setPassword("qqqqqqqq");
	};

	const confirmEmail = async () => {
		setRegistering({ visible: true, message: "Saving your progress..." });
		let data = {
			password: passwordRef.current.value,
			email: emailRef.current.value,
		};
		let response;
		do {
			console.log("Trying update user");
			response = await updateUser(data);
			console.log("Response from update user: ", { response });
		} while (response == null);
		sendSlackNotification("*Account progress saved*\nEmail: " + data.email + "\n", "creation");
		setRegistering({ visible: false });
		history.push(nextPage);
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar style={{ "--border-style": "none" }}>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
				</IonToolbar>
				<IonProgressBar value={progress} />
			</IonHeader>
			<IonLoading isOpen={registering.visible} message={registering.message} backdropDismiss />

			<IonContent>
				<IonAlert
					isOpen={!!emailConfirmation.visible}
					header="Is this correct?"
					message={emailConfirmation.message}
					buttons={[
						{
							text: "No",
							role: "cancel",
							handler: () => {
								setEmailConfirmation({
									visible: false,
									message: null,
								});
							},
						},
						{
							text: "Yes",
							role: "ok",
							handler: () => confirmEmail(),
						},
					]}
					onDidDismiss={() =>
						setEmailConfirmation({
							visible: false,
							message: null,
						})
					}
				/>
				<div className="onboarding-main-div">
					<div className="onboarding-title">Save Your Progress</div>
					<div className="onboarding-hero-image-div">
						<img className="onboarding-hero-image" src="https://fitsquad-ui-assets.s3.us-west-2.amazonaws.com/collage-v2.jpg" alt="" />
					</div>
					<div className="onboarding-text-area" style={{ textAlign: "center" }}>
						<p>Now that you have a goal and some points, save your account with an email address and password (you'll use these to login)</p>

						<div className="onboarding-username-box-div">
							{email ? (
								<IonInput
									className="onboarding-email-box"
									ref={emailRef}
									name="email"
									value={email}
									type={"text"}
									placeholder="Email address"
									enterkeyhint={"done"}
									onFocus={() => setEmailError({ visible: false, message: null })}
									required
								></IonInput>
							) : (
								<IonInput
									className="onboarding-email-box"
									ref={emailRef}
									name="username"
									type={"text"}
									placeholder="Email address"
									enterkeyhint={"done"}
									onFocus={() => setEmailError({ visible: false, message: null })}
									required
								></IonInput>
							)}
							{emailError.visible === true ? <div className="onboarding-error-message">{emailError.message}</div> : ""}
						</div>
						<div className="onboarding-username-box-div">
							{password ? (
								<IonInput
									className="onboarding-email-box"
									ref={passwordRef}
									name="password"
									value={password}
									type={"password"}
									placeholder="Password (min. 8 characters)"
									minlength="8"
									enterkeyhint={"done"}
									onFocus={() => setPasswordError({ visible: false, message: null })}
									required
								></IonInput>
							) : (
								<IonInput
									className="onboarding-email-box"
									ref={passwordRef}
									name="password"
									type={"password"}
									placeholder="Password (min. 8 characters)"
									minlength="8"
									enterkeyhint="done"
									onFocus={() => setPasswordError({ visible: false, message: null })}
									required
								></IonInput>
							)}
						</div>
						{passwordError.visible === true ? <div className="onboarding-error-message">{passwordError.message}</div> : ""}
					</div>
					<div className="onboarding-footer"></div>
				</div>
			</IonContent>
			<IonFooter className="onboarding-footer">
				<IonButton onClick={validateData} className="onboarding-advance-button">
					Save progress
				</IonButton>
				<IonButton className="button-as-link" routerLink={nextPage}>
					I'm ok losing my progress
				</IonButton>
			</IonFooter>
		</IonPage>
	);
};

export default SaveProgress;
