import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonContent,
	IonFooter,
	IonHeader,
	IonLoading,
	IonPage,
	IonProgressBar,
	IonToolbar,
	useIonViewDidEnter,
} from "@ionic/react";
import React, { useState } from "react";
import "../../css/Home.css";
import "../../css/Onboarding.css";
import { logPageView } from "../../services/AnalyticsService";
import { getRandomGifFromSearch } from "../../services/GifService";
import { getUser } from "../../services/UserService";
import { StorageService } from "../../services/StorageService";
const storageService = new StorageService();

const PointsConfirmation = ({ progress, setProgress, activity }) => {
	const [user, setUser] = useState();
	const [loading, setLoading] = useState(true);
	const [randomGif, setRandomGif] = useState();
	useIonViewDidEnter(() => {
		logPageView("Points Confirmation");
		setProgress(0.7);
		// Commented this out because we don't story the activity prop
		// and so if onboarding is interrupted, we want to resume on step 7, not 8
		//storageService.setItem("lastOnboardingStep", 8);
		fetchUser();
	});

	const fetchUser = async () => {
		let userstored = JSON.parse(await getUser());
		setUser(userstored);
		setRandomGif(await getRandomGifFromSearch(activity.activity, 50, "pg"));
		setLoading(false);
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar style={{ "--border-style": "none" }}>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
				</IonToolbar>
				<IonProgressBar value={progress} />
			</IonHeader>
			<IonLoading isOpen={loading} onDidDismiss={() => setLoading(false)} message={"Calculating your credit.."} backdropDismiss />
			{user && activity && randomGif ? (
				<React.Fragment>
					<IonContent>
						<div className="onboarding-main-div">
							<div className="onboarding-title">Great job {user.userName}!</div>
							<div className="onboarding-text-area" style={{ textAlign: "center" }}>
								<p>That {activity.activity} earned you... </p>
								<span style={{ fontSize: "50px" }}>{activity.points} points!</span>
								<p>Did it look a little something like this?</p>
								<img style={{ borderRadius: "50px" }} src={randomGif} alt="" />
							</div>
						</div>
					</IonContent>
					<IonFooter className="onboarding-footer">
						<IonButton routerLink="/onboarding/notificationspermission" className="onboarding-advance-button">
							Haha yea something like that!
						</IonButton>
					</IonFooter>
				</React.Fragment>
			) : (
				""
			)}
		</IonPage>
	);
};

export default PointsConfirmation;
