/* eslint-disable no-restricted-globals */
import { IonIcon, IonModal, IonPopover, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { addCircle, homeOutline, medalOutline, megaphone, peopleOutline, personOutline, podiumOutline } from "ionicons/icons";
import React, { useState } from "react";
import { Redirect, Route } from "react-router";
import BotAddActivity from "../components/Bot/BotAddActivity";
import AddActivityComponent from "../components/AddActivityComponent";
import AddActivityComponentCore from "../components/AddActivityComponentCore";
import Register from "../components/Onboarding/Register";
import CompetitionCompleted from "../pages/activities/CompetitionCompleted";
import CompetitionCompletedLastWeek from "../pages/activities/CompetitionCompletedLastWeek";
import NewSquadCompetition from "../pages/activities/NewSquadCompetition";
import NewUserGoal from "../pages/activities/NewUserGoal";
import SquadWeekCompleted from "../pages/activities/SquadWeekCompleted";
import SquadLastWeekCompleted from "../pages/activities/SquadWeekCompletedLastWeek";
import AddUserToSquad from "../pages/AddUserToSquad";
import ChatInterface from "../pages/ChatInterface";
import ChatRoom from "../components/ChatRoom/ChatRoom";
//import EndOfWeekSummary from "../pages/EndOfWeekSummary";
import ExerciseDetails from "../pages/ExerciseDetails";
import Home from "../pages/Home";
import LoadingPage from "../pages/LoadingPage";
import NotificationPlayGround from "../pages/NotificationPlayground";
import NotificationsList from "../pages/NotificationsList";
import UserProfile from "../components/Profile/Profile";
import UserProfileOther from "../components/Profile/Profile-other";
import ManageSquads from "../components/ManageSquad/ManageSquads";
import SquadCompetitionScoreboard from "../pages/SquadCompetitionScoreboard";
import SquadCreate from "../pages/SquadCreate";
import SquadCreateComplete from "../pages/SquadCreateComplete";
import SquadDetails from "../pages/SquadDetails";
import SquadInvite from "../pages/SquadInvite";
import SquadJoin from "../components/JoinSquad/JoinSquadPage";
import SquadScoreboard from "../pages/SquadScoreboardPage";
import Standings from "../pages/Standings";
import SystemActivityDetails from "../pages/SystemActivityDetails";
import UserSettings from "../pages/UserSettings";
import Admin from "../pages/Admin";
import ImageViewer from "../pages/ImageViewer";
import SquadJoined from "../pages/SquadJoined";
import SeasonDetails from "../pages/SeasonDetails";
import SeasonSalesPage from "../pages/SeasonSalesPage";
import DailyWorkoutPage from "../pages/DailyWorkout";
import SeasonTerms from "../components/Seasons/SeasonTerms";
import Inventory from "../components/Inventory/Inventory";
import InventoryItem from "../components/Inventory/InventoryItem";
import EndOfSeasonSummary from "../pages/EndOfSeasonSummary";
import TestForms from "../components/Inventory/TestForms";
import Challenges from "../components/Challenges/Challenges";
import ChallengeDetails from "../components/Challenges/ChallengeDetails";
import FeedbackComponent from "../components/Feedback/Feedback";
import Menu from "../components/Menu";
import SquadHome from "../components/SquadHome/SquadHome";
import NotificationComponent from "../components/NotificationComponent";
import ResetPassword from "../pages/ResetPassword";
import TestGif from "../pages/TestGif";
import ChallengeSelection from "../components/Challenges/ChallengeSelection";
import PurchaseTesting from "../components/Subscriptions/PurchaseTesting";

const MainRouting = ({ activityModal, showAddActivityModal, logNavigationEvent }) => {
	const [feedbackPopover, setFeedbackPopover] = useState(false);
	return (
		<IonReactRouter>
			<NotificationComponent />
			<Menu />
			{
				//console.log("In stage 3")
			}
			<IonModal isOpen={activityModal}>
				<AddActivityComponentCore showModal={showAddActivityModal} setFeedbackPopover={setFeedbackPopover} />
			</IonModal>
			<IonPopover backdropDismiss="false" cssClass="video-announcement" isOpen={feedbackPopover}>
				<FeedbackComponent setFeedbackPopover={setFeedbackPopover} />
			</IonPopover>
			<IonTabs>
				<IonRouterOutlet id="menuContent">
					<Route path="/testgif" exact>
						<TestGif />
					</Route>
					<Route path="/notificationplayground" exact>
						<NotificationPlayGround />
					</Route>
					<Route path="/addusertosquad/:squadID" exact>
						<AddUserToSquad />
					</Route>

					{/*
					<Route path="/addactivity" exact>
						<AddActivityComponent source={null} />
					</Route>
					<Route path="/feed" exact>
						<ActivityFeeds />
					</Route>
					*/}
					<Route path="/:tab(chatinterface)" exact>
						<ChatInterface />
					</Route>
					<Route path="/profile" exact>
						<UserProfile />
					</Route>
					<Route path="/otherprofile/:passedUserID" key={location.pathname}>
						<UserProfileOther />
					</Route>
					<Route path="/managesquads" exact>
						<ManageSquads />
					</Route>
					<Route path="/usersettings" exact>
						<UserSettings />
					</Route>
					<Route path="/register" exact>
						<Register />
					</Route>
					<Route path="/squadjoin" exact>
						<SquadJoin />
					</Route>
					<Route path="/squadcreate" exact>
						<SquadCreate />
					</Route>
					<Route path="/squadcreatecomplete/:squadID" exact>
						<SquadCreateComplete />
					</Route>
					<Route path="/squadcreatecompleteonboarding/:squadID" exact>
						<SquadCreateComplete onboarding={true} />
					</Route>
					<Route path="/squad/invite/:squadID" exact>
						<SquadInvite />
					</Route>
					<Route path="/squad/:squadID" key={location.pathname}>
						<SquadDetails />
					</Route>
					{/*
					<Route path="/activityfeed" exact>
						<ActivityFeeds />
					</Route>
					*/}
					<Route path="/exercisedetails/:activityID">
						<ExerciseDetails />
					</Route>
					<Route path="/systemactivitydetails/:activityID">
						<SystemActivityDetails />
					</Route>
					<Route path="/newusergoal" exact>
						<NewUserGoal />
					</Route>
					{/*
					<Route path="/endofweeksummary/:activityID">
						<EndOfWeekSummary />
					</Route>
					*/}
					<Route path="/competitioncompleted/:activityID">
						<CompetitionCompleted />
					</Route>
					<Route path="/competitioncompletedlastweek/:competitionID">
						<CompetitionCompletedLastWeek />
					</Route>
					<Route path="/squadweekcompleted/:activityID">
						<SquadWeekCompleted />
					</Route>
					<Route path="/squadlastweekcompleted/:squadID" key={location.pathname}>
						<SquadLastWeekCompleted />
					</Route>
					<Route path="/newsquadcompetition/:activityID">
						<NewSquadCompetition />
					</Route>
					<Route path="/chatroom/:roomID" key={location.pathname}>
						<ChatRoom />
					</Route>
					<Route path="/loadingPage/:page">
						<LoadingPage />
					</Route>
					<Route path="/squadscoreboard/:chatRoomID" key={location.pathname}>
						<SquadScoreboard />
					</Route>
					<Route path="/competitionscoreboard/:competitionID">
						<SquadCompetitionScoreboard />
					</Route>
					<Route path="/home" exact>
						<Home />
					</Route>
					<Route path="/botaddactivity" exact>
						<BotAddActivity />
					</Route>
					<Route path="/standings">
						<Standings />
					</Route>
					<Route path="/notifications">
						<NotificationsList />
					</Route>
					<Route path="/admin">
						<Admin />
					</Route>
					<Route path="/image/:imageURL">
						<ImageViewer />
					</Route>
					<Route path="/dailyworkout/:imageURL">
						<DailyWorkoutPage />
					</Route>
					<Route path="/squadjoined/:squadID">
						<SquadJoined />
					</Route>
					<Route path="/season/:seasonID">
						<SeasonDetails />
					</Route>
					<Route path="/season-sales-page/">
						<SeasonSalesPage />
					</Route>
					<Route path="/seasons/terms">
						<SeasonTerms />
					</Route>
					<Route path="/userinventory" exact>
						<Inventory />
					</Route>
					<Route path="/userinventory/:itemID">
						<InventoryItem />
					</Route>
					<Route path="/endofseason">
						<EndOfSeasonSummary />
					</Route>
					<Route path="/testforms">
						<TestForms />
					</Route>
					<Route path="/challenges" exact>
						<Challenges />
					</Route>
					<Route path="/challenge/:id" key={location.pathname}>
						<ChallengeDetails />
					</Route>
					<Route path="/challengeSelection/:id" key={location.pathname}>
						<ChallengeSelection />
					</Route>
					<Route path="/squadhome/:id" key={location.pathname}>
						<SquadHome />
					</Route>
					<Route path="/resetpassword/" exact>
						<ResetPassword />
					</Route>
					<Route path="/purchasetesting/" exact>
						<PurchaseTesting />
					</Route>
					{/* <Route path="/challengeselection/:id" key={location.pathname}>
						<ChallengeSelection />
					</Route> */}
					<Redirect path="" to="/home" exact />
				</IonRouterOutlet>
				<IonTabBar slot="bottom">
					<IonTabButton tab="feed" href="/home" onClick={() => logNavigationEvent("tab-bar", "home")}>
						<IonIcon icon={homeOutline}></IonIcon>
						Home
					</IonTabButton>
					<IonTabButton tab="profile" href="/profile" onClick={() => logNavigationEvent("tab-bar", "profile")}>
						<IonIcon icon={personOutline}></IonIcon>Profile
					</IonTabButton>
					<IonTabButton tab="add" onClick={() => showAddActivityModal(true)}>
						<IonIcon icon={addCircle}></IonIcon>Add Activity
					</IonTabButton>
					{/*

					<IonTabButton tab="add" href="/botaddactivity" onClick={() => logNavigationEvent("tab-bar", "bot")}>


<IonTabButton tab="add" href="/botaddactivity" onClick={() => logNavigationEvent("tab-bar", "bot")}>
						<div style={{ height: "30px", width: "55px", objectFit: "cover" }}>
							<img src="assets/images/cheerleader-banner.jpg" alt="" />
						</div>
						Cheerleadr
					</IonTabButton>

						*/}

					<IonTabButton tab="squad" href="/chatinterface" onClick={() => logNavigationEvent("tab-bar", "squad")}>
						<IonIcon icon={peopleOutline}></IonIcon>Squads
					</IonTabButton>
					<IonTabButton tab="chat" href="/challenges" onClick={() => logNavigationEvent("tab-bar", "challenges")}>
						<img src="/assets/images/medal-2.png" style={{ width: "33px" }} alt="" />
						Challenges
					</IonTabButton>
				</IonTabBar>
			</IonTabs>
		</IonReactRouter>
	);
};

export default MainRouting;
