import { menuController } from "@ionic/core";
import {
	IonBackButton,
	IonButtons,
	IonHeader,
	IonIcon,
	IonLabel,
	IonPage,
	IonSegment,
	IonSegmentButton,
	IonTitle,
	IonToolbar,
	useIonViewDidLeave,
	useIonViewWillEnter,
} from "@ionic/react";
import { analyticsOutline, chatbubblesOutline, menuOutline, newspaperOutline } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import AuthContext from "../../contexts/GlobalState";
import "../../css/SquadHome.css";
import { logGenericEvent } from "../../services/AnalyticsService";
import { getActivities, getAllChatRooms, getLatestMessagesV2, getMessagesAndActivities } from "../../services/ChatService";
import { getChatRoomIDFromSquadID, getSquad } from "../../services/SquadService";
import { StorageService } from "../../services/StorageService";
import ActivitiesRenderer from "./ActivitiesRenderer";
import Chat from "./Chat";
import SquadStats from "./SquadStats";

const SquadHome = () => {
	const { userState } = useContext(AuthContext);
	const squadID = parseInt(useParams().id);
	const [squadObject, setSquadObject] = useState(null);
	const [chatRoomObj, setChatRoomObject] = useState();
	const storageService = new StorageService();
	const [view, setView] = useState("feed");
	const [messageFeed, setMessageFeed] = useState();
	const [activityFeed, setActivityFeed] = useState([]);
	const [skeletonText, setSkeletonText] = useState(false);
	const [passedText, setPassedText] = useState("");
	const [refresher, setRefresher] = useState(Date.now()); // used to simply trigger a rerender without more info

	let interval;
	const timerInterval = 10000;

	useEffect(() => {
		setRefresher(Date.now());
	}, [messageFeed, activityFeed]);

	useIonViewWillEnter(async () => {
		setSkeletonText(true);
		let chatRoomID;
		let squad = await getSquad(squadID);
		setSquadObject(squad);
		if (!squad.chatRoomID) {
			chatRoomID = await getChatRoomIDFromSquadID(squadID);
		} else {
			chatRoomID = await getChatRoomIDFromSquadID(squadID);
		}
		console.log({ chatRoomID });
		let chatRoomObject = await storageService.getObject(chatRoomID);
		console.log({ chatRoomObject });
		setChatRoomObject(chatRoomObject);
		if (chatRoomObject === null) {
			let chatRooms = await getAllChatRooms(true, true);
			// console.log({ chatRooms });
			for await (let room of chatRooms) {
				room.roomPage = "/chatroom/" + room.id;
				await storageService.setObject(room.id, room);
				await getMessagesAndActivities(room);
			}
			chatRoomObject = await storageService.getObject(chatRoomID);
			console.log({ chatRoomObject });
			setChatRoomObject(chatRoomObject);
		}
		//let localFeed = await storageService.getObject("feed-" + chatRoomObject.id);
		let localActivityFeed = await storageService.getObject("feed-" + chatRoomID);
		// console.log({ localActivityFeed });
		let localMessageFeed = await storageService.getObject("messages-" + chatRoomID);
		if (localMessageFeed) {
			setMessageFeed(localMessageFeed);
		}
		if (localActivityFeed) {
			setActivityFeed(localActivityFeed.reverse());
		}
		setSkeletonText(false);
		interval = setInterval(() => {
			console.log("Timer pull triggered");
			getLatestMessagesV2(chatRoomObject).then(async (timerPull) => {
				if (timerPull !== null) {
					if ((await storageService.getItem("acceptTimerPull-messages")) !== "false") {
						console.log("acceptTimerPull-messages != false. Using result from timer pull");
						let latestMessages = await storageService.getObject("messages-" + chatRoomObject.id);
						setMessageFeed(latestMessages);
						setRefresher(Date.now()); // gets used to trigger refresh in child components
					} else {
						console.log("acceptTimerPull-messages = false. Throwing away result from timer pull");
					}
				} else {
					let latestMessages = await storageService.getObject("messages-" + chatRoomObject.id);
					setMessageFeed(latestMessages);
					setRefresher(Date.now()); // gets used to trigger refresh in child components
				}
				console.log("Timer pull end");
			});
			getActivities(chatRoomObject).then(async (timerPull) => {
				if (timerPull !== null) {
					//console.log("timerPull: " + JSON.stringify(timerPull));
					if ((await storageService.getItem("acceptTimerPull-activities")) !== "false") {
						let latestActivities = await storageService.getObject("feed-" + chatRoomObject.id);
						setActivityFeed(latestActivities.reverse());
						setRefresher(Date.now());
					} else {
						console.log("acceptTimerPull-activities = false. Throwing away result from timer pull");
					}
				} else {
					let latestActivities = await storageService.getObject("feed-" + chatRoomObject.id);
					setActivityFeed(latestActivities.reverse());
					setRefresher(Date.now());
				}
				console.log("Timer pull end");
			});
		}, timerInterval);
	});

	useIonViewDidLeave(() => {
		clearInterval(interval);
	});

	const refresh = async (event) => {
		setRefresher(Date.now()); // this is used to trigger useEffect hooks in child components
		setSquadObject(await getSquad(squadID));

		if (event)
			setTimeout(() => {
				console.log("Async operation has ended");
				event.detail.complete();
			}, 2000);
	};
	const onClickHandler = () => {
		logGenericEvent("Menu");
		menuController.open();
	};
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonIcon slot="start" icon={menuOutline} onClick={() => onClickHandler()} size="large" />
					<IonButtons slot="start">
						<IonBackButton text="" />
					</IonButtons>
					{squadObject ? <IonTitle>{squadObject.name}</IonTitle> : <IonTitle>Squad Home</IonTitle>}
				</IonToolbar>
			</IonHeader>

			<div style={{ minHeight: "10%" }}>
				<IonSegment mode="ios" color="tertiary" value={view} onIonChange={(e) => setView(e.detail.value)} style={{ position: "fixed", zIndex: "20000" }}>
					<IonSegmentButton className="squad-home-segement-button" value="feed" layout="icon-top">
						<IonIcon icon={newspaperOutline} />
						<IonLabel className="squad-home-label">Feed</IonLabel>
					</IonSegmentButton>
					<IonSegmentButton className="squad-home-segement-button" value="stats" layout="icon-top">
						<IonIcon icon={analyticsOutline} />
						<IonLabel className="squad-home-label">Stats</IonLabel>
					</IonSegmentButton>
					<IonSegmentButton className="squad-home-segement-button" value="chat" layout="icon-top">
						<IonIcon icon={chatbubblesOutline} />
						<IonLabel className="squad-home-label">Chat</IonLabel>
					</IonSegmentButton>
				</IonSegment>
			</div>

			{/* <IonRefresher slot="fixed" onIonRefresh={refresh}>
					<IonRefresherContent refreshingText="Updating Squad Data"></IonRefresherContent>
				</IonRefresher> */}
			{squadObject && view === "feed" ? (
				<ActivitiesRenderer setRefresher={setRefresher} feed={activityFeed} setFeed={setActivityFeed} chatRoomObj={chatRoomObj} refresher={refresher} />
			) : (
				""
			)}
			{/* {squadObject && view === "feed" && activityFeed && activityFeed.length > 0 ? (
				<ActivitiesRenderer feed={activityFeed} setFeed={setActivityFeed} chatRoomObj={chatRoomObj} refresher={refresher} />
			) : (
				"Loading activity feed..."
			)} */}
			{squadObject && view === "chat" ? (
				<Chat passedText={passedText} skeletonText={skeletonText} feed={messageFeed} setFeed={setMessageFeed} chatRoomObj={chatRoomObj} refresher={refresher} />
			) : (
				""
			)}
			{squadObject && view === "stats" ? <SquadStats squadObject={squadObject} refresher={refresher} /> : ""}
			{/* {squadID ? <CoachMarkController setTextValue={setPassedText} source={"squadHome"} squadID={squadID} /> : ""} */}
		</IonPage>
	);
};

export default SquadHome;
