import React, { useRef, useState } from "react";
import { IonButton, IonButtons, IonHeader, IonIcon, IonInput, IonTitle, IonToolbar } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import "../../css/Home.css";
import "../../css/ActivityDetails.css";
import { validateCode } from "../../services/SquadService";
import { useHistory } from "react-router";

const EnterSquadCode = ({ setCodeEntry }) => {
	const history = useHistory();
	const [codeError, setCodeError] = useState(false);
	const [validatingCode, setValidatingCode] = useState(false);
	const codeRef = useRef(null);

	const tryCode = async () => {
		let code = codeRef.current.value;
		let response = await validateCode(code.toLowerCase());
		setValidatingCode(true);
		if (Object.keys(response).length === 0) {
			setValidatingCode(false);
			setCodeError(true);
		} else {
			setValidatingCode(false);
			history.push("/squadjoined/" + response.id);
		}
	};

	return (
		<React.Fragment>
			<IonHeader>
				<IonToolbar className="activity-details-toolbar">
					<IonTitle>Enter Code</IonTitle>
					<IonButton color="tertiary" slot="end" onClick={() => setCodeEntry(false)}>
						<IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
					</IonButton>
				</IonToolbar>
			</IonHeader>
			<div style={{ margin: "16px", textAlign: "center" }}>
				<IonInput ref={codeRef} name="code" type={"text"} placeholder="Squad code" enterkeyhint="done" onFocus={() => setCodeError(false)} required></IonInput>
				<IonButton expand="block" onClick={() => tryCode()}>
					Try code
				</IonButton>
				{codeError === true ? <p className="onboarding-error-message">Could not find a squad with that code</p> : ""}
			</div>
		</React.Fragment>
	);
};

export default EnterSquadCode;
