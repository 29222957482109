import { IonButton, IonCol, IonContent, IonHeader, IonIcon, IonInput, IonLoading, IonPopover, IonRow, IonTitle, IonToolbar } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import { getQuantityOfItemByItemID, redeemItem, updateInventoryItem } from "../../services/InventoryService";
import { sendSlackErrorNotification, sendSlackNotification } from "../../services/NotificationService";
import { getUser, updateUser } from "../../services/UserService";

const CollectEmail = ({ itemDetails, userItemID, setModal, setItemQuantity }) => {
	const [email, setEmail] = useState("");
	const [updating, setUpdating] = useState(false);
	const [showPopOver, setShowPopOver] = useState(false);
	const [codeError, setCodeError] = useState();
	const [emailError, setEmailError] = useState();

	const emailRef = useRef(null);

	useEffect(() => {
		populateFields();
	}, []);

	const populateFields = async () => {
		let user = JSON.parse(await getUser());
		setEmail(user.email ? user.email : "");
	};

	/**
	 *
	 * Need to complete the redeeming here if an itemID is passed
	 * because item redemption shouldn't happen until address is confirmed
	 *
	 */
	const sendEmail = async () => {
		let user = JSON.parse(await getUser(null, false));
		let validData = true;
		let response;
		if (emailRef.current?.value === "") {
			setEmailError("required");
			validData = false;
		} else {
			setEmailError("");
		}
		if (validData === true) {
			setUpdating(true);
			let body = {
				email: emailRef.current?.value ? emailRef.current.value : email,
			};
			if (body.email !== user.email) {
				// update it
				response = await updateUser(body).catch((error) => {
					alert(
						"There was an error collecting your information. Please try again. If you continue to run into issues please contact support at support@fitsquadapp.com"
					);
				});
			} else {
				//nothing changed!
				response = true;
			}

			if (response) {
				if (userItemID) {
					let response = await redeemItem(userItemID).catch((error) => {
						setShowPopOver(false);
						alert(
							"There was an error redeeming your item. Please try again. If you continue to run into issues please contact support at support@fitsquadapp.com"
						);
					});
					if (response && response.length > 0) {
						setItemQuantity(await getQuantityOfItemByItemID(itemDetails.id, true));
						let body = {
							readyForProcessing: true,
						};
						await updateInventoryItem(userItemID, body);
						sendSlackNotification(
							`*NEW ITEM READY FOR PROCESSING*\nUsername: ${user.userName}\nItem: ${itemDetails.name}\nItem ID : ${itemDetails.id}\nUser Item ID: ${userItemID}`,
							"keyEvent"
						);
						setShowPopOver(false);
					} else {
						setShowPopOver(false);
						// error cases... what are they?
						alert(
							"There was an error redeeming your item. Please try again. If you continue to run into issues please contact support at support@fitsquadapp.com"
						);
						sendSlackErrorNotification(
							"Redeem item returned empty response",
							"InventoryItem",
							`\nUserItemID: ${userItemID}\nUser ID: ${user.cognitoId}\nUsername: ${user.userName}\nItem Details: ${JSON.stringify(itemDetails)}`
						);
					}
				}
				setUpdating(false);
				setShowPopOver(true);
			}
		}
	};

	const closeModal = async () => {
		setModal({ visible: false });
	};

	return (
		<React.Fragment>
			<IonContent>
				<IonHeader>
					<IonToolbar>
						<IonTitle>Confirm Email</IonTitle>
						<IonButton color="secondary" slot="end" onClick={() => setModal({ visible: false })}>
							<IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
						</IonButton>
					</IonToolbar>
				</IonHeader>
				<IonLoading isOpen={updating} onDidDismiss={() => setUpdating(false)} message={"Confirming"} duration={3000} />
				<IonPopover
					//event={showGoalConfirmation.event}
					isOpen={showPopOver}
					cssClass="gif-response"
					onDidDismiss={(e) => closeModal()}
				>
					<div style={{ fontSize: "1.25em", padding: "10%", textAlign: "center" }}>
						Information accepted! We'll contact you via email about next steps on redeeming your {itemDetails.name}
					</div>
				</IonPopover>
				<div className="collect-address-div">
					<div className="collect-address-subtitle">Please enter or confirm your email address below so we can confirm your {itemDetails.name}</div>
					<IonRow>
						<IonCol size="12">
							<IonRow>Email Address*</IonRow>
							<IonRow>
								{email ? (
									<IonInput
										className="collect-info-input"
										ref={emailRef}
										value={email}
										name="email"
										type={"email"}
										placeholder="e.g. you@gmail.com"
										enterkeyhint="next"
										required
									></IonInput>
								) : (
									<IonInput
										className="collect-info-input"
										ref={emailRef}
										name="email"
										type={"email"}
										placeholder="e.g. you@gmail.com"
										enterkeyhint="next"
										required
									></IonInput>
								)}
							</IonRow>
							{emailError !== "" ? <IonRow className="error-message">{emailError}</IonRow> : ""}
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol style={{ textAlign: "center" }} size="12">
							<IonButton onClick={() => sendEmail()} color="success">
								Submit
							</IonButton>
						</IonCol>
					</IonRow>
				</div>
			</IonContent>
		</React.Fragment>
	);
};

export default CollectEmail;
