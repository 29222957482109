import { IonContent, IonCard, IonImg, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonSpinner } from "@ionic/react";
import React, { useEffect, useState } from "react";
import "../../css/Home.css";
import "../../css/ActivityDetails.css";
import Emoji from "react-emoji-render";
import { getSquad } from "../../services/SquadService";
import "../../css/CompetitionCompleted.css";
import SquadSummaryComponent from "../../components/SquadSummaryComponent";

const SquadSummary = (props) => {
	let squadID = props.squadID;
	const [squadObj, setSquad] = useState();
	const [loading, setLoading] = useState(true);
	//let remainingSquadPoints;
	useEffect(() => {
		getSquadData(squadID);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getSquadData = async () => {
		getSquad(squadID).then((squad) => {
			//console.log("squadsummary sid: ", { squad });
			setSquad(squad);
			setLoading(false);
		});
	};

	return (
		<React.Fragment>
			<IonCard>
				{squadObj && squadID ? (
					<div>
						<IonImg src={squadObj.image} alt="" />
						<IonCardHeader>
							<IonCardSubtitle>
								<Emoji text={squadObj.slogan} />
							</IonCardSubtitle>
							<IonCardTitle>Weekly Summary for {squadObj.name}</IonCardTitle>
						</IonCardHeader>
					</div>
				) : (
					<IonSpinner />
				)}
				<IonCardContent>
					<SquadSummaryComponent squadIDPassed={squadID} setLoading={setLoading} />
					{loading === true ? <IonSpinner /> : ""}
				</IonCardContent>
			</IonCard>
		</React.Fragment>
	);
};

export default SquadSummary;
