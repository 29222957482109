import { IonContent, getPlatforms, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonTextarea, useIonViewDidEnter } from "@ionic/react";
import React, { useState } from "react";
import { Plugins } from "@capacitor/core";
import { useHistory } from "react-router";
import { getUserPoints } from "../services/PointsAndGoalsService";
import { updateUser } from "../services/UserService";
import { StorageService } from "../services/StorageService";
import { addEmoji, getChatRooms, postChatRoomMessage, sendMessage } from "../services/ChatService";
import { setupNotificationListener, storeDeliveredNotifications } from "../services/NotificationService";
import { addReaction } from "../services/FeedService";
import { getChatRoomIDFromSquadID, getChatRoomObjFromSquadID } from "../services/SquadService";

function NotificationPlayGround() {
	const [log, setLog] = useState("");
	const history = useHistory();
	const storageService = new StorageService();

	const { PushNotifications, LocalNotifications, Device } = Plugins;

	const showRecentPushes = async () => {
		console.log("Pulling recent notifications");
		let recentNotifications = await storageService.getObject("deliveredNotifications");
		setLog(JSON.stringify(recentNotifications));
	};

	const showLog = (message) => {
		console.log(message);
		setLog((log) => [...log, "\n" + message]);
	};

	async function setupPush() {
		let body;
		let pushToken = await storageService.getItem("pushToken");
		if (pushToken !== "") {
			showLog("Your pushToken: " + pushToken);
		} else {
			showLog("No push token stored");
		}
		//LocalNotifications.requestPermission();
		PushNotifications.addListener("registration", async (token) => {
			storageService.setItem("pushToken", token.value);
			const platformsArray = getPlatforms();
			console.log("Platforms: ", { platformsArray });
			platformsArray.forEach((platform) => {
				if (platform === "ios") {
					body = {
						deviceToken: token.value.toLowerCase(),
					};
					console.log("User is on iOS. Using that payload");
				}
				if (platform === "android") {
					body = {
						registrationId: token.value,
					};
					console.log("User is on Android. Using that payload");
				}
			});
			await updateUser(body);
		});

		// Some issue with our setup and push will not work
		PushNotifications.addListener("registrationError", (error) => {
			showLog("PUSH - Error on registration: " + JSON.stringify(error));
		});

		const permResult = await PushNotifications.requestPermission();
		if (permResult.granted) {
			// Register with Apple / Google to receive push via APNS/FCM
			const result = await PushNotifications.register();
			showLog("register result: " + JSON.stringify(result));
		} else {
			showLog("push notifications wont work");
			showLog("permResult: " + JSON.stringify(permResult));
		}
		//const result = await PushNotifications.register();
		//console.log("register result: ", result);
	}

	const scheduleDailyNotification1 = () => {
		setLog((log) => [...log, "Scheduling morning update<br>"]);
		//setLog((log) => [...log, '<br>Date of next morning update: '+morningUpdateSchedule.format("MM/DD/YY h:mm:ss a")]);
		LocalNotifications.schedule({
			notifications: [
				{
					title: "Daily Notification 'on'",
					body: "Here is your daily update on your FitSquad Progress",
					id: 1,
					schedule: {
						on: {
							year: 2020,
							month: 11,
							day: 10,
							hour: 14,
							minute: 10,
							second: 0,
						},
						repeats: true,
						every: "minute",
						count: 5,
					},
					extra: {
						destination: "settings",
					},
				},
			],
		});
	};

	const scheduleDailyNotification2 = () => {
		setLog((log) => [...log, "Scheduling morning update<br>"]);
		//let newDate = new Date(2020, 11, 10, 14, 40, 5)
		showLog("Show every day at 7am");
		LocalNotifications.schedule({
			notifications: [
				{
					title: "Daily Notification",
					body: "7am",
					id: 2,
					schedule: {
						repeats: true,
						every: "day",
						on: {
							hour: 7,
							minute: 0,
						},
						count: 1,
					},
					actionTypeId: "sendNumbers",
					extra: {
						destination: "dailyupdate",
					},
				},
			],
		});
	};

	const scheduleDailyNotification3 = () => {
		setLog((log) => [...log, "Scheduling morning update<br>"]);
		LocalNotifications.schedule({
			notifications: [
				{
					title: "Daily Notification every 30 mins",
					body: "Here is your daily update on your FitSquad Progress",
					id: 3,
					schedule: {
						every: "minute",
						count: 30,
						repeats: true,
					},
					extra: {
						destination: "settings",
					},
				},
			],
		});
	};

	const setupListeners = () => {
		LocalNotifications.addListener("localNotificationActionPerformed", (notification) => {
			showLog("Result from localNotificationActionPerformed: " + JSON.stringify(notification));
			history.push("/" + notification.notification.extra.destination);
		});
		LocalNotifications.addListener("localNotificationReceived", async (notification) => {
			showLog("Result from localNotificationActionReceived" + JSON.stringify(notification));
			if (notification.actionTypeId === "sendNumbers") {
				showLog("Sending numbers");
				await getUserPoints();
				sendImmediateNotification();
			}
		});
		console.log("localNotificationActionPerformed & localNotificationActionReceived listener added");
	};

	const scheduleTenSecondNotification = () => {
		setLog((log) => [...log, "Scheduling 60 second update<br>"]);
		LocalNotifications.schedule({
			notifications: [
				{
					title: "Sixty Second Push",
					body: "This will fire every 60 seconds",
					id: 70,
					schedule: {
						//at: new Date(Date.now()+5000),
						repeats: true,
						every: "second",
						count: 60,
					},
					actionTypeId: "sendNumbers",
					extra: {
						destination: "feed",
					},
				},
			],
		});
	};

	const sendImmediateNotification = async () => {
		let currentPoints = await getUserPoints();
		showLog("This will send 30 seconds into the future");
		LocalNotifications.schedule({
			notifications: [
				{
					title: "IMMEDIATE",
					body: "You have " + currentPoints + " points",
					id: 100,
					schedule: {
						on: new Date(Date.now() + 30000),
					},
					extra: {
						destination: "dailyUpdate",
					},
				},
			],
		});
	};

	const removeListeners = () => {
		LocalNotifications.removeAllListeners();
		setLog((log) => [...log, "All listeners removed"]);
	};

	const listAllNotifications = async () => {
		let pending = await LocalNotifications.getPending();
		console.log("Pending: " + JSON.stringify(pending));
		setLog((log) => [...log, "Pending: " + JSON.stringify(pending)]);
	};

	const clearAllNotifications = async () => {
		setLog((log) => [...log, "Clearing all notifications..."]);
		let pending = await LocalNotifications.getPending();
		pending.notifications.forEach((element) => {
			console.log("Element: " + JSON.stringify(element));
			let result = LocalNotifications.cancel({
				notifications: [{ id: element.id }],
			});
			setLog((log) => [...log, "ID " + element.id + " cleared"]);
			setLog((log) => [...log, "Result: " + JSON.stringify(result)]);
		});
	};

	const showToken = async () => {
		let pushToken = await storageService.getItem("pushToken");
		if (pushToken !== "") {
			showLog("<br><br><b>Your pushToken:</b><br>" + pushToken);
		} else {
			showLog("<br><br><b>No push token stored</b><br>");
		}
	};

	const setupPushListeners = async () => {
		setupNotificationListener();
		/*
		LocalNotifications.requestPermission();
		LocalNotifications.addListener("localNotificationActionPerformed", async (action) => {
			let deviceInfo = await Device.getInfo();
			console.log("DeviceInfo: ", { deviceInfo });
			if (deviceInfo.platform === "android") {
				console.log("Action performed: " + JSON.stringify(action));
				if (action.actionId === "tap") {
					if (action.notification.extra) {
						showLog("Data payload: " + JSON.stringify(action.notification.extra));
						showLog("ChatRoom: " + action.notification.extra.payload.chatRoom);
						if (action.notification.extra.payload.chatRoom) {
							history.push("/chatroom/" + action.notification.extra.payload.chatRoom);
						}
					}
				} else if (action.actionId === "free_text") {
					console.log("squadID: " + action.notification.extra.activityData.squadId);

					let chatRoomID = await getChatRoomIDFromSquadID(action.notification.extra.activityData.squadId);
					console.log("ChatRoomID: " + chatRoomID);
					postChatRoomMessage(chatRoomID, action.inputValue);
				} else {
					let response = await addEmoji(action.notification.id, action.actionId);
					console.log("Response from addEmoji: " + response);
				}
			}
		});
		LocalNotifications.registerActionTypes({
			types: [
				{
					id: "workout-1",
					actions: [
						{
							id: ":+1:",
							title: "👍",
							destructive: true,
						},
						{
							id: ":muscle:",
							title: "💪",
							destructive: true,
						},
						{
							id: "free_text",
							title: "Respond",
							input: true,
						},
					],
				},
				{
					id: "workout-2",
					actions: [
						{
							id: ":raised_hands:",
							title: "🙌",
							destructive: true,
						},
						{
							id: ":sign_of_the_horns:",
							title: "🤘",
							destructive: true,
						},
						{
							id: "free_text",
							title: "Respond",
							input: true,
						},
					],
				},
				{
					id: "workout-3",
					actions: [
						{
							id: ":ok_hand:",
							title: "👌",
							destructive: true,
						},
						{
							id: ":clap:",
							title: "👏",
							destructive: true,
						},
						{
							id: "free_text",
							title: "Respond",
							input: true,
						},
					],
				},
				{
					id: "workout-4",
					actions: [
						{
							id: ":punch:",
							title: "👊",
							destructive: true,
						},
						{
							id: ":grinning:",
							title: "😀",
							destructive: true,
						},
						{
							id: "free_text",
							title: "Respond",
							input: true,
						},
					],
				},
			],
		});
		PushNotifications.addListener("pushNotificationReceived", async (notification) => {
			let deviceInfo = await Device.getInfo();
			console.log("DeviceInfo: ", { deviceInfo });
			if (deviceInfo.platform === "android") {
				let dataPayload;
				if (notification?.data?.payload) {
					dataPayload = await JSON.parse(notification.data.payload);
					console.log("Notification: " + JSON.stringify(dataPayload));
					if (dataPayload["payload"]["activityData"]) {
						// it's an activtiy
						console.log("Datatype: " + dataPayload["payload"]["activityData"]["dataType"]);
						console.log("Activity ID: " + dataPayload["payload"]["sourceActivityId"]);
						let randResponse = Math.floor(Math.random() * Math.floor(4)) + 1;
						let actTypeID = dataPayload["payload"]["activityData"]["dataType"] + "-" + randResponse;
						console.log("randResponse: " + actTypeID);
						await LocalNotifications.schedule({
							notifications: [
								{
									title: dataPayload["title"],
									body: dataPayload["body"],
									id: dataPayload["payload"]["id"],
									schedule: {
										on: new Date(Date.now() + 5000),
									},
									channelId: "primary",
									extra: {
										activityData: dataPayload,
									},
									actionTypeId: actTypeID,
								},
							],
						});
					} else {
						console.log("ID: " + dataPayload["payload"]["id"]);
						// it's a chat message
						await LocalNotifications.schedule({
							notifications: [
								{
									title: dataPayload["title"],
									body: dataPayload["body"],
									id: Date.now(),
									schedule: {
										on: new Date(Date.now() + 5000),
									},
									channelId: "primary",
									extra: {
										payload: dataPayload["payload"],
									},
									actionTypeId: null,
								},
							],
						});
					}
				} else {
					// this shouldn't happen
					console.log("No data payload");
				}
			}
			showLog("\n\nPUSH - pushNotificationReceived *NEW!*: " + JSON.stringify(notification));
		});

		PushNotifications.addListener("pushNotificationActionPerformed", (action) => {
			showLog("\n\nPUSH - pushNotificationActionPerformed: " + JSON.stringify(action));
			const platformsArray = getPlatforms();
			console.log("Platforms: " + JSON.stringify(platformsArray));
			platformsArray.forEach(async (platform) => {
				if (platform === "ios") {
					if (action.notification.data.aps.alert.payload.activityData?.dataType === "workout") {
						if (action.actionId === "free_text") {
							console.log("squadID: " + action.notification.data.aps.alert.payload.squadId);

							let chatRoomID = await getChatRoomIDFromSquadID(action.notification.data.aps.alert.payload.squadId);
							console.log("ChatRoomID: " + chatRoomID);
							postChatRoomMessage(chatRoomID, action.inputValue);
						} else {
							let response = await addEmoji(action.notification.data.aps.alert.payload.id, action.actionId);
							console.log("Response from addEmoji: " + response);
						}
					} else {
						showLog("PUSH - pushNotificationActionPerformed: " + JSON.stringify(action.data.aps.alert.payload.squadId));
						if (action.data.aps.alert.payload.squadId) {
							let squadID = action.data.aps.alert.payload.squadId;
							showLog("\nLooking for chat room for squadID " + squadID);
							showLog("\nGetting chat room");
							let chatRooms = await getChatRooms(false);
							let chatRoomID = null;
							for (let roomData of chatRooms) {
								showLog("\n\nchecking roomData: " + JSON.stringify(roomData));
								if (roomData.squadIdA === squadID && roomData.squadIdB !== null && chatRoomID === null) {
									showLog("\nFound the competition one in A: " + roomData.id);
									chatRoomID = roomData.id;
								} else if (roomData.squadIdA === squadID && chatRoomID === null) {
									showLog("\nFound the solo one in A: " + roomData.id);
									chatRoomID = roomData.id;
								} else if (roomData.squadIdB === squadID && roomData.squadIdA !== null && chatRoomID === null) {
									showLog("\nFound the competition one in B: " + roomData.id);
									chatRoomID = roomData.id;
								} else if (roomData.squadIdB === squadID && chatRoomID === null) {
									showLog("\nFound the solo one in B: " + roomData.id);
									chatRoomID = roomData.id;
								}
							}
						} else {
							showLog("\n\n NO SQUAD ID");
						}
						console.log("User is on iOS. Using that payload");
					}
				}
				if (platform === "android") {
					showLog("PUSH - pushNotificationActionPerformed: " + JSON.stringify(action));
					if (action.notification.data.payload) {
						let payload = JSON.parse(action.notification.data.payload);
						showLog("Data payload: " + JSON.stringify(payload));
						showLog("ChatRoom: " + payload.chatRoom);
						if (payload.chatRoom) {
							history.push("/chatroom/" + payload.chatRoom);
						}
					}
				}
			});
		});

		showLog("\n\nPUSH Listeners set");
		*/
	};

	const getDeliveredNotifications = async () => {
		await storeDeliveredNotifications();
	};

	const clearDeliveredNotifications = async () => {
		PushNotifications.removeAllDeliveredNotifications();
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Settings</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonButton onClick={getDeliveredNotifications}>Get Delivered Notifications</IonButton>
				<IonButton onClick={showRecentPushes}>Show Delivered Notifications</IonButton>
				<IonButton onClick={clearDeliveredNotifications}>Clear Delivered Notifications</IonButton>

				<IonButton onClick={sendImmediateNotification}>Send Immediate Notification</IonButton>
				<IonButton onClick={setupListeners}>setupListeners</IonButton>
				<IonButton onClick={setupPushListeners}>setupPushListeners</IonButton>
				<IonButton onClick={removeListeners}>removeListeners</IonButton>
				<IonButton onClick={setupPush}>Setup Push</IonButton>
				<IonButton onClick={showToken}>Show Token</IonButton>
			</IonContent>
			<IonContent>
				<IonTextarea value={log} autoGrow="true" readonly="true" />
			</IonContent>
		</IonPage>
	);
}

export default NotificationPlayGround;
