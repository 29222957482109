import { IonCol, IonPopover, IonRow, IonSpinner, useIonViewWillEnter } from "@ionic/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "../../css/Home.css";
import "../../css/Seasons.css";
import { hasSeasonStarted, isSeasonOpenForRegistration } from "../../services/SeasonsService";

const SeasonsStatusComponent = ({ seasonObject, userSeasonObject }) => {
	const [seasonStatus, setSeasonStatus] = useState({
		status: "tbd",
		message: "loading",
	});

	useEffect(() => {
		processStatus();
	}, [userSeasonObject]);

	const processStatus = async () => {
		let userInSeason;

		// Is season is open for registration?
		if (isSeasonOpenForRegistration(seasonObject) === true) {
			seasonObject.registrationStatus = "open";
		} else {
			seasonObject.registrationStatus = "closed";
		}

		// Is season underway?
		if ((await hasSeasonStarted(seasonObject)) === true) {
			seasonObject.status = "underway";
		}
		if (moment() < moment(seasonObject.startDate)) {
			seasonObject.status = "upcoming";
		}
		if (moment() >= moment(seasonObject.endDate)) {
			seasonObject.status = "completed";
		}

		// Is/was user in this season?
		if (userSeasonObject === undefined) {
			userInSeason = false;
		} else {
			userInSeason = true;
		}

		let status = `${seasonObject.registrationStatus}-${seasonObject.status}-${userInSeason}`;
		//console.log(`Season status: ${status}`);
		let message;

		switch (status) {
			case "open-underway-false":
				message = "Season is under way! Get in before registration closes on " + moment(seasonObject.registrationCloses).format("dddd, MMMM Do") + ".";
				break;
			case "open-underway-true":
				message = "Season is under way! Good luck hitting your goal!";
				break;
			case "open-upcoming-false":
				message = "Season is coming up on " + moment(seasonObject.startDate).format("dddd, MMMM Do") + "! Get in before it kicks off";
				break;
			case "open-upcoming-true":
				message = "You're in for the upcoming season. It begins on " + moment(seasonObject.startDate).format("dddd, MMMM Do") + ". Good luck!";
				break;
			case "closed-underway-false":
				message =
					"You missed out on this season. It started on " + moment(seasonObject.startDate).format("dddd, MMMM Do") + ". Stay tuned for for the next one.";
				break;
			case "closed-underway-true":
				message = "Season is under way! Good luck hitting your goal!";
				break;
			case "closed-upcoming-false":
				message = "This season is coming up! You can register beginning on " + moment(seasonObject.registrationOpens).format("dddd, MMMM Do");
				break;
			case "closed-upcoming-true":
				message = "You're in for the upcoming season. It begins on " + moment(seasonObject.startDate).format("dddd, MMMM Do") + ". Good luck!";
				break;
			case "closed-completed-false":
				message = "This season was completed on " + moment(seasonObject.endDate).format("dddd, MMMM Do") + ".";
				break;
			case "closed-completed-true":
				message = "This season is completed on " + moment(seasonObject.endDate).format("dddd, MMMM Do") + ". Here were your results";
				break;
			default:
				break;
		}
		setSeasonStatus({
			status: status,
			message: message,
		});
	};
	return (
		<React.Fragment>
			<div style={{ marginLeft: "16px", marginRight: "16px" }}>
				<div>
					<div className="section-title" style={{ paddingLeft: "0px" }}>
						Season Status
					</div>
					<div>{seasonStatus.message}</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default SeasonsStatusComponent;
