/* eslint-disable jsx-a11y/accessible-emoji */
import {
	IonAlert,
	IonBackButton,
	IonButton,
	IonButtons,
	IonCard,
	IonCardContent,
	IonChip,
	IonCol,
	IonContent,
	IonHeader,
	IonIcon,
	IonLabel,
	IonLoading,
	IonModal,
	IonPage,
	IonPopover,
	IonRefresher,
	IonRefresherContent,
	IonRow,
	IonSearchbar,
	IonTitle,
	IonToast,
	IonToolbar,
	useIonViewWillEnter,
} from "@ionic/react";
import { close, closeCircleSharp, filterSharp } from "ionicons/icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import AuthContext from "../../contexts/GlobalState";
import "../../css/SquadJoin.css";
import { logGenericEvent, logPageView } from "../../services/AnalyticsService";
import { coachMarkCheck } from "../../services/HelperServices";
import { sendSlackNotification } from "../../services/NotificationService";
import { getChatRoomIDFromSquadID, getSquad } from "../../services/SquadService";
import { getSquadTags, getTagNameFromTagID } from "../../services/TagService";
import { deleteUserFromSquad, getUserID, inviteUserToSquad } from "../../services/UserService";
import CoachMarkController from "../CoachMarks/CoachMarkController";
import JoinSquadCoachMark from "../CoachMarks/JoinSquadCoachMark";
import EnterSquadCode from "./EnterSquadCode";
import JoinSquadFilters from "./JoinSquadFilters";
import JoinSquadPopover from "./JoinSquadPopover";
import RequestSquadPopover from "./RequestSquadPopover";

const SquadJoin = () => {
	const history = useHistory();
	const { userState, updateUserState } = useContext(AuthContext);
	const [showCoachMark, setShowCoachMark] = useState(false);
	const [searchText, setSearchText] = useState("");
	const [squadObject, setSquadObject] = useState([]);
	const [foundSquads, setFoundSquads] = useState([]);
	const [allSquads, setAllSquads] = useState([]);
	const [allTags, setAllTags] = useState([]);
	const [updating, setUpdating] = useState({
		visible: false,
		message: null,
	});
	const [loading, setLoading] = useState(false);
	const [inviteToast, setInviteToast] = useState(false);
	const [codeEntry, setCodeEntry] = useState(false);
	const [waitingToast, showWaitingToast] = useState(false);
	const [showCancelledToast, setShowCancelledToast] = useState(false);
	const [quitSquadAlert, setQuitSquadAlert] = useState({
		visible: false,
		squadID: null,
		squadName: null,
	});
	const [quitSquadToast, setQuitSquadToast] = useState(false);
	const [declineInviteAlert, setDeclineInviteAlert] = useState({
		visible: false,
		message: null,
		squadID: null,
		squadName: null,
	});
	const [userID, setUserID] = useState("");
	const [showFilters, setShowFilters] = useState(false);
	const [fullSquadAlert, setFullSquadAlert] = useState(false);
	const [filters, setFilters] = useState([]);
	const [joinSquad, setJoinSquad] = useState({
		visible: false,
		squadID: null,
		squadName: null,
		message: null,
	});
	const [requestSquad, setRequestSquad] = useState({
		visible: false,
		squadID: null,
		squadName: null,
		message: null,
	});
	const [showJoinSquadConfirmed, setShowJoinSquadConfirmed] = useState({
		visible: false,
		squadObject: null,
		chatRoomID: null,
	});
	const [showRequestSquadConfirmed, setShowRequestSquadConfirmed] = useState({
		visible: false,
		squadObject: null,
		chatRoomID: null,
	});

	const searchTerm = useRef(null);

	useIonViewWillEnter(async () => {
		console.log({ userState });
		setLoading(true);
		setFilters([]); // for going forward and back
		logPageView("squad join");
		getUserID().then((uID) => {
			setUserID(uID);
		});
		getTags();
		getSquads().then(async () => {
			setLoading(false);
			await coachMarkCheck(setShowCoachMark, "joinSquad");
		});
	}, []);

	useEffect(() => {
		//console.log({ filters });
		if (filters.length > 0) {
			// Take Foundsquads, look at the tags array and see if any elements in the array have an id that is in filters
			// If it does add it to the array to show. If not exclude it
			let result = squadObject.filter((obj) => {
				let deeperResult = obj.tags.filter((tag) => filters.includes(tag.id));
				if (deeperResult.length > 0 && obj.showInSearch === true) {
					return obj;
				}
			});
			setFoundSquads(result);
		} else {
			console.log("Showing all squads");
			getSquads();
		}
	}, [filters]);

	const getTags = async () => {
		let tagList = await getSquadTags(true);
		let tagListSelected = [];
		tagList.forEach((tag) => {
			tag.selected = false;
			tagListSelected.push(tag);
		});
		setAllTags(tagListSelected);
	};

	const getSquads = async (event, refresh = false) => {
		//setLoading(true);
		let uID;
		let allSquads = await getSquad(null, 1000, 0, refresh).catch((error) => {
			throw Error(error);
		});
		console.log({ userState });
		if (userState.user === null || userState === null) {
			uID = await getUserID();
			updateUserState();
		} else {
			uID = userState.user.cognitoId;
		}
		// Only render squads the user is not in
		if (allSquads.length > 0) {
			let tempArray = [];
			for (let element of allSquads) {
				//console.log({ element });
				if (element.isPrivate === true) {
					element.buttonType = "request";
				} else {
					element.buttonType = "join";
				}
				if (element.capacity > 0 && element.memberCount >= element.capacity) {
					element.buttonType = "full";
				}
				for (let members of element.members) {
					//console.log({ members });
					if (members.userId === uID && members.accepted === true) {
						element.buttonType = "member"; // user is in squad and accepted = true
					}
					if (members.userId === uID && members.accepted === false) {
						element.buttonType = "waiting"; // user is in squad and accepted = false
					}
					if (members.userId === uID && members.role === "owner") {
						element.buttonType = "owner";
					}
				}
				//console.log({ element });
				if (element.disbanded === false) {
					tempArray.push(element);
				}
			}
			setSquadObject(tempArray);
			setFoundSquads(tempArray);
		} else {
			console.log("No squads array");
			setSquadObject(null);
		}
		if (event) event.detail.complete();
		//setLoading(false);
	};

	const processSearchTerm = () => {
		//console.log("searchTerm.current.value: " + searchTerm.current.value);
		if (searchTerm.current.value !== "") {
			let result = squadObject.filter((obj) => {
				if (
					obj.name.toLowerCase().includes(searchTerm.current.value.toLowerCase()) ||
					obj.shortCode.toLowerCase().includes(searchTerm.current.value.toLowerCase())
				) {
					return obj;
				}
			});
			setFoundSquads(result);
		} else {
			setFoundSquads(squadObject);
		}
	};

	const confirmJoinSquad = async (squadID, squadName) => {
		let message = `Are you sure you would like to join the squad <b>${squadName}</b>?`;
		setJoinSquad({
			visible: true,
			squadID: squadID,
			squadName: squadName,
			message: message,
		});
	};

	const confirmRequestSquad = async (squadID, squadName) => {
		let message = `Are you sure you would like to request to join the squad <b>${squadName}</b>?`;
		setRequestSquad({
			visible: true,
			squadID: squadID,
			squadName: squadName,
			message: message,
		});
	};

	const processJoinSquad = async (squadID) => {
		console.log("Joining user to squadID " + squadID);
		setUpdating({
			visible: true,
			message: "Joining squad...",
		});
		let user = userState.user;
		let userID = userState.user.cognitoId;
		await inviteUserToSquad(userID, squadID)
			.then(async (response) => {
				// find that squad in BOTH the current array and the array of all squads
				let newSquad = await updateButtonTypeOfSquad(squadID, "waiting");
				newSquad.buttonType = "member";
				sendSlackNotification("*New public squad joined*\nUser: " + user.userName + "\nSquad Name: " + newSquad.name + "\n", "creation");
				logGenericEvent("joined public squad");
				//processSearchTerm();
				await updateUserState();
				let chatRoomID = await getChatRoomIDFromSquadID(squadID, true);
				setShowJoinSquadConfirmed({ visible: true, chatRoomID: chatRoomID, squadObject: newSquad });
			})
			.catch((error) => {
				alert("Hmm there was an error trying to join that squad. We've been alerted. For now please try joining a different squad.");
				//console.log("invite error: ", { error });
			});
		setUpdating({
			visible: false,
		});
	};

	const processRequestSquad = async (squadID) => {
		setUpdating({
			visible: true,
			message: "Requesting to join squad...",
		});
		let user = userState.user;
		let userID = userState.user.cognitoId;
		await inviteUserToSquad(userID, squadID)
			.then(async (response) => {
				// find that squad in BOTH the current array and the array of all squads
				let newSquad = await updateButtonTypeOfSquad(squadID, "waiting");
				sendSlackNotification("*New public squad joined*\nUser: " + user.userName + "\nSquad Name: " + newSquad.name + "\n", "creation");
				//processSearchTerm();
				await updateUserState();
				setShowRequestSquadConfirmed({ visible: true, squadObject: newSquad });
			})
			.catch((error) => {
				console.log("invite error: ", { error });
			});
		setUpdating({
			visible: false,
		});
	};

	const updateButtonTypeOfSquad = async (squadID, buttonType) => {
		let tempFoundArray = foundSquads;
		let tempAllArray = squadObject;
		let foundSquadIndex = tempFoundArray.findIndex((squad) => squad.id === squadID);
		let foundSquadIndexAll = tempAllArray.findIndex((squad) => squad.id === squadID);
		let newSquad = await getSquad(squadID, null, null, true);
		newSquad.buttonType = buttonType;
		tempFoundArray[foundSquadIndex] = newSquad;
		tempAllArray[foundSquadIndexAll] = newSquad;

		// Update them both
		setFoundSquads(tempFoundArray);
		setSquadObject(tempAllArray);
		return newSquad;
	};

	const cancelRequest = async (event, squadID, squadName, confirmation = false) => {
		if (confirmation === false) {
			setDeclineInviteAlert({
				visible: true,
				message: "Are you sure you want to cancel your request to join " + squadName + "?",
				squadID: squadID,
				squadName: squadName,
			});
		} else {
			setUpdating({ visible: true, message: "Canceling Join Request..." });
			await deleteUserFromSquad(userID, squadID).catch((error) => {
				alert("Error: " + error);
				return;
			});
			await updateUserState();
			let user = userState.user;
			searchTerm.current.value = "";
			let newSquad = await updateButtonTypeOfSquad(squadID, "request");
			await updateUserState();
			logGenericEvent("cancelled request to join squad");
			sendSlackNotification("*New squad request cancellation sent*\nUser: " + user.userName + "\nSquad Name: " + newSquad.name + "\n", "creation");
			//processSearchTerm();
			setUpdating({ visible: false, message: null });
		}
	};

	const quitSquad = async (squadID) => {
		let user = userState.user;
		let userID = userState.user.cognitoId;
		await deleteUserFromSquad(userID, squadID)
			.then(async (response) => {
				setUpdating({ visible: true, message: `Leaving squad...` });
				// find that squad in the current array
				let tempArray = foundSquads;
				let newSquad;
				let foundSquadIndex = tempArray.findIndex((squad) => squad.id === squadID);
				if (tempArray[foundSquadIndex].isPrivate === true) {
					newSquad = await updateButtonTypeOfSquad(squadID, "request");
				} else {
					newSquad = await updateButtonTypeOfSquad(squadID, "join");
				}
				logGenericEvent("left squad");
				sendSlackNotification("*User left squad*\nUser: " + user.userName + "\nSquad Name: " + newSquad.name + "\n", "creation");
				await updateUserState();
				// change the value of that squad so it shows requested
				// set new state
				setUpdating({ visible: false });
			})
			.catch((error) => {
				alert("Oops an error occurred. Pull down on the screen to refresh and try again.");
				console.log("quit error: ", { error });
			});
	};

	const removeTag = async (id) => {
		let tempAllTags = [];
		let tempSquadTags = filters.slice();
		for await (let tag of allTags) {
			// used for await just in case
			if (tag.id === id) {
				let index = tempSquadTags.indexOf(tag.id); // issue here was index was not being found
				if (index !== -1) {
					tempSquadTags.splice(index, 1);
					setFilters(tempSquadTags);
				}
			}
		}
	};

	return (
		<IonPage>
			<IonHeader className="squad-join-header">
				<IonToolbar style={{ "--border-width": "0px" }}>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
					<IonTitle>Join a Squad</IonTitle>
					<IonButtons slot="end">
						<IonButton color="tertiary" onClick={() => setCodeEntry(true)}>
							Code
						</IonButton>
					</IonButtons>
				</IonToolbar>
				<IonRow>
					<IonSearchbar mode="ios" className="squad-join-searchbar" ref={searchTerm} onIonChange={processSearchTerm} animated autoCorrect="on"></IonSearchbar>
					<IonButton className="squad-join-filter-button" onClick={() => setShowFilters(true)}>
						<IonIcon color="primary" icon={filterSharp}></IonIcon>Filter
					</IonButton>
				</IonRow>
			</IonHeader>
			<IonContent>
				<IonPopover
					isOpen={showCoachMark}
					cssClass="chat-room-tool-tip-1"
					onDidDismiss={() => {
						setShowCoachMark(false);
					}}
				>
					<div style={{ position: "absolute", top: "0%", left: "90%", fontSize: "30px", zIndex: "200000" }} onClick={() => setShowCoachMark(false)}>
						<IonIcon icon={closeCircleSharp}></IonIcon>
					</div>
					<JoinSquadCoachMark setShowCoachMark={setShowCoachMark} />
				</IonPopover>
				<IonRefresher slot="fixed" onIonRefresh={(e) => getSquads(e, true)}>
					<IonRefresherContent refreshingText="Updating Squad List"></IonRefresherContent>
				</IonRefresher>
				<IonPopover isOpen={codeEntry} onDidDismiss={() => setCodeEntry(false)} cssClass="exercise-details-full-screen-popover">
					<EnterSquadCode setCodeEntry={setCodeEntry} />
				</IonPopover>
				<IonPopover
					isOpen={showJoinSquadConfirmed.visible}
					onDidDismiss={() => setShowJoinSquadConfirmed({ visible: false })}
					cssClass="exercise-details-full-screen-popover"
				>
					<JoinSquadPopover setShowJoinSquadConfirmed={setShowJoinSquadConfirmed} showJoinSquadConfirmed={showJoinSquadConfirmed} />
				</IonPopover>
				<IonPopover
					isOpen={showRequestSquadConfirmed.visible}
					onDidDismiss={() => setShowRequestSquadConfirmed({ visible: false })}
					cssClass="exercise-details-full-screen-popover"
				>
					<RequestSquadPopover setShowRequestSquadConfirmed={setShowRequestSquadConfirmed} showRequestSquadConfirmed={showRequestSquadConfirmed} />
				</IonPopover>
				<IonPopover isOpen={fullSquadAlert} onDidDismiss={() => setFullSquadAlert(false)} cssClass="exercise-details-full-screen-popover">
					<div style={{ margin: "16px", textAlign: "center" }}>
						<img src="https://fitsquad-ui-assets.s3-us-west-2.amazonaws.com/membersonly.jpg" alt="" />
						<div>
							<p>We limit squad sizes so each persons' contribution is meaningful and unfortunately that squad is full.</p>
						</div>
					</div>
				</IonPopover>
				<IonModal isOpen={showFilters} onDidDismiss={() => setShowFilters(false)} cssClass="exercise-details-full-screen-popover">
					<JoinSquadFilters setFilters={setFilters} filters={filters} setShowFilters={setShowFilters} />
				</IonModal>
				<IonLoading isOpen={loading} onDidDismiss={() => setLoading(false)} message={"Loading all squads"} backdropDismiss />
				{/* {loading === false ? <CoachMarkController source={"squadJoin3"} /> : ""} */}
				<IonAlert
					isOpen={joinSquad.visible}
					header="Confirm Squad Joining"
					message={joinSquad.message}
					buttons={[
						{
							text: "Cancel",
							role: "cancel",
							handler: () => {
								setJoinSquad({ visible: false });
							},
						},
						{
							text: "Yes",
							role: "ok",
							handler: () => {
								processJoinSquad(joinSquad.squadID);
							},
						},
					]}
					onDidDismiss={() => setJoinSquad({ visible: false })}
				/>
				<IonAlert
					isOpen={requestSquad.visible}
					header="Confirm Squad Request"
					message={requestSquad.message}
					buttons={[
						{
							text: "Cancel",
							role: "cancel",
							handler: () => {
								setRequestSquad({ visible: false });
							},
						},
						{
							text: "Yes",
							role: "ok",
							handler: () => {
								processRequestSquad(requestSquad.squadID);
							},
						},
					]}
					onDidDismiss={() => setRequestSquad({ visible: false })}
				/>
				<IonAlert
					isOpen={declineInviteAlert.visible}
					header="You sure?"
					message={declineInviteAlert.message}
					buttons={[
						{
							text: "Cancel",
							role: "cancel",
							handler: () => {
								setDeclineInviteAlert({ visible: false });
							},
						},
						{
							text: "Yes",
							role: "ok",
							handler: () => {
								cancelRequest(null, declineInviteAlert.squadID, declineInviteAlert.squadName, true);
							},
						},
					]}
					onDidDismiss={() => setDeclineInviteAlert({ visible: false })}
				/>
				<IonAlert
					isOpen={quitSquadAlert.visible}
					header="You sure?"
					message={`Are you sure you would like to quit ${quitSquadAlert.squadName}? <br/><br/>The squad's goal and points for this week will be reduced.`}
					buttons={[
						{
							text: "No",
							role: "cancel",
							handler: () => {
								setQuitSquadAlert({ visible: false, squadID: null, squadName: null });
							},
						},
						{
							text: "Yes",
							role: "ok",
							handler: () => {
								quitSquad(quitSquadAlert.squadID);
							},
						},
					]}
					onDidDismiss={() => setQuitSquadAlert({ visible: false, squadID: null, squadName: null })}
				/>
				<IonLoading isOpen={updating.visible} onDidDismiss={() => setUpdating(false)} message={updating.message} backdropDismiss={true} />
				<IonToast isOpen={inviteToast} onDidDismiss={() => setInviteToast(false)} message="Request to join sent" duration={2000} />
				<IonToast isOpen={waitingToast} onDidDismiss={() => showWaitingToast(false)} message="Your request is waiting on approval" duration={2000} />
				<IonToast isOpen={showCancelledToast} onDidDismiss={() => setShowCancelledToast(false)} message="Request cancelled" duration={2000} />
				<IonToast isOpen={quitSquadToast} onDidDismiss={() => setQuitSquadToast(false)} message="Quitting squad" duration={2000} />
				<div className="horizontal-scroll" style={{ paddingLeft: "12px" }}>
					{filters !== null ? (
						filters.map((tagID, i) => {
							let tagName = getTagNameFromTagID(tagID, allTags);
							return (
								<div key={`${i}`} className="streak">
									<IonChip outline="false" color="tertiary" onClick={() => removeTag(tagID)}>
										<IonLabel style={{ display: "inline", height: "inherit" }}>
											<IonIcon icon={close} slot="start" />
											{tagName}
										</IonLabel>
									</IonChip>
								</div>
							);
						})
					) : (
						<div className="instructional-text">Earn items by participating in seasons</div>
					)}
				</div>
				{foundSquads &&
					foundSquads.map((squad, i) => {
						if (squad.showInSearch === true) {
							return (
								<div key={`${i}`} style={{ borderBottom: "1px solid rgb(0,0,0,0.1)", paddingBottom: "5px", paddingTop: "5px" }}>
									<IonRow key={`${i}`} className="squad-search-row">
										<IonCol size="3" className="search-squad-image-column" onClick={() => history.push("/squad/" + squad.id)}>
											<img src={squad.image} className="search-squad-image" alt="" />
											{/* comment out for now so people dont share others codes
										
										<br />
										<span style={{ fontSize: "12px" }}>Squad Code: {squad.shortCode}</span> */}
										</IonCol>
										<IonCol size="9" style={{ margin: "auto" }}>
											<IonRow>
												<IonCol size="6" onClick={() => history.push("/squad/" + squad.id)}>
													<div className="search-squad-name">{squad.name}</div>
													<div className="search-squad-slogan">{squad.slogan}</div>
												</IonCol>
												<IonCol id="buttons" size="3" style={{ margin: "auto" }}>
													{squad.buttonType === "request" ? (
														<IonButton size="small" className="search-squad-button" onClick={() => confirmRequestSquad(squad.id, squad.name)}>
															Request
														</IonButton>
													) : (
														""
													)}
													{squad.buttonType === "join" ? (
														<IonButton size="small" className="search-squad-button" onClick={() => confirmJoinSquad(squad.id, squad.name)}>
															Join
														</IonButton>
													) : (
														""
													)}
													{squad.buttonType === "waiting" ? (
														<IonButton
															color="warning"
															size="small"
															className="search-squad-button"
															onClick={() => cancelRequest(React.MouseEvent, squad.id, squad.name)}
														>
															<span style={{ fontSize: "11px" }}>Requested</span>
														</IonButton>
													) : (
														""
													)}
													{squad.buttonType === "member" ? (
														<IonButton
															color="success"
															size="small"
															className="search-squad-button"
															onClick={() => setQuitSquadAlert({ visible: true, squadID: squad.id, squadName: squad.name })}
														>
															<span style={{ fontSize: "11px" }}>Member</span>
														</IonButton>
													) : (
														""
													)}
													{squad.buttonType === "owner" ? (
														<IonButton color="primary" size="small" className="search-squad-button">
															<span style={{ fontSize: "11px" }}>Owner</span>
														</IonButton>
													) : (
														""
													)}
													{squad.buttonType === "full" ? (
														<IonButton color="warning" size="small" className="search-squad-button" onClick={() => setFullSquadAlert(true)}>
															<span style={{ fontSize: "11px" }}>Full</span>
														</IonButton>
													) : (
														""
													)}
												</IonCol>
											</IonRow>
										</IonCol>
									</IonRow>
									<IonRow>
										<div style={{ paddingLeft: "5%", paddingRight: "5%" }}>
											{squad.tags &&
												squad.tags.map((tag, i) => {
													return (
														<IonChip key={`${i}`} outline="false" color="tertiary">
															<IonLabel style={{ fontSize: "10px" }}>{tag.tag}</IonLabel>
														</IonChip>
													);
												})}
										</div>
									</IonRow>
								</div>
							);
						}
					})}
				{loading === false && foundSquads.length < 1 ? (
					<div className="no-squad">
						<span>🤷‍♀️</span>
						<br />
						<div style={{ fontSize: ".75em" }}>
							No squads found with that tag.
							<br />
							Consider creating one!
							<br />
						</div>
						<IonButton routerLink={`/squadcreate`}>Create a squad</IonButton>
					</div>
				) : (
					""
				)}
			</IonContent>
		</IonPage>
	);
};

export default SquadJoin;
