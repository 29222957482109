//export const API_URL = "https://api-dev.fitsquadapp.com/";
export const API_URL = "https://api.fitsquadapp.com/";
//export const API_URL = "http://localhost:3000/dev/";
export const LOCAL_API_URL = "http://localhost:3000/dev/";
export const PHP_URL = "http://35.85.48.169/fitbotphp/";
export const PHP_URL_NAME = "https://fitpetapp.com/fitbotphp/";
//export const API_URL = 'http://35.165.20.234/fitbotphp/dialogflow-api/tester.php';
export const COGNITO_URL = "https://cognito-idp.us-west-2.amazonaws.com";
//export const API_URL = 'https://dvs84tvgdi.execute-api.us-west-2.amazonaws.com/prod/'
export const ClientID = "3r4o7i4r4laf83ervcsbq9g17a";

export const emptyAvatar = "https://fitsquad-ui-assets.s3-us-west-2.amazonaws.com/empty-avatar.png";
export const loadingMessages = ["challenging server to duel...", "going for a walk...", "doing burpees...", "meal prepping...", "steaming some vegetables..."];
export const loadingImages = [
	"assets/images/4-quote.jpg",
	"assets/images/5-quote.jpg",
	"assets/images/3-quote.jpg",
	"assets/images/2-quote.jpg",
	"assets/images/1-quote.jpg",
]

export const weekCompletedImages = [
	"assets/images/completed_1.jpg",
	"assets/images/completed_2.jpg",
	"assets/images/completed_3.jpg",
]
export const fakeSquadNames = [
	"Boom Boys",
	"Agony of De Feet",
	"Win or Booze",
	"Hoops I Did It Again",
	"Chafing the Dream",
	"We Got the Runs",
	"Gone With the Win",
	"Fast but Not Furious",
	"Goal Diggers",
	"Case of the Runs",
	"Single Belles",
	"Hungry Hippos",
	"Hell On Heels",
];

export const fakeSquadSlogans = [
	"No one ever says, 'Its only a game' when they're winning",
	"It is not cheating, it’s team work",
	"We're not lazy, we're just on energy saving mode",
	"Life is short, smile while you still have teeth",
	"3 out of 2 people have trouble with fractions",
	"When nothing goes right…… go left",
	"Life is designed to kick your a**",
	"Go g, there is no planet B",
	"Save water & drink champagne",
	"An onion a day keeps everyone away",
	"After Tuesday even the calendar say W T F",
	"We're 35% funny & 75% bad at math",
];

export const sampleAchievement = [
	{
		name: "Best Day",
		value: 3400,
		unit: "points",
		image: "https://cdn0.iconfinder.com/data/icons/mother-s-day-9/32/mothers_day_best_mom-512.png",
		date: "11/20/20",
	},
	{
		name: "Most Logged",
		value: 1400,
		unit: "points",
		image: "https://cdn3.iconfinder.com/data/icons/signs-8/52/sign-17-256.png",
		date: "11/20/20",
	},
	{
		name: "Longest Streak",
		value: 5,
		unit: "weeks",
		image: "https://cdn2.iconfinder.com/data/icons/gamification-badges-1/300/streak_7-256.png",
		date: "11/20/20",
	},
];

export const systemActivities = [
	"newUserGoal",
	"newSquadGoal",
	"userGoalEvaluated",
	"squadGoalEvaluated",
	"goalOver80Percent",
	"goalCompleted",
	"squadJoinRequested",
	"squadMembershipRemoved",
	"squadJoinDeclined",
	"squadJoinAccepted",
	"squadRoleChanged",
	"friendRequest",
	"friendRemoved",
	"friendRequestApproved",
	"userLevelUp",
	"userLevelDown",
	"newSquadCompetition",
	"competitionEvaluated",
	"squadMembershipQuit",
	"seasonRegistration"
];

export const excludedActivities = [
	"squadJoinDeclined",
	"squadGoalEvaluated",
	"squadInviteRequested",
	"newSquadGoal",
];

export const offlineJWT = "eyJraWQiOiJiVmI1eEM0NDZTdExWcFdVcWtLZ2hwNGRTSnNURERPMVJzUGNFMjgya0YwPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJlYzBhMWE2Yy02OTcyLTRlMzAtYWIyYi1jNmUyOWI0YzQ4YmMiLCJhdWQiOiIzbzJndnM1azNuNXFkdWFjOHR2MTg4cGhlaiIsImNvZ25pdG86Z3JvdXBzIjpbInVzZXJzIl0sImV2ZW50X2lkIjoiMTZmNWQ2ZGQtNzcxYS00ZDUwLWEzNDctMDQzMWY0ZjliNmI3IiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2MDIzNjU2MzYsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy13ZXN0LTIuYW1hem9uYXdzLmNvbVwvdXMtd2VzdC0yXzNGYklZOFJ5ayIsImNvZ25pdG86dXNlcm5hbWUiOiJCaWdnUmlnZzE1OTY0MDI2MDQzNDIiLCJleHAiOjE2MDIzNjkyMzYsImlhdCI6MTYwMjM2NTYzNn0.ZP20v2WFrT5-y9BtJn9arCei1FSA10SsE_Uyh5QLA5lAmmPhPttueu_jUmXPneSFHWSdkzpWAjAtfQk_4Fa5XKHmDa8OLrE2tHKRm_kxXT_0kTVJqIhChst_-CBTXnd_kSCcapVjdhWuh2TFzzBfiDgvwTIvruI9kgjBuJrRztXkwbPfGuY1JyAKT45L0sByCD_264uBkTc1NVvJMbpo5ncf0rWAp4ZfaZFPnjUHqwiuaU-if0j5Y6RHkJfOUb4650q7eumjtdgvqALBnQvFW7j44pWvFq20TKY3CcH1rKfhChkzQtZy4ckayqNOjIqw2GUKIjSkusrc_taQFaCvBQ"

export const squadFlavorText = [
]

export const conversationEngineMessageTypes = [
	"LOG_WORKOUT"
]

export const sports = [
	"golf",
	"volleyball",
	"spikeball",
	"spike ball",
	"tennis",
	"football",
	"softball",
	"baseball",
]

export const goalSettingImages = [
	"assets/images/goal_image.jpg",
	"assets/images/goals-blocks.jpg",
	"assets/images/goals-wood.jpg",
	"assets/images/goals-yellow.jpg",
]

export const loadingTips = [
	"Getting too many notifications? Go to Profile and tap the ⚙ icon to change your preferences",
	"Goal weeks end Sunday at Midnight PT so be sure to log your week's activity before then!",
	"If you have a FitBit or other fitness app connected, no need to log your activity manually",
	"Show your squadmates love for their activities. They appreciate it!",
	"It pays to crank it up! If your activity is more intense, you can earn 1.5x or even 2x points per minute!",
	"You earn points even for mindfulness activity. A clear mind is just as important for health as a strong body.",
]

export const logActivityPlaceholderText = [
	"Walking a 100lb dog",
	"The Murph",
	"Meditating",
	"Surfing a giant wave",
	"Wrestling with my nephews",
	"Rehabing my achilles",
	"Being amazing",
	"Torturous treadmill run",
	"Crime fighting",
	"Earning points for my squad :)",
	"Yoga",
	"Horse polo",
	"Playing softball",
	"Cursing 2020",
	"Picking up highway trash",
	"Hiking a glacier",
	"Skydiving",
	"Fencing",
	"Underwater basketweaving"
]

export const premiumItemIDs = [0,6,12,13,16] //0 is placeholder for an additional item

export const firstWeekEncouragement = [
	"Great Start 👍",
	"Everything is going great so far! 💯🙌",
	"You have started really well 👊",
	"You are on fire! Keep it going and you’ll be unstoppable. 🔥🔥"
]

export const botResponseTime = 1500

export const possibleVariables = [
	"_userName_",
	"_defaultCheerleaderName_",
	"_cheerleaderName_"
]

export const randomCheerleaderNames = [
	"Cheery",
	"That sexy bot",
	"Gweneth Paltrow",
	"Cheery McCheerface",
	"Cheery the bot"
]

export const challengeTesterUsernames = [
	"Topher",
	"Ashley",
	"Riggz",
	"iphonesrock",
	"testy3",
	"testy2"
]

export const cloudFrontURL = "https://d3sveztjqii75x.cloudfront.net"

export const excludedNotifications = [
	"dailyMessage",
	"inactivityNotification",
	"dailyMessageNoSquad",
	"userGoalEvaluated"
]