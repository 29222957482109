import {
	IonAvatar,
	IonButton,
	IonCol,
	IonContent,
	IonHeader,
	IonIcon,
	IonImg,
	IonItem,
	IonLabel,
	IonList,
	IonLoading,
	IonPage,
	IonRefresher,
	IonRefresherContent,
	IonRow,
	IonText,
	IonTitle,
	IonToolbar,
	useIonViewDidEnter,
	useIonViewDidLeave,
	useIonViewWillEnter,
} from "@ionic/react";
import moment from "moment";
import { menuController } from "@ionic/core";

import React, { useContext, useEffect, useState } from "react";
import Emoji from "react-emoji-render";
import { useHistory } from "react-router";
import AuthContext from "../contexts/GlobalState";
import "../css/ChatInterface.css";
import { logGenericEvent, logPageView } from "../services/AnalyticsService";
import { getAllChatRooms, getChatRooms, getLastMessageInFeed } from "../services/ChatService";
import { getSquad, isUserInSquad } from "../services/SquadService";
import { StorageService } from "../services/StorageService";
import { getUserNameOfLoggedInUser } from "../services/UserService";
import { menuOutline } from "ionicons/icons";

const ChatInterface = () => {
	const { userState, chatRoomsGlobal } = useContext(AuthContext);
	const [dayOfTheWeek] = useState(moment().format("ddd"));

	const [squadsChatRooms, setSquadChatRooms] = useState([]);
	const [competitionChatRooms, setCompetitionChatRooms] = useState([]);
	//const [friendChatRooms, setFriendChatRooms] = useState([]);
	const storageService = new StorageService();
	const history = useHistory();
	const [showLoading, setShowLoading] = useState(true);
	const [refresher, setRefresher] = useState(0);
	const [refreshing, setRefreshing] = useState(false);

	let noSquadEmoji = ":thinking_face:";

	useIonViewDidEnter(async () => {
		logPageView("chat-interface");
	});

	// useEffect(() => {
	// 	setSquadChatRooms(chatRoomsGlobal);
	// }, [chatRoomsGlobal, userState]);

	useIonViewDidLeave(() => {
		setShowLoading(false);
	});

	useEffect(() => {
		// problem is this happens every first load
		console.log("Re-render on chatInterface because user state was updated");
		doRefresh(undefined, true);
	}, [userState]);

	const onClickHandler = () => {
		logGenericEvent("Menu");
		menuController.open();
	};

	const setupSquadChatRooms = async (chatRoomArray, refresh) => {
		for (let chatRoom of chatRoomArray) {
			let squadInfo = await getSquad(chatRoom.squadIdA, null, null, refresh);
			// console.log({ chatRoom });
			// updating chatroom to get most recent message
			chatRoom.type = "squad";
			chatRoom.name = squadInfo.name;
			chatRoom.lastTime = "";
			chatRoom.lastSender = "";
			chatRoom.lastMessage = "";
			getLastMessageInFeed(chatRoom.id).then((lastMessageInFeed) => {
				//console.log("lastMessageInFeed:", { lastMessageInFeed });
				if (lastMessageInFeed !== undefined && lastMessageInFeed !== null) {
					chatRoom.lastTime = moment(lastMessageInFeed.timeStamp).format("M/D/YY");
					chatRoom.lastSender = lastMessageInFeed.user.userName;
					getUserNameOfLoggedInUser().then((userName) => {
						if (chatRoom.lastSender === userName) {
							chatRoom.lastSender = "You";
						}
						chatRoom.lastMessage = chatRoom.lastSender + ": " + lastMessageInFeed.message;
						setRefresher(Date.now());
					});
				} else {
					chatRoom.lastMessage = chatRoom.description;
				}
			});
			chatRoom.image = squadInfo.image;
			chatRoom.roomPage = "/chatroom/" + chatRoom.id;
			setSquadChatRooms((squadsChatRooms) => [...squadsChatRooms, chatRoom]);
			storageService.setObject(chatRoom.id, chatRoom);
		}
	};

	const setupCompetitionChatRooms = async (chatRoomArray) => {
		//console.log("cChatRoom Array: ", { chatRoomArray });
		for (let chatRoom of chatRoomArray) {
			// An intersquad chatroom
			// updating chatroom to get most recent message
			chatRoom.startDate = moment(chatRoom.createdAt).format("YYYY-MM-DD");
			chatRoom.endDate = moment(chatRoom.startDate).add(7, "days").format("YYYY-MM-DD");
			chatRoom.type = "competition";
			//console.log("chatRoom: ", { chatRoom });
			//getMessagesAndActivities(chatRoom);
			chatRoom.lastTime = "";
			chatRoom.lastSender = "";
			chatRoom.lastMessage = "";
			getLastMessageInFeed(chatRoom.id).then((lastMessageInFeed) => {
				console.log("lastMessageInFeed:", { lastMessageInFeed });
				if (lastMessageInFeed !== undefined && lastMessageInFeed !== null) {
					chatRoom.lastTime = moment(lastMessageInFeed.timeStamp).format("M/D/YY");
					chatRoom.lastSender = lastMessageInFeed.user.userName;
					getUserNameOfLoggedInUser().then((userName) => {
						if (chatRoom.lastSender === userName) {
							chatRoom.lastSender = "You";
						}
						chatRoom.lastMessage = chatRoom.lastSender + ": " + lastMessageInFeed.message;
						setRefresher(Date.now());
					});
				} else {
					chatRoom.lastMessage = chatRoom.description;
				}
			});
			//console.log("LastMessageInFeed: " + JSON.stringify(lastMessageInFeed));
			chatRoom.roomPage = "/chatroom/" + chatRoom.id;
			setCompetitionChatRooms((competitionChatRooms) => [...competitionChatRooms, chatRoom]);
			storageService.setObject(chatRoom.id, chatRoom);
		}
	};

	/*
	const setupFriendChatRooms = (chatRoomArray) => {
		for (let chatRoom of chatRoomArray) {
			setFriendChatRooms((friendChatRooms) => [...friendChatRooms, chatRoom]);
			storageService.setObject(chatRoom.id, chatRoom);
		}
	};
	*/

	const createChatRoomArrays = async (refresh = false) => {
		let chatRooms = await storageService.getObject("chatRooms");
		// chatRooms = await getAllChatRooms(refresh, false).catch((error) => {
		// 	alert("Error: " + error);
		// });
		let chatRoomObject = [];
		if (!chatRooms) {
			console.log("Chat rooms empty: ", { chatRooms });
			setSquadChatRooms([]);
			setCompetitionChatRooms([]);
			//setFriendChatRooms([]);
			setShowLoading(false);
			return;
		}
		console.log("Chatrooms: ", { chatRooms });
		for await (let element of chatRooms) {
			console.log({ element });
			if ((await isUserInSquad(element.squadIdA)) === false) {
				element.disabled = true;
			}
			chatRoomObject.push(element);
		}
		chatRoomObject.reverse();
		let sChatRooms = [];
		let cChatRooms = [];
		let fChatRooms = [];
		for await (let chatRoom of chatRoomObject) {
			if (chatRoom.squadIdA !== null && chatRoom.squadIdB == null) {
				sChatRooms.push(chatRoom);
			} else if (chatRoom.squadIdA !== null && chatRoom.squadIdB !== null) {
				cChatRooms.push(chatRoom);
			} else {
				fChatRooms.push(chatRoom);
			}
		}
		await setupSquadChatRooms(sChatRooms, refresh);
		//await setupCompetitionChatRooms(cChatRooms, refresh);
		//setupFriendChatRooms(fChatRooms);
		setShowLoading(false);
	};

	const doRefresh = async (event, refresh = true) => {
		// One refresh because createRooms is one function
		setRefreshing(true);
		setSquadChatRooms([]);
		//setCompetitionChatRooms([]);
		//setFriendChatRooms([]);
		await createChatRoomArrays(refresh);
		setRefresher(Date.now());
		if (event) {
			setTimeout(() => {
				setRefreshing(false);
				event.detail.complete();
			}, 2000);
		} else {
			setRefreshing(false);
		}
	};

	const navToChatroom = (chatRoomID) => {
		console.log("Naving to room " + chatRoomID);
		history.push("/squadhome/" + chatRoomID);
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonIcon slot="start" icon={menuOutline} onClick={() => onClickHandler()} size="large" />
					<IonTitle>Squads</IonTitle>
				</IonToolbar>
				<IonRow className="page-info-container" style={window.innerWidth <= 320 ? { fontSize: "0.75em", paddingTop: "0px" } : { paddingTop: "0px" }}>
					<IonCol size="4" className="squad-management-column">
						<IonButton
							color="primary"
							size="small"
							className="squad-management-button"
							onClick={() => {
								history.push("/squadjoin");
							}}
						>
							Join a Squad
						</IonButton>
					</IonCol>
					<IonCol size="4" className="squad-management-column">
						<IonButton
							color="primary"
							size="small"
							className="squad-management-button"
							onClick={() => {
								history.push("/managesquads");
							}}
						>
							Manage Squads
						</IonButton>
					</IonCol>
					<IonCol size="4" className="squad-management-column">
						<IonButton
							color="primary"
							size="small"
							className="squad-management-button"
							onClick={() => {
								history.push("/squadcreate");
							}}
						>
							Create Squad
						</IonButton>
					</IonCol>
				</IonRow>
				<IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={"Loading..."} duration={5000} />
			</IonHeader>
			<IonContent className="Content">
				<IonRefresher slot="fixed" onIonRefresh={doRefresh}>
					<IonRefresherContent refreshingText="Refreshing chat rooms"></IonRefresherContent>
				</IonRefresher>
				<IonList className="ALlSAMECoLro ion-no-padding">
					{squadsChatRooms && squadsChatRooms.length === 0 && !showLoading && !refreshing ? (
						<div className="no-squad">
							<Emoji text={noSquadEmoji} />
							<br />
							You're not in a squad
							<br />
							<IonButton routerLink={`/squadjoin`}>Join a squad</IonButton>
						</div>
					) : (
						""
					)}
					{squadsChatRooms &&
						squadsChatRooms.map((squad, index) => {
							//console.log({ squad });
							if (squad.lastMessage.length > 35) {
								squad.lastMessage = squad.lastMessage.substring(0, 35) + "...";
							}
							if (squad.disabled === true && dayOfTheWeek !== "Mon") {
								return (
									<div key={index} style={{ backgroundColor: "lightgray", opacity: ".3" }}>
										<IonRow key={index}>
											<IonCol size="3" className="squad-image-column">
												<IonAvatar className="squad-image-avatar">
													<img src={squad.image} className="squad-image-chat-interface" alt="" />
												</IonAvatar>
											</IonCol>
											<IonCol size="6" style={{ margin: "auto" }}>
												<IonRow>
													<span className="squad-name">{squad.name}</span>
												</IonRow>
												<IonRow>
													<span className="last-message">{squad.lastMessage}</span>
												</IonRow>
											</IonCol>
											<IonCol size="2" className="chat-time-column" style={{ margin: "auto" }}>
												<div className="chat-time-column-div">
													<span className="chat-time">{squad.lastTime}</span>
												</div>
											</IonCol>
										</IonRow>
									</div>
								);
							} else {
								// can view all squads on Monday
								return (
									<IonRow key={index} onClick={() => navToChatroom(squad.squadIdA)}>
										<IonCol size="3" className="squad-image-column">
											<IonAvatar className="squad-image-avatar">
												<img src={squad.image} className="squad-image-chat-interface" alt="" />
											</IonAvatar>
										</IonCol>
										<IonCol size="6" style={{ margin: "auto" }}>
											<IonRow>
												<span className="squad-name">{squad.name}</span>
											</IonRow>
											<IonRow>
												<span className="last-message">{squad.lastMessage}</span>
											</IonRow>
										</IonCol>
										<IonCol size="2" className="chat-time-column" style={{ margin: "auto" }}>
											<div className="chat-time-column-div">
												<span className="chat-time">{squad.lastTime}</span>
											</div>
										</IonCol>
									</IonRow>
								);
							}
						})}
					{competitionChatRooms &&
						competitionChatRooms.map((competition, index) => {
							return (
								<IonItem key={index} className="ALlSAMECoLro competition-chat-room" onClick={() => navToChatroom(competition.id)}>
									<IonAvatar className="chatImage" slot="start">
										<IonImg src={competition.image}></IonImg>
									</IonAvatar>
									<IonLabel>
										<div className="withcount">
											<div className="nameAvatar">
												<h2 color="light" className="nameAvatarH2">
													{competition.name}
												</h2>
											</div>

											<div className="timeEnd">
												<IonText className="IonText" slot="end" color="light">
													{competition.time}
												</IonText>
											</div>
										</div>
										<p className="last-message">{competition.lastMessage}</p>
									</IonLabel>
								</IonItem>
							);
						})}
				</IonList>
			</IonContent>
		</IonPage>
	);
};

export default ChatInterface;
