import { Plugins } from "@capacitor/core";
import { IonBackButton, IonButtons, IonChip, IonContent, IonHeader, IonLabel, IonPage, IonTitle, IonToolbar, useIonViewDidEnter } from "@ionic/react";
import React, { useState } from "react";
import { useParams } from "react-router";
import SquadInviteComponent from "../components/SquadInviteComponent";
import "../css/Home.css";
import "../css/Onboarding.css";
import "../css/SquadCreate.css";
import "../css/SquadScoreboard.css";
import { getSquad } from "../services/SquadService";

const SquadInvite = () => {
	const { PushNotifications, LocalNotifications, Device } = Plugins;
	let squadID = useParams().squadID;
	const [squadObject, setSquadObject] = useState();

	useIonViewDidEnter(async () => {
		let squad = await getSquad(squadID);
		setSquadObject(squad);
	});

	/*
	const setupPush = async () => {
		let body;
		LocalNotifications.requestPermission();
		let deviceInfo = await Device.getInfo();

		PushNotifications.addListener("registration", async (token) => {
			storageService.setItem("pushToken", token.value);
			const platformsArray = await getPlatforms();
			console.log("Platforms: ", { platformsArray });
			platformsArray.forEach((platform) => {
				if (platform === "ios") {
					body = {
						deviceToken: token.value.toLowerCase(),
					};
					console.log("User is on iOS. Using that payload");
				}
				if (platform === "android") {
					body = {
						registrationId: token.value,
					};
					console.log("User is on Android. Using that payload");
				}
			});
			await updateUser(body);
		});

		// Some issue with our setup and push will not work
		PushNotifications.addListener("registrationError", (error) => {
			alert("Error registering for push notifications: ", error);
			setPushNotifications(false);
		});

		await setupNotificationListener(history);

		if (deviceInfo.platform === "android") {
			const notificationChannel = {
				id: "primary", // id must match android/app/src/main/res/values/strings.xml's default_notification_channel_id
				name: "primary",
				description: "primary",
				importance: 5,
				visibility: 1,
			};
			PushNotifications.createChannel(notificationChannel);
			console.log("[and] Channel created");
		}
		const permResult = await PushNotifications.requestPermission()
			.catch((error) => {
				setPushNotifications(false);
				alert("Error: " + error);
				return;
			})
			.then(async (permResult) => {
				console.log("permResult: " + permResult);
				if (permResult.granted) {
					// Register with Apple / Google to receive push via APNS/FCM
					const result = await PushNotifications.register();
					console.log("register result", result);
				} else {
					setPushNotifications(false);
					alert(`push notifications won't work`);
				}
			});
	};
*/
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
					<IonTitle>Invite Squadmates</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<div className="onboarding-main-div" style={{ paddingLeft: "16px", paddingRight: "16px" }}>
					{squadObject ? (
						<React.Fragment>
							<div className="squad-data-div" style={{ width: "100%", textAlign: "center", paddingTop: "10px" }}>
								<div id="squad-image">
									{squadObject ? <img src={squadObject.image} alt="" style={{ height: "80%", width: "80%", borderRadius: "10px" }} /> : ""}
								</div>
								<div style={{ textAlign: "center" }}>
									<div className="squad-slogan-div" style={{ width: "100%", fontStyle: "italic" }}>
										{squadObject.slogan}
									</div>
									<div className="squad-name-div" style={{ width: "100%", fontSize: "1.5em", fontWeight: "bold" }}>
										{squadObject.name}
									</div>
									<div style={{ width: "100%", fontSize: "1.5em", fontWeight: "bold" }}>
										{squadObject.tags &&
											squadObject.tags.map((tag, i) => {
												return (
													<IonChip key={`${i}`} outline="false" color="tertiary">
														<IonLabel>{tag.tag}</IonLabel>
													</IonChip>
												);
											})}
									</div>
								</div>
							</div>
							<SquadInviteComponent squadObject={squadObject} />
						</React.Fragment>
					) : (
						""
					)}
				</div>
			</IonContent>
		</IonPage>
	);
};

export default SquadInvite;
