import { Plugins } from "@capacitor/core";
import { HealthKit } from "@ionic-native/health-kit";
import { menuController } from "@ionic/core";
import {
	IonBadge,
	IonButton,
	IonCard,
	IonCardContent,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonContent,
	IonHeader,
	IonIcon,
	IonItem,
	IonLoading,
	IonModal,
	IonPage,
	IonPopover,
	IonRefresher,
	IonRefresherContent,
	IonSpinner,
	IonTitle,
	IonToolbar,
} from "@ionic/react";
import { closeCircleSharp, menuOutline, notifications } from "ionicons/icons";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import ChallengeProgressComponent from "../components/Challenges/ChallengeProgressComponent";
import EndOfWeekSummaryComponent from "../components/HomePageCards/EndOfWeekSummaryComponent";
import SquadProgressComponent from "../components/HomePageCards/SquadProgressComponent";
import UserProgressComponent from "../components/HomePageCards/UserProgressComponent";
import SelectWeeklySquadComponent from "../components/SelectWeeklySquadComponent";
import VersionUpdateComponent from "../components/VersionUpdateComponent";
import AuthContext from "../contexts/GlobalState";
import "../css/Home.css";
import "../css/Profile.css";
import { PHP_URL_NAME } from "../models/constants";
import { logGenericEvent, logNavigationEvent, logPageView } from "../services/AnalyticsService";
import { postPHP } from "../services/APIService";
//import "./Collapsible.scss";
import ChallengesHomeCoachMark from "../components/CoachMarks/ChallengesHomeCoachMark";
import GenericFeedbackComponent from "../components/Feedback/GenericFeedback";
import NotificationsListComponent from "../components/NotificationListComponent";
import { areNotificationsAuthorized, enableNotifications, sendSlackNotification } from "../services/NotificationService";
import {
	clearAllSquadGoals,
	clearAllSquadInfo,
	getAcceptedNonWeeklySquads,
	getCurrentWeeklySquad,
	getLastWeeklySquad,
	getSquad,
	setCurrentlyWeeklySquad,
} from "../services/SquadService";
import { StorageService } from "../services/StorageService";
import { getUser, getUserID, updateUser } from "../services/UserService";
import UserDataComponent from "../components/UserDataComponent";

const Home = () => {
	const showUpdate = true;
	const storageService = new StorageService();
	const { availableChallenges, userState, challenges, pullingUpdates, refreshInProgress, globalGoal, updateUserState, updateGoalState, challengesEnabled } =
		useContext(AuthContext);
	const history = useHistory();
	const [refreshing, setRefreshing] = useState({
		visible: true,
		message: "Refreshing your data...",
	});
	const [dayOfTheWeek] = useState(moment().format("ddd"));
	// const [dayOfTheWeek] = useState("Tue");
	const [gifImage, setGifImage] = useState();
	const [randomChallengeID, setRandomChallengeID] = useState();
	const [user, setUser] = useState(userState.user);
	const [timeOfDay, setTimeOfDay] = useState();
	const [showModal, setShowModal] = useState(false);
	const [acceptedSquads, setAcceptedSquads] = useState([]);
	const [showSquadSelectionPopover, setShowSquadSelectionPopover] = useState({
		visible: false,
		squadID: null,
	});
	const [showUpdateModal, setShowUpdateModal] = useState(false);
	const [numberOfUpdates, setNumberOfUpdates] = useState(0);
	const [refreshingAtAppLevel, setRefreshingAtAppLevel] = useState(refreshInProgress);
	const [createdToday, setCreatedToday] = useState(null);
	const [weeklySelection, setWeeklySelection] = useState({
		visible: false,
		squadName: null,
		squadImage: null,
		squadID: null,
	});
	const [squadsForTheWeek, setSquadsForTheWeek] = useState([]);
	const [showCoachMark, setShowCoachMark] = useState(false);
	const [genericFeedbackModal, setGenericFeedbackModal] = useState(false);

	const { Device } = Plugins;
	if (user && createdToday === null) {
		console.log(moment(user.createdAt).format("DD-MM-YY"));
		console.log(moment().format("DD-MM-YY"));

		if (moment(user.createdAt).format("DD-MM-YY") === moment().format("DD-MM-YY")) {
			console.log("setting created at to true");
			setCreatedToday(true);
		} else {
			console.log("setting created at to false");
			setCreatedToday(false);
		}
	}

	useEffect(() => {
		logPageView("home");
		validateNotifications();
		// setupPurchases();
		getRecentUpdateCount();
		calculateTimeOfDay();
		// getDayOfTheWeekGIF();
		if (dayOfTheWeek === "Mon") {
			checkForWeeklySquad();
		}
		showVersionUpdatePopover();
		showWeekUpdatePopover();
		checkForHK();
		setRefreshing({ visible: false });
	}, []);

	useEffect(() => {
		console.log("Re-render because user state was updated");
		setUser({ ...userState.user });
	}, [userState]);

	useEffect(() => {
		console.log("Re-render because globalGoal was updated");
	}, [globalGoal]);

	useEffect(() => {
		if (challenges && challenges.length === 0 && availableChallenges && availableChallenges.length > 0 && challengesEnabled) {
			setRandomChallengeID(Math.floor(Math.random() * availableChallenges.length));
		}
	}, [challenges, availableChallenges, challengesEnabled]);

	// const checkChallengesCoachMark = async () => {
	// 	if (showUpdateModal === false && challengesEnabled) {
	// 		await coachMarkCheck(setShowCoachMark, "challengesHome");
	// 	}
	// };

	// const setupPurchases = async () => {
	// 	if (user) {
	// 		console.log("Preparing purchases");
	// 		await Purchases.setDebugLogsEnabled(true);
	// 		await Purchases.setup("appl_PzeDmQKWSxqAyPhJcTWXUjNaQoQ", user.cognitoId);
	// 		await Purchases.getOfferings(
	// 			(offerings) => {
	// 				console.log("Purchases offerings: ", { offerings });
	// 				if (offerings.current !== null && offerings.current.availablePackages.length !== 0) {
	// 					// Display packages for sale
	// 				}
	// 			},
	// 			(error) => {
	// 				console.log("Purchases Error: ", { error });
	// 			}
	// 		);
	// 	}
	// };

	const checkForWeeklySquad = async () => {
		let squadIDs = await getAcceptedNonWeeklySquads(); // Neither? Use these
		// console.log({ squadIDs });
		let tempSquadArray = [];
		for await (let squadID of squadIDs) {
			let squad = await getSquad(squadID);
			tempSquadArray.push(squad);
		}
		// console.log({ tempSquadArray });
		setAcceptedSquads(tempSquadArray); // set the full list
		let weeklySquadStatus = await getCurrentWeeklySquad(); // already set this week? Use this
		// console.log({ weeklySquadStatus });
		if (weeklySquadStatus !== null) {
			selectInitialWeeklySquads(weeklySquadStatus, true);
			return;
		}
		let lastWeekSquadStatus = await getLastWeeklySquad(); // not already set this week? Use this
		// console.log({ lastWeekSquadStatus });

		if (lastWeekSquadStatus !== null) {
			selectInitialWeeklySquads(lastWeekSquadStatus, true);
			return;
		}
		//selectWeeklySquad(squadIDs, true); // auto chooses last squad, not random squad
		selectInitialWeeklySquads(squadIDs, true); // auto chooses last squad, not random squad
	};

	const validateNotifications = async () => {
		let deviceInfo = await Device.getInfo();
		let pushNotificationsAuthorized = await areNotificationsAuthorized();
		let user = JSON.parse(await getUser(null, false));
		let pushToken = await storageService.getItem("pushToken");
		let userID = await getUserID();
		if (pushToken !== "" && user.pushIdentifiers.length > 0) {
			// tokens stored both on the server and on the client
			// gtg
			console.log("tokens stored both on the server and on the client. gtg");
			return;
		}
		if (pushToken === "" && user.pushIdentifiers.length > 0) {
			// nothing locally but stored on the server
			// reauthenticate
			console.log("No push token stored locally, but stored on the server");
			sendSlackNotification("No push token stored locally, but stored on the server, reenabling them for userID " + userID, "keyEvent");
			await enableNotifications();
		}
		if (pushToken !== "" && user.pushIdentifiers.length < 1) {
			// nothing on the server but stored locally
			// update server
			console.log("No push token stored on the server, but stored locally");
			sendSlackNotification(
				"No push token stored on the server, but stored locally. Trying to update it for userID " + userID + " with token: " + pushToken,
				"keyEvent"
			);
			let body;
			if (deviceInfo.platform === "ios") {
				body = {
					deviceToken: pushToken.toLowerCase(),
				};
				console.log("User is on iOS. Using that payload");
			}
			if (deviceInfo.platform === "android") {
				body = {
					registrationId: pushToken,
				};
				console.log("User is on Android. Using that payload");
			}
			let response = await updateUser(body);
			console.log({ response });
			sendSlackNotification("Tokens updated for userID " + userID, "keyEvent");
		}
		if (pushToken === "" && user.pushIdentifiers.length < 1) {
			// never authorized pushes
			console.log("never authorized pushes. gtg");
			return;
		}
		// // if (user && pushNotificationsAuthorized === true && deviceInfo.platform !== "web") {
		// if (pushNotificationsAuthorized === true || deviceInfo.platform === "web") {
		// 	console.log("Pushes enabled, confirming them");
		// 	sendSlackNotification("Pushes enabled, confirming them for userID " + userID, "keyEvent");
		// 	let pushToken = await storageService.getItem("pushToken");
		// 	console.log({ pushToken });
		// 	sendSlackNotification("Trying with token" + pushToken + " for " + userID, "keyEvent");
		// 	if (pushToken !== "") {
		// 		let body;
		// 		if (deviceInfo.platform === "ios" || deviceInfo.platform === "web") {
		// 			body = {
		// 				deviceToken: pushToken.toLowerCase(),
		// 			};
		// 			console.log("User is on iOS. Using that payload");
		// 		}
		// 		if (deviceInfo.platform === "android") {
		// 			body = {
		// 				registrationId: pushToken,
		// 			};
		// 			console.log("User is on Android. Using that payload");
		// 		}
		// 		let response = await updateUser(body);
		// 		console.log({ response });
		// 		sendSlackNotification("Tokens updated for userID " + userID, "keyEvent");
		// 	} else {
		// 		console.log("No push token stored");
		// 		sendSlackNotification("No push token stored, enabling them for userID " + userID, "keyEvent");
		// 		await enableNotifications();
		// 	}
		// } else {
		// 	console.log("Pushes not enabled");
		// 	sendSlackNotification("Pushes not enabled for userID " + userID, "keyEvent");
		// }
	};

	const checkForHK = async () => {
		console.log("Checking for HK:");
		let options = {
			readTypes: [
				"HKQuantityTypeIdentifierStepCount",
				"HKWorkoutTypeIdentifier",
				"HKQuantityTypeIdentifierActiveEnergyBurned",
				"HKQuantityTypeIdentifierAppleExerciseTime",
			],
		};
		let hkAvailable;
		await HealthKit.available().then((result) => {
			hkAvailable = result;
			console.log("HK Available:", result);
		});

		// Basically any time the app is loaded and the user has HK connected, it will check for permission
		if (user && user.healthkitConnected === true && hkAvailable === true) {
			console.log("Authorizing HK");
			await HealthKit.requestAuthorization(options)
				.then((result) => {
					console.log("Authorization complete");
					console.log({ result }); // results in null
					//sendSlackNotification("*NEW EVENT*\nUsername: " + user.userName + "\nHealthKit Authorization result:" + result, "keyEvent");
				})
				.catch((error) => {
					console.log("Error in authorizing HK: ", error);
				});
		} else {
			console.log("Error in trying to authorize HK for user. Either user object not connected, hk connected === false or hk not available");
		}
	};

	const getRecentUpdateCount = async () => {
		let numUpdates = parseInt(await storageService.getItem("recentUpdateCount"));
		//console.log("numUpdates: " + numUpdates);
		if (numUpdates === "") {
			numUpdates = 0;
		}
		setNumberOfUpdates(numUpdates);
	};

	const showWeekUpdatePopover = async () => {
		let dow = moment().format("ddd");
		let shownUpdate = await storageService.getObject("weeklyUpdateShown");
		//shownUpdate = null;
		//dow = "Mon";
		// console.log(moment(user.createdAt).format("DD-MM-YY"));
		// console.log(moment().format("DD-MM-YY"));

		if (createdToday === true) {
			setShowModal(false);
			return;
		}
		if (showCoachMark === true) {
			setShowModal(false);
			return;
		}
		if (dow !== "Mon") {
			setShowModal(false);
			return;
		}
		if (shownUpdate === null) {
			shownUpdate = {};
			setShowModal(true);
			shownUpdate[moment().format("MM/DD/YY")] = true;
			storageService.setObject("weeklyUpdateShown", shownUpdate);
			return;
		}
		if (shownUpdate[moment().format("MM/DD/YY")]) {
			setShowModal(false);
			return;
		} else {
			shownUpdate[moment().format("MM/DD/YY")] = true;
			storageService.setObject("weeklyUpdateShown", shownUpdate);
			setShowModal(true);
			return;
		}
	};

	const showVersionUpdatePopover = async () => {
		// Prioritize special updates. These are controlled purely client side and a flag is set server side to ensure they are not repeated
		// At some point need to better scale this
		// Weekly squads announcement
		let shownWeeklyAnnouncement = await storageService.getItem("shownWeeklyAnnouncement");
		console.log("shownWeeklyAnnouncement: " + shownWeeklyAnnouncement);

		let recentlyUpdated = await storageService.getItem("recentlyUpdated");
		if ((recentlyUpdated === "true" || recentlyUpdated === "") && showModal === false && createdToday === false && showUpdate === true) {
			storageService.setItem("recentlyUpdated", "false");
			setShowUpdateModal(true);
			return;
		}
	};
	const calculateTimeOfDay = () => {
		let hour = moment().format("k");
		if (hour >= 18) {
			setTimeOfDay("evening");
		} else if (hour >= 12) {
			setTimeOfDay("afternoon");
		} else {
			setTimeOfDay("morning");
		}
		//setDayOfTheWeekLong(moment().format("dddd"))
	};

	// const getDayOfTheWeekGIF = async () => {
	// 	let gifOfTheDay = await storageService.getObject("userGIFHistory");

	// 	let gifURL;
	// 	if (gifOfTheDay === null) {
	// 		gifOfTheDay = {};
	// 	}
	// 	if (!gifOfTheDay[moment().format("MM/DD/YY")]) {
	// 		let date = moment().format("YYYY-MM-DD");
	// 		gifURL = await getDailyGif(date);
	// 		gifOfTheDay[moment().format("MM/DD/YY")] = gifURL;
	// 		storageService.setObject("userGIFHistory", gifOfTheDay);
	// 		setGifImage(gifURL);
	// 	} else {
	// 		setGifImage(gifOfTheDay[moment().format("MM/DD/YY")]);
	// 	}
	// };

	const refresh = async (event) => {
		//console.log("Refreshing... on a Tuesday");
		//setRefreshing({ visible: true, message: "Refreshing" });
		setUser(null);
		let userObj = userState.user;
		await storageService.removeItem("userGoal-null");
		await storageService.removeItem("userGoal-" + userObj.cognitoId);
		await clearAllSquadGoals(userObj);
		await clearAllSquadInfo(userObj);
		await updateUserState();
		await updateGoalState();
		setUser({ ...userObj });
		if (event)
			setTimeout(() => {
				//console.log("Async operation has ended");
				event.detail.complete();
			}, 2000);
		//setRefreshing({ visible: false });
	};

	const navToNotifications = () => {
		logNavigationEvent("home", "notifications");
		history.push("/notifications/");
	};

	const selectWeeklySquad = async (squadID) => {
		// Pass one ID and either add or remove it from the existing list
		let squad;
		let updatedSOTW = [];
		let removed = false;
		if (squadsForTheWeek.includes(squadID)) {
			// if its in there remove it
			updatedSOTW = squadsForTheWeek.filter((item) => item !== squadID);
			removed = true;
		} else {
			// otherwise add it
			updatedSOTW = squadsForTheWeek;
			if (squadID !== "" && squadID !== " " && squadID !== null) {
				updatedSOTW.push(squadID);
			}
		}
		setSquadsForTheWeek(updatedSOTW);
		squad = await getSquad(squadID);
		if (squad.squadType === 99 || squadID === "random") {
			squad = {
				name: "Random Weekly Squad",
				image: "https://media.giphy.com/media/UqjY9hmFprXDVp24oK/giphy.gif",
			};
		}
		if (removed === true) {
			// it was removed
			sendSlackNotification("Weekly Squad REMOVED\nUsername: " + user.userName + "\nSquad: " + squad.name + "(" + squadID + ")", "keyEvent");
		} else {
			// it was added
			sendSlackNotification("Weekly Squad selected\nUsername: " + user.userName + "\nSquad: " + squad.name + "(" + squadID + ")", "keyEvent");
			setWeeklySelection({
				visible: true,
				squadName: squad.name,
				squadImage: squad.image,
				squadID: squadID,
			});
		}
		let userID;
		if (user.cognitoId) {
			userID = user.cognitoId;
		} else {
			userID = await getUserID();
		}
		let data = {
			squadID: updatedSOTW.join(),
			userID: userID,
			date: moment().startOf("isoWeek").format("YYYY-MM-DD"),
		};
		let weeklySquadAPI = PHP_URL_NAME + "fitsquad/userWeeklySquad.php";
		postPHP(weeklySquadAPI, data);
		setCurrentlyWeeklySquad(updatedSOTW);
	};

	const selectInitialWeeklySquads = async (squadIDs) => {
		// When the page first loads it hits this to indicate previous selections
		let squad;
		let squadIDsInts = []; // done to hold int's not strings
		// Need to iterate on squadIDs array
		let slackNotification = "Default Squads selected\nUsername: " + user.userName;
		for (let squadID of squadIDs) {
			squad = await getSquad(squadID);
			if (squad.squadType === 99 || squadID === "random") {
				squad = {
					name: "Random Weekly Squad",
				};
				squadIDsInts.push("random");
			} else {
				squadIDsInts.push(parseInt(squadID));
			}
			slackNotification = slackNotification + "\nSquad: " + squad.name + "(" + squadID + ")";
		}
		sendSlackNotification(slackNotification, "keyEvent");
		setSquadsForTheWeek(squadIDsInts);
		let userID;
		if (user.cognitoId) {
			userID = user.cognitoId;
		} else {
			userID = await getUserID();
		}
		let data = {
			squadID: squadIDs.join(),
			userID: userID,
			date: moment().startOf("isoWeek").format("YYYY-MM-DD"),
		};
		let weeklySquadAPI = PHP_URL_NAME + "fitsquad/userWeeklySquad.php";
		postPHP(weeklySquadAPI, data);
		setCurrentlyWeeklySquad(squadIDs);
	};

	const onClickHandler = () => {
		logGenericEvent("Menu");
		menuController.open();
	};

	const navToChallenge = (challengeID) => {
		logGenericEvent("View Challenge " + challengeID);
		history.push("/challenge/" + challengeID);
	};

	const navTo = (destination, analyticsName) => {
		logNavigationEvent("user-progress-component", analyticsName);
		history.push(destination);
	};

	return (
		<React.Fragment>
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonIcon slot="start" icon={menuOutline} onClick={() => onClickHandler()} size="large" />
						{timeOfDay && user ? (
							<IonTitle>
								Good {timeOfDay}
								<br />
								{user.userName}!
							</IonTitle>
						) : (
							<IonTitle>Hey there!</IonTitle>
						)}
						{pullingUpdates === true ? (
							<IonSpinner name="dots" color="primary" style={{ position: "absolute", top: "20%", left: "90%", zIndex: "20000" }} />
						) : (
							<React.Fragment>
								{user && user.deviceInfo && user.deviceInfo.operatingSystem && user.deviceInfo.operatingSystem === "ios" ? (
									<IonBadge style={{ position: "absolute", top: "20%", left: "90%", zIndex: "20000" }} color="danger">
										{numberOfUpdates && numberOfUpdates > 0 ? <span>{numberOfUpdates}</span> : ""}
									</IonBadge>
								) : (
									""
								)}
								{user && user.deviceInfo.operatingSystem && user.deviceInfo.operatingSystem === "android" ? (
									<IonBadge style={{ position: "absolute", top: "20%", left: "93%", zIndex: "20000" }} color="danger">
										{numberOfUpdates && numberOfUpdates > 0 ? <span>{numberOfUpdates}</span> : ""}
									</IonBadge>
								) : (
									""
								)}
								{numberOfUpdates > 0 ? (
									<IonButton color="secondary" slot="end" onClick={navToNotifications} className="no-shadow">
										<img src="assets/images/animated-bell.gif" alt="" style={{ height: "34px" }} />
									</IonButton>
								) : (
									<IonButton color="secondary" slot="end" onClick={navToNotifications} className="no-shadow">
										<IonIcon slot="icon-only" icon={notifications}></IonIcon>
									</IonButton>
								)}
							</React.Fragment>
						)}
					</IonToolbar>
				</IonHeader>
				{showModal && showModal === true ? (
					<React.Fragment>
						<EndOfWeekSummaryComponent modalState={showModal} setModalState={setShowModal} />
					</React.Fragment>
				) : (
					""
				)}
				{showUpdateModal && showUpdateModal === true ? <VersionUpdateComponent /> : ""}
				<IonContent className="home-background">
					<IonModal isOpen={genericFeedbackModal}>
						<GenericFeedbackComponent setGenericFeedbackModal={setGenericFeedbackModal} />
					</IonModal>
					<IonRefresher slot="fixed" onIonRefresh={refresh}>
						<IonRefresherContent refreshingText="Updating data"></IonRefresherContent>
					</IonRefresher>
					<IonPopover
						isOpen={showSquadSelectionPopover.visible}
						cssClass="exercise-details-full-screen-popover"
						onDidDismiss={(e) => {
							setShowSquadSelectionPopover({ visible: false, squadID: null });
						}}
					>
						<SelectWeeklySquadComponent setModal={setShowSquadSelectionPopover} modal={showSquadSelectionPopover} squadID={showSquadSelectionPopover.squadID} />
					</IonPopover>
					<IonPopover
						isOpen={showCoachMark}
						cssClass="chat-room-tool-tip-2"
						onDidDismiss={() => {
							setShowCoachMark(false);
						}}
					>
						<div style={{ position: "absolute", top: "0%", left: "90%", fontSize: "30px", zIndex: "200000" }} onClick={() => setShowCoachMark(false)}>
							<IonIcon icon={closeCircleSharp}></IonIcon>
						</div>
						<ChallengesHomeCoachMark setShowCoachMark={showCoachMark} />
					</IonPopover>
					<IonPopover
						isOpen={weeklySelection.visible}
						cssClass="exercise-details-full-screen-popover"
						onDidDismiss={(e) => {
							setWeeklySelection({ visible: false, squadName: null, squadImage: null, squadID: null });
						}}
					>
						<ConfirmWeeklySelection
							setSquadsForTheWeek={setSquadsForTheWeek}
							squadsForTheWeek={squadsForTheWeek}
							setModal={setWeeklySelection}
							weeklySelection={weeklySelection}
						/>
					</IonPopover>
					<div className="home-background">
						<IonLoading
							isOpen={refreshing.visible}
							onDidDismiss={() => setRefreshing({ visible: false })}
							message={refreshing.message}
							backdropDismiss="true"
							duration={5000}
						/>
						{/* <div id="dailyGIF" style={{ textAlign: "center" }} className="home-card">
							{gifImage ? <img src={gifImage} alt="" style={{ width: "100%", borderRadius: "10px" }} /> : ""}
						</div> */}
						{/* NOTIFICATION COMPONENT */}

						<IonCard id="userInfo">
							<div id="header" className="squad-scoreboard-header">
								<div
									style={{
										width: "100%",
										textAlign: "center",
										backgroundColor: "var(--ion-color-secondary) !important",
										color: "var(--ion-color-tertiary)",
										fontSize: "1.5em",
										padding: "2%",
									}}
								>
									Recent Updates
								</div>
							</div>
							{user ? (
								<div className="squad-progress-component">
									<NotificationsListComponent
										globalGoal={globalGoal}
										userObject={user}
										dow={dayOfTheWeek}
										showFlavorText={true}
										refreshInProgress={refreshingAtAppLevel}
									/>
								</div>
							) : (
								""
							)}
						</IonCard>
						{/* CHALLENGES PROGRESS COMPONENT */}

						{challenges &&
							challenges.length > 0 &&
							challenges.map((challenge, i) => {
								if (challenge.challengeDetails && parseInt(challenge.active) === 1) {
									return (
										<div key={`${i}`} onClick={() => navToChallenge(challenge.challengeID)}>
											<ChallengeProgressComponent challengeObject={challenge} source={"home"} />
										</div>
										// <IonItem routerLink={"/challenge/" + challenge.challengeID} routerDirection="forward" key={`${i}`}>

										// </IonItem>
									);
								}
							})}
						{/* 
						CHALLENEGES AD COMPONENT

						{challenges && challenges.length === 0 && availableChallenges && availableChallenges.length > 0 ? (
							<React.Fragment>
								<IonCard onClick={() => navToChallenge(availableChallenges[randomChallengeID].id)}>
									<ChallengeHeaderComponent challengeDetails={availableChallenges[randomChallengeID]} />
									<IonCardContent>
										<div className="view-challenge" style={{ width: "90%", float: "left" }}>
											Check out this challenge
										</div>
										<div className="chevron" style={{ float: "left" }}>
											{">"}
										</div>
									</IonCardContent>
								</IonCard>
							</React.Fragment>
						) : (
							""
						)}
 */}
						{/* MONDAY VS OTHE DAYS COMPONENTS */}

						{dayOfTheWeek && dayOfTheWeek !== "Mon" ? (
							<React.Fragment>
								{/* SQAUD PROGRESS COMPONENT */}

								{user &&
									dayOfTheWeek &&
									user.squads &&
									user.squads.map((squad, i) => {
										if (squad.accepted === true && squad.squad.disbanded !== true) {
											//wtf
											return (
												<IonCard key={`${i}`} className="home-card" routerLink={"/squadhome/" + squad.squadId}>
													<div className="squad-progress-component">
														<SquadProgressComponent squadId={squad.squadId} dow={dayOfTheWeek} showFlavorText={true} refreshInProgress={refreshingAtAppLevel} />
													</div>
												</IonCard>
											);
										} else {
											return "";
										}
									})}

								{/* USER PROGRESS COMPONENT */}

								<IonCard id="userInfo">
									{user && dayOfTheWeek ? (
										<div className="squad-progress-component">
											<UserProgressComponent
												globalGoal={globalGoal}
												userObject={user}
												dow={dayOfTheWeek}
												showFlavorText={true}
												refreshInProgress={refreshingAtAppLevel}
											/>
										</div>
									) : (
										""
									)}
								</IonCard>
							</React.Fragment>
						) : (
							<React.Fragment>
								{/* SELECT SQUADS COMPONENT */}

								<IonCard>
									<IonCardContent>
										<IonCardTitle style={{ fontSize: "1.5em" }}>Choose Your Featured Squads</IonCardTitle>
										<IonCardSubtitle>These squads will be your squads for the week</IonCardSubtitle>
										<IonItem onClick={() => selectWeeklySquad("random")}>
											<IonCol size="3">
												<img src="https://media.giphy.com/media/UqjY9hmFprXDVp24oK/giphy.gif" height="60" alt="squadimage" />
											</IonCol>
											<IonCol size="7">
												<div>
													Weekly Squad{" "}
													<img alt="new icon" src="https://fitsquad-ui-assets.s3.us-west-2.amazonaws.com/new-icon.png" style={{ height: "20px" }} />
												</div>
											</IonCol>
											<IonCol size="2">{squadsForTheWeek.includes("random") ? <div style={{ fontSize: "1.35em" }}>✅</div> : ""}</IonCol>
										</IonItem>
										{user &&
											acceptedSquads &&
											acceptedSquads.map((squad, i) => {
												return (
													<IonItem key={`${i}`} onClick={() => selectWeeklySquad(squad.id)}>
														<IonCol size="3">
															<img src={squad.image} height="60" alt="squadimage" />
														</IonCol>
														<IonCol size="7">
															<div>{squad.name}</div>
														</IonCol>

														<IonCol size="2">{squadsForTheWeek.includes(squad.id) ? <div style={{ fontSize: "1.35em" }}>✅</div> : ""}</IonCol>
													</IonItem>
												);
											})}
									</IonCardContent>
								</IonCard>

								{/* WEEKLY UPDATE COMPONENT */}

								{createdToday === false ? (
									<IonCard onClick={() => setShowModal(true)}>
										<IonCardContent>
											<IonCardTitle>Your week is complete</IonCardTitle>
											<IonCardSubtitle>Tap to see how it went!</IonCardSubtitle>
											<img src="assets/images/results-are-in.jpg" alt="" />
										</IonCardContent>
									</IonCard>
								) : (
									""
								)}
								<IonCard id="userInfo">
									{user && dayOfTheWeek ? (
										<div
											className="squad-progress-component"
											onClick={() => {
												navTo("/profile", "profile");
											}}
										>
											<UserDataComponent />
										</div>
									) : (
										""
									)}
								</IonCard>
							</React.Fragment>
						)}
					</div>
					<div style={{ margin: "5%", textAlign: "center" }}>
						<IonButton expand="block" className="feedback-button" onClick={() => setGenericFeedbackModal(true)}>
							📢 Share Feedback!
						</IonButton>
					</div>
				</IonContent>
			</IonPage>
		</React.Fragment>
	);
};

export default Home;

const ConfirmWeeklySelection = ({ setModal, weeklySelection, setSquadsForTheWeek, squadsForTheWeek }) => {
	return (
		<React.Fragment>
			<div style={{ textAlign: "center", margin: "10%" }}>
				<img src={weeklySelection.squadImage} style={{ width: "50%" }} alt="" />
			</div>
			{weeklySelection.squadName === "Random Weekly Squad" ? (
				<React.Fragment>
					<div style={{ textAlign: "center", margin: "10%" }}>
						<h2>{weeklySelection.squadName} </h2>
					</div>
					<div style={{ textAlign: "center", margin: "10%" }}>
						Tomorrow you'll be placed in a squad with other motivated people (like you!)
						<br />
						<br />
						This squad will only be for this coming week, and will be removed next Monday
						<br />
						<br />
						All your points will count towards that squad's goal
					</div>
				</React.Fragment>
			) : (
				<React.Fragment>
					<div style={{ textAlign: "center", margin: "10%" }}>
						<h2>{weeklySelection.squadName} </h2>
					</div>
					<div style={{ textAlign: "center", margin: "10%" }}>
						This will be one of your featured Squads this week
						<br />
						<br />
						Your points will count towards this squad's goal and you'll be counted on to come through for this squad.
					</div>
				</React.Fragment>
			)}

			<IonButton
				size="large"
				style={{ marginLeft: "25%", width: "50%", marginTop: "5%", marginBottom: "5%", fontSize: "1em", textTransform: "none" }}
				onClick={() => setModal({ visible: false, squadName: null, squadImage: null, squadID: null })}
			>
				Got it!
			</IonButton>
		</React.Fragment>
	);
};
