import { IonButton, IonCol, IonContent, IonIcon, IonInput, IonModal, IonRow, IonSpinner } from "@ionic/react";
import { closeCircleOutline, returnDownBackOutline } from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import { sendMessage } from "../../services/ChatService";
import { getMultipleRandomGifsFromSearch } from "../../services/GifService";
import { StorageService } from "../../services/StorageService";
const storageService = new StorageService();

const GifSelector = ({ setGifSelectorOpen, chatRoomObj, setFeed, setScroller, setShowToast }) => {
	const [gifArray, setGifArray] = useState([]);
	const [loadingGIFs, setLoadingGifs] = useState(false);
	const searchTerm = useRef(null);
	useEffect(() => {
		showGifs();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const submitSearch = () => {
		console.log("Search term: " + searchTerm.current.value);
		let search = searchTerm.current.value;
		showGifs(search);
		setLoadingGifs(true);
	};

	const handleKeyUp = (event) => {
		if (event.keyCode === 13) {
			submitSearch();
		}
	};

	const sendGIF = (URL) => {
		sendMessage(URL, chatRoomObj, setFeed, setScroller, setShowToast);
		setGifSelectorOpen(false);
	};

	const showGifs = async (search = "funny exercise") => {
		let tempGifArray = await getMultipleRandomGifsFromSearch(search);
		console.log("GifArray: ", { tempGifArray });
		setGifArray(tempGifArray);
		setLoadingGifs(false);
	};

	return (
		//<IonToolbar className="chat-input-toolbar">
		<IonModal isOpen={true} cssClass="gif-selector">
			<div className="giphy-modal-div">
				<IonRow>
					<IonCol size="8">
						<IonInput className="chat-input" placeholder="search Giphy" type="text" ref={searchTerm} onKeyUp={handleKeyUp}></IonInput>
					</IonCol>
					<IonCol size="2">
						<IonButton onClick={submitSearch} className="submit-button" color="chat-bar-button">
							<IonIcon size="small" icon={returnDownBackOutline}></IonIcon>
						</IonButton>
					</IonCol>
					<IonCol size="2">
						<IonButton onClick={() => setGifSelectorOpen(false)} className="submit-button" color="chat-bar-button">
							<IonIcon size="small" icon={closeCircleOutline}></IonIcon>
						</IonButton>
					</IonCol>
				</IonRow>
				<IonContent>
					{loadingGIFs === false ? (
						gifArray.map((image, i) => {
							return (
								<img
									src={image.images.downsized.url}
									style={{ width: "100px" }}
									onClick={() => sendGIF(image.images.original.url, chatRoomObj, setFeed, setScroller, setShowToast)}
									alt=""
								/>
							);
						})
					) : (
						<IonSpinner />
					)}
				</IonContent>
			</div>
		</IonModal>

		//</IonToolbar>
	);
};

export default GifSelector;
