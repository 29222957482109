import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonSlide, IonSlides, IonTitle, IonToolbar } from "@ionic/react";
import React from "react";
import { useParams } from "react-router";

const ImageViewer = (props) => {
	const imageURL = decodeURIComponent(useParams().imageURL);

	const slideOpts = {
		zoom: {
			maxRatio: 5,
		},
		onlyExternal: true,
	};
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons>
						<IonBackButton text="" />
					</IonButtons>
					<IonTitle>Image</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent style={{ "--background": "black" }}>
				<IonSlides style={{ height: "100%" }} zoom options={slideOpts}>
					<IonSlide>
						<div className="swiper-zoom-container">
							<img src={imageURL} alt="" />
						</div>
					</IonSlide>
				</IonSlides>
			</IonContent>
		</IonPage>
	);
};

export default ImageViewer;
