import { IonContent } from "@ionic/react";
import moment from "moment";
import React, { useState } from "react";
import "../../css/SquadScoreboard.css";
import "../../css/FeedRenderer.css";
import SquadProgressComponentMini from "../ChatRoom/SquadProgressComponent-mini";
import SquadScoreboardComponent from "./SquadScoreboardComponent";

const SquadStats = ({ squadObject, setSquadMemberDetailsModal, refresher }) => {
	const [dayOfTheWeek] = useState(moment().format("ddd"));
	return (
		<React.Fragment>
			<IonContent className="stats-scroll-squad-home">
				<div>
					<SquadProgressComponentMini squadId={squadObject.id} dow={dayOfTheWeek} showFlavorText={true} refresher={refresher} />
				</div>
				<div>
					<SquadScoreboardComponent squadID={squadObject.id} setSquadMemberDetailsModal={setSquadMemberDetailsModal} refresher={refresher} />
				</div>
			</IonContent>
		</React.Fragment>
	);
};

export default SquadStats;
