import { IonFooter } from "@ionic/react";
import React, { useState } from "react";
import "../../css/ChatContainer.css";
import SkeletonText from "./SkeletonText";
import ChatInput from "./SquadHomeChatInput";
import GifSelector from "./SquadHomeGifSelector";
import SquadHomeMessageRenderer from "./SquadHomeMessageRenderer";

const Chat = ({ feed, setFeed, chatRoomObj, refresher, skeletonText }) => {
	const [scroller, setScroller] = useState(true); // used to simply trigger a rerender without more info
	const [gifSelectorOpen, setGifSelectorOpen] = useState(false);
	const [textValue, setTextValue] = useState("");
	//const [showLoading, setShowLoading] = useState(true);

	return (
		<React.Fragment>
			{skeletonText === true && feed === undefined ? (
				<SkeletonText />
			) : (
				<SquadHomeMessageRenderer refresher={refresher} setScroller={setScroller} scroller={scroller} feed={feed} setFeed={setFeed} chatRoomObj={chatRoomObj} />
			)}
			<IonFooter>
				{gifSelectorOpen === true ? (
					<GifSelector setGifSelectorOpen={setGifSelectorOpen} setFeed={setFeed} feed={feed} chatRoomObj={chatRoomObj} setScroller={setScroller} />
				) : (
					<ChatInput
						setGifSelectorOpen={setGifSelectorOpen}
						setFeed={setFeed}
						chatRoomObj={chatRoomObj}
						setScroller={setScroller}
						textValue={textValue}
						setTextValue={setTextValue}
					/>
				)}
			</IonFooter>
		</React.Fragment>
	);
};
export default Chat;
