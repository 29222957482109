import { IonHeader, IonTitle, IonToolbar, IonButton, IonItem, IonList, IonLabel, IonLoading, IonIcon } from "@ionic/react";
import React, { useEffect, useState } from "react";
import "../../css/Home.css";
import "../../css/ActivityDetails.css";
import { getActivityDetails, convertDurationToMinutes } from "../../services/ActivitiesService";
import moment from "moment";
import { getUserID, getUserNameFromUserID } from "../../services/UserService";
import { logPageView } from "../../services/AnalyticsService";
import { closeOutline } from "ionicons/icons";

const DailyStepsDetailsComponent = ({ activityObject, showDailyStepsDetails }) => {
	const [activityDetails, setActivityDetails] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		logPageView("exercise-details");
		fetchActivityDetails(activityObject.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function fetchActivityDetails(activityID) {
		console.log("Fetching activity details");
		let activity = JSON.parse(await getActivityDetails(activityID));
		activity.userName = await getUserNameFromUserID(activity.userId);
		let currentUserID = await getUserID();
		activity.unit = activity.activityData.valueUnit;
		activity.steps = activity.activityData.value;
		console.log("Activity: " + JSON.stringify(activity));
		activity.minutes = Math.round(await convertDurationToMinutes(activity));
		if (activity.userId === currentUserID) {
			console.log("User can delete");
			activity.canDelete = true;
		} else {
			console.log("User cannot delete");
			activity.canDelete = false;
		}
		setActivityDetails([activity]);
		setLoading(false);
	}

	return (
		<React.Fragment>
			<IonHeader>
				<IonToolbar className="activity-details-toolbar">
					<IonTitle>Activity Details</IonTitle>
					<IonButton color="tertiary" slot="end" onClick={() => showDailyStepsDetails({ visible: false, details: null })}>
						<IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
					</IonButton>
				</IonToolbar>
			</IonHeader>
			<IonLoading isOpen={loading} onDidDismiss={() => setLoading(false)} message={"Loading details"} />
			{activityDetails.map((activity, i) => {
				return (
					<IonList key={`${i}`}>
						<IonItem className="activity-details-item">
							<IonLabel>
								<h3 className="activity-title">Username</h3>
								<h2 className="activity-value">{activity.userName}</h2>
							</IonLabel>
						</IonItem>
						<IonItem className="activity-details-item">
							<IonLabel>
								<h3 className="activity-title">Activity Name</h3>
								<h2 className="activity-value">{activity.activityType}</h2>
							</IonLabel>
						</IonItem>
						<IonItem className="activity-details-item">
							<IonLabel>
								<h3 className="activity-title">Date</h3>
								<h2 className="activity-value">{moment(activity.startDate).format("ddd MM/DD/YY")}</h2>
							</IonLabel>
						</IonItem>
						<IonItem className="activity-details-item">
							<IonLabel>
								<h3 className="activity-title">Total steps</h3>
								<h2 className="activity-value">{activity.steps.toLocaleString()}</h2>
							</IonLabel>
						</IonItem>
						<IonItem className="activity-details-item">
							<IonLabel>
								<h3 className="activity-title">Points</h3>
								<h2 className="activity-value">{Math.round(activity.points).toLocaleString()}</h2>
							</IonLabel>
						</IonItem>
					</IonList>
				);
			})}
		</React.Fragment>
	);
};

export default DailyStepsDetailsComponent;
