import { IonButton, IonCol, IonContent, IonHeader, IonIcon, IonInput, IonLoading, IonPopover, IonRow, IonTitle, IonToolbar } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import { CountryDropdown } from "react-country-region-selector";
import { usePlacesWidget } from "react-google-autocomplete";
import { getQuantityOfItemByItemID, redeemItem, updateInventoryItem } from "../../services/InventoryService";
import { sendSlackErrorNotification, sendSlackNotification } from "../../services/NotificationService";
import { getUser, updateUser } from "../../services/UserService";

const CollectAddress = ({ itemDetails, userItemID, setModal, setItemQuantity }) => {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [address1, setAddress1] = useState("");
	const [address2, setAddress2] = useState("");
	const [city, setCity] = useState("");
	const [state, setState] = useState("");
	const [country, setCountry] = useState("");
	const [postalCode, setPostalCode] = useState("");
	const [email, setEmail] = useState("");

	const [firstNameError, setFirstNameError] = useState();
	const [lastNameError, setLastNameError] = useState();
	const [addressError, setAddressError] = useState();
	const [cityError, setCityError] = useState();
	const [stateError, setStateError] = useState();
	const [countryError, setCountryError] = useState();
	const [codeError, setCodeError] = useState();
	const [emailError, setEmailError] = useState();

	const [updating, setUpdating] = useState(false);
	const [showPopOver, setShowPopOver] = useState(false);

	const firstNameRef = useRef(null);
	const lastNameRef = useRef(null);
	const address1Ref = useRef(null);
	const address2Ref = useRef(null);
	const cityRef = useRef(null);
	const stateRef = useRef(null);
	const countryRef = useRef(null);
	const postalCodeRef = useRef(null);
	const emailRef = useRef(null);

	const { ref } = usePlacesWidget({
		apiKey: "AIzaSyD0LPM1gWQ-y5F666DsZ7A5NqPJp_u3iXw",
		onPlaceSelected: (place) => {
			disectAddress(place);
		},
		options: {
			types: ["address"],
		},
	});

	useEffect(() => {
		populateFields();
	}, []);

	const populateFields = async () => {
		let user = JSON.parse(await getUser());
		setEmail(user.email ? user.email : "");
		setFirstName(user.firstName ? user.firstName : "");
		setLastName(user.lastName ? user.lastName : "");
		setAddress1(user.address1 ? user.address1 : "");
		setAddress2(user.address2 ? user.address2 : "");
		setCity(user.city ? user.city : "");
		setState(user.state ? user.state : "");
		setPostalCode(user.postalCode ? user.postalCode : "");
		setCountry(user.country ? user.country : "");
	};

	const disectAddress = async (place) => {
		let streetNumber, streetName, city, state, country, postalCode;
		for await (let addressComponent of place.address_components) {
			if (addressComponent.types.includes("street_number")) {
				streetNumber = addressComponent.long_name;
			}
			if (addressComponent.types.includes("route")) {
				streetName = addressComponent.long_name;
			}
			if (addressComponent.types.includes("locality")) {
				city = addressComponent.long_name;
			}
			if (addressComponent.types.includes("administrative_area_level_1")) {
				state = addressComponent.long_name;
			}
			if (addressComponent.types.includes("country")) {
				country = addressComponent.long_name;
			}
			if (addressComponent.types.includes("postal_code")) {
				postalCode = addressComponent.long_name;
			}
		}
		setAddress1(streetNumber + " " + streetName);
		setState(state);
		setPostalCode(postalCode);
		setCountry(country);
		setCity(city);
	};

	/**
	 *
	 * Need to complete the redeeming here if an itemID is passed
	 * because item redemption shouldn't happen until address is confirmed
	 *
	 */

	const sendAddress = async () => {
		let validData = true;
		if (firstNameRef.current?.value === "") {
			setFirstNameError("required");
			validData = false;
		} else {
			setFirstNameError("");
			setFirstName(firstNameRef.current.value);
		}
		if (lastNameRef.current?.value === "") {
			setLastNameError("required");
			validData = false;
		} else {
			setLastNameError("");
			setLastName(lastNameRef.current.value);
		}
		if (!address1) {
			setAddressError("required");
			validData = false;
		} else {
			setAddressError("");
		}
		if (address2Ref.current?.value !== "") {
			setAddress2(address2Ref.current.value);
		}
		if (stateRef.current.value === "") {
			setStateError("required");
			validData = false;
		} else {
			setState(stateRef.current.value);
			setStateError("");
		}
		if (cityRef.current.value === "") {
			setCityError("required");
			validData = false;
		} else {
			setCityError("");
		}
		if (countryRef.current.value === "") {
			setCountryError("required");
			validData = false;
		} else {
			setCountryError("");
		}
		if (postalCodeRef.current.value === "") {
			setCodeError("required");
			validData = false;
		} else {
			setCodeError("");
		}
		if (emailRef.current?.value === "") {
			setEmailError("required");
			validData = false;
		} else {
			setEmailError("");
		}
		if (validData === true) {
			setUpdating(true);
			let body = {
				firstName: firstNameRef.current.value,
				lastName: lastNameRef.current?.value ? lastNameRef.current.value : lastName,
				address1: address1Ref.current?.value ? address1Ref.current.value : address1,
				address2: address2Ref.current?.value ? address2Ref.current.value : address2,
				city: cityRef.current?.value ? cityRef.current.value : city,
				state: stateRef.current?.value ? stateRef.current.value : state,
				country: countryRef.current?.value ? countryRef.current.value : country,
				postalCode: postalCodeRef.current?.value ? postalCodeRef.current.value : postalCode,
				email: emailRef.current?.value ? emailRef.current.value : email,
			};
			console.log({ body });

			let response = await updateUser(body).catch((error) => {
				alert(
					"There was an error collecting your information. Please try again. If you continue to run into issues please contact support at support@fitsquadapp.com"
				);
			});
			if (response) {
				if (userItemID) {
					let user = JSON.parse(await getUser(null, false));
					let response = await redeemItem(userItemID).catch((error) => {
						setShowPopOver(false);
						alert(
							"There was an error redeeming your item. Please try again. If you continue to run into issues please contact support at support@fitsquadapp.com"
						);
					});
					if (response && response.length > 0) {
						setItemQuantity(await getQuantityOfItemByItemID(itemDetails.id, true));
						let body = {
							readyForProcessing: true,
						};
						await updateInventoryItem(userItemID, body);
						sendSlackNotification(
							`*NEW ITEM READY FOR PROCESSING*\nUsername: ${user.userName}\nItem: ${itemDetails.name}\nItem ID : ${itemDetails.id}\nUser Item ID: ${userItemID}`,
							"keyEvent"
						);
						setShowPopOver(false);
					} else {
						setShowPopOver(false);
						// error cases... what are they?
						alert(
							"There was an error redeeming your item. Please try again. If you continue to run into issues please contact support at support@fitsquadapp.com"
						);
						sendSlackErrorNotification(
							"Redeem item returned empty response",
							"InventoryItem",
							`\nUserItemID: ${userItemID}\nUser ID: ${user.cognitoId}\nUsername: ${user.userName}\nItem Details: ${JSON.stringify(itemDetails)}`
						);
					}
				}
				setUpdating(false);
				setShowPopOver(true);
			}
		}
	};

	const closeModal = async () => {
		setModal({ visible: false });
	};

	return (
		<React.Fragment>
			<IonContent>
				<IonHeader>
					<IonToolbar>
						<IonTitle>Confirm Address</IonTitle>
						<IonButton color="secondary" slot="end" onClick={() => setModal({ visible: false })}>
							<IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
						</IonButton>
					</IonToolbar>
				</IonHeader>
				<IonLoading isOpen={updating} onDidDismiss={() => setUpdating(false)} message={"Confirming"} duration={3000} />
				<IonPopover
					//event={showGoalConfirmation.event}
					isOpen={showPopOver}
					cssClass="gif-response"
					onDidDismiss={(e) => closeModal()}
				>
					<div style={{ fontSize: "1.25em", padding: "10%", textAlign: "center" }}>
						Information accepted! We'll contact you via email about updates on your shipping
					</div>
				</IonPopover>
				<div className="collect-address-div">
					<div className="collect-address-subtitle">Please enter or confirm your address below so we can ship you your {itemDetails.name}</div>
					<IonRow>
						<IonCol size="6">
							<IonRow>First name*</IonRow>
							<IonRow>
								{firstName ? (
									<IonInput
										className="collect-info-input"
										ref={firstNameRef}
										value={firstName}
										name="firstName"
										type={"text"}
										placeholder="e.g. Diane"
										enterkeyhint="next"
										onFocus={() => setFirstNameError("")}
										required
									></IonInput>
								) : (
									<IonInput
										className="collect-info-input"
										ref={firstNameRef}
										name="firstName"
										type={"text"}
										placeholder="e.g. Diane"
										enterkeyhint="next"
										onFocus={() => setFirstNameError("")}
										required
									></IonInput>
								)}
							</IonRow>
							{firstNameError !== "" ? <IonRow className="error-message">{firstNameError}</IonRow> : ""}
						</IonCol>
						<IonCol size="6">
							<IonRow>Last name*</IonRow>
							<IonRow>
								{lastName ? (
									<IonInput
										className="collect-info-input"
										ref={lastNameRef}
										value={lastName}
										name="lastName"
										type={"text"}
										placeholder="e.g. Smith "
										enterkeyhint="next"
										onFocus={() => setLastNameError("")}
										required
									></IonInput>
								) : (
									<IonInput
										className="collect-info-input"
										ref={lastNameRef}
										name="lastName"
										type={"text"}
										placeholder="e.g. Smith "
										enterkeyhint="next"
										onFocus={() => setLastNameError("")}
										required
									></IonInput>
								)}
							</IonRow>
							{lastNameError !== "" ? <IonRow className="error-message">{lastNameError}</IonRow> : ""}
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="12">
							<IonRow>Email Address*</IonRow>
							<IonRow>
								{email ? (
									<IonInput
										className="collect-info-input"
										ref={emailRef}
										value={email}
										name="email"
										type={"email"}
										placeholder="e.g. you@gmail.com"
										enterkeyhint="next"
										required
									></IonInput>
								) : (
									<IonInput
										className="collect-info-input"
										ref={emailRef}
										name="email"
										type={"email"}
										placeholder="e.g. you@gmail.com"
										enterkeyhint="next"
										required
									></IonInput>
								)}
							</IonRow>
							{emailError !== "" ? <IonRow className="error-message">{emailError}</IonRow> : ""}
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="12">
							<IonRow>Address*</IonRow>
							<IonRow>
								{address1 ? (
									<input
										className="collect-info-input native-input sc-ion-input-md sc-ion-input-ios"
										ref={ref}
										style={{ width: "90%" }}
										value={address1}
										onClick={() => setAddress1()}
										onChange={() => null}
									/>
								) : (
									<input
										className="collect-info-input native-input sc-ion-input-md sc-ion-input-ios"
										ref={ref}
										style={{ width: "90%" }}
										onClick={() => setAddress1()}
										onChange={() => null}
									/>
								)}
							</IonRow>
							{addressError !== "" ? <IonRow className="error-message">{addressError}</IonRow> : ""}
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="12">
							<IonRow>Apartment, suite, etc.</IonRow>
							<IonRow>
								{address2 ? (
									<IonInput
										className="collect-info-input"
										ref={address2Ref}
										value={address2}
										name="address2"
										type={"text"}
										placeholder="e.g. Diane"
										enterkeyhint="next"
										required
									></IonInput>
								) : (
									<IonInput
										className="collect-info-input"
										ref={address2Ref}
										name="address2"
										type={"text"}
										placeholder="e.g. Diane"
										enterkeyhint="next"
										required
									></IonInput>
								)}
							</IonRow>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="12">
							<IonRow>City*</IonRow>
							<IonRow>
								{city ? (
									<IonInput
										className="collect-info-input"
										ref={cityRef}
										value={city}
										name="city"
										type={"text"}
										placeholder="e.g. San Diego"
										enterkeyhint="next"
										onFocus={() => setCityError("")}
										required
									></IonInput>
								) : (
									<IonInput
										className="collect-info-input"
										ref={cityRef}
										name="city"
										type={"text"}
										placeholder="e.g. San Diego"
										enterkeyhint="next"
										onFocus={() => setCityError("")}
										required
									></IonInput>
								)}
							</IonRow>
							{cityError !== "" ? <IonRow className="error-message">{cityError}</IonRow> : ""}
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="12">
							<IonRow>State/Region*</IonRow>
							<IonRow>
								{state ? (
									<IonInput
										className="collect-info-input"
										ref={stateRef}
										value={state}
										name="state"
										type={"text"}
										placeholder="e.g. California"
										enterkeyhint="next"
										onFocus={() => setStateError("")}
										required
									></IonInput>
								) : (
									<IonInput
										className="collect-info-input"
										ref={stateRef}
										name="state"
										type={"text"}
										placeholder="e.g. California"
										enterkeyhint="next"
										onFocus={() => setStateError("")}
										required
									></IonInput>
								)}
							</IonRow>
							{stateError !== "" ? <IonRow className="error-message">{stateError}</IonRow> : ""}
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="12">
							<IonRow>Postal Code*</IonRow>
							<IonRow>
								{postalCode ? (
									<IonInput
										className="collect-info-input"
										ref={postalCodeRef}
										value={postalCode}
										name="postalCode"
										type={"text"}
										placeholder="e.g. 92101"
										enterkeyhint="next"
										onFocus={() => setCodeError("")}
										required
									></IonInput>
								) : (
									<IonInput
										className="collect-info-input"
										ref={postalCodeRef}
										name="postalCode"
										type={"text"}
										placeholder="e.g. 92101"
										enterkeyhint="next"
										onFocus={() => setCodeError("")}
										required
									></IonInput>
								)}
							</IonRow>
							{codeError !== "" ? <IonRow className="error-message">{codeError}</IonRow> : ""}
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="12">
							<IonRow>Country*</IonRow>
							<IonRow>
								<CountryDropdown
									className="collect-info-input native-input sc-ion-input-md sc-ion-input-ios"
									value={country}
									ref={countryRef}
									onChange={(val) => setCountry(val)}
									onFocus={() => setCountryError("")}
								/>
							</IonRow>
							{countryError !== "" ? <IonRow className="error-message">{countryError}</IonRow> : ""}
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol style={{ textAlign: "center" }} size="12">
							<IonButton onClick={() => sendAddress()} color="success">
								Submit
							</IonButton>
						</IonCol>
					</IonRow>
				</div>
			</IonContent>
		</React.Fragment>
	);
};

export default CollectAddress;
