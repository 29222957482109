import { Plugins } from "@capacitor/core";
import {
	IonAlert,
	IonBackButton,
	IonButton,
	IonButtons,
	IonContent,
	IonFooter,
	IonHeader,
	IonInput,
	IonLoading,
	IonPage,
	IonProgressBar,
	IonToolbar,
	useIonViewDidEnter,
} from "@ionic/react";
import Amplify, { Auth } from "aws-amplify";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router";
import aws_exports from "../../aws-exports";
import "../../css/Home.css";
import "../../css/Onboarding.css";
import { emptyAvatar } from "../../models/constants";
import { logGenericEvent, mixpanelIdentifyUser, setProperty } from "../../services/AnalyticsService";
import { getRandomUser } from "../../services/AuthService";
import { getAvailableChallengeByCode } from "../../services/ChallengeService";
import { sendSlackNotification } from "../../services/NotificationService";
import { validateCode } from "../../services/SquadService";
import { StorageService } from "../../services/StorageService";
import { updateUser, updateUserSetting } from "../../services/UserService";
const storageService = new StorageService();
const { Device } = Plugins;

const Register = ({ progress, setProgress, setSquadToJoin, defaultInviteCode }) => {
	const registerVersion = "notificationsAfterLogworkout";
	const registeringMessage = "Setting up your account (could take a minute)";
	const history = useHistory();
	const { Camera } = Plugins;
	const [isFakeUser, setIsFakeUser] = useState(false);
	const [userName, setUsername] = useState(null);
	const [email, setEmail] = useState(null);
	const [password, setPassword] = useState(null);
	const [platform, setPlatform] = useState();
	const [inviteCode, setInviteCode] = useState(null);

	const [registering, setRegistering] = useState({
		visible: false,
		message: "",
	});

	const [usernameError, setUsernameError] = useState({
		visible: false,
		message: null,
	});
	const [usernameConfirmation, setUsernameConfirmation] = useState({
		visible: false,
		message: null,
	});
	const [photoError, setPhotoError] = useState({
		visible: false,
		message: null,
	});
	const [inviteCodeError, setInviteCodeError] = useState({
		visible: false,
		message: null,
	});
	const [validatingCode, setValidatingCode] = useState(false);

	const usernameRef = useRef(null);
	const inviteCodeRef = useRef(null);

	useIonViewDidEnter(() => {
		Amplify.configure(aws_exports);
		Device.getInfo().then((deviceInfo) => {
			setPlatform(deviceInfo.platform);
		});
		setProgress(0.3);
		storageService.setItem("lastOnboardingStep", 3);
	});

	const signInLocal = async (data) => {
		const storageService = new StorageService();
		let username = data.email;
		let password = data.password;
		let awsResponse;
		let response;
		try {
			let response = await Auth.signIn({
				username,
				password,
			});
			//console.log("Response from AWS signIn: " + JSON.stringify(response));
			let loginObject = JSON.parse(JSON.stringify(response));
			awsResponse = loginObject;
			//accessToken = loginObject['signInUserSession']['accessToken']['jwtToken']
			let expirationDate = loginObject.signInUserSession.idToken.payload.exp;
			let refreshToken = loginObject.signInUserSession.refreshToken.token;
			let idToken = loginObject.signInUserSession.idToken.jwtToken;
			await storageService.setItem("idToken", idToken);
			await storageService.setObject("expirationDate", expirationDate);
			await storageService.setItem("refreshToken", refreshToken);
			await storageService.setItem("displayUsername", data.username);
			await storageService.setItem("fullUsername", data.email);
			await storageService.setItem("password", data.password);
		} catch (error) {
			//console.log("Error from AWS signIn: " + JSON.stringify(error));
			alert("Error signing in: ", { error });
			return;
		}
		await storageService.setObject("onboarding", { completed: false, page: 4 });
		await storageService.setItem("lastOnboardingStep", 4);
		let body = {
			userName: data.username,
			email: data.email,
			avatar: emptyAvatar,
		};
		if (isFakeUser === true) {
			body.gender = "other";
		}
		console.log({ awsResponse });
		try {
			mixpanelIdentifyUser(awsResponse.attributes.sub);
		} catch {
			console.log("Mixpanel error");
		}
		logGenericEvent("Registered");
		do {
			console.log("Trying update user");
			response = await updateUser(body);
			console.log("Response from update user: ", { response });
		} while (response == null);
		updateUserSetting("initialVersion", registerVersion);
		setProperty("initialVersion", registerVersion);
		sendSlackNotification("*New account created!* \nUsername: " + data.username + "\n", "creation");
		setRegistering({ visible: false, message: "" });
		history.push("/onboarding/greeting");
	};

	async function signUpLocal(data) {
		let username = data.email;
		let password = data.password;
		let email = data.email;
		try {
			let response = await Auth.signUp({
				username,
				password,
				attributes: {
					email,
				},
			});
			// console.log("Response from AWS signUp: " + JSON.stringify(response));
		} catch (error) {
			console.log("Error from AWS signUp: " + JSON.stringify(error));
			//alert(`Error registering: ${error}`);
			return error;
		}
		signInLocal(data);
	}

	const validateUsername = (username) => {
		if (!username || username.length < 4) {
			setUsernameError({
				visible: true,
				message: "Username should be at least 4 characters",
			});
			return false;
		}
		if (username.length > 16) {
			setUsernameError({
				visible: true,
				message: "Please keep your username under 16 character 🙏",
			});
			return false;
		}
		if (username.includes(" ")) {
			setUsernameError({
				visible: true,
				message: "No spaces or puntuation please 🙏",
			});
			return false;
		}
		setUsernameError({
			visible: false,
			message: null,
		});
		return true;
	};

	const confirmUsername = async () => {
		setRegistering({ visible: true, message: registeringMessage });
		let deviceInfo = await storageService.getObject("deviceInfo");
		if (deviceInfo === null) {
			// just in case...
			deviceInfo = await Device.getInfo();
			await storageService.setObject("deviceInfo", deviceInfo);
		}
		let data = {
			username: usernameRef.current.value,
			password: deviceInfo.uuid,
			email: usernameRef.current.value + "-" + deviceInfo.uuid + "@tempemail.com",
		};
		console.log({ data });
		let errorResponse = await signUpLocal(data);
		console.log("ErrorResponse: " + JSON.stringify(errorResponse));
		if (errorResponse) {
			setRegistering({ visible: false, message: "" });
			let message;
			switch (errorResponse.message) {
				case "User already exists":
					message = "Hmm looks like that username is already in our system. Try another";
					break;
				default:
					message = errorResponse.message;
					break;
			}
			setUsernameError({
				visible: true,
				message: message,
			});
		}
	};

	const validateData = async () => {
		let validData = true;
		// Starts off true
		if (validData === true) {
			validData = validateUsername(usernameRef.current.value);
			console.log("Checking username");
		}
		// If the username is good continue
		if (validData === true) {
			if (inviteCodeRef.current.value) {
				validData = await validateInviteCode(inviteCodeRef.current.value);
				console.log("Checking invite code");
			} else {
				console.log("Skipping invite code check");
			}
		}
		// If the invite code is valid continue
		if (validData === false) {
			return false;
		} else {
			setUsernameConfirmation({
				visible: true,
				message: usernameRef.current.value,
			});
		}
	};

	const validateDataFake = async () => {
		setIsFakeUser(true);
		let rando = await getRandomUser();
		setEmail(rando.results[0].email);
		setPassword("qqqqqqqq");
		setUsername(rando.results[0].login.username);
	};

	const validateInviteCode = async (inviteCode) => {
		setValidatingCode(true);
		let response = await validateCode(inviteCode.toLowerCase());
		console.log(`Response from validateCode ${JSON.stringify(response)}`);
		if (Object.keys(response).length === 0) {
			let response = await getAvailableChallengeByCode(inviteCode.toLowerCase());
			if (response !== null) {
				await storageService.setItem("initialCode", inviteCode.toLowerCase());
				await storageService.setObject("initialChallengeToJoin", response);
				console.log({ response });
				setValidatingCode(false);

				return true;
			} else {
				setValidatingCode(false);
				//TODO: Better handling of this error
				setInviteCodeError({
					visible: true,
					message: "That invite code was unrecognized. Please try a different code.",
				});
				return false;
			}
		} else {
			setValidatingCode(false);
			await storageService.setItem("initialCode", inviteCode.toLowerCase());
			await storageService.setObject("initialSquadToJoin", response);
			return true;
		}
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar style={{ "--border-style": "none" }}>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
				</IonToolbar>
				<IonProgressBar value={progress} />
			</IonHeader>
			<IonLoading isOpen={registering.visible} message={registering.message} backdropDismiss />
			<IonLoading isOpen={validatingCode} message="Validating invite code" backdropDismiss />
			<IonContent>
				<IonAlert
					isOpen={!!photoError.visible}
					header="Oops"
					message={photoError.message}
					buttons={[
						{
							text: "Understood",
							role: "ok",
							handler: () => {
								setPhotoError({
									visible: false,
									message: null,
								});
							},
						},
					]}
					onDidDismiss={() =>
						setPhotoError({
							visible: false,
							message: null,
						})
					}
				/>
				<IonAlert
					isOpen={!!usernameConfirmation.visible}
					header={usernameConfirmation.message}
					message="Is this want you want to be called?"
					buttons={[
						{
							text: "No",
							role: "cancel",
							handler: () => {
								setUsernameConfirmation({
									visible: false,
									message: null,
								});
							},
						},
						{
							text: "Yes",
							role: "ok",
							handler: () => confirmUsername(),
						},
					]}
					onDidDismiss={() =>
						setUsernameConfirmation({
							visible: false,
							message: null,
						})
					}
				/>
				<div className="onboarding-main-div">
					<div className="onboarding-title">To get started we need to know what to call you</div>
					<div className="onboarding-subtitle">Keep in mind this will be visible to others if you join a squad 😉</div>

					<img className="onboarding-hero-image" src="assets/images/team-silohuettes.jpg" alt="" />
					<div className="onboarding-username-box-div">
						{userName ? (
							<IonInput
								className="onboarding-username-box"
								ref={usernameRef}
								name="username"
								value={userName}
								type={"text"}
								placeholder="Username (get creative!)"
								enterkeyhint="next"
								onFocus={() => setUsernameError({ visible: false, message: null })}
								required
							></IonInput>
						) : (
							<IonInput
								className="onboarding-username-box"
								ref={usernameRef}
								name="username"
								type={"text"}
								placeholder="Username (get creative!)"
								enterkeyhint="next"
								onFocus={() => setUsernameError({ visible: false, message: null })}
								required
							></IonInput>
						)}
						{usernameError.visible === true ? <div className="onboarding-error-message">{usernameError.message}</div> : ""}
						{inviteCode ? (
							<IonInput
								className="squad-code-box"
								ref={inviteCodeRef}
								name="inviteCode"
								value={inviteCode}
								type={"text"}
								placeholder="Invite code"
								enterkeyhint="done"
								onFocus={() => setInviteCodeError({ visible: false, message: null })}
								required
							></IonInput>
						) : (
							<IonInput
								className="squad-code-box"
								ref={inviteCodeRef}
								name="inviteCode"
								type={"text"}
								placeholder="Invite code"
								enterkeyhint="done"
								onFocus={() => setInviteCodeError({ visible: false, message: null })}
								required
							></IonInput>
						)}
						{inviteCodeError.visible === true ? <p className="onboarding-error-message">{inviteCodeError.message}</p> : ""}
					</div>
				</div>
				<div className="onboarding-footer">
					<IonButton onClick={validateData} className="onboarding-advance-button">
						Create Account
					</IonButton>
					{platform && platform === "web" ? (
						<IonButton onClick={validateDataFake} className="onboarding-advance-button">
							Register fake
						</IonButton>
					) : (
						""
					)}
				</div>
			</IonContent>
			<IonFooter>
				<div className="onboarding-form">
					<div className="onboarding-form-items"></div>
					<div className="onboarding-disclaimer">
						By tapping to continue, you are indicating that you agree with our{" "}
						<a href="https://www.fitsquadapp.com/other/privacy-policy" target="_new">
							Privacy Policy
						</a>{" "}
						and{" "}
						<a href="https://www.fitsquadapp.com/other/terms-conditions" target="_new">
							Terms of Service
						</a>
					</div>
				</div>
			</IonFooter>
		</IonPage>
	);
};

export default Register;
