import { IonAvatar, IonButton, IonCol, IonRow, IonToast } from "@ionic/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";
import Emoji from "react-emoji-render";
import "../css/ActivityDetails.css";
import "../css/CompetitionCompleted.css";
import "../css/Home.css";
import { getUserActivities } from "../services/ActivitiesService";
import { logGiveLoveEvent } from "../services/AnalyticsService";
import { addReaction } from "../services/FeedService";
import { sendSlackNotification } from "../services/NotificationService";
import { getSquad, getSquadGoal, getSquadNameFromSquadID, getUsersInSquad } from "../services/SquadService";
import { getUserNameFromUserID, getUserNameOfLoggedInUser } from "../services/UserService";

const SquadSummaryComponent = ({ squadIDPassed, setLoading }) => {
	let squadID = squadIDPassed;
	const [showToast, setShowToast] = useState(false);
	const [squadAGoalObject, setSquadAGoalObject] = useState();
	const [squadAObject, setSquadAObject] = useState();
	const [squadAMembers, setSquadAMembers] = useState();
	const [squadATitles, setSquadATitles] = useState({
		mvpID: null,
		overAchieverID: null,
		fastID: null,
		runnerUpID: null,
	});
	const [height, setHeight] = useState();
	const memberCompleteText = ":boom: Hit their goal! :boom:";
	const memberAlmostText = ":+1: Gave great effort! :+1: ";
	const memberShortText = "Didn't quite get there :disappointed:";

	//let remainingSquadPoints;
	let width = window.innerWidth;
	useEffect(() => {
		loadSquadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const loadSquadData = async () => {
		// Setup Squad A
		console.log("SquadID: " + squadID);
		setSquadAObject(await getSquad(squadID));
		let startDate = moment().startOf("isoWeek").subtract(1, "weeks");
		let endDate = moment().endOf("isoWeek").subtract(1, "weeks").add(1, "day");
		console.log({ startDate });
		console.log({ endDate });

		let squadAGoal = await getSquadGoal(squadID, startDate, endDate, true, false);
		if (squadAGoal === null) {
			return;
		}
		//console.log({ squadAGoal });
		squadAGoal.percent = Math.round((squadAGoal.goalProgress / squadAGoal.goalPoints) * 100);

		//Setup Squad A Members
		let squadAMembers = await getUsersInSquad(squadID, true, false, startDate, endDate, false);
		console.log({ squadAMembers });
		//Set MVP
		squadAMembers.sort((a, b) => (a.goalProgress > b.goalProgress ? -1 : 1));
		let mvp = squadAMembers[0].id;
		let runnerUp = squadAMembers[1].id;
		//Set Overachiever
		squadAMembers.sort((a, b) => (a.goalProgress / a.goalPoints > b.goalProgress / b.goalPoints ? -1 : 1));
		let i = 0;
		while (squadAMembers[i].id === mvp && i < squadAMembers.length) {
			i++;
		}
		let overAchiever = squadAMembers[i].id;
		// //Set most ambitious
		// squadAMembers.sort((a, b) => (a.goalPoints > b.goalPoints ? -1 : 1));
		// i = 0;
		// while ((squadAMembers[i].id === mvp || squadAMembers[i].id === overAchiever) && i < squadAMembers.length) {
		// 	i++;
		// }
		// let mostAmbitious = squadAMembers[i].id;
		squadAMembers.sort((a, b) => (a.goalProgress > b.goalProgress ? -1 : 1));

		setSquadAMembers(squadAMembers);
		setSquadATitles({
			mvpID: mvp,
			overAchieverID: overAchiever,
			// mostAmbitiousID: mostAmbitious,
			runnerUpID: runnerUp,
		});
		//console.log("squad A Members: ", { squadAMembers });

		if (squadAGoal.percent >= 100) {
			squadAGoal.titleText = ":raised_hands: GOAL COMPLETED!";
			squadAGoal.squadFlavorText = "Your squad did a great job all week and crushed this goal. Way to go!";
			squadAGoal.squadIcon = "checkmarkCircleSharp";
			squadAGoal.iconColor = "green";
			squadAGoal.buttonTitle = "Shoutout your squad";
			squadAGoal.success = true;
		} else if (squadAGoal.percent >= 90) {
			squadAGoal.titleText = ":pinching_hand: Great effort!";
			squadAGoal.squadFlavorText = "Your squad gave a solid effort but just came up a little short. This upcoming week presents a new opportunity!";
			squadAGoal.squadIcon = "warningSharp";
			squadAGoal.iconColor = "yellow";
			squadAGoal.buttonTitle = "Encourage your squad";
			squadAGoal.success = false;
		} else if (squadAGoal.percent < 90) {
			squadAGoal.titleText = ":disappointed: Didn't quite get there";
			squadAGoal.squadFlavorText = "Your squad tried but it just wasn't meant to be last week. Think you guys have what it takes this week?";
			squadAGoal.squadIcon = "closeCircleSharp";
			squadAGoal.iconColor = "red";
			squadAGoal.buttonTitle = "Console your squad";
			squadAGoal.success = false;
		}
		setSquadAGoalObject(squadAGoal);
		setLoading(false);
	};

	const sendMessageToSquad = () => {};
	const sendLove = async (userID, emoji) => {
		//Get Activities for the userID for the monday
		setShowToast({
			open: true,
			message: "Love sent!",
		});
		let startDate = moment().startOf("isoWeek");
		let endDate = moment(startDate).add(10, "hours");
		let activities = JSON.parse(await getUserActivities(null, null, startDate, endDate, null, userID));
		let activityID = null;
		if (activities) {
			activities.forEach((activity) => {
				if (activity.activityType === "userGoalEvaluated") {
					activityID = activity.id;
					return;
				}
			});
		}
		if (activityID !== null) {
			logGiveLoveEvent("end-of-week-squad-summary", "goal completed", emoji, userID);
			addReaction(activityID, emoji);
			let squadName = await getSquadNameFromSquadID(squadIDPassed);
			let fromUserName = await getUserNameOfLoggedInUser();
			let toUserName = await getUserNameFromUserID(userID);
			let notificationText = `🏁*New Weekly Summary Reaction Added*🏁\nSquad Name: ${squadName}\nFrom User: ${fromUserName}\nTo User: ${toUserName}\nReaction: ${emoji}`;
			sendSlackNotification(notificationText, "love");
		}
	};

	return (
		<div>
			<IonToast isOpen={showToast.open} onDidDismiss={() => setShowToast({ open: false, message: null })} message={showToast.message} duration={1000} />
			<div id="top-part" className="competition-scoreboard-header">
				<div className="competition-squad-data-holder">
					{squadAObject && squadAGoalObject ? (
						<div>
							<div style={{ fontSize: "1.45em", textAlign: "center" }}>
								{squadAGoalObject.iconColor === "red" ? <Emoji text={squadAGoalObject.titleText} /> : ""}
								{squadAGoalObject.iconColor === "yellow" ? <Emoji text={squadAGoalObject.titleText} /> : ""}
								{squadAGoalObject.iconColor === "green" ? <Emoji text={squadAGoalObject.titleText} /> : ""}
								{squadAGoalObject.iconColor === "orange" ? <Emoji text={squadAGoalObject.titleText} /> : ""}
							</div>
							<div id="numbers-part" className="competition-goal-holder" style={{ paddingLeft: "10px", textAlign: "left", height: "100%" }}>
								<span>Total: {squadAGoalObject.goalProgress.toLocaleString()}</span>
								<br />
								<br />
								<span>Goal: {squadAGoalObject.goalPoints.toLocaleString()}</span>
								<br />
								<br />
								<span>Percent: {squadAGoalObject.percent}%</span>
							</div>
							<div style={{ backgroundColor: "purple", padding: "10px" }}>{squadAGoalObject.squadFlavorText}</div>
						</div>
					) : (
						""
					)}
				</div>
			</div>
			{/*
			squadAGoalObject ? (
				<div style={{ textAlign: "center" }}>
					<IonButton expand="block" onClick={() => sendMessageToSquad()}>
						{squadAGoalObject.buttonTitle}
					</IonButton>
				</div>
			) : (
				""
			)*/}

			<div>
				<div className="competition-squad-team-holder" style={{ textAlign: "center" }}>
					{squadAMembers &&
						squadAMembers.map((member, i) => {
							let bkColor;
							if (Math.round((member.goalProgress / member.goalPoints) * 100) >= 100) {
								bkColor = "green";
							} else if (Math.round((member.goalProgress / member.goalPoints) * 100) >= 90) {
								bkColor = "yellow";
							} else if (Math.round((member.goalProgress / member.goalPoints) * 100) < 90) {
								bkColor = "red";
							}

							if (i % 2 === 0) {
								bkColor = "white";
							} else {
								bkColor = "lightblue";
							}
							return (
								<IonRow key={`${i}`} style={{ backgroundColor: `${bkColor}` }}>
									<IonCol className="squad-a-image-holder" size="4">
										<IonAvatar className="member-image" style={{ margin: "auto" }}>
											<img className="user-avatar" style={{ width: "100%", height: "100%", objectFit: "cover" }} src={member.user.avatar} alt="" />
										</IonAvatar>
										Send love
										<br />
										<IonButton className="love-buttons" onClick={() => sendLove(member.userId, "raised_hands")}>
											<Emoji text=":raised_hands:" />
										</IonButton>
										<IonButton className="love-buttons" onClick={() => sendLove(member.userId, "muscle")}>
											<Emoji text=":muscle:" />
										</IonButton>
										<br />
										<IonButton className="love-buttons" onClick={() => sendLove(member.userId, "+1")}>
											<Emoji text=":+1:" />
										</IonButton>
										<IonButton className="love-buttons" onClick={() => sendLove(member.userId, "poop")}>
											<Emoji text=":poop:" />
										</IonButton>
									</IonCol>
									<IonCol size="8">
										<IonRow>
											{squadATitles.mvpID && squadATitles.mvpID === member.id ? (
												<div className="projected-mvp" style={{ width: "100%" }}>
													<Emoji text=":trophy: MVP :trophy:" />
													<br />
													<span>Most points this week</span>
												</div>
											) : (
												<div></div>
											)}
											{squadATitles.overAchieverID && squadATitles.overAchieverID === member.id ? (
												<div className="most-on-fire" style={{ width: "100%" }}>
													<Emoji text=":fire: Over Achiever :fire:" />
													<br />
													<span>Biggest % over goal</span>
												</div>
											) : (
												<div></div>
											)}
											{squadATitles.runnerUpID && squadATitles.runnerUpID === member.id ? (
												<div className="fastest-start" style={{ width: "100%" }}>
													<Emoji text=":second_place_medal: Runner-up :second_place_medal:" />
													<br />
													<span>2nd most points this week</span>
												</div>
											) : (
												<div></div>
											)}
										</IonRow>
										<IonRow>
											<IonCol size="12" style={{ textAlign: "left" }}>
												<span className="competition-squad-name" style={{ fontSize: "1.4em", fontWeight: "bold" }}>
													{member.user.userName}
												</span>
												<br />
												<span className="competition-squad-name">Total: {member.goalProgress.toLocaleString()}</span>
												<br />
												<span className="competition-squad-name">Goal: {member.goalPoints.toLocaleString()}</span>
												<br />
												<span className="competition-squad-name">Percent: {Math.round((member.goalProgress / member.goalPoints) * 100)}%</span>
												<br />
												{member.goalProgress >= member.goalPoints ? <Emoji style={{ fontSize: "1em", fontStyle: "italic" }} text={memberCompleteText} /> : ""}

												{member.goalProgress >= 0.9 * member.goalPoints && member.goalProgress < member.goalPoints ? (
													<Emoji style={{ fontSize: "1em", fontStyle: "italic" }} text={memberAlmostText} />
												) : (
													""
												)}
												{member.goalProgress < 0.9 * member.goalPoints ? <Emoji style={{ fontSize: "1em", fontStyle: "italic" }} text={memberShortText} /> : ""}
											</IonCol>
										</IonRow>
									</IonCol>
								</IonRow>
							);
						})}
				</div>
			</div>
			{squadAGoalObject && squadAMembers && squadAGoalObject.success === true ? (
				<Confetti width={width} height={10000} recycle={true} numberOfPieces={100} />
			) : (
				""
			)}
		</div>
	);
};

export default SquadSummaryComponent;
