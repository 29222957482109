import { StorageService } from "./StorageService";
import Amplify, { Auth } from "aws-amplify";
import aws_exports from "../aws-exports";
import { timeConverter } from "./HelperServices";
import { API_URL, COGNITO_URL, ClientID, offlineJWT, LOCAL_API_URL } from "../models/constants";

let debugExpirationDate = 1595119758;
var expired = false;

//const idToken = "eyJraWQiOiJiVmI1eEM0NDZTdExWcFdVcWtLZ2hwNGRTSnNURERPMVJzUGNFMjgya0YwPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI4YjNlZTg1MS02ZjAyLTRjZWQtODllNy00MTAyYWQ4Y2I0NTAiLCJhdWQiOiIzbzJndnM1azNuNXFkdWFjOHR2MTg4cGhlaiIsImNvZ25pdG86Z3JvdXBzIjpbInVzZXJzIl0sImV2ZW50X2lkIjoiYjU5YTgwYzUtMjU4Yy00MmE2LTkyNTItYzhmMTdlMjcyNzk4IiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE1OTU4NzkyNTQsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy13ZXN0LTIuYW1hem9uYXdzLmNvbVwvdXMtd2VzdC0yXzNGYklZOFJ5ayIsImNvZ25pdG86dXNlcm5hbWUiOiJ3YWxrMTU5NTg3OTI0NjI0MiIsImV4cCI6MTU5NTg4Mjg1NCwiaWF0IjoxNTk1ODc5MjU0fQ.KMQXLONC4xZhAcL8e1CivMODcLp3Nk0VSc61wF2a-TboI8YI8gUcDB0FeeOv4S5honi-IDC8tpo6YSMzkmqv-CVhNb1byewLWMwKhcydkPQ9QdZK3hx5alIFpnAeuHz9p27yTf2qVmL1nV4atT82Y7TJ9UlmkiBOAr2lQ3veHLnJNcH_DYJRF23wN3wO8XfAfA1LxKMptxxQhEkD3qp8L7KUngOx5m3r2UjTTL6R4szuyHv6u5YoX4c9qTSBs_K2ugi34gmO1XQd9udQmP6rdWc5qj0IPpQ26RFdvk-5XvXB6wJXKcKurURhCmGjJ4fMyhDlSEfZGL3ZYJ_MRWb40Q"

/*
How this works:
- On every call we check if the token is valid
- First we call confirmToken, which checks if it's in debug mode
- If it's not in debug mode, we compare the expiration date to current date
-- If current is < expiration, confirmToken = true and we're all good
-- If current is > expiration, we need to refresh
-- If expiration doesn't exist, we need to login

*/

/**
 * confirmToken
 *
 * @param storageService
 *
 * returns true if token is valid or throws an error
 */
export const confirmToken = async (storageService) => {
	var expirationDate;
	const currentUnixTime = Math.floor(Date.now() / 1000);
	if (expired === true) {
		// In debug mode
		console.log("[JS] Debug flag set for refresh token");
		await storageService.setItem("expirationDate", debugExpirationDate.toString());
		await refreshToken(storageService)
			.then()
			.catch((error) => {
				throw Error("Error in refreshToken: " + error);
			});
		return true;
	}

	// Not in debug mode
	//console.log("[JS] [confirmToken] Checking expiration date");
	expirationDate = parseFloat(await storageService.getItem("expirationDate"));
	//console.log("[JS] [confirmToken] Expiration Date: " + timeConverter(expirationDate) + " -- Current Date: " + timeConverter(currentUnixTime));
	if (currentUnixTime < expirationDate) {
		// time to refresh the token
		//console.log("[JS] [confirmToken] Token is not expired");
		return true;
	} else {
		console.log("[JS] [confirmToken] Token is expired/non-existant. Refreshing");
		await refreshToken(storageService).catch((error) => {
			throw Error("Error from refreshToken: " + error);
		});
		return true; // should be a true or false
	}
};

/**
 * refreshToken
 *
 * @param storageService
 *
 * returns true if tokens were refreshed or throws an error
 */

export const refreshToken = async (storageService) => {
	var refreshToken;
	//console.log("[JS] [refreshToken] Pulling refreshToken");
	refreshToken = await storageService.getItem("refreshToken").catch((error) => {
		throw Error("Error from fetching refreshToken: " + error);
	});
	//console.log("[JS] refreshToken in fetch callback: "+refreshToken);
	if (refreshToken === null || refreshToken === "") {
		//console.log("[JS] [refreshToken] refreshToken is empty. Need to log user in");
		let signInResult = await signIn(storageService);
		return signInResult; // true or false. If it's false means user needs to reg
	} else {
		// we have a refresh token
		//console.log("[JS] [refreshToken] refreshToken found: "+refreshToken);
		console.log("[JS] [refreshToken] refreshToken found");
		console.log("[JS] [refreshToken] Calling AWS to refresh ID token");
		await getTokenFromAWS(refreshToken, storageService).catch((error) => {
			throw Error("Error returned from getTokenFromAWS: " + error);
		});
	}
	//console.log("[JS] refreshToken: "+refreshToken);
	return true; // true or false
};

export async function signIn(storageService) {
	Amplify.configure(aws_exports);
	const username = await storageService.getItem("fullUsername").catch((error) => {
		throw Error("Error from retrieving username: " + error);
	});
	console.log("fullUsername: " + username);

	const password = await storageService.getItem("password").catch((error) => {
		throw Error("Error from retrieving password: " + error);
	});
	if (username !== "" && password !== "") {
		const response = await Auth.signIn({ username, password }).catch((error) => {
			throw Error("Login Error returned: " + JSON.stringify(error));
		});
		//console.log("[JS] AWS SignIn Response: "+JSON.stringify(response));
		console.log("[JS] AWS SignIn Response successful");
		const awsResponse = JSON.stringify(response);
		const loginObject = JSON.parse(awsResponse);
		const expirationDate = loginObject["signInUserSession"]["idToken"]["payload"]["exp"];
		const refreshToken = loginObject["signInUserSession"]["refreshToken"]["token"];
		const idToken = loginObject["signInUserSession"]["idToken"]["jwtToken"];
		//let user = getUser(null, true);
		await storageService
			.setItem("idToken", idToken)
			.then((result) => {
				console.log("idToken successfully stored");
			})
			.catch((error) => {
				throw Error("Error from storing idToken: " + JSON.stringify(error));
			});
		await storageService
			.setObject("expirationDate", expirationDate)
			.then((result) => {
				console.log("expirationDate successfully stored");
			})
			.catch((error) => {
				throw Error("Error from storing expirationDate: " + JSON.stringify(error));
			});
		await storageService
			.setItem("refreshToken", refreshToken)
			.then((result) => {
				console.log("refreshToken successfully stored");
			})
			.catch((error) => {
				throw Error("Error from storing refreshToken: " + JSON.stringify(error));
			});
		return true;
	} else {
		console.log("No user account info stored");
		return false;
	}
}

/**
 *
 * @param refreshToken
 * @param storageService
 *
 * returns true if tokens are valid or throws an error
 *
 */

export const getTokenFromAWS = async (refreshToken, storageService) => {
	const url = COGNITO_URL;
	console.log("[JS] [getTokenFromAWS] Getting new token from AWS");
	//console.log("[JS] [getTokenFromAWS] Passing this refreshToken: "+refreshToken);
	const data = {
		AuthFlow: "REFRESH_TOKEN_AUTH",
		AuthParameters: {
			REFRESH_TOKEN: refreshToken,
		},
		ClientId: ClientID,
	};
	const response = await Promise.resolve(
		fetch(url, {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/x-amz-json-1.1",
				"X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
			},
		})
	);
	let awsResponse = await response.json();
	let responseType = awsResponse["__type"];
	switch (responseType) {
		case "InvalidParameterException":
			console.log("[JS] [getTokenFromAWS] Cognito Response:", awsResponse);
			throw Error(awsResponse["message"]);
		case "NotAuthorizedException":
			console.log("[JS] [getTokenFromAWS] Cognito Response:", awsResponse);
			console.log("Error from AWS: " + awsResponse["message"]);
			let signInSuccessful = await signIn(storageService);
			console.log("Sign In Successful: " + signInSuccessful); // if this is successful, the tokens are set
			if (signInSuccessful === false) {
				throw Error("Sign in failed. Probably need to re-register. Just throw error for now");
			}
			break;
		default:
			const currentUnixTime = Math.floor(Date.now() / 1000);
			let awsResponseJson = JSON.parse(JSON.stringify(awsResponse));
			//console.log('[JS] awsResponse Object:', awsResponseJson);
			let idToken = awsResponse["AuthenticationResult"]["IdToken"];
			//console.log('[JS] new idToken:', idToken);
			let accessToken = awsResponseJson["AuthenticationResult"]["AccessToken"];
			let expiresIn = awsResponseJson["AuthenticationResult"]["ExpiresIn"];
			if (idToken == null || accessToken == null || expiresIn == null) {
				throw Error("Error from AWS Login:\nidToken = " + idToken + "\nAccessToken = " + accessToken + "\nExpires In = " + expiresIn);
			}
			let expirationDate = currentUnixTime + expiresIn;
			console.log("[JS] New Expiration date:", timeConverter(expirationDate));
			storageService.setItem("idToken", idToken);
			storageService.setItem("expirationDate", expirationDate);
			storageService.setItem("accessToken", accessToken);
			break;
	}
	return true;
};

/**
 * getIDToken
 *
 * If user has tokens stored, confirms those token & return the ID token
 * If not, but if user has un/pw stored, log them in & return the ID token
 * If not, throws error that the user needs to register
 *
 * return ID token or throws error
 *
 */
export const getIDToken = async () => {
	var cognitoFieldsPresent;
	var idToken;
	var userFieldsPresent;
	var signInSuccess;
	var validTokens;
	const storageService = new StorageService();
	if (API_URL === LOCAL_API_URL) {
		return offlineJWT
	}
	//console.log("[JS] [getIDToken] checking token");
	// Check if exp date, id token & refresh token are stored
	cognitoFieldsPresent = await hasCognitoFields(storageService).catch((error) => {
		throw Error(error);
	});
	//console.log("[JS] [getIDToken] cognitoFieldsPresent = "+cognitoFieldsPresent);
	// if they're not, check for the user fields
	if (cognitoFieldsPresent === false) {
		//console.log("[JS] [getIDToken] cognitoFieldsPresent = false, checking userFields");
		userFieldsPresent = await hasUserFields(storageService).catch((error) => {
			throw Error(error);
		});
	}
	//console.log("[JS] [getIDToken] userFieldsPresent = "+userFieldsPresent);
	// if no cognitoFields  but user fields are there then log the user in
	if (userFieldsPresent === true && cognitoFieldsPresent === false) {
		console.log("[JS] [getIDToken] cognitoFieldsPresent = false, userFields = true");
		signInSuccess = await signIn(storageService).catch((error) => {
			throw Error(error);
		});
		if (signInSuccess === false) {
			// If sign in fails, register user
			throw Error("Sign in failed, user needs to be registered");
		}
	}
	if (userFieldsPresent === false && cognitoFieldsPresent === false) {
		console.log("[JS] [getIDToken] cognitoFieldsPresent = false, userFields = false");
		throw Error("No un/pw stored, user needs to be registered");
	}
	// If we're here, we have tokens. Need to check if they're good
	//console.log("[JS] [getIDToken] confirming tokens");
	validTokens = await confirmToken(storageService).catch((error) => {
		throw Error("Error in from confirmToken: " + error);
	});
	if (validTokens === true) {
		//console.log("[JS] [getIDToken] validTokens = " + validTokens);
		idToken = await storageService.getItem("idToken");
	}
	return idToken;
};

/**
 * hasAllRequiredFields
 *
 * Checks for expirationDate, idToken and refreshToken
 * If any are missing returns false, else returns true
 * If it returns false, user needs to be logged in
 *
 * @param storageService
 */

export const hasCognitoFields = async (storageService) => {
	var allFields = true;
	const expirationDate = await storageService.getItem("expirationDate").catch((error) => {
		console.log("Error in getting expirationDate: " + error);
		allFields = false;
	});
	const idToken = await storageService.getItem("idToken").catch((error) => {
		console.log("Error in getting idToken: " + error);
		allFields = false;
	});
	const refreshToken = await storageService.getItem("refreshToken").catch((error) => {
		console.log("Error in getting refreshToken: " + error);
		allFields = false;
	});

	if (expirationDate === "" || idToken === "" || refreshToken === "") {
		console.log("One of the e/i/f fields is empty");
		allFields = false;
	}
	return allFields;
};

/**
 * hasUserFields
 *
 * Checks for username and password
 * If any are missing returns false, else returns true
 * If it returns false, user needs to be registered
 *
 * @param storageService
 */

export const hasUserFields = async (storageService) => {
	var allFields = true;
	const fullUsername = await storageService.getItem("fullUsername").catch((error) => {
		console.log("Error in getting fullUsername: " + error);
		allFields = false;
	});
	const password = await storageService.getItem("password").catch((error) => {
		console.log("Error in getting password: " + error);
		allFields = false;
	});
	if (password === "" || fullUsername === "") {
		allFields = true;
	}
	return allFields;
};

export const getRandomUser = async (results=1) => {
	const url = "https://randomuser.me/api/?results="+results;
	const response = await Promise.resolve(
		fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		})
	).catch((error) => {
		console.log("[getRandomUser] Error in API call: " + error);
		throw Error(error);
	});
	return response
	.json()
	.then((data) => {
		return data;
	})
	.catch((error) => {
		console.log("[getRandomUser] Error in JSON conversion: " + error);
		throw Error(error);
	});
}

/*
// Username isn't sent back yet
export const getUserName = async (idToken : string = '') => {
    if (idToken === null || idToken === '' || idToken === undefined) {
        idToken = await getIDToken().catch(error => {throw Error("Error in getIDtoken: "+error)})
    }
    const response = await Promise.resolve(            
        fetch(API_URL + 'user', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': idToken
            }
        })
    )
    let userObject = await response.json().catch(error => {throw Error ("Error in parsing healthKit response: "+error)})
    console.log('[JS] [getUserName] Server Response:'+JSON.stringify(userObject))
    return userObject     
}
*/

