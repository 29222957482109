import { InAppPurchase2 as iap } from "@ionic-native/in-app-purchase-2";
import {
	IonAlert,
	IonBackButton,
	IonButton,
	IonButtons,
	IonCard,
	IonCol,
	IonContent,
	IonFooter,
	IonHeader,
	IonIcon,
	IonLoading,
	IonModal,
	IonPage,
	IonPopover,
	IonRow,
	IonSpinner,
	IonToast,
	IonToolbar,
	useIonViewWillEnter,
} from "@ionic/react";
import { closeCircleSharp, closeOutline } from "ionicons/icons";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import AuthContext from "../../contexts/GlobalState";
import "../../css/Challenges.css";
import "../../css/Home.css";
import { challengeTesterUsernames, cloudFrontURL } from "../../models/constants";
import { getAllUserChallengeLedgers, quitUserChallenge, updateUserChallenge } from "../../services/ChallengeService";
import { getAvailableChallengeByID, getChallengeFromArrayByID, signUpUserForChallenge } from "../../services/ChallengeService.ts";
import { sendSlackErrorNotification, sendSlackNotification } from "../../services/NotificationService";
import { StorageService } from "../../services/StorageService";
import { getUserID } from "../../services/UserService";
import ChallengeDetailsComponent from "./ChallengeDetailsComponent";
import ChallengeLedgerComponent from "./ChallengeLedgerComponent";
import ChallengeProgressComponent from "./ChallengeProgressComponent";

import CollectAddressChallenge from "./CollectAddressChallenge";
import ChallengeTerms from "./ChallengeTerms";
import ChallengeFAQsComponent from "./ChallengeFAQsComponent";
import ChallengeHeaderComponent from "./ChallengeHeaderComponent";
import ChallengeDetailsCoachMark from "../CoachMarks/ChallengeDetailsCoachMark";
import { coachMarkCheck } from "../../services/HelperServices";
import { logPageView } from "../../services/AnalyticsService";
import ChallengeSelection from "./ChallengeSelection";

const ChallengeDetails = () => {
	const { userState, updateUserState, challenges, updateChallenges } = useContext(AuthContext);
	const storageService = new StorageService();
	let challengeID = useParams().id;
	let width = window.innerWidth;
	const vidRef = useRef(null);
	const [challengeDetails, setChallengeDetails] = useState();
	const [showRulesPopover, setShowRulesPopover] = useState(false);
	const [videoPlaying, setVideoPlaying] = useState(false);
	const [videoWindowOpen, setVideoWindowOpen] = useState(false);
	const [challengeStatus, setChallengeStatus] = useState(); // if the user is in a challenge, hold the object here
	const [challengeIsActive, setChallengeIsActive] = useState(false);
	const [collectAddressModal, setCollectAddressModal] = useState({
		visible: false,
		challengeDetails: null,
		challengeStatus: null,
	});
	const [product, setProduct] = useState({});
	const [nextStepsModal, setNextStepsModal] = useState(false);
	const [deviceInfo, setDeviceInfo] = useState(userState.deviceInfo);
	const [quitChallenge, setQuitChallenge] = useState({
		visible: false,
		message: null,
	});
	const [loading, showLoading] = useState({
		visible: false,
		message: null,
	});
	const [challengeLedger, setChallengeLedger] = useState(null);
	const contentRef = useRef(null);
	const [showCoachMark, setShowCoachMark] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const [storeError, setStoreError] = useState(false);
	const [purchaseModal, showPurchaseModal] = useState(false);
	const [products, setProducts] = useState();
	const [purchaseState, setPurchaseState] = useState({
		success: false,
		tier: null,
		transactionData: null,
	});

	useIonViewWillEnter(async () => {});

	useEffect(() => {}, [challengeDetails, challengeLedger]);

	useEffect(() => {
		processPage();
	}, []);

	useEffect(() => {}, [challengeIsActive]);
	useEffect(() => {
		checkChallengesCoachMark();
	}, []);

	useEffect(() => {
		if (challengeID && challenges && challenges.length > 0) {
			checkForActiveChallenge();
		}
	}, [challenges]);

	const processPage = async () => {
		let challenge;
		challenge = await getChallenge();
		initInAppPurchase(challenge);
		await checkForActiveChallenge();
		let userID;
		if (!userState.user) {
			userID = await getUserID();
		} else {
			userID = userState.user.cognitoId;
		}
		logPageView(challenge.name + " Challenge (" + challenge.id + ")");
		sendSlackNotification(userState.user.userName + " viewed " + challenge.name + " Challenge (" + challenge.id + ")", "challenges", false);
		setChallengeLedger(await getAllUserChallengeLedgers(userID, challengeID));
	};

	const checkForActiveChallenge = async () => {
		for (let challenge of challenges) {
			if (challenge.challengeID === challengeID) {
				setChallengeStatus(challenge); // we store this here for challenges progress vs. challenge details
				if (parseInt(challenge.active) === 1) {
					console.log("Challenge is Active");
					setChallengeIsActive(true);
					setNextStepsModal(true);
				}
			}
		}
	};

	const initInAppPurchase = async (challengeDeets) => {
		let deviceInfo = await storageService.getObject("deviceInfo");
		if (!deviceInfo || deviceInfo.platform === "android" || deviceInfo.platform === "ios") {
			iap.verbosity = iap.DEBUG;
			console.log("Challenge Info", JSON.stringify(challengeDeets));

			let productObject = iap.get("challenge_level1_4weeks_full");
			console.log("Product Object for test", JSON.stringify(productObject));

			if (productObject && (productObject.state === "registered" || productObject.state === "valid")) {
				// assuming if one above is valid, they all are...
				let tempProducts = [];
				let t1ProductObject = iap.get("challenge_type_4");
				let t2ProductObject = iap.get("challenge_type_3");
				tempProducts.push(t1ProductObject);
				tempProducts.push(t2ProductObject);
				tempProducts.push(productObject);
				console.log("[already reg] Product Array: ", JSON.stringify(tempProducts));

				setProducts(tempProducts);
			} else {
				iap.register({
					id: "challenge_level1_4weeks_full",
					type: iap.CONSUMABLE,
				});
				iap.register({
					id: "challenge_type_3",
					type: iap.CONSUMABLE,
				});
				iap.register({
					id: "challenge_type_4",
					type: iap.CONSUMABLE,
				});
			}

			iap.error((err) => {
				console.log("Store Error", JSON.stringify(err));
				setShowToast({
					open: true,
					message: "Error with store. The FitSquad team has been notified",
				});
				setStoreError(true);
				sendSlackErrorNotification(
					"Error processing store. Error:" + JSON.stringify(err),
					"Challenge Details Page",
					"Challenge ID: " + challengeDeets.id + "\nUsername: " + userState.user.userName
				);
			});

			// Run some code only when the store is ready to be used
			iap.ready(() => {
				//alert("Product ready!");
				console.log("Product ready!");
				let tempProducts = [];
				let t1ProductObject = iap.get("challenge_type_4");
				let t2ProductObject = iap.get("challenge_type_3");
				let t3ProductObject = iap.get("challenge_level1_4weeks_full");
				tempProducts.push(t1ProductObject);
				tempProducts.push(t2ProductObject);
				tempProducts.push(t3ProductObject);
				console.log("[1st time] Product Array: ", JSON.stringify(tempProducts));
				console.log("Product 1: ", tempProducts[1].price);
				setProducts(tempProducts);
			});

			// iap.when(challenge.metadata.pricePlan).updated((product) => {
			// 	console.log("UPDATE TRIGGERED:");
			// 	console.log(JSON.stringify(product));
			// 	if (product.owned) console.log("Product owned");
			// 	else console.log("Product not owned");
			// });

			iap.when(challengeDeets.metadata.pricePlan).cancelled(() => {
				// The user cancelled the purchase
				console.log("Order cancelled");
				setShowToast({
					open: true,
					message: "Order cancelled",
				});
			});

			iap.when(challengeDeets.metadata.pricePlan).error(() => {
				// An error occured
				console.log("Order error");
				setShowToast({
					open: true,
					message: "Order error, contact support at contact@fitsquadapp.com",
				});
			});

			iap.when(challengeDeets.metadata.pricePlan).initiated(() => {
				// The user clicked on the purchase button and the purchase is now process
				console.log("Order initiated");
				setShowToast({
					open: true,
					message: "Order initiated",
				});
			});

			iap.refresh();
		} else {
			console.log("On web. Challenge: ");
			console.log({ challengeDeets });
			setProducts(sampleProducts);
		}
	};

	const getChallenge = async () => {
		let challenge = null;
		if (challengeID) {
			challenge = await getAvailableChallengeByID(challengeID);
			setChallengeDetails(challenge);
			// initInAppPurchase(challenge);
		}
		return challenge;
	};

	const collectDetails = async (transactionData, tier) => {
		// Sending challengeDetails here because needed to already fetch them anyways
		let startDate, endDate;
		const dayINeed = 1; // for Monday
		const today = moment().isoWeekday();
		// if we haven't yet passed the day of the week that I need:
		if (today <= dayINeed) {
			// then just give me this week's instance of that day
			startDate = moment().startOf("isoWeek");
		} else {
			// otherwise, give me *next week's* instance of that same day
			startDate = moment().add(1, "weeks").startOf("isoWeek");
		}
		endDate = moment(startDate)
			.add(challengeDetails.weekLength - 1, "weeks")
			.endOf("isoWeek");
		challengeDetails.startDate = startDate.format("YYYY-MM-DD HH:mm:ss"); // proper format for mysql ::shrug::
		challengeDetails.endDate = endDate.format("YYYY-MM-DD HH:mm:ss");
		challengeDetails.userID = await getUserID();
		challengeDetails.challengeID = challengeDetails.id;
		challengeDetails.transactionData = transactionData;
		challengeDetails.tier = tier;
		let salesNotification = `🟢*New Sale*🟢\nUsername: ${userState.user.userName}\nChallenge name: ${challengeDetails.name}\nPrice: ${product.price}`;
		sendSlackNotification(salesNotification, "purchase", false);

		// console.log("TRANSACTION DETAILS: ");
		console.log(JSON.stringify(challengeDetails));
		let signUpResponse = await signUpUserForChallenge(challengeDetails);
		console.log({ signUpResponse });

		if (purchaseState.tier > 0) {
			console.log("Showing collect address modal");
			setCollectAddressModal({ visible: true, challengeDetails: challengeDetails, challengeStatus: signUpResponse.challengeObject });
		} else {
			await updateChallenges(userState.user);
			setChallengeLedger(getAllUserChallengeLedgers(userState.user.cognitoId, challengeID));
		}
	};

	const confirmQuitChallenge = async (squadID, squadName) => {
		let message = `Quitting a challenge isn't reversible and no refund is possible. Are you sure you want to quit?`;
		setQuitChallenge({
			visible: true,
			message: message,
		});
	};

	const processQuitChallenge = async () => {
		// showLoading({ visible: true, message: "Quiting challenge..." });
		setNextStepsModal(false);
		let tempChallengeDetails = challengeStatus;
		tempChallengeDetails.active = 0;
		delete tempChallengeDetails.transactionData;
		delete tempChallengeDetails.status;
		//await updateUserChallenge(tempChallengeDetails);
		await quitUserChallenge(userState.user.cognitoId, tempChallengeDetails.id);
		await updateUserState();
		await updateChallenges();
		setChallengeLedger(await getAllUserChallengeLedgers(userState.user.cognitoId, challengeID));
		let salesNotification = `🔴*New Challenge Quit*🔴\nUsername: ${userState.user.userName}\nChallenge name: ${challengeDetails.name}`;
		sendSlackNotification(salesNotification, "purchase", false);
		// showLoading({ visible: false });
		scrollToTop();
		setChallengeIsActive(false);
	};

	const scrollToTop = () => {
		contentRef.current && contentRef.current.scrollToTop();
	};

	const playIntroVideo = () => {
		setVideoWindowOpen(true);
		// vidRef.current.play();
	};

	const checkChallengesCoachMark = async () => {
		await coachMarkCheck(setShowCoachMark, "challengeDetails");
	};

	return (
		<IonPage>
			{challengeDetails ? (
				<React.Fragment>
					<IonHeader>
						<IonToolbar className="details-toolbar" style={{ "--background": `${challengeDetails.metadata.primaryColor}` }}>
							<IonButtons slot="start">
								<IonBackButton text="" color="primary" />
							</IonButtons>
						</IonToolbar>
					</IonHeader>
					<IonLoading isOpen={showLoading.visible} onDidDismiss={() => showLoading({ visible: false })} message={showLoading.message} />

					<IonModal
						isOpen={collectAddressModal.visible}
						onDidDismiss={async () => {
							setCollectAddressModal({ visible: false, challengeDetails: null });
							await updateChallenges(userState.user);
							setChallengeLedger(getAllUserChallengeLedgers(userState.user.cognitoId, challengeID));
						}}
						cssClass="exercise-details-full-screen-popover"
					>
						<CollectAddressChallenge
							setModal={setCollectAddressModal}
							challengeObject={collectAddressModal.challengeStatus}
							challengeDetails={collectAddressModal.challengeDetails}
						/>
					</IonModal>
					<IonModal
						isOpen={purchaseModal}
						onDidDismiss={async () => {
							if (purchaseState.success === true) {
								collectDetails(purchaseState.transactionData, purchaseState.tier);
								// if (purchaseState.option > 0) {
								// 	setCollectAddressModal({ visible: true, challengeDetails: challengeDetails, challengeStatus: challengeStatus });
								// }
								// setChallengeLedger(getAllUserChallengeLedgers(userState.user.cognitoId, challengeID));
							}
						}}
						cssClass="exercise-details-full-screen-popover"
					>
						<ChallengeSelection challengeID={challengeID} showPurchaseModal={showPurchaseModal} products={products} setPurchaseState={setPurchaseState} />
					</IonModal>
					<IonPopover
						isOpen={showCoachMark}
						cssClass="chat-room-tool-tip-1"
						onDidDismiss={() => {
							setShowCoachMark(false);
						}}
					>
						<div style={{ position: "absolute", top: "0%", left: "90%", fontSize: "30px", zIndex: "200000" }} onClick={() => setShowCoachMark(false)}>
							<IonIcon icon={closeCircleSharp}></IonIcon>
						</div>
						<ChallengeDetailsCoachMark setShowCoachMark={setShowCoachMark} />
					</IonPopover>
					{challengeStatus ? (
						<IonPopover
							isOpen={nextStepsModal && challengeIsActive}
							onDidDismiss={() => {
								setNextStepsModal(false);
							}}
							cssClass="exercise-details-full-screen-popover"
						>
							<div style={{ textAlign: "center", marginTop: "10%" }}>
								<img className="challenge-details-image" src={`${cloudFrontURL}/challenges/icons/thumbs-up.png`} alt="" />
							</div>
							<div style={{ margin: "10%", fontSize: "1.25em" }}>
								<p>You're all set with your challenge!</p>
								{moment().format("YYYY MM DD") === moment(challengeStatus.startDate).format("YYYY MM DD") ? (
									<p>Your challenge started today.</p>
								) : (
									<p>Your challenge will start on {moment(challengeStatus.startDate).format("dddd MMM DD")}</p>
								)}
								<p>Good luck earning your points!</p>
								<p>Any problems? Email contact@fitsquadapp.com</p>
							</div>
						</IonPopover>
					) : (
						""
					)}
					<IonToast isOpen={showToast.open} onDidDismiss={() => setShowToast({ open: false, message: null })} message={showToast.message} duration={1000} />

					<IonContent ref={contentRef} scrollEvents={true}>
						<IonPopover
							isOpen={videoWindowOpen}
							cssClass="exercise-details-full-screen-popover"
							onDidDismiss={(e) => {
								setVideoWindowOpen(false);
							}}
						>
							<VideoPopover setVideoWindowOpen={setVideoWindowOpen} challengeID={challengeID} videoURL={challengeDetails.metadata.videoURL} />
						</IonPopover>
						<IonPopover
							isOpen={showRulesPopover}
							cssClass="activity-full-screen-popover"
							onDidDismiss={(e) => {
								setShowRulesPopover(false);
							}}
						>
							<ChallengeTerms />
						</IonPopover>

						<IonAlert
							isOpen={quitChallenge.visible}
							header="Confirm Squad Request"
							message={quitChallenge.message}
							buttons={[
								{
									text: "Cancel",
									role: "cancel",
									handler: () => {
										setQuitChallenge({ visible: false });
									},
								},
								{
									text: "Yes",
									role: "ok",
									handler: () => {
										processQuitChallenge();
									},
								},
							]}
							onDidDismiss={() => setQuitChallenge({ visible: false })}
						/>
						<ChallengeHeaderComponent challengeDetails={challengeDetails} />
						<div className="details-card-holder">
							{challengeStatus && challengeStatus.status === 3 && parseInt(challengeStatus.success) === 1 ? (
								<video ref={vidRef} id="success" preload="auto" autoPlay={false} muted controls style={{ width: "100%" }}>
									<source src={`${cloudFrontURL}/challenges/${challengeID}/success.mp4`} />
								</video>
							) : (
								""
							)}
							{challengeStatus && challengeStatus.status === 3 && parseInt(challengeStatus.success) === 0 ? (
								<video ref={vidRef} id="success" preload="auto" autoPlay={false} muted controls style={{ width: "100%" }}>
									<source src={`${cloudFrontURL}/challenges/${challengeID}/fail.mp4`} />
								</video>
							) : (
								""
							)}
							{challengeIsActive === true && challengeStatus && challengeDetails ? (
								<React.Fragment>
									<div className="details-section-title">Total progress</div>
									<ChallengeProgressComponent challengeObject={challengeStatus} challengeDetails={challengeDetails} />
								</React.Fragment>
							) : (
								""
							)}
							{challengeDetails && challengeDetails.metadata && challengeDetails.metadata.videoURL ? ( // Challenge is not active
								<div style={{ textAlign: "center" }}>
									<IonButton style={{ width: "80%", marginBottom: "10%" }} onClick={() => playIntroVideo()}>
										Watch Intro Video
									</IonButton>
								</div>
							) : (
								""
							)}
							{challengeLedger && challengeLedger.length > 0 ? (
								<React.Fragment>
									<div className="details-section-title">Challenge History</div>
									<ChallengeLedgerComponent challengeLedger={challengeLedger} />
								</React.Fragment>
							) : (
								""
							)}
							<ChallengeDetailsComponent challengeDetails={challengeDetails} challengeObject={challengeStatus} />
							<ChallengeFAQsComponent challengeDetails={challengeDetails} challengeObject={challengeStatus} />
						</div>
						{challengeIsActive === true ? (
							<div style={{ width: "80%", marginLeft: "10%", marginBottom: "5%" }}>
								<IonButton expand="block" color="danger" onClick={() => confirmQuitChallenge()}>
									Quit Challenge
								</IonButton>
							</div>
						) : (
							""
						)}
					</IonContent>
					<IonFooter className="button-footer">
						{challengeIsActive === false && challengeDetails ? (
							<div style={{ width: "80%", marginLeft: "10%" }}>
								<IonButton
									expand="block"
									color="success"
									onClick={() => {
										showPurchaseModal(true);
									}}
								>
									Sign up
								</IonButton>
							</div>
						) : (
							""
						)}
					</IonFooter>
				</React.Fragment>
			) : (
				<IonSpinner />
			)}
		</IonPage>
	);
};

export default ChallengeDetails;

const VideoPopover = ({ challengeID, videoURL }) => {
	const vidRef = useRef(null);
	return (
		<React.Fragment>
			<div>
				<video ref={vidRef} id="success" preload="auto" controls autoPlay={true} style={{ width: "100%" }}>
					<source src={`${cloudFrontURL}/challenges/${challengeID}/${videoURL}`} />
				</video>
			</div>
		</React.Fragment>
	);
};

const sampleProducts = [
	{
		id: "challenge_type_4",
		alias: "challenge_type_4",
		type: "consumable",
		group: "",
		state: "valid",
		title: "Donation-only Challenge",
		description: "Challenge where the reward is a donation to a charity",
		priceMicros: 4990000,
		price: "$4.99",
		currency: "USD",
		countryCode: null,
		loaded: true,
		canPurchase: true,
		owned: false,
		introPrice: "",
		introPriceMicros: "",
		introPricePeriod: null,
		introPriceNumberOfPeriods: null,
		introPricePeriodUnit: null,
		introPriceSubscriptionPeriod: null,
		introPricePaymentMode: null,
		ineligibleForIntroPrice: null,
		discounts: [],
		downloading: false,
		downloaded: false,
		additionalData: null,
		transaction: null,
		trialPeriod: null,
		trialPeriodUnit: null,
		billingPeriod: null,
		billingPeriodUnit: null,
		valid: true,
	},
	{
		id: "challenge_type_3",
		alias: "challenge_type_3",
		type: "consumable",
		group: "",
		state: "valid",
		title: "Challenge w/o shirt",
		description: "Challenge that does not involve a shirt as a reward",
		priceMicros: 29990000,
		price: "$29.99",
		currency: "USD",
		countryCode: null,
		loaded: true,
		canPurchase: true,
		owned: false,
		introPrice: "",
		introPriceMicros: "",
		introPricePeriod: null,
		introPriceNumberOfPeriods: null,
		introPricePeriodUnit: null,
		introPriceSubscriptionPeriod: null,
		introPricePaymentMode: null,
		ineligibleForIntroPrice: null,
		discounts: [],
		downloading: false,
		downloaded: false,
		additionalData: null,
		transaction: null,
		trialPeriod: null,
		trialPeriodUnit: null,
		billingPeriod: null,
		billingPeriodUnit: null,
		valid: true,
	},
	{
		id: "challenge_level1_4weeks_full",
		alias: "challenge_level1_4weeks_full",
		type: "consumable",
		group: "",
		state: "valid",
		title: "4 week Level 1 Challenge",
		description: "A level 1 Challenge that lasts 4 weeks",
		priceMicros: 39990000,
		price: "$39.99",
		currency: "USD",
		countryCode: null,
		loaded: true,
		canPurchase: true,
		owned: false,
		introPrice: "",
		introPriceMicros: "",
		introPricePeriod: null,
		introPriceNumberOfPeriods: null,
		introPricePeriodUnit: null,
		introPriceSubscriptionPeriod: null,
		introPricePaymentMode: null,
		ineligibleForIntroPrice: null,
		discounts: [],
		downloading: false,
		downloaded: false,
		additionalData: null,
		transaction: null,
		trialPeriod: null,
		trialPeriodUnit: null,
		billingPeriod: null,
		billingPeriodUnit: null,
		valid: true,
	},
];
