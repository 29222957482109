import React, { useEffect, useState } from "react";
import { IonSkeletonText } from "@ionic/react";
import "../../css/Profile.css";
import "../../css/Home.css";
import Emoji from "react-emoji-render";
import moment from "moment";
import { StorageService } from "../../services/StorageService";
import { getGoal } from "../../services/PointsAndGoalsService";
import { getSquadGoal } from "../../services/SquadService";

const SquadWeeklyStreaksComponent = ({ squadObject }) => {
	var [activityArray, setActivityArray] = useState([]);
	const [loadingStreaks, setLoadingStreaks] = useState(true);
	const storageService = new StorageService();

	useEffect(() => {
		setGoalStreaks(squadObject);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const setGoalStreaks = async (squadObject) => {
		console.log({ squadObject });
		let squadID = squadObject.id;
		let pointHistory = await storageService.getObject("squadPointHistory-" + squadID);
		if (pointHistory === null) {
			pointHistory = {};
		}
		if (!pointHistory.weeklyTotals) {
			pointHistory.weeklyTotals = {};
		}

		//Because it's historic data, we don't need to hit the server everytime to get it
		setActivityArray([]);
		let weeksChecked = 1;
		let goalObject;
		let totalPriorWeeks = 20;
		while (weeksChecked <= totalPriorWeeks) {
			let startDate = moment().startOf("isoWeek").subtract(weeksChecked, "weeks");
			let endDate = moment().endOf("isoWeek").subtract(weeksChecked, "weeks").add(1, "day");
			// Do this because of tz BS
			startDate = moment(startDate).subtract(1, "day");
			endDate = moment(endDate).add(1, "day");
			console.log("StartDate set: " + startDate.format());
			console.log("EndDate set: " + endDate.format());
			if (!pointHistory.weeklyTotals[moment(startDate).format("MM/DD/YY")]) {
				console.log(`Pulling new data for squad on ${moment(startDate).format("MM/DD/YY")}`);
				goalObject = await getSquadGoal(squadID, startDate, endDate, true, false);
				if (goalObject !== null) {
					pointHistory.weeklyTotals[moment(startDate).format("MM/DD/YY")] = goalObject;
				}
			} else {
				console
					.log
					//`Using current data:\n date: ${moment(startDate).format("MM/DD/YY")}  amount: ${pointHistory.dailyTotals[moment(startDate).format("MM/DD/YY")]} `
					();
				goalObject = pointHistory.weeklyTotals[moment(startDate).format("MM/DD/YY")];
			}
			//console.log({ goalObject });
			if (goalObject !== null) {
				if (goalObject.goalProgress >= goalObject.goalPoints) {
					goalObject.success = 1;
				} else {
					goalObject.success = 0;
				}
				// eslint-disable-next-line no-loop-func
				setActivityArray((activityArray) => [...activityArray, goalObject]);
			}
			weeksChecked++;
		}
		console.log("setGoalStreaks - pointHistory prior to setting: ", { pointHistory });
		storageService.setObject("squadPointHistory-" + squadID, pointHistory);
		console.log("Pulled a total of " + (weeksChecked + 1) + " weeks");
		console.log("[JS] [pullLastThreeWeeks] Complete");
		setLoadingStreaks(false);
	};

	return (
		<React.Fragment>
			<div className="section-title">Weekly goal streaks</div>
			{loadingStreaks === true ? (
				<IonSkeletonText style={{ width: "50px", height: "50px" }} animated />
			) : (
				<div className="horizontal-scroll" style={{ paddingLeft: "12px" }}>
					{activityArray.length > 0 ? (
						activityArray.map((activity, index) => {
							return (
								<div key={`${index}`} className="streak">
									<div className="goal-streak-container">
										<div className="goal-streak-indicator">
											{activity.success === 1 ? <Emoji text=":trophy:" /> : <Emoji text=":disappointed:" />}
											<br />
										</div>
										<div className="goal-streak-data-points">
											<div className="goal-streak-data-points-each">
												<span style={{ opacity: "0.5" }}>Points: </span>
												{activity.success === 1 ? (
													<span className="goal-streak-data-points-bold success-bold">{activity.goalProgress.toLocaleString()}</span>
												) : (
													<span className="goal-streak-data-points-bold fail-bold">{activity.goalProgress.toLocaleString()}</span>
												)}
											</div>
											<div className="goal-streak-data-points-each">
												<span style={{ opacity: "0.5" }}>Goal: </span>
												<span className="goal-streak-data-points-bold">{activity.goalPoints.toLocaleString()}</span>
											</div>
										</div>
									</div>
									<div className="goal-week">{moment(activity.startDate).format("MMM DD, YYYY")}</div>
								</div>
							);
						})
					) : (
						<div className="instructional-text">after your first week, you'll see your streaks here</div>
					)}
				</div>
			)}
		</React.Fragment>
	);
};

export default SquadWeeklyStreaksComponent;
