/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonCol,
	IonContent,
	IonHeader,
	IonInput,
	IonItem,
	IonLabel,
	IonLoading,
	IonPage,
	IonPopover,
	IonRow,
	IonToolbar,
} from "@ionic/react";
import Amplify, { Auth } from "aws-amplify";
import aws_exports from "../aws-exports";
import React, { useEffect, useRef, useState } from "react";
import { getIDToken } from "../services/AuthService";
import { useHistory } from "react-router";

const ResetPassword = () => {
	const history = useHistory();
	Amplify.configure(aws_exports);

	const [newpasswordError, setNewPasswordError] = useState({
		visible: false,
		message: null,
	});

	const [previouspasswordError, setPreviousPasswordError] = useState({
		visible: false,
		message: null,
	});

	const [generalError, setGeneralError] = useState({
		visible: false,
		message: null,
	});

	const [successMessage, setSuccessMessage] = useState({
		visible: false,
		message: null,
	});

	const [loggingIn, setLoggingIn] = useState({
		visible: false,
		message: "",
	});

	const [validatingData, setValidatingData] = useState({
		visible: false,
		message: null,
	});

	const previousPasswordRef = useRef(null);
	const newPasswordRef = useRef(null);

	useEffect(() => {
		newPasswordRef.current.value = "";
		previousPasswordRef.current.value = "";
	}, []);

	const validateNewPassword = async () => {
		let password;
		if (newPasswordRef.current === null) {
			password = null;
		} else {
			password = newPasswordRef.current.value;
		}
		if (password === null || password.length < 8) {
			setNewPasswordError({
				visible: true,
				message: "Password should be at least 8 characters",
			});
			return false;
		} else {
			setNewPasswordError({
				visible: false,
				message: null,
			});
			return true;
		}
	};

	const validateOldPassword = async () => {
		let password;
		if (previousPasswordRef === null) {
			password = null;
		} else {
			password = previousPasswordRef.current.value;
		}
		if (password === null || password.length < 1) {
			setPreviousPasswordError({
				visible: true,
				message: "Please enter your previous password",
			});
			return false;
		} else if (password.length < 8) {
			setPreviousPasswordError({
				visible: true,
				message: "Password should be at least 8 characters",
			});
			return false;
		} else {
			setPreviousPasswordError({
				visible: false,
				message: null,
			});
			return true;
		}
	};

	const resetPassword = async () => {
		if (validateNewPassword() === false) {
			return;
		}
		if (validateOldPassword() === false) {
			return;
		}
		let currentUserObject = await Auth.currentAuthenticatedUser();
		try {
			let idToken = await getIDToken();
			await Auth.changePassword(currentUserObject, previousPasswordRef.current.value, newPasswordRef.current.value);
			// let response = await Auth.changePassword(idToken, previousPasswordRef.current.value, newPasswordRef.current.value);
			// console.log("awsResponse from AWS signIn: " + JSON.stringify(response));
			setSuccessMessage({ visible: true, message: "Your password was successfully updated 👍" });
		} catch (error) {
			console.log("Error from AWS signIn: " + JSON.stringify(error));
			switch (error.code) {
				case "LimitExceededException":
					error.sanitizedMessage = "Too many attempts to try an reset the password for this account. Please try again in about 15 minutes.";
					break;
				case "NotAuthorizedException":
					error.sanitizedMessage = "Incorrect previous password";
					break;
				default:
					error.sanitizedMessage = error.message;
					break;
			}
			setGeneralError({
				visible: true,
				message: error.sanitizedMessage,
			});
		}
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar style={{ "--border-style": "none" }}>
					<IonButtons slot="start">
						<IonBackButton text="" />
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonLoading isOpen={loggingIn.visible} onDidDismiss={() => setLoggingIn({ visible: false, message: "" })} message={loggingIn.message} />
				<IonLoading isOpen={validatingData.visible} onDidDismiss={() => setValidatingData({ visible: false, message: "" })} message={validatingData.message} />
				<IonPopover
					isOpen={successMessage.visible}
					className="love-thanks"
					onDidDismiss={(e) => {
						setSuccessMessage({
							visible: false,
							message: null,
						});
						window.history.back();
					}}
				>
					<div style={{ fontSize: "1.25em", paddingTop: "33px", textAlign: "center" }}>
						<br />
						<span dangerouslySetInnerHTML={{ __html: successMessage.message }}></span>
					</div>
				</IonPopover>
				<div className="login-main-div">
					<p className="login-title">Reset Password</p>
					<IonItem>
						<IonLabel className="login-label" position="stacked">
							Previous Password
						</IonLabel>
						<IonInput ref={previousPasswordRef} name="email" type={"email"} placeholder="whoops@doh.com" enterkeyhint="next" required></IonInput>
					</IonItem>
					{previouspasswordError.visible === true ? (
						<p className="error-message" style={{ marginLeft: "20px", marginRight: "20px", marginTop: "10px" }}>
							{previouspasswordError.message}
						</p>
					) : (
						""
					)}
					<IonItem>
						<IonLabel className="login-label" position="stacked">
							New Password
						</IonLabel>
						<IonInput ref={newPasswordRef} name="email" type={"email"} placeholder="whoops@doh.com" enterkeyhint="next" required></IonInput>
					</IonItem>
					{newpasswordError.visible === true ? (
						<p className="error-message" style={{ marginLeft: "20px", marginRight: "20px", marginTop: "10px" }}>
							{newpasswordError.message}
						</p>
					) : (
						""
					)}
					<div style={{ marginTop: "10px" }}>
						<IonRow>
							<IonCol size="2"></IonCol>
							<IonCol size="8">
								<IonButton onClick={() => resetPassword()} expand="block">
									Reset Password
								</IonButton>
							</IonCol>
							<IonCol size="2"></IonCol>
						</IonRow>
					</div>
					{generalError.visible === true ? (
						<p className="error-message" style={{ marginLeft: "20px", marginRight: "20px", marginTop: "10px" }}>
							{generalError.message}
						</p>
					) : (
						""
					)}
				</div>
			</IonContent>
		</IonPage>
	);
};

export default ResetPassword;
