/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from "react";
import {
	IonAlert,
	IonBackButton,
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonLabel,
	IonPage,
	IonSegment,
	IonSegmentButton,
	IonTitle,
	IonToolbar,
	useIonViewWillEnter,
} from "@ionic/react";
import { syncOutline } from "ionicons/icons";
import { getSquadStandings, getSquadCompetitionStandings, getAllSquads } from "../services/SquadService";
import { ScrollSync, ScrollSyncNode } from "scroll-sync-react";
import "../css/Standings.css";
import { getAllUserIDs, getUser, getUserID, getUserStatistics } from "../services/UserService";
import StandingsSquadComponent from "../components/StandingsSquadComponent";
//import StandingsCompetitionComponent from "../components/StandingsCompetitionComponent";
import StandingsIndividualComponent from "../components/StandingsIndividualComponent";
import { StorageService } from "../services/StorageService";
import moment from "moment";
import { logPageView } from "../services/AnalyticsService";

const Standings = () => {
	const storageService = new StorageService();
	const [squadStandings, setSquadStandings] = useState([]);
	//const [squadCompetitionStandings, setSquadCompetitionStandings] = useState([]);
	const [individualStats, setIndividualStats] = useState([]);
	const [sortedColumn, setSortedColumn] = useState(3);
	const [view, setView] = useState("squad");
	const [loadingSquadStandings, setLoadingSquadStandings] = useState(true);
	//const [loadingCompetitionStandings, setLoadingCompetitionStandings] = useState(true);
	const [loadingIndividualStats, setLoadingIndividualStats] = useState(true);
	const [confirmRefreshAlert, setConfirmRefreshAlert] = useState([""]);

	useIonViewWillEnter(async () => {
		logPageView("standings");
		await loadSquadStandings();
		//loadCompetitionStandings();
		loadIndividualStandings();
	});

	useEffect(() => {});

	const loadSquadStandings = async (refresh = false) => {
		// Load the data for the squad standings
		let allSquads = squadStandings;
		if (allSquads.length === 0) {
			allSquads = await getAllSquads(refresh, refresh);
		}
		console.log({ allSquads });
		let allSquadsWithStandings = [];
		let currentUserID = await getUserID(refresh);
		for (let squad of allSquads) {
			squad.inSquad = false;
			let squadStandings;
			squadStandings = await getSquadStandings(squad.id, refresh).catch(async (error) => {
				console.log("No standings reported. Setting values to 0: " + error);
			});
			if (squadStandings === undefined) {
				squadStandings = {
					wins: 0,
					losses: 0,
					last10Wins: 0,
					winPercent: 0,
					last10Losses: 0,
					avgWeeklyGoal: null,
					avgWeeklyPoints: null,
					avgWeeklyDifference: null,
					avgMemberCompletionPercent: null,
				};
			}
			for (let user of squad.members) {
				if (currentUserID === user.userId && user.accepted === true) {
					squad.inSquad = true;
				}
			}
			squad.standings = squadStandings;
			allSquadsWithStandings.push(squad);
		}
		allSquadsWithStandings.sort((a, b) => (a.standings.winPercent > b.standings.winPercent ? -1 : 1));
		setSquadStandings(allSquadsWithStandings);
		setLoadingSquadStandings(false);
	};
	/*
	const loadCompetitionStandings = async (refresh = false) => {
		// Load the data for the competition standings
		let allSquads = squadCompetitionStandings;
		if (allSquads.length === 0) {
			allSquads = await getAllSquads(refresh, refresh);
		}
		let allSquadsWithStandings = [];
		let currentUserID = await getUserID(refresh);
		for (let squad of allSquads) {
			squad.inSquad = false;
			let squadCompetitionStandings;
			squadCompetitionStandings = await getSquadCompetitionStandings(squad.id, refresh).catch(async (error) => {
				console.log("Error. Setting values to 0: " + error);
			});
			if (squadCompetitionStandings === undefined) {
				squadCompetitionStandings = {
					wins: 0,
					losses: 0,
					last10Wins: 0,
					winPercent: 0,
					last10Losses: 0,
					ties: 0,
					last10Ties: 0,
				};
			}
			for (let user of squad.members) {
				if (currentUserID === user.userId) {
					squad.inSquad = true;
				}
			}
			squad.competitionStandings = squadCompetitionStandings;
			allSquadsWithStandings.push(squad);
		}
		allSquadsWithStandings.sort((a, b) => (a.competitionStandings.winPercent > b.competitionStandings.winPercent ? -1 : 1));
		setSquadCompetitionStandings(allSquadsWithStandings);
		setLoadingCompetitionStandings(false);
	};
*/
	const loadIndividualStandings = async (refresh = false) => {
		// Load the data for the individual standings
		let allUserIDs = await getAllUserIDs(refresh, refresh);
		let allUserStats = [];
		let currentUserID = await getUserID(refresh);
		for (let userID of allUserIDs) {
			let stats = {};
			let user;
			//console.log("Trying userID: " + userID);
			await getUserStatistics(userID, refresh)
				.catch((error) => {
					console.log("No data for user: " + userID + ". Setting stats to 0");
				})
				.then((response) => {
					stats = response;
				});
			if (stats === undefined) {
				stats = {
					avgWeeklyDifference: 0,
					avgWeeklyGoal: 0,
					avgWeeklyPoints: 0,
					last10Losses: 0,
					last10Wins: 0,
					lastUpdated: moment().format(),
					losses: 0,
					winPercent: 0,
					wins: 0,
				};
			}
			if (stats !== "" && stats !== null && stats !== undefined) {
				stats.accuracyPercent = Math.round((1 - Math.abs(stats.avgWeeklyDifference) / stats.avgWeeklyGoal) * 100);
				if (isNaN(stats.accuracyPercent)) {
					stats.accuracyPercent = 0;
				}
				user = JSON.parse(await getUser(userID, false));
				user.stats = stats;
				if (currentUserID === userID) {
					user.currentUser = true;
				}
				allUserStats.push(user);
			}
		}
		allUserStats.sort((a, b) => (a.stats.winPercent > b.stats.winPercent ? -1 : 1));
		setIndividualStats(allUserStats);
		setLoadingIndividualStats(false);
	};

	const refreshTrigger = async (event, confirmation = false) => {
		if (confirmation === false) {
			setConfirmRefreshAlert({
				message: "Stats typically only reset weekly. Are you sure you'd like to refresh?",
			});
		} else {
			// Refresh teams based on stats
			setLoadingSquadStandings(true);
			//setLoadingCompetitionStandings(true);
			setLoadingIndividualStats(true);
			await storageService.removeItem("user-statistics");
			await storageService.removeItem("user-ids");
			let allSquads = await getAllSquads(true, true);
			for (let squad of allSquads) {
				await storageService.removeItem("squad-standings-" + squad.id);
			}
			loadSquadStandings(true);
			//loadCompetitionStandings(true);
			loadIndividualStandings(true);
		}
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton defaultHref="/home" text="" color="light" />
					</IonButtons>
					<IonTitle>Standings</IonTitle>
					<IonButton color="secondary" slot="end" onClick={refreshTrigger}>
						<IonIcon slot="icon-only" icon={syncOutline}></IonIcon>
					</IonButton>
				</IonToolbar>
			</IonHeader>
			<IonSegment value={view} onIonChange={(e) => setView(e.detail.value)}>
				<IonSegmentButton value="squad">
					<IonLabel>Squads Overall</IonLabel>
				</IonSegmentButton>
				{/*
				<IonSegmentButton value="competition">
					<IonLabel>Competition</IonLabel>
				</IonSegmentButton>
				*/}
				<IonSegmentButton value="season">
					<IonLabel>Season</IonLabel>
				</IonSegmentButton>
				<IonSegmentButton value="individual">
					<IonLabel>Individual</IonLabel>
				</IonSegmentButton>
			</IonSegment>
			<IonContent>
				<IonAlert
					isOpen={!!confirmRefreshAlert.message}
					header="You sure?"
					message={confirmRefreshAlert.message}
					buttons={[
						{
							text: "Yes",
							role: "ok",
							handler: () => {
								refreshTrigger(null, true);
							},
						},
						{
							text: "Cancel",
							role: "cancel",
							handler: () => {
								setConfirmRefreshAlert("");
							},
						},
					]}
					onDidDismiss={() => setConfirmRefreshAlert("")}
				/>
				<ScrollSync>
					<ScrollSyncNode group="a">
						<div
							style={{
								overflowX: "auto",
								flexWrap: "nowrap",
								width: "100%",
							}}
						>
							{view === "squad" ? (
								<StandingsSquadComponent
									squadStandings={squadStandings}
									setSquadStandings={setSquadStandings}
									sortedColumn={sortedColumn}
									setSortedColumn={setSortedColumn}
									loadingSquadStandings={loadingSquadStandings}
									setLoadingSquadStandings={setLoadingSquadStandings}
								/>
							) : (
								""
							)}
							{/*
							view === "competition" ? (
								<StandingsCompetitionComponent
									squadCompetitionStandings={squadCompetitionStandings}
									setSquadCompetitionStandings={setSquadCompetitionStandings}
									sortedColumn={sortedColumn}
									setSortedColumn={setSortedColumn}
									loadingCompetitionStandings={loadingCompetitionStandings}
									setLoadingCompetitionStandings={setLoadingCompetitionStandings}
								/>
							) : (
								""
							)*/}
							{view === "season" ? (
								<div className="season-announcement">
									📅
									<br />
									There is not a current season.
									<br />
									Stay tuned for details on an upcoming one!
								</div>
							) : (
								""
							)}
							{view === "individual" ? (
								<StandingsIndividualComponent
									individualStats={individualStats}
									setIndividualStats={setIndividualStats}
									sortedColumn={sortedColumn}
									setSortedColumn={setSortedColumn}
									loadingIndividualStats={loadingIndividualStats}
									setLoadingIndividualStats={setLoadingIndividualStats}
								/>
							) : (
								""
							)}
						</div>
					</ScrollSyncNode>
				</ScrollSync>
			</IonContent>
		</IonPage>
	);
};

export default Standings;
