/* eslint-disable array-callback-return */
import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonContent,
	IonHeader,
	IonIcon,
	IonModal,
	IonSlide,
	IonSlides,
	IonTitle,
	IonToolbar,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../contexts/GlobalState";
import "../../css/ActivityDetails.css";
import "../../css/Home.css";
import ChallengeSummary from "../../pages/end_of_week_slides/ChallengeSummary";
import ChallengeSummaryFail from "../Challenges/ChallengeSummaryFail";
import ChallengeSummarySuccess from "../Challenges/ChallengeSummarySuccess";
import PersonalSummary from "../../pages/end_of_week_slides/PersonalSummary";
import SquadSummary from "../../pages/end_of_week_slides/SquadSummary";
import { getUserSquadIDs } from "../../services/SquadService";
import NewUserGoalComponent from "../NewUserGoalComponent";

const EndOfWeekSummaryComponent = ({ modalState, setModalState }) => {
	//const [modalState, setModalState] = useState(true);
	//const [loadingAllComponents, setLoadingAllComponents] = useState(true);
	const { challenges } = useContext(AuthContext);
	const [squadIDList, setSquadIDList] = useState();
	const [seasonJustEnded] = useState();

	useEffect(() => {
		loadEOW();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const loadEOW = async () => {
		// let didSeasonJustEnd = await didSeasonJustEndForUser();
		//didSeasonJustEnd = true;
		//console.log(`Did season just end? ${didSeasonJustEnd}`);
		// setSeasonJustEnded(didSeasonJustEnd);
		let squadIDList = await getUserSquadIDs();
		//await checkForCompetition();
		//console.log({ squadIDList });
		setSquadIDList(squadIDList);
	};

	const mySwiper = useRef(document.createElement("ion-slides"));

	const slideOpts = {
		initialSlide: 0,
		speed: 400,
		autoHeight: true,
		centeredSlides: true,
	};
	return (
		<React.Fragment>
			<IonModal isOpen={modalState} onDidDismiss={(e) => setModalState(false)}>
				<IonHeader>
					<IonToolbar>
						<IonTitle>End of Week Summary</IonTitle>
						<IonButton color="secondary" slot="end" onClick={() => setModalState(false)}>
							<IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
						</IonButton>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{seasonJustEnded !== null ? (
						<IonSlides pager={true} options={slideOpts} className="slides-container" style={{ height: "auto", minHeight: "100%" }} lock ref={mySwiper}>
							<IonSlide>
								<IonCard>
									<img src="assets/images/completed_2.jpg" alt="" />
									<IonCardHeader>
										<IonCardTitle>Your End of Week Summary</IonCardTitle>
									</IonCardHeader>
									<IonCardContent className="font-1-5">
										<div>Your week is now complete! See how you and your squad did by swiping right-to-left</div>
									</IonCardContent>
								</IonCard>
							</IonSlide>
							<IonSlide style={{ height: "auto" }}>
								<PersonalSummary />
							</IonSlide>
							<IonSlide>
								<NewUserGoalComponent swiper={mySwiper} />
							</IonSlide>
							{/*
							user &&
							user.seasonData &&
							user.seasonData[0] &&
							user.seasonData[0].weeklyResults.length > 0 &&
							user.seasonData[0].active === true &&
							seasonJustEnded === false ? (
								<IonSlide>
									<EndOfWeekSeasonSlide />
								</IonSlide>
							) : (
								""
							)
							*/}
							{/*
							seasonJustEnded === true ? (
								<IonSlide>
									<img src="https://fit-pet-prod-static-assets.s3.us-west-2.amazonaws.com/seasons/eos-screen.jpg" alt="" />
									<IonButton color="primary" onClick={() => showEndOfSeason()} style={{ position: "fixed", top: "60%", width: "60%" }}>
										How'd I do?
									</IonButton>
								</IonSlide>
							) : (
								""
							)
							*/}
							{challenges &&
								challenges.length > 0 &&
								challenges.map((challenge, index) => {
									if (challenge.status === 0 || challenge.status === 1 || challenge.status === 3) {
										// Only show if the challenge is starting soon, in progress or just completed
										if (challenge.status === 3 && parseInt(challenge.success) === 1) {
											// if recently completed and successful
											console.log("Showing Challenge Success");
											return (
												<IonSlide key={`${index}`} style={{ height: "auto" }}>
													<ChallengeSummarySuccess challengeObject={challenge} />
												</IonSlide>
											);
										} else if (challenge.status === 3 && challenge.success === 0) {
											// if recently completed and unsuccessful
											return (
												<IonSlide key={`${index}`} style={{ height: "auto" }}>
													<ChallengeSummaryFail challengeObject={challenge} />
												</IonSlide>
											);
										} else {
											// Otherwise show the standard summary
											return (
												<IonSlide key={`${index}`} style={{ height: "auto" }}>
													<ChallengeSummary challengeObject={challenge} />
												</IonSlide>
											);
										}
									}
								})}
							{squadIDList &&
								squadIDList.map((squadID, index) => {
									console.log("SQUADIDLIST: " + squadID);
									return (
										<IonSlide key={`${index}`} style={{ height: "auto" }}>
											<div>
												<SquadSummary squadID={squadID} />
											</div>
										</IonSlide>
									);
								})}
						</IonSlides>
					) : (
						""
					)}
				</IonContent>
			</IonModal>
		</React.Fragment>
	);
};

export default EndOfWeekSummaryComponent;
