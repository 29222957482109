import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonBackButton,
	IonCol,
	IonAvatar,
	IonRow,
	IonLoading,
	useIonViewDidEnter,
} from "@ionic/react";
import React, { useState } from "react";
import "../../css/Home.css";
import "../../css/ActivityDetails.css";
import { getActivityDetails } from "../../services/ActivitiesService";
import { useParams } from "react-router";
import moment from "moment";
import Emoji from "react-emoji-render";
import { getSquad, getSquadGoal, getUsersInSquad } from "../../services/SquadService";
import "../../css/CompetitionCompleted.css";

const SquadWeekCompleted = () => {
	const activityID = useParams().activityID;
	const [loading, setLoading] = useState(false);
	const [squadAGoalObject, setSquadAGoalObject] = useState();
	const [squadAObject, setSquadAObject] = useState();
	const [squadAMembers, setSquadAMembers] = useState();

	//let remainingSquadPoints;

	useIonViewDidEnter(async () => {
		await fetchActivityDetails();
	}, []);

	async function fetchActivityDetails() {
		//TODO: Put content into DB for randomizing
		//TODO: Show table of activities, broken by day to help user understand what happened that week
		//TODO: Show week over week performance
		//TODO: Show achievements, highlight key performances etc.
		//let user = await getUser();
		let activity = await JSON.parse(await getActivityDetails(activityID));
		console.log({ activity });
		loadSquadData(activity);
	}

	const loadSquadData = async (activityDetails) => {
		// Setup Squad A
		console.log({ activityDetails });
		setSquadAObject(await getSquad(activityDetails.activityData.squadId));
		let squadAGoal = await getSquadGoal(
			activityDetails.activityData.squadId,
			moment(activityDetails.activityData.startDate),
			moment(activityDetails.activityData.endDate),
			true,
			false
		);
		setLoading(true);

		//Setup Squad A Members
		let squadAMembers = await getUsersInSquad(
			activityDetails.activityData.squadId,
			true,
			false,
			moment(activityDetails.activityData.startDate),
			moment(activityDetails.activityData.endDate)
		);
		setSquadAMembers(squadAMembers);
		console.log("squad A Members: ", { squadAMembers });

		setSquadAGoalObject(squadAGoal);
		setLoading(false);
	};

	return (
		<IonPage>
			<IonContent>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/activityfeed" text="" color="light" />
						</IonButtons>
						<IonTitle>The Matchup</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonLoading isOpen={loading} onDidDismiss={() => setLoading(false)} message={"Loading some amazing people"} duration={10000} />
				<div id="top-part" className="competition-scoreboard-header">
					<div className="competition-squad-data-holder">
						<IonRow>
							<IonCol style={{ textAlign: "center" }} size="12">
								{squadAGoalObject && squadAGoalObject.goalProgress >= squadAGoalObject.goalPoints ? (
									<IonCol style={{ textAlign: "center", backgroundColor: "green", color: "while" }} size="12">
										<Emoji text=":raised_hands: Squad goal achieved! Streak increased! :raised_hands:" />
									</IonCol>
								) : (
									""
								)}
								{squadAGoalObject && squadAGoalObject.goalProgress < squadAGoalObject.goalPoints ? (
									<IonCol style={{ textAlign: "center" }} size="12">
										<Emoji text=":disappointed: Squad goal missed... :disappointed:" />
									</IonCol>
								) : (
									""
								)}
							</IonCol>
						</IonRow>
						<IonRow style={{ marginLeft: "20px" }}>
							{squadAObject ? (
								<IonCol className="squad-a-image-holder" size="12">
									<IonAvatar className="squad-image" style={{ margin: "auto", height: "200px", width: "200px" }}>
										<img src={squadAObject.image} alt="" />
									</IonAvatar>
									<span className="competition-squad-name">{squadAObject.name}</span>
									<div className="competition-squad-slogan">"{squadAObject.slogan}"</div>
								</IonCol>
							) : (
								""
							)}
						</IonRow>
					</div>
				</div>
				<div>
					<div id="numbers-part" className="competition-goal-holder">
						<IonRow style={{ marginLeft: "20px" }}>
							{squadAGoalObject ? (
								<IonCol className="" size="12">
									<span className="competition-goals">
										Goal <br />
									</span>
									{squadAGoalObject.goalPoints}
									<br />
									<br />
									<span className="competition-goals" style={{ backgroundColor: "yellow", color: "black" }}>
										Total <br />
										{squadAGoalObject.goalProgress}
									</span>
									<br />
								</IonCol>
							) : (
								""
							)}
						</IonRow>
					</div>
					<div className="competition-squad-team-holder" style={{ textAlign: "center" }}>
						{squadAMembers &&
							squadAMembers.map((member, i) => {
								let bkColor;
								if (Math.round((member.goalProgress / member.goalPoints) * 100) >= 100) {
									bkColor = "green";
								} else if (Math.round((member.goalProgress / member.goalPoints) * 100) >= 90) {
									bkColor = "yellow";
								} else if (Math.round((member.goalProgress / member.goalPoints) * 100) < 90) {
									bkColor = "red";
								}
								return (
									<IonCol className="squad-a-image-holder" size="12" style={{ backgroundColor: `${bkColor}`, color: "white" }}>
										<IonAvatar className="member-image" style={{ margin: "auto" }}>
											<img className="user-avatar" src={member.user.avatar} alt="" />
										</IonAvatar>
										<span className="competition-squad-name">{member.user.userName}</span>
										<br />
										<span className="competition-squad-name">
											{member.goalProgress}/{member.goalPoints}
											<br /> {Math.round((member.goalProgress / member.goalPoints) * 100)}%{" "}
										</span>
										<br />
									</IonCol>
								);
							})}
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default SquadWeekCompleted;
