import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonPage, IonProgressBar, IonToolbar, useIonViewDidEnter } from "@ionic/react";
import React from "react";
import "../../css/Home.css";
import "../../css/Onboarding.css";
import { StorageService } from "../../services/StorageService";
const storageService = new StorageService();

const Seasons = ({ progress, setProgress }) => {
	useIonViewDidEnter(() => {
		setProgress(0.2);
		storageService.setItem("lastOnboardingStep", 2);
	});

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar style={{ "--border-style": "none" }}>
					<IonButtons slot="start">
						<IonBackButton defaultHref="/entryscreen" text="" color="primary" />
					</IonButtons>
				</IonToolbar>
				<IonProgressBar value={progress} />
			</IonHeader>
			<IonContent>
				<div className="onboarding-main-div">
					<div className="onboarding-title">How it Works</div>
					<img className="onboarding-hero-image" src="assets/images/seasons.jpg" alt="" />

					<div className="onboarding-text-area" style={{ textAlign: "center" }}>
						Throughout the year FitSquad offers structured challenges you can participate in.<br />
						<br />
						During a challenge, you and a small team of friends, family or just like-minded strangers (your "squad") will set weekly goals for how active you'll be.
						<br />
						<br />
						<hr style={{ borderWidth: "1px" }} />
						Just for participating, your squad will receive <span className="bold">snazzy shirts</span> showcasing your commitment.
						<br />
						<br />
						<img src="assets/images/vertical-shirt-collage-2.jpeg" alt="" />
						<br />
						<br />
						<hr style={{ borderWidth: "1px" }} />
						If your squad hits their goal <span className="bold">8 out 10 weeks</span>, you and your squad will each earn{" "}
						<span className="bold">unique, custom medals.</span>
						<br />
						<br />
						<img src="assets/images/medals.jpg" alt="" />
						<br />
						<br />
						<hr style={{ borderWidth: "1px" }} />
						Plus, <span className="bold">10% of the proceeds</span> from each season will go to a <span className="bold">different charity.</span>
						<br />
						<br />
						<img src="assets/images/charity.jpg" alt="" />
						<br />
						<br />
						<hr style={{ borderWidth: "1px" }} />
						So by being <span className="bold">consistently active</span>, you'll not only earn <span className="bold">cool rewards for yourself</span>, you'll
						help <span className="bold">make the world a better place!</span>
						<br />
						<br />
						<hr style={{ borderWidth: "1px" }} />
						But don't worry if none of this is your thing - participation in a season is <span className="bold">always optional</span>. You can{" "}
						<span className="bold">use FitSquad for free</span> and feel the motivation to move from being part of a squad.
					</div>
				</div>
				<div className="onboarding-footer">
					<IonButton routerLink="/onboarding/register" className="onboarding-advance-button">
						Wow, very cool
					</IonButton>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default Seasons;
