import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonItem,
	IonList,
	IonRow,
	IonSpinner,
	useIonViewWillEnter,
} from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import ChallengeDetailsComponent from "./ChallengeDetailsComponent";
import ChallengeLedgerComponent from "./ChallengeLedgerComponent";
import ChallengeProgressComponent from "./ChallengeProgressComponent";
import "../../css/ActivityDetails.css";
import "../../css/Home.css";
import { getChallengeDetailsByChallengeID } from "../../services/ChallengeService";

const ChallengeSummaryFail = ({ challengeObject }) => {
	let width = window.innerWidth;
	const vidRef = useRef(null);
	return (
		<React.Fragment>
			{challengeObject ? (
				<React.Fragment>
					<IonCard>
						<IonCardHeader>
							<IonCardTitle>Challenge Completed!</IonCardTitle>
						</IonCardHeader>
						<video ref={vidRef} id="fail" preload="auto" autoPlay={false} muted controls loop style={{ width: "100%" }}>
							<source src="https://d3sveztjqii75x.cloudfront.net/challenges/1/fail.mp4" />
						</video>
					</IonCard>
				</React.Fragment>
			) : (
				<IonSpinner />
			)}
		</React.Fragment>
	);
};

export default ChallengeSummaryFail;
