import { IonHeader, IonTitle, IonToolbar, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonIcon } from "@ionic/react";
import React, { useEffect, useState } from "react";
import "../../css/Home.css";
import "../../css/ActivityDetails.css";
import { getActivityDetails } from "../../services/ActivitiesService";
import { getUserNameFromUserID } from "../../services/UserService";
import { getSystemActivityComponents } from "../../services/HelperServices";
import Emoji from "react-emoji-render";
import { logPageView } from "../../services/AnalyticsService";
import { closeOutline } from "ionicons/icons";

const SystemActivityDetailsComponent = ({ activityObject, showSystemActivityDetails }) => {
	const [activityDetails, setActivityDetails] = useState([]);
	const [buttons, setButtons] = useState([]);

	useEffect(() => {
		logPageView("system-activity-details");
		fetchActivityDetails(activityObject.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function fetchActivityDetails(activityID) {
		let activity = JSON.parse(await getActivityDetails(activityID));
		activity["userName"] = await getUserNameFromUserID(activity.userId);
		activity["components"] = getSystemActivityComponents(activity);
		console.log("Activity: " + JSON.stringify(activity["components"]["buttons"]));
		// /console.log("Activity components: " + activity["components"]);
		if (activity["components"]["buttons"] !== null && activity["components"]["buttons"] !== undefined) {
			console.log("Setting Buttons");
			setButtons(activity["components"]["buttons"]);
		}
		setActivityDetails([activity]);
	}

	return (
		<React.Fragment>
			<IonHeader>
				<IonToolbar className="activity-details-toolbar">
					<IonTitle>Activity Details</IonTitle>
					<IonButton color="tertiary" slot="end" onClick={() => showSystemActivityDetails({ visible: false, activityID: null, component: null })}>
						<IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
					</IonButton>
				</IonToolbar>
			</IonHeader>
			<IonCard>
				{activityDetails.map((activity, i) => {
					return (
						<div key={`${i}`}>
							<img src={activity.components.image} alt="" />
							<IonCardHeader>
								<IonCardTitle>{activity.components.title}</IonCardTitle>
							</IonCardHeader>
							<IonCardContent>
								<Emoji text={activity.components.body} />
							</IonCardContent>
						</div>
					);
				})}
				<IonCardContent>
					{buttons.map((button, i) => {
						return (
							<IonButton key={`${i}`} size="medium" routerLink={`/${button.action}`} routerDirection="back">
								{button.name}
							</IonButton>
						);
					})}
				</IonCardContent>
			</IonCard>
		</React.Fragment>
	);
};

export default SystemActivityDetailsComponent;
