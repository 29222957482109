import { IonAvatar, IonCol, IonGrid, IonRow, IonSkeletonText } from "@ionic/react";
import React from "react";
import "../../css/ChatContainer.css";

const SkeletonText = () => {
	return (
		<IonGrid className="message-container">
			<IonRow>
				<IonCol size="2">
					<IonAvatar>
						<IonSkeletonText animated />
					</IonAvatar>
				</IonCol>
				<IonCol size="10">
					<IonGrid>
						<IonRow>
							<IonCol size="12">
								<IonSkeletonText animated />
								<span className="date">
									<IonSkeletonText animated />
								</span>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size="12">
								<IonSkeletonText animated />
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonCol>
			</IonRow>
			<IonRow>
				<IonCol size="2">
					<IonAvatar>
						<IonSkeletonText animated />
					</IonAvatar>
				</IonCol>
				<IonCol size="10">
					<IonGrid>
						<IonRow>
							<IonCol size="12">
								<IonSkeletonText animated />
								<span className="date">
									<IonSkeletonText animated />
								</span>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size="12">
								<IonSkeletonText animated />
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonCol>
			</IonRow>
			<IonRow>
				<IonCol size="2">
					<IonAvatar>
						<IonSkeletonText animated />
					</IonAvatar>
				</IonCol>
				<IonCol size="10">
					<IonGrid>
						<IonRow>
							<IonCol size="12">
								<IonSkeletonText animated />
								<span className="date">
									<IonSkeletonText animated />
								</span>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size="12">
								<IonSkeletonText animated />
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonCol>
			</IonRow>
			<IonRow>
				<IonCol size="2">
					<IonAvatar>
						<IonSkeletonText animated />
					</IonAvatar>
				</IonCol>
				<IonCol size="10">
					<IonGrid>
						<IonRow>
							<IonCol size="12">
								<IonSkeletonText animated />
								<span className="date">
									<IonSkeletonText animated />
								</span>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size="12">
								<IonSkeletonText animated />
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonCol>
			</IonRow>
			<IonRow>
				<IonCol size="2">
					<IonAvatar>
						<IonSkeletonText animated />
					</IonAvatar>
				</IonCol>
				<IonCol size="10">
					<IonGrid>
						<IonRow>
							<IonCol size="12">
								<IonSkeletonText animated />
								<span className="date">
									<IonSkeletonText animated />
								</span>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size="12">
								<IonSkeletonText animated />
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};
export default SkeletonText;
