import React, { useEffect } from "react";

import { useHistory, useParams } from "react-router";
import { getPlatforms, IonPage } from "@ionic/react";

import { Plugins } from "@capacitor/core";
import { getChatRooms } from "../services/ChatService";
import { initAnalytics } from "../services/AnalyticsService";

const LoadingPage = () => {
	const { LocalNotifications, PushNotifications } = Plugins;
	const page = useParams().page;
	const history = useHistory();

	useEffect(() => {
		console.log("Hit loading page");
		initAnalytics();
		//sendImmediateNotification("listeners setup")
		LocalNotifications.removeAllListeners();
		PushNotifications.removeAllListeners();
		LocalNotifications.cancel({
			notifications: [
				{
					id: 1,
				},
			],
		}).catch((error) => {
			throw error(error);
		});
		LocalNotifications.addListener("localNotificationActionPerformed", async (notification) => {
			//await sendImmediateNotification("action performed: Payload - "+JSON.stringify(notification))
			//showLog("Result from localNotificationActionPerformed: " + JSON.stringify(notification));
			history.push("/" + notification.notification.extra.destination);
		});
		LocalNotifications.addListener("localNotificationReceived", async (notification) => {
			//sendImmediateNotification("notification received")
			console.log("Result from localNotificationActionReceived" + JSON.stringify(notification));
		});

		// Show us the notification payload if the app is open on our device
		PushNotifications.addListener("pushNotificationReceived", async (notification) => {
			//await sendImmediateNotification("Result from pushNotificationReceived:" + JSON.stringify(notification.data.aps.alert.payload.chatRoom))
		});

		// Method called when tapping on a notification
		PushNotifications.addListener("pushNotificationActionPerformed", async (notification) => {
			const platformsArray = getPlatforms();
			console.log("Notification Payload: " + JSON.stringify(notification));
			let destination = "/home";
			platformsArray.forEach(async (platform) => {
				if (platform === "ios") {
					if (notification.notification.data.aps.alert.payload.chatRoom) {
						destination = "/chatroom/" + notification.notification.data.aps.alert.payload.chatRoom;
					} else if (notification.notification.data.aps.alert.payload.squadId) {
						let squadID = notification.notification.data.aps.alert.payload.squadId;
						//showLog("\nGetting chat room")
						//showLog("\nLooking for chat room for squadID "+squadID)
						let chatRooms = await getChatRooms(false);
						let chatRoomID = null;
						for (let roomData of chatRooms) {
							//showLog("\n\nchecking roomData: "+JSON.stringify(roomData))
							if (roomData.squadIdA === squadID && roomData.squadIdB !== null && chatRoomID === null) {
								//showLog("\nFound the competition one in A: "+roomData.id)
								destination = "/chatroom/" + roomData.id;
							} else if (roomData.squadIdA === squadID && chatRoomID === null) {
								//showLog("\nFound the solo one in A: "+roomData.id)
								destination = "/chatroom/" + roomData.id;
							} else if (roomData.squadIdB === squadID && roomData.squadIdA !== null && chatRoomID === null) {
								//showLog("\nFound the competition one in B: "+roomData.id)
								destination = "/chatroom/" + roomData.id;
							} else if (roomData.squadIdB === squadID && chatRoomID === null) {
								//showLog("\nFound the solo one in B: "+roomData.id)
								destination = "/chatroom/" + roomData.id;
							}
						}
					}
					console.log("Destination set to: " + destination);
					history.push(destination);
				}
				if (platform === "android") {
					console.log("Platform is Android");
					if (notification.notification.data.payload) {
						console.log("Found Payload");
						let payload = JSON.parse(notification.notification.data.payload);
						if (payload.chatRoom) {
							console.log("Chatroom found in payload - going to chatroom");
							destination = "/chatroom/" + payload.chatRoom;
						} else if (payload.squadId) {
							console.log("squadId found in payload - going to figuring our which chatroom");
							let squadID = payload.squadId;
							showLog("\nGetting chat room");
							showLog("\nLooking for chat room for squadID " + squadID);
							let chatRooms = await getChatRooms(false);
							let chatRoomID = null;
							for (let roomData of chatRooms) {
								showLog("\n\nchecking roomData: " + JSON.stringify(roomData));
								if (roomData.squadIdA === squadID && roomData.squadIdB !== null && chatRoomID === null) {
									showLog("\nFound the competition one in A. Setting Destination to: " + roomData.id);
									destination = "/chatroom/" + roomData.id;
								} else if (roomData.squadIdA === squadID && chatRoomID === null) {
									showLog("\nFound the solo one in A: " + roomData.id);
									destination = "/chatroom/" + roomData.id;
								} else if (roomData.squadIdB === squadID && roomData.squadIdA !== null && chatRoomID === null) {
									showLog("\nFound the competition one in B: " + roomData.id);
									destination = "/chatroom/" + roomData.id;
								} else if (roomData.squadIdB === squadID && chatRoomID === null) {
									showLog("\nFound the solo one in B: " + roomData.id);
									destination = "/chatroom/" + roomData.id;
								}
							}
						}
					}
					console.log("Destination set to: " + destination);
					history.push(destination);
				}
			});
		});
		console.log("localNotificationActionPerformed & localNotificationActionReceived listener added");
		console.log("Page passed: " + page);
		const url = page.split(":");
		console.log("After split: ", { url });
		if (url[1]) {
			history.push("/" + url[0] + "/" + url[1]);
		} else {
			history.push("/" + page);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const showLog = (body) => {
		console.log(body);
	};
	/*
    const sendImmediateNotification = async (body = "Sent after login") => {		
		LocalNotifications.schedule({
			notifications: [
				{
					title: "IMMEDIATE",
					body: body,
					id: 100,
					schedule: {
						on: new Date(Date.now() + 30000),
					},
					extra: {
						destination: "dailyUpdate",
					},
				},
			],
		});
    };
    */

	return <IonPage></IonPage>;
};

export default LoadingPage;
