/* eslint-disable jsx-a11y/accessible-emoji */
import { IonHeader, IonTitle, IonToolbar, IonButton, IonIcon, IonTextarea } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import Rating from "react-rating";
import "../../css/Home.css";
import "../../css/ActivityDetails.css";
import { sendSlackNotification } from "../../services/NotificationService";
import { getUserNameFromUserID } from "../../services/UserService";

const FeedbackComponent = ({ setFeedbackPopover }) => {
	const feedbackBody = useRef(null);
	const [rating, setRating] = useState(0);
	const [feedbackSent, setFeedbackSent] = useState(false);

	const sendFeedback = async () => {
		let userName = await getUserNameFromUserID();
		// let text = "*Username:* " + userName + "\n*Rating:* " + rating + "\n*Feedback:* " + feedbackBody.current.value;
		let text = "*Username:* " + userName + "\n*Rating:* " + rating;

		sendSlackNotification(text, "feedback");
		setFeedbackSent(true);
	};

	return (
		<React.Fragment>
			<IonHeader>
				<IonToolbar className="activity-details-toolbar">
					<IonTitle>🙏 Feedback Needed 🙏</IonTitle>
					<IonButton color="tertiary" slot="end" onClick={() => setFeedbackPopover(false)}>
						<IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
					</IonButton>
				</IonToolbar>
			</IonHeader>
			<div style={{ margin: "5%" }}>
				{feedbackSent === false ? (
					<React.Fragment>
						<div style={{ textAlign: "center" }}>
							<h2>We'd love to know what you think of FitSquad!</h2>
						</div>
						<div style={{ width: "100%", textAlign: "center", marginTop: "10%" }}>How would you rate the app?</div>
						<div style={{ width: "100%", textAlign: "center", marginTop: "10%" }}>
							<Rating
								emptySymbol={<img src="assets/images/star-empty.png" style={{ width: "50px" }} alt="" />}
								fullSymbol={<img src="assets/images/star-full.png" style={{ width: "50px" }} alt="" />}
								onClick={(rating) => setRating(rating)}
							/>
						</div>
						{/* 
						<div style={{ width: "100%", marginTop: "10%", lineHeight: "200%" }}>
							Any other feedback to share? <br />
							What do you like?
							<br /> What could we do better?
							<br /> What you share below will go directly to the founders and not be made public.
						</div>
						<IonTextarea
							autoGrow={true}
							style={{ border: "1px darkgray solid", borderRadius: "10px", paddingLeft: "10px", paddingRight: "10px" }}
							ref={feedbackBody}
							name="userID"
							type={"text"}
						></IonTextarea> */}
						<IonButton
							onClick={() => sendFeedback()}
							size="large"
							style={{ marginLeft: "25%", width: "50%", marginTop: "5%", marginBottom: "5%", fontSize: "1em", textTransform: "none" }}
						>
							Send feedback
						</IonButton>
					</React.Fragment>
				) : (
					<React.Fragment>
						<div style={{ width: "100%", textAlign: "center", marginTop: "10%" }}>
							<img src="https://media.giphy.com/media/xT9IghxMpphZhrp2XC/giphy.gif" alt="" />
						</div>
						<div style={{ width: "100%", textAlign: "center", marginTop: "10%" }}>We genuinely appreciate your feedback!</div>
						<div style={{ width: "100%", textAlign: "center", marginTop: "10%" }}>
							For more updates and connecting with other squaddies, consider joining our Facebook group: <br />
							<a href="https://www.facebook.com/groups/fitsquadapp">facebook.com/groups/fitsquadapp</a>
						</div>
					</React.Fragment>
				)}
			</div>
		</React.Fragment>
	);
};

export default FeedbackComponent;
