/* eslint-disable no-restricted-globals */
import { IonPage, IonRouterOutlet, useIonViewDidEnter, useIonViewWillEnter } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import moment from "moment";
import React, { useContext, useState } from "react";
import { Redirect, Route, useParams } from "react-router";
import ConnectTracker from "../components/Onboarding/ConnectTracker";
import FitbitConfirmation from "../components/Onboarding/FitbitConfirmation";
import GoalsExplanation from "../components/Onboarding/GoalExplanationSolo";
import Greeting from "../components/Onboarding/Greeting";
import HealthKitConfirmation from "../components/Onboarding/HealthKitConfirmation";
import InitialBonus from "../components/Onboarding/InitialBonus";
import Intro from "../components/Onboarding/Intro";
import LogFirstWorkout from "../components/Onboarding/LogFirstWorkout";
import NotificationsPermission from "../components/Onboarding/NotificationsPermission";
import PointsConfirmation from "../components/Onboarding/PointsConfirmation";
import PointsExplanation from "../components/Onboarding/PointsExplanation";
import Register from "../components/Onboarding/Register";
import Seasons from "../components/Onboarding/Seasons";
import SetGoal from "../components/Onboarding/SetGoal";
import SquadSelection from "../components/Onboarding/SquadSelection";
import "../css/Home.css";
import ChatInterface from "./ChatInterface";
import Home from "./Home";
import SquadCreate from "./SquadCreate";
import SquadCreateComplete from "./SquadCreateComplete";
import SquadJoin from "../components/JoinSquad/JoinSquadPage";
import AuthContext from "../contexts/GlobalState";
import { StorageService } from "../services/StorageService";
import { getGoal } from "../services/PointsAndGoalsService";
import { getUser } from "../services/UserService";
import EntryScreen from "./EntryScreen";
import LoginScreen from "./LoginScreen";
import GoalsExplanationSolo from "../components/Onboarding/GoalExplanationSolo";
import GoalsExplanationSquad from "../components/Onboarding/GoalExplanationSquad";
import SaveProgress from "../components/Onboarding/SaveYourProgess";
import SavePhoto from "../components/Onboarding/SaveYourPhoto";
import RandomSquadExplanation from "../components/Onboarding/RandomSquadExplanation";
import ChatRoom from "../components/ChatRoom/ChatRoom";
import ChallengeDetails from "../components/Challenges/ChallengeDetails";
import ChallengesSignup from "../components/Onboarding/ChallengesSignup";
import Question from "../components/Onboarding/Question";
import TriggerNotification from "../components/Onboarding/TriggerNotification";
import EndOnboarding from "../components/Onboarding/EndOnboarding";
import SquadHome from "../components/SquadHome/SquadHome";

const OnboardingContainer = () => {
	// Trying it this way to maintain state between pages vs passing props or using local storage
	const { onboardingCompleted } = useContext(AuthContext);
	const storageService = new StorageService();
	const pageID = useParams().pageID;
	const [activity, setActivity] = useState();
	const [healthKitData, setHealthKitData] = useState({
		avgWeeklyTime: 0,
		avgWeeklyCalories: 0,
		avgWeeklySteps: 0,
		avgWeeklyPoints: 0,
		anyData: false,
	});
	const [progress, setProgress] = useState(0);
	const [goalObject, setGoalObject] = useState();
	const [squadToJoin, setSquadToJoin] = useState();
	const [redirectLink, setRedirectLink] = useState();

	useIonViewWillEnter(async () => {
		console.log("In container");
		console.log(`onboardingCompleted: ${onboardingCompleted}`);
		if (onboardingCompleted === false) {
			let lastOnboardingStep = await storageService.getItem("lastOnboardingStep");

			switch (parseFloat(lastOnboardingStep)) {
				case 1:
					setProgress(0.1);
					setRedirectLink("/onboarding/intro");
					break;
				case 2:
					setProgress(0.2);
					setRedirectLink("/onboarding/register");
					break;
				case 3:
					setProgress(0.3);
					setRedirectLink("/onboarding/greeting");
					break;
				case 4:
					setProgress(0.4);
					setRedirectLink("/onboarding/question");
					break;
				case 5:
					setProgress(0.5);
					setRedirectLink("/onboarding/pointsexplanation");
					break;
				case 6:
					setProgress(0.5);
					setRedirectLink("/onboarding/goalexplanationsolo");
					break;
				case 6.2:
					setProgress(0.53);
					setRedirectLink("/onboarding/randomsquadexplanation");
					break;
				case 6.1:
					setProgress(0.57);
					setRedirectLink("/onboarding/goalexplanationsquad");
					break;
				case 7:
					setProgress(0.6);
					setRedirectLink("/onboarding/logfirstworkout");
					break;
				case 8:
					setProgress(0.6);
					setRedirectLink("/onboarding/notificationspermission");
					break;
				case 9:
					setProgress(0.7);
					setRedirectLink("/onboarding/connecttracker");
					break;
				case 10:
					setProgress(0.7);
					setRedirectLink("/onboarding/fitbitconfirmation");
					break;
				case 11:
					setProgress(0.7);
					setRedirectLink("/onboarding/healthkitconfirmation");
					break;
				case 12:
					setProgress(0.75);
					setRedirectLink("/onboarding/setgoal");
					break;
				case 13:
					setProgress(0.8);
					setRedirectLink("/onboarding/initialbonus");
					break;
				case 14:
					setProgress(0.85);
					setRedirectLink("/onboarding/saveyourphoto");
					break;
				case 16:
					setProgress(0.95);
					setGoalObject(await getGoal());
					setRedirectLink("/onboarding/saveprogress");
					break;
				case 17:
					setProgress(1.0);
					setRedirectLink("/onboarding/challengesignup");
					break;
				case 18:
					setProgress(1.0);
					setGoalObject(await getGoal());
					setRedirectLink("/onboarding/squadselection");
					break;
				case 19:
					setProgress(1.0);
					setGoalObject(await getGoal());
					setRedirectLink("/onboarding/endonboarding");
					break;
				default:
					console.log("case default");
					setRedirectLink("/entryscreen");
					break;
			}
		}
		if (onboardingCompleted === undefined) {
			setRedirectLink("/onboarding/intro");
		}
	});

	return (
		<IonPage>
			{redirectLink ? (
				<IonReactRouter>
					<IonRouterOutlet>
						<Route path="/onboarding/intro" exact>
							<Intro progress={progress} setProgress={setProgress} />
						</Route>
						<Route path="/onboarding/register" exact>
							<Register progress={progress} setProgress={setProgress} setSquadToJoin={setSquadToJoin} />
						</Route>
						<Route path="/onboarding/question" exact>
							<Question progress={progress} setProgress={setProgress} />
						</Route>
						<Route path="/onboarding/greeting" exact>
							<Greeting progress={progress} setProgress={setProgress} squadToJoin={squadToJoin} />
						</Route>
						<Route path="/onboarding/pointsexplanation" exact>
							<PointsExplanation progress={progress} setProgress={setProgress} />
						</Route>
						<Route path="/onboarding/goalexplanationsolo" exact>
							<GoalsExplanationSolo progress={progress} setProgress={setProgress} />
						</Route>
						<Route path="/onboarding/goalexplanationsquad" exact>
							<GoalsExplanationSquad progress={progress} setProgress={setProgress} />
						</Route>
						<Route path="/onboarding/randomsquadexplanation" exact>
							<RandomSquadExplanation progress={progress} setProgress={setProgress} />
						</Route>
						<Route path="/onboarding/logfirstworkout" exact>
							<LogFirstWorkout progress={progress} setProgress={setProgress} setActivity={setActivity} />
						</Route>
						<Route path="/onboarding/pointsconfirmation" exact>
							<PointsConfirmation progress={progress} setProgress={setProgress} activity={activity} />
						</Route>
						<Route path="/onboarding/connecttracker" exact>
							<ConnectTracker progress={progress} setProgress={setProgress} />
						</Route>
						<Route path="/onboarding/healthkitconfirmation" exact>
							<HealthKitConfirmation progress={progress} setProgress={setProgress} healthKitData={healthKitData} setHealthKitData={setHealthKitData} />
						</Route>
						<Route path="/onboarding/setgoal" exact>
							<SetGoal progress={progress} setProgress={setProgress} healthKitData={healthKitData} setGoalObject={setGoalObject} squadToJoin={squadToJoin} />
						</Route>
						<Route path="/onboarding/initialbonus" exact>
							<InitialBonus progress={progress} setProgress={setProgress} activity={activity} goalObject={goalObject} />
						</Route>
						<Route path="/onboarding/notificationspermission" exact>
							<NotificationsPermission progress={progress} setProgress={setProgress} goalObject={goalObject} />
						</Route>
						<Route path="/onboarding/triggernotification" exact>
							<TriggerNotification progress={progress} setProgress={setProgress} activity={activity} />
						</Route>
						<Route path="/onboarding/squadselection" exact>
							<SquadSelection progress={progress} setProgress={setProgress} goalObject={goalObject} setSquadToJoin={setSquadToJoin} squadToJoin={squadToJoin} />
						</Route>
						<Route path="/onboarding/saveyourprogress" exact>
							<SaveProgress progress={progress} setProgress={setProgress} goalObject={goalObject} squadToJoin={squadToJoin} />
						</Route>
						<Route path="/onboarding/savephoto" exact>
							<SavePhoto progress={progress} setProgress={setProgress} goalObject={goalObject} squadToJoin={squadToJoin} />
						</Route>
						<Route path="/onboarding/challengesignup" exact>
							<ChallengesSignup progress={progress} setProgress={setProgress} goalObject={goalObject} squadToJoin={squadToJoin} />
						</Route>
						<Route path="/onboarding/endonboarding" exact>
							<EndOnboarding progress={progress} setProgress={setProgress} squadToJoin={squadToJoin} />
						</Route>
						<Route path="/home" exact>
							<Home />
						</Route>
						<Route path="/squadcreate" exact>
							<SquadCreate onboarding={true} />
						</Route>
						<Route path="/squadjoin" exact>
							<SquadJoin onboarding={true} />
						</Route>
						<Route path="/squadhome/:id" key={location.pathname}>
							<SquadHome />
						</Route>
						<Route path="/chatinterface" exact>
							<ChatInterface />
						</Route>
						<Route path="/squadcreatecomplete" exact>
							<SquadCreateComplete onboarding={true} />
						</Route>
						<Route path="/chatroom/:roomID" key={location.pathname}>
							<ChatRoom />
						</Route>
						<Route path="/challenge/:id" key={location.pathname}>
							<ChallengeDetails />
						</Route>
						<Route path="/squadcreatecompleteonboarding/:squadID">
							<SquadCreateComplete onboarding={true} />
						</Route>
						<Route path="/entryscreen" exact>
							<EntryScreen />
						</Route>
						<Route path="/login" exact>
							<LoginScreen />
						</Route>
						<Route path="/onboarding/fitbitconfirmation" exact>
							<FitbitConfirmation
								progress={progress}
								setProgress={setProgress}
								goalObject={goalObject}
								healthKitData={healthKitData}
								setHealthKitData={setHealthKitData}
							/>
						</Route>

						<React.Fragment>
							<Redirect path="/onboarding" to={redirectLink} exact />
						</React.Fragment>
					</IonRouterOutlet>
				</IonReactRouter>
			) : (
				""
			)}
		</IonPage>
	);
};

export default OnboardingContainer;
