import { IonButton, IonContent, IonHeader, IonIcon, IonToolbar } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import "../../css/Home.css";
import "../../css/Inventory.css";
//import bagData from "./bagExplode.data.json";
import { getRandomMysteryBagItems, getSpecificMysteryBagItem } from "../../services/InventoryService";
import BagExplode from "./BagExplode";
var _ = require("lodash");

const MysteryBag = ({ showBagModal, setShowBagModal }) => {
	const [bagData, setBagData] = useState(null);
	const [winner, setWinner] = useState(null);
	const [itemDescription, setItemDescription] = useState(null);
	const [generalDescription, setGeneralDescription] = useState(null);
	const [showCompletion, setShowCompletion] = useState(false);

	useEffect(() => {
		getBagData();
	}, []);

	const getBagData = async () => {
		// get a random # of items, not including the winner
		let tempBagData = await getRandomMysteryBagItems(showBagModal.itemDetails.id);
		// get the winner
		let winner = await getSpecificMysteryBagItem(showBagModal.itemDetails.id);
		// add the winner
		tempBagData.push(winner);
		//shuffle it
		tempBagData = _.shuffle(tempBagData);
		//find the winner
		let winnerID = tempBagData.findIndex((item) => item.inventoryItemId === showBagModal.itemDetails.id);
		//pass THAT id to the bag
		setWinner(winnerID);
		setItemDescription(tempBagData[winnerID].description);
		determineGeneralDescription(showBagModal.itemDetails);
		setBagData(tempBagData);
	};

	const determineGeneralDescription = async (item) => {
		console.log({ item });
		if (item.actions?.personalForgiveness) {
			setGeneralDescription("This will appear in your inventory and be used automatically whenever you miss a weekly goal");
		}
		if (item.actions?.onSeasonRegistration) {
			setGeneralDescription("This will appear in your inventory and be used automatically when you sign up for your next season");
		}
		if (item.actions?.bonusPoints) {
			setGeneralDescription("This will appear in your inventory and can be used whenever you need some points to hit your goal!");
		}
		if (item.actions?.collectAddress || item.actions?.collectEmail) {
			setGeneralDescription("This will appear in your inventory. Tap the redeem button to confirm your address so we can get it over to you!");
		}
	};

	return (
		<React.Fragment>
			<div>{bagData !== null && winner !== null ? <BagExplode data={bagData} winner={winner} setShowCompletion={setShowCompletion} /> : ""}</div>
			<div className="bag-winner-description">
				{showCompletion === true ? (
					<React.Fragment>
						{itemDescription !== null ? (
							<div className="description-div">
								<div className="item-description">{itemDescription}</div>
								<div className="general-description">{generalDescription}</div>
								<div className="bag-close-button">
									<IonButton onClick={() => setShowBagModal({ visible: false })}>Awesome!</IonButton>
								</div>
							</div>
						) : (
							""
						)}
					</React.Fragment>
				) : (
					""
				)}
			</div>
		</React.Fragment>
	);
};

export default MysteryBag;
