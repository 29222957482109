import {
	IonButton,
	IonCol,
	IonContent,
	IonDatetime,
	IonGrid,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonLoading,
	IonPopover,
	IonRow,
	IonSegment,
	IonSegmentButton,
	IonTitle,
	IonToolbar,
	IonModal,
	IonSelect,
	IonSelectOption,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import Emoji from "react-emoji-render";
import "../../css/AddActivityComponent.css";
import "../../css/Home.css";
import { logActivityPlaceholderText } from "../../models/constants";
import { addActivity, getMostCommonActivities } from "../../services/ActivitiesService";
import { getRandomGifFromSearch } from "../../services/GifService";
import { getRandomValueFromArray } from "../../services/HelperServices";
import { getActivitySystemMessage } from "../../services/MessageServices";
import { getGoal } from "../../services/PointsAndGoalsService";
import AuthContext from "../../contexts/GlobalState";

const BotAddActivityComponent = ({ mostFrequentActivities, modalState, setModalState, showModal, refreshAllData, setActivityData }) => {
	const { updateGoalState } = useContext(AuthContext);
	//const [modalState, setModalState] = useState(true);
	const [adding, setAdding] = useState(false);
	const [showFrequent, setShowFrequent] = useState(false);
	const [gifMessage, setGifMessage] = useState({
		visible: false,
		message: null,
		imageURL: null,
	});
	const [activityText, setActivityText] = useState("");
	const [stepsText, setStepsText] = useState("");
	const [activityTime, setActivityTime] = useState("");
	const [activityDate, setActivityDate] = useState("");
	const [stepsDate, setStepsDate] = useState("");
	const [activityIntensity, setActivityIntensity] = useState("");
	const [placeholderText] = useState(getRandomValueFromArray(logActivityPlaceholderText));
	const [minutes, setMinutes] = useState(0);
	const [hours, setHours] = useState(0);
	const [steps, setSteps] = useState(0);
	const [totalTime, setTotalTime] = useState(0);
	const [intensity, setIntensity] = useState("normal");
	const [activityType, setActivityType] = useState("activity");
	const [timeUnit, setTimeUnit] = useState("minutes");
	const [done, setDone] = useState(false);
	const [selectedDate, setSelectedDate] = useState(moment().format("MM/DD/YYYY"));
	const [activityText2, setActivityText2] = useState(getRandomValueFromArray(logActivityPlaceholderText));
	const activity = useRef(null);
	//const maxDate = "2020-11-20";

	//var muscleSize = "30px";
	//var backgroundColor = "background-blue";
	//const minutes = useRef(30);
	//const hours = useRef(0);

	const normalIntensityMultiplier = 4;
	const moderateIntensityMultiplier = 7;
	const highIntensityMultiplier = 11;

	useEffect(() => {
		//console.log({ mostFrequentActivities });
		if (done === false && activityText !== "") {
			updateActivityText();
		}
	});

	const updateActivityText = (e = null) => {
		let at2 = activityText2;
		if (at2) {
			// do this because when tapping the toggle it trip ionChange 🤷🏾‍♂️
			let text;
			//console.log("Activity: ", { activity });
			if (moment(selectedDate).format("MM/DD/YYYY") !== moment().format("MM/DD/YYYY")) {
				setActivityDate("On <b>" + moment(selectedDate).format("MM/DD/YYYY") + "</b>");
			} else {
				setActivityDate("");
			}
			text = "Your activity was <b>" + at2 + "</b>";
			let localMinutes = minutes;
			let localHours = hours;

			if (localMinutes === null || localMinutes === undefined || localMinutes === "") {
				localMinutes = 0;
			}
			if (localHours === null || localHours === undefined || localHours === "") {
				localHours = 0;
			}
			let time = parseInt(localHours) * 60 + parseInt(localMinutes);
			setTotalTime(time);
			let timeText = "you did it for <b>" + time + " minutes </b>";
			let intensityText = "and you did it at <b>" + intensity + "</b> intensity?";

			setActivityText(text);
			setActivityTime(timeText);
			setActivityIntensity(intensityText);
		} else {
			setActivityText(null);
		}
		//}
	};

	const updateStepsText = (stepsUpdated = null, date = null) => {
		if (date !== null) {
			setSelectedDate(date);
		} else {
			date = selectedDate;
		}
		if (stepsUpdated !== null) {
			setSteps(stepsUpdated);
		} else {
			stepsUpdated = steps;
		}
		if (stepsUpdated) {
			if (moment(date).format("MM/DD/YYYY") !== moment().format("MM/DD/YYYY")) {
				setStepsText("On <b>" + moment(date).format("MM/DD/YYYY") + "</b> you took <b>" + stepsUpdated.toLocaleString() + " steps?</b>");
			} else {
				setStepsText("<b>Today</b>, you took <b>" + stepsUpdated.toLocaleString() + " steps?</b>");
			}
		} else {
			setStepsText(null);
		}
	};

	const updateIntensity = (value) => {
		setIntensity(value);
	};

	const updateActivityType = (value) => {
		/* Not sure why this is needed
		if (value === "steps") {
			activity.current.value = "steps";
		}
		*/
		setActivityType(value);
	};

	const addActivityConfirm = async () => {
		console.log("Totaltime: " + totalTime);
		let calories, points;
		switch (intensity) {
			case "normal":
				calories = totalTime * normalIntensityMultiplier;
				points = totalTime * 1;
				break;
			case "moderate":
				calories = totalTime * moderateIntensityMultiplier;
				points = totalTime * 1.5;
				break;
			case "high":
				calories = totalTime * highIntensityMultiplier;
				points = totalTime * 2;
				break;
			default:
				break;
		}
		let currentDate = moment().format("MM/DD/YYYY");
		let selectDateAndTime;
		if (selectedDate === currentDate) {
			selectDateAndTime = moment(selectedDate).add(moment().format("H"), "hours").add(moment().format("m"), "minutes").format();
		} else {
			selectDateAndTime = moment(selectedDate).set("hour", 12).set("minute", 0).format();
		}
		console.log("Selected day & time: ", selectDateAndTime);
		let activityData = {
			activity: activityText2,
			duration: totalTime,
			unit: "minutes",
			calories: calories,
			date: selectDateAndTime,
			dataType: "workout",
		};
		activityData.imageURL = await getRandomGifFromSearch(activityData.activity, 50, "pg-13", false).catch((error) => {
			console.log("Error getting GIF: " + error);
			activityData.imageURL =
				"https://www.temptationgifts.com/media/catalog/product/cache/c09c62f4d7c7812f632d8af59eec3417/p/i/pigment-happy-jackson-yay-you-totally-did-it-greeting-card-gf831b_1.jpg";
		});
		let response = addActivity(activityData).catch((error) => {
			alert("Error: ", { error });
			return;
		});
		//console.log("Log activity response: ", { response });
		updateGoalState();
		//console.log({ congratsMessage });
		setDone(true);
		activityData.points = points;
		activityData.intensity = intensity;
		setActivityData(activityData);
		closeModal();
	};

	const addStepsConfirm = async () => {
		setAdding(true);
		let calories, points;
		let currentDate = moment().format("MM/DD/YYYY");
		let selectDateAndTime;
		if (selectedDate === currentDate) {
			selectDateAndTime = moment(selectedDate).add(moment().format("H"), "hours").add(moment().format("m"), "minutes").format();
		} else {
			selectDateAndTime = moment(selectedDate).set("hour", 12).set("minute", 0).format();
		}
		let stepsPerMinute = (2000 * 3) / 60; // steps per mile x 3mph / 60 mins
		let stepsTime = Math.ceil(steps / stepsPerMinute);
		points = stepsTime;
		calories = stepsTime * normalIntensityMultiplier;
		let activityData = {
			activity: "steps",
			duration: steps,
			unit: "steps",
			calories: calories,
			date: selectDateAndTime,
			dataType: "steps",
		};
		activityData.imageURL = await getRandomGifFromSearch("walking", 50, "pg-13", false).catch((error) => {
			console.log("Error getting GIF: " + error);
			activityData.imageURL =
				"https://www.temptationgifts.com/media/catalog/product/cache/c09c62f4d7c7812f632d8af59eec3417/p/i/pigment-happy-jackson-yay-you-totally-did-it-greeting-card-gf831b_1.jpg";
		});
		let response = await addActivity(activityData).catch((error) => {
			alert("Error: ", { error });
			return;
		});
		//console.log("Log activity response: ", { response });
		//console.log(response.activityPoints);
		updateGoalState();
		//console.log({ congratsMessage });
		setDone(true);
		activityData.points = response.activityPoints;
		activityData.intensity = "low";
		setActivityData(activityData);
		closeModal();
	};

	const resetActivity = () => {
		setActivityText("");
		setHours(0);
		setMinutes(30);
		setActivityTime("");
		setIntensity("normal");
		setActivityIntensity("");
		setActivityDate("");
		setStepsText("");
		setStepsDate("");
		setDone(false);
	};

	const closeModal = () => {
		resetActivity();
		setModalState(false);
	};

	return (
		<React.Fragment>
			<IonGrid>
				<IonRow>
					<IonCol size="3"></IonCol>
					<IonCol size="6">
						<IonSegment value={activityType} onIonChange={(e) => updateActivityType(e.detail.value)} className="activity-segment-controller" mode="ios">
							<IonSegmentButton value="activity" className="activity-segment-button" mode="ios">
								<IonLabel style={{ marginTop: "0px", marginBottom: "0px" }}>Activity</IonLabel>
							</IonSegmentButton>
							<IonSegmentButton value="steps" className="activity-segment-button" mode="ios">
								<IonLabel style={{ marginTop: "0px", marginBottom: "0px" }}>Steps</IonLabel>
							</IonSegmentButton>
						</IonSegment>
					</IonCol>
					<IonCol size="3"></IonCol>
				</IonRow>
				{activityType === "activity" ? (
					<React.Fragment>
						<IonItem className="activity-item" mode="ios">
							<IonLabel className="field-label" position="stacked">
								What was your activity?
							</IonLabel>
							<IonInput
								classname="activity-field"
								type="text"
								value={activityText2}
								onIonChange={(e) => {
									setActivityText2(e.detail.value);
									updateActivityText();
								}}
								onFocus={(e) => {
									setShowFrequent(true);
									setActivityText2("");
								}}
								/*
								onBlur={() => setShowFrequent(false)}
								*/
								autocomplete="true"
								clearOnEdit="true"
								list="activities"
								required
							></IonInput>
							{showFrequent ? (
								<React.Fragment>
									{mostFrequentActivities &&
										mostFrequentActivities.length > 0 &&
										mostFrequentActivities.map((activityRecord, i) => {
											console.log({ activityRecord });
											return (
												<div
													className="frequent-activities"
													value={activityRecord.activities_activityType}
													onClick={() => {
														setActivityText2(activityRecord.activities_activityType);
														setShowFrequent(false);
													}}
												>
													{activityRecord.activities_activityType}
												</div>
											);
										})}
								</React.Fragment>
							) : (
								""
							)}
						</IonItem>
						<IonItem className="activity-item white-border" mode="ios">
							<IonLabel className="field-label" position="stacked">
								How long was your activity?
							</IonLabel>
						</IonItem>
						<IonRow style={{ alignItems: "center" }}>
							<IonCol size="4">
								<IonInput
									className="input-box"
									max="240"
									inputMode="numeric"
									clearOnEdit
									onIonChange={(e) => {
										setMinutes(e.detail.value);
										updateActivityText();
									}}
								/>
							</IonCol>
							<IonCol style={{ paddingLeft: "10px" }} size="6">
								Minutes
							</IonCol>
						</IonRow>
						<IonRow style={{ alignItems: "center" }}>
							<IonCol size="4">
								<IonInput className="input-box" max="12" inputMode="numeric" clearOnEdit onIonChange={(e) => setHours(e.detail.value)} />
							</IonCol>
							<IonCol style={{ paddingLeft: "10px" }} size="6">
								Hours
							</IonCol>
						</IonRow>
						<IonRow className="activity-item" style={{ "--border-color": "white" }}>
							<IonCol size="6">
								<IonLabel className="field-label" position="stacked">
									Intensity
								</IonLabel>
							</IonCol>
							<IonCol size="6">
								<IonSegment value={intensity} onIonChange={(e) => updateIntensity(e.detail.value)} className="intensity-segment-controller" mode="ios">
									<IonSegmentButton value="normal" className="intensity-segment-button" mode="ios">
										<IonLabel style={{ marginTop: "0px", marginBottom: "0px" }}>Normal</IonLabel>
										<IonLabel className="intensity-emoji">
											<Emoji text=":slightly_smiling_face:" />
										</IonLabel>
									</IonSegmentButton>
									<IonSegmentButton value="moderate" className="intensity-segment-button" mode="ios">
										<IonLabel style={{ marginTop: "0px", marginBottom: "0px" }}>Moderate</IonLabel>
										<IonLabel className="intensity-emoji">
											<Emoji text=":sweat_smile:" />
										</IonLabel>
									</IonSegmentButton>
									<IonSegmentButton value="high" className="intensity-segment-button" mode="ios">
										<IonLabel style={{ marginTop: "0px", marginBottom: "0px" }}>High</IonLabel>
										<IonLabel className="intensity-emoji">
											<Emoji text=":sign_of_the_horns:" />
										</IonLabel>
									</IonSegmentButton>
								</IonSegment>
							</IonCol>
						</IonRow>
						<IonRow className="activity-item" style={{ "--border-color": "white" }}>
							<IonCol size="8">
								<IonLabel className="field-label" position="stacked">
									Date of activity
								</IonLabel>
							</IonCol>
							<IonCol size="4" className="activity-date-controller">
								<IonDatetime
									className="activity-date-datetime"
									color="secondary"
									displayFormat="MM.DD.YYYY"
									value={selectedDate}
									mode="ios"
									onIonChange={(e) => setSelectedDate(e.detail.value)}
								></IonDatetime>
								<span className="activity-date-down-chevron">{"\u2304"}</span>
							</IonCol>
						</IonRow>
						<IonRow>
							{activityText ? (
								<div>
									<p dangerouslySetInnerHTML={{ __html: activityDate }}></p>
									<p dangerouslySetInnerHTML={{ __html: activityText }}></p>
									<p dangerouslySetInnerHTML={{ __html: activityTime }}></p>
									<p dangerouslySetInnerHTML={{ __html: activityIntensity }}></p>
									<IonGrid>
										<IonRow>
											<IonButton
												color="success"
												onClick={() => {
													addActivityConfirm();
												}}
											>
												Yep!
											</IonButton>
											<IonButton
												color="danger"
												onClick={() => {
													resetActivity();
												}}
											>
												Reset
											</IonButton>
										</IonRow>
									</IonGrid>
								</div>
							) : (
								""
							)}
						</IonRow>
					</React.Fragment>
				) : (
					""
				)}
				{activityType === "steps" ? (
					<React.Fragment>
						<IonItem className="activity-item white-border" mode="ios">
							<IonLabel className="field-label" position="stacked">
								How many steps did you take?
							</IonLabel>
						</IonItem>
						<IonRow style={{ alignItems: "center" }}>
							<IonCol size="4">
								<IonInput className="input-box" max="240" inputMode="numeric" clearOnEdit onIonChange={(e) => updateStepsText(e.detail.value, null)} />
							</IonCol>
							<IonCol style={{ paddingLeft: "10px" }} size="6">
								Steps
							</IonCol>
						</IonRow>
						<IonRow className="activity-item" style={{ "--border-color": "white" }}>
							<IonCol size="8">
								<IonLabel className="field-label" position="stacked">
									Date of steps
								</IonLabel>
							</IonCol>
							<IonCol size="4" className="activity-date-controller">
								<IonDatetime
									className="activity-date-datetime"
									color="secondary"
									displayFormat="MM.DD.YYYY"
									value={selectedDate}
									mode="ios"
									onIonChange={(e) => updateStepsText(null, e.detail.value)}
								></IonDatetime>
								<span className="activity-date-down-chevron">{"\u2304"}</span>
							</IonCol>
						</IonRow>
						<IonRow>
							{stepsText ? (
								<div>
									<p dangerouslySetInnerHTML={{ __html: stepsText }}></p>
									<IonGrid>
										<IonRow>
											<IonButton
												color="success"
												onClick={() => {
													addStepsConfirm();
												}}
											>
												Yep!
											</IonButton>
											<IonButton
												color="danger"
												onClick={() => {
													resetActivity();
												}}
											>
												Reset
											</IonButton>
										</IonRow>
									</IonGrid>
								</div>
							) : (
								""
							)}
						</IonRow>
					</React.Fragment>
				) : (
					""
				)}
			</IonGrid>
		</React.Fragment>
	);
};

export default BotAddActivityComponent;
