import React, { useContext, useState } from "react";
import {
	IonMenu,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonContent,
	IonList,
	IonItem,
	IonButtons,
	IonMenuButton,
	IonIcon,
	IonLabel,
	IonAlert,
	IonModal,
} from "@ionic/react";
import { menuController } from "@ionic/core";
import { megaphone, megaphoneOutline, menuOutline, podiumOutline, returnDownBackOutline, settings, settingsOutline, warningOutline } from "ionicons/icons";
import "../css/Menu.css";
import AuthContext from "../contexts/GlobalState";

import { logGenericEvent } from "../services/AnalyticsService";
import { useHistory } from "react-router";
import GenericFeedbackComponent from "./Feedback/GenericFeedback";

const Menu = () => {
	const onClickHandler = (eventName) => {
		logGenericEvent(eventName);
		menuController.close();
	};
	const [logoutConfirmation, setLogoutConfirmation] = useState(false);
	const [genericFeedbackModal, setGenericFeedbackModal] = useState(false);

	const { logout, userState } = useContext(AuthContext);

	const history = useHistory();

	async function doLogout(event, confirmation = false) {
		if (confirmation === false) {
			setLogoutConfirmation(true);
		} else {
			await logout();
			history.replace("/");
		}
	}

	return (
		<React.Fragment>
			<IonMenu side="start" menuId="first" content-id="menuContent" swipe-gesture={true} disabled={false} maxEdgeStart={100} hidden={false} type="overlay">
				<IonHeader translucent>
					<IonToolbar color="tertiary" translucent>
						<IonTitle>FitSquad</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<IonModal isOpen={genericFeedbackModal}>
						<GenericFeedbackComponent setGenericFeedbackModal={setGenericFeedbackModal} />
					</IonModal>
					<IonAlert
						isOpen={!!logoutConfirmation}
						message="You sure you would like to log out?"
						buttons={[
							{
								text: "Yes",
								role: "ok",
								handler: () => {
									doLogout(null, true);
								},
							},
							{
								text: "Cancel",
								role: "cancel",
								handler: () => {
									setLogoutConfirmation(false);
								},
							},
						]}
						onDidDismiss={() => setLogoutConfirmation(false)}
					/>
					<IonList>
						{/* <IonItem routerLink="/standings" onClick={() => onClickHandler("Stats")} detail={false}>
							<IonIcon icon={podiumOutline} slot="start" />
							<IonLabel className="menu-label">Stats</IonLabel>
						</IonItem> */}
						<IonItem routerLink="/usersettings" onClick={() => onClickHandler("Settings")} detail={false}>
							<IonIcon icon={settingsOutline} slot="start" />
							<IonLabel className="menu-label">Settings</IonLabel>
						</IonItem>
						<IonItem
							onClick={() => {
								setGenericFeedbackModal(true);
								onClickHandler("Settings");
							}}
							detail={false}
						>
							<IonIcon icon={megaphoneOutline} slot="start" />
							<IonLabel className="menu-label">Share feedback</IonLabel>
						</IonItem>
						{userState.user && userState.user.userName === "Riggz" ? (
							<React.Fragment>
							<IonItem routerLink={"/admin"} onClick={() => onClickHandler("Admin")}>
								<IonIcon icon={warningOutline} slot="start" />
								<IonLabel className="menu-label">Admin</IonLabel>
							</IonItem>
							<IonItem routerLink={"/purchasetesting"} onClick={() => onClickHandler("Admin")}>
							<IonIcon icon={warningOutline} slot="start" />
							<IonLabel className="menu-label">Purchase testing</IonLabel>
						</IonItem>
						</React.Fragment>
						) : (
							""
						)}
						<IonItem onClick={() => doLogout(React.MouseEvent, false)}>
							<IonIcon icon={returnDownBackOutline} slot="start" />
							<IonLabel className="menu-label">Log out</IonLabel>
						</IonItem>
					</IonList>
				</IonContent>
			</IonMenu>
		</React.Fragment>
	);
};

export default Menu;
