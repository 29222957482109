import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonContent,
	IonFooter,
	IonHeader,
	IonItem,
	IonLabel,
	IonPage,
	IonProgressBar,
	IonRadio,
	IonRadioGroup,
	IonToolbar,
	useIonViewWillEnter,
} from "@ionic/react";
import { options } from "ionicons/icons";
import React, { useState } from "react";
import { useHistory } from "react-router";
import "../../css/Home.css";
import "../../css/Onboarding.css";
import { logPageView, setProperty } from "../../services/AnalyticsService";
import { StorageService } from "../../services/StorageService";
import { updateUserSetting } from "../../services/UserService";

const Question = ({ progress, setProgress }) => {
	const storageService = new StorageService();
	const history = useHistory();
	useIonViewWillEnter(() => {
		logPageView("Survey question");
		setProgress(0.4);
		storageService.setItem("lastOnboardingStep", 4);
	});

	const [optionSelected, setOptionSelected] = useState(null);

	const navToPointsExplanation = () => {
		updateUserSetting("fitnessJourney", optionSelected);
		setProperty("fitnessJourney", optionSelected);
		history.push("/onboarding/pointsExplanation");
	};
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar style={{ "--border-style": "none" }}>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
				</IonToolbar>
				<IonProgressBar value={progress} />
			</IonHeader>
			<IonContent>
				<div className="onboarding-main-div">
					<div className="onboarding-title">Help us tailor the app for you</div>
					<div className="onboarding-hero-image-div">
						<img className="onboarding-hero-image" src="assets/images/persona_images.jpg" alt="" />
					</div>

					<div className="onboarding-text-area" style={{ textAlign: "center" }}>
						<p>Which of these best describes you?</p>
						<IonRadioGroup value={optionSelected} onIonChange={(e) => setOptionSelected(e.detail.value)} mode="md">
							<IonItem className="onboarding-radio-item">
								<IonRadio className="onboarding-radio" value="routine" />
								<IonLabel className="onboarding-radio-label-question">
									💪 I've got a regular routine and I'm just looking for a little accountability & community
								</IonLabel>
							</IonItem>
							<IonItem className="onboarding-radio-item">
								<IonRadio className="onboarding-radio" value="struggle" />
								<IonLabel className="onboarding-radio-label-question">
									😤 I struggle to maintain my regular routine and I'm looking for help to keep me motivated.
								</IonLabel>
							</IonItem>
							<IonItem className="onboarding-radio-item">
								<IonRadio className="onboarding-radio" value="help" />
								<IonLabel className="onboarding-radio-label-question">
									🆘 I'm not in regular routine. I'm looking for both help on what to do and how to stay motivated to do it.
								</IonLabel>
							</IonItem>
						</IonRadioGroup>
					</div>
				</div>
				{/* {optionSelected ? <div className="onboarding-question-thanks"><p>Thank for sharing! 🙌</p></div> : ""} */}
			</IonContent>

			<IonFooter className="onboarding-footer">
				{optionSelected ? (
					<IonButton onClick={() => navToPointsExplanation()} className="onboarding-advance-button">
						This is me!
					</IonButton>
				) : (
					""
				)}
			</IonFooter>
		</IonPage>
	);
};

export default Question;
