/* eslint-disable no-restricted-globals */
import { Plugins } from "@capacitor/core";
import { IonApp, IonCol, IonFooter, IonPage, IonRouterOutlet, IonRow, IonSpinner } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import { Deploy } from "cordova-plugin-ionic";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
/* Pages, Components & Services */
import UserProfile from "./components/Profile/Profile";
import AuthContext from "./contexts/GlobalState";
import "./css/App.css";
import { loadingTips } from "./models/constants";
import EntryScreen from "./pages/EntryScreen";
import ForgotPassword from "./pages/ForgotPassword";
import LoginScreen from "./pages/LoginScreen";
import OnboardingContainer from "./pages/OnboardingContainer";
import MainRouting from "./routes/MainRouting";
import { initAnalytics, logLogin, logNavigationEvent } from "./services/AnalyticsService";
import { getDailyGif } from "./services/GifService";
import { getRandomValueFromArray } from "./services/HelperServices";
import { getRecentUpdatesFromAPI, processUpdates } from "./services/NotificationService";
import { StorageService } from "./services/StorageService";
import { getUser, removeBadgesFromUserObject } from "./services/UserService";
import "./theme/theme.css";
/* Theme variables */
import "./theme/variables.css";

const { PushNotifications, Device, SplashScreen } = Plugins;
const storageService = new StorageService();
const build = 62;

const App = () => {
	let debugMode = false; // forces it to stay on the login screen. This was smart! :)
	const { userState, login, logout, setPullingUpdates, onboardingCompleted, setOnboardingCompleted } = useContext(AuthContext);
	const [showLoading, setShowLoading] = useState(false);
	const [checkingForUpdate, setCheckingForUpdate] = useState(false);
	const [pullingUserInfo, setPullingUserInfo] = useState(true);
	const [randomTip] = useState(getRandomValueFromArray(loadingTips));
	const [percentDone, setPercentDone] = useState();
	const [activityModal, showAddActivityModal] = useState(false);
	const [updateAvailable, setUpdateAvailable] = useState();
	const [setNumUpdates] = useState(0);
	const [dayOfTheWeek] = useState(moment().format("ddd"));
	const [gifImage, setGifImage] = useState();

	useEffect(() => {
		doLogin();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onboardingCompleted]);

	/*
	8/12/21
	This does work, but without a line number its very difficult to troubleshoot
	Leaving it in for now though just in case
	useEffect(() => {
		window.onunhandledrejection = (err) => {
			console.log(`I FOUND AN ERROR: ${err.reason}`);
			console.trace();
		};
	});
	*/

	const getUpdates = async (userObject) => {
		getRecentUpdatesFromAPI().then((recentUpdatesFromAPI) => {
			processUpdates(recentUpdatesFromAPI, userObject).then(async () => {
				let numUpdates = parseInt(await storageService.getItem("recentUpdateCount"));
				//console.log("numUpdates: " + numUpdates);
				if (numUpdates === "") {
					numUpdates = 0;
				}
				//setNumUpdates(numUpdates);
				setPullingUpdates(false);
			});
		});
	};

	const doLogin = async () => {
		// Not actually sure what this was doing...
		// SplashScreen.show({
		// 	showDuration: 20000,
		// 	autoHide: true,
		// });
		setShowLoading(true);
		await getDayOfTheWeekGIF();
		// First determine the platform
		let deviceInfo = await Device.getInfo();
		await storageService.setObject("deviceInfo", deviceInfo);

		// Then get the user. TBD what is better to do first
		//setPullingUserInfo(true);
		let userObject = null;
		let refreshUserObject = true; // eff it, do it every time...
		if (dayOfTheWeek === "Tue") {
			// we refresh the user object on Tuesdays because of squad selection
			refreshUserObject = true;
		}
		userObject = await getUser(null, refreshUserObject).catch((error) => {
			userObject = null;
			console.log("Error: " + error);
		});

		let onboarding = await storageService.getObject("onboarding");

		// Then check for an update - Keeping this in the function so it's synchronous
		if (deviceInfo.platform !== "web") {
			let updateMethod, currentVersion, update;
			currentVersion = await Deploy.getCurrentVersion();
			if (userObject === null) {
				// if there's no user, ensure we update first
				setCheckingForUpdate(true);
				updateMethod = "auto";
				update = await Deploy.checkForUpdate().catch((error) => {});
				if (update.available) {
					// We have an update!
					console.log("Getting update");
					try {
						const resp = await Deploy.sync({ updateMethod: updateMethod }, (percentDone) => {
							setCheckingForUpdate(false);
							setUpdateAvailable(true);
							setPercentDone(percentDone);
							console.log("Percent Done: ");
							console.log({ percentDone });
						});
						currentVersion.internalBuildNumber = build;
						storageService.setObject("version", currentVersion);
						if (!currentVersion || currentVersion.versionId !== resp.versionId) {
							console.log("Different versions, reloading app");
							setUpdateAvailable(false);
							await storageService.setItem("recentlyUpdated", "true");
							await Deploy.reloadApp();
						} else {
							setUpdateAvailable(false);
							console.log("Same versions, continuing");
						}
					} catch (err) {
						setUpdateAvailable(false);
						console.log("Error in updating: " + JSON.stringify(err));
					}
				} else {
					setUpdateAvailable(false);
					console.log("No update available");
				}
			} else {
				// do it in the background
				//updateMethod = "background";
				updateMethod = "auto";

				update = await Deploy.checkForUpdate().catch((error) => {});
				if (update.available) {
					// We have an update!
					//let forceDownload = await validateVersion(currentVersion);
					let forceDownload = true; // just doing this for now until Xano can be replaced
					console.log("Force download: " + forceDownload);
					forceDownload === false ? (updateMethod = "background") : (updateMethod = "auto");
					if (updateMethod === "auto") {
						//Update immediately
						setPullingUserInfo(false);
						console.log("Getting update");
						try {
							const resp = await Deploy.sync({ updateMethod: updateMethod }, (percentDone) => {
								setCheckingForUpdate(false);
								setUpdateAvailable(true);
								setPercentDone(percentDone);
								console.log("Percent Done: ");
								console.log({ percentDone });
							});
							currentVersion.internalBuildNumber = build;
							storageService.setObject("version", currentVersion);
							if (!currentVersion || currentVersion.versionId !== resp.versionId) {
								console.log("Different versions, reloading app");
								setUpdateAvailable(false);
								await storageService.setItem("recentlyUpdated", "true");
								await Deploy.reloadApp();
							} else {
								setUpdateAvailable(false);
								console.log("Same versions, continuing");
							}
						} catch (err) {
							setUpdateAvailable(false);
							console.log("Error in updating: " + JSON.stringify(err));
						}
					} else {
						// Update in the background and don't reload
						try {
							const resp = Deploy.sync({ updateMethod: updateMethod });
							await storageService.setItem("recentlyUpdated", "true");

							currentVersion.internalBuildNumber = build;
							storageService.setObject("version", currentVersion);
							if (!currentVersion || currentVersion.versionId !== resp.versionId) {
								console.log("Different versions, reloading app");
							} else {
								console.log("Same versions, continuing");
							}
						} catch (err) {
							console.log("Error in updating: " + JSON.stringify(err));
						}
					}
				} else {
					setUpdateAvailable(false);
					console.log("No update available");
				}
			}
			//console.log("Checking 4 update");
			//console.log("currentVersion - : " + JSON.stringify(currentVersion));
			//console.log("Update Result: " + JSON.stringify(update));
			//console.log({ update });
		} else {
			// it's web
			console.log("Platform = web, skipping update");
		}

		if (userObject && (!onboarding || onboarding.completed === true)) {
			// If it' Android reset the notification channel
			if (deviceInfo.platform === "android") {
				const notificationChannel = {
					id: "primary", // id must match android/app/src/main/res/values/strings.xml's default_notification_channel_id
					name: "primary",
					description: "primary",
					importance: 5,
					visibility: 1,
				};
				PushNotifications.createChannel(notificationChannel);
			}
			userObject = JSON.parse(userObject);

			// Set login event
			logLogin();

			// Setup analytics
			await initAnalytics();

			// Remove all the badges
			if (deviceInfo.platform === "android" || deviceInfo.platform === "ios") {
				PushNotifications.removeAllDeliveredNotifications();
				removeBadgesFromUserObject();
				//setupNotificationListener(history);
			}
			// getUpdates(userObject);
			//Handled in the login function now...
			//let goalObject = await getGoal(null, true);
			//await setGlobalGoal(goalObject);
			await login(userObject);						
			setPullingUserInfo(false);
		} else if (onboarding && onboarding.completed === false) {
			// Onboarding logic
			// Setup analytics
			await initAnalytics();
			console.log("User started onboarding but has not completed");
			setOnboardingCompleted(false);
		} else {
			console.log("No user object");
			setPullingUserInfo(false);
			//await initAnalyticsFreshInstall();
			await logout();
		}
		setShowLoading(false);
	};

	const getDayOfTheWeekGIF = async () => {
		let gifOfTheDay = await storageService.getObject("userGIFHistory");

		let gifURL;
		if (gifOfTheDay === null) {
			gifOfTheDay = {};
		}
		if (!gifOfTheDay[moment().format("MM/DD/YY")]) {
			let date = moment().format("YYYY-MM-DD");
			// do it this way in case the user isn't registered
			await getDailyGif(date)
				.then(async (gifURL) => {
					gifOfTheDay[moment().format("MM/DD/YY")] = gifURL;
					await storageService.setObject("userGIFHistory", gifOfTheDay);
					setGifImage(gifURL);
				})
				.catch((error) => {
					console.log("User not registered");
				});
		} else {
			setGifImage(gifOfTheDay[moment().format("MM/DD/YY")]);
		}
	};

	return (
		<IonApp>
			{/* Loading screen */}
			{/** If no user object exists, then don't proceed until it's confirmed there isn't an update */}
			{/** If the app is updating show the update */}
			{/** If the user is not authenticated, show the entry screen  */}
			{/** If the user is authenticated, setup the main routing  */}

			{/** IF the user exists and there is an update, show the update screen */}
			<IonPage>
				{showLoading === true || debugMode === true ? (
					<React.Fragment>
						<div style={{ height: "100%", backgroundColor: "#1571b3", textAlign: "center" }}>
							<IonRow style={{ marginTop: "10%" }}>
								<IonCol style={{ textAlign: "center", paddingBottom: "10%" }}>
									<img src="assets/images/logo-w-background-w-tagline.jpg" style={{ maxWidth: "75%" }} alt="" />
								</IonCol>
							</IonRow>
							<div id="dailyGIF" style={{ textAlign: "center" }} className="home-card">
								{gifImage ? <img src={gifImage} alt="" style={{ width: "100%", borderRadius: "10px" }} /> : ""}
							</div>
							{/* {randomTip ? (
								<IonRow>
									<div
										style={{
											textAlign: "center",
											width: "100%",
											marginLeft: "20%",
											marginRight: "20%",
											backgroundColor: "white",
											borderRadius: "10px",
											padding: "5%",
											fontStyle: "italic",
											marginTop: "20%",
										}}
									>
										<span style={{ fontWeight: "bold", fontSize: "18px" }}>Tip</span>
										<br />
										<br />
										{randomTip}
									</div>
								</IonRow>
							) : (
								""
							)} */}

							{updateAvailable === true ? (
								<React.Fragment>
									<div className="info-box">
										<p>Downloading new update...</p>
										{percentDone > 0 ? <p>Percent Done: {percentDone}%</p> : <IonSpinner />}
									</div>
								</React.Fragment>
							) : (
								""
							)}
							{checkingForUpdate === true ? (
								<React.Fragment>
									<div className="info-box">
										<p>Checking for update...</p>
										<IonSpinner />
									</div>
								</React.Fragment>
							) : (
								""
							)}
							{pullingUserInfo === true || debugMode === true ? (
								<React.Fragment>
									<div className="info-box">
										<p>Refreshing your data...</p>
										<IonSpinner />
									</div>
								</React.Fragment>
							) : (
								""
							)}
						</div>
						{/* {randomTip ? (
							<IonFooter>
								<div
									style={{
										textAlign: "center",
										backgroundColor: "var(--ion-color-tertiary)",
										padding: "1%",
										fontStyle: "italic",
										color: "white",
									}}
								>
									<span style={{ fontWeight: "bold", fontSize: "18px" }}>Tip</span>
									<br />

									{randomTip}
									<br />
									<br />
								</div>
							</IonFooter>
						) : (
							""
						)} */}
					</React.Fragment>
				) : (
					""
				)}
				{/* Unauthenticated screen */}
				{userState.authenticated === false || onboardingCompleted === false ? (
					<IonReactRouter>
						<IonRouterOutlet>
							<Route path="/entryscreen" exact>
								<EntryScreen />
							</Route>
							<Route path="/forgotpassword" exact>
								<ForgotPassword />
							</Route>
							<Route path="/onboarding">
								<OnboardingContainer />
							</Route>
							<Route path="/login" exact>
								<LoginScreen />
							</Route>
							<Route path="/profile" exact>
								<UserProfile />
							</Route>
							<Redirect path="" to="/entryscreen" exact />
						</IonRouterOutlet>
					</IonReactRouter>
				) : (
					""
				)}
				{/* Authenticated screen */}
				{userState.authenticated === true && onboardingCompleted !== false && debugMode === false ? (
					<React.Fragment>
						<MainRouting activityModal={activityModal} showAddActivityModal={showAddActivityModal} logNavigationEvent={logNavigationEvent} />
					</React.Fragment>
				) : (
					""
				)}
			</IonPage>
		</IonApp>
	);
};

export default App;
