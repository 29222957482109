import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonLoading } from "@ionic/react";
import React, { useState } from "react";
import "../../css/Home.css";
import "../../css/ActivityDetails.css";
import { useParams } from "react-router";
import "../../css/CompetitionCompleted.css";
import SquadSummaryComponent from "../../components/SquadSummaryComponent";

const SquadLastWeekCompleted = (props) => {
	let squadID = useParams().squadID;
	const [loading, setLoading] = useState(true);
	if (props.squadID) {
		squadID = props.squadID;
	}

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton text="" color="light" />
					</IonButtons>
					<IonTitle>Squad Summary</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonLoading isOpen={loading} onDidDismiss={() => setLoading(false)} backdropDismiss="true" message={"Checking squad mates scores..."} />
				<SquadSummaryComponent squadIDPassed={squadID} setLoading={setLoading} />
			</IonContent>
		</IonPage>
	);
};

export default SquadLastWeekCompleted;
