import React, { useEffect, useState } from "react";
import { IonSpinner } from "@ionic/react";
import "../../css/Profile.css";
import "../../css/Home.css";
import moment from "moment";
import { StorageService } from "../../services/StorageService";
import { shouldRefresh } from "../../services/RefreshService";
import { getSquadSumPointsFromActivities, getSumPointsFromActivities } from "../../services/ActivitiesService";
import { Bar } from "react-chartjs-2";

const SquadWeeklyProgressComponent = ({ squadObject }) => {
	const [loadingWeeklyProgress, setLoadingWeeklyProgress] = useState(true);
	const [barChartData, setBarChartData] = useState([]);
	const storageService = new StorageService();

	useEffect(() => {
		setWeeklyProgress(squadObject);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 *
	 * Pull data from prior week and current week up to current date
	 *
	 */
	const setWeeklyProgress = async (squadObj) => {
		let squadID = squadObj.id;
		console.log("SquadID: " + squadID);
		let pointHistory = await storageService.getObject("squadPointHistory-" + squadID);
		if (pointHistory === null) {
			pointHistory = {};
		}
		if (!pointHistory.dailyTotals) {
			pointHistory.dailyTotals = {};
		}
		if (!pointHistory.dailyTotals.lastUpdated) {
			pointHistory.dailyTotals.lastUpdated = moment().format();
		}
		let lwDataSet = [];
		let twDataSet = [];
		let daysChecked = 0;
		let ttl = 30;
		let daysToCheck = moment().diff(moment().startOf("isoWeek").subtract(1, "weeks"), "days") + 1;
		let updated = false;
		console.log("DAYS TO CHECK: ", daysToCheck);
		while (daysChecked < daysToCheck) {
			let dailyTotal = 0;
			let startDate = moment().startOf("day").subtract(daysChecked, "days");
			let endDate = moment().endOf("day").subtract(daysChecked, "days");
			console.log("Checking " + startDate.format());
			if (moment(startDate).isSame(new Date(), "isoWeek") === true) {
				let update = await shouldRefresh(pointHistory.dailyTotals, ttl);
				if (!pointHistory.dailyTotals[moment(startDate).format("MM/DD/YY")] || update === true) {
					console.log(`Pulling new data for user on ${moment(startDate).format("MM/DD/YY")} update = ${update}`);
					//dailyTotal = await getPointsByDate(userObject.cognitoId, null, startDate, endDate);
					dailyTotal = await getSquadSumPointsFromActivities(squadID, startDate, endDate);
					console.log(`Total points for ${moment(startDate).format("MM/DD/YY")} = ${dailyTotal}`);
					pointHistory.dailyTotals[moment(startDate).format("MM/DD/YY")] = dailyTotal;
					updated = true;
				} else {
					console.log(
						`Using current data:\n date: ${moment(startDate).format("MM/DD/YY")}  amount: ${
							pointHistory.dailyTotals[moment(startDate).format("MM/DD/YY")]
						} update: ${update}`
					);
					dailyTotal = pointHistory.dailyTotals[moment(startDate).format("MM/DD/YY")];
				}
				twDataSet.push(dailyTotal);
			} else {
				// last week, dont check for refresh
				if (!pointHistory.dailyTotals[moment(startDate).format("MM/DD/YY")]) {
					console.log(`Pulling new data for user on ${moment(startDate).format("MM/DD/YY")}`);
					//dailyTotal = await getPointsByDate(userObject.cognitoId, null, startDate, endDate);
					dailyTotal = await getSquadSumPointsFromActivities(squadID, startDate, endDate);
					pointHistory.dailyTotals[moment(startDate).format("MM/DD/YY")] = dailyTotal;
				} else {
					console.log(
						`Using current data:\n date: ${moment(startDate).format("MM/DD/YY")}  amount: ${pointHistory.dailyTotals[moment(startDate).format("MM/DD/YY")]}`
					);
					dailyTotal = pointHistory.dailyTotals[moment(startDate).format("MM/DD/YY")];
				}
				lwDataSet.push(dailyTotal);
			}
			daysChecked++;
		}
		if (updated === true) {
			pointHistory.dailyTotals.lastUpdated = moment().format();
		}
		console.log("setWeeklyProgress - pointHistory prior to setting: ", { pointHistory });
		storageService.setObject("userPointHistory-" + squadID, pointHistory);
		console.log("twdataset: ", { twDataSet });
		lwDataSet = lwDataSet.reverse();
		twDataSet = twDataSet.reverse();
		console.log("Pulled a total of " + (daysChecked + 0) + " days");
		console.log("[JS] [pull weekly progress] Complete");
		setBarChartData({
			labels: ["M", "T", "W", "Th", "F", "Sa", "Su"],
			datasets: [
				{
					data: twDataSet,
					label: "This Week",
					backgroundColor: "blue",
				},
				{
					data: lwDataSet,
					label: "Last Week",
					backgroundColor: "grey",
				},
			],
		});
		setLoadingWeeklyProgress(false);
	};

	return (
		<React.Fragment>
			<div className="section-title">Weekly Progress</div>
			{loadingWeeklyProgress === true ? <IonSpinner /> : <Bar data={barChartData} />}
		</React.Fragment>
	);
};

export default SquadWeeklyProgressComponent;
