import {
	IonButton,
	IonCol,
	IonContent,
	IonHeader,
	IonIcon,
	IonInput,
	IonLoading,
	IonPopover,
	IonRadio,
	IonRadioGroup,
	IonRow,
	IonSelect,
	IonSelectOption,
	IonTitle,
	IonToolbar,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import { CountryDropdown } from "react-country-region-selector";
import { usePlacesWidget } from "react-google-autocomplete";
import { cloudFrontURL } from "../../models/constants";
import { signUpUserForChallenge } from "../../services/ChallengeService.ts";
import { getUser, getUserID, updateUser } from "../../services/UserService";
import "../../css/CollectInfo.css";
import { processChallengeOrder } from "../../services/ChallengeService";

const CollectAddressChallenge = ({ setModal, challengeDetails, challengeObject }) => {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [address1, setAddress1] = useState("");
	const [address2, setAddress2] = useState("");
	const [city, setCity] = useState("");
	const [state, setState] = useState("");
	const [country, setCountry] = useState("");
	const [postalCode, setPostalCode] = useState("");
	const [email, setEmail] = useState("");

	const [firstNameError, setFirstNameError] = useState();
	const [lastNameError, setLastNameError] = useState();
	const [addressError, setAddressError] = useState();
	const [cityError, setCityError] = useState();
	const [stateError, setStateError] = useState();
	const [countryError, setCountryError] = useState();
	const [codeError, setCodeError] = useState();
	const [emailError, setEmailError] = useState();

	const [updating, setUpdating] = useState(false);
	const [showPopOver, setShowPopOver] = useState(false);

	const [shirtStyle, setShirtStyle] = useState(null);
	const [shirtSize, setShirtSize] = useState(null);

	const firstNameRef = useRef(null);
	const lastNameRef = useRef(null);
	const address1Ref = useRef(null);
	const address2Ref = useRef(null);
	const cityRef = useRef(null);
	const stateRef = useRef(null);
	const countryRef = useRef(null);
	const postalCodeRef = useRef(null);
	const emailRef = useRef(null);

	useEffect(() => {
		console.log("Showing CAC");
		populateFields();
	}, []);

	const populateFields = async () => {
		let user = JSON.parse(await getUser());
		setEmail(user.email ? user.email : "");
		setFirstName(user.firstName ? user.firstName : "");
		setLastName(user.lastName ? user.lastName : "");
		setAddress1(user.address1 ? user.address1 : "");
		setAddress2(user.address2 ? user.address2 : "");
		setCity(user.city ? user.city : "");
		setState(user.state ? user.state : "");
		setPostalCode(user.postalCode ? user.postalCode : "");
		setCountry(user.country ? user.country : "");
	};

	/**
	 *
	 * Need to complete the redeeming here if an itemID is passed
	 * because item redemption shouldn't happen until address is confirmed
	 *
	 */

	const sendAddress = async () => {
		let validData = true;
		if (firstNameRef.current?.value === "") {
			setFirstNameError("required");
			validData = false;
		} else {
			setFirstNameError("");
			setFirstName(firstNameRef.current.value);
		}
		if (lastNameRef.current?.value === "") {
			setLastNameError("required");
			validData = false;
		} else {
			setLastNameError("");
			setLastName(lastNameRef.current.value);
		}
		if (address1Ref.current?.value === "") {
			setAddressError("required");
			validData = false;
		} else {
			setAddressError("");
		}
		if (address2Ref.current?.value !== "") {
			setAddress2(address2Ref.current.value);
		}
		if (stateRef.current.value === "") {
			setStateError("required");
			validData = false;
		} else {
			setState(stateRef.current.value);
			setStateError("");
		}
		if (cityRef.current.value === "") {
			setCityError("required");
			validData = false;
		} else {
			setCityError("");
		}
		if (countryRef.current.value === "") {
			setCountryError("required");
			validData = false;
		} else {
			setCountryError("");
		}
		if (postalCodeRef.current.value === "") {
			setCodeError("required");
			validData = false;
		} else {
			setCodeError("");
		}
		if (emailRef.current?.value === "") {
			setEmailError("required");
			validData = false;
		} else {
			setEmailError("");
		}
		if (validData === true) {
			setUpdating(true);
			let body = {
				firstName: firstNameRef.current.value,
				lastName: lastNameRef.current?.value ? lastNameRef.current.value : lastName,
				address1: address1Ref.current?.value ? address1Ref.current.value : address1,
				address2: address2Ref.current?.value ? address2Ref.current.value : address2,
				city: cityRef.current?.value ? cityRef.current.value : city,
				state: stateRef.current?.value ? stateRef.current.value : state,
				country: countryRef.current?.value ? countryRef.current.value : country,
				postalCode: postalCodeRef.current?.value ? postalCodeRef.current.value : postalCode,
				email: emailRef.current?.value ? emailRef.current.value : email,
			};
			let response = await updateUser(body).catch((error) => {
				alert(
					"There was an error collecting your address information. Please try again. If you continue to run into issues please contact support at contact@fitsquadapp.com"
				);
			});
			let userID = await getUserID();
			let orderBody = {
				userID: userID,
				challengeID: challengeObject.id,
				orderData: {
					shirtSize: shirtSize,
					shirtStyle: shirtStyle,
				},
			};
			response = await processChallengeOrder(orderBody).catch((error) => {
				alert(
					"There was an error collecting your reward information. Please try again. If you continue to run into issues please contact support at contact@fitsquadapp.com"
				);
			});
			if (response.resultCode === 400) {
				alert(
					"There was an error collecting your reward information. Please try again. If you continue to run into issues please contact support at contact@fitsquadapp.com"
				);
			}
			if (response) {
				setUpdating(false);
				setShowPopOver(true);
			}
		}
	};

	const closeModal = async () => {
		// update challenge state so the user can see the progress tracked on their screen

		setModal({ visible: false, challengeDetails: null });
	};

	return (
		<React.Fragment>
			<IonContent>
				<IonHeader>
					<IonToolbar>
						<IonTitle>Confirm Address</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonLoading isOpen={updating} onDidDismiss={() => setUpdating(false)} message={"Confirming"} duration={3000} />
				<IonPopover
					//event={showGoalConfirmation.event}
					isOpen={showPopOver}
					cssClass="gif-response"
					onDidDismiss={(e) => closeModal()}
				>
					<div style={{ margin: "10%", fontSize: "1.25em" }}>
						<p>Thanks for confirming your address!</p>
						<p>We'll email you with any shipping updates.</p>
					</div>
				</IonPopover>
				<div className="collect-address-div">
					<div className="collect-address-title">Congrats! You're all signed up for {challengeDetails.name}!</div>
					<div className="collect-address-subtitle">Confirm your information below so we can ship you your rewards</div>
					{challengeDetails.metadata && challengeObject.tier === 2 ? (
						<React.Fragment>
							<IonRow>
								<IonCol size="12">
									<IonRow>Shirt Style*</IonRow>
									<IonRow>
										<IonRadioGroup value={shirtStyle} onIonChange={(e) => setShirtStyle(e.detail.value)} style={{ textAlign: "center" }}>
											<IonCol size="4" style={{ display: "inline-block" }}>
												<div>
													<img src="/assets/images/unisex-short-sleeve-tee.jpg" alt="" className="t-shirt-image" />
												</div>
												<div>
													<IonRadio value="unisex" style={{ border: "1px solid lightgray", borderRadius: "13px", padding: "10px" }} />
												</div>
											</IonCol>
											<IonCol size="4" style={{ display: "inline-block" }}>
												<div>
													<img src="/assets/images/womens-favorite-tee.jpg" alt="" className="t-shirt-image" />
												</div>
												<div>
													<IonRadio value="womens" style={{ border: "1px solid lightgray", borderRadius: "13px", padding: "10px" }} />
												</div>
											</IonCol>
											<IonCol size="4" style={{ display: "inline-block" }}>
												<div>
													<img src="/assets/images/racer-tank.jpg" alt="" className="t-shirt-image" />
												</div>
												<div>
													<IonRadio value="racerTank" style={{ border: "1px solid lightgray", borderRadius: "13px", padding: "10px" }} />
												</div>
											</IonCol>
										</IonRadioGroup>
									</IonRow>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol size="6">Shirt Size*:</IonCol>
								<IonCol size="6">
									<IonSelect
										style={{ border: "1px solid lightgray", borderRadius: "5px" }}
										interface="popover"
										value={shirtSize}
										okText="Okay"
										cancelText="Dismiss"
										onIonChange={(e) => setShirtSize(e.detail.value)}
									>
										<IonSelectOption value="XS">XS</IonSelectOption>
										<IonSelectOption value="S">S</IonSelectOption>
										<IonSelectOption value="M">M</IonSelectOption>
										<IonSelectOption value="L">L</IonSelectOption>
										<IonSelectOption value="XL">XL</IonSelectOption>
										<IonSelectOption value="2XL">2XL</IonSelectOption>
									</IonSelect>
								</IonCol>
							</IonRow>
						</React.Fragment>
					) : (
						""
					)}

					<IonRow>
						<IonCol size="6">
							<IonRow>First name*</IonRow>
							<IonRow>
								{firstName ? (
									<IonInput
										className="collect-info-input"
										ref={firstNameRef}
										value={firstName}
										name="firstName"
										type={"text"}
										placeholder="e.g. Diane"
										enterkeyhint="next"
										onFocus={() => setFirstNameError("")}
										required
									></IonInput>
								) : (
									<IonInput
										className="collect-info-input"
										ref={firstNameRef}
										name="firstName"
										type={"text"}
										placeholder="e.g. Diane"
										enterkeyhint="next"
										onFocus={() => setFirstNameError("")}
										required
									></IonInput>
								)}
							</IonRow>
							{firstNameError !== "" ? <IonRow className="error-message">{firstNameError}</IonRow> : ""}
						</IonCol>
						<IonCol size="6">
							<IonRow>Last name*</IonRow>
							<IonRow>
								{lastName ? (
									<IonInput
										className="collect-info-input"
										ref={lastNameRef}
										value={lastName}
										name="lastName"
										type={"text"}
										placeholder="e.g. Smith "
										enterkeyhint="next"
										onFocus={() => setLastNameError("")}
										required
									></IonInput>
								) : (
									<IonInput
										className="collect-info-input"
										ref={lastNameRef}
										name="lastName"
										type={"text"}
										placeholder="e.g. Smith "
										enterkeyhint="next"
										onFocus={() => setLastNameError("")}
										required
									></IonInput>
								)}
							</IonRow>
							{lastNameError !== "" ? <IonRow className="error-message">{lastNameError}</IonRow> : ""}
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="12">
							<IonRow>Email Address*</IonRow>
							<IonRow>
								{email ? (
									<IonInput
										className="collect-info-input"
										ref={emailRef}
										value={email}
										name="email"
										type={"email"}
										placeholder="e.g. you@gmail.com"
										enterkeyhint="next"
										onFocus={() => setEmailError("")}
										required
									></IonInput>
								) : (
									<IonInput
										className="collect-info-input"
										ref={emailRef}
										name="email"
										type={"email"}
										placeholder="e.g. you@gmail.com"
										enterkeyhint="next"
										onFocus={() => setEmailError("")}
										required
									></IonInput>
								)}
							</IonRow>
							{emailError !== "" ? <IonRow className="error-message">{emailError}</IonRow> : ""}
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="12">
							<IonRow>Address*</IonRow>
							<IonRow>
								{address1 ? (
									<IonInput
										className="collect-info-input"
										ref={address1Ref}
										value={address1}
										name="address1"
										type={"address"}
										placeholder="e.g. 123 Main Street"
										enterkeyhint="next"
										onFocus={() => setAddressError("")}
										required
									/>
								) : (
									<IonInput
										className="collect-info-input"
										ref={address1Ref}
										name="address1"
										type={"address"}
										placeholder="e.g. 123 Main Street"
										enterkeyhint="next"
										onFocus={() => setAddressError("")}
										required
									/>
								)}
							</IonRow>
							{addressError !== "" ? <IonRow className="error-message">{addressError}</IonRow> : ""}
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="12">
							<IonRow>Apartment, suite, etc.</IonRow>
							<IonRow>
								{address2 ? (
									<IonInput
										className="collect-info-input"
										ref={address2Ref}
										value={address2}
										name="address2"
										type={"text"}
										placeholder="e.g. Suite 112"
										enterkeyhint="next"
									></IonInput>
								) : (
									<IonInput
										className="collect-info-input"
										ref={address2Ref}
										name="address2"
										type={"text"}
										placeholder="e.g. Suite 112"
										enterkeyhint="next"
									></IonInput>
								)}
							</IonRow>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="12">
							<IonRow>City*</IonRow>
							<IonRow>
								{city ? (
									<IonInput
										className="collect-info-input"
										ref={cityRef}
										value={city}
										name="city"
										type={"text"}
										placeholder="e.g. San Diego"
										enterkeyhint="next"
										onFocus={() => setCityError("")}
										required
									></IonInput>
								) : (
									<IonInput
										className="collect-info-input"
										ref={cityRef}
										name="city"
										type={"text"}
										placeholder="e.g. San Diego"
										enterkeyhint="next"
										onFocus={() => setCityError("")}
										required
									></IonInput>
								)}
							</IonRow>
							{cityError !== "" ? <IonRow className="error-message">{cityError}</IonRow> : ""}
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="12">
							<IonRow>State/Region*</IonRow>
							<IonRow>
								{state ? (
									<IonInput
										className="collect-info-input"
										ref={stateRef}
										value={state}
										name="state"
										type={"text"}
										placeholder="e.g. California"
										enterkeyhint="next"
										onFocus={() => setStateError("")}
										required
									></IonInput>
								) : (
									<IonInput
										className="collect-info-input"
										ref={stateRef}
										name="state"
										type={"text"}
										placeholder="e.g. California"
										enterkeyhint="next"
										onFocus={() => setStateError("")}
										required
									></IonInput>
								)}
							</IonRow>
							{stateError !== "" ? <IonRow className="error-message">{stateError}</IonRow> : ""}
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="12">
							<IonRow>Postal Code*</IonRow>
							<IonRow>
								{postalCode ? (
									<IonInput
										className="collect-info-input"
										ref={postalCodeRef}
										value={postalCode}
										name="postalCode"
										type={"text"}
										placeholder="e.g. 92101"
										enterkeyhint="next"
										onFocus={() => setCodeError("")}
										required
									></IonInput>
								) : (
									<IonInput
										className="collect-info-input"
										ref={postalCodeRef}
										name="postalCode"
										type={"text"}
										placeholder="e.g. 92101"
										enterkeyhint="next"
										onFocus={() => setCodeError("")}
										required
									></IonInput>
								)}
							</IonRow>
							{codeError !== "" ? <IonRow className="error-message">{codeError}</IonRow> : ""}
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="12">
							<IonRow>Country*</IonRow>
							<IonRow>
								<CountryDropdown
									className="collect-info-input native-input sc-ion-input-md sc-ion-input-ios"
									value={country}
									ref={countryRef}
									onChange={(val) => setCountry(val)}
									onFocus={() => setCountryError("")}
								/>
							</IonRow>
							{countryError !== "" ? <IonRow className="error-message">{countryError}</IonRow> : ""}
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol style={{ textAlign: "center" }} size="12">
							<IonButton onClick={() => sendAddress()} color="success">
								Confirm
							</IonButton>
						</IonCol>
					</IonRow>
				</div>
			</IonContent>
		</React.Fragment>
	);
};

export default CollectAddressChallenge;
