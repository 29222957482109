/* eslint-disable jsx-a11y/accessible-emoji */
import { IonAvatar, IonCol, IonIcon, IonRow, IonSpinner, useIonViewWillEnter } from "@ionic/react";
import { checkmarkCircleSharp, closeCircleSharp, hammerSharp, happySharp, trophySharp, warningSharp } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import "../css/Home.css";
import "../css/SquadScoreboard.css";
import AuthContext from "../contexts/GlobalState";
import { getGoalStatusSystemMessage } from "../services/MessageServices";
import moment from "moment";
import { getUser } from "../services/UserService";

const UserDataComponent = ({ userObj = null, goalObj = null }) => {
	const { userState, globalGoal, updateGoalState } = useContext(AuthContext);

	const [userObject, setUserObject] = useState(userObj);
	const [goalObject, setGoalObject] = useState(goalObj);
	const [doughnutChartData, setDoughnutChartData] = useState({ datasets: [], options: [] });
	const [userStreak, setUserStreak] = useState();
	const [percent, setPercent] = useState();
	const [startedThisWeek, setStartedThisWeek] = useState(false);
	const [prediction, setPrediction] = useState({
		points: null,
		percent: null,
		text: null,
		icon: null,
		iconColor: null,
		flavorText: null,
	});

	useEffect(() => {
		if (goalObj === null) {
			setGoalObject(globalGoal);
		}
		if (userObj === null) {
			setUserObject(userState.user);
		}
	}, [globalGoal, userState]);

	useEffect(() => {
		if (goalObject && userObject) {
			loadUserData();
		}
	}, [goalObject, userObject]);

	const loadUserData = async () => {
		let userID;
		if (userObject.cognitoId) {
			userID = userObject.cognitoId;
		}
		if (userObject.userID) {
			// do this because squadmembers has a different userObject format
			userID = userObject.userID;
		}
		let user = JSON.parse(await getUser(userID, false));
		// let user = userObject;
		let goal = goalObject;
		// console.log("UDC User:");
		// console.log({ user });

		// This should never happen...
		if (!goal) {
			console.log("goal is null");
			//setNullGoalNotice(true);
			return;
		}

		//console.log(`Loading user data`);
		// Set the users' streak
		if (user.goalStreak && user.goalStreak > 0) {
			let emojiStreak = "";
			for (let i = 1; i <= user.goalStreak; i++) {
				emojiStreak = emojiStreak + "🔥";
			}
			setUserStreak(emojiStreak);
		} else {
			setUserStreak(" ");
		}

		let percentLocal = Math.round((goal.goalProgress / goal.goalPoints) * 100);
		setPercent(percentLocal);
		if (percentLocal > 100) {
			percentLocal = 100;
		}

		let startOfIsoWeek = moment().startOf("isoWeek").format();
		let userCreatedStartOfIsoWeek = null;
		if (user.createdAt) {
			// do this because "other users" can have their createdAt pulled
			userCreatedStartOfIsoWeek = moment(user.createdAt).startOf("isoWeek").format();
		}
		let predictionText, predictionIcon, iconColor, predictionPoints, predictionPercent, chartColor;
		let flavorText;
		let statusText = {};

		// If the user started this week, keep it simple
		if (startOfIsoWeek === userCreatedStartOfIsoWeek) {
			console.log("Started this week");
			setStartedThisWeek(true);
			predictionPoints = "TBD";
			predictionPercent = 0;
			if (goal.goalProgress === 0) {
				predictionText = "Ready to get started?";
				flavorText = "Log your first activity by tapping the plus button. It can be for earlier in the week if you like.";
			} else {
				predictionText = "Off to a great start 👍";
				flavorText = "The more activities you log, the smarter the system gets, the more fun you'll have and the more motivated you'll feel!";
			}
			predictionIcon = "trophySharp";
			iconColor = "blue";
			chartColor = "green";
		}

		// If the user didnt start this week, make it more complex
		if (startOfIsoWeek !== userCreatedStartOfIsoWeek) {
			console.log("DID NOT Started this week");

			if ((goal.prediction === undefined || goal.prediction === null) && userObj === null) {
				await updateGoalState();
				//console.log("update goal finished");
				goal = globalGoal;
			}
			predictionPoints = goal.prediction;
			predictionPercent = Math.round((predictionPoints / goal.goalPoints) * 100);
			if (percentLocal >= 100) {
				statusText = await getGoalStatusSystemMessage("userStatus", "goalcompleted");
				predictionIcon = "trophySharp";
				iconColor = "purple";
				chartColor = "green";
			} else if (predictionPercent >= 120) {
				statusText = await getGoalStatusSystemMessage("userStatus", "crushit");
				predictionIcon = "hammerSharp";
				iconColor = "orange";
				chartColor = "green";
			} else if (predictionPercent >= 100) {
				statusText = await getGoalStatusSystemMessage("userStatus", "ontrack");
				predictionIcon = "checkmarkCircleSharp";
				iconColor = "green";
				chartColor = "green";
			} else if (predictionPercent >= 90) {
				statusText = await getGoalStatusSystemMessage("userStatus", "cuttingitclose");
				predictionIcon = "warningSharp";
				chartColor = "yellow";
				iconColor = "yellow";
			} else if (predictionPercent < 90) {
				statusText = await getGoalStatusSystemMessage("userStatus", "offtrack");
				predictionIcon = "closeCircleSharp";
				chartColor = "red";
				iconColor = "red";
			}
			if (statusText.title) {
				predictionText = statusText.title.toLowerCase();
			} else {
				predictionText = "";
			}
			flavorText = statusText.body;
		}
		setDoughnutChartData({
			datasets: [
				{
					data: [percentLocal, 100 - percentLocal],
					label: "0%",
					backgroundColor: [`${chartColor}`, "#b0b0b0"],
					borderColor: "#f6f6f6",
				},
			],
			options: [],
		});
		setPrediction({
			points: predictionPoints,
			percent: predictionPercent,
			text: predictionText,
			icon: predictionIcon,
			iconColor: iconColor,
			flavorText: flavorText,
		});
	};
	return (
		<React.Fragment>
			{userObject && goalObject ? (
				<div id="header" className="squad-scoreboard-header">
					<div>
						<IonRow className="member-holder" style={{ marginLeft: "10px", marginRight: "10px" }}>
							<IonCol className="member-avatar" size="3">
								<IonAvatar style={{ width: "75px", height: "75px" }}>
									{userObject.avatar === undefined || userObject.avatar === "" || userObject.avatar === null ? (
										<img src="https://fitsquad-ui-assets.s3-us-west-2.amazonaws.com/empty-avatar.png" alt="" className="fit-fill" />
									) : (
										<img src={userObject.avatar} alt="" className="fit-fill" />
									)}
								</IonAvatar>
							</IonCol>
							<IonCol className="member-data" size="6">
								<IonRow>
									<div className="member-name member-data-point-row">
										<span style={{ fontWeight: "bold" }}>{userObject.userName}</span>
										{prediction.text && prediction.text === "Goal completed" ? (
											<img src="assets/images/trophy_transparent.gif" alt="" style={{ height: "20px", position: "relative", top: "2px" }} />
										) : (
											""
										)}
									</div>
									<IonRow className="member-data-point-row" style={{ fontSize: "1.1em", textTransform: "capitalize" }}>
										{prediction.iconColor === "purple" ? (
											<React.Fragment>
												<IonIcon style={{ color: "purple", verticalAlign: "middle" }} icon={trophySharp} />
												<span style={{ fontSize: "1.1em" }}>{prediction.text}</span>
												<IonIcon style={{ color: "purple", verticalAlign: "middle" }} icon={trophySharp} />
											</React.Fragment>
										) : (
											<React.Fragment>
												{prediction.text}
												{prediction.iconColor === "red" ? <IonIcon style={{ color: "red" }} icon={closeCircleSharp} /> : ""}
												{prediction.iconColor === "white" ? <IonIcon style={{ color: "white" }} icon={happySharp} /> : ""}
												{prediction.iconColor === "yellow" ? <IonIcon style={{ color: "#f0ed00" }} icon={warningSharp} /> : ""}
												{prediction.iconColor === "green" ? <IonIcon style={{ color: "green" }} icon={checkmarkCircleSharp} /> : ""}
												{prediction.iconColor === "orange" ? <IonIcon style={{ color: "orange" }} icon={hammerSharp} /> : ""}
											</React.Fragment>
										)}
									</IonRow>
									<IonRow className="member-data-point-row">
										{goalObject ? (
											<React.Fragment>
												<div>
													<span className="member-current-points member-data-point-row">{goalObject.goalProgress.toLocaleString()}</span>
													{" / "}
													{goalObject.goalPoints.toLocaleString()}
												</div>
												<div style={{ marginLeft: "2%" }}>
													<span style={{ color: `${prediction.iconColor}` }}>({goalObject.goalPercent}%)</span>
												</div>
											</React.Fragment>
										) : (
											""
										)}
									</IonRow>
									<IonRow className="member-data-point-row">
										{prediction.points !== "TBD" ? ( // only show prediction if there is one...
											<React.Fragment>
												{prediction.points !== undefined && prediction.points !== null ? (
													<span className="member-data-prediction-numbers">Prediction: {prediction.points.toLocaleString()}</span>
												) : (
													<span className="member-data-prediction-numbers">
														Prediction: <IonSpinner />
													</span>
												)}
											</React.Fragment>
										) : (
											""
										)}
									</IonRow>
								</IonRow>
							</IonCol>
							{/* {doughnutChartData && percent ? ( */}
							<IonCol size="3">
								<div id="graph" className="scoreboard-doughnut-chart">
									{doughnutChartData ? <Doughnut data={doughnutChartData} height={25} options={{ cutoutPercentage: 60, maintainAspectRatio: false }} /> : ""}
									{percent >= 1000 ? (
										<div className="scoreboard-doughnut-chart-percent-user-progress" style={{ left: "-58px", top: "36px" }}>
											<span className="scoreboard-doughnut-chart-percent-text" style={{ fontSize: "0.9em" }}>
												{percent}%
											</span>
										</div>
									) : (
										""
									)}
									{percent >= 100 && percent < 1000 ? (
										<div className="scoreboard-doughnut-chart-percent-user-progress" style={{ left: "-54px", top: "36px" }}>
											<span className="scoreboard-doughnut-chart-percent-text" style={{ fontSize: "0.9em" }}>
												{percent}%
											</span>
										</div>
									) : (
										""
									)}
									{percent < 100 ? (
										<div className="scoreboard-doughnut-chart-percent-user-progress">
											<span className="scoreboard-doughnut-chart-percent-text">{percent}%</span>
										</div>
									) : (
										""
									)}
								</div>
							</IonCol>
							{/* ) : (
						""
					)} */}
						</IonRow>
						{userStreak ? (
							<IonRow style={{ marginLeft: "10px", marginRight: "10px" }}>
								<div style={{ width: "100%" }}>Current Streak: {userStreak}</div>
							</IonRow>
						) : (
							""
						)}
					</div>
				</div>
			) : (
				""
			)}
		</React.Fragment>
	);
};
export default UserDataComponent;
