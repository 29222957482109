import React from "react";
import { IonAvatar, IonCol, IonRow } from "@ionic/react";

import "../../css/Profile.css";
import "../../css/Home.css";
import { useHistory } from "react-router";

const SquadMembersComponent = ({ squadMembers }) => {
	let history = useHistory();
	const navToProfile = (userID) => {
		history.push("/otherprofile/" + userID);
	};

	return (
		<React.Fragment>
			<div className="section-title">Squad Members</div>
			<div style={{ display: "flex" }}>
				<IonRow className="squad-members">
					{squadMembers.map((member, i) => {
						let colSize = 3;
						if (squadMembers.length < 4) {
							colSize = 12 / squadMembers.length;
						}
						return (
							<IonCol size={colSize} key={`${i}`}>
								<IonAvatar value={member.userID} onClick={() => navToProfile(member.userID)} className="squad-members-img">
									<img src={member.avatar} alt="" className="fit-fill" />
								</IonAvatar>
								<div className="squad-member-name">
									{member.userName.length > 10 ? <span style={{ fontSize: ".75em" }}>{member.userName}</span> : <span>{member.userName}</span>}
								</div>
							</IonCol>
						);
					})}
				</IonRow>
			</div>
		</React.Fragment>
	);
};

export default SquadMembersComponent;
