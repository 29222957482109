import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react";
import Emoji from "react-emoji-render";

const ActivityGridComponent = ({ activity, displayTime = null }) => {
	return (
		<IonGrid>
			<IonRow>
				{displayTime === null ? (
					""
				) : (
					<IonCol size="3" className="feed-time-column">
						<span className="feed-time">{activity.displayTime}</span>
					</IonCol>
				)}

				<IonCol size="2" className="feed-emoji-column">
					{activity.icon ? <Emoji text={activity.icon} className="feed-emoji" /> : <Emoji text=":muscle:" className="feed-emoji" />}
				</IonCol>
				<IonCol>
					<IonGrid>
						<IonRow>
							<Emoji text={activity.displayText} className="feed-activity-text" />
						</IonRow>
						<IonRow>
							<span className="flavor-text">{activity.flavorText}</span>
						</IonRow>
						{activity.bonusFlavorText ? (
							<IonRow>
								<span className="bonus-flavor-text">{activity.bonusFlavorText}</span>
							</IonRow>
						) : (
							""
						)}
					</IonGrid>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};

export default ActivityGridComponent;
