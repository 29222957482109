import { IonBackButton, IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonPage, IonProgressBar, IonToolbar, useIonViewDidEnter } from "@ionic/react";
import React from "react";
import "../../css/Home.css";
import "../../css/Onboarding.css";
import { initAnalyticsFreshInstall } from "../../services/AnalyticsService";
import { StorageService } from "../../services/StorageService";
const storageService = new StorageService();

const Intro = ({ progress, setProgress }) => {
	useIonViewDidEnter(async () => {
		await initAnalyticsFreshInstall();
		setProgress(0.1);
		storageService.setItem("lastOnboardingStep", 1);
	});

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar style={{ "--border-style": "none" }}>
					<IonButtons slot="start">
						<IonBackButton defaultHref="/entryscreen" text="" color="primary" />
					</IonButtons>
				</IonToolbar>
				<IonProgressBar value={progress} />
			</IonHeader>
			<IonContent>
				<div className="onboarding-main-div">
					<div className="onboarding-title">Welcome to FitSquad!</div>

					<img className="onboarding-hero-image" src="assets/images/shirt-collage.jpg" alt="" />

					<div className="onboarding-text-area" style={{ textAlign: "center", marginLeft: "20px", marginRight: "20px" }}>
						We're here to help you build and maintain an exercise habit.
						<br />
						<br />
						We do this by:
						<br />
						<br />
						<span style={{ fontSize: "2em" }}>🎯</span> <br />
						Creating smart, weekly goals for you <br />
						<br /> <span style={{ fontSize: "2em" }}>👫</span> <br />
						Pairing you with a group of people for a shared goal
						<br />
						<br />
						<span style={{ fontSize: "2em" }}>🥇</span> <br />
						Offering seasonal challenges with rewards and charitable impact
						<br />
						<br />
						Ready to get started?
					</div>
				</div>
				<div></div>
			</IonContent>
			<IonFooter className="onboarding-footer">
				<IonButton routerLink="/onboarding/register" className="onboarding-advance-button">
					Let's do it!
				</IonButton>
			</IonFooter>
		</IonPage>
	);
};

export default Intro;
