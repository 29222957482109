import { IonCard, IonCardHeader, IonCardTitle, IonSpinner } from "@ionic/react";
import React, { useRef } from "react";
import ChallengeLedgerComponent from "../../components/Challenges/ChallengeLedgerComponent";
import ChallengeProgressComponent from "../../components/Challenges/ChallengeProgressComponent";
import "../../css/ActivityDetails.css";
import "../../css/Home.css";

const ChallengeSummarySuccess = ({ challengeObject }) => {
	console.log({ challengeObject });
	let width = window.innerWidth;
	const vidRef = useRef(null);
	return (
		<React.Fragment>
			{challengeObject ? (
				<React.Fragment>
					<IonCard>
						<IonCardHeader>
							<IonCardTitle>Challenge Completed!</IonCardTitle>
						</IonCardHeader>
						<video ref={vidRef} id="success" preload="auto" autoPlay={false} muted controls loop style={{ width: "100%" }}>
							<source src="https://d3sveztjqii75x.cloudfront.net/challenges/1/success.mp4" />
						</video>
						<ChallengeProgressComponent challengeObject={challengeObject} source={"EOW"} />
						<div className="details-card-holder">
							<div className="details-section-title">Challenge progress</div>
							<ChallengeLedgerComponent challengeLedger={challengeObject.ledger} />
						</div>
					</IonCard>
				</React.Fragment>
			) : (
				<IonSpinner />
			)}
		</React.Fragment>
	);
};

export default ChallengeSummarySuccess;
