import { get } from "./APIService"
import { StorageService } from "../services/StorageService";
import { shouldRefresh } from "./RefreshService";
import moment from "moment";
import { getUser, getUserID } from "./UserService";
const storageService = new StorageService();

/*
export const getSeasonByID = async (seasonID) =>{
    let url = "seasons/"+seasonID
    let response = await get(url)
    return response
}
*/

export const getSeasonByID = async (seasonID) =>{
    await getAllSeasons()
    let allSeasons = await storageService.getObject("seasons")
    let season = allSeasons.filter((season) => season.id === seasonID)
    return season[0]
}

export const getAllSeasons = async () =>{
    let lastUpdated = await storageService.getObject("seasons-last-updated")
    if (lastUpdated === null){lastUpdated = {}}
    let response = await storageService.getObject("seasons")
    let refresh = await shouldRefresh(lastUpdated)
    if (refresh === true){
        let url = "seasons"
        response = await get(url)
        lastUpdated.lastUpdated = moment()
        storageService.setObject("seasons",response);
        storageService.setObject("seasons-lastUpdated",lastUpdated);
    }
    return response
} 

export const getCurrentSeasons = async () =>{
    const storageService = new StorageService();
    let lastUpdated = await storageService.getObject("current-season-last-updated")
    if (lastUpdated === null){lastUpdated = {}}
    let response = await storageService.getObject("current-season")
    let refresh = await shouldRefresh(lastUpdated)
    if (refresh === true){
        let url = "seasons?current=true"
        response = await get(url)
        lastUpdated.lastUpdated = moment()
        storageService.setObject("current-season",response);
        storageService.setObject("current-season-lastUpdated",lastUpdated);
    }
    return response
} 

export const getAllSeasonRewards = async () =>{
    const storageService = new StorageService();
    let lastUpdated = await storageService.getObject("season-rewards-last-updated")
    if (lastUpdated === null){lastUpdated = {}}
    let response = await storageService.getObject("season-rewards")
    let refresh = await shouldRefresh(lastUpdated)
    if (refresh === true){
        let url = "rewards"
        response = await get(url)
        lastUpdated.lastUpdated = moment()
        storageService.setObject("season-rewards",response);
        storageService.setObject("season-rewards-lastUpdated",lastUpdated);
    }
    return response
}

export const getUserSeasons = async (userID = null) =>{
    if (userID === null){
        userID = await getUserID()
    }
    let url = `user-seasons`
    let response = await get(url)
    console.log(`User Seasons Response: ${JSON.stringify(response)}`);
    return response
} 

export const isUserinSeason = async (userObject, seasonObject = null) => {
    if (seasonObject === null){
        seasonObject = await getCurrentSeasons()
        seasonObject = seasonObject[0]
    }
    let inSeason = false
    if (!seasonObject) {return inSeason} // no current seasons
    if(!userObject.seasonData) {return inSeason} // user isn't in any seasons
    for await (let seasonData of userObject.seasonData){
        if (inSeason === false && seasonData.seasonID === seasonObject.id) {
            inSeason = true
        }
    }
    return inSeason
}

export const isUserIDinSeason = async (userID, seasonObject = null) => {
    if (seasonObject === null){
        seasonObject = await getCurrentSeasons()
        seasonObject = seasonObject[0]
    }
    let inSeason = false
    let userObject = await getUser(userID,false)
    if(!userObject.seasonData) {return inSeason} // user isn't in any seasons
    for await (let seasonData of userObject.seasonData){
        if (inSeason === false && seasonData.seasonID === seasonObject.id) {
            inSeason = true
        }
    }
    return inSeason
}

export const isSeasonOpenForRegistration = (seasonObject) => {
    let open = false
    if(!seasonObject) {return open} // no current seasons
    //console.log(`Moment: ${moment()} open: ${moment(seasonObject.registrationOpens)} close: ${moment(seasonObject.registrationCloses)}`);
    if (moment() < moment(seasonObject.registrationCloses) && moment() >= moment(seasonObject.registrationOpens)) {open = true}
    return open
}

export const hasSeasonStarted = async (seasonObject = null) => {
    if (seasonObject === null){
        seasonObject = await getCurrentSeasons()
        seasonObject = seasonObject[0]
    }
    let started = false
    if (!seasonObject) {
        return started
    }
    if (moment() < moment(seasonObject.endDate) && moment() >= moment(seasonObject.startDate)) {started = true}
    return started
}

export const getSeasonAvatarClass = (userID) => {
    
}

export const addSeasonEmojiToUsername = (username) =>{
    return "⭐ "+username
}

export const didSeasonJustEndForUser = async () => {
    // look at user object
    let user = JSON.parse(await getUser())
    // if the user has season info...
    if (user.seasonData.length > 0){
        // ... get the most recent seasons info
        // TODO: this assumes only one concurrent season at a time
        let seasonInfo =  await getSeasonByID(user.seasonData[0].seasonID)
        // see if current day is between season end date and end date + 7 days
        let currentDate = moment().format()
        //let currentDate = moment().format("2021-07-27")
        let endDateOfShowingInfo = moment(seasonInfo.endDate).add("7","days")
        // if it is it "just ended", else it didn't "just end"
        if (moment(currentDate) > moment(seasonInfo.endDate) && moment(currentDate) <= moment(endDateOfShowingInfo)){ 
            return true
        } else {
            return false
        }
    } else {
        return false
    }
    
}