import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonItem,
	IonButton,
	useIonViewDidEnter,
	IonLoading,
	IonButtons,
	IonBackButton,
	IonAlert,
	IonList,
	IonAvatar,
	IonGrid,
	IonRow,
	IonCol,
	IonToast,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import "../css/Home.css";
import "../css/SquadCreate.css";
import { useParams } from "react-router";
import { deleteUserFromSquad, getListOfUsers, inviteUserToSquad } from "../services/UserService";
import { getSquad } from "../services/SquadService";
import { logPageView } from "../services/AnalyticsService";

const AddUserToSquad = () => {
	const deleteInvitationMessage = "Invitation retracted";
	const deleteUserMessage = "Deleted from squad";

	const [alertMessage, setAlertMessage] = useState([]);
	const [deleteMemberAlert, setDeleteMemberAlert] = useState([""]);
	const [userList, setUserList] = useState([]);
	const [deleting, setDeleting] = useState(false);
	const [showDeletedToast, setShowDeletedToast] = useState({
		visibility: false,
		message: "",
	});
	const [showAddedToast, setShowAddedToast] = useState(false);
	const squadID = useParams().squadID;

	useIonViewDidEnter(async () => {
		logPageView("add-user-to-squad");
		let userList = await getListOfUsers();
		let checkedUserList = await checkUsersAgainstSquadList(userList);
		setUserList(checkedUserList);
	});

	useEffect(() => {
		console.log("Re-rendering");
	});

	const checkUsersAgainstSquadList = async (userList) => {
		let updatedUserList = [];
		let tempSquadInfo = await getSquad(squadID, null, null, true);
		userList.forEach((element) => {
			let filterResult = tempSquadInfo.members.filter((x) => x.userId === element.cognitoId);
			//console.log("ID of latest activity: " + element.id);
			//console.log("filterResult: " + JSON.stringify(filterResult));
			if (Object.keys(filterResult).length > 0) {
				if (filterResult[0].invited === true && filterResult[0].accepted === false) {
					// user has been invited
					element.status = "invited";
				}
				if (filterResult[0].invited === false && filterResult[0].requestDate !== null && filterResult[0].accepted === false) {
					// user has been requested to join
					element.status = "requested";
				}
				if (filterResult[0].acceptDate !== null) {
					// user is a member
					element.status = "member";
				}
			} else {
				element.status = "non-member";
			}
			updatedUserList.push(element);
		});

		return updatedUserList;
	};

	const deleteUserTrigger = async (event, userID, userName, confirmation = false, message) => {
		if (confirmation === false) {
			setDeleteMemberAlert({
				message: "Are you sure you want to kick " + userName + " out of your squad?",
				userID: userID,
				squadID: squadID,
				userName: userName,
			});
		} else {
			setDeleting(true);
			console.log("Kicking user w/ID = " + userID);
			let inviteResult = await deleteInviteTrigger(event, userID, message);
			console.log("Invite Result: " + inviteResult);
			setDeleting(false);
		}
	};

	/**
	 *
	 * Sends an invitation to the userID that was passed
	 *
	 * @param {*} event
	 * @param {string} userID
	 */

	const addUserTrigger = async (event, userID) => {
		let updatedUserRecord = await inviteUserToSquad(userID, squadID).catch((error) => {
			alert("Error: " + error);
			return;
		});
		if (updatedUserRecord.message !== undefined) {
			alert("Error: " + updatedUserRecord.message);
			return;
		}
		//console.log("Response from invite: " + updatedUserRecord);
		let tempUserList = userList;
		let objIndex = tempUserList.findIndex((obj) => obj.cognitoId === userID);
		//console.log("ObjIndex: " + objIndex);
		//console.log("User Record before: " + JSON.stringify(tempUserList[objIndex]));
		tempUserList[objIndex].status = "invited";
		//console.log("User Record after: " + JSON.stringify(tempUserList[objIndex]));
		//console.log("All Users after: ");
		//console.log({ tempUserList });
		//console.log("Forcing squad update");
		getSquad(squadID, null, null, true);
		setUserList(tempUserList);
		setShowAddedToast(true);
	};

	/**
	 *
	 * Takes in a userID and deletes their invite to the squad
	 *
	 * @param {*} event
	 * @param {string} userID
	 */

	const deleteInviteTrigger = async (event, userID, message) => {
		await deleteUserFromSquad(userID, squadID).catch((error) => {
			alert("Error: " + error);
			return;
		});
		let tempUserList = userList;
		let objIndex = tempUserList.findIndex((obj) => obj.cognitoId === userID);
		tempUserList[objIndex].status = "non-member";
		getSquad(squadID, null, null, true);
		setUserList(tempUserList);
		setShowDeletedToast({ visibility: true, message: message });
	};
	return (
		<React.Fragment>
			<IonAlert
				isOpen={!!alertMessage.message}
				header="Oops"
				message={alertMessage.message}
				buttons={[{ text: alertMessage.button1text, role: alertMessage.button1role }]}
				onDidDismiss={() => setAlertMessage("")}
			/>
			<IonAlert
				isOpen={!!deleteMemberAlert.message}
				header="You sure?"
				message={deleteMemberAlert.message}
				buttons={[
					{
						text: "Yes",
						role: "ok",
						handler: () => {
							deleteUserTrigger(null, deleteMemberAlert.userID, deleteMemberAlert.userName, true, deleteUserMessage);
						},
					},
					{
						text: "Cancel",
						role: "cancel",
						handler: () => {
							setDeleteMemberAlert("");
						},
					},
				]}
				onDidDismiss={() => setDeleteMemberAlert("")}
			/>
			<IonToast
				isOpen={showDeletedToast.visibility}
				onDidDismiss={() => setShowDeletedToast({ visibility: false, message: "" })}
				message={showDeletedToast.message}
				duration={2000}
			/>
			<IonToast isOpen={showAddedToast} onDidDismiss={() => setShowAddedToast(false)} message="Invitation sent" duration={2000} />
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton text="Back" color="light" />
						</IonButtons>
						<IonTitle>Add User to Squad</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<IonLoading isOpen={deleting} onDidDismiss={() => setDeleting(false)} message={"Removing user from squad..."} />
					<IonList>
						<IonGrid>
							{userList.map((user, i) => (
								<IonRow key={`${i}`}>
									<IonItem style={{ width: "100%" }}>
										<IonCol style={{ width: "20%" }} size="2">
											{user.avatar === undefined || user.avatar === "" || user.avatar === null ? (
												<IonAvatar>
													<img src="https://fitsquad-ui-assets.s3-us-west-2.amazonaws.com/empty-avatar.png" alt="" />
												</IonAvatar>
											) : (
												<IonAvatar>
													<img src={user.avatar} alt="" />
												</IonAvatar>
											)}
										</IonCol>
										<IonCol style={{ width: "60%" }} size="6">
											{user.userName}
										</IonCol>
										<IonCol style={{ width: "20%", textAlign: "center" }}>
											{user.status === "non-member" ? (
												<IonButton
													color="secondary"
													onClick={() => {
														addUserTrigger(React.MouseEvent, user.cognitoId, user.userName);
													}}
												>
													Add
												</IonButton>
											) : (
												""
											)}
											{user.status === "invited" ? (
												<IonButton
													color="medium"
													onClick={() => {
														deleteInviteTrigger(React.MouseEvent, user.cognitoId, deleteInvitationMessage);
													}}
												>
													Invited
												</IonButton>
											) : (
												""
											)}
											{user.status === "requested" ? (
												<IonButton
													color="secondary"
													onClick={() => {
														addUserTrigger(React.MouseEvent, user.cognitoId, user.userName);
													}}
												>
													Requested
												</IonButton>
											) : (
												""
											)}
											{user.status === "member" ? (
												<IonButton
													color="success"
													onClick={() => {
														deleteUserTrigger(React.MouseEvent, user.cognitoId, user.userName);
													}}
												>
													Member
												</IonButton>
											) : (
												""
											)}
										</IonCol>
									</IonItem>
								</IonRow>
							))}
						</IonGrid>
					</IonList>
				</IonContent>
			</IonPage>
		</React.Fragment>
	);
};

export default AddUserToSquad;
