import { IonHeader, IonTitle, IonToolbar, IonButton, IonIcon, IonTextarea, IonToast } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import {Clipboard} from "@ionic-native/clipboard"
import React, { useState } from "react";
import "../css/Home.css";
import "../css/ActivityDetails.css";


const CopyInviteCodeComponent = ({ showCopyReminder, setShowCopyReminder }) => {
    const [showToast, setShowToast] = useState(false)
    const copyText = () =>{
        Clipboard.copy(`${showCopyReminder.message}`)
        setShowToast(true)
        setShowCopyReminder({visible:false})
    }
	return (
		<React.Fragment>
			<IonHeader>
				<IonToolbar className="activity-details-toolbar">
					<IonTitle>Invite Message</IonTitle>
					<IonButton color="tertiary" slot="end" onClick={() => setShowCopyReminder({visible:false})}>
						<IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
					</IonButton>
				</IonToolbar>
			</IonHeader>
            <IonToast isOpen={showToast} onDidDismiss={() => setShowToast(false)} message="Invite copied to clipboard" duration={1000} />
			<div style={{ margin: "16px", height: "100%" }}>
                Copy and paste this text into your invite message <br/>
				<div style={{marginTop:"16px", marginBottom: "16px", padding: "16px", backgroundColor: "lightgray", borderWidth: "1px", borderStyle:"solid", borderRadius:"10px"}}> 
                    {showCopyReminder.message}                    
                </div>
                <IonButton expand="block" onClick={()=>copyText()}>
                    Copy Invite
                </IonButton>
			</div>
		</React.Fragment>
	);
};

export default CopyInviteCodeComponent;
