import React, { useRef } from "react";
import { IonSlides, IonSlide, IonButton } from "@ionic/react";
import Emoji from "react-emoji-render";

const ChallengesHomeCoachMark = () => {
	return (
		<React.Fragment>
			<div style={{ width: "80%", position: "absolute", top: "10%", textAlign: "center", margin: "10%" }}>
				<br />
				<div style={{ fontSize: "2em" }}>
					🏅🏅🏅
					<br />
					Challenges are here!
				</div>
				<div style={{ fontSize: "20px", paddingTop: "10%" }}>
					Set a multi-week goal to be consistently active.
					<br />
					<br />
					If you're successful, earn medals and benefit important charities.
					<br />
				</div>
				<div style={{ fontSize: "1.5em", paddingTop: "10%" }}>
					Now your activity is more rewarding
					<br />
					<br />
					💙💚💛
				</div>
			</div>
			<div style={{ width: "100%", position: "absolute", top: "90%", left: "30%" }}>
				<div style={{ width: "40%", float: "left" }}>
					Tap the Challenges icon to try one out! <Emoji text=":point_right:" />
				</div>
				<img src="assets/images/arrow-white-down-right.png" alt="" style={{ height: "70px" }} />
			</div>
		</React.Fragment>
	);
};

export default ChallengesHomeCoachMark;
