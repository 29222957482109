import {
	IonAlert,
	IonAvatar,
	IonBackButton,
	IonButton,
	IonButtons,
	IonChip,
	IonCol,
	IonContent,
	IonHeader,
	IonItem,
	IonLabel,
	IonLoading,
	IonPage,
	IonRefresher,
	IonRefresherContent,
	IonRow,
	IonSpinner,
	IonTitle,
	IonToast,
	IonToolbar,
	useIonViewDidEnter,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { loadingMessages } from "../../models/constants";
import { logGenericEvent } from "../../services/AnalyticsService";
import { getRandomValueFromArray } from "../../services/HelperServices";
import { sendSlackNotification } from "../../services/NotificationService";
import { getAllSquads, getSquad } from "../../services/SquadService";
import { acceptUserToSquad, deleteUserFromSquad, getUser, getUserID, updateUser } from "../../services/UserService";
import AuthContext from "../../contexts/GlobalState";

const SquadsManaged = ({ squadsManaged, pendingRequests, setPendingRequests, getKeyObjects }) => {
	const history = useHistory();
	const { userState, updateUserState } = useContext(AuthContext);
	const [acceptRequestAlert, setAcceptRequestAlert] = useState([""]);
	const [declineRequestAlert, setDeclineRequestAlert] = useState([""]);
	const [accepting, setAccepting] = useState(false);
	const [showRejectedToast, setShowRejectedToast] = useState(false);
	const [showDeletedToast, setShowDeletedToast] = useState(false);
	const [pendingReqs, setPendingReqs] = useState([]);

	useEffect(() => {
		//console.log({ pendingRequests });
		setPendingReqs(pendingRequests);
	}, [squadsManaged, pendingRequests]);

	/*
    useIonViewDidEnter(async () =>{
        await getSquadRequests()
    })


    const getSquadRequests = async (squadID) => {
        // Refactor to pull ALL pending requests a user can act on
		setPendingRequests([]);
		//console.log(`SquadID: ${squadID}`);
		let squadInfo = await getSquad(squadID, null, null, true);
		//console.log({ squadInfo });
		for (let members of squadInfo.members) {
			if (members.requestDate !== null && members.accepted === false && members.invited === false) {
				// user must have requested
				members.squadName = squadInfo.name;
				members.squadID = squadID;
				//console.log("members:", { members });
				members.fontSize = "1em";
				if (members.user.userName.length > 11) {
					members.fontSize = "0.75em";
				}
				if (members.user.userName.length > 17) {
					members.fontSize = "0.75em";
				}
				setPendingRequests((pendingRequests) => [...pendingRequests, members]);
			}
		}
	};

    */
	const acceptRequest = async (event, userID, userName, squadID, squadName, confirmation = false, message) => {
		//console.log(`acceptRequest squadID: ${squadID}`);
		if (confirmation === false) {
			setAcceptRequestAlert({
				message: "Are you sure you want to accept the request from " + userName + " to join " + squadName + "?",
				userID: userID,
				userName: userName,
				squadID: squadID,
				squadName: squadName,
			});
		} else {
			setAccepting(true);
			await acceptUserToSquad(userID, squadID).catch((error) => {
				alert("Error: " + error);
				return;
			});
			let tempPendingRequests = pendingRequests;
			tempPendingRequests = tempPendingRequests.filter((request) => {
				return request.userId !== userID && request.squadId !== squadID;
			});
			let user = JSON.parse(await getUser(userID));
			logGenericEvent("accepted user into squad");
			sendSlackNotification("*New user accepted into squad*\nUser: " + user.userName + "\nSquad Name: " + squadName + "\n", "creation");
			setPendingRequests(tempPendingRequests);
			await updateUserState();
			setAccepting(false);
		}
	};

	const declineRequest = async (event, userID, userName, squadID, squadName, confirmation = false) => {
		if (confirmation === false) {
			setDeclineRequestAlert({
				message: "Are you sure you want to decline the request from " + userName + " to join " + squadName + "?",
				userID: userID,
				userName: userName,
				squadID: squadID,
				squadName: squadName,
			});
		} else {
			await deleteUserFromSquad(userID, squadID).catch((error) => {
				alert("Error: " + error);
				return;
			});
			let tempPendingRequests = [];
			for (let request of pendingRequests) {
				//console.log({ request });
				// WTF!!!
				//console.log("checking for " + request.userId + " vs " + userID + "& " + request.squadId + " vs " + squadID);
				if (request.userId !== userID && parseInt(request.squadId) !== parseInt(squadID)) {
					//console.log("match found for " + userID + " " + squadID);
					tempPendingRequests.push(request);
				}
			}
			let user = JSON.parse(await getUser(userID));
			logGenericEvent("decline user from squad");
			sendSlackNotification("*New user declined entry into squad*\nUser: " + user.userName + "\nSquad Name: " + squadName + "\n", "creation");
			setPendingRequests(tempPendingRequests);
			await updateUserState();
			setShowRejectedToast(true);
		}
	};

	return (
		<div>
			<IonToast isOpen={showRejectedToast} onDidDismiss={() => setShowRejectedToast(false)} message="Request declined" duration={2000} />
			<IonToast isOpen={showDeletedToast} onDidDismiss={() => setShowDeletedToast(false)} message="Squad Deleted" duration={2000} />
			<IonAlert
				isOpen={!!acceptRequestAlert.message}
				header="You sure?"
				message={acceptRequestAlert.message}
				buttons={[
					{
						text: "Yes",
						role: "ok",
						handler: () => {
							acceptRequest(null, acceptRequestAlert.userID, acceptRequestAlert.userName, acceptRequestAlert.squadID, acceptRequestAlert.squadName, true);
						},
					},
					{
						text: "Cancel",
						role: "cancel",
						handler: () => {
							setAcceptRequestAlert("");
						},
					},
				]}
				onDidDismiss={() => setAcceptRequestAlert("")}
			/>
			<IonAlert
				isOpen={!!declineRequestAlert.message}
				header="You sure?"
				message={declineRequestAlert.message}
				buttons={[
					{
						text: "Yes",
						role: "ok",
						handler: () => {
							declineRequest(null, declineRequestAlert.userID, declineRequestAlert.userName, declineRequestAlert.squadID, declineRequestAlert.squadName, true);
						},
					},
					{
						text: "Cancel",
						role: "cancel",
						handler: () => {
							setDeclineRequestAlert("");
						},
					},
				]}
				onDidDismiss={() => setDeclineRequestAlert("")}
			/>
			{squadsManaged.map((squad, i) => {
				//console.log({ squad });
				return (
					<div key={`${i}`}>
						<IonRow className="squad-search-row">
							<IonCol size="3" className="search-squad-image-column" onClick={() => history.push("/squad/" + squad.squadId)}>
								<img src={squad.squad.image} className="search-squad-image" alt="" />
							</IonCol>
							<IonCol size="9" onClick={() => history.push("/squad/" + squad.squadId)}>
								<div className="search-squad-name">{squad.squad.name}</div>
								<div className="search-squad-slogan">{squad.squad.slogan}</div>
								<IonRow>
									{squad.squad.tags &&
										squad.squad.tags.map((tag, i) => {
											return (
												<IonChip key={`${i}`} outline="false" color="tertiary">
													<IonLabel style={{ fontSize: "10px" }}>{tag.tag}</IonLabel>
												</IonChip>
											);
										})}
								</IonRow>
							</IonCol>
						</IonRow>
						{pendingReqs.length > 0 &&
							pendingReqs.map((request, i) => {
								if (request.squadID === squad.squadId) {
									return (
										<IonRow key={`${i}`}>
											<IonItem className="invites">
												<IonCol style={{ width: "20%" }} size="2" onClick={() => history.push("/otherprofile/" + request.user.cognitoId)}>
													{request.user.avatar === undefined || request.user.avatar === "" || request.user.avatar === null ? (
														<IonAvatar>
															<img src="https://fitsquad-ui-assets.s3-us-west-2.amazonaws.com/empty-avatar.png" alt="" />
														</IonAvatar>
													) : (
														<IonAvatar>
															<img src={request.user.avatar} alt="" />
														</IonAvatar>
													)}
												</IonCol>
												<IonCol style={{ width: "60%" }} size="4">
													<IonRow style={{ fontSize: `${request.fontSize}` }}>{request.user.userName}</IonRow>
													<IonRow style={{ fontSize: "0.5em", overflowWrap: "break-word" }}>
														<i>to join {request.squadName}</i>
													</IonRow>
												</IonCol>
												<IonCol style={{ width: "20%", textAlign: "center" }}>
													<IonButton
														color="success"
														onClick={() => {
															acceptRequest(React.MouseEvent, request.user.cognitoId, request.user.userName, request.squadId, request.squadName);
														}}
													>
														Accept
													</IonButton>
												</IonCol>
												<IonCol style={{ width: "20%", textAlign: "center" }}>
													<IonButton
														color="danger"
														onClick={() => {
															declineRequest(React.MouseEvent, request.user.cognitoId, request.user.userName, request.squadId, request.squadName);
														}}
													>
														Decline
													</IonButton>
												</IonCol>
											</IonItem>
										</IonRow>
									);
								}
							})}
						<IonRow></IonRow>
					</div>
				);
			})}
		</div>
	);
};

export default SquadsManaged;
