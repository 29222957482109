import { IonCol, IonGrid, IonIcon, IonRow, IonSpinner } from "@ionic/react";
import { checkmarkCircleSharp, closeCircleSharp, hammerSharp, happySharp, trophySharp, warningSharp } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { useHistory } from "react-router";
import "../../css/SquadScoreboard.css";
import { logGenericClickEvent, logNavigationEvent } from "../../services/AnalyticsService";
import { getGoalStatusSystemMessage } from "../../services/MessageServices";

const UserProgressComponentSample = ({ userObj }) => {
	const history = useHistory();
	const [doughnutChartData, setDoughnutChartData] = useState({ datasets: [], options: [] });
	const [goalObject, setGoalObject] = useState();
	const [userObject, setUserObject] = useState(userObj);
	const [userStreak, setUserStreak] = useState();
	const [percent, setPercent] = useState();
	const [loadingData, setLoadingData] = useState(false);
	const [startedThisWeek, setStartedThisWeek] = useState(false);
	const [prediction, setPrediction] = useState({
		points: null,
		percent: null,
		text: null,
		icon: null,
		iconColor: null,
		flavorText: null,
	});
	const hoursInAWeek = 168;
	/*
	useIonViewWillEnter(async () => {
		await loadUserData();
	});
*/
	useEffect(() => {
		loadUserData();
	}, []);

	const loadUserData = async () => {
		let goal = {
			goalPoints: 900,
			goalProgress: 600,
		};
		setGoalObject(goal);
		setUserStreak("🔥🔥🔥🔥");

		let percentLocal = Math.round((goal.goalProgress / goal.goalPoints) * 100);
		setPercent(percentLocal);
		if (percentLocal > 100) {
			percentLocal = 100;
		}

		let predictionText, predictionIcon, iconColor, predictionPoints, predictionPercent, chartColor;
		let flavorText;
		let statusText = {};

		predictionPoints = 950;
		predictionPercent = Math.round((predictionPoints / goal.goalPoints) * 100);
		if (percentLocal >= 100) {
			statusText = await getGoalStatusSystemMessage("userStatus", "goalcompleted");
			predictionIcon = "trophySharp";
			iconColor = "purple";
			chartColor = "green";
		} else if (predictionPercent >= 120) {
			statusText = await getGoalStatusSystemMessage("userStatus", "crushit");
			predictionIcon = "hammerSharp";
			iconColor = "orange";
			chartColor = "green";
		} else if (predictionPercent >= 100) {
			statusText = await getGoalStatusSystemMessage("userStatus", "ontrack");
			predictionIcon = "checkmarkCircleSharp";
			iconColor = "green";
			chartColor = "green";
		} else if (predictionPercent >= 90) {
			statusText = await getGoalStatusSystemMessage("userStatus", "cuttingitclose");
			predictionIcon = "warningSharp";
			iconColor = "yellow";
		} else if (predictionPercent < 90) {
			statusText = await getGoalStatusSystemMessage("userStatus", "offtrack");
			predictionIcon = "closeCircleSharp";
			iconColor = "red";
		}
		predictionText = statusText.title;
		flavorText = statusText.body;
		setDoughnutChartData({
			datasets: [
				{
					data: [percentLocal, 100 - percentLocal],
					label: "0%",
					backgroundColor: [`${chartColor}`, "#b0b0b0"],
					borderColor: "#f6f6f6",
				},
			],
			options: [],
		});
		setPrediction({
			points: predictionPoints,
			percent: predictionPercent,
			text: predictionText,
			icon: predictionIcon,
			iconColor: iconColor,
			flavorText: flavorText,
		});
		setLoadingData(false);
	};

	const navTo = (destination, analyticsName) => {
		logNavigationEvent("user-progress-component", analyticsName);
		history.push(destination);
	};

	const navToSalesDetails = () => {
		logGenericClickEvent("squad-progress-component", "nav-to-web-sales");
		window.open("http://fitsquadapp.com/products", "_new");
	};

	return (
		<div>
			<div onClick={() => navTo("/profile", "profile")}>
				<div id="header" className="squad-scoreboard-header">
					<div>
						{userObject ? (
							<IonRow style={{ textAlign: "center" }}>
								<div style={{ width: "100%", fontSize: "1.5em", fontWeight: "bold" }}>{userObject.userName}</div>
								<br />
								{startedThisWeek === false && userStreak ? (
									<div style={{ width: "100%", fontStyle: "italic" }}>Current Streak: {userStreak}</div>
								) : (
									<div style={{ width: "100%", fontStyle: "italic" }}>Current Streak: None yet!</div>
								)}
							</IonRow>
						) : (
							""
						)}
						<IonRow style={{ height: "100px" }}>
							<IonCol size="4" style={{ textAlign: "center" }}>
								{userObject ? (
									<div>
										<img
											//src="https://fitsquad-ui-assets.s3-us-west-2.amazonaws.com/empty-avatar.png"
											src={userObject.avatar}
											alt=""
											style={{ height: "80px", width: "80px", borderRadius: "10px", objectFit: "cover" }}
										/>
									</div>
								) : (
									""
								)}
							</IonCol>
							<IonCol size="3">
								<div id="graph" className="scoreboard-doughnut-chart">
									{doughnutChartData ? <Doughnut data={doughnutChartData} height={25} options={{ cutoutPercentage: 60, maintainAspectRatio: false }} /> : ""}
									{percent >= 1000 ? (
										<div className="scoreboard-doughnut-chart-percent" style={{ left: "-58px", top: "36px" }}>
											<span className="scoreboard-doughnut-chart-percent-text" style={{ fontSize: "0.9em" }}>
												{percent}%
											</span>
										</div>
									) : (
										""
									)}
									{percent >= 100 && percent < 1000 ? (
										<div className="scoreboard-doughnut-chart-percent" style={{ left: "-54px", top: "36px" }}>
											<span className="scoreboard-doughnut-chart-percent-text" style={{ fontSize: "0.9em" }}>
												{percent}%
											</span>
										</div>
									) : (
										""
									)}
									{percent < 100 ? (
										<div className="scoreboard-doughnut-chart-percent">
											<span className="scoreboard-doughnut-chart-percent-text">{percent}%</span>
										</div>
									) : (
										""
									)}
								</div>
							</IonCol>
							<IonCol size="5">
								<div className="scoreboard-squad-data-holder" style={{ marginTop: "19%", marginLeft: "2%" }}>
									{goalObject && loadingData === false ? (
										<React.Fragment>
											<div>
												<div className="scoreboard-squad-data-label" style={{ width: "70%", display: "inline-block" }}>
													Goal Points
												</div>
												<div className="scoreboard-squad-data" style={{ width: "30%", display: "inline-block" }}>
													{goalObject.goalPoints.toLocaleString()}
												</div>
											</div>
											<div style={{ marginTop: "10%" }}>
												<div className="scoreboard-squad-data-label" style={{ width: "70%", display: "inline-block" }}>
													Current Points
												</div>
												<div className="scoreboard-squad-data" style={{ width: "30%", display: "inline-block" }}>
													{goalObject.goalProgress.toLocaleString()}
												</div>
											</div>
										</React.Fragment>
									) : (
										""
									)}
								</div>
							</IonCol>
						</IonRow>
						<IonRow>
							{
								//prediction && prediction.text !== null && refreshInProgress === false && loadingData === false ? (
							}
							{prediction && prediction.text !== null ? (
								<div className="prediction-holder">
									{prediction.points === "?" ? (
										<IonRow
											onClick={navToSalesDetails}
											style={{ backgroundColor: `${prediction.iconColor}`, color: "white" }}
											className="prediction-holder-prediction"
										>
											<IonCol size="12" style={{ textAlign: "center" }}>
												<span>{prediction.text}</span>
											</IonCol>
										</IonRow>
									) : (
										<IonRow>
											{prediction.iconColor === "purple" ? (
												<React.Fragment>
													<IonCol size="12" style={{ fontWeight: "500", textAlign: "center" }} className="prediction-holder-col">
														<IonIcon style={{ color: "purple", verticalAlign: "middle" }} size="large" icon={trophySharp} />
														<span style={{ fontSize: "1.1em", marginLeft: "20px", marginRight: "20px" }}>{prediction.text}</span>
														<IonIcon style={{ color: "purple", verticalAlign: "middle" }} size="large" icon={trophySharp} />
													</IonCol>
												</React.Fragment>
											) : (
												<React.Fragment>
													<IonCol className="prediction-holder-prediction prediction-holder-col" size="2">
														{prediction.iconColor === "red" ? <IonIcon style={{ color: "red" }} size="large" icon={closeCircleSharp} /> : ""}
														{prediction.iconColor === "white" ? <IonIcon style={{ color: "white" }} size="large" icon={happySharp} /> : ""}
														{prediction.iconColor === "yellow" ? <IonIcon style={{ color: "#f0ed00" }} size="large" icon={warningSharp} /> : ""}
														{prediction.iconColor === "green" ? <IonIcon style={{ color: "green" }} size="large" icon={checkmarkCircleSharp} /> : ""}
														{prediction.iconColor === "orange" ? <IonIcon style={{ color: "orange" }} size="large" icon={hammerSharp} /> : ""}
													</IonCol>
													<IonCol size="4" className="prediction-holder-label prediction-holder-col">
														You are:
													</IonCol>
													<IonCol size="6" style={{ fontWeight: "500", textAlign: "right" }} className="prediction-holder-col">
														{prediction.iconColor === "red" ? <span style={{ fontSize: "1.45em" }}>{prediction.text}</span> : ""}
														{prediction.iconColor === "white" ? <span style={{ fontSize: "1.15em" }}>{prediction.text}</span> : ""}
														{prediction.iconColor === "yellow" ? <span style={{ fontSize: "0.9em" }}>{prediction.text}</span> : ""}
														{prediction.iconColor === "green" ? <span style={{ fontSize: "1.5em" }}>{prediction.text}</span> : ""}
														{prediction.iconColor === "orange" ? <span style={{ fontSize: "1.25em" }}>{prediction.text}</span> : ""}
														{prediction.iconColor === "blue" ? <span style={{ fontSize: "1.1em" }}>{prediction.text}</span> : ""}
													</IonCol>
												</React.Fragment>
											)}
										</IonRow>
									)}
								</div>
							) : (
								<div className="prediction-holder">
									<IonRow>
										<IonCol className="prediction-holder-prediction prediction-holder-col" size="2">
											<IonSpinner />
										</IonCol>
										<IonCol size="4" className="prediction-holder-label prediction-holder-col">
											You are:
										</IonCol>
										<IonCol size="6" style={{ fontWeight: "500", textAlign: "right" }} className="prediction-holder-col">
											<IonSpinner />
										</IonCol>
									</IonRow>
								</div>
							)}
						</IonRow>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserProgressComponentSample;
