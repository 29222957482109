import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonChip,
	IonContent,
	IonHeader,
	IonLabel,
	IonLoading,
	IonPage,
	IonTitle,
	IonToolbar,
	useIonViewDidEnter,
} from "@ionic/react";
import React, { useState } from "react";
import { useParams } from "react-router";
import { logGenericEvent } from "../services/AnalyticsService";
import { getChatRoomIDFromSquadID, getSquad } from "../services/SquadService";
import { autoAddUserToSquad, getUserID } from "../services/UserService";

const SquadJoined = () => {
	let squadID = useParams().squadID;
	const [squadJoined, setSquadJoined] = useState();
	const [loading, setLoading] = useState(false);
	useIonViewDidEnter(async () => {
		let squad = await getSquad(squadID, null, null, true);
		let userID = await getUserID();

		await autoAddUserToSquad(userID, squad.id, squad.shortCode)
			.catch((error) => {
				logGenericEvent("error autoadding user to squad");
				console.log("Error: ", { error });
				return;
			})
			.then(async (response) => {
				console.log("Response from adding user: ", { response });
				logGenericEvent("Joined squad " + squad.id);
				squad.chatRoomID = await getChatRoomIDFromSquadID(squadID);
				console.log({ squad });
				setSquadJoined(squad);
			});

		setLoading(false);
	});
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons>
						<IonBackButton text="" />
					</IonButtons>
					<IonTitle>Welcome!</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonLoading isOpen={loading} onDidDismiss={() => setLoading(false)} message={"Loading your new home..."} />
				{squadJoined ? (
					<React.Fragment>
						<div className="onboarding-title">
							<div style={{ fontWeight: "bold" }}>
								Welcome to your new squad! <br />
							</div>
						</div>
						<div className="squad-data-div" style={{ width: "100%", textAlign: "center", paddingTop: "10px" }}>
							<div id="squad-image">
								<img src={squadJoined.image} alt="" style={{ height: "50%", width: "50%", borderRadius: "10px" }} />
							</div>
							<div style={{ textAlign: "center" }}>
								<div className="squad-slogan-div" style={{ width: "100%", fontStyle: "italic" }}>
									{squadJoined.slogan}
								</div>
								<div className="squad-name-div" style={{ width: "100%", fontSize: "1.5em", fontWeight: "bold" }}>
									{squadJoined.name}
								</div>
								<div style={{ width: "100%", fontSize: "1.5em", fontWeight: "bold" }}>
									{squadJoined.tags &&
										squadJoined.tags.map((tag, i) => {
											return (
												<IonChip key={`${i}`} outline="false" color="tertiary">
													<IonLabel>{tag.tag}</IonLabel>
												</IonChip>
											);
										})}
								</div>
							</div>
						</div>
						<div className="onboarding-text-area" style={{ textAlign: "center", paddingTop: "20%", fontSize: "20px" }}>
							Enter the squad chat room and say hi
						</div>
						<div className="onboarding-footer">
							<IonButton routerLink={`/squadhome/${squadID}}`} className="onboarding-advance-button">
								Say Hi
							</IonButton>
						</div>
					</React.Fragment>
				) : (
					""
				)}
			</IonContent>
		</IonPage>
	);
};

export default SquadJoined;
