import moment from "moment";
import { API_URL, PHP_URL, PHP_URL_NAME } from "../models/constants";
import { getIDToken } from "./AuthService";
import { getUserID, getUserNameFromUserID } from "./UserService";

/**
 *
 * Gets the feed for a user
 *
 * @param {integer} limit (optional)
 * @param {integer} offset (optional)
 *
 * return JSON object for users's activities
 *
 */
export const getUserFeed = async (limit = 20, offset = 0) => {
	const url = API_URL;
	let idToken = await getIDToken().catch((error) => {
		throw Error("[getFeed] Error in getIDtoken: " + error);
	});
	let endPoint = "activities?limit=" + limit + "&offset=" + offset;
	const response = await Promise.resolve(
		fetch(url + endPoint, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[JS][getFeed] Error in API call: " + error);
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {
			let serverResponse = JSON.stringify(data);
			console.log("[JS] getFeed severResponse:", serverResponse);
			return serverResponse;
		})
		.catch((error) => {
			console.log("[JS][getFeed] Error in JSON conversion: " + error);
			throw Error(error);
		});
};

/**
 *
 * Gets the feed for a squadID
 *
 * @param {string} squadID
 * @param {integer} limit (optional)
 * @param {integer} offset (optional)
 *
 * return JSON object for squad's activities
 *
 */
export const getSquadFeed = async (squadID, limit = 20, offset = 0) => {
	const url = API_URL;
	let idToken = await getIDToken().catch((error) => {
		throw Error("[getSquadFeed] Error in getIDtoken: " + error);
	});
	let endPoint = "activities?limit=" + limit + "&offset=" + offset + "&squadId=" + squadID;
	const response = await Promise.resolve(
		fetch(url + endPoint, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[JS][getSquadFeed] Error in API call: " + error);
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {
			let serverResponse = JSON.stringify(data);
			console.log("[JS] getSquadFeed severResponse:", serverResponse);
			return serverResponse;
		})
		.catch((error) => {
			console.log("[JS][getSquadFeed] Error in JSON conversion: " + error);
			throw Error(error);
		});
};

/**
 *
 * Returns the details about a specific activity
 *
 * @param {integer} feedID
 *
 * returns JSON object with activity details
 *
 */

export const getActivityDetails = async (feedID) => {
	const url = API_URL;
	let idToken = await getIDToken().catch((error) => {
		throw Error("[getActivityDetails] Error in getIDtoken: " + error);
	});
	let endPoint = "activities/" + feedID;
	const response = await Promise.resolve(
		fetch(url + endPoint, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[JS][getActivityDetails] Error in API call: " + error);
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {
			let serverResponse = JSON.stringify(data);
			console.log("[JS] getActivityDetails severResponse:", serverResponse);
			return serverResponse;
		})
		.catch((error) => {
			console.log("[JS][getActivityDetails] Error in JSON conversion: " + error);
			throw Error(error);
		});
};

/**
 * 
 * Creates an activity for the feed
 * 
 * @param {JSON} body 
 * 
 * Sample Body
 *  "title": "This gets rendered somewhere",
    "description": "Allow me to take this opportunity to talk at length about my day. How long can this be? I'm not sure because it gets packed in to a JSON object before inserting in to the DB. I'm hoping that the whole object, when serialized, can be more than the 255 character limit bullshit that SQL has. That's just one of the many reasons that I prefer No-SQL databases - you don't have to define character limits for strings, you just stick 'em in there and everything is fine. On the otherhand, I've certainly been taking advantage of the fact that we're using a relational database. For example, the whole concept of 'friends' is nothing more than a lookuptable with a couple of foreign key definitions (and some other metadata, of course). By having the FK relationships defined, when I query a user, I can also pull in all those related tables without having to do additional queries; it's included. I haven't figured out how to do that recursively yet, but I'm sure that is do-able. That doesn't really matter now, but at some point there might be some performance reason to investigate that opportunity further. Also, dildos.",
    "dataType": "workout",
    "activityType": "synchronized pilates",
    "value": 52,
    "valueUnit": "min",
    "totalDistance": 1.12,
    "totalDistanceUnit": "mi",
    "totalEnergyBurned": 11,
    "sourceName": "Adam's Watch",
    "creationDate": "2017-06-24 09:41:17 -0700",
    "startDate": "2020-08-10 09:20:23 -0700",
    "endDate": "2020-08-10 09:40:29 -0700",
    "reportId": "9e692c59-e4ff-44fd-ae15-83136392dfbe",
    "timeZone": "US/Pacific",
    "weatherTemperature": 88.9,
    "weatherUnit": "f",
    "humidity": 13.3
 * 
 */

export const createActivity = async (body) => {
	const url = API_URL;
	let idToken = await getIDToken().catch((error) => {
		throw Error("[createActivity] Error in getIDtoken: " + error);
	});
	let endPoint = "activities";
	const response = await Promise.resolve(
		fetch(url + endPoint, {
			method: "POST",
			body: JSON.stringify(body),
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[JS][createActivity] Error in API call: " + error);
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {
			let serverResponse = JSON.stringify(data);
			console.log("[JS] createActivity severResponse:", serverResponse);
			return serverResponse;
		})
		.catch((error) => {
			console.log("[JS][createActivity] Error in JSON conversion: " + error);
			throw Error(error);
		});
};

/**
 *
 * Deletes an activity
 *
 * @param {integer} feedID
 */

export const deleteActivity = async (feedID) => {
	const url = API_URL;
	let idToken = await getIDToken().catch((error) => {
		throw Error("[deleteActivity] Error in getIDtoken: " + error);
	});
	let endPoint = "activities/" + feedID;
	const response = await Promise.resolve(
		fetch(url + endPoint, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[JS][deleteActivity] Error in API call: " + error);
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {
			let serverResponse = JSON.stringify(data);
			console.log("[JS] deleteActivity severResponse:", serverResponse);
			return serverResponse;
		})
		.catch((error) => {
			console.log("[JS][deleteActivity] Error in JSON conversion: " + error);
			throw Error(error);
		});
};

/**
 *
 * Adds a reaction to a feed element
 *
 * @param {integer} feedID
 * @param {string} reaction
 */

export const addReaction = async (feedID, reaction) => {
	const url = API_URL;
	const data = {
		addReaction: reaction,
	};
	let idToken = await getIDToken().catch((error) => {
		throw Error("[addReaction] Error in getIDtoken: " + error);
	});
	let endPoint = "activities/" + feedID;
	logReactionGiven(reaction)
	const response = await Promise.resolve(
		fetch(url + endPoint, {
			method: "PATCH",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[JS][addReaction] Error in API call: " + error);
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {
			let serverResponse = JSON.stringify(data);
			console.log("[JS] addReaction severResponse:", serverResponse);
			return serverResponse;
		})
		.catch((error) => {
			console.log("[JS][addReaction] Error in JSON conversion: " + error);
			throw Error(error);
		});
};

export const logReactionGiven = async (reaction, squadID = null) => {
	const url = PHP_URL_NAME;
	let userID = await getUserID()
	let userName = await getUserNameFromUserID(userID)
	moment.updateLocale('en', {
		week: {
		  dow : 1, // Monday is the first day of the week.
		}
	  });
	let weekNumber = moment().week()
	let year = moment().year()
	const data = {
		userID: userID,
		userName: userName,
		weekNumber: weekNumber,
		year: year,
		emoji: reaction,
		squadID: squadID
	};
	console.log({data});
	fetch(url + "fitsquad/logLove.php", {
		method: "POST",
		body: JSON.stringify(data),
	})
}

/**
 *
 * Removes a reaction to a feed element
 *
 * @param {integer} feedID
 *
 */

export const removeReaction = async (feedID) => {
	const url = API_URL;
	const data = {
		removeReaction: 1,
	};
	let idToken = await getIDToken().catch((error) => {
		throw Error("[removeReaction] Error in getIDtoken: " + error);
	});
	let endPoint = "activities/" + feedID;
	const response = await Promise.resolve(
		fetch(url + endPoint, {
			method: "PATCH",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[JS][removeReaction] Error in API call: " + error);
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {
			let serverResponse = JSON.stringify(data);
			console.log("[JS] removeReaction severResponse:", serverResponse);
			return serverResponse;
		})
		.catch((error) => {
			console.log("[JS][removeReaction] Error in JSON conversion: " + error);
			throw Error(error);
		});
};
