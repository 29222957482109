import { IonLoading } from "@ionic/react";
import React, { useState } from "react";
import { useHistory } from "react-router";
import "../css/Standings.css";
import { loadingMessages } from "../models/constants";
import { getRandomValueFromArray } from "../services/HelperServices";

const StandingsSquadComponent = ({ squadStandings, setSquadStandings, sortedColumn, setSortedColumn, loadingSquadStandings, setLoadingSquadStandings }) => {
	const [loadingMessage, setLoadingMessage] = useState("Loading stats... This currently takes a long time... sorry 😕");
	const history = useHistory();
	const sortSquadBy = async (columnName) => {
		let allSquads = squadStandings;
		switch (columnName) {
			case "wins":
				allSquads.sort((a, b) => (a.standings.wins > b.standings.wins ? -1 : 1));
				setSortedColumn(2);
				break;
			case "winPercent":
				allSquads.sort((a, b) => (a.standings.winPercent > b.standings.winPercent ? -1 : 1));
				setSortedColumn(3);
				break;
			case "last10Wins":
				allSquads.sort((a, b) => (a.standings.last10Wins > b.standings.last10Wins ? -1 : 1));
				setSortedColumn(4);
				break;
			case "avgWeeklyGoal":
				allSquads.sort((a, b) => (a.standings.avgWeeklyGoal > b.standings.avgWeeklyGoal ? -1 : 1));
				setSortedColumn(5);
				break;
			case "avgWeeklyTotal":
				allSquads.sort((a, b) => (a.standings.avgWeeklyTotal > b.standings.avgWeeklyTotal ? -1 : 1));
				setSortedColumn(6);
				break;
			case "avgWeeklyDifference":
				allSquads.sort((a, b) => (a.standings.avgWeeklyDifference > b.standings.avgWeeklyDifference ? -1 : 1));
				setSortedColumn(7);
				break;
			case "avgMemberCompletionPercent":
				allSquads.sort((a, b) => (a.standings.avgMemberCompletionPercent > b.standings.avgMemberCompletionPercent ? -1 : 1));
				setSortedColumn(8);
				break;

			default:
				break;
		}
		setSquadStandings(allSquads);
	};

	return (
		<React.Fragment>
			<IonLoading isOpen={loadingSquadStandings} onDidDismiss={() => setLoadingSquadStandings(false)} message={loadingMessage} backdropDismiss={true} />
			<div>
				<div style={{ display: "inline-flex" }}>
					<div className="header-column header-row header header-row-column vertical-center">Squad Name</div>
					<div className={`stat-column header-row header vertical-center ${sortedColumn === 2 ? "sorted" : ""}`} onClick={() => sortSquadBy("wins")}>
						<span className="sortable">W-L </span>
					</div>
					<div
						value="winPercent"
						className={`stat-column header-row header vertical-center ${sortedColumn === 3 ? "sorted" : ""}`}
						onClick={() => sortSquadBy("winPercent")}
					>
						<span className="sortable">Win % </span>
					</div>
					<div className={`stat-column header-row header vertical-center ${sortedColumn === 4 ? "sorted" : ""}`} onClick={() => sortSquadBy("last10Wins")}>
						<span className="sortable">Last 10 </span>
					</div>
					<div className={`stat-column header-row header vertical-center ${sortedColumn === 5 ? "sorted" : ""}`} onClick={() => sortSquadBy("avgWeeklyGoal")}>
						<span className="sortable">Avg. Goal</span>
					</div>
					<div className={`stat-column header-row header vertical-center ${sortedColumn === 6 ? "sorted" : ""}`} onClick={() => sortSquadBy("avgWeeklyTotal")}>
						<span className="sortable">Avg. Total</span>
					</div>
					<div
						className={`stat-column header-row header vertical-center ${sortedColumn === 7 ? "sorted" : ""}`}
						onClick={() => sortSquadBy("avgWeeklyDifference")}
					>
						<span className="sortable">
							Avg.
							<br /> Diff
						</span>
					</div>
					<div
						className={`stat-column header-row header vertical-center ${sortedColumn === 8 ? "sorted" : ""}`}
						onClick={() => sortSquadBy("avgMemberCompletionPercent")}
					>
						<span className="sortable">Member Win %</span>
					</div>
				</div>

				{squadStandings &&
					squadStandings.map((squad, index) => {
						let bkcolor;
						if (squad.inSquad === true) {
							bkcolor = "rgb(248, 255, 187)";
						} else if (index % 2 === 0) {
							bkcolor = "white";
						} else {
							bkcolor = "rgb(248, 248, 248)";
						}
						return (
							<div style={{ display: "inline-flex", backgroundColor: `${bkcolor}` }} key={`${index}`}>
								<div className="header-column" style={{ backgroundColor: `${bkcolor}` }} onClick={() => history.push("/squad/" + squad.id)}>
									<img src={`${squad.image}`} className="squad-standings-image" alt="" />

									{squad.name}
								</div>
								<div className={`stat-column ${sortedColumn === 2 ? "sorted" : ""}`}>
									{squad.standings.wins}-{squad.standings.losses}
								</div>
								<div className={`stat-column ${sortedColumn === 3 ? "sorted" : ""}`}>{Math.round(squad.standings.winPercent * 100)}%</div>
								<div className={`stat-column ${sortedColumn === 4 ? "sorted" : ""}`}>
									{squad.standings.last10Wins}-{squad.standings.last10Losses}
								</div>
								<div className={`stat-column ${sortedColumn === 5 ? "sorted" : ""}`}>{Math.round(squad.standings.avgWeeklyGoal).toLocaleString()}</div>
								<div className={`stat-column ${sortedColumn === 6 ? "sorted" : ""}`}>{Math.round(squad.standings.avgWeeklyPoints).toLocaleString()}</div>
								<div className={`stat-column ${sortedColumn === 7 ? "sorted" : ""}`}>{Math.round(squad.standings.avgWeeklyDifference).toLocaleString()}</div>
								<div className={`stat-column ${sortedColumn === 8 ? "sorted" : ""}`}>{Math.round(squad.standings.avgMemberCompletionPercent * 100)}%</div>
								<div></div>
							</div>
						);
					})}
			</div>
		</React.Fragment>
	);
};

export default StandingsSquadComponent;
