/* eslint-disable jsx-a11y/accessible-emoji */
import { IonButton, IonHeader, IonTitle, IonToolbar } from "@ionic/react";
import React from "react";
import "../../css/ActivityDetails.css";
import "../../css/Home.css";
import { areNotificationsAuthorized, enableNotifications } from "../../services/NotificationService";

const RequestSquadPopover = ({ setShowRequestSquadConfirmed, showRequestSquadConfirmed }) => {
	return (
		<React.Fragment>
			<IonHeader>
				<IonToolbar className="activity-details-toolbar">
					<IonTitle>You're In!</IonTitle>
				</IonToolbar>
			</IonHeader>
			<div style={{ margin: "16px", textAlign: "center" }}>
				<div>
					You have requested to join <br />
					<h1>{showRequestSquadConfirmed.squadObject.name}</h1>
				</div>
				<div>
					<img className="confirm-squad-image" style={{ height: "50%" }} src={showRequestSquadConfirmed.squadObject.image} alt="" />
				</div>
				<div className="confirm-squad-slogan">{showRequestSquadConfirmed.squadObject.slogan}</div>
				<div style={{ paddingTop: "5%", paddingBottom: "5%" }}>You'll be notified when your request is accepted</div>
				{areNotificationsAuthorized === false ? (
					<IonButton expand="block" onClick={() => enableNotifications()}>
						Enable notifications
					</IonButton>
				) : (
					<IonButton expand="block" onClick={() => setShowRequestSquadConfirmed({ visible: false })}>
						Sounds good 👍
					</IonButton>
				)}
			</div>
		</React.Fragment>
	);
};

export default RequestSquadPopover;
