import { IonCard, IonCol, IonItem, IonPopover, IonRow } from "@ionic/react";
import moment from "moment";
import React, { useState } from "react";
import "../../css/ActivityDetails.css";
import "../../css/Home.css";
import "../../css/FAQs.css";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion";
import ChallengeTerms from "./ChallengeTerms";

const ChallengeFAQsComponent = ({ challengeDetails, challengeObject }) => {
	return (
		<React.Fragment>
			<div className="details-section-title">Challenge FAQs</div>
			<IonCard className="no-shadow light-gray-border">
				<Accordion allowZeroExpanded>
					{challengeDetails.metadata.faqs &&
						challengeDetails.metadata.faqs.map((faqs, i) => {
							let size = challengeDetails.metadata.faqs.length - 1;
							return (
								<React.Fragment key={`${i}`}>
									<IonItem>
										<AccordionItem>
											<AccordionItemHeading>
												<AccordionItemButton>{faqs.title}</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel>
												<p dangerouslySetInnerHTML={{ __html: faqs.body }}></p>
											</AccordionItemPanel>
										</AccordionItem>
									</IonItem>
								</React.Fragment>
							);
						})}
					<IonItem>
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>Where can I read the terms and conditions?</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<ChallengeTerms />
							</AccordionItemPanel>
						</AccordionItem>
					</IonItem>
				</Accordion>
			</IonCard>
		</React.Fragment>
	);
};

export default ChallengeFAQsComponent;
