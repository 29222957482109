import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonItem,
	IonList,
	IonRow,
	IonSpinner,
	useIonViewWillEnter,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import ChallengeDetailsComponent from "../components/Challenges/ChallengeDetailsComponent";
import ChallengeLedgerComponent from "../components/Challenges/ChallengeLedgerComponent";
import ChallengeProgressComponent from "../components/Challenges/ChallengeProgressComponent";
import "../css/ActivityDetails.css";
import "../css/Home.css";
import { getChallengeDetailsByChallengeID } from "../services/ChallengeService";
import { StorageService } from "../services/StorageService";
import ChallengeSummary from "./end_of_week_slides/ChallengeSummary";

const EndOfSeasonSummary = () => {
	const [challengeObject, setChallengeObject] = useState();
	const storageService = new StorageService();
	useEffect(() => {
		start();
	}, []);

	const start = async () => {
		let co = await storageService.getObject("challenges");
		console.log({ co });
		setChallengeObject(co[1]);
	};

	return (
		<React.Fragment>
			{challengeObject && challengeObject.challengeDetails ? (
				<React.Fragment>
					<ChallengeSummary challengeObject={challengeObject} />
				</React.Fragment>
			) : (
				<IonSpinner />
			)}
		</React.Fragment>
	);
};

export default EndOfSeasonSummary;
