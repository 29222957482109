//import awsmobile from "../aws-exports";
import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonCol,
	IonContent,
	IonHeader,
	IonInput,
	IonItem,
	IonLabel,
	IonLoading,
	IonPage,
	IonRow,
	IonToolbar,
} from "@ionic/react";
import { Plugins } from "@capacitor/core";
import Amplify, { Auth } from "aws-amplify";
import React, { useContext, useRef, useState } from "react";
import { useHistory } from "react-router";
import aws_exports from "../aws-exports";
import AuthContext from "../contexts/GlobalState";
import "../css/Register.css";
import { initAnalytics } from "../services/AnalyticsService";
import { getRecentUpdatesFromAPI, processUpdates, setupNotificationListener } from "../services/NotificationService";
import { getGoal } from "../services/PointsAndGoalsService";
import { getSquad, getSquadGoal } from "../services/SquadService";
import { StorageService } from "../services/StorageService";
import { getCognitoUsernameFromUsername, getUser, removeBadgesFromUserObject } from "../services/UserService";
const { PushNotifications, Device } = Plugins;
const storageService = new StorageService();

const ForgotPassword = () => {
	const history = useHistory();
	const loggingInMessage = "Password succesfully changed. Logging you in";
	const { login, setPullingUpdates } = useContext(AuthContext);

	const [loggingIn, setLoggingIn] = useState({
		visible: false,
		message: "",
	});

	Amplify.configure(aws_exports);

	const [validatingData, setValidatingData] = useState({
		visible: false,
		message: null,
	});

	const [resetPWError, setResetPWError] = useState({
		visible: false,
		message: null,
	});

	const [passwordError, setPasswordError] = useState({
		visible: false,
		message: null,
	});

	const [emailSentNotice, setEmailSentNotice] = useState({
		visible: false,
		message: null,
	});

	const [codeError, setCodeError] = useState({
		visible: false,
		message: null,
	});

	const [generalError, setGeneralError] = useState({
		visible: false,
		message: null,
	});

	const passwordRef = useRef(null);
	const emailRef = useRef(null);
	const resetCodeRef = useRef(null);

	const forgotPW = async () => {
		let username = await getCognitoUsernameFromUsername(emailRef.current.value);
		let validEmail = await validateEmail(username);
		if (validEmail === true) {
			try {
				let response = await Auth.forgotPassword(username);
				console.log("awsResponse from AWS signIn: " + JSON.stringify(response));
				switch (response.CodeDeliveryDetails.DeliveryMedium) {
					case "SMS":
						response.message =
							"A code was sent via text message to " +
							response.CodeDeliveryDetails.Destination +
							". Please check your messages and enter the code you received below.";
						break;
					case "EMAIL":
						response.message =
							"A code was sent via email to " + response.CodeDeliveryDetails.Destination + ". Please check your email and enter the code you received below.";
						break;
					default:
						response.message =
							"A code was sent via email to " + response.CodeDeliveryDetails.Destination + ". Please check your email and enter the code you received below.";
						break;
				}
				setResetPWError({ visible: false });
				setEmailSentNotice({
					visible: true,
					message: response.message,
				});
			} catch (error) {
				console.log("Error from AWS signIn: " + JSON.stringify(error));
				switch (error.code) {
					case "LimitExceededException":
						error.sanitizedMessage = "Too many attempts to try an reset the password for this account. Please try again in about 15 minutes.";
						break;
					default:
						error.sanitizedMessage = error.message;
						break;
				}
				setEmailSentNotice({ visible: false });
				setResetPWError({
					visible: true,
					message: error.sanitizedMessage,
				});
			}
		}
	};

	const validateEmail = async (userName) => {
		if (userName !== "" && userName !== null && userName !== undefined) {
			return true;
		} else {
			return false;
		}
	};

	const validatePassword = (password) => {
		if (password.length < 8) {
			setPasswordError({
				visible: true,
				message: "Password should be at least 8 characters",
			});
			setCodeError({
				visible: false,
				message: null,
			});
			return false;
		} else {
			setPasswordError({
				visible: false,
				message: null,
			});
			return true;
		}
	};

	const validateCode = async () => {
		let code = resetCodeRef.current.value;
		let username = await getCognitoUsernameFromUsername(emailRef.current.value);
		let newPassword = passwordRef.current.value;
		if (validatePassword(newPassword) === false) {
			return;
		}
		let response, success;
		try {
			setValidatingData({ visible: true, message: loggingInMessage });
			response = await Auth.forgotPasswordSubmit(username, code, newPassword); // will be undefined if successful
			console.log("Response from AWS validateCode: " + JSON.stringify(response));
			success = true;
		} catch (error) {
			setValidatingData({ visible: false, message: "" }); // makes sure its on the whole time
			console.log("Error from AWS validateCode: " + JSON.stringify(error));
			switch (error.code) {
				case "LimitExceededException":
					error.sanitizedMessage = "Too many password reset attempts. Please try again in about 15 minutes.";
					break;
				default:
					error.sanitizedMessage = error.message;
					break;
			}
			setCodeError({
				visible: true,
				message: error.sanitizedMessage,
			});
			success = false;
		}
		if (success === true) {
			// Log the user in
			logUserIn();
		}
	};

	const logUserIn = async () => {
		let username = await getCognitoUsernameFromUsername(emailRef.current.value);
		let data = {
			password: passwordRef.current.value,
			email: username,
		};
		let response = JSON.parse(await signInLocal(data));
		//console.log("signInResponse: " + JSON.stringify(response));
		if (response) {
			if (response.name) {
				// for some reason AWS responds with the name value sometimes :/
				response.code = response.name;
			}
			let message;
			if (response.username) {
				console.log("Successful login");
				let expirationDate = response["signInUserSession"]["idToken"]["payload"]["exp"];
				let refreshToken = response["signInUserSession"]["refreshToken"]["token"];
				let idToken = response["signInUserSession"]["idToken"]["jwtToken"];
				await storageService.setItem("idToken", idToken);
				await storageService.setObject("expirationDate", expirationDate);
				console.log("Expiration date stored: " + expirationDate);
				await storageService.setItem("refreshToken", refreshToken);
				await storageService.setItem("displayUsername", data.username);
				await storageService.setItem("fullUsername", data.email);
				await storageService.setItem("password", data.password);
				let loginResult = await doLogin();
				if (loginResult.success && loginResult.success === true) {
					setLoggingIn({ visible: false, message: "" }); // makes sure its on the whole time
					await login(loginResult.userObject);
					history.push("/");
				} else {
					setLoggingIn({ visible: false, message: "" }); // makes sure its on the whole time
					setGeneralError({
						visible: true,
						message: loginResult,
					});
				}
			} else {
				setLoggingIn({ visible: false, message: "" }); // makes sure its on the whole time
				switch (response.code) {
					default:
						message = response.message;
						setGeneralError({
							visible: true,
							message: message,
						});
						break;
				}
			}
		}
	};

	async function signInLocal(data) {
		let username = data.email;
		let password = data.password;
		let awsResponse;
		try {
			let response = await Auth.signIn({
				username,
				password,
			});
			//console.log("Response from AWS signIn: " + JSON.stringify(response));
			awsResponse = JSON.stringify(response);
		} catch (error) {
			console.log("Error from AWS signIn: " + JSON.stringify(error));
			awsResponse = JSON.stringify(error);
			return awsResponse;
		}
		return awsResponse;
	}

	const doLogin = async () => {
		let user = null;
		try {
			user = JSON.parse(await getUser(null, false));
		} catch (error) {
			console.log("Error: " + error);
		}
		if (user) {
			let deviceInfo = Device.getInfo();
			let userObject = user;
			//console.log({ userObject });
			userObject.deviceInfo = deviceInfo;
			await initAnalytics();
			await setPullingUpdates(true);
			await getRecentUpdatesFromAPI().then(async (recentUpdatesFromAPI) => {
				await processUpdates(recentUpdatesFromAPI, userObject).then(async () => {
					let numUpdates = parseInt(await storageService.getItem("recentUpdateCount"));
					console.log("numUpdates: " + numUpdates);
					if (numUpdates === "") {
						numUpdates = 0;
					}
					await setPullingUpdates(false);
				});
			});

			if (deviceInfo.platform === "android" || deviceInfo.platform === "ios") {
				await PushNotifications.removeAllDeliveredNotifications();
				await removeBadgesFromUserObject();
				await setupNotificationListener(history);
			}
			// Pre-load a bunch of info
			console.log("Preloading info");
			console.log("Getting user goal");
			await getGoal(null, true, null, null, true, true);
			console.log("Goal retrieved");
			for await (let squad of userObject.squads) {
				console.log("Getting user squad ID " + squad.id);
				await getSquad(squad.id, null, null, true);
				console.log("Getting user squad goal for squad " + squad.id);
				await getSquadGoal(squad.id, null, null, true, true);
			}
			return {
				success: true,
				userObject: userObject,
			};
		} else {
			console.log("No user object found entry screen");
			return "No user object found entry screen";
		}
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar style={{ "--border-style": "none" }}>
					<IonButtons slot="start">
						<IonBackButton text=" " color="primary" />
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonLoading isOpen={loggingIn.visible} onDidDismiss={() => setLoggingIn({ visible: false, message: "" })} message={loggingIn.message} />
				<IonLoading isOpen={validatingData.visible} onDidDismiss={() => setValidatingData({ visible: false, message: "" })} message={validatingData.message} />
				<div className="login-main-div">
					<p className="login-title">Forgot Password</p>
					<IonItem>
						<IonLabel className="login-label" position="stacked">
							Email
						</IonLabel>
						<IonInput ref={emailRef} name="email" type={"email"} placeholder="whoops@doh.com" enterkeyhint="next" required></IonInput>
					</IonItem>
					{resetPWError.visible === true ? (
						<p className="error-message" style={{ marginLeft: "20px", marginRight: "20px", marginTop: "10px" }}>
							{resetPWError.message}
						</p>
					) : (
						""
					)}
					{generalError.visible === true ? (
						<p className="error-message" style={{ marginLeft: "20px", marginRight: "20px", marginTop: "10px" }}>
							{generalError.message}
						</p>
					) : (
						""
					)}
					{emailSentNotice.visible === true ? (
						<React.Fragment>
							<p className="notification-message" style={{ marginLeft: "20px", marginRight: "20px", marginTop: "10px" }}>
								{emailSentNotice.message}
							</p>
							<IonItem>
								<IonLabel className="login-label" position="stacked">
									Enter Code
								</IonLabel>
								<IonInput ref={resetCodeRef} name="resetCode" placeholder="e.g. 842124" enterkeyhint="send" required></IonInput>
							</IonItem>
							<IonItem>
								<IonLabel className="login-label" position="stacked">
									New Password
								</IonLabel>
								<IonInput ref={passwordRef} name="newPassword" placeholder="min 8 characters" enterkeyhint="send" required></IonInput>
							</IonItem>
							{codeError.visible === true ? (
								<p className="error-message" style={{ marginLeft: "20px", marginRight: "20px", marginTop: "10px" }}>
									{codeError.message}
								</p>
							) : (
								""
							)}
							{passwordError.visible === true ? (
								<p className="error-message" style={{ marginLeft: "20px", marginRight: "20px", marginTop: "10px" }}>
									{passwordError.message}
								</p>
							) : (
								""
							)}
							<div style={{ marginTop: "10px" }}>
								<IonRow>
									<IonCol size="2"></IonCol>
									<IonCol size="8">
										<IonButton onClick={validateCode} expand="block">
											Reset Password
										</IonButton>
									</IonCol>
									<IonCol size="2"></IonCol>
								</IonRow>
								<IonRow>
									<IonCol size="2"></IonCol>
									<IonCol size="8">
										<IonButton onClick={forgotPW} color="primary" expand="block">
											Send Reset Code Again
										</IonButton>
									</IonCol>
									<IonCol size="2"></IonCol>
								</IonRow>
							</div>
						</React.Fragment>
					) : (
						<div>
							<IonRow>
								<IonCol size="2"></IonCol>
								<IonCol size="8">
									<IonButton onClick={forgotPW} expand="block">
										Send Reset Code
									</IonButton>
								</IonCol>
								<IonCol size="2"></IonCol>
							</IonRow>
						</div>
					)}
				</div>
			</IonContent>
		</IonPage>
	);
};
export default ForgotPassword;
