import { IonHeader, IonTitle, IonToolbar, IonButton, IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React from "react";
import "../css/Home.css";
import "../css/ActivityDetails.css";
import Emoji from "react-emoji-render";

const IntensityDescriptionComponent = ({ setShowIntensityModal }) => {
	return (
		<React.Fragment>
			<IonHeader>
				<IonToolbar className="activity-details-toolbar">
					<IonTitle>Intensity</IonTitle>
					<IonButton color="tertiary" slot="end" onClick={() => setShowIntensityModal(false)}>
						<IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
					</IonButton>
				</IonToolbar>
			</IonHeader>
			<div style={{ margin: "16px" }}>
				<p>
					Generally, you earn 1 point for 1 minute of activity. <br />
					<br />
					However, if your activity is more intense you can 1.5x or 2x points per minute.
					<br />
					<br />
					Here is guide for how to categorize your intensity:
					<br />
				</p>
				<p>
					<span style={{ fontWeight: "bold" }}>
						<Emoji text=":slightly_smiling_face:" /> Normal
					</span>
					<br />
					Something you could do for hours. Examples include walking, gardening, some yoga etc. <br />
					Less than 7 cal/min
					<br />
					<br />
					<span style={{ fontWeight: "bold" }}>
						<Emoji text=":sweat_smile:" /> Moderate
					</span>
					<br />
					Breathing heavily, becoming uncomfortable. Examples include lifting weights, hiking, casual cycling etc. <br />
					Approx. 7 - 11 cal/min.
					<br />
					<br />
					<span style={{ fontWeight: "bold" }}>
						<Emoji text=":sign_of_the_horns:" /> High
					</span>
					<br />
					Difficult to maintain, conversation not possible. Examples include HIIT, fast running, etc. <br />
					11+ cal/min
					<br />
					<br />
				</p>
			</div>
		</React.Fragment>
	);
};

export default IntensityDescriptionComponent;
