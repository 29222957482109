import { InAppPurchase2 as iap } from "@ionic-native/in-app-purchase-2";
import { IonButton, IonCard, IonCol, IonContent, IonFooter, IonHeader, IonIcon, IonPopover, IonRow, IonSpinner, IonToolbar } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../contexts/GlobalState";
import "../../css/Challenges.css";
import "../../css/Home.css";
import { cloudFrontURL } from "../../models/constants";
import { logPageView } from "../../services/AnalyticsService";
import { getAvailableChallengeByID } from "../../services/ChallengeService.ts";
import { sendSlackNotification } from "../../services/NotificationService";

const ChallengeSelection = ({ challengeID, showPurchaseModal, products, setPurchaseState }) => {
	const { userState } = useContext(AuthContext);
	// let challengeID = useParams().id;
	const [showToast, setShowToast] = useState(false);
	const [storeError, setStoreError] = useState(false);
	const [deviceInfo, setDeviceInfo] = useState(userState.deviceInfo);
	const [challengeDetails, setChallengeDetails] = useState();
	const [purchaseTier, setPurchaseTier] = useState(2);
	const [detailsWindow, setDetailsWindow] = useState({
		visible: false,
		item: null,
	});

	useEffect(() => {
		setupPage();
	}, []);

	const setupPage = async () => {
		let challengeDeets = await getChallenge(challengeID);
		console.log({ challengeDeets });
		logPageView("Challenge Selection " + challengeDeets.name);

		sendSlackNotification(
			userState.user.userName + " viewed Challenge Selection for " + challengeDeets.name + " Challenge (" + challengeDeets.id + ")",
			"challenges",
			false
		);
	};

	const selectPurchaseTier = async (tier) => {
		setPurchaseTier(tier);
		sendSlackNotification(userState.user.userName + " selected tier " + tier + " pricing option", "challenges", false);
	};

	//if user clicks purchase button
	const purchaseProduct = async () => {
		sendSlackNotification(userState.user.userName + " is attempting to purchase  " + purchaseTier + " pricing option", "challenges", false);
		// if (deviceInfo && deviceInfo.platform !== "web" && challengeTesterUsernames.includes(userState.user.userName) === false) {
		if (deviceInfo && deviceInfo.platform !== "web") {
			iap.order(products[purchaseTier]).then(() => {
				console.log("Order initiated");
				setShowToast({
					open: true,
					message: "Order initiated",
				});
				iap.when(products[purchaseTier]).approved((p) => {
					//store product
					console.log("Order approved");
					setShowToast({
						open: true,
						message: "Order approved",
					});

					p.verify();
					console.log("Order verified");
					setShowToast({
						open: true,
						message: "Order verified",
					});

					p.finish();
					console.log("(p) Product finished");
					console.log(JSON.stringify(p));
					setPurchaseState({
						success: true,
						tier: purchaseTier,
						transactionData: p.transactionData,
					});
					showPurchaseModal(false);
				});
			});
			iap.refresh();
		} else {
			console.log("Testing transactions");
			console.log("Product to purchase", JSON.stringify(products[purchaseTier]));
			setPurchaseState({
				success: true,
				tier: purchaseTier,
			});
			showPurchaseModal(false);
		}
	};

	const getChallenge = async (challengeID) => {
		let challenge = await getAvailableChallengeByID(challengeID);
		setChallengeDetails(challenge);
		return challenge;
	};

	return (
		<React.Fragment>
			{challengeDetails && products ? (
				<React.Fragment>
					<IonHeader>
						<IonToolbar className="details-toolbar" style={{ "--background": `${challengeDetails.metadata.primaryColor}` }}>
							<IonButton color={`${challengeDetails.metadata.textColor}`} slot="end" onClick={() => showPurchaseModal(false)}>
								<IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
							</IonButton>
						</IonToolbar>
					</IonHeader>
					<IonContent style={{ "--background": `${challengeDetails.metadata.primaryColor}` }}>
						<IonPopover
							isOpen={detailsWindow.visible}
							cssClass="exercise-details-full-screen-popover"
							onDidDismiss={(e) => {
								setDetailsWindow({ visible: false });
							}}
						>
							<DetailsPopover item={detailsWindow.item} />
						</IonPopover>
						<div className="onboarding-main-div">
							<div className="onboarding-title" style={{ color: `${challengeDetails.metadata.textColor}` }}>
								Choose your reward options
							</div>

							<React.Fragment>
								<IonCard onClick={() => selectPurchaseTier(2)} className={`${purchaseTier === 2 ? "selected-pricing-option" : "non-selected-pricing-option"}`}>
									<div className="pricing-tier-flag" style={{ backgroundColor: "gold" }}>
										GOLD TIER
									</div>
									<div className="most-popular-flag ">MOST POPULAR</div>
									<ShowRewards challengeDetails={challengeDetails} tier={3} />
									{products && products[2].price && storeError === false ? (
										<React.Fragment>
											<div className="pricing-text">{products[2].price}</div>
										</React.Fragment>
									) : (
										<React.Fragment>
											<div className="pricing-text">
												<IonSpinner />
											</div>
										</React.Fragment>
									)}
								</IonCard>
								<IonCard onClick={() => selectPurchaseTier(1)} className={`${purchaseTier === 1 ? "selected-pricing-option" : "non-selected-pricing-option"}`}>
									<div className="pricing-tier-flag" style={{ backgroundColor: "silver" }}>
										SILVER TIER
									</div>
									<ShowRewards challengeDetails={challengeDetails} tier={2} />
									{products && products[1].price && storeError === false ? (
										<React.Fragment>
											<div className="pricing-text">{products[1].price}</div>
										</React.Fragment>
									) : (
										<React.Fragment>
											<div className="pricing-text">
												<IonSpinner />
											</div>
										</React.Fragment>
									)}
								</IonCard>
								<IonCard onClick={() => selectPurchaseTier(0)} className={`${purchaseTier === 0 ? "selected-pricing-option" : "non-selected-pricing-option"}`}>
									<div className="pricing-tier-flag" style={{ backgroundColor: "sandybrown" }}>
										BRONZE TIER
									</div>
									<ShowRewards challengeDetails={challengeDetails} tier={1} />
									{products && products[0].price && storeError === false ? (
										<React.Fragment>
											<div className="pricing-text">{products[0].price}</div>
										</React.Fragment>
									) : (
										<React.Fragment>
											<div className="pricing-text">
												<IonSpinner />
											</div>
										</React.Fragment>
									)}
								</IonCard>
							</React.Fragment>
						</div>
					</IonContent>
					<IonFooter className="button-footer" style={{ backgroundColor: `${challengeDetails.metadata.primaryColor}` }}>
						<div style={{ width: "80%", marginLeft: "10%" }}>
							<IonButton expand="block" color="success" onClick={() => purchaseProduct()}>
								Confirm Rewards
							</IonButton>
						</div>
					</IonFooter>
				</React.Fragment>
			) : (
				""
			)}
		</React.Fragment>
	);
};

export default ChallengeSelection;

const DetailsPopover = ({ item }) => {
	// console.log({ item });
	return (
		<React.Fragment>
			<div style={{ textAlign: "center", marginTop: "10%" }}>
				{item.extendedDescriptionImage && item.extendedDescriptionImage !== "" ? (
					<img className="challenge-details-image" src={`${cloudFrontURL}/${item.extendedDescriptionImage}`} alt="" />
				) : (
					<img className="challenge-details-image" src={`${cloudFrontURL}/${item.icon}`} alt="" /> // more that just file name because they are generic and it's easier to read
				)}
			</div>
			<div style={{ margin: "10%" }}>
				{item.extendedDescription && item.extendedDescription !== "" ? (
					<div dangerouslySetInnerHTML={{ __html: item.extendedDescription }}></div>
				) : (
					<div dangerouslySetInnerHTML={{ __html: item.description }}></div>
				)}
			</div>
		</React.Fragment>
	);
};

const ShowRewards = ({ tier, challengeDetails }) => {
	return (
		<React.Fragment>
			{challengeDetails.metadata.rewards.map((details, i) => {
				if ((details.tier && details.tier <= tier) || !details.tier) {
					// if tier doesn't exist or the tier is >= the prop show stuff
					let size = challengeDetails.metadata.details.length - 1;
					return (
						<React.Fragment key={`${i}`}>
							<IonRow>
								<IonCol size="3">
									<img
										style={{ marginTop: i === 0 ? "2%" : "auto", marginBottom: i === size ? "5%" : "auto", height: "50px" }}
										src={`${cloudFrontURL}/${details.icon}`}
										alt=""
									/>
								</IonCol>
								<IonCol size="8">
									<div style={{ marginTop: i === 0 ? "2%" : "auto", marginBottom: i === size ? "5%" : "auto" }} className="details-description-div">
										<div dangerouslySetInnerHTML={{ __html: details.description }}></div>
									</div>
								</IonCol>
							</IonRow>
						</React.Fragment>
					);
				}
			})}
		</React.Fragment>
	);
};
