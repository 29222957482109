import { IonCard, IonCol, IonIcon, IonRow } from "@ionic/react";
import { checkmarkCircleSharp } from "ionicons/icons";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import "../../css/Challenges.css";
import "../../css/Home.css";
import { getChallengeDetailsByChallengeID } from "../../services/ChallengeService";

const ChallengeLedgerComponent = ({ challengeLedger }) => {
	const [sorted, setSorted] = useState(false);
	const [sortedLedger, setSortedLedger] = useState(null);

	useEffect(() => {
		console.log({ challengeLedger });

		if (challengeLedger) {
			let challengeLedgerRev = challengeLedger.reverse();
			console.log({ challengeLedgerRev });
			setSortedLedger(challengeLedgerRev);
		}
		setSorted(true);
	}, []);

	return (
		<React.Fragment>
			<IonCard className="no-shadow light-gray-border">
				{sorted === true &&
					sortedLedger.map((entry, i) => {
						let size = sortedLedger.length - 1;
						let backgroundColor, textColor;
						switch (parseInt(entry.transactionType)) {
							case 4: // unsuccessful - end
							case 5: // quit
							case 6: // unsuccessful - restart
								backgroundColor = "darkred";
								textColor = "white";
								break;
							case 3:
							case 8:
								backgroundColor = "darkgreen";
								textColor = "white";
								break;
							default:
								backgroundColor = "white";
								textColor = "#666666";
						}
						return (
							<React.Fragment key={`${i}`}>
								<IonRow style={{ background: `${backgroundColor}`, color: `${textColor}` }}>
									<IonCol size="2">
										<div
											style={{ display: "grid", textAlign: "center", marginTop: i === 0 ? "2%" : "auto", marginBottom: i === size ? "5%" : "auto" }}
											className="details-description-div"
										>
											{moment(entry.createdAt).format("MMM")}
											<br />
											{moment(entry.createdAt).format("Do")}
										</div>
									</IonCol>
									<IonCol size="2">
										<div style={{ marginTop: i === 0 ? "2%" : "auto", marginBottom: i === size ? "5%" : "auto" }} className="details-description-div">
											{entry.pointsEarned > 0 ? <span style={{ color: "green" }}>+{entry.pointsEarned}</span> : ""}
											{entry.pointsEarned === "0" ? <span style={{ color: "gray" }}>+{entry.pointsEarned}</span> : ""}
											{entry.pointsEarned < 0 ? <span style={{ color: "red" }}>-{entry.pointsEarned}</span> : ""}
										</div>
									</IonCol>
									<IonCol size="7">
										<div style={{ marginTop: i === 0 ? "2%" : "auto", marginBottom: i === size ? "5%" : "auto" }} className="details-description-div">
											{entry.summaryObject.description}
										</div>
									</IonCol>
								</IonRow>
								{size === i ? (
									""
								) : (
									<div>
										<hr className="details-separator" />
									</div>
								)}
							</React.Fragment>
						);
					})}
			</IonCard>
		</React.Fragment>
	);
};

export default ChallengeLedgerComponent;
