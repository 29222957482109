/* eslint-disable jsx-a11y/anchor-is-valid */
import { IonButton, IonContent, IonHeader, IonIcon, IonPage, IonPopover, IonToolbar, useIonViewDidEnter } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SeasonsCountdownTimer from "../components/Seasons/SeasonsCountdownTimer";
import SeasonsProgressGraphicComponent from "../components/Seasons/SeasonsProgressGraphicComponent";
import SeasonTerms from "../components/Seasons/SeasonTerms";

import "../css/Home.css";
import "../css/Profile.css";
import "../css/SalesPage.css";
import { logGenericClickEvent, logGenericEvent, logPageView } from "../services/AnalyticsService";
import { sendSlackNotification } from "../services/NotificationService";
import { hasSeasonStarted } from "../services/SeasonsService";
import { getUserNameOfLoggedInUser } from "../services/UserService";

const SeasonSalesPage = ({ setShowSeasonsModal, seasonsObject }) => {
	const [showRulesPopover, setShowRulesPopover] = useState(false);
	//console.log({ seasonsObject });
	const closeModal = () => {
		setShowSeasonsModal({ visible: false, seasonsObject: null });
	};

	useEffect(() => {
		sendNotification();
		logPageView("season-sales-page");
		logGenericEvent("View Season Sales Page");
	}, []);

	const sendNotification = async () => {
		let userName = await getUserNameOfLoggedInUser();
		sendSlackNotification("In app sales page viewed by " + userName, "keyEvent");
		console.log("Notification sent");
	};

	const logClickTop = () => {
		logGenericClickEvent("season-sales-page", "top-click-to-purchase");
	};

	const logClickBottom = () => {
		logGenericClickEvent("season-sales-page", "bottom-click-to-purchase");
	};

	return (
		<React.Fragment>
			<IonContent>
				<IonHeader>
					<IonToolbar>
						<IonButton color="secondary" slot="end" onClick={closeModal}>
							<IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
						</IonButton>
					</IonToolbar>
				</IonHeader>
				<IonPopover
					isOpen={showRulesPopover}
					cssClass="activity-full-screen-popover"
					onDidDismiss={(e) => {
						setShowRulesPopover(false);
					}}
				>
					<SeasonTerms />
				</IonPopover>
				<div className="sales-page-subtitle">Join the 10 week challenge!</div>
				{seasonsObject && hasSeasonStarted(seasonsObject) && hasSeasonStarted(seasonsObject) === false ? (
					<React.Fragment>
						<SeasonsCountdownTimer countdownDate={seasonsObject.startDate} />
						<div style={{ textAlign: "center", fontSize: "18px" }}>left until the season starts!</div>
					</React.Fragment>
				) : (
					<React.Fragment>
						<div style={{ textAlign: "center", fontSize: "18px" }}>The Season has started but you still have:</div>
						<br />
						<SeasonsCountdownTimer countdownDate={seasonsObject.registrationCloses} />
						<div style={{ textAlign: "center", fontSize: "18px" }}>left until registration closes!</div>
					</React.Fragment>
				)}

				<div className="sales-page-header-image">
					<img src="https://static-assets.fitsquadapp.com/seasons/summer-2021/intro-image.gif" alt="" />
				</div>
				<div className="sales-page-signup-button-div">
					<IonButton
						color="success"
						onClick={logClickTop}
						size="large"
						fill="solid"
						expand="block"
						className="sales-page-signup-button"
						href="https://fitsquadapp.com/products"
						target="_new"
					>
						Join the challenge
					</IonButton>
				</div>
				<div className="sales-page-body-text">
					Summer is a GREAT time to commit to hitting your activity goals. To help you do that, we're kicking off our first FitSquad season!
					<br />
					<br />A FitSquad Season is a <b>10 week challenge</b> where you and your squad will work to set and achieve weekly point goals.
					<br />
					<br />
					Just for signing up you'll earn some <b>great perks</b>. If you hit enough goals in 10 weeks and you'll earn <b>some sweet rewards!</b>🎁
					<br />
					<br />
					But hurry! The season kicks off soon and you wont want to miss out.
				</div>
				<div className="sales-page-section-header">Rewards</div>
				<div className="sales-page-section-sub-header">Exclusive Summer Season Medal</div>
				<div className="sales-page-image">
					<img src="https://static-assets.fitsquadapp.com/seasons/summer-2021/summer-2021-medal.png" alt="" style={{ height: "300px" }} />
				</div>
				<div className="sales-page-body-text">
					Celebrate your ability to be consistently active with the one-of-a-kind Summer 2021 FitSquad Medal! 🏅
					<br />
					<br />
					The medal is created out die cast metal and available ONLY for this season. Once the season is over, so is the opportunity to get this sweet bling!
				</div>
				<div className="sales-page-section-sub-header">Support a Special Olympics Athlete</div>
				<div className="sales-page-image">
					<img src="https://static-assets.fitsquadapp.com/seasons/summer-2021/special-olympics.jpg" alt="" style={{ height: "300px" }} />
				</div>
				<div className="sales-page-body-text">
					We're honored to partner with this season's title charity: the Special Olympics.
					<br />
					<br />
					The mission of Special Olympics is to provide year-round sports training and athletic competition in a variety of Olympic-type sports for children and
					adults with intellectual disabilities. <br />
					<br />
					By successfully completing your season, you'll help sponsor a Special Olympics athlete to compete. Your success is their success!
				</div>
				<div className="sales-page-section-sub-header">Mystery Bags</div>
				<div className="sales-page-image">
					<img src="https://static-assets.fitsquadapp.com/seasons/summer-2021/mystery-bag.jpg" alt="" style={{ height: "300px" }} />
				</div>
				<div className="sales-page-body-text">
					As a reward for a successful season you'll earn the opportunity to open a number of virtual "Mystery Bags". Each bag has a chance to have inside:
					<br />
					<br />
					<li>A bottle of wine ($20 value) delivered via Drizly</li>
					<li>A FitBit Charge 4</li>
					<li>An Amazon gift card ($25 value)</li>
					<li>A free entry into the Fall 2021 season</li>
					<li>Massage Envy gift card ($25 value)</li>
					<li>Personalized celebrity congrats via Cameo</li>
					<li>In-app digital goods (forgivness tokens, additional mystery bags, etc.)</li>
					<br />
				</div>
				<div className="sales-page-section-header">Perks</div>
				<div className="sales-page-section-sub-header">FREE FitSquad T-shirt</div>
				<div className="sales-page-image">
					<img src="https://static-assets.fitsquadapp.com/seasons/summer-2021/square-shirt-collage.jpg" alt="" />
				</div>
				<div className="sales-page-body-text">
					Just for signing up for the challenge we'll mail you a FREE FitSquad t-shirt in the cut of your choice.
					<br />
					<br />
					It's an incredibly soft and comfortable t-shirt perfect for earning points, whether through tough workouts 💪 or rejuvenating meditation 🧘‍♀️.
				</div>
				<div className="sales-page-section-sub-header">Goal Predictions</div>
				<div className="sales-page-image">
					<img src="https://static-assets.fitsquadapp.com/seasons/summer-2021/crystal-ball.jpg" alt="" />
				</div>
				<div className="sales-page-body-text">
					You're working hard towards your goals each week. But are you going to be successful?
					<br />
					<br />
					With this perk, you'll get a crystal ball, predicting based on your history if you and your squad are likely to succeed in given week or if you're
					likely to come up short.
				</div>
				<div className="sales-page-section-sub-header">Bonus Activity Points</div>
				<div className="sales-page-image">
					<img src="https://static-assets.fitsquadapp.com/seasons/summer-2021/bonus%20points.jpg" style={{ height: "300px" }} alt="" />
				</div>
				<div className="sales-page-body-text">
					With the Summer Season, you're committing to 10 weeks of hitting your activity goals. <br />
					<br />
					To help you with this, during the season, you'll be awarded bonus points for each activity you log. Just the little extra 💗 you'll need to be
					successful.
				</div>
				<div className="sales-page-section-sub-header">Daily Workouts</div>
				<div className="sales-page-image">
					<img src="https://static-assets.fitsquadapp.com/seasons/summer-2021/sparks-workout.jpg" style={{ width: "300px" }} alt="" />
				</div>
				<div className="sales-page-body-text">
					Not sure what you should do to earn points? We've got you covered!
					<br />
					<br />
					Every day you'll get a new, different daily workout to inspire you for what you should do to earn points and hit your goals
				</div>
				<div className="sales-page-section-sub-header">Forgiveness Token</div>
				<div className="sales-page-image">
					<img src="https://static-assets.fitsquadapp.com/seasons/summer-2021/forgivness-token.jpg" style={{ width: "300px" }} alt="" />
				</div>
				<div className="sales-page-body-text">
					Life is unpredictable. Sometimes, even with your best intentions, you can miss a week. <br />
					<br />
					But have no fear! Every season, you'll get a forgiveness token that will be automatically used if you happen to slip up.
					<br />
					<br />
					When the token is used, you'll get credit for a completed goal even if you didn't quite get there, and you'll keep your streak intact 🙌
				</div>
				<div className="sales-page-section-header">How it Works</div>
				<div style={{ height: "125px" }}>
					<SeasonsProgressGraphicComponent />
				</div>

				<div className="sales-page-body-text">
					Every week of the season you have the opportunity to earn <b>season points</b>. <br />
					<br />
					You earn <b>1 season point</b> if you complete <b>your weekly goal</b>
					<br />
					<br />
					You earn <b>1 season point</b> if your <b>squad completes their weekly squad goal</b>.
					<br />
					<br />
					If you earn <b>15 season points</b> before the end of the 10 weeks, you'll earn that season's rewards!
				</div>
				<div className="sales-page-body-text">
					<br />
					For more details please see{" "}
					<a className="link" onClick={() => setShowRulesPopover(true)}>
						the official rules
					</a>
				</div>
				<div className="sales-page-section-sub-header">So what are you waiting for?</div>
				<div className="sales-page-body-text">
					With these great perks and amazing rewards to keep motivated during the challenge, what are you waiting for? Sign up today!
				</div>
				<div className="sales-page-signup-button-div" style={{ marginBottom: "16px" }}>
					<IonButton
						color="success"
						onClick={logClickBottom}
						size="large"
						fill="solid"
						expand="block"
						className="sales-page-signup-button"
						href="https://fitsquadapp.com/products"
						target="_new"
					>
						Join the challenge
					</IonButton>
				</div>
			</IonContent>
		</React.Fragment>
	);
};

export default SeasonSalesPage;
