import { IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonLoading, IonContent } from "@ionic/react";
import React, { useState } from "react";
import "../../css/Home.css";
import "../../css/ActivityDetails.css";
import NewUserGoalComponent from "../../components/NewUserGoalComponent";

const NewUserGoal = () => {
	const [loading, setLoading] = useState(false);
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton text="" color="light" />
					</IonButtons>
					<IonTitle>New Goal</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonLoading isOpen={loading} onDidDismiss={() => setLoading(false)} message={"Calculating your goal"} backdropDismiss="true" />
			<IonContent>
				<NewUserGoalComponent />
			</IonContent>
		</IonPage>
	);
};

export default NewUserGoal;
