import React, { useEffect, useState } from "react";
import { IonAvatar, IonIcon, IonLoading, IonPopover, IonSkeletonText, IonSpinner, useIonViewDidEnter } from "@ionic/react";
import { CameraResultType, CameraSource, Plugins } from "@capacitor/core";
import "../../css/Profile.css";
import "../../css/Home.css";
import "../../css/SquadScoreboard.css";
import { updateSquad } from "../../services/SquadService";
import { createOutline } from "ionicons/icons";
import ChangeName from "../ChangeNameComponent";
import { logGenericClickEvent } from "../../services/AnalyticsService";
const { Camera } = Plugins;

const SquadMainAvatarComponent = ({ squadObject, goalObject, isAdmin }) => {
	console.log({ goalObject });
	const [squadData, setSquadData] = useState();
	const [updating, setUpdating] = useState(false);
	const [changeName, setChangeName] = useState(false);
	const [takenPhoto, setTakenPhoto] = useState({
		path: null,
		preview: null,
		url: null,
	});
	const takePhotoHandler = async () => {
		await Camera.getPhoto({
			allowEditing: false,
			correctOrientation: true,
			resultType: CameraResultType.DataUrl,
			source: CameraSource.Prompt,
			quality: 80,
			width: 500,
		}).then(async (photo) => {
			setUpdating(true);
			let body = {
				image: photo.dataUrl,
			};
			let result = await updateSquad(squadObject.id, body);
			setSquadData(result);
			setUpdating(false);
		});
	};

	useEffect(() => {
		if (squadObject) {
			setSquadData(squadObject);
		}
	}, [squadObject]);

	const navToSalesDetails = () => {
		logGenericClickEvent("profile", "nav-to-web-sales");
		window.open("http://fitsquadapp.com/products", "_new");
	};

	return (
		<React.Fragment>
			<IonLoading isOpen={updating} onDidDismiss={() => setUpdating(false)} message="Refreshing..." duration={1000} />
			<IonPopover isOpen={changeName} onDidDismiss={() => setChangeName(false)} cssClass="exercise-details-full-screen-popover">
				<ChangeName setChangeName={setChangeName} squadData={squadData} setSquadData={setSquadData} />
			</IonPopover>
			<div className="profile-main-avatar">
				<div className="avatar-div">
					{squadData && squadData.image ? (
						<React.Fragment>
							<IonAvatar className="squad-profile-avatar">
								{isAdmin === true ? (
									<img src={squadData.image} className="squad-profile-avatar-img" alt="" onClick={takePhotoHandler} />
								) : (
									<img src={squadData.image} className="squad-profile-avatar-img" alt="" />
								)}
							</IonAvatar>
						</React.Fragment>
					) : (
						<React.Fragment>
							<IonAvatar className="squad-profile-avatar">
								<IonSkeletonText />
							</IonAvatar>
						</React.Fragment>
					)}
					{squadData && squadData.image && squadData.image === null ? (
						<React.Fragment>
							<IonAvatar className="squad-profile-avatar">
								{isAdmin === true ? (
									<img
										src="https://fitsquad-ui-assets.s3-us-west-2.amazonaws.com/empty-avatar.png"
										className="squad-profile-avatar-img"
										alt=""
										onClick={takePhotoHandler}
									/>
								) : (
									<img src="https://fitsquad-ui-assets.s3-us-west-2.amazonaws.com/empty-avatar.png" className="squad-profile-avatar-img" alt="" />
								)}
							</IonAvatar>
						</React.Fragment>
					) : (
						""
					)}
					<div className="profile-username-stats">
						<div>
							<div className="username">
								{isAdmin === true ? (
									<React.Fragment>
										{squadData && squadData?.name ? <span>{squadData.name}</span> : <IonSpinner className="top-area-data-points-spinner" />}{" "}
										<IonIcon size="small" icon={createOutline} onClick={() => setChangeName(true)} />
									</React.Fragment>
								) : (
									<React.Fragment>
										{squadData && squadData?.name ? <span>{squadData.name}</span> : <IonSpinner className="top-area-data-points-spinner" />}
									</React.Fragment>
								)}

								{}
							</div>
							<div className="top-area-data-points">
								Goal:{" "}
								{goalObject && goalObject.goalPoints ? (
									<span className="top-area-data-points-bold">{goalObject.goalPoints}</span>
								) : (
									<IonSpinner className="top-area-data-points-spinner" />
								)}
							</div>
							<div className="top-area-data-points">
								Current:{" "}
								<span>
									{goalObject && goalObject.goalProgress ? (
										<span className="top-area-data-points-bold">{goalObject.goalProgress}</span>
									) : (
										<IonSpinner className="top-area-data-points-spinner" />
									)}
								</span>
							</div>
							<div className="top-area-data-points">
								%:{" "}
								<span>
									{goalObject && goalObject.goalPercent ? (
										<span className="top-area-data-points-bold">{goalObject.goalPercent}%</span>
									) : (
										<IonSpinner className="top-area-data-points-spinner" />
									)}
								</span>
							</div>
							<div className="top-area-data-points">
								Prediction:{" "}
								{goalObject && goalObject.prediction === "upgrade" ? (
									<div style={{ display: "flex" }} onClick={navToSalesDetails}>
										<div className="top-area-data-points-bold upgrade-text-button" style={{ backgroundColor: "green" }}>
											{goalObject.prediction.toLocaleString()}
										</div>
									</div>
								) : (
									""
								)}
								{goalObject && goalObject.prediction !== "upgrade" ? (
									<div style={{ display: "flex" }}>
										<div className="top-area-data-points-bold">{goalObject.prediction.toLocaleString()}</div>
										{parseInt(goalObject.goalProgress) >= parseInt(goalObject.goalPoints) ? (
											<img
												src="assets/images/trophy_transparent.gif"
												alt=""
												style={{ height: "13px", position: "relative", left: "-8px", top: "-5px", paddingLeft: "10px" }}
											/>
										) : (
											<span style={{ left: "2px", backgroundColor: `${goalObject.iconColor}`, top: "0px" }} className="dot"></span>
										)}
									</div>
								) : (
									""
								)}
								{!goalObject ? <IonSpinner className="top-area-data-points-spinner" /> : ""}
							</div>
							<div className="top-area-data-points">
								Streak:{" "}
								<span>
									{squadData && squadData?.goalStreak !== null && squadObject.goalStreak > 0 ? (
										<span className="top-area-data-points-bold">{squadData.emojiStreak}</span>
									) : (
										<span style={{ textTransform: "lowercase" }}>Let's get one started!</span>
									)}
								</span>
							</div>
						</div>
					</div>
				</div>
				{/** 
				<div>
					<div>
						Level: {userObject && userObject.level !== null ? <span>{userObject.level}</span> : "1"}
						<br />
						HealthPoints: {userObject && userObject.healthPoints !== null ? <span>{userObject.healthPoints.toLocaleString()}</span> : "0"}
						<br />
						Current streak: {userObject && userObject.goalStreak !== null ? <span>{userObject.goalStreak} weeks</span> : "No streak - let's get one started!"}
					</div>
				</div>
                */}
			</div>
		</React.Fragment>
	);
};

export default SquadMainAvatarComponent;
