import moment from "moment";
import { HealthKit } from "@ionic-native/health-kit";
import { convertCalsToCredits } from "./HelperServices";
import { getIDToken } from "./AuthService";
import { getPlatforms } from "@ionic/react";
import { API_URL } from "../models/constants";
import { StorageService } from "../services/StorageService";
const storageService = new StorageService();

export const loadWorkoutData = async () => {
	let workoutArray =[]
	await HealthKit.findWorkouts().then(
		(data) => {
			//console.log("Workouts: ", data);
			//let workouts = data;
		},
		(err) => {
			//console.log("error workout: ", err);
			if (err !== "") {
				
				err.forEach((element, index) => {
					if(moment(element.endDate) > moment().subtract(6,"weeks")){
						//console.log("Index: ",index);
						//console.log("Workout info: ",err[index]);
						workoutArray.push(element)
					}						
				});					
			}
		}
	);
	return workoutArray	
}

export const loadStepsData = async (startDateMoment = moment().startOf("day"), endDateMoment = moment()) => {
	let stepSum = 0;
	var stepOptions = {
		startDate: startDateMoment.toDate(),
		//startDate:  moment().subtract(15, 'minutes').valueOf,
		endDate: endDateMoment.toDate(),
		unit: "count",
		sampleType: "HKQuantityTypeIdentifierStepCount",
	};
	//console.log("stepOptions: ",stepOptions);

	let data = await HealthKit.querySampleType(stepOptions)
	return data;
};

export const loadAppleExerciseTime = async () => {
	let exerciseTime = 0;
	let day = 0
	let maxDays = 30
	while (day < maxDays) {
		var exerciseOptions = {
			startDate: moment().startOf("day").subtract(day,"days").toDate(),
			endDate: moment().endOf("day").subtract(day,"days").toDate(),
			unit: "min",
			sampleType: "HKQuantityTypeIdentifierAppleExerciseTime",
		};
		//console.log("stepOptions: ",stepOptions);
	
		await HealthKit.querySampleType(exerciseOptions)
			.then((data) => {
				exerciseTime = exerciseTime + data.reduce((a, b) => a + b.quantity, 0);
				console.log("Sum of minutes: " + exerciseTime);
				//console.log("Steps data: ",data);
				//console.log("Sum of steps: ",stepsPast24hours);
			})
			.catch((err) => {
				console.log("error steps: ", err);
			});
		day++
	}
	return exerciseTime;
};

export const loadCalorieData = async (startDate = moment().startOf("day").toDate(), endDate = moment().toDate()) => {
	let calSum = 0;
	console.log("StartDate set: " + startDate);
	console.log("EndDate set: " + endDate);
	let calorieOptions = {
		startDate: startDate,
		endDate: endDate,
		unit: "kcal",
		limit: 100000,
		sampleType: "HKQuantityTypeIdentifierActiveEnergyBurned",
	};
	//console.log("calorieOptions: ",calorieOptions);

	await HealthKit.querySampleType(calorieOptions).then(
		(data) => {
			//console.log("Data object: "+JSON.stringify(data));
			calSum = data.reduce((a, b) => a + b.quantity, 0);
			console.log("Sum of cals: " + calSum);
		},
		(err) => {
			console.log("error query calories: ", err);
		}
	);
	return Math.round(calSum);
};
export const loadSumCalorieData = async (startDate = moment().startOf("day"), endDate = moment(), userWeightInKG = 87, returnCredits = false) => {
	const platforms = getPlatforms();
	var calSum = 0;
	console.log("StartDate set: " + startDate.format());
	var calorieOptions = {
		startDate: startDate.toDate(),
		//startDate:  moment().subtract(15, 'minutes').toDate(),
		endDate: endDate.toDate(), // basically right now
		unit: "kcal",
		sampleType: "HKQuantityTypeIdentifierActiveEnergyBurned",
	};
	//console.log("calorieOptions: ",calorieOptions);

	await HealthKit.sumQuantityType(calorieOptions).then(
		(data) => {
			calSum = data;
			console.log("Sum of cals for dates " + startDate.format("YYYY-MM-DD") + " - " + endDate.format("YYYY-MM-DD") + ": " + calSum);
		},
		(err) => {
			console.log("error in sum quantity: ", err);
		}
	);
	if (platforms.includes("mobileweb")) {
		console.log("Populating local with fake data");
		calSum = 1000 * (endDate.diff(startDate, "days") + 1);
	}
	if (returnCredits === true) {
		console.log("Converting to credits");
		calSum = convertCalsToCredits(calSum, userWeightInKG);
	}
	return calSum;
};

export async function getWeight(refresh = true) {
	let userWeight = "";
	if (refresh === false) {
		// if refresh = false return value from local storage
		userWeight = await storageService.getItem("userWeight");
		if (userWeight !== null && userWeight !== undefined && userWeight !== "") {
			return userWeight;
		}
	}
	var weightOptions = {
		unit: "kg",
	};
	console.log("Getting weight");

	await HealthKit.readWeight(weightOptions).then(
		(data) => {
			userWeight = data;
			console.log("Weight object from HK: " + userWeight);
			userWeight = userWeight["value"]
			console.log("Weight value from HK: " + userWeight);
		},
		(err) => {
			userWeight = 87;
			console.log("error in getting weight: ", err, ". Using base value of ", userWeight);
		}
	);
	return userWeight;
}
export const loadSumCalorieDataServer = async (startDate = moment().startOf("day"), endDate = moment(), returnCredits = false) => {
	//TODO: Update to use activities/summary API
	console.log("StartDate set: " + startDate);
	let idToken = await getIDToken().catch((error) => {
		throw Error("Error in getIDtoken: " + error);
	});
	console.log("[JS] token is good to go");
	const url = API_URL;
	//console.log('[JS] id token passed to API:', idToken)
	/*
	const response = await Promise.resolve(
		fetch(url + "healthkit?startDate=" + startDate.format("YYYY-MM-DD") + "&endDate=" + endDate.format("YYYY-MM-DD"), {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
  );
  */
	const response = await Promise.resolve(
		fetch(url + "healthkit?startDate=" + startDate.format("YYYY-MM-DD") + "&endDate=" + endDate.format("YYYY-MM-DD"), {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	);
	let serverResponse = await response.json().catch((error) => {
		throw Error("Error in parsing healthKit response: " + error);
	});
	console.log("[JS] [loadSumCalorieDataServer] Server Response:" + JSON.stringify(serverResponse));
	return serverResponse;
};

export const loadSumStepsData = async (startDate = null, endDate = null) => {
	var stepSum;
	console.log("StartDate passed to loadSumStepsData: " + startDate);
	if (startDate === null) {
		startDate = moment().startOf("day").toDate();
	}
	if (endDate === null) {
		endDate = moment().toDate();
	}
	console.log("StartDate set: " + startDate);
	let stepOptions = {
		startDate: startDate.toDate(),
		endDate: endDate.toDate(), // basically right now
		unit: "count",
		sampleType: "HKQuantityTypeIdentifierStepCount",
	};

	await HealthKit.sumQuantityType(stepOptions).then(
		(data) => {
			stepSum = data;
			console.log("Sum of steps: " + stepSum);
		},
		(err) => {
			console.log("error in step sum quantity: ", err);
		}
	);
	return stepSum;
};

export const pullStepsByDayLast8days = async () => {
	let daysChecked = 0;
	let daysToCheck = 8;
	let stepsArray = [];
	while (daysChecked < daysToCheck) {
		let startDate = moment().startOf("day").subtract(daysChecked, "days");
		let endDate = moment().endOf("day").subtract(daysChecked, "days");
		console.log("Checking " + startDate.format());
		let tempStepsArray = [];
		tempStepsArray = await loadSumStepsData(startDate, endDate); // should just output to console
		//console.log({ tempStepsArray });
		if (tempStepsArray > 0) {
			stepsArray.push({
				"dataType": "dailySteps",
				"value": tempStepsArray,
				"startDate": startDate.format("YYYY-MM-DD")
			}) 
		}		
		daysChecked++;
	}
	console.log({ stepsArray });
	return stepsArray
};

export const pullStepsByDay = async (startMoment, endMoment) => {
	let daysChecked = 0;
	let daysToCheck = endMoment.diff(startMoment, 'days');
	let stepSum = 0
	while (daysChecked < daysToCheck) {
		let startDate = moment().startOf("day").subtract(daysChecked, "days");
		let endDate = moment().endOf("day").subtract(daysChecked, "days");
		console.log("Checking " + startDate.format());
		let tempSteps;
		tempSteps = await loadSumStepsData(startDate, endDate); // should just output to console
		console.log("Daily Steps: "+tempSteps);
		stepSum = stepSum + tempSteps
		console.log("Total Steps: "+stepSum);
		daysChecked++;
	}
	return stepSum
};

export const convertHKActivityToReadable = (hkname) => {
        switch (hkname) {
        case "HKWorkoutActivityTypeAmericanFootball":             return "American Football"
        case "HKWorkoutActivityTypeArchery":                      return "Archery"
        case "HKWorkoutActivityTypeAustralianFootball":           return "Australian Football"
        case "HKWorkoutActivityTypeBadminton":                    return "Badminton"
        case "HKWorkoutActivityTypeBaseball":                     return "Baseball"
        case "HKWorkoutActivityTypeBasketball":                   return "Basketball"
        case "HKWorkoutActivityTypeBowling":                      return "Bowling"
        case "HKWorkoutActivityTypeBoxing":                       return "Boxing"
        case "HKWorkoutActivityTypeClimbing":                     return "Climbing"
        case "HKWorkoutActivityTypeCrossTraining":                return "Cross Training"
        case "HKWorkoutActivityTypeCurling":                      return "Curling"
        case "HKWorkoutActivityTypeCycling":                      return "Cycling"
        case "HKWorkoutActivityTypeDance":                        return "Dance"
        case "HKWorkoutActivityTypeDanceInspiredTraining":        return "Dance Inspired Training"
        case "HKWorkoutActivityTypeElliptical":                   return "Elliptical"
        case "HKWorkoutActivityTypeEquestrianSports":             return "Equestrian Sports"
        case "HKWorkoutActivityTypeFencing":                      return "Fencing"
        case "HKWorkoutActivityTypeFishing":                      return "Fishing"
        case "HKWorkoutActivityTypeFunctionalStrengthTraining":   return "Functional Strength Training"
        case "HKWorkoutActivityTypeGolf":                         return "Golf"
        case "HKWorkoutActivityTypeGymnastics":                   return "Gymnastics"
        case "HKWorkoutActivityTypeHandball":                     return "Handball"
        case "HKWorkoutActivityTypeHiking":                       return "Hiking"
        case "HKWorkoutActivityTypeHockey":                       return "Hockey"
        case "HKWorkoutActivityTypeHunting":                      return "Hunting"
        case "HKWorkoutActivityTypeLacrosse":                     return "Lacrosse"
        case "HKWorkoutActivityTypeMartialArts":                  return "Martial Arts"
        case "HKWorkoutActivityTypeMindAndBody":                  return "Mind and Body"
        case "HKWorkoutActivityTypeMixedMetabolicCardioTraining": return "Mixed Metabolic Cardio Training"
        case "HKWorkoutActivityTypePaddleSports":                 return "Paddle Sports"
        case "HKWorkoutActivityTypePlay":                         return "Play"
        case "HKWorkoutActivityTypePreparationAndRecovery":       return "Preparation and Recovery"
        case "HKWorkoutActivityTypeRacquetball":                  return "Racquetball"
        case "HKWorkoutActivityTypeRowing":                       return "Rowing"
        case "HKWorkoutActivityTypeRugby":                        return "Rugby"
        case "HKWorkoutActivityTypeRunning":                      return "Running"
        case "HKWorkoutActivityTypeSailing":                      return "Sailing"
        case "HKWorkoutActivityTypeSkatingSports":                return "Skating Sports"
        case "HKWorkoutActivityTypeSnowSports":                   return "Snow Sports"
        case "HKWorkoutActivityTypeSoccer":                       return "Soccer"
        case "HKWorkoutActivityTypeSoftball":                     return "Softball"
        case "HKWorkoutActivityTypeSquash":                       return "Squash"
        case "HKWorkoutActivityTypeStairClimbing":                return "Stair Climbing"
        case "HKWorkoutActivityTypeSurfingSports":                return "Surfing Sports"
        case "HKWorkoutActivityTypeSwimming":                     return "Swimming"
        case "HKWorkoutActivityTypeTableTennis":                  return "Table Tennis"
        case "HKWorkoutActivityTypeTennis":                       return "Tennis"
        case "HKWorkoutActivityTypeTrackAndField":                return "Track and Field"
        case "HKWorkoutActivityTypeTraditionalStrengthTraining":  return "Traditional Strength Training"
        case "HKWorkoutActivityTypeVolleyball":                   return "Volleyball"
        case "HKWorkoutActivityTypeWalking":                      return "Walking"
        case "HKWorkoutActivityTypeWaterFitness":                 return "Water Fitness"
        case "HKWorkoutActivityTypeWaterPolo":                    return "Water Polo"
        case "HKWorkoutActivityTypeWaterSports":                  return "Water Sports"
        case "HKWorkoutActivityTypeWrestling":                    return "Wrestling"
        case "HKWorkoutActivityTypeYoga":                         return "Yoga"

        // iOS 10
        case "HKWorkoutActivityTypeBarre":                        return "Barre"
        case "HKWorkoutActivityTypeCoreTraining":                 return "Core Training"
        case "HKWorkoutActivityTypeCrossCountrySkiing":           return "Cross Country Skiing"
        case "HKWorkoutActivityTypeDownhillSkiing":               return "Downhill Skiing"
        case "HKWorkoutActivityTypeFlexibility":                  return "Flexibility"
        case "HKWorkoutActivityTypeHighIntensityIntervalTraining":    return "High Intensity Interval Training"
        case "HKWorkoutActivityTypeJumpRope":                     return "Jump Rope"
        case "HKWorkoutActivityTypeKickboxing":                   return "Kickboxing"
        case "HKWorkoutActivityTypePilates":                      return "Pilates"
        case "HKWorkoutActivityTypeSnowboarding":                 return "Snowboarding"
        case "HKWorkoutActivityTypeStairs":                       return "Stairs"
        case "HKWorkoutActivityTypeStepTraining":                 return "Step Training"
        case "HKWorkoutActivityTypeWheelchairWalkPace":           return "Wheelchair Walk Pace"
        case "HKWorkoutActivityTypeWheelchairRunPace":            return "Wheelchair Run Pace"

        // iOS 11
        case "HKWorkoutActivityTypeTaiChi":                       return "Tai Chi"
        case "HKWorkoutActivityTypeMixedCardio":                  return "Mixed Cardio"
        case "HKWorkoutActivityTypeHandCycling":                  return "Hand Cycling"

        // iOS 13
        case "HKWorkoutActivityTypeDiscSports":                   return "Disc Sports"
        case "HKWorkoutActivityTypeFitnessGaming":                return "Fitness Gaming"
        case "HKWorkoutActivityTypeCoolDown":                return "Cool Down"

        // Catch-all
        default:                            return "Other"
        }
}
