import {
	IonAlert,
	IonAvatar,
	IonBackButton,
	IonButton,
	IonButtons,
	IonChip,
	IonCol,
	IonContent,
	IonHeader,
	IonItem,
	IonLabel,
	IonLoading,
	IonPage,
	IonRefresher,
	IonRefresherContent,
	IonRow,
	IonSpinner,
	IonTitle,
	IonToast,
	IonToolbar,
	useIonViewDidEnter,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { loadingMessages } from "../../models/constants";
import { logGenericEvent } from "../../services/AnalyticsService";
import { getRandomValueFromArray } from "../../services/HelperServices";
import { sendSlackNotification } from "../../services/NotificationService";
import { getAllSquads, getSquad } from "../../services/SquadService";
import { acceptUserToSquad, deleteUserFromSquad, getUser, getUserID } from "../../services/UserService";
import AuthContext from "../../contexts/GlobalState";

const SquadsMember = ({ squadsMember, setSquadsMember, getKeyObjects }) => {
	const history = useHistory();
	const { userState, updateUserState } = useContext(AuthContext);
	const [updating, setUpdating] = useState(false);
	const [quitSquadToast, setQuitSquadToast] = useState(false);
	const [quitSquadAlert, setQuitSquadAlert] = useState({
		visible: false,
		squadID: null,
		squadName: null,
	});

	const quitSquad = async (squadID) => {
		setQuitSquadToast(true);
		let userID = userState.user.cognitoId;
		setUpdating(true);
		await deleteUserFromSquad(userID, squadID).then(async (response) => {
			let tempPendingRequests = squadsMember;
			tempPendingRequests = tempPendingRequests.filter((request) => {
				return request.userId !== userID && request.squadId !== squadID;
			});
			let user = JSON.parse(await getUser(userID));
			let squad = await getSquad(squadID);
			logGenericEvent("User quit squad");
			sendSlackNotification("*User Quit Squad*\nUser: " + user.userName + "\nSquad Name: " + squad.name + "\n", "creation");
			setSquadsMember(tempPendingRequests);
			await updateUserState();
			setUpdating(false);
		});
	};
	return (
		<div>
			<IonLoading isOpen={updating} onDidDismiss={() => setUpdating(false)} message="Refreshing..." duration={1000} />
			<IonToast isOpen={quitSquadToast} onDidDismiss={() => setQuitSquadToast(false)} message="Quitting squad" duration={2000} />
			<IonAlert
				isOpen={quitSquadAlert.visible}
				header="You sure?"
				message={`Are you sure you would like to quit ${quitSquadAlert.squadName}?`}
				buttons={[
					{
						text: "No",
						role: "cancel",
						handler: () => {
							setQuitSquadAlert({ visible: false, squadID: null, squadName: null });
						},
					},
					{
						text: "Yes",
						role: "ok",
						handler: () => {
							quitSquad(quitSquadAlert.squadID);
						},
					},
				]}
				onDidDismiss={() => setQuitSquadAlert({ visible: false, squadID: null, squadName: null })}
			/>
			{squadsMember.map((squad, i) => {
				//console.log({ squad });
				return (
					<IonRow key={`${i}`} className="squad-search-row">
						<IonCol size="3" className="search-squad-image-column" onClick={() => history.push("/squad/" + squad.squadId)}>
							<img src={squad.squad.image} className="search-squad-image" alt="" />
						</IonCol>
						<IonCol size="6" onClick={() => history.push("/squad/" + squad.squadId)}>
							<div className="search-squad-name">{squad.squad.name}</div>
							<div className="search-squad-slogan">{squad.squad.slogan}</div>
							<IonRow>
								{squad.squad.tags &&
									squad.squad.tags.map((tag, i) => {
										return (
											<IonChip key={`${i}`} outline="false" color="tertiary">
												<IonLabel style={{ fontSize: "10px" }}>{tag.tag}</IonLabel>
											</IonChip>
										);
									})}
							</IonRow>
						</IonCol>
						<IonCol size="3">
							<IonButton
								color="danger"
								size="small"
								className="search-squad-button"
								onClick={() => setQuitSquadAlert({ visible: true, squadID: squad.squadId, squadName: squad.squad.name })}
							>
								<span style={{ fontSize: "11px" }}>Quit Squad</span>
							</IonButton>
						</IonCol>
					</IonRow>
				);
			})}
		</div>
	);
};

export default SquadsMember;
