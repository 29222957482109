import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButton,
	useIonViewDidEnter,
	IonLoading,
	IonRefresher,
	IonRefresherContent,
	IonIcon,
	IonButtons,
	IonBackButton,
	IonRow,
	IonChip,
	IonLabel,
	IonCol,
	IonToast,
	IonAlert,
	IonToggle,
	IonPopover,
} from "@ionic/react";
import React, { useContext, useState } from "react";
import "../css/Home.css";
import { StorageService } from "../services/StorageService";
import "../css/Profile.css";
import { syncOutline } from "ionicons/icons";
import { getSquad, getSquadGoal, isUserASquadAdmin, updateSquad } from "../services/SquadService";
import { useParams } from "react-router";
import { logPageView } from "../services/AnalyticsService";
import SquadMainAvatarComponent from "../components/Profile/SquadMainAvatarComponent";
import SquadMembersComponent from "../components/Profile/SquadMembersComponent";
import SquadWeeklyStreaksComponent from "../components/Profile/SquadWeeklyStreaksComponent";
import SquadWeeklyProgressComponent from "../components/Profile/SquadWeeklyProgressComponent";

import { getSquadPrediction } from "../services/PointsAndGoalsService";
import { sendSlackNotification } from "../services/NotificationService";
import { deleteSquad } from "../services/SquadService";
import { getUser, getUserID } from "../services/UserService";
import { useHistory } from "react-router";
import { addSeasonEmojiToUsername, getCurrentSeasons, hasSeasonStarted, isUserinSeason } from "../services/SeasonsService";
import { convertStreakToEmoji } from "../services/HelperServices";
import moment from "moment";
import AuthContext from "../contexts/GlobalState";

function SquadDetails() {
	const { updateUserState } = useContext(AuthContext);

	const squadID = useParams().squadID;
	const history = useHistory();
	console.log("squadID: " + squadID);
	// Loading states
	const [loading, setLoading] = useState();
	const [squadObject, setSquadObject] = useState();
	const [goalObject, setGoalObject] = useState();
	const [isAdmin, setIsAdmin] = useState();

	const [squadMembers, setSquadMembers] = useState([]);
	const [showDeletedToast, setShowDeletedToast] = useState(false);
	const [confirmDelete, setConfirmDelete] = useState({
		visible: false,
		squadName: null,
		squadID: null,
	});
	const storageService = new StorageService();

	useIonViewDidEnter(async () => {
		logPageView("squad-details");
		doRefresh(null, false);
		let user = JSON.parse(await getUser());
		setIsAdmin(await isUserASquadAdmin(squadID, user));
	});

	const doRefresh = async (event, refresh = true) => {
		let squadObj = await getSquad(squadID, null, null, refresh);
		squadObj = convertStreakToEmoji(squadObj);
		setSquadObject(squadObj);
		getSquadMembers(squadObj);
		getGoalObject(refresh);
	};

	const delSquad = async () => {
		deleteSquad(confirmDelete.squadID);
		setShowDeletedToast(true);
		setConfirmDelete({ visible: false, squadName: null, squadID: null });
		await updateUserState();
		history.goBack();
	};

	const getSquadMembers = async (squadObject) => {
		let squadArray = await getSquad(squadObject.id);
		let membersArray = squadArray.members;
		let members = [];
		for await (let element of membersArray) {
			console.log({ element });
			if (element.accepted === true && element.user.userType !== "squadRoboCoach" && element.user.userType !== "retiredSquadRoboCoach") {
				let tempUserIDArray = [];
				let user = JSON.parse(await getUser(element.userId, true)); // doing this to get the season object
				console.log({ user });
				tempUserIDArray.userID = element.userId;
				tempUserIDArray.userName = user.userName;
				if (user.avatar === undefined) {
					tempUserIDArray.avatar = "https://fitsquad-ui-assets.s3-us-west-2.amazonaws.com/empty-avatar.png";
				} else {
					tempUserIDArray.avatar = user.avatar;
				}
				members.push(tempUserIDArray);
			} else {
				// Consider showing something else if they haven't accepted
			}
		}
		//console.log({ members });
		setSquadMembers(members);
	};

	const getGoalObject = async (refresh = false) => {
		let goalObject = await getSquadGoal(squadID, null, null, true, true, refresh).catch((error) => {
			return;
		});
		if (goalObject === null) {
			console.log("Goal is null");
			let squad = await getSquad(squadID, null, null, false);
			if (moment().format("ddd") !== "Mon") {
				// only send an error if it's not monday
				sendSlackNotification(
					`*NEW KEY ERROR*\n*Error:* Null Goal Object\n*Squad ID:* ${squadID}\n*Squad Name:* ${squad.name}\n*Page:* Squad Details`,
					"error"
				);
			}
			goalObject = {};
			goalObject.goalProgress = "-";
			goalObject.goalPoints = "Set on Tuesdays";
			goalObject.goalPercent = "-";
			goalObject.prediction = "-";
		} else {
			let gp;
			if (goalObject !== null) {
				gp = Math.round((goalObject.goalProgress / goalObject.goalPoints) * 100);
			} else {
				gp = 0;
			}
			goalObject.goalCompleted = false;
			goalObject.goalPercent = gp;
			let user = JSON.parse(await getUser(null, false));
			let seasonStarted = await hasSeasonStarted();
			let userInSeason = await isUserinSeason(user);
			//seasonStarted = true;
			//userInSeason = false;
			let showUpgradeIndicator = seasonStarted === true && userInSeason === false ? true : false;

			if (showUpgradeIndicator === true) {
				goalObject.prediction = "upgrade";
				goalObject.iconColor = "blue";
			}
			if (showUpgradeIndicator === false) {
				if (!goalObject.prediction) {
					goalObject.prediction = await getSquadPrediction(squadID);
				}
				let squadPredictionPoints = goalObject.prediction;
				let squadPredictionPercent = Math.round((squadPredictionPoints / goalObject.goalPoints) * 100);
				if (goalObject.goalProgress >= goalObject.goalPoints) {
					goalObject.iconColor = "green";
					goalObject.goalCompleted = true;
				} else if (squadPredictionPercent >= 100) {
					goalObject.iconColor = "green";
				} else if (squadPredictionPercent >= 90) {
					goalObject.iconColor = "yellow";
				} else if (squadPredictionPercent < 90) {
					goalObject.iconColor = "red";
				}

				goalObject.goalProgress = goalObject.goalProgress.toLocaleString();
				goalObject.goalPoints = goalObject.goalPoints.toLocaleString();
				goalObject.goalPercent = goalObject.goalPercent.toLocaleString();
				goalObject.prediction = goalObject.prediction.toLocaleString();
				let startOfIsoWeek = moment().startOf("isoWeek").format();
				let squad = await getSquad(squadID, null, null, false);
				let squadCreatedStartOfIsoWeek = moment(squad.createdAt).startOf("isoWeek").format();
				if (startOfIsoWeek === squadCreatedStartOfIsoWeek) {
					goalObject.prediction = "TBD";
					goalObject.iconColor = "white";
				}
				//console.log({ goalObject });
			}
		}
		setGoalObject(goalObject);
	};

	const clearStorage = async () => {
		setLoading(true);
		storageService.removeItem("squadGoal-" + squadID);
		storageService.removeItem("squadPointHistory-" + squadID);
		await doRefresh(null, true);
		setLoading(false);
	};

	const togglePrivate = async () => {
		setLoading(true);
		let body;
		if (squadObject.isPrivate === true) {
			body = {
				isPrivate: false,
			};
		} else {
			body = {
				isPrivate: true,
			};
		}
		await updateSquad(squadObject.id, body)
			.catch((error) => {
				console.log("Error updating privacy toggle: " + { error });
			})
			.then(async () => {
				let squad = await getSquad(squadObject.id, null, null, true);
				setSquadObject(squad);
				setLoading(false);
			});
	};

	const toggleVisible = async () => {
		setLoading(true);
		let body;
		if (squadObject.showInSearch === true) {
			body = {
				showInSearch: false,
			};
		} else {
			body = {
				showInSearch: true,
			};
		}
		await updateSquad(squadObject.id, body)
			.catch((error) => {
				console.log("Error updating visible toggle: " + { error });
			})
			.then(async () => {
				let squad = await getSquad(squadObject.id, null, null, true);
				setSquadObject(squad);
				setLoading(false);
			});
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
					<IonButton color="secondary" slot="end" onClick={clearStorage}>
						<IonIcon slot="icon-only" icon={syncOutline}></IonIcon>
					</IonButton>
					<IonTitle>Squad Profile</IonTitle>
				</IonToolbar>
				{isAdmin === true && squadObject ? (
					<React.Fragment>
						<IonRow className="page-info-container" style={window.innerWidth <= 320 ? { fontSize: "0.75em", paddingTop: "0px" } : { paddingTop: "0px" }}>
							<IonCol size="6" className="squad-management-column">
								<IonButton color="primary" size="small" className="squad-management-button" routerLink={`/squadcreatecomplete/${squadObject.id}`}>
									Invite New Squaddies
								</IonButton>
							</IonCol>
							<IonCol size="6" className="squad-management-column">
								<IonButton
									size="small"
									className="squad-management-button"
									color="danger"
									onClick={() => setConfirmDelete({ visible: true, squadName: squadObject.name, squadID: squadObject.id })}
								>
									Delete Squad
								</IonButton>
							</IonCol>
						</IonRow>
					</React.Fragment>
				) : (
					""
				)}
			</IonHeader>
			<IonContent>
				<IonToast isOpen={showDeletedToast} onDidDismiss={() => setShowDeletedToast(false)} message="Squad Deleted" duration={2000} />
				<IonAlert
					isOpen={confirmDelete.visible}
					header="You sure?"
					message={`Are you SURE you would like to delete the squad ${confirmDelete.squadName}?`}
					buttons={[
						{
							text: "No",
							role: "cancel",
							handler: () => {
								setConfirmDelete({ visible: false, squadName: null, squadID: null });
							},
						},
						{
							text: "Yes",
							role: "ok",
							handler: () => {
								delSquad();
							},
						},
					]}
					onDidDismiss={() => setConfirmDelete({ visible: false, squadName: null, squadID: null })}
				/>
				<IonRefresher slot="fixed" onIonRefresh={doRefresh}>
					<IonRefresherContent refreshingText="Updating Profile"></IonRefresherContent>
				</IonRefresher>
				<IonLoading isOpen={loading} onDidDismiss={() => setLoading(false)} message={"Loading"} />
				<SquadMainAvatarComponent squadObject={squadObject} goalObject={goalObject} isAdmin={isAdmin} />
				<IonRow>
					{squadObject &&
						squadObject.tags &&
						squadObject.tags.map((tag, i) => {
							return (
								<IonChip key={`${i}`} outline="false" color="tertiary">
									<IonLabel style={{ fontSize: "10px" }}>{tag.tag}</IonLabel>
								</IonChip>
							);
						})}
				</IonRow>
				{isAdmin === true && squadObject ? (
					<IonRow style={{ textAlign: "center" }}>
						<IonCol size="6">
							Private Squad <br />
							<IonToggle checked={squadObject.isPrivate} onClick={togglePrivate} color="tertiary" />
						</IonCol>
						<IonCol size="6">
							Visible in Search <br />
							<IonToggle checked={squadObject.showInSearch} onClick={toggleVisible} color="tertiary" />
						</IonCol>
					</IonRow>
				) : (
					""
				)}
				<SquadMembersComponent squadMembers={squadMembers} />
				{squadObject && squadObject.id ? <SquadWeeklyStreaksComponent squadObject={squadObject} /> : ""}
				{squadObject && squadObject.id ? <SquadWeeklyProgressComponent squadObject={squadObject} /> : ""}
			</IonContent>
		</IonPage>
	);
}

export default SquadDetails;
