/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { IonButton, IonContent, IonHeader, IonIcon, IonModal, IonSpinner, IonTitle, IonToolbar } from "@ionic/react";
import { closeOutline, notifications, people, podium } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import Emoji from "react-emoji-render";
import "../css/Home.css";
import "../css/VersionUpdate.css";
import { logGenericClickEvent, logPageView } from "../services/AnalyticsService";

const VersionUpdateComponent = () => {
	const [modalState, setModalState] = useState(true);

	const learnMore = () => {
		logGenericClickEvent("version-update", "nav-to-web-sales");
		window.open("http://fitsquadapp.com/products", "_new");
	};

	const closeModal = () => {
		logGenericClickEvent("version-update", "sweet");
		setModalState(false);
	};

	useEffect(() => {
		logPageView("New Update");
	}, []);

	return (
		<React.Fragment>
			<IonModal isOpen={modalState} onDidDismiss={(e) => setModalState(false)}>
				<IonHeader>
					<IonToolbar>
						<IonTitle>Latest Updates</IonTitle>
						<IonButton color="secondary" slot="end" onClick={() => setModalState(false)}>
							<IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
						</IonButton>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<div style={{ paddingLeft: "10px", paddingRight: "10px", paddingBottom: "20px", fontSize: "1.25em" }}>
						<h4 className="release-header">Friday, Sept 9 2022</h4>
						<ul>
							<li>
								More UI updates. Thanks again to squaddie <b>SamE</b> for all the UI feedback!
							</li>
							<br />
							<br />
							<li>Fixed an issue where the points on the profile screen were showing on the wrong day</li>
						</ul>
						{/* <h4 className="release-header">Thursday, Sept 8 2022</h4>
						<ul>
							<li>
								Updated some of the UI to have more consistent information displayed on different screen. More to come, but much love to squaddie <b>SamE</b>{" "}
								for all the UI feedback!
							</li>
							<br />
							<br />
							<li>When coming into the app with a squad, you'll no longer also join an intro squad</li>
							<br />
							<br />
							<li>
								Fixed an issue that was causing push notifications not to fire. Thank you squaddie <b>Katie</b> for letting us know!
							</li>
						</ul> */}
						{/* <h4 className="release-header">Sunday, June 26 2022</h4>
						<ul>
							<li>
								🆕 New layout! At FitSquad we're big believers in positive reinforcement.
								<br />
								<br />
								When you're acknowledged for what you do, you do more of it.
								<br />
								<br />
								So to make it easier to encourage your squad mates as well as thank them for their encouragement the home screen has been redesigned
								<br />
								<br />
								Now you can see all your most recent updates and easily "show some love"!
							</li>
							<br />
							<br />
							<li>
								📢 We also added a feedback button to both the home screen as well as the menu. We'd love to hear your feedback and hopefully this makes is
								easier!
							</li>
						</ul> */}
						{/*
						<h4 className="release-header">Friday, June 3 2022</h4>
						<ul>
							<li>
								🛠 Fixed an issue that was causing people in GMT+ time zones to not see their data correctly on their profile
							</li>
						</ul>
						<h4 className="release-header">Tuesday, May 24 2022</h4>
						<ul>
							<li>
								🛠 Fixed an issue that was causing people who were logged out to not be able to log back in. Thanks <b>Ziggy48</b> for reporting!
							</li>
						</ul>
						<h4 className="release-header">Monday, May 23 2022</h4>
						<ul>
							<li>🛠 Fixed an issue that was causing Challenge purchases to not log correctly</li>
						</ul>
						h4 className="release-header">Sunday, May 15 2022</h4>
						<ul>
							<li>
								1️⃣2️⃣3️⃣ Each Challenge now has 3 different set of rewards for you to choose from. Now you can pick your cause or influencer without being locked
								into one specific price 🙌
							</li>
						</ul>
						<h4 className="release-header">Friday, May 13 2022</h4>
						<ul>
							<li>Fixed an issue that was causing challenge purchases to fail on Android 🤦‍♂️</li>
						</ul>
						<h4 className="release-header">Monday, May 9 2022</h4>
						<ul>
							<li>
								🙌 Challenges are here! Now your activity is more rewarding! Thanks to all the squaddies who provided feedback and helped bring to life. We
								couldn't have done this without you.
							</li>
						</ul>
						<h4 className="release-header">Tuesday, May 2 2022</h4>
						<ul>
							<li>🚗 Some under the hood changes to support the upcoming Challenges feature</li>
						</ul>
						<h4 className="release-header">Wednesday, April 27 2022</h4>
						<ul>
							<li>
								📸 Fixed an issue that was causing photos to not be uploaded with activities. Thanks <b>fitbit*h</b> for reporting!
							</li>
							<br />
							<li>🤦🏾‍♂️ Fixed an issued with the feedback reminder. How bad is it to have your feedback screen have a bug?? 😆</li>
						</ul>
						<h4 className="release-header">Friday, April 15 2022</h4>
						<ul>
							<li>Happy Friday! Hope all squaddies are set to enjoy the weekend!</li>
							<li>🛠 Fixed an issue that was causing chat/activity feeds in random squads not to show</li>
							<li>🛠 (Hopefully) fixed an issue that was the GIF setting to not work properly</li>
						</ul>
						<h4 className="release-header">Tuesday, April 5 2022</h4>
						<ul>
							<li>
								🛠 (Hopefully) fixed an issue that was causing steps to show on the wrong day. Thanks <b>LISA</b> from the <b>Quad Squad</b> for reporting it!
							</li>
						</ul>
						<ul>
							<li>💥 Now when you log an activity manually, your points on your home screen should automatically update</li>
						</ul>
						<ul>
							<li>🤞 Lots of updates to prepare for our new challenges. Hoping to release it in the next week or so!</li>
						</ul>
						<h4 className="release-header">Monday, March 21 2022</h4>
						<ul>
							<li>🎉 Added a little confetti to the weekly summary. Because who doesn't like confetti?</li>
						</ul>
						<h4 className="release-header">Thursday, March 17 2022</h4>
						<ul>
							<li>
								Fixed a number of issues in onboarding. Thanks <b>FitSquadDan</b> for reporting!
							</li>
						</ul>
<h4 className="release-header">Monday, March 14 2022</h4>
						<ul>
							<li>
								Fixed an issue where steps were showing on the wrong day. Thanks <b>Disney</b> for reporting!
							</li>
						</ul>
						<h4 className="release-header">Tuesday, March 8 2022</h4>
						<ul>
							<li>
								Fixed an issue where sending a photo in chat would not show correctly. Thanks for reporting that <b>Airdaledude!</b>
							</li>
							<li>Reminder: if you see something, say something! 😀 Send any issues you find to support@fitsquadapp.com</li>
						</ul>
<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Great idea!</p>
						<ul>
							<li>
								👆 You can now tap from the notifications screen (the one with the bell in the top right) to the chat. Thanks <b>Juice</b> for the idea!
							</li>
						</ul>
						<h4 className="release-header">Wednesday, March 2 2022</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Happy March!</p>
						<ul>
							<li>🤦‍♂️ Fixed an issue that was causing the end of week summary to show every day</li>
						</ul>

						<h4 className="release-header">Monday, February 28 2022</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Happy Monday!</p>
						<ul>
							<li>🔧 Fixed an issue where selecting multiple featured squads was causing an issue. Should be all good now!</li>
						</ul>

						<h4 className="release-header">Thursday, February 24 2022</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Show off those sweaty selfies!</p>
						<ul>
							<li>
								📷 You can now add a personal photo (aka sweaty selfie) to your logged activities! Thanks <b>Disney</b> for the suggestion! <b>Ziggy48</b> we
								now expect pictures from your "patrols" 😉
							</li>
						</ul>
						<h4 className="release-header">Tuesday, February 22 2022</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Fixin' up the chat, part deux</p>
						<ul>
							<li>
								🔧 Fixed an issue where a chat message was being sent 2x. Thanks <b>Disney, Juice and Ace McGee</b> for letting us know!
							</li>
						</ul>
						<h4 className="release-header">Tuesday, February 22 2022</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Fixin' up the chat</p>
						<ul>
							<li>
								🔧 Fixed an issue where a chat message could disappear while you were entering it. Thanks <b>Ashley</b> for pointing that out!
							</li>
							<br />
							<li>🔧 Fixed an issue where the chat window would get all weird if the message was super long</li>
						</ul>
						<h4 className="release-header">Sunday, February 20 2022</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Big Revamp!</p>
						<ul>
							<li>
								🔁 We've reworked the whole squad screen! Chat, the Activity Feed and stats are all now separated to make it easier to see what's going on with
								your squad
							</li>
							<br />
							<li>
								🙌 You can now select <b>MULTIPLE</b> squads for your featured squads! Try your normal one AND a random one for a fun experience!
							</li>
							<br />
							<li>❌ GIFs will now only occassionally show in the Feed. This will hopefully strike the right balance of fun and clutter</li>
							<br />
							<li>
								❌ The "Add Fun GIF" option will now default to being off. No idea why it keeps being on after you turn it off 🤔. We'll get it figured out...
							</li>
							<br />
							<li>💨 Significant changes made under the hood to reduce loading times. More changes to follow!</li>
						</ul><h1>Past Updates</h1>
						<h4 className="release-header">Saturday, February 12 2022</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Optional GIFs</p>
						<ul>
							<li>
								❌ You now have the option on if you want into include a GIF or not with your logged activity. Thanks for the suggestion <b>Jallison</b>!
							</li>
							<li>💨 Loading speed has been increased! Thanks for all the feedback, it really does help us!</li>
						</ul>
						<h4 className="release-header">Tuesday, February 15 2022</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Challenges coming soon!</p>
						<ul>
							<li>🏅 We're super excited to be launching our next big feature: Challenges! This update lays the ground work for them</li>
							<li>♒ A new menu has been added to the top left of most screens to make room for challenges</li>
						</ul>
						
<h4 className="release-header">Tuesday, February 8 2022</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Bye bye Cheerleader...</p>
						<ul>
							<li>🤖 Whelp.. the squaddies have spoken. The Cheerleader has been retired (for now)</li>
							<li>💨 The good news is you can now log your activity much faster! Plus you can now choose what GIF you'd like your squad to see!</li>
							<li>
								📣 Ever want to get in contact with us? Just hit us up at contact@fitsquadapp.com! We'd love to hear what would make your experience better!
							</li>
						</ul>
						<h4 className="release-header">Sunday, January 23 2022</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Updated default squad</p>
						<ul>
							<li>✅ Your weekly squad selection will now default to the squad you picked in the previous week as opposed to a weekly squad</li>
						</ul>
						<h4 className="release-header">Monday, January 17 2022</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Steps fixed</p>
						<ul>
							<li>🐾 Steps logging wasn't working as expect 🤦🏾‍♂️ It's fixed now. Thanks squaddies Cocktail-and-cardio and AceMcGee for letting us know!</li>
						</ul>
						<h4 className="release-header">Monday, January 3 2022</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Happy New Year!</p>
						<ul>
							<li>🎉 Happy New Year Squaddies! Let's work together to make 2022 the best year yet</li>
							<li>🛠 Fixed some issues related to creating new squads. That will work MUCH better now </li>
							<li>📧 If you ever have any issues, please email us at support@fitsquadapp.com</li>
						</ul>
						<h4 className="release-header">Saturday, December 25 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Xmas Fixes</p>
						<ul>
							<li>🛠 Fixed an issue that caused the chat to not be working</li>
							<li>🎄 Happy Holiday Squaddies! Hope you're enjoying this time of year 💚</li>
						</ul>
						<h4 className="release-header">Monday, November 29 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Where is the love?</p>
						<ul>
							<li>💗 Put the foundation to begin tracking how much love you're showing your squaddies. It's all about that love right? 😃</li>
						</ul>
						<h4 className="release-header">Monday, November 22 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Revamped Onboarding Process, new loading images, faster squad screen and more!</p>
						<ul>
							<li>🔁 The onboarding process has been completely redone to make it easier for new squaddies to join up</li>
							<li>📷 The loading screen will now feature squaddies caught in the wild with their FitSquad shirts</li>
							<li>💨 The squad selection screen will now respond much faster</li>
						</ul>
						<h4 className="release-header">Thursday, November 4 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Easier workout entry</p>
						<ul>
							<li>
								😍 Entering activities you do frequently just got faster! When you tap the field where you enter your activity, your most common activities will
								show and you can just select one from there!
							</li>
						</ul>
						<h4 className="release-header">Tuesday, November 2 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Emoji Updates</p>
						<ul>
							<li>🙌 New emojis added for supporting your squadmates in quick replies</li>
						</ul>
						<h4 className="release-header">Monday, November 1 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Onboarding Updates</p>
						<ul>
							<li>🚀 The app onboarding has been updated to make the process smoother</li>
							<li>💨 Tweaked the startup process to hopefully get into the app a little quicker </li>
						</ul>
						<h4 className="release-header">Thursday, October 14 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Cheerleadr Updates</p>
						<ul>
							<li>👋 Your biggest fan (aka your Cheerleadr 😀) has been updated and will now let you know more about how it feels about you.</li>
							<li>
								🏃‍♂️ The activity screen has been updated so if you like the placeholder text, you can just simply enter the time you did what it says and you'll
								get your credit
							</li>
						</ul>
						<h4 className="release-header">Wednesday, September 29 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Activity Screen Fix</p>
						<ul>
							<li>⚒️ Fixed an issue on some devices where the activity screen wouldn't show</li>
						</ul>
						<h4 className="release-header">Monday, September 27 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Introducing the Cheerleadr!</p>
						<ul>
							<li>
								🤖 To help cheer you on as you pursue your execise habit, we've created your very own cheerleader. Tap the robot icon in the bottom status bar.
								We hope you like it!
							</li>
						</ul>
						<h4 className="release-header">Monday, September 13 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Improved startup speed</p>
						<ul>
							<li>💨 Refactored the update screen so you won't have to wait as long to get into the app</li>
						</ul>
						<h4 className="release-header">Tuesday, August 17 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Squad Joining Process Updated & Speed Improvements</p>
						<ul>
							<li>💨 Refactored the info in the app so you should see less spinny circles</li>
							<li>👋 Improved the onboarding process for new Squaddies so they can find squads more easily</li>
						</ul>
						<h4 className="release-header">Monday, August 9 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>🐛 🛠 (aka bug fixes!)</p>
						<ul>
							<li>🔧 Fixed an issue where redeeming points from Bonus Tokens gave the credit on the wrong day</li>
							<li>🔧 Fixed an issue where the end of season summary was still showing up in the end of week summary</li>
							<li>🔧 Fixed an issue where when going into a new squad with no messages, the screen would just spin</li>
						</ul>
						<h4 className="release-header">Tuesday, August 3 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Rewards are here!</p>
						<ul>
							<li>
								<p>
									🎁 For those of you who were successful with your seasons, you can now redeem your rewards in your inventory! Good luck winning sweet stuff
									from the Mystery Bags and congrats again!
								</p>
							</li>
						</ul>
						<h4 className="release-header">Monday, July 19 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>End of Season Prep</p>
						<ul>
							<li>
								<p>🔭 The inaugural Summer Season is coming to a close and we're prepping for it!</p>
							</li>
						</ul>
						<h4 className="release-header">Tuesday, July 13 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Adding inventory & fixing HealthKit</p>
						<ul>
							<li>
								<p>🎁 Added a section for "inventory" under your profile. This will be used for those who win things from their season!</p>
							</li>
							<li>
								<p>🙏 Attempting to fix an issue with HealthKit syncing. Hopefully this helps Hilmonster!</p>
							</li>
						</ul>
						<h4 className="release-header">Tuesday, June 22 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Accurate info</p>
						<ul>
							<li>
								<p>🛠 Fixed an issue that was causing season reporting to be inaccurate</p>
							</li>
							<li>
								<p>🛠 Fixed an issue that was causing season victories to look funny</p>
							</li>
						</ul>
						<h4 className="release-header">Thursday, June 17 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Mo' Luv</p>
						<ul>
							<li>
								<p>💙 You can now show appreciation for the love you receive on the notifications list. Acknowledge those who have acknowledged you!</p>
							</li>
							<li>
								<p>🖼 New GIFs now for various holidays! Check out our first one this Saturday</p>
							</li>
						</ul>
						<h4 className="release-header">Monday, June 14 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>RAIIIIDD</p>
						<ul>
							<li>
								<p>🐜 Killed a bug that was causing an issue for non-season participants</p>
							</li>
							<li>
								<p>🐜 Killed a bug that caused manual goal setting to fail</p>
							</li>
						</ul>
						<h4 className="release-header">Friday, June 7 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>SPEEEEEEEEED</p>
						<ul>
							<li>
								<p>💨 App will now download updates in the background which should make loading time much faster.</p>
							</li>
							<li>
								<p>🔝 Daily steps will now show at the top of a give day instead of at the bottom.</p>
							</li>
							<li>
								<p>🏁 Goals will now be based on your point totals including bonus points.</p>
							</li>
							<li>
								<p>🔢 When selecting a goal for the week, you'll now be given options instead of just a freeform selection. </p>
							</li>
						</ul>
						<h4 className="release-header">Thursday, May 27 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>You get a bonus and you get a bonus and you get a bonus</p>
						<ul>
							<li>
								<p>
									➕ Bonus points will now show correctly throughout the app. You'll notice them in <span className="bonus-points-text">green</span>
								</p>
							</li>
							<li>
								<p>🔮 Predictions are now hidden for those not participating in the season (sorry)</p>
							</li>
							<li>
								<p>🔮 Predictions will now account for your bonus if you're in the season</p>
							</li>
						</ul>
						<h4 className="release-header">Monday, May 24 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>"Stepping" into Season Week 1</p>
						<ul>
							<li>
								<p>
									👟 You can now manually add steps. Thanks to <b>@Derek Zoolander</b> for the suggestion!
								</p>
							</li>
							<li>
								<p>📈 Fixed an issue where stats on the Squad Scoreboard were slow to refresh</p>
							</li>
							<li>
								<p>📋 Added Season Summary to the Weekly Summary</p>
							</li>
						</ul>
						<h4 className="release-header">Monday, May 17 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>The Summer Season has begun!</p>
						<ul>
							<li>
								<p>🙏 Today kicks off our first 10-week challenge. Sincere thanks to all you who decided to participate!</p>
							</li>
							<li>
								<p>👀 Squaddies who are in will have a star "⭐" next to their name.</p>
							</li>
							<li>
								<p>
									🕔 If you'd still like to sign up, there's still time! You can register up until week 2, though it does get harder to complete the challenge
									the longer you wait.
								</p>
							</li>
							<li>
								<p>
									🤝 For those that chose not to participate be sure to still work toward you goals. You fellow squad mates can't succeed without the squad
									hitting their goal!
								</p>
							</li>
						</ul>
						<h4 className="release-header">Tuesday, May 11 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Minor Fixes and Seasons Prep</p>
						<ul>
							<li>
								<p>🧱 Getting the remaining bits in place for the season kick off</p>
							</li>
							<li>
								<p>🐛 Squished an annoying bug that was causing newly created users to not get an initial goal</p>
							</li>
						</ul>
						<h4 className="release-header">Saturday, May 8 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>The Summer Season 10-week challenge is here! 🙌</p>
						<ul>
							<li>
								<p>🎉 After months of late nights, we're proud to offer up our first 10-week challenge!</p>
							</li>
							<li>
								<p>
									💪 This gives YOU an opportunity to push yourself and YOUR SQUAD to be <b>consistent</b> with your exercise for 10 weeks.
								</p>
							</li>
							<li>
								<p>
									🥇 If you do, you'll <b>do some good for the world</b> by supporting Special Olympics athletes AND you'll have the opportunity to earn some
									sweet swag.
								</p>
							</li>
							<li>
								<p>
									🚫 For this season, everything is being offered at cost - <b>we'll be making no money through this</b>. We're just hoping it something you
									guys like!
								</p>
							</li>

							<IonButton
								color="success"
								size="small"
								fill="solid"
								expand="block"
								className="sales-page-signup-button"
								style={{ marginBottom: "20px" }}
								onClick={learnMore}
							>
								Learn More
							</IonButton>
						</ul>
						<h4 className="release-header">Tuesday, April 27 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>
							<Emoji text="Nothing to see here..." />
						</p>
						<ul>
							<li>
								<p>🔎 Lots of little behind the scenes things in prep for the first season</p>
							</li>
							<li>
								<p>💨 Improved loading speed on the home screen</p>
							</li>
						</ul>
						<h4 className="release-header">Thursday, April 22 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>
							<Emoji text="Speedy, Giphy, Texty" />
						</p>
						<ul>
							<li>
								<p>💨 Improved loading speed on the home screen</p>
							</li>
							<li>
								<p>
									🔠 Change adding an activity to using text input instead of sliders<span className="bold">Thanks @Cocktails-and-cardio!</span>{" "}
								</p>
							</li>
							<li>
								<p>👻 Updated the Daily GIFs to include better ones. Hopefully no more Wednesday from the Addams Family 😀 </p>
							</li>
						</ul>
						<h4 className="release-header">Wednesday, April 21 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>
							<Emoji text="Better predictions" />
						</p>
						<ul>
							<li>
								<p>🔮 Improved predictions for squads</p>
							</li>
							<li>
								<p>
									🛠 Fixed an issue where the chat would disappear if you deleted & reinstalled <span className="bold">Thanks @Wals78!</span>{" "}
								</p>
							</li>
						</ul>
						<h4 className="release-header">Sunday, April 18 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>
							<Emoji text="Bits & Pieces" />
						</p>
						<ul>
							<li>
								<p>
									❌ You can now remove a member of your squad if you like. Go to manage squads, tap the squad, tap the member and if you're the owner, you can
									remove them.
								</p>
							</li>
						</ul>
						<ul>
							<li>
								<p>
									<span className="bold">GIF</span> Changed the gif in the chat room to say GIF to make it clearer <span className="bold">Thanks @ariggz!</span>
								</p>
							</li>
						</ul>
						<ul>
							<li>
								<p>
									<IonSpinner /> Added a loading screen to the Squad Scoreboard
								</p>
							</li>
						</ul>
						<h4 className="release-header">Thursday, April 15 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>
							<Emoji text="So fresh, so clean" />
						</p>
						<ul>
							<li>
								<p>🤞 Hopefully you'll see the freshest data you have whevenver you open the app</p>
							</li>
						</ul>
						<ul>
							<li>
								<p>
									🚀 Improved onboarding that will pick up where someone left off if they don't complete it <span className="bold">Thanks @shayzee!</span>
								</p>
							</li>
						</ul>
						<ul>
							<li>
								<p>
									📸 You can now change your profile pic <span className="bold">Thanks @greatdane!</span>
								</p>
							</li>
						</ul>
						<ul>
							<li>
								<p>📊 Standings are now properly updated. Where do you stand? 🙂</p>
							</li>
						</ul>
						<ul>
							<li>
								<p>➕ When you add an activity now, your data should update</p>
							</li>
						</ul>
						<h4 className="release-header">Sunday, April 11 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>
							<Emoji text="Sunday Cleanup" />
						</p>
						<ul>
							<li>
								<p>
									🧹 Improved data loading so that when you open the app, you should have the latest data. <span className="bold">Thanks @katesmctates!</span>
								</p>
							</li>
						</ul>
						<ul>
							<li>
								<p>
									🧹 Cleaned up some inconsistencies with goal predictions and funky points layout. <span className="bold">Thanks @Licia!</span>
								</p>
							</li>
						</ul>
						<ul>
							<li>
								<p>
									🔃 Removed the "today" label on activities that didn't actually occur "today". <span className="bold">Thanks @Quackattack!</span>
								</p>
							</li>
						</ul>
						<ul>
							<li>
								<p>
									👀 When you click on a profile picture in your chat, you'll now go the correct profile{" "}
									<span className="bold">Thanks @JessP & @MammaJamma38!</span>
								</p>
							</li>
						</ul>
						<ul>
							<li>
								<p>💡 Add some helpful tips to the loading screen.</p>
							</li>
						</ul>
						<ul>
							<li>
								<p>
									<IonIcon size="small" icon={people} /> Now when you click this icon, it will take you to your overall squad view instead of directly into your
									last chat room
								</p>
							</li>
						</ul>
						<h4 className="release-header">Friday, April 9 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>
							<Emoji text="Deleting Activities" />
						</p>
						<ul>
							<li>
								<p>❌ You can now delete activities that came from other apps. Go to your profile, tap the activity, tap delete and poof!</p>
							</li>
						</ul>
						<h4 className="release-header">Friday, April 9 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>
							<Emoji text="Better Squad Management" />
						</p>
						<ul>
							<li>
								<p>🔪 Squashed a MAJOR bug that caused squad goals not to update. You should see your points update regularly now! </p>
							</li>
							<li>
								<p>
									🚨 <strong>BIG CHANGE: </strong> Squad leaders: You can now set your squad to be hidden from search. Do this if you don't want randos
									requesting to join. It can be done from the squad details screen
								</p>
							</li>
							<li>
								<p>
									👋 Inviting new squaddies has moved to the squad details screen as well. You can get there by tapping your squad from the "Manage Squads"
									screen
								</p>
							</li>
							<li>
								<p>🏡 New squaddies who do not come in with squad code will now be auto-placed into a newbie friendly Squad</p>
							</li>
							<li>
								<p>
									📝 You can now edit your squad name and photo from that same screen (Squad Details) - <i>thanks Remy for the suggestion!</i>
								</p>
							</li>
							<li>
								<p>📊 Reviewing your squadmates progress will now open to a full screen, making it easier to see what your peeps are up to!</p>
							</li>
							<li>
								<p>🔮 Squad predictions are now more accurate</p>
							</li>
							<li>
								<p>✅ Fitbit and HealthKit connections can now be toggled from the setting screen</p>
							</li>
							<li>
								<p>🙌 We'll be opening the beta up even further, so expect to see more peeps in the app very soon!</p>
							</li>
						</ul>
						<h4 className="release-header">Thursday, April 8 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>
							<Emoji text="Fixes coming Hot & Heavy 😀" />
						</p>
						<ul>
							<li>
								<p>🔳 On the join squad screen, buttons will now show if you're a member or owner of a squad</p>
							</li>
							<li>
								<p>🚫 You can now properly quit a squad if you're not feeling your peeps</p>
							</li>
							<li>
								<p>🙌 We added a new squad everyone can join without waiting for permission called "Squadmates Unite"</p>
							</li>
							<li>
								<p>📷 When you see an image in chat you can now tap on it and zoom, pan, pinch etc. (thanks Coldbeer for this suggestion!)</p>
							</li>
							<li>
								<p>👥 Squads with less than 4 members will now show their member profile pics correctly</p>
							</li>
							<li>
								<p>📊 Standings have been updated to remove the "competition" tab and show all our new peeps</p>
							</li>
							<li>
								<p>📊 The chart on your profile will now show correct data for those with lots of activities in multiple squads</p>
							</li>
							<li>
								<p>WHEW! Busy day 🙂</p>
							</li>
						</ul>
						<h4 className="release-header">Wednesday, April 7 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>
							<Emoji text="Beta Day 1 Quick update" />
						</p>
						<ul>
							<li>
								<p>You can now delete squads from the "Manage Squads" button (thanks for the request Hilmonster!)</p>
							</li>
							<li>
								<p>
									You can now pull down to refresh the screen when trying to add a squad or when managing your squads. This will help if you try to join a new
									squad but don't see it
								</p>
							</li>
						</ul>
						<h4 className="release-header">Tuesday, April 6 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>
							<Emoji text='"WELCOME BETA USERS! 🙌🙌🙌"' />
						</p>
						<ul>
							<li>
								<p>Big big welcome to all our new squaddies! Things may be a little rough around the edges, but we're all super excited to have you!</p>
							</li>
							<li>
								<p>
									This screen will pop up anytime there is anything new in the app so expect to see it from time to time. You can even scroll back into the past
									to see how far we've come 🙂
								</p>
							</li>
						</ul>
						<h4 className="release-header">Monday, April 5 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>
							<Emoji text='"Beta Launches tomorrow"' />
						</p>
						<ul>
							<li>
								<p>The day is almost here! The FitSquad Beta launches tomorrow and we'll welcome in dozens of new users.</p>
							</li>
							<li>
								<p>We'll also be kicking off our inaugural season where you can earn medals, shirts and donate to charity.</p>
							</li>
						</ul>
						<h4 className="release-header">Wednesday, Mar 17 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>
							<Emoji text='"New design is almost complete!"' />
						</p>
						<ul>
							<li>
								<p>The new design is just about wrapped up. It's been a slow but steady progress and we're almost there!</p>
							</li>
							<li>
								<p>Thereas a new intro video that people who download the app the first time will see.</p>
							</li>
						</ul>
						<h4 className="release-header">Wednesday, Mar 10 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>
							<Emoji text='"More Design Updates"' />
						</p>
						<ul>
							<li>
								<p>The designs updates keep coming! New splash/loading screen, updates to the home screen and when you peep your squadmates progress.</p>
							</li>
						</ul>
						<h4 className="release-header">Tuesday, Mar 9 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>
							<Emoji text='"More Design Updates"' />
						</p>
						<ul>
							<li>
								<p>
									Part 3 of the new design has been done! The squad profile screen, which you can see by tapping the squad name in chat, is now looking good.
								</p>
							</li>
							<li>
								<p>Additionally, when you tap on a squadmates' picture it will take you their profile screen so you can get all creepy on them :)</p>
							</li>
							<li>
								<Emoji text=":bug: Fixed an issue a user's chart was showing incorrect values" />
							</li>
						</ul>
						<h4 className="release-header">Thursday, Mar 4 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>
							<Emoji text='"Design revamp continues"' />
						</p>
						<ul>
							<li>
								<p>
									Part 2 of the design refresh is in, and you'll notice some update on the profile screen as well as the add activity screen. If you spot
									anything out of place, please let me know!
								</p>
							</li>
							<li>
								<Emoji text=":bug: Fixed an issue where it would show steps on the wrong day in the activities section of the profile <strong>Thanks Ashley!" />
							</li>
							<li>
								<Emoji text=":bug: Fixed a UX issue where if you scrolled the activities section to far it was a pain to scroll back <strong>Thanks Tim!</strong> " />
							</li>
							<br />
						</ul>
						<h4 className="release-header">Sunday, Feb 28 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>
							<Emoji text='"Hey hot stuff! :fire:"' />
						</p>
						<ul>
							<li>
								<p>
									The first pass of some of the new designs are in. You'll see an updated look primarily on the home and chat screens for now with other screens
									trickling in throughout the week
								</p>
							</li>
							<li>
								<p>
									Also, thank you to everyone who helped spread the word about the survey! We had over 100 responses and our Beta list now only has about 10
									spots open. Super exciting!
								</p>
							</li>
							<br />
						</ul>
						<h4 className="release-header">Sunday, Feb 21 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>"What did I miss?"</p>
						<ul>
							<li>
								<p>
									To help you know what love you've received, what activites from your squadmates deserve some love, and other key notifications you may have
									missed, we've added a notification icon!
								</p>
							</li>
							<li>
								<p>
									Tap the <IonIcon icon={notifications}></IonIcon> icon at in the top right of the home screen to see your notifications.
								</p>
							</li>
							<br />
						</ul>
						<h4 className="release-header">Friday, Feb 12 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>The results are in...</p>
						<ul>
							<li>
								<p>
									New Stats and standings have been added! Tap the <IonIcon icon={podium}></IonIcon> icon at the bottom right to see where your squad and you
									stand
								</p>
								<p>
									<strong>NOTE: </strong> First time you open them, it may take a while to load, especially the individual ones
								</p>
								<p>
									Also - yes <strong>AirDaleDude's</strong> stats are off. But yes, he is undefeated
								</p>
							</li>
							<br />
							<li>
								<p>
									To make room for the podium, squad chat has been move to the <IonIcon icon={people}></IonIcon> icon.
								</p>
								<p>Also, because we'll have new users coming on soon, it has a new "coach mark". You should only see it once</p>
							</li>
							<br />
						</ul>
						<h4 className="release-header">Sunday, Feb 7 2021</h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Bug Fixes, Improved Predictions and New Squads Joining!</p>
						<ul>
							<li>
								<Emoji text=":raised_hands: A couple new squads have joined the mix! If you interact with them be sure to say hi :smile:" />
							</li>
							<br />
							<li>Squad predictions have been updated to provide more accurate predictions</li>
							<br />
							<li>Added new text to squad updates to help keep them fresh</li>
							<br />
							<li>
								<Emoji text=":bug: Fixed an issue where on Android devices, it would ask to edit the photo" />
							</li>
							<br />
							<li>
								<Emoji text=":bug: Fixed an issue where on iOS devices the love sending wasn't working as intended" />
							</li>
							<br />
							<li>
								<Emoji text=":bug: Fixed an issue where if a user sent a photo, it would send a notification with a blank message" />
							</li>
							<br />
						</ul>
						<h4 className="release-header">Sunday, Jan 31 2021 </h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Updated Competition Summary and Improved Emoji Support</p>
						<ul>
							<li>Your end of week summary will now include a summary of your competition in a revised format.</li>
							<br />
							<li>New emojis support is here! Now you can reply with whatever emoji you want to peoples activity.</li>
							<br />
						</ul>
						<h4 className="release-header">Thursday, Jan 28 2021 (pt.2) </h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Photo uploads are here!</p>
						<ul>
							<li>Did some yardwork and want to prove it? Crush your latest HIIT? Went surfing? Now take a pic and share it with your squadmmates!</li>
							<br />
							<li>Simply tap the camera icon and you snap a photo or select one from your library.</li>
							<br />
						</ul>
						<h4 className="release-header">Thursday, Jan 28 2021 (pt.1) </h4>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Improved predictions</p>
						<ul>
							<li>
								Predictions now take into account when squadmmates are <i>actually</i> active in a given week
							</li>
							<br />
							<li>So if for example you typically get a lot of activity on the weekend, the system will now have an improved prediction to account for that</li>
							<br />
							<li>As a result you may see some squadmates who have little or even zero points but are still on track</li>
							<br />
							<li>This should also result in squad team predictions being more accurate</li>
						</ul>
						<p style={{ fontVariant: "small-caps", fontSize: "1.3em" }}>Squadmate Details fixed</p>
						<ul>
							<li>
								Been curious how your squadmates are doing? Tap the {">>>"} Squadmate Details {"<<<"} text now to find out <br />
								<i>Shout out to AirDaleDude for reporting!</i>
							</li>
						</ul>

							*/}
					</div>
					<div style={{ textAlign: "center" }}>
						<IonButton color="success" onClick={() => closeModal()}>
							Sweet!
						</IonButton>
					</div>
				</IonContent>
			</IonModal>
		</React.Fragment>
	);
};

export default VersionUpdateComponent;
