import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonPage,
	IonProgressBar,
	IonToolbar,
	useIonViewDidEnter,
	getPlatforms,
	IonLoading,
	IonFooter,
} from "@ionic/react";
import React, { useState } from "react";
import "../../css/Home.css";
import "../../css/Onboarding.css";
import { StorageService } from "../../services/StorageService";
import { Plugins } from "@capacitor/core";
import { updateUser } from "../../services/UserService";
import { useHistory } from "react-router";
import { getChatRoomIDFromSquadID } from "../../services/SquadService";
import { postChatRoomMessage, addEmoji, getChatRooms } from "../../services/ChatService";
import { logGenericEvent, logPageView } from "../../services/AnalyticsService";
import { enableNotifications } from "../../services/NotificationService";

const NotificationsPermission = ({ progress, setProgress }) => {
	const storageService = new StorageService();
	const [loading, setLoading] = useState(false);
	useIonViewDidEnter(() => {
		logPageView("notifications permission");
		setProgress(0.6);
		storageService.setItem("lastOnboardingStep", 8);
	});

	const { PushNotifications, LocalNotifications, Device } = Plugins;
	const history = useHistory();

	const setupPushListeners = async () => {
		LocalNotifications.requestPermission();
		LocalNotifications.addListener("localNotificationActionPerformed", async (action) => {
			let deviceInfo = await Device.getInfo();
			console.log("DeviceInfo: ", { deviceInfo });
			if (deviceInfo.platform === "android") {
				console.log("Action performed: " + JSON.stringify(action));
				if (action.actionId === "tap") {
					if (action.notification.extra) {
						console.log("Data payload: " + JSON.stringify(action.notification.extra));
						console.log("ChatRoom: " + action.notification.extra.payload.chatRoom);
						if (action.notification.extra.payload.chatRoom) {
							history.push("/chatroom/" + action.notification.extra.payload.chatRoom);
						}
					}
				} else if (action.actionId === "free_text") {
					// console.log("squadID: " + action.notification.extra.activityData.squadId);

					let chatRoomID = await getChatRoomIDFromSquadID(action.notification.extra.activityData.squadId);
					// console.log("ChatRoomID: " + chatRoomID);
					postChatRoomMessage(chatRoomID, action.inputValue);
				} else {
					let response = await addEmoji(action.notification.id, action.actionId, "activity", action.notification.extra.activityData.squadId);
					// console.log("Response from addEmoji: " + response);
				}
			}
		});
		LocalNotifications.registerActionTypes({
			types: [
				{
					id: "workout-1",
					actions: [
						{
							id: ":+1:",
							title: "👍",
							destructive: true,
						},
						{
							id: ":muscle:",
							title: "💪",
							destructive: true,
						},
						{
							id: "free_text",
							title: "Respond",
							input: true,
						},
					],
				},
				{
					id: "workout-2",
					actions: [
						{
							id: ":raised_hands:",
							title: "🙌",
							destructive: true,
						},
						{
							id: ":sign_of_the_horns:",
							title: "🤘",
							destructive: true,
						},
						{
							id: "free_text",
							title: "Respond",
							input: true,
						},
					],
				},
				{
					id: "workout-3",
					actions: [
						{
							id: ":ok_hand:",
							title: "👌",
							destructive: true,
						},
						{
							id: ":clap:",
							title: "👏",
							destructive: true,
						},
						{
							id: "free_text",
							title: "Respond",
							input: true,
						},
					],
				},
				{
					id: "workout-4",
					actions: [
						{
							id: ":punch:",
							title: "👊",
							destructive: true,
						},
						{
							id: ":grinning:",
							title: "😀",
							destructive: true,
						},
						{
							id: "free_text",
							title: "Respond",
							input: true,
						},
					],
				},
			],
		});
		PushNotifications.addListener("pushNotificationReceived", async (notification) => {
			let deviceInfo = await Device.getInfo();
			console.log("DeviceInfo: ", { deviceInfo });
			if (deviceInfo.platform === "android") {
				let dataPayload;
				if (notification?.data?.payload) {
					dataPayload = await JSON.parse(notification.data.payload);
					console.log("Notification: " + JSON.stringify(dataPayload));
					if (dataPayload["payload"]["activityData"]) {
						// it's an activtiy
						console.log("Datatype: " + dataPayload["payload"]["activityData"]["dataType"]);
						console.log("Activity ID: " + dataPayload["payload"]["sourceActivityId"]);
						let randResponse = Math.floor(Math.random() * Math.floor(4)) + 1;
						let actTypeID = dataPayload["payload"]["activityData"]["dataType"] + "-" + randResponse;
						console.log("randResponse: " + actTypeID);
						await LocalNotifications.schedule({
							notifications: [
								{
									title: dataPayload["title"],
									body: dataPayload["body"],
									id: dataPayload["payload"]["id"],
									schedule: {
										on: new Date(Date.now() + 5000),
									},
									channelId: "primary",
									extra: {
										activityData: dataPayload,
									},
									actionTypeId: actTypeID,
								},
							],
						});
					} else {
						console.log("ID: " + dataPayload["payload"]["id"]);
						// it's a chat message
						await LocalNotifications.schedule({
							notifications: [
								{
									title: dataPayload["title"],
									body: dataPayload["body"],
									id: Date.now(),
									schedule: {
										on: new Date(Date.now() + 5000),
									},
									channelId: "primary",
									extra: {
										payload: dataPayload["payload"],
									},
									actionTypeId: null,
								},
							],
						});
					}
				} else {
					// this shouldn't happen
					console.log("No data payload");
				}
			}
			console.log("\n\nPUSH - pushNotificationReceived *NEW!*: " + JSON.stringify(notification));
		});

		PushNotifications.addListener("pushNotificationActionPerformed", (action) => {
			console.log("\n\nPUSH - pushNotificationActionPerformed: " + JSON.stringify(action));
			const platformsArray = getPlatforms();
			console.log("Platforms: " + JSON.stringify(platformsArray));
			platformsArray.forEach(async (platform) => {
				if (platform === "ios") {
					if (action.notification.data.aps.alert.payload.activityData?.dataType === "workout") {
						if (action.actionId === "free_text") {
							console.log("squadID: " + action.notification.data.aps.alert.payload.squadId);

							let chatRoomID = await getChatRoomIDFromSquadID(action.notification.data.aps.alert.payload.squadId);
							console.log("ChatRoomID: " + chatRoomID);
							postChatRoomMessage(chatRoomID, action.inputValue);
						} else {
							if (action.actionId !== "tap") {
								let response = await addEmoji(
									action.notification.data.aps.alert.payload.id,
									action.actionId,
									"activity",
									action.notification.data.aps.alert.payload.squadId
								);
								console.log("Response from addEmoji: " + response);
							}
						}
					} else {
						console.log("PUSH - pushNotificationActionPerformed: " + JSON.stringify(action.data.aps.alert.payload.squadId));
						if (action.data.aps.alert.payload.squadId) {
							let squadID = action.data.aps.alert.payload.squadId;
							console.log("\nLooking for chat room for squadID " + squadID);
							console.log("\nGetting chat room");
							let chatRooms = await getChatRooms(false);
							let chatRoomID = null;
							for (let roomData of chatRooms) {
								console.log("\n\nchecking roomData: " + JSON.stringify(roomData));
								if (roomData.squadIdA === squadID && roomData.squadIdB !== null && chatRoomID === null) {
									console.log("\nFound the competition one in A: " + roomData.id);
									chatRoomID = roomData.id;
								} else if (roomData.squadIdA === squadID && chatRoomID === null) {
									console.log("\nFound the solo one in A: " + roomData.id);
									chatRoomID = roomData.id;
								} else if (roomData.squadIdB === squadID && roomData.squadIdA !== null && chatRoomID === null) {
									console.log("\nFound the competition one in B: " + roomData.id);
									chatRoomID = roomData.id;
								} else if (roomData.squadIdB === squadID && chatRoomID === null) {
									console.log("\nFound the solo one in B: " + roomData.id);
									chatRoomID = roomData.id;
								}
							}
						} else {
							console.log("\n\n NO SQUAD ID");
						}
						console.log("User is on iOS. Using that payload");
					}
				}
				if (platform === "android") {
					console.log("PUSH - pushNotificationActionPerformed: " + JSON.stringify(action));
					if (action.notification.data.payload) {
						let payload = JSON.parse(action.notification.data.payload);
						console.log("Data payload: " + JSON.stringify(payload));
						console.log("ChatRoom: " + payload.chatRoom);
						if (payload.chatRoom) {
							history.push("/chatroom/" + payload.chatRoom);
						}
					}
				}
			});
		});

		console.log("\n\nPUSH Listeners set");
	};

	const enableNotificationsLocal = async () => {
		// Doing it this way to keep notification enablement central

		setLoading(true);
		await enableNotifications();
		setLoading(false);
		return;

		/*
		logGenericEvent("attempting to enable notifications");
		let body;
		let pushToken = await storageService.getItem("pushToken");
		if (pushToken !== "") {
			console.log("Your pushToken: " + pushToken);
		} else {
			console.log("No push token stored");
		}
		//LocalNotifications.requestPermission();
		PushNotifications.addListener("registration", async (token) => {
			setLoading(true);
			storageService.setItem("pushToken", token.value);
			const platformsArray = getPlatforms();
			console.log("Platforms: ", { platformsArray });
			platformsArray.forEach((platform) => {
				if (platform === "ios") {
					body = {
						deviceToken: token.value.toLowerCase(),
					};
					console.log("User is on iOS. Using that payload");
				}
				if (platform === "android") {
					body = {
						registrationId: token.value,
					};
					console.log("User is on Android. Using that payload");
				}
			});
			await updateUser(body);
			await setupPushListeners();
			setLoading(false);
			return
		});

		// Some issue with our setup and push will not work
		PushNotifications.addListener("registrationError", (error) => {
			console.log("PUSH - Error on registration: " + JSON.stringify(error));
		});

		let permResult = await PushNotifications.requestPermission();
		if (permResult.granted) {
			// Register with Apple / Google to receive push via APNS/FCM
			const result = await PushNotifications.register();
			console.log("register result: " + JSON.stringify(result));
			logGenericEvent("successfully to enabled notifications");
		} else {
			console.log("push notifications wont work");
			console.log("permResult: " + JSON.stringify(permResult));
			//alert("Oops something happened. Try tapping the button again");
		}

		//const result = await PushNotifications.register();
		//console.log("register result: ", result);
		*/
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar style={{ "--border-style": "none" }}>
					<IonButtons slot="start">
						<IonBackButton defaultHref="/entryscreen" text="" color="primary" />
					</IonButtons>
				</IonToolbar>
				<IonProgressBar value={progress} />
			</IonHeader>
			<IonContent>
				<IonLoading isOpen={loading} onDismiss={() => setLoading(false)} backdropDismiss message={"Configuring notifications"} />
				<div className="onboarding-main-div">
					<div className="onboarding-title">To get the most out of FitSquad, enable notifications</div>
					<img className="onboarding-hero-image" src="assets/images/cheering-crowd.jpg" alt="" />

					<div className="onboarding-text-area">
						You'll be updated when you're close to your goal, when new squaddies join and when a fellow squaddie shows you love.
						<br />
						<br />
						Plus you can tune your notifications under settings.
					</div>
				</div>
				<div className="onboarding-footer"></div>
			</IonContent>
			<IonFooter className="onboarding-footer">
				<IonButton
					onClick={() =>
						enableNotificationsLocal().then(() => {
							history.push("/onboarding/triggerNotification");
						})
					}
					className="onboarding-advance-button"
				>
					Enable Notifications
				</IonButton>
				<IonButton className="button-as-link" routerLink="/onboarding/connecttracker">
					I don't want to be cheered on
				</IonButton>
			</IonFooter>
		</IonPage>
	);
};

export default NotificationsPermission;
