import {
	IonAvatar,
	IonBackButton,
	IonButton,
	IonButtons,
	IonCol,
	IonContent,
	IonFooter,
	IonHeader,
	IonPage,
	IonProgressBar,
	IonRow,
	IonToolbar,
	useIonViewWillEnter,
} from "@ionic/react";
import React, { useState } from "react";
import { useHistory } from "react-router";
import "../../css/Home.css";
import "../../css/Onboarding.css";
import { logPageView } from "../../services/AnalyticsService";
import { getUser } from "../../services/UserService";
import { StorageService } from "../../services/StorageService";
import { getRandomUser } from "../../services/AuthService";
const storageService = new StorageService();

const GoalsExplanationSquad = ({ progress, setProgress, squadToJoin }) => {
	const [userObject, setUserObject] = useState();
	const [randos, setRandos] = useState();

	useIonViewWillEnter(async () => {
		logPageView("Goal Explanation");
		setUserObject(JSON.parse(await getUser()));
		setProgress(0.55);
		setRandos(await getRandomUser(5));
		storageService.setItem("lastOnboardingStep", 6.1);
	});

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar style={{ "--border-style": "none" }}>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
				</IonToolbar>
				<IonProgressBar value={progress} />
			</IonHeader>
			<IonContent>
				<div className="onboarding-main-div">
					<div className="onboarding-title">So for example if this is your squad...</div>
					<div className="onboarding-hero-image-div">
						<img className="onboarding-hero-image" src="https://fitsquad-ui-assets.s3.us-west-2.amazonaws.com/pack-of-lions.gif" alt="" />
					</div>

					<div className="onboarding-text-area" style={{ textAlign: "center" }}>
						{userObject && randos ? (
							<React.Fragment>
								<div>
									<IonRow>
										<IonCol size="2"></IonCol>
										<IonCol></IonCol>
										<IonCol>
											<span style={{ textDecoration: "underline" }}>Goal Points</span>
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol size="2">
											<IonAvatar>
												<img src={userObject.avatar} alt="" className="goals-explanation-avatar" />
											</IonAvatar>
										</IonCol>
										<IonCol size="6" style={{ textAlign: "left", marginTop: "auto", marginBottom: "auto", paddingLeft: "5%" }}>
											{userObject.userName}
										</IonCol>
										<IonCol style={{ marginTop: "auto", marginBottom: "auto" }}>250</IonCol>
									</IonRow>
									<IonRow>
										<IonCol size="2">
											<IonAvatar>
												<img src={randos.results[0].picture.thumbnail} alt="" className="goals-explanation-avatar" />
											</IonAvatar>
										</IonCol>
										<IonCol size="6" style={{ textAlign: "left", marginTop: "auto", marginBottom: "auto", paddingLeft: "5%" }}>
											{randos.results[0].login.username}
										</IonCol>
										<IonCol style={{ marginTop: "auto", marginBottom: "auto" }}>300</IonCol>
									</IonRow>
									<IonRow>
										<IonCol size="2">
											<IonAvatar>
												<img src={randos.results[1].picture.thumbnail} alt="" className="goals-explanation-avatar" />
											</IonAvatar>
										</IonCol>
										<IonCol size="6" style={{ textAlign: "left", marginTop: "auto", marginBottom: "auto", paddingLeft: "5%" }}>
											{randos.results[1].name.title}
											{randos.results[1].name.first}
										</IonCol>
										<IonCol style={{ marginTop: "auto", marginBottom: "auto" }}>100</IonCol>
									</IonRow>
									<IonRow>
										<IonCol size="2">
											<IonAvatar>
												<img src={randos.results[3].picture.thumbnail} alt="" className="goals-explanation-avatar" />
											</IonAvatar>
										</IonCol>
										<IonCol size="6" style={{ textAlign: "left", marginTop: "auto", marginBottom: "auto", paddingLeft: "5%" }}>
											{randos.results[3].name.first}
										</IonCol>
										<IonCol style={{ marginTop: "auto", marginBottom: "auto" }}>350</IonCol>
									</IonRow>
								</div>
							</React.Fragment>
						) : (
							""
						)}

						<p>Your squad's goal for the week would be 1,000 points. </p>
						<p>
							And <b>everyone would need to contribute</b> in order for the squad to be successful!
						</p>
					</div>
				</div>
			</IonContent>
			<IonFooter className="onboarding-footer">
				<IonButton routerLink="/onboarding/logfirstworkout" className="onboarding-advance-button">
					Understood
				</IonButton>
			</IonFooter>
		</IonPage>
	);
};

export default GoalsExplanationSquad;
