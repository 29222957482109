/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable no-self-assign */
import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonCol,
	IonContent,
	IonFooter,
	IonGrid,
	IonHeader,
	IonPage,
	IonProgressBar,
	IonRow,
	IonToolbar,
	useIonViewDidEnter,
} from "@ionic/react";
import moment from "moment";
import React, { useState } from "react";
import "../../css/Home.css";
import "../../css/Onboarding.css";
import { addActivity } from "../../services/ActivitiesService";
import { logPageView } from "../../services/AnalyticsService";
import { getGoal } from "../../services/PointsAndGoalsService";
import SpinnerOnLoading from "../SpinnerOnLoadingComponent";
import { StorageService } from "../../services/StorageService";
const storageService = new StorageService();

const InitialBonus = ({ progress, setProgress, activity, goalObject }) => {
	const [dow, setDOW] = useState();
	const [goalObj, setGoalObj] = useState(goalObject);
	const [bonusPoints, setBonusPoints] = useState(goalObject);
	const [loading, setLoading] = useState(true);
	const [totalPoints, setTotalPoints] = useState(0);
	const [goalCompleted, setGoalCompleted] = useState(false);
	useIonViewDidEnter(() => {
		logPageView("initial bonus");
		setProgress(0.8);
		storageService.setItem("lastOnboardingStep", 13);
		setDOW(moment().format("dddd"));
		generateBonusCredit();
	});

	const generateBonusCredit = async () => {
		if (!goalObj) {
			goalObject = await getGoal();
			setGoalObj(goalObject);
		}
		// console.log({ goalObject });
		let pointsFromLog = activity ? activity.points : 0;
		let bps = {}; // do this mainly for debugging
		const minsInAWeek = 10080;

		if (activity) {
			// console.log({ activity });
			let activityData = {
				activity: activity.activity,
				duration: activity.duration,
				unit: "minutes",
				calories: activity.calories,
				date: activity.date,
			};
			await addActivity(activityData).catch((error) => {
				alert("Error: ", { error });
				return;
			});
		}

		if (pointsFromLog > goalObject.goalPoints) {
			setGoalCompleted(true);
		} else {
			bps.currentDate = moment();
			bps.startOfWeek = moment().startOf("isoWeek");
			bps.minsPassed = bps.currentDate.diff(bps.startOfWeek, "minutes") + 1;
			bps.minsRemaining = minsInAWeek - bps.minsPassed;
			bps.pointsPerMinute = goalObject.goalPoints / minsInAWeek;
			bps.bonusPoints = Math.ceil(bps.pointsPerMinute * bps.minsPassed) - pointsFromLog;
			bps.bonusPoints < 0 ? (bps.bonusPoints = 0) : (bps.bonusPoints = bps.bonusPoints);
			bps.pointsRemaining = Math.floor(goalObject.goalPoints - bps.bonusPoints - pointsFromLog);
			if (bps.pointsRemaining <= 0) {
				bps.pointsRemaining = 1;
			}
			setBonusPoints(bps);
			console.log({ bps });
		}
		if (bps.bonusPoints > 0) {
			let activityDataBonus = {
				activity: "first week bonus",
				duration: bps.bonusPoints,
				unit: "minutes",
				calories: bps.bonusPoints * 4,
				date: moment().format(),
			};
			await addActivity(activityDataBonus).catch((error) => {
				alert("Error: ", { error });
			});
		}
		setTotalPoints(bps.bonusPoints + pointsFromLog);
		if (!activity) {
			activity = {
				points: 0,
			};
		}
		setLoading(false);
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar style={{ "--border-style": "none" }}>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
				</IonToolbar>
				<IonProgressBar value={progress} />
			</IonHeader>
			<IonContent>
				<React.Fragment>
					<div className="onboarding-main-div">
						{loading === false ? (
							<React.Fragment>
								<div className="onboarding-title">Congrats you earned bonus points this week!</div>
								<img className="onboarding-hero-image" src="assets/images/bonus.jpg" alt="" style={{ paddingBottom: "10%" }} />
								<IonGrid style={{ marginLeft: "5%", marginRight: "5%", fontSize: "18px" }}>
									<IonRow style={{ paddingBottom: "15px" }}>
										<IonCol size="8">Weekly goal </IonCol>
										<IonCol size="4" style={{ textAlign: "center" }}>
											{goalObj.goalPoints} points
										</IonCol>
									</IonRow>
									{activity && activity.activity && activity.points && activity.points > 0 ? (
										<IonRow>
											<IonCol size="8">{activity.activity}</IonCol>
											<IonCol size="4" style={{ textAlign: "center" }}>
												({activity.points} points)
											</IonCol>
										</IonRow>
									) : (
										""
									)}
									{bonusPoints && bonusPoints.bonusPoints > 0 ? (
										<IonRow>
											<IonCol size="8">First week bonus points</IonCol>
											<IonCol size="4" style={{ textAlign: "center" }}>
												({bonusPoints.bonusPoints} points)
											</IonCol>
										</IonRow>
									) : (
										""
									)}
									<IonRow class="sumRow">
										<IonCol size="8">Points needed for goal</IonCol>
										<IonCol size="4" style={{ textAlign: "center", color: "green" }}>
											{goalObj.goalPoints - totalPoints} points
										</IonCol>
									</IonRow>
								</IonGrid>
							</React.Fragment>
						) : (
							<React.Fragment>
								<div className="onboarding-title">Calculating what your first week looks like...</div>
								<img
									style={{ width: "60%", marginLeft: "20%", borderRadius: "15px" }}
									src="https://fitsquad-ui-assets.s3.us-west-2.amazonaws.com/calculating-cat.gif"
									alt="computing monkey"
								/>
							</React.Fragment>
						)}
					</div>
				</React.Fragment>
			</IonContent>
			{loading === false ? (
				<IonFooter className="onboarding-footer">
					<IonButton routerLink="/onboarding/savephoto" className="onboarding-advance-button">
						Sounds great!
					</IonButton>
				</IonFooter>
			) : (
				<React.Fragment>
					{""}
					{/*				
					11/27/21: Initially let someone skip this but in Usertesting, a user just hit the button. So now no skipping
					<IonFooter className="onboarding-footer">
						<IonButton routerLink="/onboarding/savephoto" className="onboarding-advance-button">
							Skip this...
						</IonButton>
					</IonFooter>
				*/}
				</React.Fragment>
			)}
		</IonPage>
	);
};

export default InitialBonus;
