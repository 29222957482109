import { Plugins } from "@capacitor/core";
import { IonButton, IonCol, IonContent, IonInput, IonItem, IonLabel, IonLoading, IonPage, IonRow } from "@ionic/react";
import Amplify, { Auth } from "aws-amplify";
// import AWS from "aws-sdk";
import React, { useContext, useRef, useState } from "react";
import { useHistory } from "react-router";
import aws_exports from "../aws-exports";
import AuthContext from "../contexts/GlobalState";
import "../css/Register.css";
import { PHP_URL_NAME } from "../models/constants";
import { initAnalytics } from "../services/AnalyticsService";
import { getPHP } from "../services/APIService";
import { getRecentUpdatesFromAPI, processUpdates } from "../services/NotificationService";
import { getGoal } from "../services/PointsAndGoalsService";
import { getSquad, getSquadGoal } from "../services/SquadService";
import { StorageService } from "../services/StorageService";
import { getCognitoUsernameFromUsername, getUser, removeBadgesFromUserObject, updateUser } from "../services/UserService";
//import { FacebookLoginResponse } from "@rdlabo/capacitor-facebook-login";
const { PushNotifications, Device } = Plugins;
//const { FacebookLogin } = Plugins;
const storageService = new StorageService();
// Todos:
// - Implement social sign in

const LoginScreen = () => {
	const loggingInMessage = "Logging you in";
	//const FACEBOOK_PERMISSIONS = ["email", "public_profile"];
	const history = useHistory();
	const { login, setPullingUpdates } = useContext(AuthContext);
	const [loggingIn, setLoggingIn] = useState({
		visible: false,
		message: "",
	});

	Amplify.configure(aws_exports);

	// const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
	// 	apiVersion: "2016-04-18",
	// 	region: "us-west-2",
	// 	accessKeyId: "AKIAZYATSPDT2UHNR77Y",
	// 	secretAccessKey: "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",
	// });

	const [emailError, setEmailError] = useState({
		visible: false,
		message: null,
	});
	const [passwordError, setPasswordError] = useState({
		visible: false,
		message: null,
	});

	const passwordRef = useRef(null);
	const emailRef = useRef(null);

	async function signInLocal(data) {
		//console.log({ data });
		let username = data.email;
		let password = data.password;
		let awsResponse;
		try {
			let response = await Auth.signIn({
				username,
				password,
			});
			console.log("Response from AWS signIn: " + JSON.stringify(response));
			awsResponse = JSON.stringify(response);
		} catch (error) {
			if (error.code === "NotAuthorizedException") {
				// if it's not authorized, try with the cognitoUserName
				console.log("Login failed. Trying with cognito Username");
				try {
					// let getCognitoUserNameAPI = PHP_URL_NAME + "fitsquad/getCognitoUserName.php?email=" + username;
					// let cognitoUserNameResult = await getPHP(getCognitoUserNameAPI);
					// console.log({ cognitoUserNameResult });
					// if (cognitoUserNameResult.result === 400) {
					// 	throw error;
					// }
					// username = cognitoUserNameResult.cognitoUserName;
					// console.log({ username });
					username = await getCognitoUsernameFromUsername(data.email);
					let response = await Auth.signIn({
						username,
						password,
					});
					//console.log("Response from AWS signIn: " + JSON.stringify(response));
					awsResponse = JSON.stringify(response);
					return awsResponse;
				} catch (error) {
					console.log("Error from AWS signIn: " + JSON.stringify(error));
					awsResponse = JSON.stringify(error);
					return awsResponse;
				}
			}
		}
		return awsResponse;
	}

	const validatePassword = (password) => {
		if (password.length < 8) {
			setPasswordError({
				visible: true,
				message: "Password should be at least 8 characters",
			});
			return false;
		} else {
			setPasswordError({
				visible: false,
				message: null,
			});
			return true;
		}
	};

	const doLogin = async () => {
		let user = null;
		try {
			user = JSON.parse(await getUser(null, false));
		} catch (error) {
			console.log("Error: " + error);
		}
		if (user) {
			let deviceInfo = Device.getInfo();
			let userObject = user;
			if (userObject.appStore === false) {
				let body = {
					appStore: true,
				};
				await updateUser(body);
			}
			console.log({ userObject });
			userObject.deviceInfo = deviceInfo;
			await initAnalytics(userObject.cognitoId);
			// setPullingUpdates(true);
			// getRecentUpdatesFromAPI().then((recentUpdatesFromAPI) => {
			// 	processUpdates(recentUpdatesFromAPI, userObject).then(async () => {
			// 		let numUpdates = parseInt(await storageService.getItem("recentUpdateCount"));
			// 		console.log("numUpdates: " + numUpdates);
			// 		if (numUpdates === "") {
			// 			numUpdates = 0;
			// 		}
			// 		setPullingUpdates(false);
			// 	});
			// });

			if (deviceInfo.platform === "android" || deviceInfo.platform === "ios") {
				PushNotifications.removeAllDeliveredNotifications();
				removeBadgesFromUserObject();
				//setupNotificationListener(history);
			}
			// Pre-load a bunch of info
			console.log("Preloading info");
			console.log("Getting user goal");
			await getGoal(null, true, null, null, true, true);
			console.log("Goal retrieved");
			for await (let squad of userObject.squads) {
				console.log("Getting user squad ID " + squad.id);
				await getSquad(squad.id, null, null, true);
				console.log("Getting user squad goal for squad " + squad.id);
				await getSquadGoal(squad.id, null, null, true, true);
			}
			return {
				success: true,
				userObject: userObject,
			};
		} else {
			console.log("No user object found entry screen");
			return "No user object found entry screen";
		}
	};

	const validateData = async () => {
		setEmailError({
			visible: false,
			message: "",
		});
		setPasswordError({
			visible: false,
			message: "",
		});
		let validData = true;
		let response;
		let data = {
			password: passwordRef.current.value,
			email: emailRef.current.value,
		};
		// First make sure the values are valid
		//validData = validateEmail(emailRef.current.value);
		validData = validatePassword(passwordRef.current.value);
		console.log("Valid Data: " + validData);

		if (validData === false) {
			return false;
		}

		setLoggingIn({ visible: true, message: loggingInMessage });
		response = JSON.parse(await signInLocal(data));
		//console.log("signInResponse: " + JSON.stringify(response));
		if (response) {
			if (response.name) {
				// for some reason AWS responds with the name value sometimes :/
				response.code = response.name;
			}
			let message;
			if (response.username) {
				console.log("Successful login");
				let expirationDate = response["signInUserSession"]["idToken"]["payload"]["exp"];
				let refreshToken = response["signInUserSession"]["refreshToken"]["token"];
				let idToken = response["signInUserSession"]["idToken"]["jwtToken"];
				await storageService.setItem("idToken", idToken);
				await storageService.setObject("expirationDate", expirationDate);
				console.log("Expiration date stored: " + expirationDate);
				await storageService.setItem("refreshToken", refreshToken);
				await storageService.setItem("displayUsername", data.username);
				await storageService.setItem("fullUsername", data.email);
				await storageService.setItem("password", data.password);
				let loginResult = await doLogin();
				if (loginResult.success && loginResult.success === true) {
					console.log("Reached?");
					setLoggingIn({ visible: false, message: "" }); // makes sure its on the whole time
					await login(loginResult.userObject);
					history.push("/");
				} else {
					setLoggingIn({ visible: false, message: "" }); // makes sure its on the whole time
					setPasswordError({
						visible: true,
						message: loginResult,
					});
				}
			} else {
				setLoggingIn({ visible: false, message: "" }); // makes sure its on the whole time
				switch (response.code) {
					case "UserNotFoundException":
					case "AuthError":
						message = "No user found with that email address";
						setEmailError({
							visible: true,
							message: message,
						});
						break;
					case "NotAuthorizedException":
						message = "Incorrect username and/or password";
						setPasswordError({
							visible: true,
							message: message,
						});
						break;
					default:
						message = response.message;
						setEmailError({
							visible: true,
							message: message,
						});
						break;
				}
			}
		}
	};
	/*
	const fbLogin = async () => {
		console.log("fb login triggering");
		const result1 = await FacebookLogin.login({ FACEBOOK_PERMISSIONS });
		console.log("Result1: " + JSON.stringify(result1));
		if (result1.accessToken) {
			// Login successful.
			console.log("Result1: " + JSON.stringify(result1));
			//const result = await FacebookLogin.getProfilePictureUri();
			//console.log(`Facebook user's email is ${result}`);
			let url = `https://graph.facebook.com/${result1.accessToken.userId}?metadata=1&access_token=${result1.accessToken.token}`;
			console.log("URL: " + url);
			let user = await fetch(url, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
			});
			console.log("user: " + JSON.stringify(user));
		} else {
			console.log("Logging out");
			FacebookLogin.logout();
		}
	};
*/
	return (
		<IonPage>
			<IonContent>
				<IonLoading isOpen={loggingIn.visible} onDidDismiss={() => setLoggingIn({ visible: false, message: "" })} message={loggingIn.message} />
				<div className="login-main-div">
					<p className="login-title">Sign In</p>
					<IonItem>
						<IonLabel className="login-label" position="stacked">
							Email
						</IonLabel>
						<IonInput ref={emailRef} name="email" type={"email"} placeholder="Iam@GoodSquadmate.com" enterkeyhint="next" required></IonInput>
						{emailError.visible === true ? <p className="error-message">{emailError.message}</p> : ""}
					</IonItem>
					<IonItem>
						<IonLabel className="login-label" position="stacked">
							Password
						</IonLabel>
						<IonInput ref={passwordRef} name="password" type={"password"} placeholder="8 characters" minlength="8" enterkeyhint="done" required></IonInput>
						{passwordError.visible === true ? <p className="error-message">{passwordError.message}</p> : ""}
					</IonItem>
					<div className="forgot-password">
						<IonButton className="button-as-link" routerLink={"/forgotpassword"}>
							Forgot Password?
						</IonButton>
					</div>
					<div className="login-footer">
						{/*
													<IonRow>
							<IonCol size="2"></IonCol>
							<IonCol size="8">
								<IonButton onClick={fbLogin} expand="block">
									Sign in with Facebook!
								</IonButton>
							</IonCol>
							<IonCol size="2"></IonCol>
						</IonRow>

							*/}

						<IonRow>
							<IonCol size="2"></IonCol>
							<IonCol size="8">
								<IonButton onClick={validateData} expand="block">
									Sign in
								</IonButton>
							</IonCol>
							<IonCol size="2"></IonCol>
						</IonRow>

						<p style={{ textAlign: "center", fontSize: "15px" }}>
							Don't have an account yet?{" "}
							<a className="link" href="/onboarding">
								Create an account
							</a>
						</p>
					</div>
					{/*
				
				*/}
				</div>
			</IonContent>
		</IonPage>
	);
};
export default LoginScreen;
