import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import React from "react";

const ChallengeTerms = () => {
	return (
		<React.Fragment>
			<div style={{ margin: "0px 16px" }}>
				<h1>Official Rules for Challenges</h1>
				<p>
					<em>Last Updated: April 17, 2022</em>
				</p>
				<p>
					Throughout the year, FitSquad offers its users the opportunity to challenge themselves by participating in what we call a Challenge. A Challenge is a
					specified period of time where participants earn Challenge Points by meeting their personal goals and by their designated squad meeting its goals. During
					a challenge, the user will have access to certain Perks, and if the user completes the Challenge, they can receive one or more Rewards, as described
					below.
				</p>
				<h2>Disclaimer</h2>
				<p>
					FITSQUAD CHALLENGES, SUBSCRIPTIONS, CONTESTS, SWEEPSTAKES, AS WELL AS ANY AND ALL PRIZES, REWARDS, PERKS, AND GIVEAWAYS, ARE IN NO WAY ASSOCIATED WITH,
					AFFILIATED WITH, SPONSORED BY, OR ENDORSED BY APPLE, INC., GOOGLE, LLC., ALPHABET, INC., FACEBOOK, INC., GARMIN, INC., FITBIT, INC., OR AMAZON, INC.
					ALL TRADEMARKS ARE PROPERTY OF THEIR RESPECTIVE OWNERS.
				</p>
				<h2>Registration</h2>
				<p>
					Registering for a Challenge is easy. Anyone can register for a Challenge in the FitSquad app or on the FitSquad website when registration for a Challenge is
					open. To register for a Challenge, you must be a registered user of the FitSquad app. When registering, you will select one of the Squads
					that you are a member of to register with. This means that you will receive Challenge Points for that Squad’s performance which will count towards your
					Challenge Score.
				</p>
				<h2>Activity Points</h2>
				<p>
					Each week, the app will provide you with a new goal. This goal is based on your performance from previous weeks. You may change this goal at any time
					for any reason in the app. Additionally, the app will create a goal for each Squad in the app, which cannot be changed once it is created. These goals
					represent a number of Activity Points. Activity Points are earned by logging activities in the app, with a connected FitBit, or any other fitness
					tracker that shares steps or workout data with Apple HealthKit on the device that you have the FitSquad app installed. When you do an activity and
					earn points, you and every Squad you are a member of will receive Activity Points towards the currently active weekly goals. The number of Activity
					Points awarded for each activity will vary based on the duration and intensity of the activity according to the following table:
				</p>
				<table>
					<tbody>
						<tr>
							<td width="390">
								<strong>Activity Type</strong>
							</td>
							<td width="234">
								<strong>Activity Points Earned</strong>
							</td>
						</tr>
						<tr>
							<td width="390">Low intensity activities (0-5 calories per minute)</td>
							<td width="234">1 point per minute</td>
						</tr>
						<tr>
							<td width="390">Moderate intensity activities (6-9 calories per minute)</td>
							<td width="234">1.5 points per minute</td>
						</tr>
						<tr>
							<td width="390">High intensity activities (10+ calories per minute)</td>
							<td width="234">2 points per minute</td>
						</tr>
						<tr>
							<td width="390">Steps</td>
							<td width="234">1 point per 100 steps taken</td>
						</tr>
					</tbody>
				</table>
				<p>&nbsp;</p>
				<p>
					Calories per minute are generally estimated, and will vary from activity to activity, as well as the device capturing the activity data and other
					factors. Some devices may not report any calories or other data that the app can use to correctly classify an activity, in which case you can always
					manually create an activity and select its intensity in the app to ensure that you are credited with an appropriate number of Activity Points.
				</p>
				<h2>Goal Evaluation</h2>
				<p>
					Your personal goals end at midnight on Sunday in the timezone that the app believes you are in. If the app is unable to determine your timezone it
					will default to assuming that you are in the Pacific Timezone. New user goals are created immediately after each goal is evaluated with will be active
					for one week. All squad goals expire at midnight on Sunday night, Pacific time, and are created at midnight on Monday nights. Activity and Bonus
					Points earned in the period of time between when the Squad goal ends and a new one is created are credited towards the new goal.
				</p>
				<h2>Earning Challenge Points</h2>
				<p>
					At the end of each week or when they expire, every goal is evaluated. If your Activity Points are equal to or greater than your goal,
					your goal is considered to have been completed successfully and you will be awarded 1 Challenge Point.
				</p>
				<h2>Forgiveness Tokens</h2>
				<p>
					Some Challenge registrations will include one or more Forgiveness Tokens. A Forgiveness Token is an item that will be automatically used if you fail to
					meet your goal for a week. Instead of the goal evaluating as a failure, it will be considered a success, you will still earn the Challenge Point for your
					goal that week, and your balance of Forgiveness Tokens will be decremented. Forgiveness Tokens will have no impact on your Squad’s goal evaluation for
					the week.
				</p>
				<h2>Completing the Challenge</h2>
				<p>
					At the end of the Challenge period, the number of Challnege Points you have accumulated will be compared with the number of points necessary to win the
					Challenge. If you have earned enough Challenge Points, you will have won and any contingent rewards will be made available to you. If you did not win the
					Challenge, you will not receive any of the contingent rewards.
				</p>
				<h2>Challenge Rewards and Challenge Perks</h2>
				<p>
					Challenges generally will include one or more rewards, which are contingent on your successful completion of the Challenge (you must win the Challenge to
					receive these rewards), and one or more perks, which you will receive throughout the duration of the Challenge, regardless of your performance. These
					rewards and perks will vary by Challenge.
				</p>
				<h2>Donations</h2>
				<p>
					Any Challenge Rewards which are charitable donations will be made by FitSquad directly to the selected charity on behalf of FitSquad. As Challenge Rewards
					are contingent on you completeing the Challenge, if you fail to complete the Challenge, FitSquad will make a smaller donation to the charity, at FitSquad’s
					discretion.
				</p>
				<h2>Refunds</h2>
				<p>No refunds will be provided for any Challenge registrations for any reason.</p>
				<h2>Errors, Inaccuracies, and Omissions</h2>
				<p>
					Occasionally there may be information on our site or in the app that contains typographical errors, inaccuracies, or omissions that may related to
					product descriptions, pricing, promotions, offers, Challenge Points, Activity Points, goal performance, goal predictions, availability, inventory, or
					other matters. We reserve the right to correct any errors, inaccuracies, or omissions, and to change or update information or cancel orders if any
					information is inaccurate at any time without prior notice (including after you have submitted your order).
				</p>
				<p>
					Several perks may be available to users registered for a Challenge, based on their tier and include goal predictions in the app, bonus
					points, daily workout ideas, forgiveness tokens. Additionally, several contingent rewards are also available which, depending on the user’s tier, may
					include a donation to the Special Olympics from FitSquad, a FitSquad t-shirt, a Challenge medal, and/or mystery bags. Mystery bags, when
					opened, may contain a forgiveness token, 5%, 10%, or 15% point bonuses, an additional mystery bag, free Challenge registration for a future Challenge, free 
                    FitBit, a $20 Amazon gift card, a personalized message from a celebrity, a $25 massage gift
					card, Challenge Points for a future Challenge, or free goal predictions for life.
				</p>
			</div>
		</React.Fragment>
	);
};

export default ChallengeTerms;
