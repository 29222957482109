export interface ChatMessage {
    id?: string;
    msg: string;
    date: string;
    responseButtonId?: number;
    sender: ChatMessageType;
    imageUrl?: string;
    size: string;
}

export enum ChatMessageType {
    User,
    Bot
}

export interface ButtonModel {
        text?: string;
        messageID?: number;
        type?: string;
}

export interface BotMessage {
        buttons?: [ButtonModel];
        command?: string;
        imageUrl?: string;
        contextID?: number;
        messageText?: string;    
}