/* eslint-disable no-eval */
// import { Plugins } from "@capacitor/core";
import { IonButton, IonCol, IonIcon, IonTextarea } from "@ionic/react";
import { sendSharp } from "ionicons/icons";
import React, { useEffect, useRef } from "react";
import Emoji from "react-emoji-render";
import "../../css/MessageInput.css";
import { inputFocus, sendButtonResponse, submitText } from "../../services/BotService";

// const { Camera } = Plugins;

const BotMessageInput = ({
	setButtons,
	buttons,
	setMessages,
	setBotResponseMessages,
	setTypingMessage,
	setAnimation,
	messages,
	showAddActivityModal,
	activityData,
	setContextID,
	command,
	setCommand,
}) => {
	const textInput = useRef(null);
	var globalContext = "";

	useEffect(() => {
		if (messages && messages[messages.length - 1].buttons && messages[messages.length - 1].buttons.length > 0) {
			setButtons(messages[messages.length - 1].buttons);
		} else {
			setButtons(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function handleKeyUp(event) {
		if (event.keyCode === 13) {
			submitText(textInput, globalContext, setMessages, setBotResponseMessages, setButtons);
		}
	}

	const getColSize = () => {
		let colSize = 0;

		if (buttons !== null && buttons !== undefined) {
			colSize = Math.round(10 / buttons.length);
			/*
			if (buttons.length === 1) {
				colSize = 10;
			} else if (buttons.length === 2) {
				colSize = 4;
			} else if (buttons.length === 3) {
				colSize = 3;
			} else if (buttons.length === 4) {
				colSize = 3;
			} else if (buttons.length === 5) {
				colSize = 2;
			} else if (buttons.length === 6) {
				colSize = 2;
			}
			*/
		}

		return colSize.toString();
	};

	// const takePhotoHandler = async () => {
	// 	const photo = await Camera.getPhoto({
	// 		allowEditing: false,
	// 		correctOrientation: true,
	// 		resultType: CameraResultType.Uri,
	// 		source: CameraSource.Prompt,
	// 		quality: 80,
	// 	});
	// 	console.log({ photo });
	// 	//submitText(photo.imageUrl);
	// 	//let updateUserResponse = await updateUser(body);
	// 	//console.log("Update User Response: ", { updateUserResponse });
	// };

	// If there's buttons, show the buttons
	//console.log("We got buttons!");
	// if not show a text box with a submit button
	//console.log("No buttons:", { buttons });
	return (
		<React.Fragment>
			{buttons !== null && buttons !== undefined && buttons.length ? (
				<React.Fragment>
					{buttons.map((button, i) => {
						return (
							<IonCol key={i} size={getColSize()} size-lg>
								<IonButton
									fill="solid"
									size="small"
									expand="block"
									className="chat-footer-button"
									color="secondary"
									style={{ height: "100%" }}
									onClick={
										() => {
											sendButtonResponse(button, globalContext, setBotResponseMessages, setButtons, setMessages, setContextID, command, setCommand);
										} // this is the function called when a button is tapped. It sends the full button object to the function
									}
								>
									<Emoji text={button.text} />
								</IonButton>
							</IonCol>
						);
					})}
				</React.Fragment>
			) : (
				<React.Fragment>
					<IonCol size="8">
						<IonTextarea
							autoGrow="true"
							rows="1"
							className="chat-input"
							type="text"
							ref={textInput}
							onKeyUp={handleKeyUp}
							onFocus={(e) => inputFocus(setMessages)}
						></IonTextarea>
					</IonCol>
					{/*
					<IonCol size="1">
						<IonButton onClick={() => takePhotoHandler()} className="submit-button" color="chat-bar-button" style={{ fontSize: "0px" }}>
							<IonIcon size="small" icon={cameraOutline}></IonIcon>
						</IonButton>
					</IonCol>
					*/}
					<IonCol size="2">
						<IonButton
							onClick={() => submitText(textInput, globalContext, setMessages, setBotResponseMessages, setButtons)}
							className="submit-button"
							color="chat-bar-button"
						>
							<IonIcon size="small" icon={sendSharp}></IonIcon>
						</IonButton>
					</IonCol>
				</React.Fragment>
			)}
		</React.Fragment>
	);
};

export default BotMessageInput;
