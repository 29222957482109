import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButton,
	IonItem,
	IonLoading,
	IonLabel,
	IonItemGroup,
	IonItemDivider,
	IonToggle,
	IonIcon,
	getPlatforms,
	IonAlert,
	IonButtons,
	IonBackButton,
	IonPopover,
} from "@ionic/react";
import React, { useState, useEffect, useContext } from "react";
import { HealthKit } from "@ionic-native/health-kit";
import { StorageService } from "../services/StorageService";
import AuthContext from "../contexts/GlobalState";
import { useHistory } from "react-router";
import { eyeOffOutline, eyeOutline, informationCircleOutline } from "ionicons/icons";
import { Plugins } from "@capacitor/core";
import { getUser, getUserID, updateUser, updateUserSetting } from "../services/UserService";
import { logGenericEvent, logPageView } from "../services/AnalyticsService";
import { areNotificationsAuthorized, enableNotifications, sendSlackNotification, setupNotificationListener } from "../services/NotificationService";
import { convertHKActivityToReadable, loadWorkoutData, pullStepsByDayLast8days } from "../services/HealthKitService";
import moment from "moment";
import { addHKActivity, sendStepsToServer } from "../services/ActivitiesService";
import "../css/Settings.css";
import { Deploy } from "cordova-plugin-ionic";

function UserSettings() {
	const storageService = new StorageService();
	const [pushNotifications, setPushNotifications] = useState(true);
	const [showPassword, setShowPassword] = useState(false);
	const [password, setPassword] = useState("");
	const [fakePassword, setFakePassword] = useState("");
	const [displayUsername, setDisplayUsername] = useState("");
	const [pushToken, setPushToken] = useState("");
	const [version, setVersion] = useState();
	const [user, setUser] = useState();
	const [loading, setLoading] = useState({
		visible: false,
		message: null,
	});
	const [infoMessage, setInfoMessage] = useState({
		visible: false,
		message: null,
	});

	/** App Connections */
	const [healthKitEnabled, setHealthKitEnabled] = useState(false);
	const [sending, setSending] = useState({
		visible: false,
		message: null,
	});
	const [fitBitEnabled, setFitBitEnabled] = useState(false);
	const [fitBitAlert, setFitbitAlert] = useState();

	/** Individual Notifications  */
	const [morningUpdate, setMorningUpdate] = useState();
	const [goalProgressUpdate, setGoalProgressUpdate] = useState();
	const [activityNotifications, setActivityNotifications] = useState();

	/** Squad Notifications */
	const [squadActivities, setSquadActivities] = useState();
	const [squadMessages, setSquadMessages] = useState();
	const [competitorActivities, setCompetitorActivities] = useState();
	const [competitorMessages, setCompetitorMessages] = useState();
	const [squadUpdates, setSquadUpdates] = useState();

	const history = useHistory();
	const { logout } = useContext(AuthContext);
	const [loggingin, setLoggingIn] = useState(false);
	const [updating, setUpdating] = useState(false);
	const [disconnectHealthKitNotice, setDisconnectHealthKitNotice] = useState(false);
	const [disconnectFitbitNotice, setDisconnectFitbitNotice] = useState(false);
	const [logoutConfirmation, setLogoutConfirmation] = useState(false);
	const { PushNotifications, LocalNotifications, Device } = Plugins;

	useEffect(() => {
		const setToggles = async () => {
			await storageService.getItem("password").then((result) => {
				console.log("Result from fetching password: " + result);
				let fkpassword = "";
				setPassword(result);
				for (let i = 0; i < password.length; i++) {
					fkpassword = fkpassword + "*";
				}
				setFakePassword(fkpassword);
			});
			let user = JSON.parse(await getUser(null, true));
			setUser(user);
			//console.log("user: ", { user });
			/*
			if (user.healthkitConnected === true) {
				setHealthKitEnabled(true);
			}
			*/
			if (user.healthkitConnected === true) {
				console.log("DB says user has HK enabled");
				setHealthKitEnabled(true);
			}
			healthKitAvailable().then((result) => {
				console.log(`healthKitEnabled ${result}`);
				if (result !== undefined) {
					setHealthKitEnabled(result);
				}
			});

			if (user.fitbitConnected === true) {
				setFitBitEnabled(true);
			}
			if (user.settings) {
				console.log("User has settings");
				let settings = user.settings;
				console.log("Settings: ", { settings });
				/** morningUpdate */
				if (settings.hasOwnProperty("morningUpdate")) {
					console.log("morningUpdate setting: ", settings.morningUpdate);
					setMorningUpdate(settings.morningUpdate);
				} else {
					setMorningUpdate(true);
					await updateUserSetting("morningUpdate", true);
				}
				/** goalProgressUpdate, setGoalProgressUpdate */
				if (settings.hasOwnProperty("goalProgressUpdate")) {
					console.log("goalProgressUpdate setting: ", settings.goalProgressUpdate);
					setGoalProgressUpdate(settings.goalProgressUpdate);
				} else {
					setGoalProgressUpdate(true);
					await updateUserSetting("goalProgressUpdate", true);
				}
				/** activityNotifications, setActivityNotifications */
				if (settings.hasOwnProperty("activityNotifications")) {
					console.log("activityNotifications setting: ", settings.activityNotifications);
					setActivityNotifications(settings.activityNotifications);
				} else {
					setActivityNotifications(true);
					await updateUserSetting("activityNotifications", true);
				}
				/** squadActivities, setSquadActivities */
				if (settings.hasOwnProperty("squadActivities")) {
					console.log("squadActivities setting: ", settings.squadActivities);
					setSquadActivities(settings.squadActivities);
				} else {
					setSquadActivities(true);
					await updateUserSetting("squadActivities", true);
				}
				/** squadMessages, setSquadMessages */
				if (settings.hasOwnProperty("squadMessages")) {
					console.log("squadMessages setting: ", settings.squadMessages);
					setSquadMessages(settings.squadMessages);
				} else {
					setSquadMessages(true);
					await updateUserSetting("squadMessages", true);
				}
				/** competitorActivities, setCompetitorActivities */
				if (settings.hasOwnProperty("competitorActivities")) {
					console.log("competitorActivities setting: ", settings.competitorActivities);
					setCompetitorActivities(settings.competitorActivities);
				} else {
					setCompetitorActivities(true);
					await updateUserSetting("competitorActivities", true);
				}
				/** competitorMessages, setCompetitorMessages */
				if (settings.hasOwnProperty("competitorMessages")) {
					console.log("competitorMessages setting: ", settings.competitorMessages);
					setCompetitorMessages(settings.competitorMessages);
				} else {
					setCompetitorMessages(true);
					await updateUserSetting("competitorMessages", true);
				}
				/** squadUpdates, setSquadUpdates */
				if (settings.hasOwnProperty("squadUpdates")) {
					console.log("squadUpdates setting: ", settings.squadUpdates);
					setSquadUpdates(settings.squadUpdates);
				} else {
					setSquadUpdates(true);
					await updateUserSetting("squadUpdates", true);
				}
			}
		};
		logPageView("user-settings");
		setToggles();
		refreshAll();
		//healthKitAvailable();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function doLogout(event, confirmation = false) {
		if (confirmation === false) {
			setLogoutConfirmation(true);
		} else {
			await logout();
			history.replace("/");
		}
	}

	async function checkForPush() {
		let authorized = await areNotificationsAuthorized();
		if (authorized === true) {
			let pushToken = await storageService.getItem("pushToken");
			setPushToken(pushToken);
			setPushNotifications(true);
		} else {
			setPushToken("");
			setPushNotifications(false);
		}
	}

	async function refreshAll() {
		console.log("Refreshing");
		checkForPush();
		let user = JSON.parse(await getUser(null));
		console.log("user.userName: " + user["userName"]);
		setDisplayUsername(user.userName);
		storageService.getItem("idToken").then((result) => {
			console.log("Result from fetching idToken: " + result);
		});
		storageService.getItem("refreshToken").then((result) => {
			console.log("Result from fetching refreshToken: " + result);
		});
		storageService.getItem("expirationDate").then((result) => {
			console.log("Result from fetching expirationDate: " + result);
		});
		let currentVersion = await Deploy.getCurrentVersion();
		setVersion(JSON.stringify(currentVersion));
	}

	const healthKitAvailable = async () => {
		//console.log("Checking for healthKit");
		let options = {
			readTypes: [
				"HKQuantityTypeIdentifierStepCount",
				"HKWorkoutTypeIdentifier",
				"HKQuantityTypeIdentifierActiveEnergyBurned",
				"HKQuantityTypeIdentifierAppleExerciseTime",
			],
		};
		let hkAvailable = await HealthKit.available();
		let hkAuthorized = await HealthKit.checkAuthStatus(options);
		console.log({ hkAvailable });
		console.log({ hkAuthorized });
		sendSlackNotification(
			"*NEW EVENT*\nUsername: " + user.userName + "\nHealthKit Available: " + hkAvailable + "\nHealthKit Authorization: " + hkAuthorized,
			"keyEvent"
		);
		HealthKit.available().then(
			(data) => {
				HealthKit.checkAuthStatus(options).then((status) => {
					//console.log("HealthKit status: ", { status });
					console.log({ status });
					sendSlackNotification("*NEW EVENT*\nUsername: " + user.userName + "\nHealthKit Authorization result:" + status, "keyEvent");
					if (status === "authorized") {
						return true;
					} else {
						return false;
					}
				});
			},
			(err) => {
				console.log("error in HK available: ", err);
			}
		);
	};

	function connectHealthKit() {
		if (healthKitEnabled === false) {
			setUpdating(true);
			//console.log("Attempting to connect to healthKit");
			HealthKit.available().then(
				(data) => {
					if (data) {
						let options = {
							readTypes: [
								"HKQuantityTypeIdentifierStepCount",
								"HKWorkoutTypeIdentifier",
								"HKQuantityTypeIdentifierActiveEnergyBurned",
								"HKQuantityTypeIdentifierAppleExerciseTime",
							],
						};
						HealthKit.requestAuthorization(options).then((result) => {
							console.log("Authorization complete");
							console.log({ result });
							sendSlackNotification("*NEW EVENT*\nUsername: " + user.userName + "\nHealthKit Authorization result:" + result, "keyEvent");
							setHealthKitEnabled(true);
							let body = {
								healthkitConnected: true,
							};
							updateUser(body)
								.then((response) => {
									console.log("Response from update user: ", { response });
								})
								.catch((error) => {
									console.log("Error from PATCH user: ", { error });
								});
						});
					}
				},
				(err) => {
					setHealthKitEnabled(false);
					console.error("error in HK available: ", err);
					alert(
						"Yikes. There was an error connecting to HealthKit: ",
						err,
						". Please try again. If the issue continues please contact support at support@fitsquadapp.com"
					);
				}
			);
			setUpdating(false);
		} else {
			setDisconnectHealthKitNotice(true);
		}
	}

	const disconnectHealthKit = async () => {
		// Data will still flow in
		let body = {
			healthkitConnected: false,
		};
		updateUser(body)
			.then((response) => {
				console.log("Response from update user: ", { response });
				setHealthKitEnabled(false);
			})
			.catch(async (error) => {
				let user = JSON.parse(await getUser(null, false));
				console.log("Error from PATCH user: ", { error });
				alert("Error disconnecting healthKit: ", { error });
				sendSlackNotification(`*NEW ERROR*\nError disconnecting healthKit\nUser ID: ${user.cognitoId}\nUser Name: ${user.userName}`);
			});
	};

	const syncHealthKit = async () => {
		//TODO: Show loading screen while loading workouts
		let workoutArray = await loadWorkoutData();
		let numberOfWorkouts = workoutArray.length;
		if (numberOfWorkouts > 0) {
			setSending({ visible: true, message: "Sending " + workoutArray.length + " workouts" });
		} else {
			setSending({ visible: true, message: "No new workouts to sync" });
		}
		let i = 1;
		for (let element of workoutArray) {
			let readableWorkoutName = convertHKActivityToReadable(element.activityType);
			let data = {
				dataType: "workout",
				activityType: readableWorkoutName,
				startDate: element.startDate,
				endDate: element.endDate,
				totalDistance: 0, // cant send these because of the stupid plugin
				totalDistanceUnit: "meters",
				value: element.duration,
				valueUnit: "sec",
				totalEnergyBurned: element.calories,
				reportId: element.UUID,
				sourceName: element.sourceName,
				timeZone: "",
				weatherTemperature: "",
				humidity: "",
			};
			console.log("Sending: ", { data });
			setSending({ visible: false, message: null });
			setSending({ visible: true, message: "Sending workout " + i + " of " + numberOfWorkouts });
			await addHKActivity(data);
			i++;
		}
		let stepsArray = await pullStepsByDayLast8days();
		await sendStepsToServer(stepsArray);
	};

	const connectFitBit = async () => {
		if (fitBitEnabled === false) {
			let userID = await getUserID();
			let fitBitLink = "https://www.fitbit.com/oauth2/authorize?response_type=code&client_id=22BLTK&scope=activity&state=" + userID;
			logGenericEvent("attempt fitbit connection");
			window.open(fitBitLink, "_system");
			setFitbitAlert(true);
		} else {
			setDisconnectFitbitNotice(true);
		}
	};

	const disconnectFitbit = async () => {
		let body = {
			fitbitConnected: false,
		};
		updateUser(body)
			.then((response) => {
				console.log("Response from update user: ", { response });
				setFitBitEnabled(false);
			})
			.catch(async (error) => {
				let user = JSON.parse(await getUser(null, false));
				console.log("Error from PATCH user: ", { error });
				alert("Error disconnecting fitbit: ", { error });
				sendSlackNotification(`*NEW ERROR*\nError disconnecting fitbit\nUser ID: ${user.cognitoId}\nUser Name: ${user.userName}`, "error");
			});
	};

	const toggleShowPassword = () => {
		if (showPassword === true) {
			setShowPassword(false);
		} else {
			setShowPassword(true);
		}
	};

	const updateNotificationSetting = async (notificationType) => {
		let tempValue;
		setUpdating(true);
		switch (notificationType) {
			case "morningUpdate":
				tempValue = !morningUpdate;
				setMorningUpdate(tempValue);
				await updateUserSetting("morningUpdate", tempValue)
					.then((response) => {
						console.log("updateUser Response: ", { response });
					})
					.catch((error) => {
						alert("Error updating user: ", { error });
						setMorningUpdate(!tempValue);
					});
				break;
			case "goalProgressUpdate":
				tempValue = !goalProgressUpdate;
				setGoalProgressUpdate(tempValue);
				await updateUserSetting("goalProgressUpdate", tempValue)
					.then((response) => {
						console.log("updateUser Response: ", { response });
					})
					.catch((error) => {
						alert("Error updating user: ", { error });
						setGoalProgressUpdate(!tempValue);
					});
				break;
			case "activityNotifications":
				tempValue = !activityNotifications;
				setActivityNotifications(tempValue);
				await updateUserSetting("activityNotifications", tempValue)
					.then((response) => {
						console.log("updateUser Response: ", { response });
					})
					.catch((error) => {
						alert("Error updating user: ", { error });
						setActivityNotifications(!tempValue);
					});
				break;
			case "squadActivities":
				tempValue = !squadActivities;
				setSquadActivities(tempValue);
				await updateUserSetting("squadActivities", tempValue)
					.then((response) => {
						console.log("updateUser Response: ", { response });
					})
					.catch((error) => {
						alert("Error updating user: ", { error });
						setSquadActivities(!tempValue);
					});
				break;
			case "squadMessages":
				tempValue = !squadMessages;
				setSquadMessages(tempValue);
				await updateUserSetting("squadMessages", tempValue)
					.then((response) => {
						console.log("updateUser Response: ", { response });
					})
					.catch((error) => {
						alert("Error updating user: ", { error });
						setSquadMessages(!tempValue);
					});
				break;
			case "competitorActivities":
				tempValue = !competitorActivities;
				setCompetitorActivities(tempValue);
				await updateUserSetting("competitorActivities", tempValue)
					.then((response) => {
						console.log("updateUser Response: ", { response });
					})
					.catch((error) => {
						alert("Error updating user: ", { error });
						setCompetitorActivities(!tempValue);
					});
				break;
			case "competitorMessages":
				tempValue = !competitorMessages;
				setCompetitorMessages(tempValue);
				await updateUserSetting("competitorMessages", tempValue)
					.then((response) => {
						console.log("updateUser Response: ", { response });
					})
					.catch((error) => {
						alert("Error updating user: ", { error });
						setCompetitorMessages(!tempValue);
					});
				break;
			case "squadUpdates":
				tempValue = !squadUpdates;
				setSquadUpdates(tempValue);
				await updateUserSetting("squadUpdates", tempValue)
					.then((response) => {
						console.log("updateUser Response: ", { response });
					})
					.catch((error) => {
						alert("Error updating user: ", { error });
						setSquadUpdates(!tempValue);
					});
				break;
			default:
				break;
		}
		setUpdating(false);
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
					<IonTitle>Settings</IonTitle>
					<IonButton slot="end" color="secondary" routerLink={"/notificationplayground"}></IonButton>
				</IonToolbar>
			</IonHeader>
			<IonAlert
				isOpen={!!fitBitAlert}
				header="Good to go?"
				message="Did you successfully authorize Fitbit?"
				buttons={[
					{
						text: "No",
						role: "cancel",
						handler: () => {
							setFitbitAlert(false);
						},
					},
					{
						text: "Yes",
						role: "ok",
						handler: () => {
							history.push("/onboarding/fitbitconfirmation");
						},
					},
				]}
				onDidDismiss={() => setFitbitAlert(false)}
			/>
			<IonAlert
				isOpen={!!logoutConfirmation}
				message="You sure you would like to log out?"
				buttons={[
					{
						text: "Yes",
						role: "ok",
						handler: () => {
							doLogout(null, true);
						},
					},
					{
						text: "Cancel",
						role: "cancel",
						handler: () => {
							setLogoutConfirmation(false);
						},
					},
				]}
				onDidDismiss={() => setLogoutConfirmation(false)}
			/>
			<IonAlert
				isOpen={disconnectHealthKitNotice}
				header="Disconnect HealthKit?"
				message="Are you sure you'd like to disconnect your HealthKit from FitSquad?"
				buttons={[
					{
						text: "No",
						role: "cancel",
						handler: () => {
							setDisconnectHealthKitNotice(false);
						},
					},
					{
						text: "Yes",
						role: "ok",
						handler: () => {
							disconnectHealthKit();
						},
					},
				]}
				onDidDismiss={() => setDisconnectHealthKitNotice(false)}
			/>
			<IonAlert
				isOpen={disconnectFitbitNotice}
				header="Disconnect your Fitbit?"
				message="Are you sure you'd like to disconnect your Fitbit from FitSquad?"
				buttons={[
					{
						text: "No",
						role: "cancel",
						handler: () => {
							setDisconnectFitbitNotice(false);
						},
					},
					{
						text: "Yes",
						role: "ok",
						handler: () => {
							disconnectFitbit();
						},
					},
				]}
				onDidDismiss={() => setDisconnectFitbitNotice(false)}
			/>

			<IonContent>
				<IonLoading isOpen={loggingin} onDidDismiss={() => setLoggingIn(false)} message={"logging in"} duration={3000} />
				<IonLoading isOpen={updating} onDidDismiss={() => setUpdating(false)} message={"Updating"} duration={3000} />
				<IonLoading isOpen={sending.visible} onDidDismiss={() => setSending({ visible: false, message: null })} message={sending.message} duration={3000} />
				<IonPopover
					isOpen={infoMessage.visible}
					className="simple-message"
					onDidDismiss={(e) => {
						setInfoMessage({
							visible: false,
							message: null,
						});
					}}
				>
					<div style={{ fontSize: "1.25em", marginLeft: "5%", marginRight: "5%", marginBottom: "10%", textAlign: "center" }}>
						<br />
						<span dangerouslySetInnerHTML={{ __html: infoMessage.message }}></span>
					</div>
				</IonPopover>
				<IonItemGroup>
					<IonItemDivider className="settings-divider">
						<IonLabel>Basic Information</IonLabel>
					</IonItemDivider>
					<IonItem>
						<div className="settings-label">Username:</div>
						<div className="settings-label-value">{displayUsername ? <span> {displayUsername}</span> : ""}</div>
					</IonItem>
					<IonItem>
						<div style={{ width: "100%" }}>
							Password:&nbsp;
							{showPassword ? (
								<React.Fragment>
									{password}
									<IonButton color="light" onClick={toggleShowPassword}>
										<IonIcon slot="icon-only" icon={eyeOffOutline}></IonIcon>
									</IonButton>
								</React.Fragment>
							) : (
								<React.Fragment>
									{fakePassword}
									<IonButton color="light" onClick={toggleShowPassword}>
										<IonIcon slot="icon-only" icon={eyeOutline}></IonIcon>
									</IonButton>
								</React.Fragment>
							)}
						</div>
						<div>
							<IonButton onClick={() => history.push("/resetpassword/")}>reset</IonButton>
						</div>
					</IonItem>
				</IonItemGroup>
				<IonItemGroup>
					<IonItemDivider className="settings-divider">
						<IonLabel>App Syncing</IonLabel>
					</IonItemDivider>
					<IonItem>
						<div style={{ width: "100%" }}>FitBit Connected: </div>
						<div>
							<IonToggle checked={fitBitEnabled} onClick={connectFitBit} color="tertiary" />
						</div>
					</IonItem>
					<IonItem>
						<div style={{ width: "100%" }}>HealthKit Connected: </div>
						<div>
							<IonToggle checked={healthKitEnabled} onClick={connectHealthKit} color="tertiary" />
						</div>
					</IonItem>
					{healthKitEnabled && healthKitEnabled === true ? (
						<IonItem>
							<div style={{ width: "100%" }}>Sync data from HealthKit</div>
							<div>
								<IonButton onClick={syncHealthKit}>sync</IonButton>
							</div>
						</IonItem>
					) : (
						""
					)}
				</IonItemGroup>
				<IonItemGroup>
					<IonItemDivider className="settings-divider">
						<IonLabel>Personal Notifications</IonLabel>
					</IonItemDivider>
					<IonItem>
						<div style={{ width: "100%" }}>Enable push notifications</div>
						<div>
							<IonToggle checked={pushNotifications} onClick={() => enableNotifications()} color="tertiary" />
						</div>
					</IonItem>
					<IonItem>
						<div style={{ width: "100%" }}>
							Morning updates{" "}
							<IonIcon
								icon={informationCircleOutline}
								onClick={() => setInfoMessage({ visible: true, message: "A morning notification to let you know you and your squads' weekly goal progress" })}
							/>
						</div>

						<div>
							<IonToggle checked={morningUpdate} color="tertiary" onClick={() => updateNotificationSetting("morningUpdate")} />
						</div>
					</IonItem>
					<IonItem>
						<div style={{ width: "100%" }}>
							Goal progress updates
							<IonIcon
								icon={informationCircleOutline}
								onClick={() =>
									setInfoMessage({ visible: true, message: "A notification to let you know when you're 80% to your goal and when it's completed" })
								}
							/>
							<br />
							<span className="notification-description"></span>
						</div>
						<div>
							<IonToggle checked={goalProgressUpdate} color="tertiary" onClick={() => updateNotificationSetting("goalProgressUpdate")} />
						</div>
					</IonItem>
					<IonItem>
						<div style={{ width: "100%" }}>Activity notifications </div>
						<div>
							<IonToggle checked={activityNotifications} color="tertiary" onClick={() => updateNotificationSetting("activityNotifications")} />
						</div>
					</IonItem>
				</IonItemGroup>
				<IonItemDivider className="settings-divider">
					<IonLabel>Squad Notifications</IonLabel>
				</IonItemDivider>
				<IonItemGroup>
					<IonItem>
						<div style={{ width: "100%" }}>Squadmate activities</div>
						<div>
							<IonToggle checked={squadActivities} color="tertiary" onClick={() => updateNotificationSetting("squadActivities")} />
						</div>
					</IonItem>
					<IonItem>
						<div style={{ width: "100%" }}>Squadmate messages</div>
						<div>
							<IonToggle checked={squadMessages} color="tertiary" onClick={() => updateNotificationSetting("squadMessages")} />
						</div>
					</IonItem>
					<IonItem>
						<div style={{ width: "100%" }}>Competitor activities</div>
						<div>
							<IonToggle checked={competitorActivities} color="tertiary" onClick={() => updateNotificationSetting("competitorActivities")} />
						</div>
					</IonItem>
					<IonItem>
						<div style={{ width: "100%" }}>Competitor messages</div>
						<div>
							<IonToggle checked={competitorMessages} color="tertiary" onClick={() => updateNotificationSetting("competitorMessages")} />
						</div>
					</IonItem>
					<IonItem>
						<div style={{ width: "100%" }}>Squad updates</div>
						<div>
							<IonToggle checked={squadUpdates} color="tertiary" onClick={() => updateNotificationSetting("squadUpdates")} />
						</div>
					</IonItem>
				</IonItemGroup>
				<IonItemDivider className="settings-divider">
					<IonLabel>Version Info</IonLabel>
				</IonItemDivider>
				<IonItemGroup>
					<IonItem>
						{version ? <div style={{ marginLeft: "10px", marginRight: "10px", fontSize: "0.65em" }}>{version}</div> : <div>No version info available</div>}
					</IonItem>
				</IonItemGroup>
				<IonItemDivider className="settings-divider">
					<IonLabel>Legal</IonLabel>
				</IonItemDivider>
				<IonItemGroup>
					<IonItem onClick={() => window.open("https://www.fitsquadapp.com/other/privacy-policy")}>Privacy Policy</IonItem>
					<IonItem onClick={() => window.open("https://www.fitsquadapp.com/other/terms-conditions")}>Terms of Use</IonItem>
				</IonItemGroup>
				{user && user.userName === "Riggz" ? <IonButton routerLink={"/admin"}>Admin</IonButton> : ""}
			</IonContent>
		</IonPage>
	);
}

export default UserSettings;
