/* eslint-disable jsx-a11y/accessible-emoji */
import {
	IonAlert,
	IonBackButton,
	IonButton,
	IonButtons,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonContent,
	IonHeader,
	IonLoading,
	IonModal,
	IonPage,
	IonPopover,
	IonToolbar,
	useIonViewWillEnter,
} from "@ionic/react";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useParams } from "react-router";
import AuthContext from "../../contexts/GlobalState";
import "../../css/Home.css";
import "../../css/Inventory.css";
import { addActivity } from "../../services/ActivitiesService";
import {
	getItemDetailsByItemID,
	getQuantityOfItemByItemID,
	getQuantityOfItemByUserItemID,
	getUserItemByInventoryID,
	getUserItemIDByInventoryID,
	redeemItem,
} from "../../services/InventoryService";
import { sendSlackErrorNotification, sendSlackNotification } from "../../services/NotificationService";
import { getUser, getUserID } from "../../services/UserService";
import CollectAddress from "./CollectAddress";
import CollectEmail from "./CollectEmail";
import MysteryBag from "./MysteryBag";

const InventoryItem = () => {
	let inventoryItemID = useParams().itemID;
	const { updateGoalState, updateUserState } = useContext(AuthContext);

	const [itemDetails, setItemDetails] = useState();
	const [itemQuantity, setItemQuantity] = useState(null);
	const [showLoading, setShowLoading] = useState(false);
	const [showRedeeming, setShowRedeeming] = useState({ visible: false, message: null });
	const [noQuantityAlert, setNoQuantityAlert] = useState(false);
	const [alreadyRedeemed, setAlreadyRedeemed] = useState(false);
	const [showBagModal, setShowBagModal] = useState({
		visible: false,
		itemDetails: null,
	});
	const [collectAddressModal, setCollectAddressModal] = useState(false);
	const [collectEmailModal, setCollectEmailModal] = useState(false);
	const [awardingComplete, setAwardingComplete] = useState(false);
	const [awardingPoints, setAwardingPoints] = useState({ visible: false, amount: null });
	const [redeemed, setRedeemed] = useState(false); // only used for items that require confirmation of addresses

	/*
	useEffect(() => {
		if (itemQuantity === 0) {
			window.history.back();
		}
	}, [itemQuantity]);
	*/

	useIonViewWillEnter(async () => {
		setShowLoading(true);
		let itemDetails = await getItemDetailsByItemID(inventoryItemID);
		let userItemDetails = await getUserItemByInventoryID(inventoryItemID);
		if (itemDetails.actions) {
			// should all items with actions have the redeem button?
			itemDetails = await addMetaData(itemDetails);
		} else {
			itemDetails.buttonName = "Redeem";
		}
		if (userItemDetails && userItemDetails.readyForProcessing === true && itemDetails.buttonName !== null) {
			itemDetails.buttonName = "Redeemed";
		}

		setItemQuantity(await getQuantityOfItemByItemID(inventoryItemID)); // separated out for easy state management
		setItemDetails(itemDetails);
		setShowLoading(false);
	});

	const addMetaData = async (item) => {
		console.log({ item });
		item.buttonName = "Redeem";
		item.redeemingMessage = "Redeeming item";
		if (item.actions?.lootCrate) {
			item.generalDescription =
				"Possible Prizes include: <li>Amazon Gift Cards</li><li>Bottles of wine</li><li>FitBit Inspire 2</li><li>Massage Envy Gift Cards</li><li>Free FitSquad Seasons</li><li>Digital goods</li> and much more!";
			item.redeemingMessage = "Shuffling items in Mystery Bag...";
			item.buttonName = "Open";
			item.redeemType = "lootcrate";
		}
		if (item.actions?.personalForgiveness) {
			item.generalDescription =
				"This will be used automatically whenever you might miss a weekly goal. When it's used, you'll still get credit for your goal even though you didn't get enough points.";
			item.buttonName = null; // set buttonType to null if you don't want to redeem the item
		}
		if (item.actions?.onSeasonRegistration) {
			item.generalDescription = "This will be used automatically when you sign up for your next season.";
			item.buttonName = null; // set buttonType to null if you don't want to redeem the item
		}
		if (item.actions?.bonusPoints) {
			item.generalDescription = "Redeeming this will immediately add this many points to your tally for the week, helping you hit your goal.";
			item.redeemType = "bonuspoints";
		}
		if (item.actions?.collectAddress) {
			item.generalDescription = "Tap the redeem button to confirm your address so we can get it over to you!";
			item.redeemType = "collectaddress";
		}
		if (item.actions?.collectEmail) {
			item.generalDescription = "Tap the redeem button to confirm your email address so we can get it over to you!";
			item.redeemType = "collectemail";
		}
		if (item.actions?.charityDonation) {
			item.buttonName = null; // set buttonType to null if you don't want to redeem the item
		}
		return item;
	};

	const redeemInventoryItem = async (item) => {
		console.log({ item });
		if (item.buttonName === "Redeemed") {
			setAlreadyRedeemed(true);
			return;
		}
		let user = JSON.parse(await getUser());
		//alert("We're working on item redemption. Should be done in a couple days. Watch the Facebook group/email for updates!");
		sendSlackNotification("*NEW PRIZE REDEMPTION*\n" + user.userName + " just attempted to redeem a(n) " + item.name, "keyEvent");

		// show redeeming screen
		// hit redeem API

		let userItemID = await getUserItemIDByInventoryID(inventoryItemID);
		let response;
		// trigger appropriate action
		switch (item.redeemType) {
			case "lootcrate":
				setShowRedeeming({ visible: true, message: item.redeemingMessage });
				// moved redeeming to case because redeeming is different depending on the item
				response = await redeemItem(userItemID).catch((error) => {
					alert(
						"There was an error redeeming your item. Please try again. If you continue to run into issues please contact support at support@fitsquadapp.com"
					);
					console.log("Error: " + error);
				});
				console.log({ response });
				setShowRedeeming({ visible: false, message: null });
				if (response && response.length > 0) {
					setItemQuantity(await getQuantityOfItemByUserItemID(userItemID, true));
					let itemDetails = await getItemDetailsByItemID(response[0].inventoryItemId);
					sendSlackNotification("*NEW MYSTERY BAG REDEMPTION*\n" + user.userName + " just scored a " + itemDetails.name + " from a Mystery Bag", "keyEvent");
					itemDetails.userInventoryItemID = response[0].id;
					setShowBagModal({ visible: true, itemDetails: itemDetails });
					updateUserState();
				} else {
					// error cases... what are they?
					alert(
						"There was an error redeeming your item. Please try again. If you continue to run into issues please contact support at support@fitsquadapp.com"
					);
					sendSlackErrorNotification(
						"Redeem item returned empty response",
						"InventoryItem",
						"\nUser Item ID: " + userItemID + "\nUser ID: " + (await getUserID(false)) + "\nItem: " + item.name + "\nURL: /userinventory/" + inventoryItemID
					);
				}
				break;
			case "collectaddress":
				setCollectAddressModal({ visible: true, userItemID: userItemID });
				break;
			case "bonuspoints":
				// award points
				console.log(`Bonus Points: ${itemDetails.actions.bonusPoints}`);
				setAwardingPoints({ visible: true, amount: itemDetails.actions.bonusPoints });
				let activityData = {
					activity: "Bonus Point Token",
					duration: itemDetails.actions.bonusPoints,
					unit: "minutes",
					calories: itemDetails.actions.bonusPoints,
					date: moment().set("hour", 1).set("minute", 1).format(),
				};
				console.log({ activityData });
				response = await redeemItem(userItemID).catch((error) => {
					setAwardingPoints({ visible: false });
					alert(
						"There was an error redeeming your item. Please try again. If you continue to run into issues please contact support at support@fitsquadapp.com"
					);
					return;
				});
				response = await addActivity(activityData).catch((error) => {
					setAwardingPoints({ visible: false });
					alert(
						"There was an error redeeming your item. Please try again. If you continue to run into issues please contact support at support@fitsquadapp.com"
					);
					return;
				});
				updateGoalState();
				updateUserState();
				setItemQuantity(await getQuantityOfItemByUserItemID(userItemID, true));
				setAwardingPoints({ visible: false });
				setAwardingComplete(true);
				break;
			case "collectemail":
				setCollectEmailModal({ visible: true, userItemID: userItemID });
				break;
			default:
				break;
		}
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonLoading
				isOpen={showRedeeming.visible}
				onDidDismiss={() => setShowRedeeming({ visible: false, message: null })}
				message={showRedeeming.message}
				backdropDismiss={true}
			/>
			<IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={"Loading item..."} backdropDismiss={true} />
			<IonLoading
				isOpen={awardingPoints.visible}
				onDidDismiss={() => setAwardingPoints({ visible: false })}
				message={`Awarding you ${awardingPoints.amount} bonus points...`}
				backdropDismiss={true}
			/>
			<IonAlert
				isOpen={noQuantityAlert}
				header="Oops"
				message={`You don't have any of this item to redeem`}
				buttons={[
					{
						text: "Ok",
						role: "ok",
					},
				]}
				onDidDismiss={() => setNoQuantityAlert(false)}
			/>
			<IonAlert
				isOpen={alreadyRedeemed}
				header="Already redeemed"
				message={`You've already redeemed this item and should receive it soon. If you have any questions please contact support at support@fitsquadapp.com`}
				buttons={[
					{
						text: "Ok",
						role: "ok",
					},
				]}
				onDidDismiss={() => setAlreadyRedeemed(false)}
			/>
			<IonModal
				isOpen={showBagModal.visible}
				onDidDismiss={() => setShowBagModal({ visible: false, itemDetails: null })}
				cssClass="exercise-details-full-screen-popover"
			>
				<MysteryBag showBagModal={showBagModal} setShowBagModal={setShowBagModal} />
			</IonModal>
			<IonModal
				isOpen={collectAddressModal.visible}
				onDidDismiss={() => setCollectAddressModal({ visible: false })}
				cssClass="exercise-details-full-screen-popover"
			>
				<CollectAddress
					itemDetails={itemDetails}
					setModal={setCollectAddressModal}
					setItemQuantity={setItemQuantity}
					userItemID={collectAddressModal.userItemID}
				/>
			</IonModal>
			<IonModal
				isOpen={collectEmailModal.visible}
				onDidDismiss={() => setCollectEmailModal({ visible: false })}
				cssClass="exercise-details-full-screen-popover"
			>
				<CollectEmail itemDetails={itemDetails} setModal={setCollectEmailModal} setItemQuantity={setItemQuantity} userItemID={collectEmailModal.userItemID} />
			</IonModal>
			<IonPopover
				//event={showGoalConfirmation.event}
				isOpen={awardingComplete}
				cssClass="gif-response"
				onDidDismiss={(e) => setAwardingComplete(false)}
			>
				<div style={{ fontSize: "1.25em", padding: "10%", textAlign: "center" }}>Points awarded! These should help with hitting your goal this week 🙌</div>
			</IonPopover>
			<IonContent>
				{itemDetails && itemQuantity !== null ? (
					<IonCard>
						<img style={{ width: "100%" }} src={itemDetails.image} alt="" />
						<IonCardHeader>
							<IonCardTitle>{itemDetails.name}</IonCardTitle>
							<IonCardSubtitle>Quantity: {itemQuantity}</IonCardSubtitle>
						</IonCardHeader>
						<IonCardContent>
							<div>{itemDetails.description}</div>
							{itemDetails.generalDescription ? (
								<div style={{ marginTop: "20px" }} dangerouslySetInnerHTML={{ __html: itemDetails.generalDescription }}></div>
							) : (
								""
							)}
						</IonCardContent>
						{itemQuantity > 0 ? (
							<IonCardContent style={{ textAlign: "center" }}>
								{itemDetails.buttonName !== null ? (
									<div className={`${itemDetails.buttonName}`}>
										<IonButton className={`${itemDetails.buttonName}-button`} onClick={() => redeemInventoryItem(itemDetails)}>
											{itemDetails.buttonName}
										</IonButton>
									</div>
								) : (
									""
								)}
							</IonCardContent>
						) : (
							<IonCardContent style={{ textAlign: "center" }}>
								{itemDetails.buttonName !== null ? (
									<div className="itemRedeemButton">
										<IonButton onClick={() => setNoQuantityAlert(true)} color="primary">
											{itemDetails.buttonName}
										</IonButton>
									</div>
								) : (
									""
								)}
							</IonCardContent>
						)}
					</IonCard>
				) : (
					""
				)}
			</IonContent>
		</IonPage>
	);
};

export default InventoryItem;
