import { IonCard } from "@ionic/react";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import "../../css/Challenges.css";
import "../../css/Home.css";
import { cloudFrontURL } from "../../models/constants";
import { getChallengeDetailsByChallengeID } from "../../services/ChallengeService";
import ChallengeHeaderComponent from "./ChallengeHeaderComponent";

const ChallengeProgressComponent = ({ challengeObject, source = "details" }) => {
	const canvasRef = useRef();
	let screenWidth = window.innerWidth;
	let screedWidthWithCard = screenWidth - 32;

	// initialize the canvas context
	useEffect(() => {
		drawGraphic();
	}, []);

	const drawGraphic = async () => {
		const canvas = canvasRef.current;
		const context = canvas.getContext("2d");
		let gapBetweenCircles = screedWidthWithCard / (parseInt(challengeObject.goalPoints) + 1);
		let x = 16;

		// Draw the main line
		context.fillStyle = challengeObject.challengeDetails.metadata.primaryColor;
		context.fillRect(16 + gapBetweenCircles - 20, 24, gapBetweenCircles * (parseInt(challengeObject.goalPoints) - 1), 10);

		// Add trophy to goal points circle

		let trophy = new Image();
		trophy.src = "assets/images/orange-circle.png";
		trophy.onload = function () {
			for (let i = 1; i <= challengeObject.goalPoints; i++) {
				x = gapBetweenCircles * i;

				if (i <= challengeObject.points) {
					context.drawImage(trophy, x - 25, 17, 25, 25);
					// context.fillStyle = challengeDetail.metadata.primaryColor;
					// context.shadowColor = challengeDetail.metadata.primaryColor;
					// context.shadowBlur = 15;
				} else {
					context.beginPath();
					context.arc(x, 30, 10, 0, 2 * Math.PI, false);
					context.strokeStyle = "#525252";
					context.lineWidth = 2;
					context.stroke();
					context.fillStyle = "#ffffff";
					context.fill();
				}
			}
		};
	};
	return (
		<React.Fragment>
			<IonCard className="no-shadow light-gray-border" style={{ width: "90%" }}>
				{(source === "home" || source === "EOW") && challengeObject && challengeObject.challengeDetails ? (
					// <div className="challenge-card-holder" style={{ backgroundColor: `${challengeObject.challengeDetails.metadata.primaryColor}` }}>
					// 	<div className="main-text-div">
					// 		<div className="name" style={{ color: `${challengeObject.challengeDetails.metadata.textColor}` }}>
					// 			{challengeObject.challengeDetails.name}
					// 		</div>
					// 		<div className="description" style={{ color: `${challengeObject.challengeDetails.metadata.textColor}` }}>
					// 			{challengeObject.challengeDetails.description}
					// 		</div>
					// 	</div>
					// 	<div className="card-image">
					// 		<img className="card-image-img" src={`${cloudFrontURL}/${challengeObject.challengeDetails.image}`} alt="" />
					// 	</div>
					// </div>
					<ChallengeHeaderComponent challengeDetails={challengeObject.challengeDetails} />
				) : (
					""
				)}
				<div className="challenge-progress-title">
					Challenge Points {challengeObject.points}/{challengeObject.goalPoints}
				</div>
				<canvas height={100} width={screedWidthWithCard} style={{ position: "relative", top: "0px" }} ref={canvasRef}></canvas>
				{challengeObject.status === 0 ? (
					<div className="challenge-progress-subtitle">
						Your challenge begins in <b>{moment(challengeObject.startDate).diff(moment(), "days")}</b> days
					</div>
				) : (
					""
				)}
				{challengeObject.status === 1 ? (
					<div className="challenge-progress-subtitle">Ends {moment(challengeObject.endDate).add(1, "day").format("YYYY/MM/DD")}</div>
				) : (
					""
				)}
				{challengeObject.status === 3 ? (
					<div className="challenge-progress-subtitle">
						You completed this challenge on {moment(challengeObject.endDate).add(1, "day").format("YYYY/MM/DD")}
					</div>
				) : (
					""
				)}
			</IonCard>
		</React.Fragment>
	);
};
export default ChallengeProgressComponent;
