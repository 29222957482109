import { IonAvatar, IonButton, IonCol, IonItem, IonPopover, IonRow } from "@ionic/react";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import JSConfetti from "js-confetti";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import AuthContext from "../contexts/GlobalState";
import { logGiveLoveEvent, logNavigationEvent } from "../services/AnalyticsService";
import { addReaction } from "../services/FeedService";
import { updateReply } from "../services/NotificationService";
import { getSquadIDFromChatRoomID } from "../services/SquadService";
import { StorageService } from "../services/StorageService";
import { getUserID, getUserNameOfLoggedInUser } from "../services/UserService";
import TimeAgoComponent from "./TimeAgoComponent";
const storageService = new StorageService();

const NotificationsListComponent = ({ maxUpdates = 5, showAllUpdates = false }) => {
	// both of these are for the more full list of updates
	const history = useHistory();
	const jsConfetti = new JSConfetti();
	let updatesShown = 0;
	const { userState, notificationsList, setNotificationsList } = useContext(AuthContext);
	const [updating, setUpdating] = useState(false);
	const [showReactionSelector, setShowReactionSelector] = useState({
		open: false,
		event: undefined,
		activityID: undefined,
		type: undefined,
		index: undefined,
		targetUserID: undefined,
	});
	const [tyMessage, setTyMessage] = useState({
		open: false,
		message: null,
		emoji: null,
	});

	useEffect(() => {
		console.log("Re-rendering?");
	}, [notificationsList]);

	const navToNotifications = () => {
		logNavigationEvent("home", "notifications");
		history.push("/notifications/");
	};

	const setUpdateToResponded = async (id) => {
		if (id >= 0 && notificationsList[id]) {
			notificationsList[id].responseSent = true;
			notificationsList[id].button = false;
			setNotificationsList(notificationsList);
			storageService.setObject("recentUpdates", notificationsList);
		} else {
			console.error("id undefined in setUpdateToResponded: id: " + id + " update: " + JSON.stringify(notificationsList[id]));
		}
	};

	const navToLocation = async (update, index) => {
		let squadID;
		if (update.payload && update.payload.chatRoom) {
			squadID = await getSquadIDFromChatRoomID(update.payload.chatRoom);
		} else if (update.payload && update.payload.squadId) {
			squadID = update.payload.squadId;
		} else {
			squadID = null;
		}
		if (squadID !== null) {
			history.push("/squadhome/" + squadID);
		}
	};

	const showEmojiPicked = async (event, emojiObject, activityID, index, targetUserID) => {
		let userID = await getUserID();
		logGiveLoveEvent("notifications", "activity", emojiObject.colons, targetUserID);
		addReaction(activityID, emojiObject.colons);
		setShowReactionSelector({ open: false, event: undefined, activityID: undefined, type: undefined, index: undefined });
		setTyMessage({
			open: true,
			message: "Love sent!\nGreat job supporting your squad",
			emoji: emojiObject.colons,
		});
		jsConfetti.addConfetti({
			emojis: [emojiObject.native],
		});
		setUpdateToResponded(index);
	};

	const sayThx = async (update, index) => {
		let userName = await getUserNameOfLoggedInUser();
		let bodyObject = {
			title: "💙💙💙💙💙💙💙💙",
			message: userName + " appreciated the love you showed for their " + update.payload.activity.activityType,
			recipientId: update.payload.from.cognitoId,
		};
		updateReply(update.id, bodyObject);
		setUpdateToResponded(index);
		logGiveLoveEvent("notifications", "activity", ":blue_heart:", update.payload.from.cognitoId);
		setTyMessage({
			open: true,
			message: "Appreciation sent!<br>" + update.payload.from.userName + " will enjoy that.",
			emoji: ":blue_heart:",
		});
		jsConfetti.addConfetti({
			emojis: ["💙"],
		});
	};

	return (
		<div>
			{/* <IonLoading isOpen={pullingUpdates} onDidDismiss={() => setPullingUpdates(false)} message={"Refreshing"} />
			<IonLoading isOpen={updating} onDidDismiss={() => setUpdating(false)} message={"Updating"} duration={300} /> */}
			<IonPopover
				event={showReactionSelector.event}
				isOpen={showReactionSelector.open}
				cssClass="reaction-selector"
				onDidDismiss={(e) => {
					setShowReactionSelector({ open: false, event: undefined, activityID: null, type: null, index: null, targetUserID: null });
				}}
			>
				<Picker
					onSelect={(emoji) => showEmojiPicked(emoji, emoji, showReactionSelector.activityID, showReactionSelector.index, showReactionSelector.targetUserID)}
				/>
			</IonPopover>
			<IonPopover
				isOpen={tyMessage.open}
				className="love-thanks"
				onDidDismiss={(e) => {
					setTyMessage({
						open: false,
						message: null,
						imageURL: null,
					});
				}}
			>
				<div style={{ fontSize: "1.25em", paddingTop: "33px", textAlign: "center" }}>
					<br />
					<span dangerouslySetInnerHTML={{ __html: tyMessage.message }}></span>
				</div>
			</IonPopover>
			{notificationsList &&
				notificationsList.map((update, index) => {
					let currentTime = moment();
					let timeAgo = currentTime.diff(moment(update.sentTime), "days");
					if ((updatesShown < maxUpdates && update.responseSent !== true && timeAgo < 7) || (showAllUpdates === true && updatesShown < maxUpdates)) {
						updatesShown++;
						// only show top 4

						let itemProps = null;
						let avatarMargin = "4%";
						let dynamicFontSize = "1em";
						if (userState && userState.user.deviceInfo.operatingSystem === "ios") {
						}
						if (userState && userState.user.deviceInfo.operatingSystem === "android") {
							avatarMargin = "6%";
							dynamicFontSize = "0.7em";
						}
						return (
							<IonItem key={`${index}`} {...itemProps} style={{ fontSize: "0.9em", paddingBottom: "5px" }} className={"update-read"}>
								<IonRow style={{ paddingBottom: "5px" }}>
									{!update.avatar ? ( // No avatar? Show just a full row with no button
										<IonCol size="12">
											<div dangerouslySetInnerHTML={{ __html: update.body }}></div>
											<div style={{ fontSize: "0.7em", color: "#a4a4a4", marginTop: "2%" }}>
												<TimeAgoComponent date={update.createdAt} />
											</div>
										</IonCol>
									) : (
										// Has avatar... show it and check for a button
										<React.Fragment>
											<IonCol size="2" style={{ marginTop: "auto", marginBottom: "auto", marginRight: avatarMargin }}>
												<IonAvatar>
													<img style={{ objectFit: "cover" }} src={update.avatar} alt="" />
												</IonAvatar>
											</IonCol>
											{update.button === false || update.responseSent === true ? (
												<IonCol size="9" onClick={() => navToLocation(update, index)}>
													<div dangerouslySetInnerHTML={{ __html: update.body }}></div>
													<div style={{ fontSize: "0.7em", color: "#a4a4a4", marginTop: "2%" }}>
														<TimeAgoComponent date={update.createdAt} />
													</div>
												</IonCol>
											) : (
												<React.Fragment>
													<IonCol size="7" onClick={() => navToLocation(update, index)}>
														<div dangerouslySetInnerHTML={{ __html: update.body }}></div>
														<div style={{ fontSize: "0.7em", color: "#a4a4a4", marginTop: "2%" }}>
															<TimeAgoComponent date={update.createdAt} />
														</div>
													</IonCol>
													<IonCol size="2" style={{ marginTop: "auto", marginBottom: "auto" }}>
														{update.payload.type === "newSquadActivity" ? (
															<IonButton
																style={{ fontSize: dynamicFontSize }}
																onClick={(e) =>
																	setShowReactionSelector({
																		targetUserID: update.payload.userId,
																		open: true,
																		event: e.nativeEvent,
																		activityID: update.payload.id,
																		type: "activity",
																		index: index,
																	})
																}
															>
																+ Love
															</IonButton>
														) : (
															""
														)}
														{update.payload.type === "reactionReceived" && update.read !== true ? (
															<IonButton style={{ fontSize: dynamicFontSize }} onClick={() => sayThx(update, index)}>
																❤
															</IonButton>
														) : (
															""
														)}
													</IonCol>
												</React.Fragment>
											)}
										</React.Fragment>
									)}
								</IonRow>
							</IonItem>
						);
					}
				})}
			{notificationsList && notificationsList.length > 5 && maxUpdates !== 99 && updatesShown > 0 ? (
				<div style={{ marginLeft: "5%", marginTop: "5%" }} onClick={navToNotifications}>
					See more updates
				</div>
			) : (
				""
			)}
			{updatesShown === 0 ? <div style={{ marginLeft: "5%", marginTop: "5%" }}>Squaddie updates will appear here 👍</div> : ""}
		</div>
	);
};

export default NotificationsListComponent;
