import moment from 'moment'
import { async } from 'rxjs/internal/scheduler/async';
import { API_URL } from '../models/constants';
import { getIDToken } from './AuthService';

export const getRandomGifFromSearch = async (searchTerm, limit = 50, rating = "pg-13", requireSquare = true) => {
    const APIKey = "idU7a9opLcsdH2pXMjylRri7RsSkweyz";
    const url = "https://api.giphy.com/v1/gifs/search";
    const response = await Promise.resolve(
        fetch(url + "?api_key=" + APIKey + "&q=" + searchTerm + "&limit=" + limit + "&rating="+rating, {
            method: "GET",
        })
    ).catch((error) => {
        console.log("[getDayOfTheWeekGIF] Error in API call: " + error);
        throw Error(error);
    });
    let responseArray = await response.json();
    console.log({responseArray});
    if (responseArray.data.length === 0){
        return "https://www.temptationgifts.com/media/catalog/product/cache/c09c62f4d7c7812f632d8af59eec3417/p/i/pigment-happy-jackson-yay-you-totally-did-it-greeting-card-gf831b_1.jpg"
    } else if  (responseArray.data.length === 1) {    
        return responseArray.data[0].images.fixed_width.url;
    } else {
        let square = false
        let count = 0
        let randGif = null
        do {            
            randGif = Math.round(Math.random() * responseArray.data.length) - 1
            // console.log({randGif});
            if(responseArray?.data[randGif]?.images){ // make sure the one found has "images"
                if (requireSquare === true){
                    let width = responseArray.data[randGif].images.fixed_width.width
                    let height = responseArray.data[randGif].images.fixed_width.height
                    if (width === height){
                        square = true
                    } else {
                        count++
                    }
                } else {
                    square = true
                }
                
            } else { // if not, skip it
                count++
            }
            
            if (count > 19){square = true}
        } while (square === false)
        if (responseArray.data[randGif] === undefined){
            return "https://www.temptationgifts.com/media/catalog/product/cache/c09c62f4d7c7812f632d8af59eec3417/p/i/pigment-happy-jackson-yay-you-totally-did-it-greeting-card-gf831b_1.jpg"
        }
        return responseArray.data[randGif].images.fixed_width.url.split('?')[0];
    }
    
};

export const getMultipleRandomGifsFromSearch = async (searchTerm, limit = 100, rating = "pg-13") => {
    const APIKey = "idU7a9opLcsdH2pXMjylRri7RsSkweyz";
    const url = "https://api.giphy.com/v1/gifs/search";
    const response = await Promise.resolve(
        fetch(url + "?api_key=" + APIKey + "&q=" + searchTerm + "&limit=" + limit + "&rating="+rating, {
            method: "GET",
        })
    ).catch((error) => {
        console.log("[getDayOfTheWeekGIF] Error in API call: " + error);
        throw Error(error);
    });
    let responseArray = await response.json();
    responseArray.data.sort(()=>Math.random() - 0.5)
    return responseArray.data.slice(0,50);
};

export const getDailyGif = async (date) => {
    const url = API_URL;
	let idToken = await getIDToken().catch((error) => {
		throw Error("[getDailyGif] Error in getIDtoken: " + error);
	});
	let endPoint = "daily-gif?date=" +date;
	const response = await Promise.resolve(
		fetch(url + endPoint, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[JS][getDailyGif] Error in API call: " + error);
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {			
			console.log("[JS] getDailyGif severResponse:", data);
			return data.url;
		})
		.catch((error) => {
			console.log("[JS][getDailyGif] Error in JSON conversion: " + error);
			throw Error(error);
		});
}