import { IonButton, IonIcon, IonPopover } from "@ionic/react";
import { informationCircleOutline } from "ionicons/icons";
import React, { useState } from "react";
import "../css/AddActivityComponent.css";
import "../css/Home.css";

const InfoButton = ({ message }) => {
	const [showHelpText, setShowHelpText] = useState({
		open: false,
		event: undefined,
		message: null,
	});
	return (
		<React.Fragment>
			<IonPopover
				event={showHelpText.event}
				isOpen={showHelpText.open}
				cssClass="info-popover"
				onDidDismiss={(e) => {
					setShowHelpText({ open: false, event: undefined });
					//setScroller(false);
				}}
			>
				<div>{showHelpText.message}</div>
			</IonPopover>
			<IonButton
				size="small"
				color="secondary"
				className="info-button"
				onClick={(e) => {
					setShowHelpText({ open: true, event: e.nativeEvent, message: message });
				}}
			>
				<IonIcon slot="icon-only" icon={informationCircleOutline} style={{ color: "var(--ion-color-primary)" }} />
			</IonButton>
		</React.Fragment>
	);
};

export default InfoButton;
