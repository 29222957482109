export const sampleUpdate = {
    avatar: "https://static-assets.fitsquadapp.com/user/6baaedc1-8978-4eb1-ab14-122b0e47a31e/avatar.jpeg",
    body: "<b>Ashley</b> did <b>dog walking for 75 minutes</b>",
    button: true,
    createdAt: "2022-07-03T05:30:08.000Z",
    id: "1f0f78c0-1f4b-47c7-824d-1ec2e1ffbc1e",
    link: "/chatroom/f606a146-2094-4274-b261-3cd892bf7191",
    payload: {
        activityData: {
            activityType: "dog walking",
            calPerMin: 4,
            creationDate: "2022-07-02T22:24:22-07:00",
            dataType: "workout",
            endDate: "2022-07-02T22:24:00-07:00",
            intensityName: "low",
            points: 75,
            reportId: "0f0c3de4-18e9-49c2-ac50-6a4e6cacb0a3",
            sourceName: "Manual Log",
            startDate: "2022-07-02T21:09:00-07:00",
            totalEnergyBurned: 300,
            value: 75,
            valueUnit: "minutes"
        },
        activityType: "golf",
        bonusPoints: 0,
        createdAt: "2022-07-03T05:24:23.654Z",
        deleted: false,
        endDate: "2022-07-03T05:24:00.000Z",
        fitbitId: null,
        healthkitId: null,
        id: 232296,
        points: 75,
        reactionCount: 0,
        reactions: [],
        sourceActivityId: 232295,
        squadId: 318,
        squadName: "Hustle and Muscle",
        startDate: "2022-07-03T04:09:00.000Z",
        timeStamp: "2022-07-03T05:24:23.654Z",
        type: "newSquadActivity",
        updatedAt: "2022-07-03T05:24:23.654Z",
        userId: "6baaedc1-8978-4eb1-ab14-122b0e47a31e",
        userName: "Ashley"
    },
    read: false,
    responseSent: false,
    sentTime: "2022-07-03T05:30:08.000Z",
    title: "Hustle and Muscle",
    updatedAt: "2022-07-03T06:24:14.232Z",
    userId: "b2b3ca3d-e22e-458d-bb2e-f99631646657",
}