import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import React from "react";

const SeasonTerms = () => {
	return (
		<React.Fragment>
			<div style={{ margin: "0px 16px" }}>
				<h1>Official Rules for Seasons</h1>
				<p>
					<em>Last Updated: June 4, 2021</em>
				</p>
				<p>
					Throughout the year, FitSquad offers its users the opportunity to challenge themselves by participating in what we call a Season. A Season is a
					specified period of time where participants earn Season Points by meeting their personal goals and by their designated squad meeting its goals. During
					the season period, the user will have access to certain Perks, and if the user wins the season, they can receive one or more Rewards, as described
					below.
				</p>
				<h2>Disclaimer</h2>
				<p>
					FITSQUAD SEASONS, SUBSCRIPTIONS, CONTESTS, SWEEPSTAKES, AS WELL AS ANY AND ALL PRIZES, REWARDS, PERKS, AND GIVEAWAYS, ARE IN NO WAY ASSOCIATED WITH,
					AFFILIATED WITH, SPONSORED BY, OR ENDORSED BY APPLE, INC., GOOGLE, LLC., ALPHABET, INC., FACEBOOK, INC., GARMIN, INC., FITBIT, INC., OR AMAZON, INC.
					ALL TRADEMARKS ARE PROPERTY OF THEIR RESPECTIVE OWNERS.
				</p>
				<h2>Registration</h2>
				<p>
					Registering for a Season is easy. Anyone can register for a season in the FitSquad app or on the FitSquad website when registration for the season is
					open (generally, for the two weeks before the season starts, and up to two weeks after the start of the season). To register for a season, you must be
					a registered user of the FitSquad app and you must be a member of one or more Squads in the app. When registering, you will select one of the Squads
					that you are a member of to register with. This means that you will receive Season Points for that Squad’s performance which will count towards your
					Season Score.
				</p>
				<h2>Activity Points</h2>
				<p>
					Each week, the app will provide you with a new goal. This goal is based on your performance from previous weeks. You may change this goal at any time
					for any reason in the app. Additionally, the app will create a goal for each Squad in the app, which cannot be changed once it is created. These goals
					represent a number of Activity Points. Activity Points are earned by logging activities in the app, with a connected FitBit, or any other fitness
					tracker that shares steps or workout data with Apple HealthKit on the device that you have the FitSquad app installed. When you do an activity and
					earn points, you and every Squad you are a member of will receive Activity Points towards the currently active weekly goals. The number of Activity
					Points awarded for each activity will vary based on the duration and intensity of the activity according to the following table:
				</p>
				<table>
					<tbody>
						<tr>
							<td width="390">
								<strong>Activity Type</strong>
							</td>
							<td width="234">
								<strong>Activity Points Earned</strong>
							</td>
						</tr>
						<tr>
							<td width="390">Low intensity activities (0-5 calories per minute)</td>
							<td width="234">1 point per minute</td>
						</tr>
						<tr>
							<td width="390">Moderate intensity activities (6-9 calories per minute)</td>
							<td width="234">1.5 points per minute</td>
						</tr>
						<tr>
							<td width="390">High intensity activities (10+ calories per minute)</td>
							<td width="234">2 points per minute</td>
						</tr>
						<tr>
							<td width="390">Steps</td>
							<td width="234">1 point per 100 steps taken</td>
						</tr>
					</tbody>
				</table>
				<p>&nbsp;</p>
				<p>
					Calories per minute are generally estimated, and will vary from activity to activity, as well as the device capturing the activity data and other
					factors. Some devices may not report any calories or other data that the app can use to correctly classify an activity, in which case you can always
					manually create an activity and select its intensity in the app to ensure that you are credited with an appropriate number of Activity Points.
				</p>
				<h2>Bonus Points</h2>
				<p>
					Based on the type of season registration you have purchased and other items in your inventory within the app, you may also receive bonus points. Bonus
					points are additional points that are awarded when you complete activities and are usually a percentage of the points earned for that activity. For
					example, if your season registration included a 10% point bonus, if you completed an activity that was worth 42 points, you would also receive 5 bonus
					points for that activity.
				</p>
				<h2>Goal Evaluation</h2>
				<p>
					Your personal goals end at midnight on Sunday in the timezone that the app believes you are in. If the app is unable to determine your timezone it
					will default to assuming that you are in the Pacific Timezone. New user goals are created immediately after each goal is evaluated with will be active
					for one week. All squad goals expire at midnight on Sunday night, Pacific time, and are created at midnight on Monday nights. Activity and Bonus
					Points earned in the period of time between when the Squad goal ends and a new one is created are credited towards the new goal.
				</p>
				<h2>Earning Season Points</h2>
				<p>
					At the end of each week or when they expire, every goal is evaluated. If your Activity Points + Bonus Points are equal to or greater than your goal,
					your goal is considered to have been completed successfully and you will be awarded 1 Season Point. Likewise, if the squad that you selected when you
					registered for the season also successfully completes its goal, you will be awarded 1 additional Season Point.
				</p>
				<h2>Forgiveness Tokens</h2>
				<p>
					Some season registrations will include one or more Forgiveness Tokens. A Forgiveness Token is an item that will be automatically used if you fail to
					meet your goal for a week. Instead of the goal evaluating as a failure, it will be considered a success, you will still earn the Season Point for your
					goal that week, and your balance of Forgiveness Tokens will be decremented. Forgiveness Tokens will have no impact on your Squad’s goal evaluation for
					the week.
				</p>
				<h2>Completing the Season</h2>
				<p>
					At the end of the season period, the number of Season Points you have accumulated will be compared with the number of points necessary to win the
					season. If you have earned enough Season Points, you will have won and any contingent rewards will be made available to you. If you did not win the
					season, you will not receive any of the contingent rewards.
				</p>
				<h2>Season Rewards and Season Perks</h2>
				<p>
					Seasons generally will include one or more rewards, which are contingent on your successful completion of the season (you must win the season to
					receive these rewards), and one or more perks, which you will receive throughout the duration of the season, regardless of your performance. These
					rewards and perks will vary by season.
				</p>
				<h2>Donations</h2>
				<p>
					Any Season Rewards which are charitable donations will be made by FitSquad directly to the selected charity on behalf of FitSquad. As Season Rewards
					are contingent on you winning the season, if you fail to win the season, FitSquad will make a smaller donation to the charity, at FitSquad’s
					discretion.
				</p>
				<h2>Refunds</h2>
				<p>No refunds will be provided for any season registrations for any reason.</p>
				<h2>Errors, Inaccuracies, and Omissions</h2>
				<p>
					Occasionally there may be information on our site or in the app that contains typographical errors, inaccuracies, or omissions that may related to
					product descriptions, pricing, promotions, offers, Season Points, Activity Points, goal performance, goal predictions, availability, inventory, or
					other matters. We reserve the right to correct any errors, inaccuracies, or omissions, and to change or update information or cancel orders if any
					information is inaccurate at any time without prior notice (including after you have submitted your order).
				</p>
				<h2>Summer Season, 2021</h2>
				<p>
					Summer Season 2021 runs from May 17, 2021 through July 26, 2021. To win the season, you must earn 15 Season Points over the 10 week period. All
					registrations for the season include a contingent Season Reward which is a donation to the Special Olympics. The size of the donation made by FitSquad
					will vary depending on how many users have registered for the season, their tier of registration, and their performance during the season.
				</p>
				<p>
					Several perks may be available to users registered for the Summer Season, 2021, based on their tier and include goal predictions in the app, bonus
					points, daily workout ideas, forgiveness tokens. Additionally, several contingent rewards are also available which, depending on the user’s tier, may
					include a donation to the Special Olympics from FitSquad, a FitSquad t-shirt, a season medal, and/or mystery bags. For this season, mystery bags, when
					opened, may contain a forgiveness token, 5%, 10%, or 15% point bonuses, an additional mystery bag, free season registration for a future season, free
					tier upgrade for a future season, a bottle of wine, a FitBit, a $20 Amazon gift card, a personalized message from a celebrity, a $25 massage gift
					card, Season Points for the next season, or free goal predictions for life.
				</p>
			</div>
		</React.Fragment>
	);
};

export default SeasonTerms;
