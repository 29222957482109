import moment from "moment";
import { API_URL } from "../models/constants";
import { getIDToken } from "./AuthService";
import { shouldRefresh } from "./RefreshService";
import { StorageService } from "./StorageService";
const storageService = new StorageService();


export const getSquadTags = async (alphabeticalSort = false, refresh = false) => {
	const url = API_URL;
	let allTags = await storageService.getObject("squad-tags");
	let allTagsLastUpdated = await storageService.getItem("squad-tags-lastUpdated"); // should be a dateformatted object
	if (allTags && allTags !== "" && allTagsLastUpdated) {
		let update = await shouldRefresh(allTags, 2880, allTagsLastUpdated)		
		if (update === false && refresh === false){			
			return allTags;
		}
	}
	let idToken = await getIDToken().catch((error) => {
		throw Error("[getSquadTags] Error in getIDtoken: " + error);
	});
	let endPoint = "tags"
	const response = await Promise.resolve(
		fetch(url + endPoint, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[getSquadTags] Error in API call: " + error);
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {	
			if (alphabeticalSort === true){
				data = sortTagArray(data)
			}
			storageService.setObject("squad-tags",data)
			storageService.setItem("squad-tags-lastUpdated",moment().format());
			return data;
		})
		.catch((error) => {
			console.log("[getSquadTags] Error in JSON conversion: " + error);
			throw Error(error);
		});
}

export const sortTagArray = (array) => {
	array.sort(function(a, b) {
		var textA = a.tag.toUpperCase();
		var textB = b.tag.toUpperCase();
		return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
	});
	return array
}

export const getTagNameFromTagID = (tagID, tagArray) => {
	// didn't make tagArray optional because fetching tags would need to by synchronous
	let result = tagArray.find((tag) => tag.id === tagID)
	if (result !== undefined){
		return result.tag
	} else {
		return null
	}
}