import {
	IonAvatar,
	IonBackButton,
	IonButton,
	IonButtons,
	IonCol,
	IonContent,
	IonFooter,
	IonHeader,
	IonPage,
	IonProgressBar,
	IonRow,
	IonToolbar,
	useIonViewWillEnter,
} from "@ionic/react";
import React, { useState } from "react";
import { useHistory } from "react-router";
import "../../css/Home.css";
import "../../css/Onboarding.css";
import { logPageView } from "../../services/AnalyticsService";
import { getUser } from "../../services/UserService";
import { StorageService } from "../../services/StorageService";
import { getRandomUser } from "../../services/AuthService";
const storageService = new StorageService();

const RandomSquadExplanation = ({ progress, setProgress }) => {
	useIonViewWillEnter(async () => {
		logPageView("Random Squad Explanation");
		setProgress(0.52);
		storageService.setItem("lastOnboardingStep", 6.2);
	});

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar style={{ "--border-style": "none" }}>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
				</IonToolbar>
				<IonProgressBar value={progress} />
			</IonHeader>
			<IonContent>
				<div className="onboarding-main-div">
					<div className="onboarding-title">
						Each week you can choose to <b>join together with a group of people</b> and work towards a <b>shared goal</b>
					</div>
					<div className="onboarding-hero-image-div">
						<img className="onboarding-hero-image" src="https://media.giphy.com/media/l3q2Wl7Wpz09Z5hfi/giphy-downsized.gif" alt="" />
					</div>
				</div>
			</IonContent>
			<IonFooter className="onboarding-footer">
				<IonButton routerLink="/onboarding/goalexplanationsquad" className="onboarding-advance-button">
					Go on...
				</IonButton>
			</IonFooter>
		</IonPage>
	);
};

export default RandomSquadExplanation;
