import { IonContent, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonList, IonLoading, IonPopover } from "@ionic/react";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import Emoji from "react-emoji-render";
import ActivityGridComponent from "../ActivityFeedComponents/ActivityGridComponent";
import DailyStepsDetailsComponent from "../ActivityFeedComponents/DailyStepsDetailsComponent";
import DailyStepsGridComponent from "../ActivityFeedComponents/DailyStepsGridComponent";
import ExerciseDetailsComponent from "../ActivityFeedComponents/ExerciseDetailsComponent";
import SystemActivityDetailsComponent from "../ActivityFeedComponents/SystemActivityDetailsComponent";
import EndOfWeekSummaryComponent from "../HomePageCards/EndOfWeekSummaryComponent";
import "../../css/ActivitiesFeed.css";
import "../../css/Home.css";
import { excludedActivities, systemActivities } from "../../models/constants";
import { getUserActivities } from "../../services/ActivitiesService";
import {
	convertExerciseActivityToFlavorText,
	convertExerciseActivityToText,
	convertSystemActivityToTextUser,
	getIconFromExerciseActivity,
	getUserTimeZone,
} from "../../services/HelperServices";
import { getUserID, getUserNameFromUserID } from "../../services/UserService";
import { StorageService } from "../../services/StorageService";

const UserActivityFeed = ({ passedUserID, parentFeedOffset }) => {
	//Get the feed
	// Render the feed
	// If the user scrolls, get more feed
	let userID = passedUserID;
	const [showModal, setShowModal] = useState(false);
	const [activities, setActivities] = useState([]);
	const [dailySum, setDailySum] = useState([]);
	const [dailySumBonus, setDailySumBonus] = useState([]);
	const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false);
	const [activityOffset, setActivityOffset] = useState(0);
	const [loading, setLoading] = useState(false);
	const [firstPull, setFirstPull] = useState(true);
	const [refreshing, setRefreshing] = useState(false);

	const [activityDetails, showActivityDetails] = useState({
		visible: false,
		details: {},
	});
	const [exerciseDetails, showExerciseDetails] = useState({
		visible: false,
		details: {},
	});
	const [systemActivityDetails, showSystemActivityDetails] = useState({
		visible: false,
		details: {},
	});
	const [dailyStepsDetails, showDailyStepsDetails] = useState({
		visible: false,
		details: {},
	});

	const loadingMessage = "Pulling activity data...";
	let activityLimit = 10;
	let counterDate = "";
	let activityDate;
	let showSeperator = true;
	let noActivitiesEmoji = ":shrug:";

	/*
	useIonViewDidEnter(() => {
		setActivityOffset(0)
		fetchActivities();
	});
	*/

	useEffect(() => {
		console.log("Rendering Activity Feed");
		console.log({ dailySum });
		setRefreshing(true);
		setActivities([]);
		setActivityOffset(0);
		fetchActivities(0, true).catch((error) => {
			setLoading(false);
			alert("Error: " + error);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parentFeedOffset]);

	const fetchActivities = async (offset, resetDailySum = false) => {
		let userName = "You";
		if (firstPull === true) {
			setLoading(true);
			setFirstPull(false);
		}
		if (offset == null || offset === undefined) {
			offset = activityOffset;
		}
		if (offset === 0 || parentFeedOffset === true) {
			offset = 0;
			// if we're fetching the most recent activities, reset the array
			console.log("Clearing activities");
			setActivities([]);
		}
		let activitiesArray = JSON.parse(
			await getUserActivities(activityLimit, offset, null, null, "endDate", userID).catch((error) => {
				setLoading(false);
				setRefreshing(false);
				throw Error(error);
			})
		);
		console.log(`activitiesArray.length = ${activitiesArray.length}`);
		let ao = activityOffset + activitiesArray.length;
		setActivityOffset(ao);
		if (userID !== null) {
			userName = await getUserNameFromUserID(userID);
		}
		let userTimeZone = await getUserTimeZone();
		let currentTimeZone = moment.tz.guess(true);
		activitiesArray.sort((a, b) => (a.endDate < b.endDate ? 1 : -1));
		console.log("SortedActivities: ", { activitiesArray });
		//console.log("Activities Array: " + JSON.stringify(activtiesArray));
		//let currentDate, previousDate
		let tempDailySum = dailySum;
		if (resetDailySum === true) {
			tempDailySum = [];
		}
		for (let element of activitiesArray) {
			let systemActivity = false;
			if (element.squadId == null) {
				if (excludedActivities.includes(element.activityType)) {
					// Some "activities" shouldn't be shown...
					return;
				}
				// we only care about activities not related to the squad
				if (systemActivities.includes(element.activityType)) {
					systemActivity = true;
				}
				if (element.activityType === "dailySteps" || element.activityType === "steps") {
					systemActivity = true;
				}
				if (systemActivity === true) {
					// we only care about system activities for the main user
					let feedBody = convertSystemActivityToTextUser(element);
					element.displayText = feedBody.displayText;
					element.flavorText = feedBody.flavorText;
					element.component = feedBody.component;
					element.displayDate = moment(element.endDate); // use the endDate for system activties
					if (element.displayDate > moment().tz(currentTimeZone)) {
						console.log(`Display date is in the future, using startDate`);
						element.displayDate = moment(element.startDate);
					}
					console.log(`Current Date: ${moment().tz(currentTimeZone).format()} ### element.displayDate: ${moment(element.displayDate).format()}`);
					element.icon = feedBody.icon;
					//element.bonusPoints = Math.ceil(element.points * 0.1);
					if (element.bonusPoints && element.bonusPoints > 0) {
						element.bonusFlavorText = "+" + element.bonusPoints + " bonus from your season commitment!";
					}
				} else {
					// it's a physical activity
					element.displayText = convertExerciseActivityToText(userName, element.activityType, element.activityData.value, element.activityData.valueUnit);
					//element.bonusPoints = Math.ceil(element.points * 0.1);
					if (element.bonusPoints && element.bonusPoints > 0) {
						element.bonusFlavorText = "+" + element.bonusPoints + " bonus from your season commitment!";
					}
					element.flavorText = convertExerciseActivityToFlavorText(userName, element.points);
					element.icon = getIconFromExerciseActivity(element.activityType);
					element.component = "exercisedetails";
					element.displayDate = moment(element.activityData.startDate).tz(currentTimeZone); // use the startDate for activities
				}
				if (element.activityData.startDate === element.activityData.endDate) {
					element.displayTime = "";
				} else {
					element.displayTime = moment(element.activityData.endDate).tz(currentTimeZone).format("h:mm a");
				}
				if (element.points) {
					let activityDate = moment(element.displayDate).tz(currentTimeZone).format("MM/DD/YY");
					// if (tempDailySum[activityDate] === undefined) {
					// 	tempDailySum[activityDate] = element.points + element.bonusPoints;
					// } else {
					// 	tempDailySum[activityDate] = tempDailySum[activityDate] + element.points + element.bonusPoints;
					// }
					tempDailySum[activityDate] = await getDailyTotal(activityDate);
				}
				//console.log("Index: ", index);
				//console.log("Workout info: ", activtiesArray[index]);
				if (systemActivity === true && element.activityType !== "dailySteps" && userID !== null) {
					console.log("skip adding system activity for other user");
				} else {
					setActivities((activities) => [...activities, element]);
				}
			}
		}
		setDailySum(tempDailySum);
		/*
		let sortedActivities = activities;
		sortedActivities.sort((a, b) => (a.displayDate > b.displayDate ? 1 : -1));
		console.log("SortedActivities: " + JSON.stringify(activities));
		setActivities(sortedActivities);
		*/
		setDisableInfiniteScroll(activitiesArray.length < activityLimit);
		setRefreshing(false);
		setLoading(false);
	};

	async function searchNext($event) {
		await fetchActivities(activityOffset).catch((error) => {
			setLoading(false);
			alert("Error: " + error);
		});
		$event.target.complete();
	}

	const getDailyTotal = async (date) => {
		const storageService = new StorageService();
		let userID = await getUserID();
		let pointHistory = await storageService.getObject("userPointHistory-" + userID);
		console.log({ date });

		let points = pointHistory.dailyTotals[date];
		console.log({ points });
		return points;
	};

	return (
		<React.Fragment>
			{showModal && showModal === true ? (
				<React.Fragment>
					<EndOfWeekSummaryComponent modalState={showModal} setModalState={setShowModal} />
				</React.Fragment>
			) : (
				""
			)}
			<IonPopover
				//event={showGoalConfirmation.event}
				isOpen={exerciseDetails.visible}
				cssClass="exercise-details-full-screen-popover"
				onDidDismiss={(e) => {
					showExerciseDetails({
						visible: false,
						details: null,
					});
				}}
			>
				<ExerciseDetailsComponent activityObject={exerciseDetails.details} showExerciseDetails={showExerciseDetails} />
			</IonPopover>
			<IonPopover
				//event={showGoalConfirmation.event}
				isOpen={systemActivityDetails.visible}
				cssClass="exercise-details-full-screen-popover"
				onDidDismiss={(e) => {
					showSystemActivityDetails({
						visible: false,
						details: null,
					});
				}}
			>
				<SystemActivityDetailsComponent activityObject={systemActivityDetails.details} showSystemActivityDetails={showSystemActivityDetails} />
			</IonPopover>
			<IonPopover
				//event={showGoalConfirmation.event}
				isOpen={systemActivityDetails.visible}
				cssClass="exercise-details-full-screen-popover"
				onDidDismiss={(e) => {
					showSystemActivityDetails({
						visible: false,
						details: null,
					});
				}}
			>
				<SystemActivityDetailsComponent activityObject={systemActivityDetails.details} showSystemActivityDetails={showSystemActivityDetails} />
			</IonPopover>
			<IonPopover
				//event={showGoalConfirmation.event}
				isOpen={dailyStepsDetails.visible}
				cssClass="exercise-details-full-screen-popover"
				onDidDismiss={(e) => {
					showDailyStepsDetails({
						visible: false,
						details: null,
					});
				}}
			>
				<DailyStepsDetailsComponent activityObject={dailyStepsDetails.details} showDailyStepsDetails={showDailyStepsDetails} />
			</IonPopover>

			<IonLoading isOpen={loading} onDidDismiss={() => setLoading(false)} message={loadingMessage} duration={10000} />
			{activities.length === 0 && !loading && !refreshing ? (
				<div className="no-activities">
					<Emoji text={noActivitiesEmoji} />
					<br />
					No activities recorded
					<br />
				</div>
			) : (
				""
			)}
			{activities &&
				activities.map((activity, i) => {
					activityDate = moment(activity.displayDate).format("ddd, MMMM DD");
					// let dSum = getDailyTotal(activityDate);
					let dSum = dailySum[moment(activity.displayDate).format("MM/DD/YY")];

					if (counterDate !== activityDate) {
						showSeperator = true;
						counterDate = activityDate;
					} else {
						showSeperator = false;
					}
					return (
						<IonList key={`${i}`}>
							<div>
								{showSeperator === true ? (
									<React.Fragment>
										{i > 0 ? <div style={{ margin: "auto", width: "85%", height: "1px", backgroundColor: "lightgray" }} /> : ""}
										<div className="date-separator" style={{ display: "block" }}>
											<div className="date-separator-text">{activityDate}</div>
											<div className="flavor-text" style={{ width: "100%", textAlign: "right", opacity: "0.5", paddingRight: "5%" }}>
												Daily sum: {dSum} points
											</div>
										</div>
									</React.Fragment>
								) : (
									""
								)}
							</div>
							{activity.component === "exercisedetails" ? (
								<IonItem className="feed-item" lines="none" onClick={() => showExerciseDetails({ visible: true, details: activity })}>
									<ActivityGridComponent activity={activity} displayTime={true} />
								</IonItem>
							) : (
								""
							)}
							{activity.component === "systemactivitydetails" ? (
								<IonItem className="feed-item" lines="none" onClick={() => showSystemActivityDetails({ visible: true, details: activity })}>
									<ActivityGridComponent activity={activity} />
								</IonItem>
							) : (
								""
							)}
							{activity.component === "dailysteps" ? (
								<IonItem className="feed-item" lines="none" onClick={() => showDailyStepsDetails({ visible: true, details: activity })}>
									<DailyStepsGridComponent activity={activity} />
								</IonItem>
							) : (
								""
							)}
							{activity.component === "newusergoal" ? (
								<IonItem className="feed-item" lines="none" routerLink={`/newusergoal`}>
									<ActivityGridComponent activity={activity} />
								</IonItem>
							) : (
								""
							)}
							{activity.component === "endofweeksummary" ? (
								<IonItem className="feed-item" lines="none" onClick={() => setShowModal(true)}>
									<ActivityGridComponent activity={activity} />
								</IonItem>
							) : (
								""
							)}
						</IonList>
					);
				})}
			<IonInfiniteScroll threshold="100px" disabled={disableInfiniteScroll} onIonInfinite={(e) => searchNext(e)}>
				<IonInfiniteScrollContent loadingText="Getting more activities..."></IonInfiniteScrollContent>
			</IonInfiniteScroll>
		</React.Fragment>
	);
};

export default UserActivityFeed;
