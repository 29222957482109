import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonCard,
	IonContent,
	IonFooter,
	IonHeader,
	IonModal,
	IonPage,
	IonProgressBar,
	IonSlide,
	IonSlides,
	IonToolbar,
	useIonViewWillEnter,
} from "@ionic/react";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router";
import "../../css/Home.css";
import "../../css/Onboarding.css";
import { logPageView } from "../../services/AnalyticsService";
import { getAvailableChallenges } from "../../services/ChallengeService";
import { StorageService } from "../../services/StorageService";
import ChallengeDetails from "../Challenges/ChallengeDetails";
import ChallengeHeaderComponent from "../Challenges/ChallengeHeaderComponent";
// // Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
// import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom, Navigation } from "swiper";
// import "swiper/swiper.min.css";
// import "swiper/modules/autoplay/autoplay.min.css";
// import "swiper/modules/keyboard/keyboard.min.css";
// import "swiper/modules/pagination/pagination.min.css";
// import "swiper/modules/scrollbar/scrollbar.min.css";
// import "swiper/modules/zoom/zoom.min.css";

// Import Swiper styles
//import "swiper/css";
const storageService = new StorageService();

const ChallengesSignup = ({ progress, setProgress }) => {
	const mySwiper = useRef(document.createElement("ion-slides"));
	const [availableChallenges, setAvailableChallenges] = useState();
	const [challengeDetails, setChallengeDetails] = useState({
		visible: false,
		challengeID: null,
	});

	const slideOpts = {
		initialSlide: 0,
		speed: 400,
		slidesPerView: 1.2,
		autoHeight: true,
		centeredSlides: true,
	};

	useIonViewWillEnter(async () => {
		logPageView("Challenges Signup");
		setProgress(0.97);
		await storageService.setItem("lastOnboardingStep", 17);
		setAvailableChallenges(await getAvailableChallenges());
	});

	const showChallengeDetails = (challengeID) => {
		setChallengeDetails({
			visible: true,
			challengeID: challengeID,
		});
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar style={{ "--border-style": "none" }}>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
				</IonToolbar>
				<IonProgressBar value={progress} />
			</IonHeader>
			<IonContent>
				<IonModal isOpen={challengeDetails.visible}>
					<ChallengeDetails challengeIDPassed={challengeDetails.challengeID} />
				</IonModal>
				<div className="onboarding-main-div">
					<div className="onboarding-title">Signup for your first Challenge!</div>
					<div className="onboarding-hero-image-div">
						<img className="onboarding-hero-image" src="assets/images/points-diagram.jpg" alt="" />
					</div>

					<div className="onboarding-text-area" style={{ textAlign: "center" }}>
						<p>Challenges are multi-week goals that reward you for signing up, reward you for completion and contribute proceed to a worthy organiaztion</p>
						<p>Sign up for one of these challenges to kick-off your commitment!</p>
					</div>
				</div>
				{availableChallenges ? (
					<React.Fragment>
						<IonSlides pager={true} options={slideOpts} className="slides-container" style={{ height: "auto", minHeight: "100%" }} lock ref={mySwiper}>
							{availableChallenges.map((challenge, i) => {
								return (
									<IonSlide key={`${i}`}>
										<IonCard onClick={() => showChallengeDetails(challenge.id)}>
											<ChallengeHeaderComponent challengeDetails={challenge} />
										</IonCard>
									</IonSlide>
								);
							})}
						</IonSlides>
					</React.Fragment>
				) : (
					""
				)}
			</IonContent>
			<IonFooter className="onboarding-footer">
				<IonButton className="button-as-link" routerLink={"/onboarding/squadselection"}>
					Not right now
				</IonButton>
			</IonFooter>
		</IonPage>
	);
};

export default ChallengesSignup;
