import { CameraResultType, CameraSource, Plugins } from "@capacitor/core";
import {
	IonAlert,
	IonBackButton,
	IonButton,
	IonButtons,
	IonContent,
	IonFooter,
	IonHeader,
	IonLoading,
	IonPage,
	IonProgressBar,
	IonToolbar,
	useIonViewDidEnter,
} from "@ionic/react";
import React, { useState } from "react";
import { useHistory } from "react-router";
import "../../css/Home.css";
import "../../css/Onboarding.css";
import { logPageView } from "../../services/AnalyticsService";
import { sendSlackNotification } from "../../services/NotificationService";
import { getRandomAvatar } from "../../services/SquadService";
import { StorageService } from "../../services/StorageService";
import { getUser, updateUser } from "../../services/UserService";

const storageService = new StorageService();
const { Device } = Plugins;

const SavePhoto = ({ progress, setProgress, setSquadToJoin, defaultInviteCode }) => {
	const history = useHistory();
	const { Camera } = Plugins;
	const [takenPhoto, setTakenPhoto] = useState(null);
	const [userObject, setUserObject] = useState(null);
	const [savingPhoto, setSavingPhoto] = useState({
		visible: false,
		message: "",
	});

	const [photoError, setPhotoError] = useState({
		visible: false,
		message: null,
	});

	useIonViewDidEnter(async () => {
		let user = JSON.parse(await getUser());
		setUserObject(user);
		logPageView("savephoto");
		setProgress(0.85);
		storageService.setItem("lastOnboardingStep", 14);
	});

	const takePhotoHandler = async () => {
		const photo = await Camera.getPhoto({
			allowEditing: false,
			correctOrientation: true,
			resultType: CameraResultType.DataUrl,
			source: CameraSource.Prompt,
			quality: 80,
			width: 500,
		});

		//console.log("Photo String: " + JSON.stringify(photo));
		setTakenPhoto({
			path: photo.dataUrl,
			preview: photo.dataUrl,
		});
	};

	const validateData = async () => {
		if (takenPhoto !== null) {
			setSavingPhoto({
				visible: true,
				message: "Saving your amazing photo 😉",
			});
			let body = {
				avatar: takenPhoto.preview,
			};
			let response = await updateUser(body);
			setSavingPhoto({ visible: false });
			sendSlackNotification("*Image updates*\nUsername: " + userObject.userName + "\nAvatar: " + response.avatar + "\n", "creation");
		}
		history.push("/onboarding/saveyourprogress");
	};

	const validateDataRandom = async () => {
		let rando = await getRandomAvatar();
		setTakenPhoto({
			path: rando,
			preview: rando,
		});
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar style={{ "--border-style": "none" }}>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
				</IonToolbar>
				<IonProgressBar value={progress} />
			</IonHeader>
			<IonLoading isOpen={savingPhoto.visible} message={savingPhoto.message} backdropDismiss />
			<IonContent>
				<IonAlert
					isOpen={!!photoError.visible}
					header="Oops"
					message={photoError.message}
					buttons={[
						{
							text: "Understood",
							role: "ok",
							handler: () => {
								setPhotoError({
									visible: false,
									message: null,
								});
							},
						},
					]}
					onDidDismiss={() =>
						setPhotoError({
							visible: false,
							message: null,
						})
					}
				/>
				<div className="onboarding-main-div">
					<div className="onboarding-title">Almost done! Photo time!</div>
					<div className="onboarding-text-area" style={{ textAlign: "center" }}>
						<p>Want to add picture to show your squadmates who you are?</p>
					</div>
					{userObject ? (
						<div style={{ height: "50%", marginTop: "20%" }}>
							<div style={{ textAlign: "center" }}>
								{takenPhoto ? (
									<img src={takenPhoto.preview} className="onboarding-add-photo-button onboarding-avatar-image" alt="" onClick={takePhotoHandler} />
								) : (
									<img src={userObject.avatar} className="onboarding-add-photo-button onboarding-avatar-image" alt="" onClick={takePhotoHandler} />
								)}
							</div>
							<div className="onboarding-add-photo-button-div">
								<img src="assets/images/add-photo-button.png" className="onboarding-add-photo-button" alt="" onClick={takePhotoHandler} />
							</div>
						</div>
					) : (
						""
					)}
				</div>
			</IonContent>
			<IonFooter className="onboarding-footer">
				<IonButton onClick={validateData} className="onboarding-advance-button">
					Save photo
				</IonButton>
				<IonButton onClick={validateDataRandom} className="onboarding-advance-button">
					Get random photo
				</IonButton>
				<IonButton className="button-as-link" routerLink="/onboarding/saveyourprogress">
					No thanks
				</IonButton>
			</IonFooter>
		</IonPage>
	);
};

export default SavePhoto;
