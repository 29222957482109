/* eslint-disable jsx-a11y/accessible-emoji */
import { IonAvatar, IonButton, IonCard, IonCol, IonContent, IonLoading, IonPopover, IonRow, IonSpinner } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import "../../css/Home.css";
import "../../css/SquadScoreboard.css";
import { getGoal, getPrediction, isGoalCompleted } from "../../services/PointsAndGoalsService";
import { hasSeasonStarted, isUserinSeason } from "../../services/SeasonsService";
import { getSquadMembers, getSquadNameFromSquadID } from "../../services/SquadService";
import { getUser, getUserNameOfLoggedInUser } from "../../services/UserService";
import LoadingComponent from "../LoadingComponent";
import AuthContext from "../../contexts/GlobalState";
import { scheduleSingleNotification, sendSlackNotification } from "../../services/NotificationService";
import { logGiveLoveEvent, logNavigationEvent } from "../../services/AnalyticsService";
import { logReactionGiven } from "../../services/FeedService";
import JSConfetti from "js-confetti";
import UserDataComponent from "../UserDataComponent";

const SquadScoreboardComponent = ({ squadID, setSquadMemberDetailsModal, refresher }) => {
	const { userState, squadMemberProgress, calculatingSquadMemberProgress, updateSquadMates } = useContext(AuthContext);
	const history = useHistory();
	const jsConfetti = new JSConfetti();
	const userName = userState.user.userName;
	const [squadMembers, setSquadMembers] = useState();
	const [loading, setLoading] = useState(false);
	const [confirmationMessage, setConfirmationMessage] = useState({
		open: false,
		message: null,
		emoji: null,
	});

	//let remainingSquadPoints;

	useEffect(() => {
		console.log({ calculatingSquadMemberProgress });
		if (calculatingSquadMemberProgress === false) {
			console.log("calculatingSquadMemberProgress = false");
			//loadSquadMembers(squadID);
			let squadMembers = squadMemberProgress[squadID];
			// console.log({ squadMembers });
			setSquadMembers(squadMembers);
		} else {
			console.log("calculatingSquadMemberProgress = true");
			setLoading(true);
		}
	}, [calculatingSquadMemberProgress]);

	useEffect(() => {
		let response = updateSquadMates(userState.user, true).then(() => {
			setLoading(false);
			console.log("SquadMemberProgress in updateSquadMates");
			console.log({ squadMemberProgress });
			console.log(JSON.stringify(squadMemberProgress[308]));
			let squadMembers = squadMemberProgress[squadID];
			console.log({ squadMembers });
			if (squadMembers !== undefined) {
				setSquadMembers(squadMembers);
			}
		});
		console.log({ response });
		// }, [refresher]); this would mean it updates every tick. That seems too often
	}, []);

	const navTo = (destination, analyticsName) => {
		logNavigationEvent("squad-home-stats", analyticsName);
		history.push(destination);
	};

	const sendCelebration = async (member) => {
		let bodyObject = {
			title: "🎉🎉🎉🎉🎉🎉🎉🎉",
			message: userName + " saw your progress so far this week and says congrats!",
			recipientId: member.userID,
		};
		let squadName = await getSquadNameFromSquadID(squadID);
		logGiveLoveEvent("stats", "goal congrats", "🎉", member.userID);
		logReactionGiven("congrats");
		scheduleSingleNotification(bodyObject.message, bodyObject.title, member.userID);
		sendSlackNotification(`*New Goal Progress Love Sent*\n*From:* ${userName}\n*To:* ${member.userName}\n*Squad:* ${squadName}\n*Type:* Celebration`, "love");
		setConfirmationMessage({
			open: true,
			message: "🎉 Congrats sent! 🎉<br>" + member.userName + " will enjoy that.",
			emoji: ":blue_heart:",
		});
		jsConfetti.addConfetti({
			emojis: ["🎉"],
		});
	};

	const sendMotivation = async (member) => {
		let bodyObject = {
			title: "💪💪💪💪💪💪💪💪",
			message: userName + " saw your progress so far this week and says get motivated!",
			recipientId: member.userID,
		};
		let squadName = await getSquadNameFromSquadID(squadID);
		scheduleSingleNotification(bodyObject.message, bodyObject.title, member.userID);
		sendSlackNotification(`*New Goal Progress Love Sent*\n*From:* ${userName}\n*To:* ${member.userName}\n*Squad:* ${squadName}\n*Type:* Motivation`, "love");
		logGiveLoveEvent("stats", "goal motivation", "💪", member.userID);
		logReactionGiven("motivation");
		setConfirmationMessage({
			open: true,
			message: "💪 Motivation sent! 💪<br>" + member.userName + " will enjoy that.",
			emoji: ":blue_heart:",
		});
		jsConfetti.addConfetti({
			emojis: ["💪"],
		});
	};

	return (
		<React.Fragment>
			<div className="member-list">
				<IonPopover
					isOpen={confirmationMessage.open}
					className="love-thanks"
					onDidDismiss={(e) => {
						setConfirmationMessage({
							open: false,
							message: null,
							imageURL: null,
						});
					}}
				>
					<div style={{ fontSize: "1.25em", paddingTop: "33px", textAlign: "center" }}>
						<br />
						<span dangerouslySetInnerHTML={{ __html: confirmationMessage.message }}></span>
					</div>
				</IonPopover>
				{squadMembers && squadMembers.length > 0 ? (
					squadMembers.map((member, i) => {
						//console.log({ member });
						let rowBackgroundColor = "#fff";
						let prediction = {
							text: member.predictionText,
							points: member.goal.prediction,
							iconColor: member.iconColor,
						};
						let goalObject = {
							goalProgress: member.goal.goalProgress,
							goalPercent: member.goal.goalPercent,
							goalPoints: member.goal.goalPoints,
							prediction: member.goal.prediction,
						};
						return (
							<IonCard className="stats-card" key={`${i}`}>
								<div
									onClick={() => {
										navTo("/otherprofile/" + member.userID, "otherprofile");
									}}
								>
									<UserDataComponent userObj={member} goalObj={goalObject} />
								</div>
								<IonRow>
									<IonCol size="6" style={{ textAlign: "center" }}>
										<IonButton size="small" color="success" onClick={() => sendCelebration(member)}>
											🎉 Celebrate
										</IonButton>
									</IonCol>
									<IonCol size="6" style={{ textAlign: "center" }}>
										<IonButton size="small" color="warning" onClick={() => sendMotivation(member)}>
											💪 Motivate
										</IonButton>
									</IonCol>
								</IonRow>
							</IonCard>
						);
					})
				) : (
					<div>
						Calculating squad member stats...
						<IonSpinner />
					</div>
				)}
			</div>
		</React.Fragment>
	);
};

export default SquadScoreboardComponent;
