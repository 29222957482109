import moment from "moment";
import { idkMessages } from "../models/botMessages";
import { ChatMessageType } from "../models/chat-message";
import { API_URL, botResponseTime, possibleVariables, randomCheerleaderNames } from "../models/constants";
import { getIDToken } from "./AuthService";
import botFunctions from "./BotFunctions";
import { createGoalFromFitbit } from "./FitbitService";
import { getRandomGifFromSearch } from "./GifService";
import { getBubbleSize, getRandomValueFromArray } from "./HelperServices";
import { sendSlackBotNotification, sendSlackBotNotificationFromBot, sendSlackErrorNotification } from "./NotificationService";
import { StorageService } from "./StorageService";
import { getUserID, getUserNameFromUserID, getUserNameOfLoggedInUser, updateUser } from "./UserService";
const storageService = new StorageService();



export const BOT_API_BASE_URL = "https://fitpetapp.com/fitbotphp";
//export const BOT_API_URL = "/dialogflow-api/tester.php"


export const sendApiMessage = async (buttonId) => {
	const url = API_URL;
	const data = {
		buttonId: buttonId,
	};
	var idToken;
	idToken = await getIDToken().catch((error) => {
		throw Error("[sendApiMessage] Error in getIDtoken: " + error);
	});
	console.log("[JS][sendApiMessage] token is good to go");

    const response = await Promise.resolve(
		fetch(url + "message", {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[JS][sendApiMessage] Error in API call: " + error);
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {
			console.log("[JS] sendApiMessage Success:", data);
			let msgResponse = JSON.parse(JSON.stringify(data));
			return data;
		})
		.catch((error) => {
			console.log("[JS] Error in JSON conversion: " + error);
			throw Error(error);
		});
};


export const logMessage = async (fromUserID, toUserID, message) => {
	let fromUserName
	let toUserName
	if (fromUserID === "1"){
		fromUserName = "bot"
	} else {
		fromUserName = await getUserNameFromUserID(fromUserID).catch((error) => {
			console.log("No user account");
		})
	}
	if (toUserID === "1"){
		toUserName = "bot"
	} else {
		toUserName = await getUserNameFromUserID(toUserID).catch((error) => {
			console.log("No user account");
		})
	}
	let queryParams = {
		"fromUserID": fromUserID,
		"fromUserName": fromUserName,
		"toUserID": toUserID,
		"toUserName": toUserName,
		"message": message
	}
	
	let url = new URL(BOT_API_BASE_URL+ "/fitsquad/logBotMessage.php")
	if (queryParams !== null){
		url.search = new URLSearchParams(queryParams).toString()
	}
	await Promise.resolve(
		fetch(url, {
			method: "GET",
		})
	);
}


export const sendFreeTextMessageToDialogFlow = async (text) => {
	logMessage(await getUserID(),"1",text)
	const url = BOT_API_BASE_URL + "/dialogflow-api/tester.php?text=" + text;
	let logWorkoutArray = {};
	let action
	console.log("URL: " + url);

	const response = await Promise.resolve(
		fetch(url, {
			method: "GET",
		})
	);

	let data = await response.json();

	console.log("sendFreeTextMessage Success:", data);
	let msgResponse = {
		messageText: "",
		imageUrl: "",
	};
	msgResponse.dialogFlowResponse = data;
	console.log("Message response: ",{msgResponse});
	// Do the below so the highlevel param "messageText" always has a value
	if (msgResponse) {
		// if DF has a response
		if (msgResponse.dialogFlowResponse.queryResult.fulfillmentText) {
			// if it sends back fulfillmentText
			//console.log("FulfillmentText: " + msgResponse.dialogFlowResponse.queryResult.fulfillmentText);
			//console.log("FulfillmentText contains http: " + msgResponse.dialogFlowResponse.queryResult.fulfillmentText.includes("http"));
			// If the fulfillment text has http, assume it's an image and put it in the imageURL field
			if (msgResponse.dialogFlowResponse.queryResult.fulfillmentText.includes("http")) {
				msgResponse.messageText = "";
				msgResponse.imageUrl = msgResponse.dialogFlowResponse.queryResult.fulfillmentText;
			} else {
				msgResponse.messageText = msgResponse.dialogFlowResponse.queryResult.fulfillmentText; // populate messageText with that
			}
		} else { // DF responded with an action
			if(msgResponse.dialogFlowResponse.queryResult.action){
				while(msgResponse.dialogFlowResponse.queryResult.action.indexOf(".")>0) { // remove the periods
					msgResponse.dialogFlowResponse.queryResult.action = msgResponse.dialogFlowResponse.queryResult.action.replace('.',''); // otherwise, for now, put the action in there
				}			
				let botAction = new botFunctions()	
				// execute a function in BotFunctions based on what DF responds with		
				switch (msgResponse.dialogFlowResponse.queryResult.action) {
					case "logworkoutmets":
						msgResponse = await botAction[msgResponse.dialogFlowResponse.queryResult.action](msgResponse)
						//msgResponse.type = "LOG_WORKOUT" // from constants conversationEngineMessageTypes
						break;
					case "smalltalkagent":
						msgResponse = await botAction[msgResponse.dialogFlowResponse.queryResult.action](msgResponse).catch((error)=>{
							error.messageResponse = msgResponse
							throw Error(error)
						})
						break;
					case "processfreetextresponse":
						msgResponse = await botAction[msgResponse.dialogFlowResponse.queryResult.action](msgResponse).catch((error)=>{
							error.messageResponse = msgResponse
							throw Error(error)
						})
						break;
					default:
						msgResponse.messageText = getRandomValueFromArray(idkMessages);
						break;
				}
			} else {
				console.log("DF didn't response with action OR fulfillment text");
				msgResponse.messageText = "Error occurred: DF didn't response with action OR fulfillment text"
			}
		}
	}
	return msgResponse;
};

export const getMessageFromDatabase = async (messageID, messageQuery, messageContextID) => {
	let url = new URL(BOT_API_BASE_URL+ "/fitsquad/getMessage.php")
	let queryParams = {}
	if (messageID){
		queryParams.messageID = messageID
	}
	if (messageQuery){
		queryParams.message_query = messageQuery
	}
	if (messageContextID){
		queryParams.message_context_id = messageContextID
	}
	if (queryParams !== null){
		url.search = new URLSearchParams(queryParams).toString()
	}
	//console.log({url});
	const response = await Promise.resolve(
		fetch(url, {
			method: "GET",
		})
	);
	//console.log({response});
	let messageText = await response.json();
	console.log("messageText from get message: "+messageText);
	return messageText
}

export const activityFollowUpMessage = async (activity) =>{
	let userName
			await getUserNameOfLoggedInUser().then((response) => {
				userName = response
			}).catch((error)=>{
				userName = "unknown user"
			})
	const oddsOfMessage = 70
	let randomChance = Math.floor(Math.random() * 100)
	if (randomChance > oddsOfMessage) {
		console.log({randomChance});
		return null
	}
	let responseArray = [
		`If you were to describe your ${activity} in one word, what would the word be?`,
		`Is there a song that can describe how you feel after that ${activity}? `,
		`You know what ${userName}? \nI just want you to know I'm proud of you for doing that ♥ `,
		`Was it the most amazing ${activity} ever?`,
		`Would you use the word amazeballs to describe that ${activity}?`,
		`If that ${activity} was a rack of ribs, would you pour BBQ sauce all over it and go HAM on it? 😃`,
		`I bet you're sweaty AF after that ${activity}, amirite?`,
		`P.S. ${userName} - you're amazing. Just thought you should know that.`,
		`I can tell you curshed that ${activity} ${userName}! I felt it from inside your phone!`,
		`P.S. ${userName} - I just wanted to let you know something: You're sexy 😎. Yea I said it!`,
		`Are you like totally on an emotional high after that ${activity}, ${userName}?`,
		`After that ${activity}, you need to do some stretching. Promise me you will?`,
		`Great job with your ${activity}, ${userName}. Do you plan to do more this week?`,
		`I bet you looked great during your ${activity}, ${userName}! Wanna send me a selfie to show me you post ${activity} glow?`,
		`I'm so glad you're getting some physical activity, ${userName}. How about we add another session this week?`,
		`Great job! You're so inspiring. I hope you'll do more this week and try again next Monday, too.`,
		`Great job with your ${activity}, ${userName}! Is there anything more planned this week?`,
		`Great job with your ${activity}, ${userName}. I hope you'll do more this week and get that sweat!`,
		`Great work with your ${activity} this week! I am eager for what's ahead on our journey together`,
		`I'm so glad you're doing your ${activity}, ${userName}. I can't wait to see what we have in store for next week!`,
		`You're a strong person ${userName}! I'm so proud of you for doing that ${activity}`,
		`I'm so happy with all your hard work!`,
		`Excellent job with that workout. You are one in a million, ${userName}. The world needs more people like you!`,
	]
	let response = await getRandomValueFromArray(responseArray)
	return response
}

export const getDateLastMessageBotSent = async () =>{
	const storageService = new StorageService();
	let messageStore = await storageService.getBotMessages();
	let lastMessage = messageStore.at(-1)
	return moment.unix(lastMessage.date/1000)
}

export const scrollToBottom = () => {
	let msgRendererEl = document.querySelector("#messageRendererContainer");
	//let lastMsg = document.querySelector(".message-container:last-child");

	//console.log("Message Renderer E1: " + msgRendererEl);

	if (msgRendererEl !== null) {
		msgRendererEl.scrollIntoView({ block: "end", behavior: "smooth" });
		console.log("scrolled to bottom");
	}
};

export const inputFocus = async (setMessages) => {
	//console.log("Focus on input. Should scroll");
	let currentFeed = await storageService.getBotMessages();
	setMessages(currentFeed);
	//setScroller(true);
}

export const showTypingIndicator = async (msgStorage, setMessages) => {
	// This pushes the users message into the message storage
	const typingMessage = {
		id: "0",
		msg: "is typing",
		date: new Date().getTime().toString(),
		responseButtonId: 0,
		sender: ChatMessageType.Bot,
		imageUrl: "",
		size: "7",
	};
	msgStorage.push(typingMessage);
	await storageService.setObject("botMessages", msgStorage);
	//setTypingMessage(msgStorage);
	inputFocus(setMessages);
	scrollToBottom();
};

export const sendActivityMessage = async (activityData, setBotResponseMessages, setButtons, setMessages, setContextID, command, setCommand) => {
	console.log({activityData});
	//activityData.imageUrl = randomGif;
	//get messages from storage
	let msgStorage = await storageService.getBotMessages();
	if (msgStorage === null) {
		msgStorage = [];
	}
	//store user's response & rerender
	let text = "I did " + activityData.activity + " for " + activityData.duration + " " + activityData.unit + " at " + activityData.intensity + " intensity";
	let congratsMessage = "Awesome Job! That <b>" + activityData.activity + "</b> earned you <b>" + activityData.points + " points!</b> "
	let lookALittleMessage = "Did your "+ activityData.activity +" look a little like this? 😁"
	if (activityData.dataType === "steps"){
		text = "I did " + activityData.duration + " " + activityData.unit;
		congratsMessage = "Way to go! Those <b>" + activityData.duration + " " + activityData.unit + "</b> earned you <b>" + activityData.points + " points!</b> "
	}
	storeFreeTextUserMessage(text, msgStorage, setMessages);
	let deviceInfo = await storageService.getObject("deviceInfo")
	let goodMessageID, mehMessageID

	let messageArray = [
		{
			"buttons": null,
			"command": null,
			"imageUrl": "",
			"contextID": null,
			"messageText": congratsMessage
		  },
		  {
			"buttons": null,
			"command": null,
			"imageUrl": "",
			"contextID": null,
			"messageText": lookALittleMessage
		  },{
			"buttons": [
				{
					"text": "Haha yes!",
					"category": "positive_gif"
				},
				{
					"text": "Meh not really",
					"category": "negative_gif"
				}
			],
			"command": null,
			"imageUrl": activityData.imageURL,
			"contextID": null,
			"messageText": ""
		  }
	]
	botSendMessage(messageArray,setBotResponseMessages,setButtons,setMessages, true, setContextID, command, setCommand)
};

export const storeFreeTextUserMessage = (text, msgStorage, setMessages) => {
	let messageBubbleSize = getBubbleSize(text);
	const userResponseFreeTextMessage = {
		msg: text,
		date: new Date().getTime().toString(),
		sender: ChatMessageType.User,
		size: messageBubbleSize.toString(),
	};

	// We push messages into this array...
	msgStorage.push(userResponseFreeTextMessage);
	setMessages(msgStorage);

	console.log("user msg", userResponseFreeTextMessage);

	// AND we add it to local storage!
	storageService.setObject("botMessages", msgStorage);
};

export const sendBotMessage = async (messageObject, msgStorage, removeIndicator = true, setBotResponseMessages, setButtons,setMessages) => {
	let messageBubbleSize = 9
	if (messageObject.messageText !== ""){ // account for messages without text (i.e. image only)
		const encodedMessageText = messageObject.messageText.replace(/[\u00A0-\u9999<>]/gim, function (i) {
			return "&#" + i.charCodeAt(0) + ";";
		});
		messageBubbleSize = getBubbleSize(encodedMessageText);
	}
	let apiResponseChatMsg = {
		msg: messageObject.messageText,
		date: new Date().getTime().toString(),
		sender: ChatMessageType.Bot,
		imageUrl: messageObject.imageUrl,
		size: messageBubbleSize.toString(),
		buttons: null
	};
	messageObject.buttons && (apiResponseChatMsg.buttons = messageObject.buttons);
	if (removeIndicator === true) {
		msgStorage.pop();
	}
	msgStorage.push(apiResponseChatMsg);
	//logMessage("1", await getUserID(), messageResponse.messageText + " " + messageResponse.imageUrl);
	setMessages(msgStorage);
	// We do the above BEFORE we set the object so that not only is the array cleared but the local storage as well
	storageService.setObject("botMessages", msgStorage).then(() => {
		// not sure why await doesn't work...
		setBotResponseMessages(msgStorage); // not sure why this is needed but it is
		apiResponseChatMsg.buttons && setButtons(apiResponseChatMsg.buttons);
		scrollToBottom();
	});
};

export const storeUserMessage = (button, msgStorage,setMessages) => {
	let messageBubbleSize = getBubbleSize(button.text);
	const userResponseChatMsg = {
		id: "" + button.messageID,
		msg: button.text,
		date: new Date().getTime().toString(),
		responseButtonId: button.messageID,
		sender: ChatMessageType.User,
		imageUrl: "",
		size: messageBubbleSize.toString(),
	};

	// We push messages into this array...
	msgStorage.push(userResponseChatMsg);
	setMessages(msgStorage);

	console.log("user msg", userResponseChatMsg);

	// AND we add it to local storage!
	storageService.setObject("botMessages", JSON.stringify(msgStorage)).then(() => {});
};

export const storeBotMessage = async (messageResponse, button, msgStorage, setButtons, setBotResponseMessages) => {
	setButtons(messageResponse.buttons);
	const encodedMessageText = messageResponse.messageText.replace(/[\u00A0-\u9999<>]/gim, function (i) {
		return "&#" + i.charCodeAt(0) + ";";
	});
	let messageBubbleSize = getBubbleSize(encodedMessageText);
	const apiResponseChatMsg = {
		id: messageResponse.contextId.toString(),
		msg: encodedMessageText,
		date: new Date().getTime().toString(),
		responseButtonId: button.messageID,
		sender: ChatMessageType.Bot,
		imageUrl: messageResponse.imageUrl,
		size: messageBubbleSize.toString(),
	};
	console.log("Messages in the storage (after pop):", msgStorage);
	msgStorage.push(apiResponseChatMsg);

	// We do the above BEFORE we set the object so that not only is the array cleared but the local storage as well
	storageService.setObject("botMessages", msgStorage).then(() => {
		setBotResponseMessages(msgStorage);
		console.log("Messages in the storage (storeBotMessage):", msgStorage);
		scrollToBottom();
		//scrollToBottom();
	});
};

export const submitText = async (textInput, contextID = null, setMessages, setBotResponseMessages, setButtons, setContextID = null, command = null, setCommand = null) => {
	let text = textInput.current.value;
	if (text !== "" && text !== null && text !== undefined) { // ensures its not empty
		let username = await getUserNameOfLoggedInUser()
		textInput.current.value = ""; // clears the box
		console.log("Text captured: " + text);

		//get messages from storage
		let msgStorage = await storageService.getBotMessages();
		if (msgStorage === null) {
			msgStorage = [];
		}
		//store user's response & rerender
		console.log("Users text: " + JSON.stringify(text));

		storeFreeTextUserMessage(text, msgStorage, setMessages);
		
		if (command !== null){
			await processCommand(command, text)
			setCommand(null)
		}

		if (contextID !== null && contextID !== "") {			
			await showTypingIndicator(msgStorage, setMessages);
			let messageResponsePromise = getOnboardingMessageObject(contextID)
			let responseTime = botResponseTime;
			setTimeout(async () => {
				let messageObjectArray = await Promise.resolve(messageResponsePromise);
				setButtons(null)
				console.log("Message response, formatted: ", { messageObjectArray });
				botSendMessage(messageObjectArray, setBotResponseMessages, setButtons, setMessages, false, setContextID, command, setCommand);
				sendSlackBotNotification(username, text, messageObjectArray.messageText);

			}, responseTime);
		} else {
			// normal free text message
			await showTypingIndicator(msgStorage, setMessages);
			let responseTime = Math.random() * 1000 + 1000;
			let pop = true;
			let messageNum = 1;
			setTimeout(async () => {
				await sendFreeTextMessageToDialogFlow(text)
					.then(async (messageResponse) => {
						// after a certain amount of time, show the next message
						//console.log("Message response, formatted: ", { messageResponse });
						let messageTextArray = [];
						//console.log("messageResponse.messageText: " + messageResponse.messageText);
						if (messageResponse.messageText === "" || messageResponse.messageText === undefined || messageResponse.messageText === null) {
							messageResponse.messageText = messageResponse.imageUrl;
						}
						messageTextArray = messageResponse.messageText.split("\\n");
						messageResponse.wholeMessage = "";
						for (let message of messageTextArray) {
							if (messageNum > 1) {
								// ensures you only get an image for the first message
								messageResponse.imageUrl = null;
							}
							messageResponse.messageText = message;
							messageResponse.wholeMessage = messageResponse.wholeMessage + "\n" + messageResponse.messageText;
							//console.log("Sending bot message");
							await sendBotMessage(messageResponse, msgStorage, pop, setBotResponseMessages, setButtons, setMessages);
							pop = false;
							messageNum++;
						}
						console.log("From bot action: ", { messageResponse });
						/*
						if (messageResponse.type && conversationEngineMessageTypes.includes(messageResponse.type)) {
							console.log("Trigger Engine");
							let message = await conversationEngine(messageResponse);
							if (message) {
								let btwMessage = {
									messageText: "By the way...",
								};
								await sendBotMessage(btwMessage, msgStorage, false, setBotResponseMessages, setButtons, setMessages);
								await showTypingIndicator(msgStorage, setMessages);
								setTimeout(async () => {
									sendBotMessage(message, msgStorage, true);
								}, 5000);
							}
						}
						*/
						sendSlackBotNotification(username, text, messageResponse.wholeMessage, messageResponse);
					})
					.catch(async (error) => {
						console.log("Error thrown: " + error);
						let messageResponse = {};
						messageResponse.messageText = "Hmmm I didn't understand. Sorry 😞";
						await sendBotMessage(messageResponse, msgStorage, pop, setBotResponseMessages, setButtons, setMessages);
						pop = false;
						messageNum++;
						sendSlackBotNotification(username, text, messageResponse.messageText, "Error: " + error);
					}); // returns back the response from DF
			}, responseTime);
		}
	}
};

export const botSendMessage = async (messageArray, setBotResponseMessages, setButtons, setMessages, showTyping = true, setContextID, command, setCommand) => {
	// messageArray should be an array of objects of type messageResponse
	// messageResponse has messageText, imageUrl properties
	let msgStorage = await storageService.getBotMessages();
	//console.log({msgStorage});
	if (msgStorage === null) {msgStorage = []}
	let responseTime = botResponseTime;
	let addResponseTime = botResponseTime
	let pop = true;
	for await (let messageObject of messageArray) {
		let msg;
		
		if (showTyping === true) {
			setTimeout(async () => {
				await showTypingIndicator(msgStorage, setMessages);
			}, responseTime);
		} else {
			showTyping = true
		}
		setTimeout(async () => {
			let userName
			await getUserNameOfLoggedInUser().then((response) => {
				userName = response
			}).catch((error)=>{
				userName = "unknown user"
			})
			await sendBotMessage(messageObject, msgStorage, pop, setBotResponseMessages, setButtons, setMessages);
			msg = await storageService.getBotMessages();
			setMessages(msg);
			setButtons(messageObject.buttons)
			setContextID(messageObject.contextID)
			setCommand(messageObject.command)
			sendSlackBotNotificationFromBot(userName, messageObject);
		}, responseTime + addResponseTime);
		responseTime = responseTime + addResponseTime
	}
};

export 	const sendButtonResponse = async (button, globalContext, setBotResponseMessages, setButtons, setMessages, setContextID, command = null, setCommand) => {
	let messageResponsePromise;
	if (button.context !== undefined && button.context !== null) {
		globalContext = button.context;
	}
	const msgStorage = await storageService.getBotMessages();
	storeUserMessage(button, msgStorage, setMessages);
	console.log("Storage after user message:", msgStorage);

	//show the typing indicator
	await showTypingIndicator(msgStorage, setMessages);
	//log("Storage after typing indicator:", msgStorage);

	if (command !== null){
		await processCommand(command)
		setCommand(null)
	}
	if (button.category) {
		messageResponsePromise = getOnboardingMessageObject(null,button.category)
	} else {
		messageResponsePromise = getOnboardingMessageObject(button.messageID, null)
	}
	let responseTime = botResponseTime;
	setTimeout(async () => {
		let messageObjectArray = await Promise.resolve(messageResponsePromise);
		setButtons(null)
		console.log("Message response, formatted: ", { messageObjectArray });
		//let messageObjectArray = await packageMessageObjectArray(messageResponse);
		botSendMessage(messageObjectArray, setBotResponseMessages, setButtons, setMessages, false, setContextID, command, setCommand);
		/*
		if (messageResponse.type && conversationEngineMessageTypes.includes(messageResponse.type)) {
			console.log("Trigger Engine");
			let message = await conversationEngine(messageResponse);
			if (message) {
				if (message.btw === true) {
					let btwMessage = {
						messageText: "By the way...",
					};
					await sendBotMessage(btwMessage, msgStorage, false, setBotResponseMessages, setButtons, setMessages);
				}
				await showTypingIndicator(msgStorage, setMessages);
				setTimeout(async () => { 
					sendBotMessage(message, msgStorage, true);
				}, 5000);
			}
		}
		*/
		sendSlackBotNotification(await getUserNameOfLoggedInUser(), button.text, messageObjectArray.messageText);
	}, responseTime);
};

export const getWelcomeBackMessage = async () => {
	const url = "https://x8ki-letl-twmt.n7.xano.io/api:-91w5asB/random_welcome_back_message"
	let response
	let statusCode
	await fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			"Accept": "application/json",
		},
	}).catch(async (error)=>{
			let userID = await getUserID(false)
			let userName = await getUserNameFromUserID()
			console.log({error});
			sendSlackErrorNotification(error.status,"\nGET: "+url,"User ID: "+userID+"\nUserName: "+userName+"\nResult: "+error)
			throw new Error(error.status + " Failed Fetch ");
	}).then(async(result) => {
		// status 404 or 500 will set ok to false
		if (result.ok) {
			// Success: convert data received & run callback
			response = result
			statusCode = result.status
		}
		else {
			let userID = await getUserID(false)
			let userName = await getUserNameFromUserID()
			console.log({result});
			sendSlackErrorNotification(result.status,"\nGET: "+url,"User ID: "+userID+"\nUserName: "+userName+"\nResult: "+JSON.stringify(result))
			throw new Error(result.status + " Failed Fetch ");
		}
	})

	return response
		.json()
		.then((data) => {
			data.statusCode = statusCode
			console.log(`[GET ${url}] success: ${JSON.stringify(data)}`);
			return data[0].message;
		})
		.catch((error) => {
			console.log(`[GET ${url}] Error in JSON conversion: ${error}`);
			throw Error(error);
		});
}

export const getOnboardingMessage = async (botMessageID) => {
	const url = "https://x8ki-letl-twmt.n7.xano.io/api:-91w5asB/bot_messages/"+botMessageID
	let response
	let statusCode
	await fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			"Accept": "application/json",
		},
	}).catch(async (error)=>{
			let userID = await getUserID(false)
			let userName = await getUserNameFromUserID()
			console.log({error});
			sendSlackErrorNotification(error.status,"\nGET: "+url,"User ID: "+userID+"\nUserName: "+userName+"\nResult: "+error)
			throw new Error(error.status + " Failed Fetch ");
	}).then(async(result) => {
		// status 404 or 500 will set ok to false
		if (result.ok) {
			// Success: convert data received & run callback
			response = result
			statusCode = result.status
		}
		else {
			let userID = await getUserID(false)
			let userName = await getUserNameFromUserID()
			console.log({result});
			sendSlackErrorNotification(result.status,"\nGET: "+url,"User ID: "+userID+"\nUserName: "+userName+"\nResult: "+JSON.stringify(result))
			throw new Error(result.status + " Failed Fetch ");
		}
	})

	return response
		.json()
		.then(async (data) => {
			data.statusCode = statusCode
			data.messageText = await replaceVariables(data.messageText)
			console.log(`[GET ${url}] success: ${JSON.stringify(data)}`);
			return data;
		})
		.catch((error) => {
			console.log(`[GET ${url}] Error in JSON conversion: ${error}`);
			throw Error(error);
		});
}

export const getOnboardingMessageObject = async (botMessageID = null, messageCategory = null) => {
	const url = "https://x8ki-letl-twmt.n7.xano.io/api:-91w5asB/bot_message_objects"
	let data = {
		category: messageCategory,
		message_id: botMessageID
	}
	let response
	let statusCode
	await fetch(url, {
		method: "POST",
		body: JSON.stringify(data),
		headers: {
			"Content-Type": "application/json",
			"Accept": "application/json",
		},
	}).catch(async (error)=>{
			let userID = await getUserID(false)
			let userName = await getUserNameFromUserID()
			console.log({error});
			sendSlackErrorNotification(error.status,"\nGET: "+url,"User ID: "+userID+"\nUserName: "+userName+"\nResult: "+error)
			throw new Error(error.status + " Failed Fetch ");
	}).then(async(result) => {
		// status 404 or 500 will set ok to false
		if (result.ok) {
			// Success: convert data received & run callback
			response = result
			statusCode = result.status
		}
		else {
			let userID = await getUserID(false)
			let userName = await getUserNameFromUserID()
			console.log({result});
			sendSlackErrorNotification(result.status,"\nGET: "+url,"User ID: "+userID+"\nUserName: "+userName+"\nResult: "+JSON.stringify(result))
			throw new Error(result.status + " Failed Fetch ");
		}
	})

	return response
		.json()
		.then(async (data) => {
			console.log({data});
			data.statusCode = statusCode
			// iterate over each message in the object to replace variables
			let formattedMessageObject = []
			for await (let messageObject of data.messageObject){
				//console.log({messageObject});
				messageObject.messageText = await replaceVariables(messageObject.messageText)
				formattedMessageObject.push(messageObject)
			}
			//console.log(`[GET ${url}] success: ${JSON.stringify(formattedMessageObject)}`);
			return formattedMessageObject;
		})
		.catch((error) => {
			console.log(`[GET ${url}] Error in JSON conversion: ${error}`);
			throw Error(error);
		});
}

export const getOnboardingMessageWithText = async (botMessageID, text) => {
	const url = "https://x8ki-letl-twmt.n7.xano.io/api:-91w5asB/bot_messages_w_replacement"
	let response
	let statusCode
	let data = {
		"messageID": botMessageID,
		"text": text
	  }
	console.log({data});
	await fetch(url, {
		method: "POST",
		body: JSON.stringify(data),
		headers: {
			"Content-Type": "application/json",
			"Accept": "application/json",
		},
	}).catch(async (error)=>{
			let userID = await getUserID(false)
			let userName = await getUserNameFromUserID()
			console.log({error});
			sendSlackErrorNotification(error.status,"\nGET: "+url,"User ID: "+userID+"\nUserName: "+userName+"\nResult: "+error)
			throw new Error(error.status + " Failed Fetch ");
	}).then(async(result) => {
		// status 404 or 500 will set ok to false
		if (result.ok) {
			// Success: convert data received & run callback
			response = result
			statusCode = result.status
		}
		else {
			let userID = await getUserID(false)
			let userName = await getUserNameFromUserID()
			console.log({result});
			sendSlackErrorNotification(result.status,"\nGET: "+url,"User ID: "+userID+"\nUserName: "+userName+"\nResult: "+JSON.stringify(result))
			throw new Error(result.status + " Failed Fetch ");
		}
	})

	return response
		.json()
		.then((data) => {
			data.statusCode = statusCode
			console.log(`[GET ${url}] success: ${JSON.stringify(data)}`);
			return data;
		})
		.catch((error) => {
			console.log(`[GET ${url}] Error in JSON conversion: ${error}`);
			throw Error(error);
		});
}


export const replaceVariables = async (message) => {
	for await (let variable of possibleVariables){
		if (message.search(variable) >= 0){
			switch(variable){
				case "_userName_":
					let userName = await getUserNameOfLoggedInUser()
					message = message.replace(variable, userName)
					break;
				case "_defaultCheerleaderName_":
					let dcn = await getRandomValueFromArray(randomCheerleaderNames)
					message = message.replace(variable, dcn)
					break;
				case "_cheerleaderName_":
					message = message.replace(variable,await storageService.getItem("cheerleaderName"))
					break;
				default:
					break;
			}
		}
	}
	//console.log({message});
	return message
}

export const processCommand = async (command, variable_1 = null) =>{
	console.log("Processing command\n Command: "+command+" \n Variable: "+variable_1);
	switch(command) {
		case "storeUsername":
			let body = {
				userName: variable_1
			}
			await updateUser(body)
			break;
		case "storeCheerleaderName":
			await storageService.setItem("cheerleaderName", variable_1)
			break;
		case "pullFitBitData":
			console.log("Pulling FB Data");
			let data = await createGoalFromFitbit()
			let messageObject = [
				{				  
				  "command": null,
				  "imageUrl": "",
				  "contextID": null,
				  "messageText": "According to your Fitbit, you "
				}
			  ]
			break;
		default:
			sendSlackErrorNotification("Bad command parse from processCommand function", "Command attempted: "+command)
			break;
	}
}









