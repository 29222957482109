import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react";

const SeasonsWeeklyResultComponent = ({ success, displayText, icon }) => {
	return (
		<IonGrid>
			<IonRow>
				{success === true ? (
					<IonCol size="4" className="feed-time-column">
						<span className="feed-time">+1 season point</span>
					</IonCol>
				) : (
					""
				)}
				<IonCol size="2" className="feed-emoji-column">
					<span className="feed-emoji">{icon}</span>
				</IonCol>
				<IonCol>
					<IonGrid>
						<IonRow>
							<span className="feed-activity-text">{displayText}</span>
						</IonRow>
					</IonGrid>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};

export default SeasonsWeeklyResultComponent;
