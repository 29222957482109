import { API_URL } from "../models/constants";
import { logGenericEvent } from "./AnalyticsService";
import { getIDToken } from "./AuthService";

export const getFitbitHistory = async () => {
	// May need to implement caching at some point
	let url
	let idToken = await getIDToken().catch((error) => {
		throw Error("Error in getIDtoken: " + error);
	});
	url = API_URL + "fitbit/history";
	const response = await Promise.resolve(
		fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		}).catch((error) => {
			throw Error("Error in getting getFitbitHistory: " + error);
		})
	).catch((error) => {
		throw Error("Error in getting getFitbitHistory: " + error);
	});
	return response
		.json()
		.then((data) => {
			console.log("[getFitbitHistory] Response:", data);
			return data;
		})
		.catch((error) => {
			console.log("[getFitbitHistory] Error in JSON conversion: " + error);
			throw Error(error);
		});
}

export const createGoalFromFitbit = async () => {
	let totalExerciseTime;
	let totalCalories;
	let totalSteps;
	let fitbitHistory = await getFitbitHistory().catch((error) => {
		logGenericEvent("error connecting with Fitbit: " + error);
		return;
	});
	let percentOfCaloriesFromExercise = 0.15;
	console.log({ fitbitHistory });

	let anyData = true,
		avgWeeklyTime = null,
		avgWeeklyCalories = null,
		avgCaloriesPerMin = null,
		avgWeeklyPoints = null,
		avgWeeklySteps = null,
		weightedExerciseTime = null;
	if (!fitbitHistory) {
		anyData = false;
	} else {
		totalExerciseTime = fitbitHistory["activities-minutesFairlyActive"] + fitbitHistory["activities-minutesVeryActive"];
		weightedExerciseTime = fitbitHistory["activities-minutesFairlyActive"] * 1.5 + fitbitHistory["activities-minutesVeryActive"] * 2;
		totalCalories = fitbitHistory["activities-calories"];
		totalSteps = fitbitHistory["activities-steps"];
		console.log("Total time: " + totalExerciseTime);
		console.log("totalCalories: " + totalCalories);
		avgWeeklyTime = Math.round((totalExerciseTime / 30) * 7);
		avgWeeklyCalories = Math.round((totalCalories / 30) * 7 * percentOfCaloriesFromExercise);
		avgWeeklySteps = Math.round((totalSteps / 30) * 7);
		if (avgWeeklyTime === 0) {
			// just in case
			avgWeeklyTime = Math.round((avgWeeklySteps / 2000 / 3) * 60); // steps/2000 = # of miles / 3mph = hours stepping * 60 mins
		}
		avgCaloriesPerMin = Math.round(avgWeeklyCalories / avgWeeklyTime);
		if (isNaN(avgCaloriesPerMin)) {
			avgCaloriesPerMin = 0;
		}
		//avgCaloriesPerMin = 0;
		if (weightedExerciseTime !== null && weightedExerciseTime > 0) {
			avgWeeklyPoints =
				Math.round(((fitbitHistory["activities-minutesFairlyActive"] / 30) * 7 * 1.5) / 10) * 10 +
				Math.round(((fitbitHistory["activities-minutesVeryActive"] / 30) * 7 * 2) / 10) * 10;
		} else if (avgCaloriesPerMin > 11) {
			avgWeeklyPoints = Math.round((avgWeeklyTime * 2) / 10) * 10;
		} else if (avgCaloriesPerMin > 7) {
			avgWeeklyPoints = Math.round((avgWeeklyTime * 1.5) / 10) * 10;
		} else {
			avgWeeklyPoints = Math.round(avgWeeklyTime / 10) * 10;
		}
		if (avgWeeklyTime === 0 && avgWeeklyCalories === 0 && avgWeeklySteps === 0 && avgCaloriesPerMin === 0) {
			anyData = false;
		}
		logGenericEvent("goal created with Fitbit");
	}

	let data = {
		avgWeeklyTime: avgWeeklyTime,
		avgWeeklyCalories: avgWeeklyCalories,
		avgWeeklySteps: avgWeeklySteps,
		avgWeeklyPoints: avgWeeklyPoints,
		anyData: anyData,
	}
	return data
};