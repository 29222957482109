import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonImg, IonSpinner } from "@ionic/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";
import Emoji from "react-emoji-render";
import "../../css/ActivityDetails.css";
import "../../css/Home.css";
import { getGoal } from "../../services/PointsAndGoalsService";
import { getUser, getUserNameFromUserID } from "../../services/UserService";

const PersonalSummary = () => {
	const [activityDetails, setActivityDetails] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getLastGoalDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function getLastGoalDetails() {
		//TODO: Put content into DB for randomizing
		//TODO: Show table of activities, broken by day to help user understand what happened that week
		//TODO: Show week over week performance
		//TODO: Show achievements, highlight key performances etc.
		let user = JSON.parse(await getUser(null, false));
		let startDate = moment().startOf("isoWeek").subtract(1, "weeks");
		let endDate = moment().endOf("isoWeek").subtract(1, "weeks").add(1, "day");
		// Do this because of tz BS
		startDate = moment(startDate).subtract(1, "day");
		endDate = moment(endDate).add(1, "day");
		let goal = await getGoal(null, true, startDate, endDate, false);
		console.log("USER from PersonalSummary: ", { user });
		console.log("GOAL from PersonalSummary: ", { goal });
		if (goal === null) {
			goal = {
				goalPoints: "N/A",
				goalProgress: "N/A",
				success: false,
			};
		}
		let activity = {};
		activity.userName = await getUserNameFromUserID();
		activity.goalPoints = goal.goalPoints;
		activity.goalProgress = goal.goalProgress;
		//activity.goalPoints = activity.goalProgress + 1; //testing token message
		if (goal.success) {
			if (activity.goalProgress < activity.goalPoints) {
				//forgiveness token used
				activity.title = "🙏 Saved by Your Forgiveness Token 🙏";
				activity.body =
					"You came up a bit short, but your forgiveness token saved you.<br><br>You'll keep your streak intact and if you're in a season it will count as a season point.";
				activity.image = "https://static-assets.fitsquadapp.com/seasons/forgiveness-token.png";
				activity.result = "🟡";
			} else {
				activity.title = "🙌 Woohoo! You did it! 🙌";
				activity.body = "Excellent Job! You put out a great effort and accomplished what you set out to do. Well done!";
				activity.image = "https://fitsquad-ui-assets.s3-us-west-2.amazonaws.com/you_did_it.jpg";
				activity.result = ":green_circle:";
				activity.success = true;
			}
		} else {
			activity.title = "Ah bummer :disappointed:";
			activity.result = ":red_circle:";
			activity.body = "Solid effort but you came up short. Let's get back after it this week 💪";
			activity.image = "https://fitsquad-ui-assets.s3-us-west-2.amazonaws.com/oops.jpg";
			activity.success = false;
		}
		//activity.components = getSystemActivityComponents(activity);
		//console.log("Activity: " + JSON.stringify(activity));
		activity.percent = Math.round((goal.goalProgress / goal.goalPoints) * 100) + "%";
		activity.streak = user.goalStreak;
		//activity.streak = 7;
		if (activity.streak && activity.streak > 0) {
			let emojiStreak = "";
			for (let i = 1; i <= activity.streak; i++) {
				emojiStreak = emojiStreak + "🔥";
			}
			activity.emojiStreak = emojiStreak;
		}
		if (!activity.streak) {
			activity.streak = 0;
		}
		console.log({ activity });
		setActivityDetails([activity]);
		setLoading(false);
	}

	return (
		<IonCard>
			{loading === true ? (
				<IonSpinner />
			) : (
				<React.Fragment>
					{activityDetails.map((activity, i) => {
						let width = window.innerWidth;
						let height = window.innerHeight;
						return (
							<div key={`${i}`}>
								{activity.success === true ? <Confetti width={width} height={height} recycle={true} numberOfPieces={100} /> : ""}

								<IonImg src={activity.image} alt="" />
								<IonCardHeader>
									<IonCardSubtitle>
										<Emoji text={activity.title} />
									</IonCardSubtitle>
									<IonCardTitle>Personal Summary</IonCardTitle>
								</IonCardHeader>
								<IonCardContent className="font-1-5">
									<div style={{ fontSize: "20px", marginBottom: "20px" }} dangerouslySetInnerHTML={{ __html: activity.body }}></div>
									<div>Goal: {activity.goalPoints.toLocaleString()}</div>
									<div>Points: {activity.goalProgress.toLocaleString()}</div>
									<div>Percent: {activity.percent}</div>
									<div>
										Result: <Emoji text={activity.result} />
									</div>
									{activity.streak === 0 ? (
										""
									) : (
										<div>
											{activity.streak > 1 ? (
												<div>
													You extended your streak to {activity.streak} weeks. Way to go!
													<br />
													<span>{activity.emojiStreak}</span>
												</div>
											) : (
												<div>You started a new streak! 🔥</div>
											)}
										</div>
									)}

									<br />

									<br />
								</IonCardContent>
							</div>
						);
					})}
				</React.Fragment>
			)}
		</IonCard>
	);
};

export default PersonalSummary;
