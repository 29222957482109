import { IonBackButton, IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonPage, IonProgressBar, IonToolbar, useIonViewWillEnter } from "@ionic/react";
import React from "react";
import { useHistory } from "react-router";
import "../../css/Home.css";
import "../../css/Onboarding.css";
import { logPageView } from "../../services/AnalyticsService";
import { StorageService } from "../../services/StorageService";
const storageService = new StorageService();

const PointsExplanation = ({ progress, setProgress }) => {
	useIonViewWillEnter(() => {
		logPageView("Points Explanation");
		setProgress(0.5);
		storageService.setItem("lastOnboardingStep", 5);
	});

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar style={{ "--border-style": "none" }}>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
				</IonToolbar>
				<IonProgressBar value={progress} />
			</IonHeader>
			<IonContent>
				<div className="onboarding-main-div">
					<div className="onboarding-title">In FitSquad you earn points for being active</div>
					<div className="onboarding-hero-image-div">
						<img className="onboarding-hero-image" src="assets/images/points-diagram.jpg" alt="" />
					</div>

					<div className="onboarding-text-area" style={{ textAlign: "center" }}>
						<p>
							It's super simple:
							<br />
							<br />
							<span style={{ fontWeight: "bold" }}>1 minute of activity = 1 point.</span>
						</p>
						<p>The best part is any reasonable activity counts!</p>
						<p>So whether you hike or ski, walk or lift, do yardwork or do burpees it all counts towards your goal and your squads' goal.</p>
					</div>
				</div>
			</IonContent>
			<IonFooter className="onboarding-footer">
				<IonButton routerLink="/onboarding/goalexplanationsolo" className="onboarding-advance-button">
					Makes sense
				</IonButton>
			</IonFooter>
		</IonPage>
	);
};

export default PointsExplanation;
