import { IonLoading } from "@ionic/react";
import React, { useState } from "react";
import { useHistory } from "react-router";
import "../css/Standings.css";
import { loadingMessages } from "../models/constants";
import { getRandomValueFromArray } from "../services/HelperServices";

const StandingsIndividualComponent = ({
	individualStats,
	setIndividualStats,
	sortedColumn,
	setSortedColumn,
	loadingIndividualStats,
	setLoadingIndividualStats,
}) => {
	const [loadingMessage, setLoadingMessage] = useState(getRandomValueFromArray(loadingMessages));
	const history = useHistory();
	const sortSquadBy = async (columnName) => {
		let allUsers = individualStats;
		switch (columnName) {
			case "wins":
				allUsers.sort((a, b) => (a.stats.wins > b.stats.wins ? -1 : 1));
				setSortedColumn(2);
				break;
			case "winPercent":
				allUsers.sort((a, b) => (a.stats.winPercent > b.stats.winPercent ? -1 : 1));
				setSortedColumn(3);
				break;
			case "last10Wins":
				allUsers.sort((a, b) => (a.stats.last10Wins > b.stats.last10Wins ? -1 : 1));
				setSortedColumn(4);
				break;
			case "accuracyPercent":
				allUsers.sort((a, b) => (a.stats.accuracyPercent > b.stats.accuracyPercent ? -1 : 1));
				setSortedColumn(5);
				break;
			case "avgWeeklyGoal":
				allUsers.sort((a, b) => (a.stats.avgWeeklyGoal > b.stats.avgWeeklyGoal ? -1 : 1));
				setSortedColumn(6);
				break;
			case "avgWeeklyTotal":
				allUsers.sort((a, b) => (a.stats.avgWeeklyPoints > b.stats.avgWeeklyPoints ? -1 : 1));
				setSortedColumn(7);
				break;
			case "avgWeeklyDifference":
				allUsers.sort((a, b) => (a.stats.avgWeeklyDifference > b.stats.avgWeeklyDifference ? -1 : 1));
				setSortedColumn(8);
				break;
			default:
				break;
		}
		setIndividualStats(allUsers);
	};

	return (
		<React.Fragment>
			<IonLoading isOpen={loadingIndividualStats} onDidDismiss={() => setLoadingIndividualStats(false)} message={loadingMessage} backdropDismiss={true} />
			<div>
				<div style={{ display: "inline-flex" }}>
					<div className="header-column header-row header header-row-column vertical-center">Squaddie Name</div>
					<div className={`stat-column header-row header vertical-center ${sortedColumn === 2 ? "sorted" : ""}`} onClick={() => sortSquadBy("wins")}>
						<span className="sortable">W-L </span>
					</div>
					<div
						value="winPercent"
						className={`stat-column header-row header vertical-center ${sortedColumn === 3 ? "sorted" : ""}`}
						onClick={() => sortSquadBy("winPercent")}
					>
						<span className="sortable">Win % </span>
					</div>
					<div className={`stat-column header-row header vertical-center ${sortedColumn === 4 ? "sorted" : ""}`} onClick={() => sortSquadBy("last10Wins")}>
						<span className="sortable">Last 10 </span>
					</div>
					<div className={`stat-column header-row header vertical-center ${sortedColumn === 5 ? "sorted" : ""}`} onClick={() => sortSquadBy("accuracyPercent")}>
						<span className="sortable">Avg. Accuracy</span>
					</div>
					<div className={`stat-column header-row header vertical-center ${sortedColumn === 6 ? "sorted" : ""}`} onClick={() => sortSquadBy("avgWeeklyGoal")}>
						<span className="sortable">Avg. Goal</span>
					</div>
					<div className={`stat-column header-row header vertical-center ${sortedColumn === 7 ? "sorted" : ""}`} onClick={() => sortSquadBy("avgWeeklyTotal")}>
						<span className="sortable">Avg. Total</span>
					</div>
					<div
						className={`stat-column header-row header vertical-center ${sortedColumn === 8 ? "sorted" : ""}`}
						onClick={() => sortSquadBy("avgWeeklyDifference")}
					>
						<span className="sortable">
							Avg.
							<br /> Diff
						</span>
					</div>
				</div>

				{individualStats &&
					individualStats.map((user, index) => {
						let bkcolor;
						if (user.currentUser === true) {
							bkcolor = "rgb(248, 255, 187)";
						} else if (index % 2 === 0) {
							bkcolor = "white";
						} else {
							bkcolor = "rgb(248, 248, 248)";
						}
						return (
							<div style={{ display: "inline-flex", backgroundColor: `${bkcolor}` }} key={`${index}`}>
								<div className="header-column" style={{ backgroundColor: `${bkcolor}` }} onClick={() => history.push("/otherprofile/" + user.cognitoId)}>
									<img src={`${user.avatar}`} className="squad-standings-image" alt="" />
									{user.userName}
								</div>
								<div className={`stat-column ${sortedColumn === 2 ? "sorted" : ""}`}>
									{user.stats.wins}-{user.stats.losses}
								</div>
								<div className={`stat-column ${sortedColumn === 3 ? "sorted" : ""}`}>{Math.round(user.stats.winPercent * 100)}%</div>
								<div className={`stat-column ${sortedColumn === 4 ? "sorted" : ""}`}>
									{user.stats.last10Wins}-{user.stats.last10Losses}
								</div>
								<div className={`stat-column ${sortedColumn === 5 ? "sorted" : ""}`}>{user.stats.accuracyPercent}%</div>
								<div className={`stat-column ${sortedColumn === 6 ? "sorted" : ""}`}>{Math.round(user.stats.avgWeeklyGoal).toLocaleString()}</div>
								<div className={`stat-column ${sortedColumn === 7 ? "sorted" : ""}`}>{Math.round(user.stats.avgWeeklyPoints).toLocaleString()}</div>
								<div className={`stat-column ${sortedColumn === 8 ? "sorted" : ""}`}>{Math.round(user.stats.avgWeeklyDifference).toLocaleString()}</div>
								<div></div>
							</div>
						);
					})}
			</div>
		</React.Fragment>
	);
};

export default StandingsIndividualComponent;
