import { Plugins } from "@capacitor/core";

const { Storage } = Plugins;

export class StorageService {
	async setObjectAndPreference(key, value) {
		//console.log("[setObjectAndPreference] Key: "+key);
		await this.setObject(key, value);
	}

	async setItemAndPreference(key, value) {
		//console.log("[setItemAndPreference] Key: "+key);
		await this.setItem(key, value);
	}

	async setObject(key, value) {
		await Storage.set({
			key: key,
			value: JSON.stringify(value),
		});
	}

	async getMessages() {
		const msgs = await Storage.get({ key: "messages" });
		const msgsTemp = JSON.parse(msgs.value || "[]");

		if (typeof msgsTemp === "string") {
			let messagesObj = [];

			try {
				messagesObj = JSON.parse(JSON.parse(msgsTemp || ""));
			} catch (e) {
				console.error(e);
				messagesObj = JSON.parse(JSON.parse(msgs.value || ""));
			}

			return messagesObj;
		} else {
			const messagesObj = msgsTemp;
			return messagesObj;
		}
	}

	async getObject(key) {
		const ret = await Storage.get({ key: key });
		if (ret === null || ret === undefined || JSON.stringify(ret) === '{"value":null}') {
			return null;
		} else {
			return JSON.parse(ret.value || "");
		}
	}

	async getBotMessages() {
		const ret = await Storage.get({ key: "botMessages" });
		if (ret === null || ret === undefined || JSON.stringify(ret) === '{"value":null}') {
			return null;
		} else {
			let messages = JSON.parse(ret.value || "")
			if (messages.length > 20){		
				messages.slice(Math.max(messages.length - 20, 0))
			}	
			return messages;
		}
	}

	async getBotOnboardingMessages() {
		const ret = await Storage.get({ key: "botOnboardingMessages" });
		if (ret === null || ret === undefined || JSON.stringify(ret) === '{"value":null}') {
			return null;
		} else {
			let messages = JSON.parse(ret.value || "")
			if (messages.length > 20){		
				messages.slice(Math.max(messages.length - 20, 0))
			}	
			return messages;
		}
	}

	async setItem(key, value) {
		await Storage.set({
			key: key,
			value: value,
		});
	}

	async getItem(key) {
		const { value } = await Storage.get({ key: key });
		//console.log('Looking for item '+key+': ', value);
		return value || "";
	}

	async removeItem(key) {
		await Storage.remove({ key: key });
	}

	async keys() {
		const { keys } = await Storage.keys();
		return keys
		//console.log('Got keys: ', keys);
	}

	async clear() {
		await Storage.clear();
	}
}
