import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonLoading,
	IonPage,
	IonPopover,
	IonRefresher,
	IonRefresherContent,
	IonTitle,
	IonToolbar,
	useIonViewDidEnter,
} from "@ionic/react";
import { settings, syncOutline } from "ionicons/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import ExerciseDetailsComponent from "../components/ActivityFeedComponents/ExerciseDetailsComponent";
import SystemActivityDetailsComponent from "../components/ActivityFeedComponents/SystemActivityDetailsComponent";
import SeasonsProgressComponent from "../components/Seasons/SeasonsProgressComponent";
import SeasonsProgressGraphicComponent from "../components/Seasons/SeasonsProgressGraphicComponent";
import "../css/Home.css";
import "../css/Profile.css";
import "../css/Seasons.css";
import { sampleUserLedger, sampleUserSeason } from "../models/sampleSeason";
import { logGenericEvent, logPageView } from "../services/AnalyticsService";
import { getAllSeasons, getUserSeasons } from "../services/SeasonsService";
import { StorageService } from "../services/StorageService";
import { getUser } from "../services/UserService";
import SeasonsStatusComponent from "../components/Seasons/SeasonsStatusComponent";
import SeasonsInRewardsComponent from "../components/Seasons/SeasonsInRewardsComponent";
import SeasonsOutRewardsComponent from "../components/Seasons/SeasonsOutRewardsComponent";
import SeasonsWeeklyResults from "../components/Seasons/SeasonsWeeklyResults";
import { Link } from "react-router-dom";
import SeasonTerms from "../components/Seasons/SeasonTerms";

const SeasonDetails = () => {
	const history = useHistory();
	const seasonID = useParams().seasonID;
	// Loading states
	const [loading, setLoading] = useState();
	const [refresher, setRefresher] = useState();
	const [userObject, setUserObject] = useState();
	const [seasonObject, setSeasonObject] = useState();
	const [userLedger, setUserLedger] = useState();
	const [userSeasonObject, setUserSeasonObject] = useState();
	const [currentSeasonID, setCurrentSeasonID] = useState();
	const [showRulesPopover, setShowRulesPopover] = useState(false);
	const [activityDetails, showActivityDetails] = useState({
		visible: false,
		activityID: null,
		component: null,
	});
	const [systemActivityDetails, showSystemActivityDetails] = useState({
		visible: false,
		activityID: null,
		component: null,
	});

	const storageService = new StorageService();

	useIonViewDidEnter(async () => {
		logPageView("season-details");
		await doRefresh(null, false);
	});

	useEffect(() => {}, [userSeasonObject]);

	const getPassedInSeasonObject = async () => {
		console.log("SeasonID: " + seasonID);
		let seasonObj = await getAllSeasons(); // this is ALL Seasons
		return seasonObj[seasonObj.map((e) => e.id).indexOf(parseInt(seasonID))]; // Find the seasonObject associated with the passed in Season ID
	};

	const doRefresh = async (event, refresh = true) => {
		setUserObject(null);
		let userID = null;
		let currentSeasonObj = await getPassedInSeasonObject();
		//console.log({ currentSeasonObj });
		setSeasonObject(currentSeasonObj);
		let userObj = JSON.parse(await getUser(userID, refresh));
		setUserObject(userObj);
		if (userObj.seasonData[0]) {
			setUserSeasonObject(userObj.seasonData[0]);
		}
		if (event)
			setTimeout(() => {
				console.log("Async operation has ended");
				event.detail.complete();
			}, 2000);
	};

	const clearStorage = async () => {
		setLoading(true);
		storageService.removeItem("userGoal-null");
		storageService.removeItem("userGoal-" + userObject.cognitoId);
		storageService.removeItem("userPointHistory-" + userObject.cognitoId);
		setRefresher(Date.now());
		await doRefresh(null, true);
		setLoading(false);
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
					<IonTitle>Season Details</IonTitle>
					<IonButton color="secondary" slot="end" onClick={clearStorage}>
						<IonIcon slot="icon-only" icon={syncOutline}></IonIcon>
					</IonButton>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonPopover
					//event={showGoalConfirmation.event}
					isOpen={activityDetails.visible}
					cssClass="activity-full-screen-popover"
					onDidDismiss={(e) => {
						showActivityDetails({
							visible: false,
							activityID: null,
							component: null,
						});
					}}
				>
					<ExerciseDetailsComponent activityID={activityDetails.activityID} showActivityDetails={showActivityDetails} />
				</IonPopover>
				<IonPopover
					isOpen={showRulesPopover}
					cssClass="activity-full-screen-popover"
					onDidDismiss={(e) => {
						setShowRulesPopover(false);
					}}
				>
					<SeasonTerms />
				</IonPopover>
				<IonPopover
					//event={showGoalConfirmation.event}
					isOpen={systemActivityDetails.visible}
					cssClass="activity-full-screen-popover"
					onDidDismiss={(e) => {
						showSystemActivityDetails({
							visible: false,
							activityID: null,
							component: null,
						});
					}}
				>
					<SystemActivityDetailsComponent activityID={systemActivityDetails.activityID} showSystemActivityDetails={showSystemActivityDetails} />
				</IonPopover>
				<IonRefresher slot="fixed" onIonRefresh={doRefresh}>
					<IonRefresherContent refreshingText="Updating Seasons Data"></IonRefresherContent>
				</IonRefresher>
				<IonLoading isOpen={loading} onDidDismiss={() => setLoading(false)} message={"Loading"} />
				{seasonObject ? (
					<React.Fragment>
						<div>
							<img src={seasonObject.image} alt="" />
						</div>
						<SeasonsStatusComponent seasonObject={seasonObject} userSeasonObject={userSeasonObject} />

						{userSeasonObject ? (
							<React.Fragment>
								<SeasonsProgressComponent seasonObject={seasonObject} userObject={userObject} userSeasonObject={userSeasonObject} />
								<SeasonsProgressGraphicComponent userSeasonObject={userSeasonObject} />
								<SeasonsInRewardsComponent seasonObject={seasonObject} userObject={userObject} userSeasonObject={userSeasonObject} />
							</React.Fragment>
						) : (
							<SeasonsOutRewardsComponent seasonObject={seasonObject} userObject={userObject} userSeasonObject={userSeasonObject} />
						)}

						<div style={{ marginLeft: "16px", marginRight: "16px" }}>
							<div>
								<div className="section-title" style={{ paddingLeft: "0px" }}>
									How it Works
								</div>
								<div>
									You earn <b>1 season point</b> if you complete <b>your weekly goal.</b>
									<br />
									<br />
									You earn <b>1 season point</b> if your <b>squad completes their weekly squad goal</b>.
									<br />
									<br />
									If you earn <b>15 season points</b> before the end of the 10 weeks, you'll earn this season's rewards!
								</div>
								<div>
									<br />
									For more details please see{" "}
									<a className="link" onClick={() => setShowRulesPopover(true)}>
										the official rules
									</a>
								</div>
							</div>
						</div>
						{userSeasonObject ? <SeasonsWeeklyResults seasonObject={userSeasonObject} /> : ""}
					</React.Fragment>
				) : (
					""
				)}
			</IonContent>
		</IonPage>
	);
};

export default SeasonDetails;
