/* eslint-disable jsx-a11y/alt-text */
import {
	IonAvatar,
	IonCol,
	IonContent,
	IonImg,
	IonItem,
	IonLoading,
	IonPopover,
	IonRefresher,
	IonRefresherContent,
	IonRow,
	IonToast,
	useIonViewDidLeave,
} from "@ionic/react";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import moment from "moment";
import React, { Fragment, useContext, useRef, useState } from "react";
import Emoji from "react-emoji-render";
import { useHistory } from "react-router";
import AuthContext from "../../contexts/GlobalState";
import "../../css/FeedRenderer.css";
import "../../css/Home.css";
import { getActivityDetails } from "../../services/ActivitiesService";
import { logGiveLoveEvent } from "../../services/AnalyticsService";
import { addEmoji, getActivities } from "../../services/ChatService";
import { getSquadIDFromChatRoomID } from "../../services/SquadService";
import { StorageService } from "../../services/StorageService";
import { getAvatarFromUserID, getUserID } from "../../services/UserService";
/**
 *
 * Important: This component is only rerendered when the feed object is updated
 * The scroller
 *
 */

const ActivitiesRenderer = ({ feed, setFeed, chatRoomObj, setRefresher, refresher, sortOrder = "newestOnBottom", roomType = "squad" }) => {
	// const { refreshingChat } = useContext(AuthContext);
	const history = useHistory();
	// const [renderingMessages, setRenderingMessages] = useState(true);
	const [showToast, setShowToast] = useState(false);
	const [showLoading, setShowLoading] = useState(true);
	const [showReactionSelector, setShowReactionSelector] = useState({
		open: false,
		event: undefined,
		activityID: undefined,
		type: undefined,
		userID: undefined,
	});
	// const [showLongPressSelector, setShowLongPressSelector] = useState({
	// 	open: false,
	// 	event: undefined,
	// });
	console.log("Rendering FeedRenderer");
	let counterDate = "";
	let activityDate;
	let showSeperator = true;
	let setToScroll;
	const feedEndRef = useRef(null);
	const storageService = new StorageService();

	// var waitTime = 1000; // just adding 1 incase it's nil

	useIonViewDidLeave(() => {
		console.log("ionViewDidLeave event fired from FeedRendere");
		clearTimeout(setToScroll);
	});

	/**
	 *
	 * Adds an emoji to Activity
	 *
	 * Immediately closes popover
	 * Gets localFeed
	 * Looks for the activityID, checks it for # of reactions
	 * If there aren't any, sets count to 1, adds the reactions object
	 * If there are some, checks to see if what's being added already exists
	 *
	 * @param {*} activityID
	 * @param {*} reaction
	 */
	const addEmojiToActivity = async (activityID, reaction, type) => {
		const storageService = new StorageService();
		//console.log("Adding emoji "+reaction+" to ID "+activityID+" ["+moment().format()+"]");
		setShowReactionSelector({ open: false, event: undefined, activityID: undefined, type: undefined });
		let currentFeed = await storageService.getObject("feed-" + chatRoomObj.id); // get the current feed
		for (var x = 0; x < currentFeed.length; x++) {
			// iterate over local feed
			if (currentFeed[x].id === activityID) {
				// check the reactions object
				if (currentFeed[x].reactionCount > 0) {
					// if something from local storage has reactions we need to see if we're incrementing
					let reactionUpdated = false;
					for (var y = 0; y < currentFeed[x].reactions.length; y++) {
						// look for reaction in reactions
						console.log("Reaction " + y + " has " + currentFeed[x].reactions[y].emoji + " emoji");
						if (currentFeed[x].reactions[y].emoji === reaction) {
							// what is being added already exists
							// TODO: Come back to this and implement removing of previous reactions
							/* 
							let userID = await getUserID()
							let filterResult = currentFeed[x].reactionIDs.filter((obj) => obj.user === userID && obj.type === reaction); // look if THIS user has left THIS reaction before
							if (Object.keys(filterResult).length === 0) {
								currentFeed[x].reactions[y].count++ // if not, add it
							} else {
								console.log("User has left this reaction before. Removing it");
							}
							*/
							//console.log("Reactions before: "+JSON.stringify(currentFeed[x].reactions[y])+" ["+moment().format()+"]");
							currentFeed[x].reactions[y].count++;
							//console.log("Reactions after: "+JSON.stringify(currentFeed[x].reactions[y])+" ["+moment().format()+"]");
							reactionUpdated = true;
						}
					}
					if (reactionUpdated === false) {
						//console.log("Checked existing reactions. Doesn't exist so adding reaction "+reaction+" to activity ID "+activityID+" ["+moment().format()+"]");
						currentFeed[x].reactionCount++;
						let avatar = await getAvatarFromUserID();
						let userID = await getUserID(false);
						currentFeed[x].reactions.push({
							count: 1,
							emoji: reaction,
							user: userID,
							avatar: avatar,
						});
					}
				} else {
					// whats in local storage does not have a reaction yet
					//console.log("Adding reaction "+reaction+" to activity ID "+activityID+" ["+moment().format()+"]");
					currentFeed[x].reactionCount = 1;
					let avatar = await getAvatarFromUserID();
					let userID = await getUserID(false);
					currentFeed[x].reactions.push({
						count: 1,
						emoji: reaction,
						user: userID,
						avatar: avatar,
					});
				}
			}
		}
		await storageService.setItem("acceptTimerPull", "false"); // flag just in case
		// This isn't doing anything... why??
		//console.log("CurrentFeed: " + JSON.stringify(currentFeed));
		let sortedFeed;
		sortedFeed = currentFeed;
		sortedFeed.sort((a, b) => {
			if (a.unixdate) {
				//console.log("Sorting by unixdate");
				return a.unixdate > b.unixdate ? 1 : -1;
			} else {
				//console.log("Sorting by date");
				return a.date > b.date ? 1 : -1;
			}
		});
		setFeed(sortedFeed.reverse()); // update the state
		//console.log("Feed updated [" + moment().format() + "]");
		await storageService.setObject("feed-" + chatRoomObj.id, currentFeed); // store updated feed locally
		try {
			let activityDetails = await getActivityDetails(activityID);
			logGiveLoveEvent("chat-room", activityDetails.activityType, reaction, activityDetails.userId);
			let squadID = await getSquadIDFromChatRoomID(chatRoomObj.id);
			addEmoji(activityID, reaction, type, squadID); // send it to the server
		} catch (e) {
			console.log("Error in adding emoji: " + e);
		}
		await storageService.setItem("acceptTimerPull", "true");
		console.log("acceptTimerPull-activities set to true");
		console.log("Emoji Added " + activityID + " [" + moment().format() + "]");
	};

	const goToMemberPage = (id) => {
		history.push("/otherprofile/" + id);
	};

	const showEmojiPicked = (event, emojiObject) => {
		addEmojiToActivity(showReactionSelector.activityID, emojiObject.colons, showReactionSelector.type);
		setShowReactionSelector({ open: false, event: undefined, activityID: undefined, type: undefined });
	};

	const addExistingEmoji = (emoji, activityID, type) => {
		addEmojiToActivity(activityID, emoji, type);
	};

	const confirmAddReaction = async (isopen, e, activityID, type, userID) => {
		let currentUserID = await getUserID();
		if (userID !== currentUserID) {
			console.log("showing reaction selector");
			setShowReactionSelector(isopen, e, activityID, type, userID);
		} else {
			setShowToast(true);
		}
	};

	// const showPhotoZoomed = async (imageURL) => {
	// 	history.push("/image/" + encodeURIComponent(imageURL));
	// };

	const refresh = async (event) => {
		await storageService.removeItem("lastSquadActivityPull-" + chatRoomObj.squadIdA);
		await storageService.removeItem("squad-activities-" + chatRoomObj.squadIdA);
		await storageService.removeItem("feed-" + chatRoomObj.id);
		setFeed(null);
		await getActivities(chatRoomObj, 1000, null, null, null).then((result) => {
			if (event) {
				event.detail.complete();
			}
			console.log({ result });
			setFeed(result.reverse());
			setRefresher(Date.now());
		});

		// let latestActivities = await storageService.getObject("feed-" + chatRoomObj.id);
	};

	if (feed !== null && feed !== undefined) {
		return (
			<IonContent className="chat-scroll">
				<IonRefresher slot="fixed" onIonRefresh={refresh}>
					<IonRefresherContent refreshingText="Updating Squad Data"></IonRefresherContent>
				</IonRefresher>
				<IonPopover
					event={showReactionSelector.event}
					isOpen={showReactionSelector.open}
					cssClass="reaction-selector"
					onDidDismiss={(e) => {
						setShowReactionSelector({ open: false, event: undefined });
						//setScroller(false);
					}}
				>
					<Picker onSelect={(emoji) => showEmojiPicked(emoji, emoji)} />
				</IonPopover>
				<IonToast
					isOpen={showToast}
					onDidDismiss={() => setShowToast(false)}
					message="You can't add reactions to your own activities :)"
					duration={3000}
					color="tertiary"
				/>
				<div>
					<div>
						<IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={"Loading activity feed..."} backdropDismiss={true} />

						{feed.map((feedItem, index) => {
							if (feedItem.cssClass === "squadGoalEvaluated-css") {
								return "";
							}
							if (feedItem.type === "text" || feedItem.type === "message" || feedItem.type === "image") {
								return "";
							}
							activityDate = moment(feedItem.date).format("ddd, MMMM DD");
							if (counterDate !== activityDate) {
								showSeperator = true;
								counterDate = activityDate;
							} else {
								showSeperator = false;
							}

							// Non-chat message
							// If it's a system message in a competition room, skip it
							if (feedItem.cssClass && feedItem.cssClass !== "feed-activity" && roomType === "competition") {
								return "";
							} else {
								// let randomImage = Math.floor(Math.random() * 100); // picks a number bewteen 1-100
								let randomImage = 100; // just show em all for now
								return (
									<React.Fragment key={`${index}`}>
										{showSeperator === true ? (
											<div className="date-separator">
												<div className="date-separator-text">{activityDate}</div>
											</div>
										) : (
											""
										)}
										{feedItem.cssClass ? (
											<IonRow className={feedItem.cssClass} style={{ marginTop: "16px" }} routerLink={`${feedItem.link}`} key={`${index}`}>
												<IonCol size="1">
													<IonAvatar onClick={() => goToMemberPage(feedItem.userID)} style={{ height: "24px", width: "24px" }}>
														{feedItem.avatar && feedItem.avatar !== "" && feedItem.avatar !== null ? (
															<img className="user-avatar" src={feedItem.avatar} style={{ height: "24px", width: "24px", objectFit: "cover" }} />
														) : (
															<img
																className="user-avatar"
																src="https://fitsquad-ui-assets.s3-us-west-2.amazonaws.com/empty-avatar.png"
																style={{ height: "25px", width: "25px", objectFit: "cover" }}
															/>
														)}
													</IonAvatar>
												</IonCol>
												{feedItem.pointsText ? (
													<React.Fragment>
														<IonCol
															size="8"
															onClick={(e) =>
																confirmAddReaction({ open: true, event: e.nativeEvent, activityID: feedItem.id, type: "activity", userID: feedItem.userID })
															}
														>
															{feedItem.cssClass !== "dailySteps-css" ? ( // don't show a time for daily steps
																<React.Fragment>
																	<span className="date" style={{ paddingLeft: "0px" }}>
																		{moment(feedItem.date).format("h:mm a")}
																	</span>
																	<br />
																</React.Fragment>
															) : (
																""
															)}
															<Emoji text={feedItem.message} className="activity-text" />
														</IonCol>
														<IonCol size="3" style={{ textAlign: "right" }}>
															<div className="points-text">{feedItem.pointsText} points</div>
														</IonCol>
													</React.Fragment>
												) : (
													<IonCol
														size="11"
														onClick={(e) =>
															confirmAddReaction({ open: true, event: e.nativeEvent, activityID: feedItem.id, type: "activity", userID: feedItem.userID })
														}
													>
														<span className="date" style={{ paddingLeft: "0px" }}>
															{moment(feedItem.date).format("h:mm a")}
														</span>
														<br />
														<Emoji text={feedItem.message} />
													</IonCol>
												)}
												{feedItem.imageURL && feedItem.imageURL.includes(".gif") && randomImage >= 80 ? ( // if there's a gif, show it 20% of the time
													<IonRow style={{ width: "100%" }}>
														<IonImg src={feedItem.imageURL} alt="" style={{ objectFit: "cover", width: "100%" }} />
													</IonRow>
												) : (
													""
												)}
												{feedItem.imageURL && (feedItem.imageURL.includes(".jpg") || feedItem.imageURL.includes(".png")) ? ( // if there's an image, show it all of the time
													<IonRow style={{ width: "100%" }}>
														<IonImg src={feedItem.imageURL} alt="" style={{ objectFit: "cover", width: "100%" }} />
													</IonRow>
												) : (
													""
												)}
											</IonRow>
										) : (
											// If it doesn't have a class for some reason...
											<IonItem className="feed-activity" routerLink={`${feedItem.link}`} key={`${index}`}>
												<IonRow>
													<IonCol size="2">
														<IonAvatar className="feed-activity-avatar">
															<img className="user-avatar" src={feedItem.avatar} />
														</IonAvatar>
													</IonCol>
													<IonCol size="7">
														<Emoji text={feedItem.message} className="activity-text" />
													</IonCol>
													<IonCol size="3">
														<span className="date">{moment(feedItem.date).format("h:mm a")}</span>
													</IonCol>
												</IonRow>
											</IonItem>
										)}

										{/* {feedItem.reactionCount > 0 ? ( */}
										<React.Fragment>
											<div className="reaction-container">
												{feedItem.reactions.map((reactions, index) => {
													//TODO: Create a better system for handling emoji exceptions
													if (reactions.emoji === ":smiling_face_with_3_hearts:") {
														reactions.emoji = ":smiling_face_with_hearts:";
													}
													return (
														<IonAvatar className="reaction-avatar" key={`${index}`} onClick={(e) => addExistingEmoji(reactions.emoji, feedItem.id, "activity")}>
															<img className="user-avatar" src={reactions.avatar} style={{ height: "24px", width: "24px", objectFit: "cover" }} />
															<Emoji text={reactions.emoji} className="reaction-emoji-container" />
														</IonAvatar>
													);
												})}
												<div
													onClick={(e) =>
														confirmAddReaction({ open: true, event: e.nativeEvent, activityID: feedItem.id, type: "activity", userID: feedItem.userID })
													}
													style={{
														position: "relative",
														top: "5px",
														width: "30px",
														border: "1px solid lightgray",
														textAlign: "center",
														borderRadius: "20px",
													}}
												>
													<img src="/assets/images/add-reaction.png" style={{ height: "15px" }} />
												</div>
											</div>
										</React.Fragment>
										{/* ) : (
											<React.Fragment>
												<div
													onClick={(e) =>
														confirmAddReaction({ open: true, event: e.nativeEvent, activityID: feedItem.id, type: "activity", userID: feedItem.userID })
													}
													style={{ width: "30px", border: "1px solid lightgray", textAlign: "center", borderRadius: "20px", marginLeft: "10px" }}
												>
													<img src="/assets/images/add-reaction.png" style={{ height: "15px" }} />
												</div>
											</React.Fragment>
										)} */}
										{showLoading === true ? <React.Fragment>{setShowLoading(false)}</React.Fragment> : ""}
									</React.Fragment>
								);
							}
						})}
						<div ref={feedEndRef} />
					</div>
				</div>
			</IonContent>
		);
	} else {
		console.log("Feed is undefined");
		return (
			<IonContent className="chat-scroll">
				<div>
					<h2>Loading activity feed...</h2>
					<div ref={feedEndRef} />
				</div>
			</IonContent>
		);
	}
};

export default ActivitiesRenderer;
