import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, useIonViewWillEnter, IonLoading, IonButton } from "@ionic/react";
import React, { useState } from "react";
import { useParams } from "react-router";
import moment from "moment";
import "../css/Home.css";
import "../css/SquadScoreboard.css";
import { getSquadMembers } from "../services/SquadService";
import { StorageService } from "../services/StorageService";
import { getGoal } from "../services/PointsAndGoalsService";
import SquadProgressComponent from "../components/HomePageCards/SquadProgressComponent";
import { useHistory } from "react-router";
import { getChatRooms } from "../services/ChatService";
import SquadScoreboardComponent from "../components/ChatRoom/SquadScoreboardComponent";
import { logPageView } from "../services/AnalyticsService";
const storageService = new StorageService();

const SquadScoreboard = () => {
	const history = useHistory();
	const roomID = useParams().chatRoomID;
	const [calculating, setCalculating] = useState(true);
	const [squadID, setSquadID] = useState();
	const [squadMembers, setSquadMembers] = useState();
	const hoursInAWeek = 168;

	//let remainingSquadPoints;

	useIonViewWillEnter(async () => {
		logPageView("squad-scoreboard");
		setCalculating(true);
		await loadMemberData();
		setCalculating(false);
	});

	const loadMemberData = async () => {
		let members = [];
		let squadID;
		let chatRoomObject = await storageService.getObject(roomID);

		if (chatRoomObject !== "" && chatRoomObject !== null) {
			squadID = chatRoomObject.squadIdA;
		} else {
			squadID = roomID;
		}
		setSquadID(squadID);
		let squadArray = await getSquadMembers(null, squadID);
		for (let element of squadArray) {
			element.goal = await getGoal(element.userID, false);
			if (element.goal !== null) {
				let timeRemaining = moment.duration(moment(element.goal.endDate).diff(moment(Date.now())));
				let hoursRemaining = Math.floor(timeRemaining.asHours());
				let hoursElapsed = hoursInAWeek - hoursRemaining;
				element.goal.projectedPoints = Math.round((element.goal.goalProgress / hoursElapsed) * hoursInAWeek);
				let projectedPercent = Math.round((element.goal.projectedPoints / element.goal.goalPoints) * 100);
				element.goal.goalPercent = Math.round((element.goal.goalProgress / element.goal.goalPoints) * 100);
				element.projectedPercent = projectedPercent;
				if (element.goal.goalProgress >= element.goal.goalPoints) {
					element.predictionText = "GOAL COMPLETED";
					element.predictionIcon = "trophySharp";
					element.iconColor = "purple";
				} else if (projectedPercent >= 120) {
					element.predictionText = "GOING TO CRUSH IT";
					//flavorText = ":muscle: You're doing fantastic! Your a model of consitency and your squad should be greatful they have you. Now get them on your level.";
					element.predictionIcon = "hammerSharp";
					element.iconColor = "orange";
				} else if (projectedPercent >= 100) {
					element.predictionText = "ON TRACK";
					//flavorText = ":ok_hand: So far, so good. Keep this up and this goal is as good as completed";
					element.predictionIcon = "checkmarkCircleSharp";
					element.iconColor = "green";
				} else if (projectedPercent >= 90) {
					element.predictionText = "CUTTING IT CLOSE";
					//flavorText =
					//":worried: You're doing ok, but as of right now, you won't be successful. What can you do to pick up the pace? Or can one of your squadmmates help you out this week?";
					element.predictionIcon = "warningSharp";
					element.iconColor = "yellow";
				} else if (projectedPercent < 90) {
					element.predictionText = "OFF TRACK";
					//flavorText =
					//":anguished: Rut roh. You're not looking so hot this week. Think its possible to pick it up? If not, do your part to encourage your squad to make sure they pick it up for you!";
					element.predictionIcon = "closeCircleSharp";
					element.iconColor = "red";
				}
				/*
				let chartColor = "green";
				if (projectedPercent >= 100) {
					chartColor = "green";
				} else if (projectedPercent >= 90) {
					chartColor = "yellow";
				} else if (projectedPercent < 90) {
					chartColor = "red";
				}
				element.chartData = {
					labels: ["Red", "Blue"],
					datasets: [
						{
							data: [element.goal.goalProgress],
							barPercentage: 1,
							categoryPercentage: 1,
							minBarLength: 0,
							backgroundColor: [chartColor],
							key: element.userID + "color",
						},
						{
							data: [element.goal.goalPoints],
							barPercentage: 1,
							categoryPercentage: 1,
							minBarLength: 0,
							backgroundColor: ["lightgray"],
							key: element.userID + "gray",
						},
					],
					options: [],
				};
				*/
				members.push(element);
			} else {
				console.error(`User ${element.userID} doesn't have a goal`);
			}
		}
		members.sort((a, b) => (a.projectedPercent > b.projectedPercent ? -1 : 1));
		setSquadMembers(members);
	};

	const navToChatroom = async () => {
		let chatRooms = await getChatRooms(false).catch((error) => {
			alert("Error: " + error);
		});
		console.log({ chatRooms });
		let chatRoomDestination;
		for (let chatRoom of chatRooms) {
			if (chatRoom.squadIdA === parseInt(squadID)) {
				chatRoomDestination = chatRoom.id;
			}
		}
		history.push("/chatroom/" + chatRoomDestination);
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton defaultHref="/home" text="" color="light" />
					</IonButtons>
					<IonTitle>Squad Scoreboard</IonTitle>
				</IonToolbar>
				{squadID ? <SquadProgressComponent squadId={squadID} dow={moment().format("ddd")} showFlavorText={false} /> : ""}
				<IonToolbar style={{ textAlign: "center" }}>
					<IonButton expand="block" onClick={() => navToChatroom()}>
						Motivate your squad!
					</IonButton>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonLoading isOpen={calculating} onDidDismiss={() => setCalculating(false)} message={"Computing totals"} />
				{squadID ? <SquadScoreboardComponent squadID={squadID} /> : ""}
			</IonContent>
		</IonPage>
	);
};

export default SquadScoreboard;
