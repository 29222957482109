import { getIDToken } from "./AuthService";
import { API_URL } from "../models/constants";
import moment from "moment";
import { StorageService } from "../services/StorageService";
import { getWeight, loadSumCalorieData, loadSumStepsData } from "./HealthKitService";
import {convertStepsToCals,convertCalsToCredits} from "./HelperServices"
import { getUser, getUserID } from "./UserService";
import { shouldRefresh } from "./RefreshService";
const storageService = new StorageService();

export const getCompetition = async (squadID, startDateMoment = null, endDateMoment = null, refresh = false, storeCompetition = true) => {
    let mostRecentGoalObject = [];
	let url
	let idToken = await getIDToken().catch((error) => {
		throw Error("Error in getIDtoken: " + error);
	});
	if (startDateMoment === null){
		startDateMoment = moment().startOf("isoWeek")		
	}
	if (endDateMoment === null){
		endDateMoment = moment().endOf("isoWeek").add(1, "day");		
	}	
    let competitionObject = await storageService.getObject("squadCompetitions-"+squadID);
	if (competitionObject && competitionObject !== "" && competitionObject[moment(startDateMoment).format("MM/DD/YY")] && competitionObject[moment(startDateMoment).format("MM/DD/YY")].lastUpdated) {
		let update = await shouldRefresh(competitionObject[moment(startDateMoment).format("MM/DD/YY")])		
		if (update === false && refresh === false){
			//console.log(`Loading competitionObject for squad ${squadID} from local storage`);			
			return competitionObject[moment(startDateMoment).format("MM/DD/YY")];
		} else {
			//console.log(`Hitting competition API because squadID=${squadID}, refresh=${refresh} & update=${update}`);
		}
	} else {
		//console.log(`No competition object for squad ${squadID} for date ${moment(startDateMoment).format("MM/DD/YY")} `);		
	}
	url = API_URL + "competitions/?squadId="+squadID+"&startDate="+startDateMoment.format()+"&endDate="+endDateMoment.format()

	//console.log("Getting competition object: "+url);
	const response = await Promise.resolve(
		fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		}).catch((error) => {
			throw Error("Error in getting competition: " + error);
		})
	).catch((error) => {
		throw Error("Error in getting competition: " + error);
	});
	const responseLength = (await response.clone().text()).length;
	//console.log("Response Length:" + responseLength);
	if (responseLength > 2) {
		let serverResponse = await response.json().catch((error) => {
			throw Error("Error in parsing API response from getCompetition: " + error);
		});
		//console.log("[JS] getCompetition Success:", serverResponse);
		serverResponse[0].lastUpdated = moment()
		if (storeCompetition === true){
            if (competitionObject === null){competitionObject = {}}

            competitionObject[moment(startDateMoment).format("MM/DD/YY")] = serverResponse[0]
			storageService.setObject("squadCompetitions-"+squadID, competitionObject);
		}			
		return serverResponse[0];
	} else {
		//console.log("No competition object returned from server");
		competitionObject = null;
	}
	return competitionObject;
}

export const getCompetitionByID = async (competitionID) => {
	let url
	let idToken = await getIDToken().catch((error) => {
		throw Error("Error in getIDtoken: " + error);
	});
	url = API_URL + "competitions/"+competitionID

	//console.log("Getting competition object: "+url);
	const response = await Promise.resolve(
		fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		}).catch((error) => {
			throw Error("Error in getting competition: " + error);
		})
	).catch((error) => {
		throw Error("Error in getting competition: " + error);
	});
	const responseLength = (await response.clone().text()).length;
	//console.log("Response Length:" + responseLength);
	if (responseLength > 2) {
		let serverResponse = await response.json().catch((error) => {
			throw Error("Error in parsing API response from getCompetition: " + error);
		});
		//console.log("[JS] getCompetition Success:", serverResponse);	
		return serverResponse;
	} else {
		//console.log("No competition object returned from server");
		return null;
	}
}

export const getLastWeekCompetition = async () => {
	let lwCompObject = []
	let userObj = JSON.parse(await getUser(null, true));
	let lastWeekStartDate = moment().startOf("isoWeek").subtract(1, "weeks");
	let lastWeekEndDate = moment().endOf("isoWeek").subtract(1, "weeks").add(1, "day");
	userObj.squads.forEach(async (squad) => {
		let lwc = await getCompetition(squad.squadId, lastWeekStartDate, lastWeekEndDate, false, true);
		if (lwc !== null) {
			lwCompObject.push(lwc)
		}
	});
	return lwCompObject
}