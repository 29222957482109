import React, { useContext, useEffect, useState } from "react";
import { IonAvatar, IonButton, IonPopover, IonSkeletonText, IonSpinner } from "@ionic/react";
import { Plugins, CameraResultType, CameraSource } from "@capacitor/core";
import "../../css/Profile.css";
import "../../css/Home.css";
import { getUser, getUserID, updateUser } from "../../services/UserService";
import { golf } from "ionicons/icons";
import { logGenericClickEvent } from "../../services/AnalyticsService";
import { isGoalCompleted } from "../../services/PointsAndGoalsService";
import AuthContext from "../../contexts/GlobalState";

const ProfileMainAvatarComponent = ({ userObj, goalObj }) => {
	const { Camera } = Plugins;
	const { userState, globalGoal, updateUserState } = useContext(AuthContext);
	const [userObject, setUserObject] = useState(userState.user);
	const [goalObject, setGoalObject] = useState(globalGoal);
	const [takenPhoto, setTakenPhoto] = useState(null);
	const [showConfirmation, setShowConfirmation] = useState(null);

	useEffect(() => {
		if (userObj) {
			setUserObject(userObj);
			setGoalObject(goalObj);
		} else {
			setUserObject(userState.user);
			setGoalObject(globalGoal);
		}
	});

	const takePhotoHandler = async () => {
		let userID = await getUserID(false);
		if (userID === userObject.cognitoId) {
			const photo = await Camera.getPhoto({
				allowEditing: false,
				correctOrientation: true,
				resultType: CameraResultType.DataUrl,
				source: CameraSource.Prompt,
				quality: 80,
				width: 500,
			});
			setTakenPhoto({
				path: photo.dataUrl,
				preview: photo.dataUrl,
			});

			let body = {
				avatar: photo.dataUrl,
			};
			let updateUserResponse = await updateUser(body);
			console.log({ updateUserResponse });
			let user = JSON.parse(await getUser(null, true));
			updateUserState(user);
			setUserObject(user);
			setShowConfirmation(true);
		} else {
			console.log("Logged in user is not currently viewed user");
		}
	};

	const navToSalesDetails = () => {
		logGenericClickEvent("profile", "nav-to-web-sales");
		window.open("http://fitsquadapp.com/products", "_new");
	};

	return (
		<React.Fragment>
			<IonPopover
				isOpen={showConfirmation}
				className="love-thanks"
				onDidDismiss={(e) => {
					setShowConfirmation(false);
				}}
			>
				<div style={{ fontSize: "1.25em", paddingTop: "33px", textAlign: "center" }}>
					Profile picture updated
					<br />
					<br />
					It may take some time to appear updated throughout the app
				</div>
			</IonPopover>
			<div className="profile-main-avatar">
				<div className="avatar-div">
					{takenPhoto ? <img src={takenPhoto.preview} className="squad-profile-avatar-img" alt="" onClick={takePhotoHandler} /> : ""}
					{userObject && userObject.avatar && !takenPhoto ? (
						<React.Fragment>
							<IonAvatar className="squad-profile-avatar">
								<img src={userObject.avatar} className="squad-profile-avatar-img" alt="" onClick={takePhotoHandler} />
							</IonAvatar>
						</React.Fragment>
					) : (
						""
					)}
					{userObject && userObject.avatar && userObject.avatar === null && !takenPhoto ? (
						<React.Fragment>
							<IonAvatar className="squad-profile-avatar">
								<img
									className="squad-profile-avatar-img"
									src="https://fitsquad-ui-assets.s3-us-west-2.amazonaws.com/empty-avatar.png"
									onClick={takePhotoHandler}
									alt=""
								/>
							</IonAvatar>
						</React.Fragment>
					) : (
						""
					)}
					<div className="profile-username-stats">
						<div>
							<div className="username">
								{userObject && userObject?.userName ? <span>{userObject.userName}</span> : <IonSpinner className="top-area-data-points-spinner" />}
							</div>
							<div className="top-area-data-points">
								Goal:{" "}
								{goalObject && goalObject.goalPoints ? (
									<span className="top-area-data-points-bold">{goalObject.goalPoints.toLocaleString()}</span>
								) : (
									<IonSpinner className="top-area-data-points-spinner" />
								)}
							</div>
							<div className="top-area-data-points">
								Current:{" "}
								<span>
									{goalObject && goalObject.goalProgress >= 0 ? (
										<React.Fragment>
											<span className="top-area-data-points-bold">{goalObject.goalProgress.toLocaleString()}</span>
											{goalObject.bonusPoints > 0 ? <span className="squad-info-bonus"> (+{goalObject.bonusPoints.toLocaleString()})</span> : ""}
										</React.Fragment>
									) : (
										<IonSpinner className="top-area-data-points-spinner" />
									)}
								</span>
							</div>
							<div className="top-area-data-points">
								%:{" "}
								<span>
									{goalObject !== undefined && goalObject !== null && goalObject.goalPercent >= 0 ? (
										<span className="top-area-data-points-bold">{goalObject.goalPercent.toLocaleString()}%</span>
									) : (
										<IonSpinner className="top-area-data-points-spinner" />
									)}
								</span>
							</div>
							<div className="top-area-data-points">
								Prediction:{" "}
								{goalObject && goalObject.prediction === "upgrade" ? (
									<div style={{ display: "flex" }} onClick={navToSalesDetails}>
										<div className="top-area-data-points-bold upgrade-text-button" style={{ backgroundColor: "green" }}>
											{goalObject.prediction.toLocaleString()}
										</div>
									</div>
								) : (
									""
								)}
								{goalObject && goalObject.prediction !== "upgrade" ? (
									<div style={{ display: "flex" }}>
										<div className="top-area-data-points-bold">{goalObject.prediction.toLocaleString()}</div>
										{isGoalCompleted(goalObject) === true ? (
											<img
												src="assets/images/trophy_transparent.gif"
												alt=""
												style={{ height: "13px", position: "relative", left: "-8px", top: "-5px", paddingLeft: "10px" }}
											/>
										) : (
											<span style={{ left: "2px", backgroundColor: `${goalObject.iconColor}`, top: "0px" }} className="dot"></span>
										)}
									</div>
								) : (
									""
								)}
								{!goalObject ? <IonSpinner className="top-area-data-points-spinner" /> : ""}
							</div>
							<div className="top-area-data-points">
								Streak:{" "}
								<span>
									{userObject && userObject?.goalStreak !== null && userObject.goalStreak > 0 ? (
										<span className="top-area-data-points-bold">{userObject.emojiStreak}</span>
									) : (
										<span style={{ textTransform: "lowercase" }}>Let's get one started!</span>
									)}
								</span>
							</div>
						</div>
					</div>
				</div>
				{/** 
				<div>
					<div>
						Level: {userObject && userObject.level !== null ? <span>{userObject.level}</span> : "1"}
						<br />
						HealthPoints: {userObject && userObject.healthPoints !== null ? <span>{userObject.healthPoints.toLocaleString()}</span> : "0"}
						<br />
						Current streak: {userObject && userObject.goalStreak !== null ? <span>{userObject.goalStreak} weeks</span> : "No streak - let's get one started!"}
					</div>
				</div>
                */}
			</div>
		</React.Fragment>
	);
};

export default ProfileMainAvatarComponent;
