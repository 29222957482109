/* Methods for interacting with randomly generated messages */

import { API_URL } from "../models/constants";
import { getIDToken } from "./AuthService";

export const getActivitySystemMessage = async (intensity, activityName, points, language = "en", personality = "default") => {
	const url = API_URL;
	var idToken;
	idToken = await getIDToken().catch((error) => {
		throw Error("[getActivitySystemMessage] Error in getIDtoken: " + error);
    });
    let intensityProperCase = intensity.charAt(0).toUpperCase() + intensity.slice(1)

    const response = await Promise.resolve(
		fetch(url + "system-message?messageType=activity&messageSubType=userDidActivity"+intensityProperCase+"&language="+language+"&personality="+personality+"&activityType="+activityName+"&points="+points, {
			method: "get",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[JS][getActivitySystemMessage] Error in API call: " + error);
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {
			console.log("[JS] getActivitySystemMessage Success:", data);
			return data;
		})
		.catch((error) => {
			console.log("[JS] Error in JSON conversion: " + error);
			throw Error(error);
		});
};

export const getGoalStatusSystemMessage = async (type, status,language = "en", personality = "default") => {
	const url = API_URL;
	var idToken;
	idToken = await getIDToken().catch((error) => {
		throw Error("[getGoalStatusSystemMessage] Error in getIDtoken: " + error);
    });

    const response = await Promise.resolve(
		fetch(url + "system-message?messageType="+type+"&messageSubType="+status+"&language="+language+"&personality="+personality, {
			method: "get",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
		})
	).catch((error) => {
		console.log("[JS][getGoalStatusSystemMessage] Error in API call: " + error);
		throw Error(error);
	});

	return response
		.json()
		.then((data) => {
			return data;
		})
		.catch((error) => {
			console.log("[JS] Error in JSON conversion: " + error);
			throw Error(error);
		});
};