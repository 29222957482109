import { IonButton, IonCol, IonGrid, IonRow, IonSpinner, useIonViewWillEnter } from "@ionic/react";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import Emoji from "react-emoji-render";
import { useHistory } from "react-router";
import "../../css/SquadScoreboard.css";
import { logGenericClickEvent, logNavigationEvent } from "../../services/AnalyticsService";
import { getGoalStatusSystemMessage } from "../../services/MessageServices";
import { getSquadPrediction } from "../../services/PointsAndGoalsService";
import { getCurrentSeasons, hasSeasonStarted, isUserinSeason } from "../../services/SeasonsService";
import { getChatRoomIDFromSquadID, getSquad, getSquadGoal, isSquadIDinUserObject, isUserInSquad } from "../../services/SquadService";
import { StorageService } from "../../services/StorageService";
import { getUser } from "../../services/UserService";
import AuthContext from "../../contexts/GlobalState";
const storageService = new StorageService();

const SquadProgressComponent = ({ chatRoomObject, dow, squadId, showFlavorText, refresh = true, refreshInProgress }) => {
	const history = useHistory();
	const { userState, squadInfo, userSquads } = useContext(AuthContext);
	const [doughnutChartData, setDoughnutChartData] = useState({ datasets: [], options: [] });
	const [squadGoalObject, setSquadGoalObject] = useState();
	const [squadPercent, setSquadPercent] = useState();
	const [squadObject, setSquadObject] = useState();
	const [startedThisWeek, setStartedThisWeek] = useState(false);
	const [squadPrediction, setSquadPrediction] = useState({
		points: null,
		percent: null,
		text: null,
		icon: null,
		iconColor: null,
		textColor: null,
		flavorText: null,
	});
	const [loadingData, setLoadingData] = useState(false);

	/*
	useEffect(() => {
		//console.log(`refreshInProgress: ${refreshInProgress}`);
		if (refreshInProgress === false) {
			loadSquadData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refreshInProgress]);
	*/
	useIonViewWillEnter(async () => {
		// This gets the locally stored info first and then fetches server info, updating it after
		await refreshData();
	});

	useEffect(() => {
		console.log("Squad progress component rerendering");
		refreshData();
	}, [userState, squadInfo, userSquads]);

	const refreshData = async () => {
		console.log({ userSquads });
		if ((await isSquadIDinUserObject(userState.user, squadId)) === true) {
			console.log(`Squad ID ${squadId} IS in userObject`);
			let squadGoal = await storageService.getObject("squadGoal-" + squadId);
			if (userSquads !== undefined) {
				await loadSquadData(squadGoal);
			}
			getSquadGoal(squadId, null, null, false, true, true).then((result) => {
				console.log("Squad goal updated: ");
				console.log({ result });
				loadSquadData(result);
			});
		} else {
			console.log(`Squad ID ${squadId} is not in userObject`);
		}
	};

	function titleCase(str) {
		str = str.toLowerCase().split(" ");
		for (var i = 0; i < str.length; i++) {
			str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
		}
		return str.join(" ");
	}
	const loadSquadData = async (squadGoal) => {
		//console.log("loading squad data");
		if (chatRoomObject) {
			squadId = chatRoomObject.squadIdA;
		}
		//console.log("Squad goal for squad: " + squadId);
		//console.log({ squadGoal });
		let squad = userSquads[squadId];
		console.log({ squad });
		if (squad === undefined || squad === null) {
			console.log("Squad is undefined <shrug?>");
			return;
		}

		// // If there's no data stored, fetch it with await. Otherwise can be done async
		// if (squad === null) {
		// 	squad = await getSquad(squadId, null, null, true);
		// } else {
		// 	getSquad(squadId, null, null, true).then((result) => {
		// 		console.log("Squad data updated");
		// 		squad = result;
		// 	});
		// }

		//squad.lastWeekURL = "/squadlastweekcompleted/" + squadId;
		setSquadObject(squad);
		if (dow && dow !== "Mon") {
			if (squadGoal === null) {
				//console.log("squad goal is null");
				//setNullGoalNotice(true);
				return;
			}

			setSquadGoalObject(squadGoal);
			let percent = Math.round((squadGoal.goalProgress / squadGoal.goalPoints) * 100);
			setSquadPercent(percent);
			if (percent > 100) {
				percent = 100;
			}
			let startOfIsoWeek = moment().startOf("isoWeek").format();
			let squadCreatedStartOfIsoWeek = moment(squad.createdAt).startOf("isoWeek").format();
			if (squad.squadType === 99) {
				// this accounts for random weekly squads
				squadCreatedStartOfIsoWeek = null;
			}
			//console.log("startOfIsoWeek: " + startOfIsoWeek + " squadCreatedStartOfIsoWeek: " + squadCreatedStartOfIsoWeek);
			let squadPredictionPoints, squadPredictionPercent;
			let squadPredictionText, squadPredictionIcon, iconColor, chartColor, textColor;
			let squadFlavorText;
			let squadText = {};

			// Have something different if the squad was formed this week
			if (startOfIsoWeek === squadCreatedStartOfIsoWeek) {
				console.log("Started this week");
				setStartedThisWeek(true);
				squadPredictionPercent = 0;
				if (squadGoal.goalProgress === 0) {
					squadText.title = "Ready to get started?";
					squadText.body = "Log your first activity by tapping the plus button. It can be for earlier in the week if you like.";
				} else {
					squadText.title = "Great Start 👍";
					squadText.body = "The more activities your squad logs, the better we'll be able to predict your weekly score.";
				}
				squadPredictionIcon = "trophySharp";
				iconColor = "var(--ion-color-tertiary)";
				squadPredictionText = squadText.title;
				squadFlavorText = squadText.body;
				textColor = "white";
				squadPredictionPoints = "TBD";
				chartColor = "green";
			}
			// Just broke this out so it's clearer
			if (startOfIsoWeek !== squadCreatedStartOfIsoWeek) {
				let seasonStarted = userState.seasonStarted;
				let userInSeason = userState.userInSeason;
				let showUpgradeIndicator = seasonStarted === true && userInSeason === false ? true : false;
				//seasonStarted = true;
				//userInSeason = true;
				if (showUpgradeIndicator === true) {
					squadGoal.prediction = "upgrade";
					squadPredictionPoints = "?";
					squadPredictionPercent = "upgrade";
					squadPredictionText = "Upgrade your season to see predictions";
					squadPredictionIcon = "upgrade";
					iconColor = "#1571b3";
					textColor = "white";
					chartColor = "blue";
					squadFlavorText = "";
				}
				if (showUpgradeIndicator === false) {
					if (!squadGoal.prediction) {
						//console.log("Fetching prediction??");
						squadGoal.prediction = await getSquadPrediction(squadId);
					}
					squadPredictionPoints = squadGoal.prediction;
					squadPredictionPercent = Math.round((squadPredictionPoints / squadGoal.goalPoints) * 100);
					//squadPredictionPercent = 80;
					if (squadGoal.goalProgress >= squadGoal.goalPoints) {
						squadText = await getGoalStatusSystemMessage("squadStatus", "goalcompleted");
						squadPredictionIcon = "completed";
						iconColor = "purple";
						textColor = "white";
						chartColor = "green";
					} else if (squadPredictionPercent >= 120) {
						squadText = await getGoalStatusSystemMessage("squadStatus", "crushit");
						squadPredictionIcon = "hammerSharp";
						iconColor = "orange";
						textColor = "white";
						chartColor = "green";
					} else if (squadPredictionPercent >= 100) {
						squadText = await getGoalStatusSystemMessage("squadStatus", "ontrack");
						squadPredictionIcon = "checkmarkCircleSharp";
						iconColor = "green";
						textColor = "white";
						chartColor = "green";
					} else if (squadPredictionPercent >= 90) {
						squadText = await getGoalStatusSystemMessage("squadStatus", "cuttingitclose");
						squadPredictionIcon = "warningSharp";
						iconColor = "yellow";
						textColor = "black";
						chartColor = "#f0ed00";
					} else if (squadPredictionPercent < 90) {
						squadText = await getGoalStatusSystemMessage("squadStatus", "offtrack");
						squadPredictionIcon = "closeCircleSharp";
						iconColor = "#525252";
						textColor = "white";
						chartColor = iconColor;
					}
					squadPredictionText = await titleCase(squadText.title);
					squadFlavorText = squadText.body;
				}
			}
			setDoughnutChartData({
				datasets: [
					{
						data: [percent, 100 - percent],
						label: "0%",
						backgroundColor: [`${chartColor}`, "#b0b0b0"],
						borderColor: "#f6f6f6",
					},
				],
				options: [],
			});

			setSquadPrediction({
				points: squadPredictionPoints,
				percent: squadPredictionPercent,
				text: squadPredictionText,
				textColor: textColor,
				icon: squadPredictionIcon,
				iconColor: iconColor,
				flavorText: squadFlavorText,
			});
		}
	};

	const navTo = (link) => {
		logNavigationEvent("squad-progress-component", "squad-weekly-summary");
		console.log("history pushing: " + link);
		history.push(link);
	};

	const navToChatroom = async (squadID) => {
		logNavigationEvent("squad-progress-component", "squad-chat-room");
		history.push("/squadhome/" + squadID);
	};

	const navToSalesDetails = () => {
		logGenericClickEvent("squad-progress-component", "nav-to-web-sales");
		window.open("http://fitsquadapp.com/products", "_new");
	};

	return (
		<div>
			{(dow && dow !== "Mon") || startedThisWeek === true ? (
				<div>
					<div id="header" className="squad-scoreboard-header">
						<div className="prediction-holder">
							{
								//squadPrediction && squadPrediction.text !== null && refreshInProgress === false ? (
							}
							{squadPrediction && squadPrediction.text !== null ? (
								<React.Fragment>
									{squadPrediction.points === "?" ? ( // make row clickable
										<IonRow
											onClick={navToSalesDetails}
											style={{ backgroundColor: `${squadPrediction.iconColor}`, color: `${squadPrediction.textColor}` }}
											className="prediction-holder-prediction"
										>
											<IonCol size="12" style={{ textAlign: "center" }}>
												<span>{squadPrediction.text}</span>
											</IonCol>
										</IonRow>
									) : (
										<IonRow
											style={{ backgroundColor: `${squadPrediction.iconColor}`, color: `${squadPrediction.textColor}` }}
											className="prediction-holder-prediction"
										>
											<IonCol size="12" style={{ textAlign: "center" }}>
												<span style={{ fontSize: "1.15em" }}>{squadPrediction.text}</span>
											</IonCol>
										</IonRow>
									)}
								</React.Fragment>
							) : (
								// When the prediction is loading
								<IonRow
									style={{ backgroundColor: `${squadPrediction.iconColor}`, color: `${squadPrediction.textColor}` }}
									className="prediction-holder-prediction"
								>
									<IonCol size="12" style={{ textAlign: "center" }}>
										<span style={{ fontSize: "1.45em" }}>
											<IonSpinner />
										</span>
									</IonCol>
								</IonRow>
							)}
						</div>
						<div className="squad-info-image">
							<div id="squad-info" className="squad-info-div">
								{squadObject ? (
									<React.Fragment>
										<div className="squad-slogan-name-div">
											<div className="squad-slogan-div" style={{ width: "100%", fontStyle: "italic" }}>
												{squadObject.slogan}
											</div>
											<div className="squad-name-div" style={{ width: "100%", fontSize: "1.5em", fontWeight: "bold" }}>
												{squadObject.name}
											</div>
										</div>
										<div className="scoreboard-chart-data">
											<div className="scoreboard-doughnut-chart">
												{doughnutChartData ? (
													<Doughnut data={doughnutChartData} height={64} options={{ cutoutPercentage: 60, maintainAspectRatio: false }} className="graph" />
												) : (
													""
												)}
												{
													//squadPercent >= 1000 && refreshInProgress === false && loadingData === false ? (
												}
												{squadPercent >= 1000 ? (
													<div className="scoreboard-doughnut-chart-percent-squad-progress" style={{ left: "-58px", top: "36px" }}>
														<span className="scoreboard-doughnut-chart-percent-text" style={{ fontSize: "0.9em" }}>
															{squadPercent}%
														</span>
													</div>
												) : (
													""
												)}
												{
													//squadPercent >= 100 && squadPercent < 1000 && refreshInProgress === false && loadingData === false ? (
												}
												{squadPercent >= 100 && squadPercent < 1000 ? (
													<div className="scoreboard-doughnut-chart-percent-squad-progress" style={{ left: "-54px", top: "36px" }}>
														<span className="scoreboard-doughnut-chart-percent-text" style={{ fontSize: "0.9em" }}>
															{squadPercent}%
														</span>
													</div>
												) : (
													""
												)}
												{
													//squadPercent < 100 && refreshInProgress === false && loadingData === false ? (
												}
												{squadPercent < 100 ? (
													<div className="scoreboard-doughnut-chart-percent-squad-progress">
														<span className="scoreboard-doughnut-chart-percent-text">{squadPercent}%</span>
													</div>
												) : (
													""
												)}
											</div>
											<div className="scoreboard-squad-data-holder">
												{
													//squadGoalObject && refreshInProgress === false && loadingData === false ? (
												}
												{squadGoalObject ? (
													<div>
														<IonRow>
															<IonCol className="scoreboard-squad-data-label">Goal</IonCol>
															<IonCol className="scoreboard-squad-data">{squadGoalObject.goalPoints.toLocaleString()}</IonCol>
														</IonRow>
														<IonRow>
															<IonCol className="scoreboard-squad-data-label">Current</IonCol>
															<IonCol className="scoreboard-squad-data">{squadGoalObject.goalProgress.toLocaleString()}</IonCol>
														</IonRow>
													</div>
												) : (
													<div>
														<IonRow>
															<IonCol className="scoreboard-squad-data-label">Goal</IonCol>
															<IonCol className="scoreboard-squad-data">
																<IonSpinner />
															</IonCol>
														</IonRow>
														<IonRow>
															<IonCol className="scoreboard-squad-data-label">Current</IonCol>
															<IonCol className="scoreboard-squad-data">
																<IonSpinner />
															</IonCol>
														</IonRow>
													</div>
												)}
												<div>
													{
														//squadPrediction.points && refreshInProgress === false && loadingData === false ? (
													}
													{squadPrediction.points ? (
														<IonRow>
															<IonCol className="scoreboard-squad-data-label">Prediction</IonCol>
															<IonCol className="scoreboard-squad-data extra-emphasis">{squadPrediction.points.toLocaleString()}</IonCol>
														</IonRow>
													) : (
														<IonRow>
															<IonCol className="scoreboard-squad-data-label">Prediction</IonCol>
															<IonCol className="scoreboard-squad-data extra-emphasis">
																<IonSpinner />
															</IonCol>
														</IonRow>
													)}
												</div>
											</div>
										</div>
									</React.Fragment>
								) : (
									""
								)}
							</div>
							<div id="squad-image" className="squad-image-div">
								{squadObject ? <img src={squadObject.image} alt="" style={{ height: "90px", width: "90px" }} className="squad-image-100" /> : ""}
							</div>
						</div>
						{/**
							 * Dropping the flavor text for now because too much text
<div className="squad-flavor-text">
							{showFlavorText === true && squadPrediction.flavorText !== null && squadObject ? <Emoji text={squadPrediction.flavorText} /> : ""}
							{
								//showFlavorText === true && (squadPrediction.flavorText === null || !squadObject || refreshInProgress === true || loadingData === true) ? (
							}
							{showFlavorText === true && (squadPrediction.flavorText === null || !squadObject) ? <IonSpinner /> : ""}
						</div>


							 */}
					</div>
				</div>
			) : (
				<div>
					<div className="time-holder">
						<IonGrid>
							<IonRow>
								<IonCol className="time-remaining-text" size="12">
									New Squad Goal Starts Tomorrow
									{squadObject ? (
										<IonButton color="secondary" onClick={() => navTo(squadObject.lastWeekURL)}>
											Last Week's Results
										</IonButton>
									) : (
										""
									)}
								</IonCol>
							</IonRow>
						</IonGrid>
					</div>
				</div>
			)}
		</div>
	);
};

export default SquadProgressComponent;
