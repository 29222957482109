import { Clipboard } from "@ionic-native/clipboard";
import { SocialSharing } from "@ionic-native/social-sharing/";
import { IonAlert, IonButton, IonCol, IonIcon, IonPopover, IonRow, IonToast, useIonViewDidEnter } from "@ionic/react";
import { chatbubble, logoFacebook, logoInstagram, logoWhatsapp, mail } from "ionicons/icons";
import React, { useState } from "react";
import "../css/Home.css";
import "../css/SquadInvite.css";
import { logGenericEvent } from "../services/AnalyticsService";
import { sendSlackNotification } from "../services/NotificationService";
import CopyInviteCodeComponent from "./CopyInviteCodeComponent";

const SquadInviteComponent = ({ squadObject }) => {
	let shareMessageNoLink =
		'Join my FitSquad "' +
		squadObject.name +
		'" and let\'s stay accountable to our activity goals! Download the app here and enter the code "' +
		squadObject.shortCode +
		'"';
	let shareMessageWithLink =
		'Join my FitSquad "' +
		squadObject.name +
		'" and let\'s stay accountable to our activity goals! Download the app here https://fitsquadapp.com and enter the code "' +
		squadObject.shortCode +
		'"';
	const shareImage = "https://www.fitsquadapp.com/wp-content/uploads/2021/03/mockup-of-three-iphones-11-lying-together-792-el-2400.png?2e68f1&2e68f1";
	const shareURL = "https://fitsquadapp.com/";
	const shareSubject = "Join my FitSquad!";
	const [network, setNetwork] = useState();
	const [showToast, setShowToast] = useState(false);
	const [showCopyReminder, setShowCopyReminder] = useState({
		visible: false,
		message: null,
	});
	const [showError, setShowError] = useState({
		visible: false,
		message: null,
	});

	useIonViewDidEnter(async () => {});

	const copyText = async () => {
		await Clipboard.copy(shareMessageWithLink);
		setShowToast(true);
		setShowCopyReminder({ visible: false });
	};

	const ShareController = async (showReminder = true, networkPassed = network) => {
		logGenericEvent("attempting to share");
		setNetwork(networkPassed);
		if (showReminder === true) {
			console.log({ shareMessageNoLink });
			setShowCopyReminder({
				visible: true,
				message: shareMessageNoLink,
			});
		} else {
			let response;
			let errorThrown = null;
			switch (networkPassed) {
				case "facebook":
					response = await SocialSharing.shareViaFacebookWithPasteMessageHint(null, null, shareURL, null).catch((error) => {
						errorThrown = error;
					});
					break;
				case "instagram":
					response = await SocialSharing.shareViaInstagram(null, shareImage).catch((error) => {
						errorThrown = error;
					});
					break;
				case "whatsapp":
					response = await SocialSharing.shareViaWhatsApp(null, shareImage, shareURL).catch((error) => {
						errorThrown = error;
					});
					break;
				case "sms":
					response = await SocialSharing.shareViaSMS(shareMessageWithLink).catch((error) => {
						errorThrown = error;
					});
					break;
				case "email":
					response = await SocialSharing.shareViaEmail(shareMessageWithLink, shareSubject).catch((error) => {
						errorThrown = error;
					});
					break;
				default:
					break;
			}
			sendSlackNotification("*New squad invite sent!*\nNetwork: " + networkPassed + "\nSquad Name: " + squadObject.name + "\n", "creation");
			logGenericEvent("successfully shared");
			if (errorThrown !== null && errorThrown !== "cancelled" && errorThrown !== false && errorThrown !== true && errorThrown !== "OK") {
				setShowError({ visible: true, message: "Oops an error happened: " + errorThrown });
				logGenericEvent("error in sharing");
				sendSlackNotification(
					"*Error in sharing*\nError: " + errorThrown + "\nNetwork: " + networkPassed + "\nSquad Name: " + squadObject.name + "\n",
					"creation"
				);
			}
			//console.log("response: " + JSON.stringify(response));
			setNetwork(null);
		}
	};

	return (
		<React.Fragment>
			<IonToast isOpen={showToast} onDidDismiss={() => setShowToast(false)} message="Code copied to clipboard" duration={1000} />
			<IonPopover
				isOpen={showCopyReminder.visible}
				cssClass="exercise-details-full-screen-popover"
				onDidDismiss={(e) => {
					setShowCopyReminder({
						visible: false,
						message: null,
					});
					ShareController(false);
				}}
			>
				<CopyInviteCodeComponent setShowCopyReminder={setShowCopyReminder} showCopyReminder={showCopyReminder} />
			</IonPopover>
			<IonAlert
				isOpen={!!showError.visible}
				header="Oops"
				message={showError.message}
				buttons={[
					{
						text: "Ok",
						role: "cancel",
						handler: () => {
							setShowError({ visible: false, message: null });
						},
					},
				]}
				onDidDismiss={() => setShowError({ visible: false, message: null })}
			/>
			<div className="invite-div">
				<p>
					Share code: "{squadObject.shortCode}" <br />
					<IonButton size="small" onClick={() => copyText()}>
						Copy Invite
					</IonButton>
				</p>
				<p style={{ textAlign: "center" }}>Invite your friends and family to your squad</p>
				<div>
					<IonRow>
						<IonCol>
							<IonButton onClick={() => ShareController(true, "facebook")}>
								<IonIcon icon={logoFacebook} />
							</IonButton>
						</IonCol>
						<IonCol>
							<IonButton onClick={() => ShareController(true, "instagram")}>
								<IonIcon icon={logoInstagram} />
							</IonButton>
						</IonCol>
						<IonCol>
							<IonButton onClick={() => ShareController(true, "whatsapp")}>
								<IonIcon icon={logoWhatsapp} />
							</IonButton>
						</IonCol>
						<IonCol>
							<IonButton onClick={() => ShareController(false, "sms")}>
								<IonIcon icon={chatbubble} />
							</IonButton>
						</IonCol>
						<IonCol>
							<IonButton onClick={() => ShareController(false, "email")}>
								<IonIcon icon={mail} />
							</IonButton>
						</IonCol>
					</IonRow>
				</div>
			</div>
		</React.Fragment>
	);
};

export default SquadInviteComponent;
