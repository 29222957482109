import { IonCol, IonPopover, IonRow, IonSpinner } from "@ionic/react";
import React, { useEffect, useState } from "react";
import "../../css/Home.css";
import "../../css/Seasons.css";
import { getAllSeasonRewards } from "../../services/SeasonsService";
import RewardPopover from "./RewardPopover";

const SeasonsRewardsComponent = ({ seasonObject, userObject, userSeasonObject }) => {
	const [showRewardPopover, setShowRewardPopover] = useState({
		visible: false,
		rewardName: null,
		rewardImage: null,
		rewardDescription: null,
	});
	const [rewardObject, setRewardObject] = useState();

	useEffect(() => {
		setupUserRewards();
	}, [userSeasonObject]);

	const getRewardDetails = async (rewardID, rewardsObject) => {
		return rewardsObject[rewardsObject.map((e) => e.id).indexOf(parseInt(rewardID))]; // gets the reward details from the rewards object
	};

	const setupUserRewards = async () => {
		let tempRewardObject = [];
		let rewardsObject = await getAllSeasonRewards();
		//console.log({ userSeasonObject });
		for await (let reward of userSeasonObject.rewards) {
			tempRewardObject.push(await getRewardDetails(reward.rewardId, rewardsObject)); // adds the reward details for the rewards the user has
		}
		//console.log({ tempRewardObject });
		setRewardObject(tempRewardObject);
	};

	return (
		<React.Fragment>
			<IonPopover
				isOpen={showRewardPopover.visible}
				cssClass="activity-full-screen-popover"
				onDidDismiss={(e) => {
					setShowRewardPopover({
						visible: false,
						rewardImage: null,
						rewardDescription: null,
					});
				}}
			>
				<RewardPopover showRewardPopover={showRewardPopover} setShowRewardPopover={setShowRewardPopover} />
			</IonPopover>
			<div style={{ marginLeft: "16px" }}>
				<React.Fragment>
					<div>
						<div className="section-title" style={{ paddingLeft: "0px" }}>
							Perks And rewards on the line
						</div>
						<IonRow>
							{!rewardObject ? <IonSpinner /> : ""}
							{rewardObject &&
								rewardObject.map((reward, i) => {
									return (
										<IonCol
											key={`${i}`}
											size="3"
											onClick={() =>
												setShowRewardPopover({ visible: true, rewardName: reward.name, rewardImage: reward.image, rewardDescription: reward.description })
											}
										>
											<div>
												{reward.image !== null ? (
													<img src={reward.image} alt="" />
												) : (
													<img src="https://miro.medium.com/max/880/1*PHQUUPETLzyjJ5FE799-UA.jpeg" alt="" />
												)}
											</div>
										</IonCol>
									);
								})}
						</IonRow>
					</div>
				</React.Fragment>
			</div>
		</React.Fragment>
	);
};

export default SeasonsRewardsComponent;
