import { ChallengeDetails, ChallengeLedgerObjectServer, ChallengeObject, ChallengeObjectServer, OrderDetails } from "../models/challengeModels";

import { StorageService } from "../services/StorageService";
import { PHP_URL_NAME } from "../models/constants";
import { getPHP, patchPHP, postPHP } from "./APIService"
import { getUserID } from "./UserService";

export const getAvailableChallenges = async (availableOnly:string = "true") => {
    let availableChallengesAPI = PHP_URL_NAME + "fitsquad/challenges/availableChallenges.php?availableOnly="+availableOnly;
    let response = await getPHP(availableChallengesAPI)
    if(response.challengeObject){
        const storageService = new StorageService();
        await storageService.setObject("availableChallenges", response.challengeObject);
        return response.challengeObject
    } else {
        return null
    }
}

export const getAvailableChallengeByID = async (challengeID : number, activeOnly: boolean = true) => {
    let availableChallengesAPI = PHP_URL_NAME + "fitsquad/challenges/availableChallenges.php?id="+challengeID+"&activeOnly="+activeOnly;
    let response = await getPHP(availableChallengesAPI)
    if(response.challengeObject){
        return response.challengeObject[0]
    } else {
        return null
    }
}

export const getAvailableChallengeByCode = async (code: string) => {
    let availableChallengesAPI = PHP_URL_NAME + "fitsquad/challenges/availableChallenges.php?code="+code;
    let response = await getPHP(availableChallengesAPI)
    if(response.challengeObject && response.challengeObject !== null){
        return response.challengeObject[0]
    } else {
        return null
    }
}

export const signUpUserForChallenge = async (challengeDetails: any) => {
    let userChallengesAPI = PHP_URL_NAME + "fitsquad/challenges/userChallenges.php";
    var response : ChallengeObjectServer = await postPHP(userChallengesAPI, challengeDetails)
    return response
}

export const processChallengeOrder = async (orderDetails: OrderDetails) => {
    let userChallengesAPI = PHP_URL_NAME + "fitsquad/challenges/processOrder.php";
    var response : ChallengeObjectServer = await postPHP(userChallengesAPI, orderDetails)
    return response
}

export const updateUserChallenge = async (challengeDetails: any) => {
    let userChallengesAPI = PHP_URL_NAME + "fitsquad/challenges/userChallenges.php";
    var response : ChallengeObjectServer = await patchPHP(userChallengesAPI, challengeDetails)
    return response
}

export const quitUserChallenge = async (userID : string, challengesID: number) => {
    let userChallengesAPI = PHP_URL_NAME + "fitsquad/challenges/quitChallenge.php?userID="+userID+"&challengesID="+challengesID;
    var response : ChallengeObjectServer = await getPHP(userChallengesAPI)
    return response
}

export const getAllUserChallenges = async (userID : string, activeOnly: boolean = false) => {
    let userChallengesAPI = PHP_URL_NAME + "fitsquad/challenges/userChallenges.php?userID="+userID+"&activeOnly="+activeOnly;
    var response : ChallengeObjectServer = await getPHP(userChallengesAPI)
    return response
}

export const isUserOnAChallenge = async (userID : string, activeOnly: boolean = true) => {
    if (userID === null){
        userID = await getUserID()
    }
    let userChallengesAPI = PHP_URL_NAME + "fitsquad/challenges/userChallenges.php?userID="+userID+"&activeOnly="+activeOnly;
    var response : ChallengeObjectServer = await getPHP(userChallengesAPI)
    console.log({response});
    
    if(response.challengeObject[0] !== null){
        console.log("Response is not empty");
        
        return true
    } else {
        console.log("Response is empty");

        return false
    }
}

export const getAllUserChallengeLedgers = async (userID : string, challengeID: number ) => {
    let userChallengesAPI = PHP_URL_NAME + "fitsquad/challenges/userChallenges.php?userID="+userID+"&challengeID="+challengeID+"&ledgerOnly=true";
    var response : ChallengeLedgerObjectServer = await getPHP(userChallengesAPI)
    if (response.resultCode === 200){
        return response.challengeLedgerObject
    } else {
        return response
    }
}

/**
 * 
 * Intended to pull a certain challenge from an array of challenges the user has
 * 
 * @param challengeArray 
 * @param idDesired 
 * @returns 
 */
export const getChallengeFromChallengeObjectByID = async (challengeArray : Array<ChallengeObject>, idDesired : number) =>{
    let challengeObject : any
    for(let challenge of challengeArray){
        if (challenge.challengeID === idDesired){
            challengeObject = challenge
        }
    }
    return challengeObject
}

export const getChallengeFromChallengeDetailsByID = async (challengeArray : Array<ChallengeDetails>, idDesired : number) =>{
    let challengeDetails : any
    for(let challenge of challengeArray){
        if (parseInt(challenge.id) === idDesired){
            challengeDetails = challenge
        }
    }
    return challengeDetails
}

export const getChallengeDetailsByChallengeID = async (idDesired : number) =>{
    let challengeDetails = null;
    // First see if any are stores
    const storageService = new StorageService();
    let availableChallenges = await storageService.getObject("availableChallenges")
    if (availableChallenges === null){
        availableChallenges = await getAvailableChallenges()
    }
    if (Array.isArray(availableChallenges) && availableChallenges.length > 0){
        for (let availableChallenge of availableChallenges){
            if(availableChallenge.id === idDesired){
                challengeDetails = availableChallenge
            }
        }
        return challengeDetails
    } else {
        return null
    }

}

// export const didChallengeJustEnd = async (challengeID:Number, challengeArray: Array<ChallengeObject> = []) => {
//     const storageService = new StorageService();
//     if (challengeArray === []){
//         challengeArray = await storageService.getObject("challenges");
//     }
//     // if the user has season info...
//     if (challengeArray.length > 0){
//         // ... get the most recent seasons info
//         // TODO: this assumes only one concurrent season at a time
//         let seasonInfo =  await getSeasonByID(user.seasonData[0].seasonID)
//         // see if current day is between season end date and end date + 7 days
//         let currentDate = moment().format()
//         //let currentDate = moment().format("2021-07-27")
//         let endDateOfShowingInfo = moment(seasonInfo.endDate).add("7","days")
//         // if it is it "just ended", else it didn't "just end"
//         if (moment(currentDate) > moment(seasonInfo.endDate) && moment(currentDate) <= moment(endDateOfShowingInfo)){ 
//             return true
//         } else {
//             return false
//         }
//     } else {
//         return false
//     }
// }