import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButton,
	IonCardContent,
	IonButtons,
	IonBackButton,
	useIonViewWillEnter,
	IonItem,
	IonRow,
	IonCol,
	IonRefresher,
	IonRefresherContent,
	IonCard,
	IonCardHeader,
	IonCardTitle,
	IonCardSubtitle,
	IonLoading,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import "../../css/Home.css";
import "../../css/Inventory.css";
import { getUser } from "../../services/UserService";
import { getItemDetailsByItem, getUserInventory } from "../../services/InventoryService";
import { flashOutline } from "ionicons/icons";

const Inventory = () => {
	const [inventory, setInventory] = useState([]);
	const [showLoading, setShowLoading] = useState(false);
	useEffect(() => {}, []);

	useIonViewWillEnter(async () => {
		await getInventory();
	});

	const getInventory = async (refresh = false) => {
		let user = JSON.parse(await getUser(null, refresh));
		if (user.inventory) {
			let tempInventory = await user.inventory;
			//console.log({ tempInventory });
			let tempArray = [];
			for await (let item of tempInventory) {
				item.details = await getItemDetailsByItem(item);

				tempArray.push(item);
			}
			//console.log({ tempArray });
			setInventory(tempInventory);
		} else {
			setInventory(null);
		}
	};

	const doRefresh = async (event, refresh = true) => {
		setInventory([]);
		await getInventory(refresh);
		if (event)
			setTimeout(() => {
				console.log("Async operation has ended");
				event.detail.complete();
			}, 2000);
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
					<IonTitle>Inventory</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} backdropDismiss={true} />

			<IonContent>
				<IonRefresher slot="fixed" onIonRefresh={doRefresh}>
					<IonRefresherContent refreshingText=""></IonRefresherContent>
				</IonRefresher>
				<div style={{ display: "inline-block" }}>
					{inventory !== null &&
						inventory.map((item, i) => {
							return (
								<IonCard className="itemCard" button={true} routerLink={"/userinventory/" + item.inventoryItemId}>
									<IonCardHeader>
										<div style={{ fontSize: "0.75em", textAlign: "center" }}>{item.details.name}</div>
									</IonCardHeader>
									<IonCardContent style={{ textAlign: "center" }}>
										<img style={{ maxHeight: "75px" }} src={item.details.image} alt="" />
									</IonCardContent>
								</IonCard>
							);
						})}
					{inventory === null ? (
						<div style={{ textAlign: "center" }}>
							<h2>You have no items in your inventory</h2>
							<h3>Earn them by participating in seasons!</h3>
						</div>
					) : (
						""
					)}
				</div>
			</IonContent>
		</IonPage>
	);
};

export default Inventory;
