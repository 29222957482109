import { IonCol, IonLabel, IonPopover, IonRow, IonSegment, IonSegmentButton, IonSpinner, useIonViewWillEnter } from "@ionic/react";
import React, { useEffect, useState } from "react";
import "../../css/Home.css";
import "../../css/Seasons.css";
import { sampleRewardsObject } from "../../models/sampleSeason";
import { getAllSeasonRewards } from "../../services/SeasonsService";

import RewardPopover from "./RewardPopover";

const SeasonsOutRewardsComponent = ({ seasonObject, userObject, userSeasonObject }) => {
	const [showRewardPopover, setShowRewardPopover] = useState({
		visible: false,
		rewardName: null,
		rewardImage: null,
		rewardDescription: null,
	});
	const [seasonObj, setSeasonObj] = useState();
	const [rewardTiers, setRewardTiers] = useState(null);
	const [view, setView] = useState("squaddie");

	useEffect(() => {
		setupAllRewards();
	}, [userSeasonObject]);

	const getRewardDetails = async (rewardID, rewardsObject) => {
		return rewardsObject[rewardsObject.map((e) => e.id).indexOf(parseInt(rewardID))]; // gets the reward details from the rewards object
	};

	const setupAllRewards = async () => {
		let tempSeasonObject = seasonObject;
		let rewardsObject = await getAllSeasonRewards();
		let tierIndex = 0;
		let tempTiers = [];
		// This adds reward details to the season object
		// This should allow for easy parsing by tier, while preserving important properties like quantity
		for await (let tier of tempSeasonObject.tiers) {
			let rewardIndex = 0;
			for await (let rewards of tier.rewards) {
				let rewardDetails = await getRewardDetails(rewards.rewardId, rewardsObject);
				tempSeasonObject.tiers[tierIndex].rewards[rewardIndex].details = rewardDetails;
				rewardIndex++;
			}
			if (tierIndex === 1) {
				setView(tier.id);
			}
			tierIndex++;
			tempTiers.push(tier);
		}

		setRewardTiers(tempTiers);
		setSeasonObj(tempSeasonObject);
	};

	return (
		<React.Fragment>
			<IonPopover
				isOpen={showRewardPopover.visible}
				cssClass="activity-full-screen-popover"
				onDidDismiss={(e) => {
					setShowRewardPopover({
						visible: false,
						rewardImage: null,
						rewardDescription: null,
					});
				}}
			>
				<RewardPopover showRewardPopover={showRewardPopover} setShowRewardPopover={setShowRewardPopover} />
			</IonPopover>
			<div style={{ marginLeft: "16px", marginRight: "16px" }}>
				<React.Fragment>
					<div>
						<div className="section-title" style={{ paddingLeft: "0px" }}>
							Rewards
						</div>
						<IonSegment value={view} onIonChange={(e) => setView(e.detail.value)}>
							{rewardTiers &&
								rewardTiers.map((tier, i) => {
									return (
										<React.Fragment>
											<IonSegmentButton key={i} value={tier.id}>
												<IonLabel>{tier.name}</IonLabel>
											</IonSegmentButton>
										</React.Fragment>
									);
								})}
						</IonSegment>
						<IonRow>
							{seasonObj &&
								seasonObj.tiers &&
								seasonObj.tiers.map((tiers, i) => {
									if (parseInt(view) === parseInt(tiers.id)) {
										// this ensures only rewards for the tier selected are visible
										return (
											// This loops through the tiers
											<React.Fragment>
												{tiers.rewards.map((reward, i) => {
													return (
														// this loops through the rewards in the tier
														<React.Fragment>
															<IonCol
																key={i}
																size="3"
																onClick={() =>
																	setShowRewardPopover({
																		visible: true,
																		rewardName: reward.details.name,
																		rewardImage: reward.details.image,
																		rewardDescription: reward.details.description,
																	})
																}
															>
																<div>
																	{reward.details.image !== null ? (
																		<img src={reward.details.image} alt="" />
																	) : (
																		<img src="https://miro.medium.com/max/880/1*PHQUUPETLzyjJ5FE799-UA.jpeg" alt="" />
																	)}
																</div>
															</IonCol>
														</React.Fragment>
													);
												})}
											</React.Fragment>
										);
									}
								})}
						</IonRow>
					</div>
				</React.Fragment>
			</div>
		</React.Fragment>
	);
};

export default SeasonsOutRewardsComponent;
